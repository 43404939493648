(function () {
  "use strict";

  var lariDevApp = angular.module("lariAidApp");
  lariDevApp.controller("InvestigationFormController", InvestigationFormController);

  InvestigationFormController.$inject = [
    "$element",
    "$scope",
    "$window",
    "$mdSidenav",
    "Principal",
    "$state",
    "$mdMedia",
    "$cookies",
    "APP",
    "InvestigationFormService",
    "$stateParams",
    "$location",
    "investigationFormIndexService",
    "$document",
    "$interval",
    "$rootScope",
    "$sessionStorage",
    "Upload",
    "$timeout",
    "$q",
    "$mdDialog",
    "CommonService",
    "SideCommentsDirectiveService",
    "$filter",
    "$controller",
    "$mdUtil",
    "BASE_URL"
  ];
  function InvestigationFormController(
    $element,
    $scope,
    $window,
    $mdSidenav,
    Principal,
    $state,
    $mdMedia,
    $cookies,
    APP,
    InvestigationFormService,
    $stateParams,
    $location,
    investigationFormIndexService,
    $document,
    $interval,
    $rootScope,
    $sessionStorage,
    Upload,
    $timeout,
    $q,
    $mdDialog,
    CommonService,
    SideCommentsDirectiveService,
    $filter,
    $controller,
    $mdUtil,
    BASE_URL
  ) {
    $scope.deleteLoader = {};
    $scope.deleteLoader.flag = false;
    $scope.recordFlag = true;
    var vm = this;
    angular.extend(this, $controller("LibraryAddRequestController", { $scope: $scope }));
    var id = "birthCertificateSectionSummary";
    $scope.mediaCheck = $mdMedia;
    $scope.cancelAutoSave = false;
    $rootScope.formValid = {};
    vm.profileForm = $stateParams;
    vm.formProfile = {};
    vm.investigation = [];
    $scope.investigationFormId = vm.profileForm.investigationFormId;
    vm.app = APP;
    $scope.entityChanged = false;
    $scope.assetDownloadAPI = BASE_URL + vm.app.INVESTIGATION.DOWNLOAD_API;
    vm.user = CommonService.getCookieUser().cookieUser;
    $scope.uploadFileSectionList = [];
    $scope.uploadFileSectionListObj = {};
    $scope.uploadFileSectionListObj.uploadList = [];

    $scope.uploadFileSectionListObjSummary = {};
    $scope.uploadFileSectionListObjSummary.uploadList = [];
    $scope.sideCommentTrigger = false;

    $scope.uploadFileSectionFamilyPhotoList = [];
    $scope.uploadFileSectionFamilyPhotoListObj = {};
    $scope.uploadFileSectionFamilyPhotoListObj.uploadList = [];
    $scope.fileTypeError = false;
    $scope.fileUploadLoader = false;
    vm.warrnFlag = false;
    $scope.checkValid;
    $scope.isView = $sessionStorage.isView;
    vm.entityProfileList = [];
    $scope.uploadFileDelete = {};

    vm.myDate = new Date();
    $scope.sideComments = {};

    $scope.toggleValue = {};
    $scope.toggleValue.sideNav = [];
    $scope.toggleValue.sideNav.checkboxes = [];
    $scope.toggleValue.sideNav.checkboxes.values = [];
    $scope.summaryActivated = true;

    $scope.sideNavAccordion = {};
    $scope.sideNavAccordion.expanded = false;
    $scope.familyPhotoCommentTrigger = false;

    var originatorEv;

    $scope.investigationSectionObj = {};
    $scope.investigationSectionObj.id = [];
    $scope.investigationSectionObj.name = [];
    vm.sectionValue = {};
    vm.sectionValue.birthcerticate_checked = false;
    vm.sectionValue.birthAndMedical_checked = false;
    vm.sectionValue.familyInfo_checked = false;
    vm.sectionValue.schoolInfo_checked = false;
    vm.sectionValue.religiousInstitution_checked = false;
    vm.sectionValue.residencyQuestion_checked = false;
    vm.sectionValue.neighbourhoodCanvas_checked = false;
    vm.sectionValue.other_checked = false;
    vm.sectionValue.summary_checked = false;
    $scope.uploadedFile = false;
    $scope.lastEditTime;
    vm.isLastEditVisible = false; //Initially its not visible for ui.
    $rootScope.sideCommentVisible = true; //To edit the side comments even when the form is in readOnly mode
    vm.showWarning = {};
    $rootScope.warningPopup = {};
    $rootScope.warningPopup.show = undefined;

    $scope.goback = false;
    vm.disableUpload = false;
    $rootScope.notApproved = false;
    $scope.maxDate = new Date(vm.myDate.getFullYear(), vm.myDate.getMonth(), vm.myDate.getDate());

    vm.loggedUserRole = CommonService.getCookieUser().loggedUserRole;

    /* Method name           : loadSectionFormByType()
     * Method Description 	 : This method is used to trigger the section form state by type
     */
    vm.loadSectionFormByType = function (type) {
      $scope.previousState = angular.copy($scope.currentState);
      if ($scope.currentState == undefined) {
        $scope.previousState = type;
      }
      if ($scope.currentState != type) {
        $scope.sideCommentTrigger = false;
      }
      $scope.currentState = type;
      $scope.triggerSaveForActiveSection($scope.previousState, $scope.currentState);
    };

    $scope.YesToProceed = false;
    $scope.sectionNavigationFunc = function (type) {
      if (type == "bithcerticateForm") {
        $scope.currentSection = vm.app.REF_TERM.BIRTH_CERTIFICATE;
        if ($scope.isOwner || $scope.YesToProceed || $scope.goback || !$rootScope.warningPopup.show) {
          $state.go("birthCertificate");
          $scope.YesToProceed = false;
          $scope.goback = false;
        } else if ($rootScope.warningPopup.show) {
          $scope.showWarning("formSectionChange");
        }

        //$scope.getSectionIdByType('BIRTH_CERTIFICATE');
      } else if (type == "birthAndMedicalForm") {
        $scope.currentSection = vm.app.REF_TERM.BIRTH_AND_MEDICAL_RECORDS;
        if ($scope.isOwner || $scope.YesToProceed || $scope.goback || !$rootScope.warningPopup.show) {
          $state.go("birthMedicalRecord");
          $scope.YesToProceed = false;
          $scope.goback = false;
        } else if ($rootScope.warningPopup.show) {
          $scope.showWarning("formSectionChange");
        }
        //$scope.getSectionIdByType('BIRTH_AND_MEDICAL_RECORDS');
      } else if (type == "familyInfoForm") {
        $scope.currentSection = vm.app.REF_TERM.FAMILY_INFORMATION;
        if ($scope.isOwner || $scope.YesToProceed || $scope.goback || !$rootScope.warningPopup.show) {
          $state.go("familyInformation");
          $scope.YesToProceed = false;
          $scope.goback = false;
        } else if ($rootScope.warningPopup.show) {
          $scope.showWarning("formSectionChange");
        }
        //$scope.getSectionIdByType('FAMILY_INFORMATION');
      } else if (type == "schoolInfoForm") {
        $scope.currentSection = vm.app.REF_TERM.SCHOOL_INFORMATION;
        if ($scope.isOwner || $scope.YesToProceed || $scope.goback || !$rootScope.warningPopup.show) {
          $state.go("schoolInformation");
          $scope.YesToProceed = false;
          $scope.goback = false;
        } else if ($rootScope.warningPopup.show) {
          $scope.showWarning("formSectionChange");
        }
        //$scope.getSectionIdByType('SCHOOL_INFORMATION');
      } else if (type == "religiousInstitutionForm") {
        $scope.currentSection = vm.app.REF_TERM.RELIGIOUS_INSTITUTIONS;
        if ($scope.isOwner || $scope.YesToProceed || $scope.goback || !$rootScope.warningPopup.show) {
          $state.go("religiousInstitution");
          $scope.YesToProceed = false;
          $scope.goback = false;
        } else if ($rootScope.warningPopup.show) {
          $scope.showWarning("formSectionChange");
        }
        //$scope.getSectionIdByType('RELIGIOUS_INSTITUTIONS');
      } else if (type == "neighbourhoodCanvasForm") {
        $scope.currentSection = vm.app.REF_TERM.NEIGHBORHOOD_CANVAS;
        if ($scope.isOwner || $scope.YesToProceed || $scope.goback || !$rootScope.warningPopup.show) {
          $state.go("neighbourhoodCanvas");
          $scope.YesToProceed = false;
          $scope.goback = false;
        } else if ($rootScope.warningPopup.show) {
          $scope.showWarning("formSectionChange");
        }
        //$scope.getSectionIdByType('NEIGHBORHOOD_CANVAS');
      } else if (type == "residencyQuestionForm") {
        $scope.currentSection = vm.app.REF_TERM.RESIDENCY_QUESTIONS;
        if ($scope.isOwner || $scope.YesToProceed || $scope.goback || !$rootScope.warningPopup.show) {
          $state.go("residencyQuestions");
          $scope.YesToProceed = false;
          $scope.goback = false;
        } else if ($rootScope.warningPopup.show) {
          $scope.showWarning("formSectionChange");
        }
        //$scope.getSectionIdByType('RESIDENCY_QUESTIONS');
      } else if (type == "summaryForm") {
        $scope.currentSection = vm.app.REF_TERM.SUMMARY;
        if ($scope.isOwner || $scope.YesToProceed || $scope.goback || !$rootScope.warningPopup.show) {
          $state.go("summary");
          $scope.YesToProceed = false;
          $scope.goback = false;
        } else if ($rootScope.warningPopup.show) {
          $scope.showWarning("formSectionChange");
        }
        //$scope.getSectionIdByType('SUMMARY');
      } else if (type == "dnaInfoForm") {
        $scope.currentSection = vm.app.REF_TERM.DNA;
        if ($scope.isOwner || $scope.YesToProceed || $scope.goback || !$rootScope.warningPopup.show) {
          $state.go("dnaInformation");
          $scope.YesToProceed = false;
          $scope.goback = false;
        } else if ($rootScope.warningPopup.show) {
          $scope.showWarning("formSectionChange");
        }
        //$scope.getSectionIdByType('DNA');
      } else if (type == "otherForm") {
        $scope.currentSection = vm.app.REF_TERM.OTHER;
        if ($scope.isOwner || $scope.YesToProceed || $scope.goback || !$rootScope.warningPopup.show) {
          $state.go("other");
          $scope.YesToProceed = false;
          $scope.goback = false;
        } else if ($rootScope.warningPopup.show) {
          $scope.showWarning("formSectionChange");
        }
        //$scope.getSectionIdByType('OTHER');
      }
      $scope.currentState = type;
    };

    $scope.showWarning = function (popupId) {
      var event = this;
      $mdDialog.show({
        contentElement: angular.element("#" + popupId),
        targetEvent: event,
        clickOutsideToClose: false,
        escapeToClose: false
      });
      $timeout(function () {
        $rootScope.warningPopup.show = false;
      });
    };

    vm.backToCurrentSection = function () {
      $scope.goback = true;
      $scope.retainValue = true; // To retain checkbox values if No is clicked in popup
      $mdDialog.cancel();
      var index = null;

      var ps = $scope.previousState;
      $scope.currentState = $scope.previousState;
      if ($scope.currentState == "bithcerticateForm") {
        index = 0;
      } else if ($scope.currentState == "birthAndMedicalForm") {
        index = 1;
      } else if ($scope.currentState == "neighbourhoodCanvasForm") {
        index = 2;
      } else if ($scope.currentState == "familyInfoForm") {
        index = 3;
      } else if ($scope.currentState == "schoolInfoForm") {
        index = 4;
      } else if ($scope.currentState == "religiousInstitutionForm") {
        index = 5;
      }
      $scope.collapseAll(index);
      $scope.sectionNavigationFunc(ps);
    };

    //To clear select All values after clicking Yes in popup
    vm.clearSelectAllCheckListValue = function (sectionType) {
      vm["selectAll" + sectionType + "Type"].firstObj.checked = false;
      vm["selectAll" + sectionType + "Type"].secondObj.checked = false;
      vm["selectAll" + sectionType + "Type"].thirdObj.checked = false;
      vm["selectAll" + sectionType + "Type"].fourthObj.checked = false;
    };

    vm.changeSection = function () {
      $scope.YesToProceed = true;
      $mdDialog.cancel();
      var cs = $scope.currentState;
      //Clear or keep previous value in checkbox if yes is clicked in popup
      if ($scope.investigationFormType == "ANALYST_FORM") {
        if ($scope.previousState == "bithcerticateForm" && !$scope.isOwner) {
          $scope.birthCertificateCheckList = angular.copy($scope.birthCertificateCheckListTempObj);
          vm.clearSelectAllCheckListValue("BC");
        } else if ($scope.previousState == "birthAndMedicalForm" && !$scope.isOwner) {
          $scope.birthMedicalCheckList = angular.copy($scope.birthMedicalCheckListTempObj);
          vm.clearSelectAllCheckListValue("BirthMedicalRecord");
        } else if ($scope.previousState == "neighbourhoodCanvasForm" && !$scope.isOwner) {
          $scope.neighbourhoodCheckList = angular.copy($scope.neighbourhoodCheckListTempObj);
          vm.clearSelectAllCheckListValue("Neighbourhood");
        } else if ($scope.previousState == "familyInfoForm" && !$scope.isOwner) {
          $scope.familyCheckList = angular.copy($scope.familyCheckListTempObj);
          vm.clearSelectAllCheckListValue("Family");
        } else if ($scope.previousState == "schoolInfoForm" && !$scope.isOwner) {
          $scope.schoolCheckList = angular.copy($scope.schoolCheckListTempObj);
          vm.clearSelectAllCheckListValue("School");
        } else if ($scope.previousState == "religiousInstitutionForm" && !$scope.isOwner) {
          $scope.religiousCheckList = angular.copy($scope.religiousCheckListTempObj);
          vm.clearSelectAllCheckListValue("Religious");
        }
      }
      $scope.sectionNavigationFunc(cs);
    };

    /* Method name           : triggerSaveForActiveSection()
     * Method Description 	 : This method is used to trigger the save function on navigation menu click
     */

    $scope.triggerSaveForActiveSection = function (sectionType, currentType) {
      if (sectionType) {
        if (sectionType == vm.app.INVESTIGATION_FORM.BIRTH_CERTIFICATE_FORM) {
          $timeout(function () {
            if (!$scope.isreadonly && sectionType != currentType) {
              angular.element("#bcFormSave").triggerHandler("click");
            }
            if (sectionType != currentType) {
              $scope.saveAnalystFormTypeCheckList("birthCertificate");
            }
            $scope.currentType = currentType;
            $scope.sectionNavigationFunc(currentType);
          }, 1);
        } else if (sectionType == vm.app.INVESTIGATION_FORM.BIRTH_AND_MEDICAL_FORM) {
          $timeout(function () {
            if (!$scope.isreadonly && sectionType != currentType) {
              angular.element("#medicalFormSave").triggerHandler("click");
            }
            if (sectionType != currentType) {
              $scope.saveAnalystFormTypeCheckList("birthMedical");
            }
            $scope.currentType = currentType;

            $scope.sectionNavigationFunc(currentType);
          }, 1);
        } else if (sectionType == vm.app.INVESTIGATION_FORM.FAMILY_FORM) {
          $timeout(function () {
            if (!$scope.isreadonly && sectionType != currentType) {
              angular.element("#familyFormSave").triggerHandler("click");
            }
            if (sectionType != currentType) {
              $scope.saveAnalystFormTypeCheckList("family");
            }
            $scope.currentType = currentType;
            $scope.sectionNavigationFunc(currentType);
          }, 1);
        } else if (sectionType == vm.app.INVESTIGATION_FORM.SCHOOL_FORM) {
          $timeout(function () {
            if (!$scope.isreadonly && sectionType != currentType) {
              angular.element("#schoolFormSave").triggerHandler("click");
            }
            if (sectionType != currentType) {
              $scope.saveAnalystFormTypeCheckList("school");
            }
            $scope.currentType = currentType;
            $scope.sectionNavigationFunc(currentType);
          }, 1);
        } else if (sectionType == vm.app.INVESTIGATION_FORM.RELIGIOUS_FORM) {
          $timeout(function () {
            if (!$scope.isreadonly && sectionType != currentType) {
              angular.element("#religiousFormSave").triggerHandler("click");
            }
            if (sectionType != currentType) {
              $scope.saveAnalystFormTypeCheckList("religious");
            }
            $scope.currentType = currentType;
            $scope.sectionNavigationFunc(currentType);
          }, 1);
        } else if (sectionType == vm.app.INVESTIGATION_FORM.NEIGHBOURHOOD_CANVASS_FORM) {
          $timeout(function () {
            if (!$scope.isreadonly && sectionType != currentType) {
              angular.element("#neighbourhoodFormSave").triggerHandler("click");
            }
            if (sectionType != currentType && sectionType != currentType) {
              $scope.saveAnalystFormTypeCheckList("neighbourhood");
            }
            $scope.currentType = currentType;
            $scope.sectionNavigationFunc(currentType);
          }, 1);
        } else if (sectionType == vm.app.INVESTIGATION_FORM.RESIDENCY_FORM) {
          $timeout(function () {
            if (!$scope.isreadonly && sectionType != currentType) {
              angular.element("#residencyFormSave").triggerHandler("click");
            }
            $scope.currentType = currentType;
            $scope.sectionNavigationFunc(currentType);
          }, 1);
        } else if (sectionType == vm.app.INVESTIGATION_FORM.DNA_FORM) {
          $timeout(function () {
            if (!$scope.isreadonly && sectionType != currentType) {
              angular.element("#dnaFormSave").triggerHandler("click");
            }
            $scope.currentType = currentType;
            $scope.sectionNavigationFunc(currentType);
          }, 1);
        } else if (sectionType == vm.app.INVESTIGATION_FORM.SUMMARY_FORM) {
          $timeout(function () {
            if (!$scope.isreadonly && sectionType != currentType) {
              angular.element("#summaryFormSave").triggerHandler("click");
            }
            $scope.currentType = currentType;
            $scope.sectionNavigationFunc(currentType);
          }, 1);
        } else if (sectionType == vm.app.INVESTIGATION_FORM.OTHER_FORM) {
          $timeout(function () {
            if (!$scope.isreadonly && sectionType != currentType) {
              angular.element("#otherFormSave").triggerHandler("click");
            }
            $scope.currentType = currentType;
            $scope.sectionNavigationFunc(currentType);
          }, 1);
        }
      }
    };

    /* Method name           : getSectionIdByType()
     * Method Description 	 : This method is used to get the section Id by type passed
     */
    /*$scope.getSectionIdByType = function(type){
    		var data = InvestigationFormService.getSectionIdByType(type);
  		  	data.then(function(response){
  		  		//$scope.investigationFormSectionId = response[0].id;
  		  		if(type == 'BIRTH_CERTIFICATE'){
  		  			$scope.birthCertificateSectionId = response[0].id;
  		  			$state.go("birthCertificate");
  		  		} else if(type == 'BIRTH_AND_MEDICAL_RECORDS'){
  		  			$scope.birthMedicalSectionId = response[0].id;
  		  			$state.go("birthMedicalRecord");
  		  		} else if(type == 'FAMILY_INFORMATION'){
  		  			$scope.familyInfoSectionId = response[0].id;
  		  			$state.go("familyInformation");
  		  		} else if(type == 'SCHOOL_INFORMATION'){
  		  			$scope.schoolInfoSectionId = response[0].id;
  		  			$state.go("schoolInformation");
  		  		} else if(type == 'RELIGIOUS_INSTITUTIONS'){
  		  			$scope.religiousInsSectionId = response[0].id;
  		  			$state.go("religiousInstitution");
  		  		} else if(type == 'NEIGHBORHOOD_CANVAS'){
  		  			$scope.neighbourhoodSectionId = response[0].id;
  		  			$state.go("neighbourhoodCanvas");
  		  		} else if(type == 'DNA'){
		  			$scope.dnaFormSectionId = response[0].id;
		  			$state.go("dnaInformation");
		  		}else if(type == 'RESIDENCY_QUESTIONS'){
  		  			$scope.residencySectionId = response[0].id;
  		  			$state.go("residencyQuestions");
  		  		} else if(type == 'SUMMARY'){
  		  			$scope.summarySectionId = response[0].id;
  		  			$state.go("summary");
  		  		} else if(type == 'OTHER'){
  		  			$scope.summarySectionId = response[0].id;
  		  			$state.go("other");
  		  		}
  		  }, function(reason) {
  			  return reason;
  		  });
    	};*/

    /* Method name           : triggerAutosave()
     * Method Description 	 : This method triggers auto save once investigation form is opened
     */
    var idleInterval;
    var intervalFlag = false;
    $scope.triggerAutosave = function () {
      clearTimeout(idleInterval);
      idleInterval = $timeout(function () {
        if (angular.isDefined(idleInterval)) {
          if (!$scope.cancelAutoSave) {
            if (intervalFlag == true) {
              // $scope.triggerAutosave();
              //$interval.cancel(idleInterval);
              // $scope.autoSaveSection($location.path(),false);
              intervalFlag = false;
            } else if (intervalFlag == false) {
              $scope.autoSaveSection($location.path(), true);
            }
          }
        }
      }, 7000);
    };

    /* Method name           : triggerAutoSaveInterval()
     * Method Description 	 : This method triggers auto save interval once investigation form is opened
     */
    var stopIntrvl;
    $scope.triggerAutoSaveInterval = function () {
      if (!$scope.isreadonly) {
        //$interval.cancel(stopIntrvl);
        if (angular.isDefined(stopIntrvl)) return;
        //clearInterval(stopIntrvl);
        stopIntrvl = $interval(function () {
          if (!$scope.cancelAutoSave) {
            $scope.triggerAutosave();
          }
        }, 7000);
      }
    };

    $scope.stopInterval = function () {
      if (angular.isDefined(stopIntrvl)) {
        $interval.cancel(stopIntrvl);
        stopIntrvl = undefined;
      }
    };

    $scope.$on("$destroy", function () {
      $scope.stopInterval();
    });

    //$document.find('body').on('mousemove DOMMouseScroll mousewheel mousedown keydown keypress keyup tap click touchstart',checkAndResetIdle);

    /* Method name           : checkAndResetIdle()
     * Method Description 	 : This method resets the idle time interval
     */
    function checkAndResetIdle() {
      if (intervalFlag == false) {
        intervalFlag = true;
        $scope.triggerAutosave();
      }
    }

    /* Method name           : autoSaveSection()
     * Method Description 	 : This method assign the section auto save value by location path
     */
    $scope.autoSaveSection = function (path, value) {
      path = path.substring(path.lastIndexOf("/"), path.length);
      if (path == "/birthCertificate") {
        $rootScope.birthCertificateAutoSave = value;
      } else if (path == "/birthMedicalReport") {
        $rootScope.birthMedicalAutoSave = value;
      } else if (path == "/familyInformation") {
        $rootScope.familyInfoAutoSave = value;
      } else if (path == "/schoolInformation") {
        $rootScope.schoolInfoAutoSave = value;
      } else if (path == "/religiousInstitution") {
        $rootScope.religiousInstitutionAutoSave = value;
      } else if (path == "/neighbourhoodCanvas") {
        $rootScope.neighbourhoodCanvasAutoSave = value;
      } else if (path == "/residencyQuestions") {
        $rootScope.residencyQuestionsAutoSave = value;
      } else if (path == "/dnaInformation") {
        $rootScope.dnaAutoSave = value;
      } else if (path == "/summary") {
        $rootScope.summaryAutoSave = value;
      } else if (path == "/other") {
        $rootScope.otherAutoSave = value;
      }
    };

    vm.cancelAutoSave = function () {
      $interval.cancel(idleInterval);
      $scope.cancelAutoSave = true;
    };

    /* Method name           : checkSectionFormType()
     * Method Description 	 : This method is used to load the section from which it is been refreshed
     */
    vm.checkSectionFormType = function () {
      var type = investigationFormIndexService.getInvestigationValue();
      vm.loadSectionFormByType(type);
      $scope.cancelAutoSave = false;
      //$scope.triggerAutosave();
      $scope.triggerAutoSaveInterval();
    };

    /*vm.checkSectionFormType();*/

    /* Method name           : loadBirthCertificateInitially()
     * Method Description 	 : This method is used to load birth certificate form initially on page load
     */
    vm.loadBirthCertificateInitially = function () {
      $state.go("birthCertificate");
    };

    /* Method name           : isActiveSectionForm()
     * Method Description 	 : This method is used to highlight the active investigation form in left panel of sections
     */
    vm.isActiveSectionForm = function (path) {
      if ($location.path().substr($location.path().lastIndexOf("/")) == path) {
        return true;
      } else {
        return false;
      }
    };
    /*Investigation Form's Section sidenav  Starts Here*/
    $scope.sectionLeft = buildToggler("formLeft");
    $scope.toggleRight = buildToggler("formRight");

    function buildToggler(componentId) {
      return function () {
        $mdSidenav(componentId).toggle();
      };
    }
    /*Investigation Form's Section sidenav Ends Here */

    vm.backProfile = function () {
      vm.investigation.id = vm.formProfile.investigationId;
      $state.go("profile", vm.investigation);
    };

    vm.formProfileDetails = function (investigationFormId) {
      if (investigationFormId) {
        var data = InvestigationFormService.getFormProfileDetails(investigationFormId);
        data.then(
          function (response) {
            vm.profileDetailLoader = false;
            if (response.dateOfBirth != "" && response.dateOfBirth != null) {
              response.dobMonth = response.dateOfBirth.substring(0, 3)
              response.dobDay = response.dateOfBirth.substring(3, 7)
              response.dobYear = response.dateOfBirth.substring(7, 12)
            }
            vm.formProfile = response;
            $scope.updateProspectName(
              response.firstName,
              response.lastName,
              vm.formProfile.investigationType,
              vm.formProfile.investigationId
            );
            $scope.investigationID = vm.formProfile.investigationId;
            $scope.investigationFormType = vm.formProfile.investigationFormType;
            $scope.investigationDateOfBirth = vm.formProfile.dateOfBirth;
            $scope.investigationFormStatus = vm.formProfile.investigationFormStatus;

            if (vm.formProfile.isOwner == 1) {
              $scope.isOwner = true;
            } else {
              $scope.isOwner = false;
            }
            $scope.isreadonly = true;
            //To confirm the investigation status is open
            if (
              vm.formProfile.investigationStatus != vm.app.REF_TERM.CONFIRMED &&
              vm.formProfile.investigationStatus != vm.app.REF_TERM.FAILED &&
              vm.formProfile.investigationStatus != vm.app.REF_TERM.UNDETERMINED &&
              vm.formProfile.investigationStatus != vm.app.REF_TERM.WAIVED &&
              vm.formProfile.investigationStatus != vm.app.REF_TERM.NA &&
              vm.formProfile.investigationStatus != vm.app.REF_TERM.DELETED_IN_EBIS
            ) {
              if (vm.loggedUserRole == vm.app.GLOBAL.MANAGER || vm.loggedUserRole == vm.app.GLOBAL.ADMIN) {
                $scope.isreadonly = false;
              }

              //Investigation Form
              if (
                vm.formProfile.investigationFormType == vm.app.INVESTIGATION.INVESTIGATOR_FORM &&
                ((vm.formProfile.isOwner == 1 && vm.formProfile.investigationFormStatus == vm.app.GLOBAL.IN_PROGRESS) ||
                  vm.loggedUserRole == vm.app.GLOBAL.ANALYST || vm.loggedUserRole == vm.app.GLOBAL.ROLE_COUNTRY_ADMIN)
              ) {
                $scope.isreadonly = false;
              } //Analyst Form
              else if (
                vm.formProfile.investigationFormType != vm.app.INVESTIGATION.INVESTIGATOR_FORM &&
                vm.formProfile.isOwner == 1
              ) {
                $scope.isreadonly = false;
                if (
                  vm.loggedUserRole == vm.app.GLOBAL.ANALYST &&
                  vm.formProfile.investigationFormStatus == vm.app.GLOBAL.COMPLETED
                ) {
                  $scope.isreadonly = true;
                }
              }
            }
          },
          function (reason) {
            return reason;
          }
        );
      }
    };

    if (vm.profileForm != null) {
      vm.formProfileDetails(vm.profileForm.investigationFormId);
    }

    /**
     * Method to check the uploaded file section.
     * orginSectionFileUpload - Will be set from every section.
     */
    $scope.setOrginSectionFileUpload = function (orginSectionName) {
      $scope.orginSectionFileUpload = orginSectionName;
    };

    //fileupload
    $scope.uploadFileInSectionSummary = function (
      ev,
      files,
      questionKey,
      sectionId,
      isSummary,
      id,
      idName,
      sectionAccordionIndex,
      description
    ) {
      $scope.uploadingFile = files;
      $scope.fileUploadLoader = true;
      $scope.fileTypeError = false;
      vm.submittedAsset = {};
      vm.submittedAsset.fileName = files.name;
      var dispalyName = files.name;
      dispalyName = dispalyName.slice(0, dispalyName.lastIndexOf("."));
      var questionId;
      var fileDescription;
      var entityId;
      var investigationFormType;
      $scope.questionKeyUpload = questionKey;
      $scope.sectionAccordionIndex = sectionAccordionIndex;

      if (questionKey != null && questionKey != undefined) {
        questionId = questionKey;
      }
      if (description != null && description != undefined) {
        fileDescription = description;
      }
      if (sectionId != null && sectionId != undefined) {
        entityId = sectionId;
      }
      if ($scope.investigationFormType == vm.app.INVESTIGATION.INVESTIGATOR_FORM) {
        investigationFormType = "INVESTIGATOR_FORM";
      } else if ($scope.investigationFormType == vm.app.INVESTIGATION.ANALYST_FORM) {
        investigationFormType = "ANALYST_FORM";
      }

      if (files && files.size <= 26214400) {
        if (
          files.type == "application/pdf" ||
          files.type == "image/png" ||
          files.type == "image/jpg" ||
          files.type == "image/jpeg" ||
          files.type == "image/bmp"
        ) {
          $scope.uploadFileDetails = {};
          $scope.uploadFileDetails.files = files;
          $scope.uploadFileDetails.dispalyName = dispalyName;
          $scope.uploadFileDetails.entityId = entityId;
          $scope.uploadFileDetails.investigationFormType = investigationFormType;
          $scope.uploadFileDetails.questionId = questionId;
          $scope.uploadFileDetails.fileDescription = fileDescription;
          $scope.uploadFileDetails.isSummary = isSummary;
          $scope.uploadFileDetails.sectionAccordionIndex = sectionAccordionIndex;
          //Show the confirmation popup if user edit the another user form
          if ($scope.isOwner) $scope.uploadFile();
          else {
            vm.disableUpload = false;
            $mdDialog.show({
              targetEvent: ev,
              contentElement: "#" + vm.app.INVESTIGATION_FORM.UPLOAD_DOCUMENT_CONFIRMATION_POPUPID,
              clickOutsideToClose: false,
              escapeToClose: false
            });
          }
        } else {
          $timeout(function () {
            $scope.fileUploadLoader = false;
            var errorId = "fileTypeError_" + idName;
            var element = document.getElementById(errorId);
            element.classList.remove("hide");
            $timeout(function () {
              element.classList.add("hide");
            }, 5000);
          }, 100);
        }
      } else {
        $scope.fileUploadLoader = false;
        var errorId = "fileSizeError_" + idName;
        var element = document.getElementById(errorId);
        element.classList.remove("hide");
        $timeout(function () {
          element.classList.add("hide");
        }, 5000);
      }
    };

    //Upload the aatachments
    $scope.uploadFile = function () {
      vm.disableUpload = true;
      //Initialize the file upload progress flag for respective sections.
      if (vm.app.INVESTIGATION_FORM.FAMILY_QUESTIONS.QUESTION1_PHOTO == $scope.uploadFileDetails.questionId) {
        $scope.fileUploadProgress_photo = 0;
      } else if ($scope.uploadFileDetails.isSummary == vm.app.GLOBAL.TRUE) {
        $scope.fileUploadProgressSummary = 0;
      } else {
        $scope.fileUploadProgressAccordion = 0;
      }
      //Close the confirmation popup
      vm.gobacktoForm();
      $scope.uploadFileDetails.files.upload = Upload.upload({
        url: BASE_URL + "api/assets/uploadDocument",
        fields: {
          displayName: $scope.uploadFileDetails.dispalyName,
          parentEntityId: vm.profileForm.investigationFormId,
          entityId: $scope.uploadFileDetails.entityId,
          parentEntityType: $scope.uploadFileDetails.investigationFormType,
          entityType: "SECTION",
          questionId: $scope.uploadFileDetails.questionId,
          description: $scope.uploadFileDetails.fileDescription,
          isSummary: $scope.uploadFileDetails.isSummary,
          sectionAccordionIndex: $scope.uploadFileDetails.sectionAccordionIndex
        },
        file: $scope.uploadFileDetails.files
      });
      $scope.uploadFileDetails.files.upload.then(
        function (response) {
          $scope.uploadedFile = true;
          $scope.fileUploadLoader = false;
          $scope.fileUploadProgressAccordion = null;
          $scope.fileUploadProgressSummary = null;
          $scope.fileUploadProgress_photo = null;
          $scope.updateLastEditTime(response.data.updatedOn);
          setTimeout(function () {
            $scope.uploadFileDetails.files.result = response.data;

            // Summary documents
            if (
              !$scope.uploadFileDetails.questionId &&
              $scope.uploadFileDetails.questionId != vm.app.INVESTIGATION_FORM.SUMMARY_KEY &&
              $scope.uploadFileDetails.questionId != vm.app.INVESTIGATION_FORM.FAMILY_QUESTIONS.QUESTION1_PHOTO
            ) {
              $scope.uploadFileSectionListObj.uploadList.push($scope.uploadFileDetails.files.result);
              $scope.uploadFileSectionList.push($scope.uploadFileSectionListObj);
            } else if ($scope.uploadFileDetails.questionId == vm.app.INVESTIGATION_FORM.SUMMARY_KEY) {
              $scope.uploadFileSectionListObjSummary.uploadList.push($scope.uploadFileDetails.files.result);
              $scope.uploadFileSectionListSummary.push($scope.uploadFileSectionListObjSummary);
            }
            //Family photo documents
            else {
              $scope.uploadFileSectionFamilyPhotoListObj.uploadList.push($scope.uploadFileDetails.files.result);
              $scope.uploadFileSectionFamilyPhotoList.push($scope.uploadFileSectionFamilyPhotoListObj);
              //Set the temporay family photo object to restrict the family description save call
              $scope.tempFamilyPhotoObject = angular.copy($scope.uploadFileSectionFamilyPhotoListObj.uploadList);
            }
          });
        },
        function (response) {
          $scope.fileUploadLoader = false;
          $scope.fileUploadProgressAccordion = null;
          $scope.fileUploadProgressSummary = null;
          $scope.fileUploadProgress_photo = null;
          if (response.status > 0) $scope.errorMsg = response.status + ": " + response.data;
        },
        function (evt) {
          if ($scope.uploadFileDetails.questionId == vm.app.INVESTIGATION_FORM.FAMILY_QUESTIONS.QUESTION1_PHOTO) {
            $scope.fileUploadProgress_photo = Math.min(100, parseInt((100.0 * evt.loaded) / evt.total));
          } else if ($scope.uploadFileDetails.isSummary == "true") {
            $scope.fileUploadProgressSummary = Math.min(100, parseInt((100.0 * evt.loaded) / evt.total));
          } else {
            $scope.fileUploadProgressAccordion = Math.min(100, parseInt((100.0 * evt.loaded) / evt.total));
          }
        }
      );
    };

    /* Method name           : hideUploadProgressBar()
     * Method Description 	 : This method is used to make the progress value null to hide the progress bar on abort
     */
    $scope.hideUploadProgressBar = function (isSummary) {
      $timeout(function () {
        if (isSummary == "true") {
          $scope.fileUploadProgressSummary = null;
        } else if (isSummary == "photo") {
          $scope.fileUploadProgress_photo = null;
          $scope.fileUploadLoader = false;
        } else {
          $scope.fileUploadProgressAccordion = null;
        }
      }, 100);
    };

    $scope.removeAttachment = function (element) {
      $(element.target.parentElement).remove();
    };
    $scope.deleteFileConfirm = function (ev, documentId, index, questionKey) {
      $scope.uploadFileDelete = {};
      $scope.uploadFileDelete.documentId = documentId;
      $scope.uploadFileDelete.index = index;
      $scope.uploadFileDelete.questionKey = questionKey;
      $scope.recordFlag = false;
      $mdDialog.show({
        targetEvent: ev,
        contentElement: "#deleteUploadFilePopup",
        clickOutsideToClose: false,
        escapeToClose: false
      });
    };

    $scope.deleteFile = function () {
      var data = InvestigationFormService.deleteFiles($scope.uploadFileDelete.documentId);
      data.then(function (response) {
        $scope.recordFlag = true;
        $mdDialog.hide();
        $scope.updateLastEditTime(response.updatedOn);
        $scope.uploadedFile = true;
        if (
          !$scope.uploadFileDelete.questionKey &&
          $scope.uploadFileDelete.questionKey != vm.app.INVESTIGATION_FORM.SUMMARY_KEY &&
          $scope.uploadFileDelete.questionKey != vm.app.INVESTIGATION_FORM.FAMILY_QUESTIONS.QUESTION1_PHOTO
        ) {
          $scope.uploadFileSectionList[0].uploadList.splice($scope.uploadFileDelete.index, 1);
        } else if ($scope.uploadFileDelete.questionKey == vm.app.INVESTIGATION_FORM.SUMMARY_KEY) {
          $scope.uploadFileSectionListSummary[0].uploadList.splice($scope.uploadFileDelete.index, 1);
        } else {
          $scope.uploadFileSectionFamilyPhotoList[0].uploadList.splice($scope.uploadFileDelete.index, 1);
          //Set the temporay family photo object to restrict the family description save call
          $scope.tempFamilyPhotoObject = angular.copy($scope.uploadFileSectionFamilyPhotoList[0].uploadList);
        }
      });
    };

    $scope.deleteDocumentByIndex = function (entityKey, sectionAccordionIndex) {
      var parentEntityId = vm.profileForm.investigationFormId;
      var data = InvestigationFormService.deleteFilesByIndex(parentEntityId, entityKey, sectionAccordionIndex);
      data.then(function (response) {
        //Updating 0th index after delete 0th accordion
        if (sectionAccordionIndex == 0) {
          $scope.getUploadFiles(null, entityKey, sectionAccordionIndex);
        }
      });
    };

    $scope.deleteAllFamilyPhoto = function (familyPhotos) {
      $mdDialog.hide();
      $scope.uploadFileSectionFamilyPhotoList[0].uploadList = [];
      angular.forEach(familyPhotos, function (data, key) {
        InvestigationFormService.deleteFiles(data.id);
      });
    };

    $scope.getUploadFiles = function (questionKey, entityKey, sectionAccordionIndex) {
      $scope.uploadFileSectionList = [];
      $scope.uploadFileSectionListObj.uploadList = [];

      $scope.uploadFileLoader = true;
      var parentEntityId = vm.profileForm.investigationFormId;
      var data = InvestigationFormService.getUploadFiles(parentEntityId, entityKey, sectionAccordionIndex, questionKey);
      data.then(function (response) {
        //sectionAccordionIndex!=undefined &&
        if (
          !questionKey &&
          questionKey != vm.app.INVESTIGATION_FORM.SUMMARY_KEY &&
          questionKey != "familyInformation_familyPhoto_1"
        ) {
          $scope.uploadFileSectionListObj.uploadList = response;
          $scope.uploadFileSectionList.push($scope.uploadFileSectionListObj);
        } else if (questionKey == vm.app.INVESTIGATION_FORM.SUMMARY_KEY) {
          $scope.uploadFileSectionListSummary = [];
          $scope.uploadFileSectionListObjSummary.uploadList = [];

          $scope.uploadFileSectionListObjSummary.uploadList = response;
          $scope.uploadFileSectionListSummary.push($scope.uploadFileSectionListObjSummary);
        } else {
          $scope.uploadFileSectionFamilyPhotoList = [];
          $scope.uploadFileSectionFamilyPhotoListObj.uploadList = [];

          $scope.uploadFileSectionFamilyPhotoListObj.uploadList = response;
          $scope.uploadFileSectionFamilyPhotoList.push($scope.uploadFileSectionFamilyPhotoListObj);
          //Set the temporay family photo object to restrict the family description save call
          $scope.tempFamilyPhotoObject = [];
          $scope.tempFamilyPhotoObject = angular.copy($scope.uploadFileSectionFamilyPhotoListObj.uploadList);
        }

        $scope.uploadFileLoader = false;
      });
    };

    //Method for add or suggest entity
    $scope.entityObj = {};
    $scope.entityRequestAddorSuggest = function (entityName, entityType) {
      entityName = entityName.split(",")[0];
      $scope.entityObj.entityTypeKey = [];
      $scope.entityObj.searchKey = {};
      if (entityType == "Medical Institution") {
        $scope.entityObj.entityTypeKey.push(vm.app.INVESTIGATION.MEDICAL_INSTITUTION);
      } else if (entityType == "Religious Institution") {
        $scope.entityObj.entityTypeKey.push(vm.app.INVESTIGATION.RELIGIOUS_INSTITUTION);
      } else if (entityType == "School") {
        $scope.entityObj.entityTypeKey.push(vm.app.INVESTIGATION.SCHOOL);
      } else if (entityType == "Dna Center") {
        $scope.entityObj.entityTypeKey.push(vm.app.INVESTIGATION.DNA_CENTER);
      } else if (entityType == "Local Registry") {
        $scope.entityObj.entityTypeKey.push(vm.app.INVESTIGATION.LOCAL_REGISTRY);
      } else if (entityType == "National Registry") {
        $scope.entityObj.entityTypeKey.push(vm.app.INVESTIGATION.NATIONAL_REGISTRY);
      } else {
        $scope.entityObj.entityTypeKey.push(vm.app.INVESTIGATION.LOCAL_REGISTRY);
        $scope.entityObj.entityTypeKey.push(vm.app.INVESTIGATION.NATIONAL_REGISTRY);
      }
      $scope.entityObj.searchKey = entityName;

      var deferred = $q.defer();
      var data = InvestigationFormService.addorSuggestEntityRequest($scope.entityObj);
      data.then(function (response) {
        deferred.resolve(response);
        //To  handle auto complete scroll in touch based devices
        window.addEventListener(
          "touchstart",
          function () {
            $timeout(function () {
              if (response.length == 0) {
                $mdUtil.enableScrolling();
                window.addEventListener(
                  "touchmove",
                  function () {
                    $(".md-autocomplete-suggestions-container.md-not-found").addClass("ng-hide");
                    $(".md-autocomplete-suggestions-container.md-not-found").removeClass(
                      "$mdAutocompleteCtrl.notFoundVisible()"
                    );
                  },
                  false
                );
              }
            }, 200);
          },
          false
        );
      });
      return deferred.promise;
    };

    vm.sideNavClose = function () {
      $mdSidenav("formLeft").close();
    };

    $scope.collapseAll = function (index) {
      vm.collapseAll(index);
      if (index == 0) {
        vm.getAnalystSectionList("birthCertitficate", vm.selectAllBCType);
      } else if (index == 1) {
        vm.getAnalystSectionList("birthMedical", vm.selectAllBirthMedicalRecordType);
      } else if (index == 2) {
        vm.getAnalystSectionList("neighbourhood", vm.selectAllNeighbourhoodType);
      } else if (index == 3) {
        vm.getAnalystSectionList("family", vm.selectAllFamilyType);
      } else if (index == 4) {
        vm.getAnalystSectionList("school", vm.selectAllSchoolType);
      } else if (index == 5) {
        vm.getAnalystSectionList("religious", vm.selectAllReligiousType);
      } else if (index == 9) {
        vm.summarycall();
      }
    };

    vm.collapseAll = function (index) {
      $sessionStorage.selectedSectionIndex = index;
      if (index != 9) {
        for (var i = 0; i < 6; i++) {
          if (i == index) {
            if ($scope.sideNavAccordion[i]) {
              $scope.sideNavAccordion[i] = false;
              vm.currentStateSideNav();
            } else {
              $scope.sideNavAccordion[i] = true;
            }
          } else {
            $scope.sideNavAccordion[i] = false;
          }
        }
        $scope.summaryActivated = true;
      } else {
        for (var i = 0; i < 10; i++) {
          $scope.sideNavAccordion[i] = true;
        }
        $scope.summaryActivated = false;
      }
    };

    vm.currentStateSideNav = function () {
      if ($scope.currentState == "bithcerticateForm" && $scope.isOwner == true) {
        $scope.saveAnalystFormTypeCheckList("birthCertificate");
      } else if ($scope.currentState == "birthAndMedicalForm" && $scope.isOwner == true) {
        $scope.saveAnalystFormTypeCheckList("birthMedical");
      } else if ($scope.currentState == "neighbourhoodCanvasForm" && $scope.isOwner == true) {
        $scope.saveAnalystFormTypeCheckList("neighbourhood");
      } else if ($scope.currentState == "familyInfoForm" && $scope.isOwner == true) {
        $scope.saveAnalystFormTypeCheckList("family");
      } else if ($scope.currentState == "schoolInfoForm" && $scope.isOwner == true) {
        $scope.saveAnalystFormTypeCheckList("school");
      } else if ($scope.currentState == "religiousInstitutionForm" && $scope.isOwner == true) {
        $scope.saveAnalystFormTypeCheckList("religious");
      }
    };

    vm.togglecheckbox = function (sideNavVal, checkboxes, type, selectAll) {
      if (selectAll) {
        for (var i = 1; i <= selectAll; i++) {
          // console.log("type====ww"+$scope.toggleValue.sideNav[sideNavVal].checkboxes[i].values[type]);

          //$scope.toggleValue.sideNav[sideNavVal].checkboxes[0].values[type]=true;
          $scope.toggleValue.sideNav[sideNavVal].checkboxes[i].values[type] = true;
          //$scope.toggleValue.sideNav[sideNavVal].checkboxes[2].values[type]=true;
          //$scope.toggleValue.sideNav[sideNavVal].checkboxes[3].values[type]=true;
          /* for(var j=0;j<4;j++){
					 if(j==type){
				           $scope.toggleValue.sideNav[sideNavVal].checkboxes[i].values[j]=true;
					 }
					 else{
						$scope.toggleValue.sideNav[sideNavVal].checkboxes[i].values[j]=false;
					 }
				 }*/
        }
      } else {
        for (var i = 0; i < 4; i++) {
          if (i == type) {
            $scope.toggleValue.sideNav[0].checkboxes[checkboxes].values[i] = false;
            $scope.toggleValue.sideNav[sideNavVal].checkboxes[checkboxes].values[i] = true;
          } else {
            $scope.toggleValue.sideNav[sideNavVal].checkboxes[checkboxes].values[i] = false;
          }
        }
      }
    };
    vm.openMenu = function ($mdOpenMenu, ev) {
      originatorEv = ev;
      $mdOpenMenu(ev);
    };

    /*vm.openDialog = function(ev,id){
   		$mdDialog.show({                   
            targetEvent: ev,
            contentElement:'#'+id
           });
   	}*/
    vm.selectCheck = [
      {
        name: "Birth Certificate"
      },
      {
        name: "Birth Medical"
      },
      {
        name: "Family Information"
      }
    ];
    //COPY INVESTIGATOR
    if (vm.user.authorities[0] == vm.app.GLOBAL.ROLE_INVESTIGATOR) {
      delete $sessionStorage.isView;
    }
    vm.formSectionCopy = function (sections, ev, id) {
      $scope.investigationSectionObj = {};
      $scope.investigationSectionObj.id = [];
      $scope.investigationSectionObj.name = [];

      var data = CommonService.getRefTermList(vm.app.REF_TERM.SECTION);
      data.then(function (response) {
        vm.formSectionList = response;

        //To set the selected section id and name
        if (sections.birthcerticate_checked) {
          vm.getSectionId("Birth Certificate", vm.formSectionList);
        }
        if (sections.birthAndMedical_checked) {
          vm.getSectionId("Birth And Medical Records", vm.formSectionList);
        }
        if (sections.neighbourhoodCanvas_checked) {
          vm.getSectionId("Neighborhood Canvass", vm.formSectionList);
        }
        if (sections.familyInfo_checked) {
          vm.getSectionId("Family Information", vm.formSectionList);
        }
        if (sections.schoolInfo_checked) {
          vm.getSectionId("School Information", vm.formSectionList);
        }
        if (sections.religiousInstitution_checked) {
          vm.getSectionId("Religious Institutions", vm.formSectionList);
        }
        if (sections.residencyQuestion_checked) {
          vm.getSectionId("Residency Questions", vm.formSectionList);
        }
        if (sections.other_checked) {
          vm.getSectionId("Other", vm.formSectionList);
        }
        if (sections.summary_checked) {
          vm.getSectionId("Summary", vm.formSectionList);
        }
        $mdDialog.show({
          targetEvent: ev,
          contentElement: "#" + id,
          parent: angular.element(document.body),
          clickOutsideToClose: false,
          escapeToClose: false
        });
      });
    };
    vm.getSectionId = function (sectionName, formlist) {
      for (var i = 0; i < formlist.length; i++) {
        if (formlist[i].name == sectionName) {
          $scope.investigationSectionObj.id.push(formlist[i].id);
          $scope.investigationSectionObj.name.push(formlist[i].name);
          break;
        }
      }
    };
    vm.checkFlag = true;
    vm.selectAllSection = function (list) {
      if (list) {
        vm.sectionValue.birthcerticate_checked = false;
        vm.sectionValue.birthAndMedical_checked = false;
        vm.sectionValue.neighbourhoodCanvas_checked = false;
        vm.sectionValue.familyInfo_checked = false;
        vm.sectionValue.schoolInfo_checked = false;
        vm.sectionValue.religiousInstitution_checked = false;
        vm.sectionValue.residencyQuestion_checked = false;
        vm.sectionValue.other_checked = false;
        vm.sectionValue.summary_checked = false;
      } else {
        vm.sectionValue.birthcerticate_checked = true;
        vm.sectionValue.birthAndMedical_checked = true;
        vm.sectionValue.neighbourhoodCanvas_checked = true;
        vm.sectionValue.familyInfo_checked = true;
        vm.sectionValue.schoolInfo_checked = true;
        vm.sectionValue.religiousInstitution_checked = true;
        vm.sectionValue.residencyQuestion_checked = true;
        vm.sectionValue.other_checked = true;
        vm.sectionValue.summary_checked = true;
      }
    };
    vm.unselect = function () {
      vm.selectAll = false;
    };

    vm.goBack = function () {
      $mdDialog.hide();
    };
    vm.submitWarn = function () {
      if ($sessionStorage.analystFormId != null) {
        vm.copyDisable = true;
        vm.analystFormId = $sessionStorage.analystFormId;
        vm.investigatorFormId = vm.profileForm.investigationFormId;
        var data = InvestigationFormService.copyInvestigationFormDetails(
          vm.analystFormId,
          vm.investigatorFormId,
          $scope.investigationSectionObj.id
        );
        data.then(
          function (response) {
            vm.copyDisable = false;
            vm.warrnFlag = true;
          },
          function (reason) {
            vm.copyDisable = false;
            return reason;
          }
        );
      }
    };

    vm.openAnalystForm = function () {
      if ($sessionStorage.analystFormId != null) {
        vm.profileForm.investigationFormId = $sessionStorage.analystFormId;
      }
      delete $sessionStorage.analystFormId;
      $state.go("profileForm", vm.profileForm);
      $sessionStorage.selectedSectionIndex = 0;
      $sessionStorage.formType = vm.app.INVESTIGATION.ANALYST_FORM;
      delete $sessionStorage.isView;
      $mdDialog.hide();
    };
    vm.selectedItemList = [];
    vm.selectAllBCType = {};
    vm.selectAllBCType.firstObj = {};
    vm.selectAllBCType.secondObj = {};
    vm.selectAllBCType.thirdObj = {};
    vm.selectAllBCType.fourthObj = {};
    vm.selectAllBCType.firstObj.checked = false;
    vm.selectAllBCType.secondObj.checked = false;
    vm.selectAllBCType.thirdObj.checked = false;
    vm.selectAllBCType.fourthObj.checked = false;

    vm.selectAllBirthMedicalRecordType = {};
    vm.selectAllBirthMedicalRecordType.firstObj = {};
    vm.selectAllBirthMedicalRecordType.secondObj = {};
    vm.selectAllBirthMedicalRecordType.thirdObj = {};
    vm.selectAllBirthMedicalRecordType.fourthObj = {};
    vm.selectAllBirthMedicalRecordType.firstObj.checked = false;
    vm.selectAllBirthMedicalRecordType.secondObj.checked = false;
    vm.selectAllBirthMedicalRecordType.thirdObj.checked = false;
    vm.selectAllBirthMedicalRecordType.fourthObj.checked = false;

    vm.selectAllNeighbourhoodType = {};
    vm.selectAllNeighbourhoodType.firstObj = {};
    vm.selectAllNeighbourhoodType.secondObj = {};
    vm.selectAllNeighbourhoodType.thirdObj = {};
    vm.selectAllNeighbourhoodType.fourthObj = {};
    vm.selectAllNeighbourhoodType.firstObj.checked = false;
    vm.selectAllNeighbourhoodType.secondObj.checked = false;
    vm.selectAllNeighbourhoodType.thirdObj.checked = false;
    vm.selectAllNeighbourhoodType.fourthObj.checked = false;

    vm.selectAllFamilyType = {};
    vm.selectAllFamilyType.firstObj = {};
    vm.selectAllFamilyType.secondObj = {};
    vm.selectAllFamilyType.thirdObj = {};
    vm.selectAllFamilyType.fourthObj = {};
    vm.selectAllFamilyType.firstObj.checked = false;
    vm.selectAllFamilyType.secondObj.checked = false;
    vm.selectAllFamilyType.thirdObj.checked = false;
    vm.selectAllFamilyType.fourthObj.checked = false;

    vm.selectAllSchoolType = {};
    vm.selectAllSchoolType.firstObj = {};
    vm.selectAllSchoolType.secondObj = {};
    vm.selectAllSchoolType.thirdObj = {};
    vm.selectAllSchoolType.fourthObj = {};
    vm.selectAllSchoolType.firstObj.checked = false;
    vm.selectAllSchoolType.secondObj.checked = false;
    vm.selectAllSchoolType.thirdObj.checked = false;
    vm.selectAllSchoolType.fourthObj.checked = false;

    vm.selectAllReligiousType = {};
    vm.selectAllReligiousType.firstObj = {};
    vm.selectAllReligiousType.secondObj = {};
    vm.selectAllReligiousType.thirdObj = {};
    vm.selectAllReligiousType.fourthObj = {};
    vm.selectAllReligiousType.firstObj.checked = false;
    vm.selectAllReligiousType.secondObj.checked = false;
    vm.selectAllReligiousType.thirdObj.checked = false;
    vm.selectAllReligiousType.fourthObj.checked = false;

    vm.toggleList = function (list, subList, index, parentIndex, item, selectObj) {
      if (index == 0) {
        if (selectObj.firstObj.checked) {
          angular.forEach(subList, function (data, key) {
            if (index == key) {
              data.checked = false;
              selectObj.firstObj.checked = false;
            }
          });
        } else {
          var listCheckFirstSize = 0;
          angular.forEach(list, function (data, key) {
            for (var i = 0; i < data.checkBoxDTOList.length; i++) {
              if (i == 0 && data.checkBoxDTOList[index].checked == true) {
                listCheckFirstSize++;
              }
            }
          });
          if (list.length == listCheckFirstSize) {
            selectObj.firstObj.checked = true;
          } else {
            selectObj.firstObj.checked = false;
          }
        }
      } else if (index == 1) {
        if (selectObj.secondObj.checked) {
          angular.forEach(subList, function (data, key) {
            if (index == key) {
              data.checked = false;
              selectObj.secondObj.checked = false;
            }
          });
        } else {
          var listCheckSecondSize = 0;
          angular.forEach(list, function (data, key) {
            for (var i = 0; i < data.checkBoxDTOList.length; i++) {
              if (i == 0 && data.checkBoxDTOList[index].checked == true) {
                listCheckSecondSize++;
              }
            }
          });
          if (list.length == listCheckSecondSize) {
            selectObj.secondObj.checked = true;
          } else {
            selectObj.secondObj.checked = false;
          }
        }
      } else if (index == 2) {
        if (selectObj.thirdObj.checked) {
          angular.forEach(subList, function (data, key) {
            if (index == key) {
              data.checked = false;
              selectObj.thirdObj.checked = false;
            }
          });
        } else {
          var listCheckThirdSize = 0;
          angular.forEach(list, function (data, key) {
            for (var i = 0; i < data.checkBoxDTOList.length; i++) {
              if (i == 0 && data.checkBoxDTOList[index].checked == true) {
                listCheckThirdSize++;
              }
            }
          });
          if (list.length == listCheckThirdSize) {
            selectObj.thirdObj.checked = true;
          } else {
            selectObj.thirdObj.checked = false;
          }
        }
      } else if (index == 3) {
        if (selectObj.fourthObj.checked) {
          angular.forEach(subList, function (data, key) {
            if (index == key) {
              data.checked = false;
              selectObj.fourthObj.checked = false;
            }
          });
        } else {
          var listCheckFourthSize = 0;
          angular.forEach(list, function (data, key) {
            for (var i = 0; i < data.checkBoxDTOList.length; i++) {
              if (i == 0 && data.checkBoxDTOList[index].checked == true) {
                listCheckFourthSize++;
              }
            }
          });
          if (list.length == listCheckFourthSize) {
            selectObj.fourthObj.checked = true;
          } else {
            selectObj.fourthObj.checked = false;
          }
        }
      }
    };

    vm.toggleSubList = function (subList, index, parentIndex, toggleObj) {
      angular.forEach(subList, function (data, key) {
        if (index != key) {
          data.checked = false;
          toggleObj.firstObj.checked = false;
          toggleObj.secondObj.checked = false;
          toggleObj.thirdObj.checked = false;
          toggleObj.fourthObj.checked = false;
        } else {
          data.checked = true;
        }
      });
    };

    vm.isChecked = function (selectObj) {
      return selectObj.checked;
    };

    vm.toggleAll = function (doSelectAll, indexVal, list, selectableObj) {
      if (indexVal == 0) {
        selectableObj.firstObj.checked = doSelectAll;
        selectableObj.secondObj.checked = false;
        selectableObj.thirdObj.checked = false;
        selectableObj.fourthObj.checked = false;
      } else if (indexVal == 1) {
        selectableObj.secondObj.checked = doSelectAll;
        selectableObj.firstObj.checked = false;
        selectableObj.thirdObj.checked = false;
        selectableObj.fourthObj.checked = false;
      } else if (indexVal == 2) {
        selectableObj.thirdObj.checked = doSelectAll;
        selectableObj.secondObj.checked = false;
        selectableObj.firstObj.checked = false;
        selectableObj.fourthObj.checked = false;
      } else if (indexVal == 3) {
        selectableObj.fourthObj.checked = doSelectAll;
        selectableObj.secondObj.checked = false;
        selectableObj.thirdObj.checked = false;
        selectableObj.firstObj.checked = false;
      }
      if (!doSelectAll) {
        angular.forEach(list, function (data, key) {
          if (indexVal == 0) {
            angular.forEach(data.checkBoxDTOList, function (val, index) {
              if (index == 0) {
                val.checked = false;
              }
            });
          }
          if (indexVal == 1) {
            angular.forEach(data.checkBoxDTOList, function (val, index) {
              if (index == 1) {
                val.checked = false;
              }
            });
          }
          if (indexVal == 2) {
            angular.forEach(data.checkBoxDTOList, function (val, index) {
              if (index == 2) {
                val.checked = false;
              }
            });
          }
          if (indexVal == 3) {
            angular.forEach(data.checkBoxDTOList, function (val, index) {
              if (index == 3) {
                val.checked = false;
              }
            });
          }
        });
      } else {
        angular.forEach(list, function (data, key) {
          if (indexVal == 0) {
            angular.forEach(data.checkBoxDTOList, function (val, index) {
              if (index == 0) {
                val.checked = true;
              } else {
                val.checked = false;
              }
            });
          }
          if (indexVal == 1) {
            angular.forEach(data.checkBoxDTOList, function (val, index) {
              if (index == 1) {
                val.checked = true;
              } else {
                val.checked = false;
              }
            });
          }
          if (indexVal == 2) {
            angular.forEach(data.checkBoxDTOList, function (val, index) {
              if (index == 2) {
                val.checked = true;
              } else {
                val.checked = false;
              }
            });
          }
          if (indexVal == 3) {
            angular.forEach(data.checkBoxDTOList, function (val, index) {
              if (index == 3) {
                val.checked = true;
              } else {
                val.checked = false;
              }
            });
          }
        });
      }
    };

    /*$scope.birthCertificateCheckList = [];
      $scope.birthMedicalCheckList = [];
      $scope.neighbourhoodCheckList = [];
      $scope.familyCheckList = [];
      $scope.schoolCheckList = [];
      $scope.religiousCheckList = [];*/

    vm.getAnalystSectionList = function (type, selectableObj) {
      $timeout(function () {
        if ($sessionStorage.formType == vm.app.INVESTIGATION.ANALYST_FORM && !$scope.retainValue) {
          var sectionKey;
          if (type == "birthCertitficate") {
            sectionKey = vm.app.REF_TERM.BIRTH_CERTIFICATE;
            $scope.birthCertificateCheckList = [];
          } else if (type == "birthMedical") {
            sectionKey = vm.app.REF_TERM.BIRTH_AND_MEDICAL_RECORDS;
            $scope.birthMedicalCheckList = [];
          } else if (type == "neighbourhood") {
            sectionKey = vm.app.REF_TERM.NEIGHBORHOOD_CANVAS;
            $scope.neighbourhoodCheckList = [];
          } else if (type == "family") {
            sectionKey = vm.app.REF_TERM.FAMILY_INFORMATION;
            $scope.familyCheckList = [];
          } else if (type == "school") {
            sectionKey = vm.app.REF_TERM.SCHOOL_INFORMATION;
            $scope.schoolCheckList = [];
          } else if (type == "religious") {
            sectionKey = vm.app.REF_TERM.RELIGIOUS_INSTITUTIONS;
            $scope.religiousCheckList = [];
          }
          var formId = $scope.investigationFormId;
          var data = InvestigationFormService.getIndividualAnalystSectionList(sectionKey, formId);
          data.then(function (response) {
            selectableObj.fourthObj.checked = false;
            selectableObj.secondObj.checked = false;
            selectableObj.thirdObj.checked = false;
            selectableObj.firstObj.checked = false;
            if (type == "birthCertitficate") {
              if ($scope.birthCertificateCheckList.length == 0) {
                vm.loadStaticBirthCertificateList();
                $scope.birthCertificateCheckListTempObj = angular.copy($scope.birthCertificateCheckList);
              }
              if (response && response.formSectionResultDTOList.length > 0) {
                angular.forEach($scope.birthCertificateCheckList, function (val, index) {
                  angular.forEach(response.formSectionResultDTOList, function (data, key) {
                    if (val.questionKey == data.questionKey) {
                      val.checkBoxDTOList = data.checkBoxDTOList;
                    }
                    if (data.questionValue == "Other" && data.subAnswer && data.subAnswer != "") {
                      val.subAnswer = data.subAnswer;
                      val.others_Checked = true;
                    }
                  });
                });
                $scope.birthCertificateCheckListTempObj = angular.copy($scope.birthCertificateCheckList);
              } /*else {
        				  vm.loadStaticBirthCertificateList();
        			  }*/
            } else if (type == "birthMedical") {
              if ($scope.birthMedicalCheckList.length == 0) {
                vm.loadStaticBirthMedicalRecordList();
                $scope.birthMedicalCheckListTempObj = angular.copy($scope.birthMedicalCheckList);
              }
              if (response && response.formSectionResultDTOList.length > 0) {
                angular.forEach($scope.birthMedicalCheckList, function (val, index) {
                  angular.forEach(response.formSectionResultDTOList, function (data, key) {
                    if (val.questionValue == data.questionValue) {
                      val.checkBoxDTOList = data.checkBoxDTOList;
                    }
                    if (data.questionValue == "Other" && data.subAnswer && data.subAnswer != "") {
                      val.subAnswer = data.subAnswer;
                      val.others_Checked = true;
                    }
                  });
                });
                $scope.birthMedicalCheckListTempObj = angular.copy($scope.birthMedicalCheckList);
              } else {
                vm.loadStaticBirthMedicalRecordList();
              }
            } else if (type == "neighbourhood") {
              if ($scope.neighbourhoodCheckList.length == 0) {
                vm.loadStaticNeighbourhoodCheckList();
                $scope.neighbourhoodCheckListTempObj = angular.copy($scope.neighbourhoodCheckList);
              }
              if (response && response.formSectionResultDTOList.length > 0) {
                angular.forEach($scope.neighbourhoodCheckList, function (val, index) {
                  angular.forEach(response.formSectionResultDTOList, function (data, key) {
                    if (val.questionValue == data.questionValue) {
                      val.checkBoxDTOList = data.checkBoxDTOList;
                    }
                    if (data.questionValue == "Other" && data.subAnswer && data.subAnswer != "") {
                      val.subAnswer = data.subAnswer;
                      val.others_Checked = true;
                    }
                  });
                });
                $scope.neighbourhoodCheckListTempObj = angular.copy($scope.neighbourhoodCheckList);
              } else {
                vm.loadStaticNeighbourhoodCheckList();
              }
            } else if (type == "family") {
              if ($scope.familyCheckList.length == 0) {
                vm.loadStaticFamilyCheckList();
                $scope.familyCheckListTempObj = angular.copy($scope.familyCheckList);
              }
              if (response && response.formSectionResultDTOList.length > 0) {
                angular.forEach($scope.familyCheckList, function (val, index) {
                  angular.forEach(response.formSectionResultDTOList, function (data, key) {
                    if (val.questionValue == data.questionValue) {
                      val.checkBoxDTOList = data.checkBoxDTOList;
                    }
                    if (data.questionValue == "Other" && data.subAnswer && data.subAnswer != "") {
                      val.subAnswer = data.subAnswer;
                      val.others_Checked = true;
                    }
                  });
                });
                $scope.familyCheckListTempObj = angular.copy($scope.familyCheckList);
              } else {
                vm.loadStaticFamilyCheckList();
              }
            } else if (type == "school") {
              if ($scope.schoolCheckList.length == 0) {
                vm.loadStaticSchoolCheckList();
                $scope.schoolCheckListTempObj = angular.copy($scope.schoolCheckList);
              }
              if (response && response.formSectionResultDTOList.length > 0) {
                angular.forEach($scope.schoolCheckList, function (val, index) {
                  angular.forEach(response.formSectionResultDTOList, function (data, key) {
                    if (val.questionValue == data.questionValue) {
                      val.checkBoxDTOList = data.checkBoxDTOList;
                    }
                    if (data.questionValue == "Other" && data.subAnswer && data.subAnswer != "") {
                      val.subAnswer = data.subAnswer;
                      val.others_Checked = true;
                    }
                  });
                });
                $scope.schoolCheckListTempObj = angular.copy($scope.schoolCheckList);
              } else {
                vm.loadStaticSchoolCheckList();
              }
            } else if (type == "religious") {
              if ($scope.religiousCheckList.length == 0) {
                vm.loadStaticReligiousCheckList();
                $scope.religiousCheckListTempObj = angular.copy($scope.religiousCheckList);
              }
              if (response && response.formSectionResultDTOList.length > 0) {
                angular.forEach($scope.religiousCheckList, function (val, index) {
                  angular.forEach(response.formSectionResultDTOList, function (data, key) {
                    if (val.questionValue == data.questionValue) {
                      val.checkBoxDTOList = data.checkBoxDTOList;
                    }
                    if (data.questionValue == "Other" && data.subAnswer && data.subAnswer != "") {
                      val.subAnswer = data.subAnswer;
                      val.others_Checked = true;
                    }
                  });
                });
                $scope.religiousCheckListTempObj = angular.copy($scope.religiousCheckList);
              } else {
                vm.loadStaticReligiousCheckList();
              }
            }
          });
        }
        $scope.retainValue = false;
      }, 500);
      //}
    };

    $scope.setEntityType = function (typeKey, birthFlag, listFlag) {
      angular.forEach(vm.entityTypeList, function (data, key) {
        if (data.key == typeKey && birthFlag == vm.app.GLOBAL.FALSE && listFlag == vm.app.GLOBAL.FALSE) {
          $scope.entity.entityTypeSearchKey = data;
          $scope.entityTypeList.push(data);
        }
        if (data.key == typeKey && birthFlag == vm.app.GLOBAL.TRUE && listFlag == vm.app.GLOBAL.FALSE) {
          $scope.entity.entityTypeSearchKey = data;
        }
        if (data.key == typeKey && birthFlag == vm.app.GLOBAL.FALSE && listFlag == vm.app.GLOBAL.TRUE) {
          $scope.entityTypeList.push(data);
        }
      });
    };

    $scope.birthCertificateEntityRequest = function (ev, id, flag, chosenEntity) {
      $scope.entity = {};
      $scope.entityTypeList = [];
      $scope.inputSearchText = "";
      //Set Entity Type List
      $scope.setEntityType(vm.app.INVESTIGATION.LOCAL_REGISTRY, vm.app.GLOBAL.FALSE, vm.app.GLOBAL.TRUE);
      $scope.setEntityType(vm.app.INVESTIGATION.NATIONAL_REGISTRY, vm.app.GLOBAL.FALSE, vm.app.GLOBAL.TRUE);

      if (chosenEntity != undefined && chosenEntity != null) {
        $scope.entity.entityTypeSearchKey = {};
        if (chosenEntity == vm.app.GLOBAL.NATIONAL_REGISTRY) {
          $scope.setEntityType(vm.app.INVESTIGATION.NATIONAL_REGISTRY, vm.app.GLOBAL.TRUE, vm.app.GLOBAL.FALSE);
        } else {
          $scope.setEntityType(vm.app.INVESTIGATION.LOCAL_REGISTRY, vm.app.GLOBAL.TRUE, vm.app.GLOBAL.FALSE);
        }
      }
      $scope.openNewEntity(ev, id, flag);
    };

    $scope.birthMedicalEntityRequest = function (ev, id, flag) {
      $scope.entity = {};
      $scope.entity.entityTypeSearchKey = {};
      $scope.entityTypeList = [];
      $scope.inputSearchText = "";
      $scope.setEntityType(vm.app.INVESTIGATION.MEDICAL_INSTITUTION, vm.app.GLOBAL.FALSE, vm.app.GLOBAL.FALSE);
      $scope.openNewEntity(ev, id, flag);
    };

    $scope.religiousEntityRequest = function (ev, id, flag) {
      $scope.entity = {};
      $scope.entity.entityTypeSearchKey = {};
      $scope.entityTypeList = [];
      $scope.inputSearchText = "";
      $scope.setEntityType(vm.app.INVESTIGATION.RELIGIOUS_INSTITUTION, vm.app.GLOBAL.FALSE, vm.app.GLOBAL.FALSE);
      $scope.openNewEntity(ev, id, flag);
    };

    $scope.schoolEntityRequest = function (ev, id, flag) {
      $scope.entity = {};
      $scope.entity.entityTypeSearchKey = {};
      $scope.entityTypeList = [];
      $scope.inputSearchText = "";
      $scope.setEntityType(vm.app.INVESTIGATION.SCHOOL, vm.app.GLOBAL.FALSE, vm.app.GLOBAL.FALSE);
      $scope.openNewEntity(ev, id, flag);
    };

    $scope.dnaEntityRequest = function (ev, id, flag) {
      $scope.entity = {};
      $scope.entity.entityTypeSearchKey = {};
      $scope.entityTypeList = [];
      $scope.inputSearchText = "";
      $scope.setEntityType(vm.app.INVESTIGATION.DNA_CENTER, vm.app.GLOBAL.FALSE, vm.app.GLOBAL.FALSE);
      $scope.openNewEntity(ev, id, flag);
    };

    vm.loadStaticBirthCertificateList = function () {
      $scope.birthCertificateCheckList = [
        {
          questionValue: "Declaration timing",
          questionKey: "birth_declaration_time",
          "dataTranslate" : "prospects.AnalystFormQuestion.declarationTiming",	
          checkBoxDTOList: [
            {
              value: "SUPPORTS",
              checked: false
            },
            {
              value: "INCONCLUSIVE",
              checked: false
            },
            {
              value: "DOES NOT SUPPORT",
              checked: false
            },
            {
              value: "NOT APPLICABLE",
              checked: false
            }
          ]
        },
        {
          questionValue: "Civil Registry Verification",
          questionKey: "birth_civil",
          "dataTranslate" : "prospects.AnalystFormQuestion.civilRegistryVerification",
          checkBoxDTOList: [
            {
              value: "SUPPORTS",
              checked: false
            },
            {
              value: "INCONCLUSIVE",
              checked: false
            },
            {
              value: "DOES NOT SUPPORT",
              checked: false
            },
            {
              value: "NOT APPLICABLE",
              checked: false
            }
          ]
        },
        {
          questionValue: "National Registry Verification",
          questionKey: "birth_national",
          "dataTranslate" : "prospects.AnalystFormQuestion.nationalRegistryVerification",
          checkBoxDTOList: [
            {
              value: "SUPPORTS",
              checked: false
            },
            {
              value: "INCONCLUSIVE",
              checked: false
            },
            {
              value: "DOES NOT SUPPORT",
              checked: false
            },
            {
              value: "NOT APPLICABLE",
              checked: false
            }
          ]
        },
        {
          questionValue: "Declarants",
          questionKey: "birth_declarant",
          "dataTranslate" : "prospects.AnalystFormQuestion.declarants",
          checkBoxDTOList: [
            {
              value: "SUPPORTS",
              checked: false
            },
            {
              value: "INCONCLUSIVE",
              checked: false
            },
            {
              value: "DOES NOT SUPPORT",
              checked: false
            },
            {
              value: "NOT APPLICABLE",
              checked: false
            }
          ]
        },
        {
          questionValue: "Documentation Authenticity",
          questionKey: "birth_documentation",
          "dataTranslate" : "prospects.AnalystFormQuestion.documentationAuthenticity",
          checkBoxDTOList: [
            {
              value: "SUPPORTS",
              checked: false
            },
            {
              value: "INCONCLUSIVE",
              checked: false
            },
            {
              value: "DOES NOT SUPPORT",
              checked: false
            },
            {
              value: "NOT APPLICABLE",
              checked: false
            }
          ]
        },
        {
          questionValue: "Other",
          questionKey: "birth_other",
          "dataTranslate" : "prospects.AnalystFormQuestion.other",
          others_Checked: false,
          subAnswer: "",
          checkBoxDTOList: [
            {
              value: "SUPPORTS",
              checked: false
            },
            {
              value: "INCONCLUSIVE",
              checked: false
            },
            {
              value: "DOES NOT SUPPORT",
              checked: false
            },
            {
              value: "NOT APPLICABLE",
              checked: false
            }
          ]
        }
      ];
    };

    vm.loadStaticBirthMedicalRecordList = function () {
      $scope.birthMedicalCheckList = [
        {
          questionValue: "Records Availability",
          questionKey: "birth_medical_record_available",
          "dataTranslate" : "prospects.AnalystFormQuestionMedical.recordsAvailability",
          checkBoxDTOList: [
            {
              value: "SUPPORTS",
              checked: false
            },
            {
              value: "INCONCLUSIVE",
              checked: false
            },
            {
              value: "DOES NOT SUPPORT",
              checked: false
            },
            {
              value: "NOT APPLICABLE",
              checked: false
            }
          ]
        },
        {
          questionValue: "Records Authenticity",
          questionKey: "birth_medical_record_auth",
          "dataTranslate" : "prospects.AnalystFormQuestionMedical.recordsAuthenticity",
          checkBoxDTOList: [
            {
              value: "SUPPORTS",
              checked: false
            },
            {
              value: "INCONCLUSIVE",
              checked: false
            },
            {
              value: "DOES NOT SUPPORT",
              checked: false
            },
            {
              value: "NOT APPLICABLE",
              checked: false
            }
          ]
        },
        {
          questionValue: "Cons. with reported DOB",
          questionKey: "birth_medical_cons_dob",
          "dataTranslate" : "prospects.AnalystFormQuestionMedical.consWithReportedDOB",
          checkBoxDTOList: [
            {
              value: "SUPPORTS",
              checked: false
            },
            {
              value: "INCONCLUSIVE",
              checked: false
            },
            {
              value: "DOES NOT SUPPORT",
              checked: false
            },
            {
              value: "NOT APPLICABLE",
              checked: false
            }
          ]
        },
        {
          questionValue: "Cons. w/ Mother/Gender/etc",
          questionKey: "birth_medical_cons_mg",
          "dataTranslate" : "prospects.AnalystFormQuestionMedical.consW/Mother/Gender/etc",
          checkBoxDTOList: [
            {
              value: "SUPPORTS",
              checked: false
            },
            {
              value: "INCONCLUSIVE",
              checked: false
            },
            {
              value: "DOES NOT SUPPORT",
              checked: false
            },
            {
              value: "NOT APPLICABLE",
              checked: false
            }
          ]
        },
        {
          questionValue: "Midwife Verification",
          questionKey: "birth_medical_Midwife",
          "dataTranslate" : "prospects.AnalystFormQuestionMedical.midwifeVerification",
          checkBoxDTOList: [
            {
              value: "SUPPORTS",
              checked: false
            },
            {
              value: "INCONCLUSIVE",
              checked: false
            },
            {
              value: "DOES NOT SUPPORT",
              checked: false
            },
            {
              value: "NOT APPLICABLE",
              checked: false
            }
          ]
        },
        {
          questionValue: "Other",
          questionKey: "birth_medical_other",
          "dataTranslate" : "prospects.AnalystFormQuestionMedical.other",
          others_Checked: false,
          subAnswer: "",
          checkBoxDTOList: [
            {
              value: "SUPPORTS",
              checked: false
            },
            {
              value: "INCONCLUSIVE",
              checked: false
            },
            {
              value: "DOES NOT SUPPORT",
              checked: false
            },
            {
              value: "NOT APPLICABLE",
              checked: false
            }
          ]
        }
      ];
    };

    vm.loadStaticNeighbourhoodCheckList = function () {
      $scope.neighbourhoodCheckList = [
        {
          questionValue: "Recognition of Photo by Name",
          questionKey: "neighbour_recog",
          "dataTranslate" : "prospects.AnalystFormQuestionCanvas.recognitionOfPhotoByName",
          checkBoxDTOList: [
            {
              value: "SUPPORTS",
              checked: false
            },
            {
              value: "INCONCLUSIVE",
              checked: false
            },
            {
              value: "DOES NOT SUPPORT",
              checked: false
            },
            {
              value: "NOT APPLICABLE",
              checked: false
            }
          ]
        },
        {
          questionValue: "Nickname v. Reported Name",
          questionKey: "neighbour_nickname",
          "dataTranslate" : "prospects.AnalystFormQuestionCanvas.nicknameV.ReportedName",
          checkBoxDTOList: [
            {
              value: "SUPPORTS",
              checked: false
            },
            {
              value: "INCONCLUSIVE",
              checked: false
            },
            {
              value: "DOES NOT SUPPORT",
              checked: false
            },
            {
              value: "NOT APPLICABLE",
              checked: false
            }
          ]
        },
        {
          questionValue: "Neighborhood Cooperation",
          questionKey: "neighbour_cooperation",
          "dataTranslate" : "prospects.AnalystFormQuestionCanvas.neighborhoodCooperation",
          checkBoxDTOList: [
            {
              value: "SUPPORTS",
              checked: false
            },
            {
              value: "INCONCLUSIVE",
              checked: false
            },
            {
              value: "DOES NOT SUPPORT",
              checked: false
            },
            {
              value: "NOT APPLICABLE",
              checked: false
            }
          ]
        },
        {
          questionValue: "Other",
          questionKey: "neighbour_other",
          "dataTranslate" : "prospects.AnalystFormQuestionCanvas.other",
          others_Checked: false,
          subAnswer: "",
          checkBoxDTOList: [
            {
              value: "SUPPORTS",
              checked: false
            },
            {
              value: "INCONCLUSIVE",
              checked: false
            },
            {
              value: "DOES NOT SUPPORT",
              checked: false
            },
            {
              value: "NOT APPLICABLE",
              checked: false
            }
          ]
        }
      ];
    };

    vm.loadStaticFamilyCheckList = function () {
      $scope.familyCheckList = [
        {
          questionValue: "Parent Sibling Info Consistent",
          questionKey: "family_parent",
          "dataTranslate" : "prospects.AnalystFormQuestionFamily.parentSiblingInfoConsistent",
          checkBoxDTOList: [
            {
              value: "SUPPORTS",
              checked: false
            },
            {
              value: "INCONCLUSIVE",
              checked: false
            },
            {
              value: "DOES NOT SUPPORT",
              checked: false
            },
            {
              value: "NOT APPLICABLE",
              checked: false
            }
          ]
        },
        {
          questionValue: "Family Photos",
          questionKey: "family_familyPhoto",
          "dataTranslate" : "prospects.AnalystFormQuestionFamily.familyPhotos",
          checkBoxDTOList: [
            {
              value: "SUPPORTS",
              checked: false
            },
            {
              value: "INCONCLUSIVE",
              checked: false
            },
            {
              value: "DOES NOT SUPPORT",
              checked: false
            },
            {
              value: "NOT APPLICABLE",
              checked: false
            }
          ]
        },
        {
          questionValue: "Other",
          questionKey: "family_other",
          "dataTranslate" : "prospects.AnalystFormQuestionFamily.other",
          others_Checked: false,
          subAnswer: "",
          checkBoxDTOList: [
            {
              value: "SUPPORTS",
              checked: false
            },
            {
              value: "INCONCLUSIVE",
              checked: false
            },
            {
              value: "DOES NOT SUPPORT",
              checked: false
            },
            {
              value: "NOT APPLICABLE",
              checked: false
            }
          ]
        }
      ];
    };

    vm.loadStaticReligiousCheckList = function () {
      $scope.religiousCheckList = [
        {
          questionValue: "Matches Reported Info",
          questionKey: "religious_matchs",
          "dataTranslate" : "prospects.AnalystFormQuestionRelegious.matchesReportedInfo",
          checkBoxDTOList: [
            {
              value: "SUPPORTS",
              checked: false
            },
            {
              value: "INCONCLUSIVE",
              checked: false
            },
            {
              value: "DOES NOT SUPPORT",
              checked: false
            },
            {
              value: "NOT APPLICABLE",
              checked: false
            }
          ]
        },
        {
          questionValue: "Authenticity/Timing",
          questionKey: "religious_auth",
          "dataTranslate" : "prospects.AnalystFormQuestionRelegious.authenticity/Timing",
          checkBoxDTOList: [
            {
              value: "SUPPORTS",
              checked: false
            },
            {
              value: "INCONCLUSIVE",
              checked: false
            },
            {
              value: "DOES NOT SUPPORT",
              checked: false
            },
            {
              value: "NOT APPLICABLE",
              checked: false
            }
          ]
        },
        {
          questionValue: "Other",
          questionKey: "religious_other",
          "dataTranslate" : "prospects.AnalystFormQuestionRelegious.other",
          others_Checked: false,
          subAnswer: "",
          checkBoxDTOList: [
            {
              value: "SUPPORTS",
              checked: false
            },
            {
              value: "INCONCLUSIVE",
              checked: false
            },
            {
              value: "DOES NOT SUPPORT",
              checked: false
            },
            {
              value: "NOT APPLICABLE",
              checked: false
            }
          ]
        }
      ];
    };

    vm.loadStaticSchoolCheckList = function () {
      $scope.schoolCheckList = [
        {
          questionValue: "Availability of Records",
          questionKey: "school_availability",
          "dataTranslate" : "prospects.AnalystFormQuestionSchool.availabilityOfRecords",
          checkBoxDTOList: [
            {
              value: "SUPPORTS",
              checked: false
            },
            {
              value: "INCONCLUSIVE",
              checked: false
            },
            {
              value: "DOES NOT SUPPORT",
              checked: false
            },
            {
              value: "NOT APPLICABLE",
              checked: false
            }
          ]
        },
        {
          questionValue: "8th Grade National Exam",
          questionKey: "school_eigth_grade",
          "dataTranslate" : "prospects.AnalystFormQuestionSchool.8thGradeNationalExam",
          checkBoxDTOList: [
            {
              value: "SUPPORTS",
              checked: false
            },
            {
              value: "INCONCLUSIVE",
              checked: false
            },
            {
              value: "DOES NOT SUPPORT",
              checked: false
            },
            {
              value: "NOT APPLICABLE",
              checked: false
            }
          ]
        },
        {
          questionValue: "Authenticity of Records",
          questionKey: "school_auth",
          "dataTranslate" : "prospects.AnalystFormQuestionSchool.authenticityOfRecords",
          checkBoxDTOList: [
            {
              value: "SUPPORTS",
              checked: false
            },
            {
              value: "INCONCLUSIVE",
              checked: false
            },
            {
              value: "DOES NOT SUPPORT",
              checked: false
            },
            {
              value: "NOT APPLICABLE",
              checked: false
            }
          ]
        },
        {
          questionValue: "DOB Consistency w/ Records",
          questionKey: "school_dob_cons",
          "dataTranslate" : "prospects.AnalystFormQuestionSchool.DOBConsistencyw/Records",
          checkBoxDTOList: [
            {
              value: "SUPPORTS",
              checked: false
            },
            {
              value: "INCONCLUSIVE",
              checked: false
            },
            {
              value: "DOES NOT SUPPORT",
              checked: false
            },
            {
              value: "NOT APPLICABLE",
              checked: false
            }
          ]
        },
        {
          questionValue: "Name Consistency w/ Records",
          questionKey: "school_name_cons",
          "dataTranslate" : "prospects.AnalystFormQuestionSchool.nameConsistencyw/Records",
          checkBoxDTOList: [
            {
              value: "SUPPORTS",
              checked: false
            },
            {
              value: "INCONCLUSIVE",
              checked: false
            },
            {
              value: "DOES NOT SUPPORT",
              checked: false
            },
            {
              value: "NOT APPLICABLE",
              checked: false
            }
          ]
        },
        {
          questionValue: "Recognition of Employees",
          questionKey: "school_recog",
          "dataTranslate" : "prospects.AnalystFormQuestionSchool.recognitionOfEmployees",
          checkBoxDTOList: [
            {
              value: "SUPPORTS",
              checked: false
            },
            {
              value: "INCONCLUSIVE",
              checked: false
            },
            {
              value: "DOES NOT SUPPORT",
              checked: false
            },
            {
              value: "NOT APPLICABLE",
              checked: false
            }
          ]
        },
        {
          questionValue: "Recognition of Classmates",
          questionKey: "school_recog_class",
          "dataTranslate" : "prospects.AnalystFormQuestionSchool.recognitionOfClassmates",
          checkBoxDTOList: [
            {
              value: "SUPPORTS",
              checked: false
            },
            {
              value: "INCONCLUSIVE",
              checked: false
            },
            {
              value: "DOES NOT SUPPORT",
              checked: false
            },
            {
              value: "NOT APPLICABLE",
              checked: false
            }
          ]
        },
        {
          questionValue: "Other",
          questionKey: "school_other",
          "dataTranslate" : "prospects.AnalystFormQuestionSchool.other",
          others_Checked: false,
          subAnswer: "",
          checkBoxDTOList: [
            {
              value: "SUPPORTS",
              checked: false
            },
            {
              value: "INCONCLUSIVE",
              checked: false
            },
            {
              value: "DOES NOT SUPPORT",
              checked: false
            },
            {
              value: "NOT APPLICABLE",
              checked: false
            }
          ]
        }
      ];
    };

    $scope.saveAnalystFormTypeCheckList = function (type, isFromSection, isSaveFormAudit) {
      if ($scope.investigationFormType == vm.app.INVESTIGATION.ANALYST_FORM) {
        var postCheckList = [];
        var sectionKey;
        var isSaveFormAuditFlag = true;
        var isSimilar = true;

        if (type == "birthCertificate") {
          sectionKey = vm.app.REF_TERM.BIRTH_CERTIFICATE;
          postCheckList = angular.copy($scope.birthCertificateCheckList);
          if (
            angular.isDefined($scope.birthCertificateCheckList) &&
            angular.isDefined($scope.birthCertificateCheckListTempObj) &&
            !angular.equals($scope.birthCertificateCheckList, $scope.birthCertificateCheckListTempObj)
          ) {
            isSimilar = false;
            $rootScope.warningPopup.show = true;
            // $scope.birthCertificateCheckList = null;
          }
        } else if (type == "birthMedical") {
          sectionKey = vm.app.REF_TERM.BIRTH_AND_MEDICAL_RECORDS;
          postCheckList = angular.copy($scope.birthMedicalCheckList);
          if (
            angular.isDefined($scope.birthMedicalCheckList) &&
            angular.isDefined($scope.birthMedicalCheckListTempObj) &&
            !angular.equals($scope.birthMedicalCheckList, $scope.birthMedicalCheckListTempObj)
          ) {
            isSimilar = false;
            $rootScope.warningPopup.show = true;
          }
        } else if (type == "neighbourhood") {
          sectionKey = vm.app.REF_TERM.NEIGHBORHOOD_CANVAS;
          postCheckList = angular.copy($scope.neighbourhoodCheckList);
          if (
            angular.isDefined($scope.neighbourhoodCheckList) &&
            angular.isDefined($scope.neighbourhoodCheckListTempObj) &&
            !angular.equals($scope.neighbourhoodCheckList, $scope.neighbourhoodCheckListTempObj)
          ) {
            isSimilar = false;
            $rootScope.warningPopup.show = true;
          }
        } else if (type == "family") {
          sectionKey = vm.app.REF_TERM.FAMILY_INFORMATION;
          postCheckList = angular.copy($scope.familyCheckList);
          if (
            angular.isDefined($scope.familyCheckList) &&
            angular.isDefined($scope.familyCheckListTempObj) &&
            !angular.equals($scope.familyCheckList, $scope.familyCheckListTempObj)
          ) {
            isSimilar = false;
            $rootScope.warningPopup.show = true;
          }
        } else if (type == "school") {
          sectionKey = vm.app.REF_TERM.SCHOOL_INFORMATION;
          postCheckList = angular.copy($scope.schoolCheckList);
          if (
            angular.isDefined($scope.schoolCheckList) &&
            angular.isDefined($scope.schoolCheckListTempObj) &&
            !angular.equals($scope.schoolCheckList, $scope.schoolCheckListTempObj)
          ) {
            isSimilar = false;
            $rootScope.warningPopup.show = true;
          }
        } else if (type == "religious") {
          sectionKey = vm.app.REF_TERM.RELIGIOUS_INSTITUTIONS;
          postCheckList = angular.copy($scope.religiousCheckList);
          if (
            angular.isDefined($scope.religiousCheckList) &&
            angular.isDefined($scope.religiousCheckListTempObj) &&
            !angular.equals($scope.religiousCheckList, $scope.religiousCheckListTempObj)
          ) {
            isSimilar = false;
            $rootScope.warningPopup.show = true;
          }
        }
        var formId = $scope.investigationFormId;
        if (angular.isDefined(isSaveFormAudit) && isSaveFormAudit == false) isSaveFormAuditFlag = isSaveFormAudit;
        if (!isSimilar && (isFromSection == true || $scope.isOwner == true)) {
          var data = InvestigationFormService.saveIndividualAnalystSectionList(
            sectionKey,
            formId,
            isSaveFormAuditFlag,
            postCheckList
          );
          data.then(function (response) {
            //Set the checklist temp object
            $scope[type + "CheckListTempObj"] = angular.copy($scope[type + "CheckList"]);
          });
        }
      }
    };

    vm.birthCertificateCall = function () {
      // $rootScope.warningPopup.show = false;
      if ($scope.birthCertificateCheckList == undefined || $scope.birthCertificateCheckList.length == 0) {
        vm.getAnalystSectionList("birthCertitficate", vm.selectAllBCType);
      }
      vm.loadSectionFormByType("bithcerticateForm");
      //$scope.birthCertificateCheckListTempObj = angular.copy($scope.birthCertificateCheckList);
    };

    vm.birthMedicalCall = function () {
      if ($scope.birthMedicalCheckList == undefined || $scope.birthMedicalCheckList.length == 0) {
        vm.getAnalystSectionList("birthMedical", vm.selectAllBirthMedicalRecordType);
      }
      vm.loadSectionFormByType("birthAndMedicalForm");
      //$scope.birthMedicalCheckListTempObj = angular.copy($scope.birthMedicalCheckList);
    };

    vm.neighbourhoodCall = function () {
      if ($scope.neighbourhoodCheckList == undefined || $scope.neighbourhoodCheckList.length == 0) {
        vm.getAnalystSectionList("neighbourhood", vm.selectAllNeighbourhoodType);
      }
      vm.loadSectionFormByType("neighbourhoodCanvasForm");
      //$scope.neighbourhoodCheckListTempObj = angular.copy($scope.neighbourhoodCheckList);
    };

    vm.familyCall = function () {
      if ($scope.familyCheckList == undefined || $scope.familyCheckList.length == 0) {
        vm.getAnalystSectionList("family", vm.selectAllFamilyType);
      }
      vm.loadSectionFormByType("familyInfoForm");
      //$scope.familyCheckListTempObj = angular.copy($scope.familyCheckList);
    };

    vm.schoolCall = function () {
      if ($scope.schoolCheckList == undefined || $scope.schoolCheckList.length == 0) {
        vm.getAnalystSectionList("school", vm.selectAllSchoolType);
      }
      vm.loadSectionFormByType("schoolInfoForm");
      //$scope.schoolCheckListTempObj = angular.copy($scope.schoolCheckList);
    };

    vm.religiousCall = function () {
      if ($scope.religiousCheckList == undefined || $scope.religiousCheckList.length == 0) {
        vm.getAnalystSectionList("religious", vm.selectAllReligiousType);
      }
      vm.loadSectionFormByType("religiousInstitutionForm");
      //$scope.religiousCheckListTempObj = angular.copy($scope.religiousCheckList);
    };

    vm.summarycall = function () {
      if ($scope.birthCertificateCheckList == undefined || $scope.birthCertificateCheckList.length == 0) {
        vm.getAnalystSectionList("birthCertitficate", vm.selectAllBCType);
      }
      if ($scope.birthMedicalCheckList == undefined || $scope.birthMedicalCheckList.length == 0) {
        vm.getAnalystSectionList("birthMedical", vm.selectAllBirthMedicalRecordType);
      }
      if ($scope.neighbourhoodCheckList == undefined || $scope.neighbourhoodCheckList.length == 0) {
        vm.getAnalystSectionList("neighbourhood", vm.selectAllNeighbourhoodType);
      }
      if ($scope.familyCheckList == undefined || $scope.familyCheckList.length == 0) {
        vm.getAnalystSectionList("family", vm.selectAllFamilyType);
      }
      if ($scope.schoolCheckList == undefined || $scope.schoolCheckList.length == 0) {
        vm.getAnalystSectionList("school", vm.selectAllSchoolType);
      }
      if ($scope.religiousCheckList == undefined || $scope.religiousCheckList.length == 0) {
        vm.getAnalystSectionList("religious", vm.selectAllReligiousType);
      }
      $timeout(function () {
        vm.collapseAll(9);
        vm.loadSectionFormByType("summaryForm");
        vm.sideNavClose();
      }, 500);
    };

    vm.submitInvestigationForm = function (ev, id) {
      $mdDialog.show({
        contentElement: angular.element("#" + id),
        targetEvent: ev,
        clickOutsideToClose: false,
        escapeToClose: false
      });
    };
    vm.gobacktoForm = function () {
      $mdDialog.cancel();
      $scope.recordFlag = true;
      $scope.fileUploadLoader = false;
    };

    vm.openToComplete = function (index, formName, event) {
      vm.collapseAll(index); // First Call to close all the SideNav
      vm.loadSectionFormByType(formName);
      vm.collapseAll(index); // Second Call to Open the SideNav
      vm.sideNavClose();
      var msg = "provideDetailsPopup";
      vm.submitInvestigationForm(event, msg);
    };

    $scope.summaryValidation = function (event) {
      var i, j;
      for (i = 0; i < $scope.birthCertificateCheckList.length; i++) {
        var valid = false;
        for (j = 0; j < 4; j++) {
          if ($scope.birthCertificateCheckList[i].checkBoxDTOList[j].checked == true) {
            valid = true;
            break;
          }
        }
        if (
          !valid &&
          i == $scope.birthCertificateCheckList.length - 1 &&
          !$scope.birthCertificateCheckList[i].others_Checked
        ) {
          valid = true;
        }
        if (!valid) {
          vm.openToComplete(0, "bithcerticateForm", event);
          return false;
        }
      }

      for (i = 0; i < $scope.birthMedicalCheckList.length; i++) {
        var valid = false;
        for (j = 0; j < 4; j++) {
          if ($scope.birthMedicalCheckList[i].checkBoxDTOList[j].checked == true) {
            valid = true;
            break;
          }
        }
        if (!valid && i == $scope.birthMedicalCheckList.length - 1 && !$scope.birthMedicalCheckList[i].others_Checked) {
          valid = true;
        }
        if (!valid) {
          vm.openToComplete(1, "birthAndMedicalForm", event);
          return false;
        }
      }

      for (i = 0; i < $scope.neighbourhoodCheckList.length; i++) {
        var valid = false;
        for (j = 0; j < 4; j++) {
          if ($scope.neighbourhoodCheckList[i].checkBoxDTOList[j].checked == true) {
            valid = true;
            break;
          }
        }
        if (
          !valid &&
          i == $scope.neighbourhoodCheckList.length - 1 &&
          !$scope.neighbourhoodCheckList[i].others_Checked
        ) {
          valid = true;
        }
        if (!valid) {
          vm.openToComplete(2, "neighbourhoodCanvasForm", event);
          return false;
        }
      }

      for (i = 0; i < $scope.familyCheckList.length; i++) {
        var valid = false;
        for (j = 0; j < 4; j++) {
          if ($scope.familyCheckList[i].checkBoxDTOList[j].checked == true) {
            valid = true;
            break;
          }
        }
        if (!valid && i == $scope.familyCheckList.length - 1 && !$scope.familyCheckList[i].others_Checked) {
          valid = true;
        }
        if (!valid) {
          vm.openToComplete(3, "familyInfoForm", event);
          return false;
        }
      }

      for (i = 0; i < $scope.schoolCheckList.length; i++) {
        var valid = false;
        for (j = 0; j < 4; j++) {
          if ($scope.schoolCheckList[i].checkBoxDTOList[j].checked == true) {
            valid = true;
            break;
          }
        }
        if (!valid && i == $scope.schoolCheckList.length - 1 && !$scope.schoolCheckList[i].others_Checked) {
          valid = true;
        }
        if (!valid) {
          vm.openToComplete(4, "schoolInfoForm", event);
          return false;
        }
      }

      for (i = 0; i < $scope.religiousCheckList.length; i++) {
        var valid = false;
        for (j = 0; j < 4; j++) {
          if ($scope.religiousCheckList[i].checkBoxDTOList[j].checked == true) {
            valid = true;
            break;
          }
        }
        if (!valid && i == $scope.religiousCheckList.length - 1 && !$scope.religiousCheckList[i].others_Checked) {
          valid = true;
        }
        if (!valid) {
          vm.openToComplete(5, "religiousInstitutionForm", event);
          return false;
        }
      }

      if (valid) {
        var msg = "formConformPopup";
        $rootScope.validationCompleted(event, msg);
      }
    };

    vm.clearfield = function (type) {
      vm.emptyChecked = [
        {
          value: "SUPPORTS",
          checked: false
        },
        {
          value: "INCONCLUSIVE",
          checked: false
        },
        {
          value: "DOES NOT SUPPORT",
          checked: false
        },
        {
          value: "NOT APPLICABLE",
          checked: false
        }
      ];
      if (type == "BIRTH_CERTIFICATE") {
        $scope.birthCertificateCheckList[5].subAnswer = "";
        $scope.birthCertificateCheckList[5].checkBoxDTOList = vm.emptyChecked;
      } else if (type == "BIRTH_MEDICAL") {
        $scope.birthMedicalCheckList[5].subAnswer = "";
        $scope.birthMedicalCheckList[5].checkBoxDTOList = vm.emptyChecked;
      } else if (type == "NEIGHBORHOOD_CANVASS") {
        $scope.neighbourhoodCheckList[3].subAnswer = "";
        $scope.neighbourhoodCheckList[3].checkBoxDTOList = vm.emptyChecked;
      } else if (type == "FAMILY_INFORMATION") {
        $scope.familyCheckList[2].subAnswer = "";
        $scope.familyCheckList[2].checkBoxDTOList = vm.emptyChecked;
      } else if (type == "SCHOOL_INFORMATION") {
        $scope.schoolCheckList[7].subAnswer = "";
        $scope.schoolCheckList[7].checkBoxDTOList = vm.emptyChecked;
      } else if (type == "RELIGIOUS_INSTITUTION") {
        $scope.religiousCheckList[2].subAnswer = "";
        $scope.religiousCheckList[2].checkBoxDTOList = vm.emptyChecked;
      }
    };

    $scope.analystInit = function () {
      if ($sessionStorage.formType == vm.app.INVESTIGATION.ANALYST_FORM) {
        if ($sessionStorage.selectedSectionIndex != 0) {
          var index = $sessionStorage.selectedSectionIndex;
          $scope.collapseAll(index);
        } else {
          $scope.collapseAll(0);
        }
      }
    };

    $scope.analystInit();

    $scope.getSideComments = function (formId, section, index) {
      if ($sessionStorage.formType == vm.app.INVESTIGATION.ANALYST_FORM) {
        $scope.sectionDTO = [];
        $scope.sideCommentTrigger = false;
        var data = SideCommentsDirectiveService.getSideComments(formId, section);
        //$scope.sideComments = {};
        data.then(
          function (response) {
            $scope.sideComments = response; //angular.toJson(response);
            angular.forEach($scope.sideComments.formSectionDTOs, function (data, key) {
              if (data.accordionIndex == index) {
                //$scope.sectionDTO = [];
                $scope.sectionDTO = data;
              }
            });
            $scope.sideCommentInitTrigger();
            if (section == vm.app.REF_TERM.FAMILY_INFORMATION) {
              $scope.getPhotoSectionDTO();
            }
          },
          function (reason) {
            return reason;
          }
        );
      }
    };

    $scope.getSectionDTO = function (index) {
      $scope.sideCommentTrigger = false;
      $scope.sectionDTO = [];
      angular.forEach($scope.sideComments.formSectionDTOs, function (data, key) {
        if (data.accordionIndex == index) {
          $scope.sectionDTO = data;
        }
      });
      $scope.sideCommentInitTrigger();
    };

    $scope.sideCommentInitTrigger = function () {
      $scope.sideCommentTrigger = true;
    };

    $scope.sideCommentCloseTrigger = function () {
      $scope.sideCommentTrigger = !$scope.sideCommentTrigger;
    };

    $scope.formViewCall = function (index) {
      $scope.sideCommentTrigger = false;
      $scope.getSideComments($scope.investigationFormId, $scope.currentSection, index);
    };

    /**
     * Method to delete side comments by section type and accodionIndex
     */
    $scope.deleteSidecommentsByIndex = function (sectionType, accordionIndex) {
      var data = SideCommentsDirectiveService.getSideCommentsBySectionIndex(
        $scope.investigationFormId,
        sectionType,
        accordionIndex
      );
      data.then(function (response) {
        $scope.sideComment = response;
        if ($scope.sideComment.investigationFormId != null) {
          $q.all([
            SideCommentsDirectiveService.deleteCommentsByIndex($scope.investigationFormId, sectionType, accordionIndex)
          ]).then(function (value) {
            if (accordionIndex != 0) {
              --accordionIndex;
            }
            $scope.getSideComments($scope.investigationFormId, sectionType, accordionIndex);
          });
        }
      });
    };

    $scope.getPhotoSectionDTO = function () {
      $scope.photoSectionDTO = [];
      angular.forEach($scope.sideComments.formSectionDTOs, function (data, key) {
        if (data.accordionIndex == vm.app.INVESTIGATION_FORM.FAMILY_QUESTIONS.PHOTO_INDEX) {
          $scope.photoSectionDTO = data;
        }
      });
      $scope.familyPhotoCommentTrigger = true;
    };

    //Form Request
    $scope.requestStatus = function (requestedEntity) {
      var data = InvestigationFormService.getApprovedEntityProfile(requestedEntity.answerId);
      data.then(function (response) {
        if (response.isApproved) {
          requestedEntity.answer = response.entityName;
          requestedEntity.answerId = response.entityId;
          requestedEntity.requested = false;
          requestedEntity.subAnswer = true;
          $scope.checkValid = true; //This flag is used to maintain the approved message for 500ms - used in $scope.hideApprovedMessage()
          $timeout(function () {
            $scope.checkValid = false;
          }, 500);
        }
      });
      return requestedEntity;
    };

    /**
     * This function will hide the Entity approved message on its first change.
     */
    $scope.hideApprovedMessage = function (subAnswerFlag) {
      if (subAnswerFlag && !$scope.checkValid) {
        subAnswerFlag = undefined;
      }
      return subAnswerFlag;
    };
    // if the entity is choosed from dropdown but entity is in pending status
    $scope.isEntityApproved = function (value, isEntityValueChanged) {
      if (value != undefined) {
        $scope.entityChanged = isEntityValueChanged;
        var data = InvestigationFormService.getApprovedEntityProfile(value);
        data.then(function (response) {
          if (response.isApproved) {
            $rootScope.notApproved = false;
          } else {
            $rootScope.notApproved = true;
          }
        });
      } else {
        $rootScope.notApproved = false;
        $scope.entityChanged = false;
      }
    };
    function convertLocalDateToServer(date) {
      if (date) {
        return $filter("date")(date, "MMMM dd, yyyy hh:mm a");
      } else {
        return null;
      }
    }

    $scope.updateLastEditTime = function (lastEditTime) {
      $scope.lastEditTime = convertLocalDateToServer(lastEditTime);
      vm.isLastEditVisible = true;
    };

    vm.getEntityTypeList = function () {
      var entityTypeData = CommonService.getRefTermList(vm.app.REF_TERM.ENTITY_PROFILE);
      entityTypeData.then(function (response) {
        vm.entityTypeList = response;
      });
    };
    vm.getEntityTypeList();

    // To enable the delete button flag after 200 ms
    $scope.enableDeleteButton = function () {
      $timeout(function () {
        $scope.deleteLoader.flag = false;
      }, 200);
    };

    // Make flag to indicate there is no changes in Upload documents in the current form.

    $scope.uploadFileStatusUpdated = function () {
      $scope.uploadedFile = false;
    };

    $scope.clearCKEditor = function () {
      var instance = {};
      for (instance in CKEDITOR.instances) {
        CKEDITOR.instances[instance].updateElement();
      }
      CKEDITOR.instances[instance].setData("");
    };

    // To change String to Date format
    $scope.datePickerFormat = function (datePickerValue) {
      if (datePickerValue && datePickerValue != "") {
        return new Date(datePickerValue);
      }
    };
  }
})();
