(function() {
    'use strict';

    var lariDevApp = angular
        .module('lariAidApp')
        lariDevApp.controller('UserProspectsController', UserProspectsController);
    UserProspectsController.$inject = ['$scope','$window', '$mdSidenav','Principal','$state','$mdMedia','$stateParams','APP','$timeout','tabservice','$mdDialog','BaseFactory','UserProfileService','$element','UserService','CommonService','$cookies','notifications'];
    function UserProspectsController ($scope,$window, $mdSidenav,Principal,$state,$mdMedia,$stateParams,APP,$timeout,tabservice,$mdDialog,BaseFactory,UserProfileService,$element,UserService,CommonService,$cookies,notifications) {
    	var vm=this;
    	vm.back = back;
    	vm.$state =$state;
    	vm.user = $stateParams; 
    	vm.media = $mdMedia;
    	vm.APP = APP;
    	vm.title = $state.includes('users');
   	    vm.titleName = vm.APP.STATES.USERS; 
   	    vm.emailDisable = false;
    	$scope.updateUserName = function(firstName,lastName){
    		vm.userName = firstName + " "+lastName;
    	};
    	$scope.userRole = function(userRole){
    		vm.userRole = userRole;
    	};
    	$scope.userActivated = function(activated){
    		vm.userActivate = activated;
    	};
   	    
   	 vm.stateProfileDetails = function(){
  		$state.go("userprofile",vm.user);
  	};
  	vm.stateActivity = function(){
  		$state.go("useractivity",vm.user);
  	};
    	// Set the active md tab while refresh the page
    	
    	vm.checkUserIndex = function(){
    		vm.userDetailsTabIndex = tabservice.getIndex();
    	};
    	
    	vm.checkUserIndex();
    	
    	$scope.WindowPopupHeight=window.innerHeight;
    	function back(){
    		$state.go(vm.user.userType);
    	};
    	
    	vm.toggleLeft = toggleLeft('right');
     	
    	function toggleLeft(navID) {
     	      return function() {
     	        $mdSidenav(navID).toggle()
     	      };
    	};
     	    
    	vm.prospectsInit = function(){
    		vm.currentState = $state.current.name;
 	    	vm.loogedUserDetails =  $cookies.getObject('sessionDetails');
     		var loggedUser = vm.loogedUserDetails.authorities[0];
     		//Read_only user has all the access as Manager user in USER page except to add a non-registered user
     		if(loggedUser == vm.APP.GLOBAL.ROLE_MANAGER){
     			vm.loggedUserRole = vm.APP.GLOBAL.MANAGER;
     		}
     		else if(loggedUser = vm.APP.GLOBAL.ROLE_ADMIN){
     			vm.loggedUserRole = vm.APP.GLOBAL.ADMIN;
     		}
			else if(loggedUser = vm.APP.GLOBAL.ROLE_INVESTIGATOR){
				vm.loggedUserRole = vm.APP.GLOBAL.INVESTIGATOR;	
			}
			else if(loggedUser = vm.APP.GLOBAL.ROLE_ANALYST){
				vm.loggedUserRole = vm.APP.GLOBAL.ANALYST;
			}
			else if(loggedUser == vm.APP.GLOBAL.ROLE_READ_ONLY){
     			vm.loggedUserRole = vm.APP.GLOBAL.READ_ONLY;
			}
 	    };
    	
//EDIT INVESTIGATOR POPUP
     	
     	vm.openEditPopup = function(ev,id,flag){
     		vm.investigator = {};
            vm.popupTitleFlag = flag;
            vm.existFlag = false;
            vm.saveDisableFlag = false;
            vm.updateExistFlag = false;
            vm.updateDisableFlag = false;
     		if(vm.user != null){
            	vm.investigatorDetails(vm.user.id);
            }
     		
     		/*$mdDialog.show({	
     			targetEvent: ev,
                controller: function () {
                    return vm;
                },
                controllerAs: 'vm',
                templateUrl: '/app/user/external/addEditInvestigator.popup.html'
   	    	});  */  
     		$mdDialog.show({   	    		
     			targetEvent: ev,
     			contentElement:'#'+id,
     			parent: angular.element(document.body)
   	    	});
     	};
     	
     	vm.isFabOpen = false;
     	
     	vm.searchLocations = function(searchKey){
	  		 if(searchKey){
	  			vm.locationLoader = true;
	  			var data =  CommonService.getRefTermList(vm.APP.REF_TERM.COUNTRY,searchKey);
		  	  	data.then(function(response){
		        	vm.locationVO = response;
		        	vm.locationLoader = false;
		  	  	});
	  		 }
	  	 }
     	
     	vm.initLanguage = function(){
   	    	var data =  BaseFactory.getLanguagesList();
    		data.then(function(response){
   	        	vm.languagesVO = response;
   	        });
   	    };
   	    
   	    vm.clearLocation = function(searchKey,listValue){    		
   	    	vm[searchKey] = undefined;
     		vm[listValue] = undefined;
   	    };
   	    
   	    vm.cancel = function(){
   	    	$mdDialog.hide();
   	    };
   	    
	   	 $element.find('input').on('keydown', function(ev) {
	         ev.stopPropagation();
	     });
     	     	
     	vm.investigatorDetails = function(userId){
     		vm.investigator = {};
     		//Set the investigator object to avoid unwanted update call
     		vm.oldInvestigatorObj = {};
     		vm.oldInvestigatorObj.language = [];
	   	    vm.oldInvestigatorObj.phoneNumber = [];
	   	    vm.oldInvestigatorObj.userCountryDTO = {};

        	if(userId){
        		var data = UserProfileService.getInvestigatorDetails(userId);
        		  data.then(function(response){
        			  vm.userProfileLoader = false;
        			  
        			  vm.investigator.firstName = response.firstName;
        			  vm.investigator.lastName = response.lastName;
        			  vm.investigator.emailAddress = response.emailAddress;
        			  
        			  //Set the investigator object to avoid unwanted update call
        			  vm.oldInvestigatorObj.firstName = response.firstName;
        			  vm.oldInvestigatorObj.lastName = response.lastName;
        			  vm.oldInvestigatorObj.emailAddress = response.emailAddress;
        			  vm.oldInvestigatorObj.id = vm.user.id;
        			  
        			  if(response.userCountryDTO){
        				  vm.investigator.comment = response.userCountryDTO.comment;
        				  //Set the investigator object to avoid unwanted update call
        				  vm.oldInvestigatorObj.userCountryDTO.countryId = response.userCountryDTO.countryId;
        				  vm.oldInvestigatorObj.userCountryDTO.comment = response.userCountryDTO.comment;
        			  }
        			  
        			  
        			  if(response.userCountryDTO){
        				  var country = {};
            			  country.id = response.userCountryDTO.countryId;
            			  country.name = response.userCountryDTO.countryName;
            			  vm.investigator.country = country;
        			  }
        			  
        			  if(response.phoneNumber != null){
        				  angular.forEach(response.phoneNumber, function(data, key){        				  
            				  vm.investigator.phoneNumber = data.value;
            				  //Set the investigator object to avoid unwanted update call
            				  var phone = {};
            				  phone.value = data.value;
            				  phone.phoneType = vm.APP.USER.PHONETYPE;
            				  phone.phoneEntityType = vm.APP.USER.ENTITYTYPE;
            				  vm.oldInvestigatorObj.phoneNumber.push(phone);  
            			  }); 
        			  }        			     
        			  
        			  if(response.language){
        				  vm.investigator.language = [];
            			  angular.forEach(response.language, function(data, key){
            				  var langObj = {};
            				  langObj.id = data.languageId;
            				  langObj.name = data.languageName;
            				  vm.investigator.language.push(langObj);
            				  vm.oldInvestigatorObj.language = response.language;
            			  });
        			  }
        			  
        		  }, function(reason) {
        		return reason;
        		});
        	}
        };
        
        vm.saveInvestigator = function(investigator,addInvestigatorForm){  
			if(addInvestigatorForm.$valid && investigator){
				vm.investigatorObj = {};
		   	    vm.investigatorObj.language = [];
		   	    vm.investigatorObj.phoneNumber = [];
		   	    vm.investigatorObj.userCountryDTO = {};
				vm.updateDisableFlag = true;
				var isPutJsonSimilar = false;
				
				if(vm.user != null){
					vm.investigatorObj.id = vm.user.id;
				}
				
				if(investigator.country != null){
					vm.investigatorObj.userCountryDTO.countryId = investigator.country.id;
					vm.investigatorObj.userCountryDTO.comment = investigator.comment;
				}
				
				angular.forEach(investigator.language, function(obj, index) {
					var lang = {};
					lang.languageId = obj.id;
					vm.investigatorObj.language.push(lang);				
	    		});		
				
				if(investigator.phoneNumber != null) {
					var phone = {};
					phone.value = investigator.phoneNumber;
					phone.phoneType = vm.APP.USER.PHONETYPE;
					phone.phoneEntityType = vm.APP.USER.ENTITYTYPE;
					vm.investigatorObj.phoneNumber.push(phone);  
				}
				
				vm.investigatorObj.firstName = investigator.firstName;
				vm.investigatorObj.lastName = investigator.lastName;
				vm.investigatorObj.emailAddress = investigator.emailAddress;
				
				if(!angular.isUndefined(vm.oldInvestigatorObj)){
					if(angular.equals(vm.investigatorObj, vm.oldInvestigatorObj)){
						isPutJsonSimilar = true;
					}
				}
				
				if(!isPutJsonSimilar) {
					var data =  UserService.updateInvestigator(vm.investigatorObj);
					data.then(function(response){		
						$mdDialog.hide();
						$state.transitionTo($state.current, $stateParams, {
						    reload: true,
						    inherit: false,
						    notify: true
						});
						notifications.showSuccess({message: vm.APP.LIBRARY.USER_EDIT});
		   	        }, function(reason) {
		   	        	if(reason.status == 409) {
		   	        		vm.updateExistFlag = true;
		   	        		vm.updateDisableFlag = false;
		   	        	} else {
		   	        		notifications.showError({message: vm.APP.LIBRARY.USER_EDIT_PROBLEM});
		   	        	}
		   	        	$timeout(function(){
		   	        		vm.updateExistFlag = false;
		   	        	},3000);
		   	        });
				} else {
					$mdDialog.hide();
				}
			}
			
    	};
    	
/*    	//DELETE POPUP
    	
    	vm.openDeletePopup = function(ev,id){
    		
    		if(vm.user != null){
    			vm.investigatorActiveCaseCount(vm.user.id);
            }
     		
     		$mdDialog.show({    			
     			targetEvent: ev,
                controller: function () {
                    return vm;
                },
                controllerAs: 'vm',
                templateUrl: '/app/user/external/deleteInvestigatorPopup.html'
   	    	});     		
     	};
     	
     	vm.investigatorActiveCaseCount = function(userId){
     		if(userId){
     			var data = UserProfileService.getInvestigatorActiveCaseCount(userId);
     			data.then(function(response){
     				vm.activeCaseCount = response;
     			});     			
     		};
     	};
     	
     	vm.deleteInvestigator = function(userId){
     		if(userId){
     			var data = UserProfileService.deleteInvestigatorDetails(userId);
     			data.then(function(response){
     				vm.activeCaseCount = response;
     			});     			
     		};
     	};*/

    }
})();
