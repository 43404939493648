(function() {
    'use strict';

    angular
        .module('lariAidApp')
        .service('LibraryRequestService', LibraryRequestService);

    LibraryRequestService.$inject = ['$resource','restService','$q','$rootScope'];
    
    function LibraryRequestService ($resource,restService,$q,$rootScope) {
    	
    	this.getRequestFilterList = function(formRequest){
    		var deferred = $q.defer();
    		var url = "library/request/status/count?formRequest="+formRequest;
    		var data = restService.restCall('', url, 'GET');
    		data.$promise.then(function(response){
    			deferred.resolve(response);
    		},
    		function (error) {
    			deferred.reject('ERROR');
    		});
    		return deferred.promise;
    	};
    	
    	this.getRequestLists = function(filterObj){
    		var deferred = $q.defer();
    		var url = "library/request";
    		var data = restService.restCall(filterObj, url, 'POST');
    		data.$promise.then(function(response){
    			deferred.resolve(response);
    		},
    		function (error) {
    			deferred.reject('ERROR');
    		});
    		return deferred.promise;
    	}; 
    	
    	this.getRequestProfileById = function(reqId) {
    		var deferred = $q.defer();
    		var url = "location-mappings/"+reqId;
    		var data = restService.restCall('', url, 'GET');
    		data.$promise.then(function(response){
    			deferred.resolve(response);
    		},
    		function (error) {
    			deferred.reject('ERROR');
    		});
    		return deferred.promise;
    	};
    	
    	this.saveApprovedRequestLists = function(approveObj){
    		var errormsg ={};
    		var deferred = $q.defer();
    		var url = "location-mappings/request/approve";
    		var data = restService.restCall(approveObj, url, 'POST');
    		data.$promise.then(function(response){
    			deferred.resolve(response);
    		},
    		function (error) {
    			errormsg = error;
    			deferred.reject(errormsg);
    		});
    		return deferred.promise;
		};

    	this.entityApprove = function(approveEntityRequest){
    		var errormsg ={};
    		var deferred = $q.defer();
    		var url = "entity-profiles/request/approve";
    		var data = restService.restCall(approveEntityRequest, url, 'PUT');
    		data.$promise.then(function(response){
    			deferred.resolve(response);
    		},
    		function (error) {
    			errormsg = error;
    			deferred.reject(errormsg);
    		});
    		return deferred.promise;
    	};
    }
})();
