(function () {
  "use strict";

  angular.module("lariAidApp").factory("AuthServerProvider", AuthServerProvider);

  AuthServerProvider.$inject = ["$http", "$localStorage", "$sessionStorage", "$q", "BASE_URL"];

  function AuthServerProvider($http, $localStorage, $sessionStorage, $q, BASE_URL) {
    var service = {
      login,
      logout,
      loginWithToken,
      storeAuthenticationToken,
      getToken
    };

    return service;

    function login(tokenValue) {
      const token = { tokenId: tokenValue };
      const url = "api/token";

      return $http.get(BASE_URL + url, { params: token }).success(function (data, status, headers) {
        if (data.id_token != null) {
          service.storeAuthenticationToken(data.id_token, true);
          return data.id_token;
        }
      });
    }

    function logout(isLoggedOut) {
      // logout from the server
      $http.post(BASE_URL + "api/logout?isLoggedOut=" + isLoggedOut).success(function (response) {
        //Reload page if the appversion miss match
        var currentVersion = $localStorage.lari_currentVersion;
        var appVersion = $localStorage.lari_appVersion;
        if (currentVersion && appVersion != currentVersion) {
          $localStorage.lari_appVersion = $localStorage.lari_currentVersion;
          setTimeout(function () {
            location.reload(true);
            window.location.href = window.location.href;
          });
        }
        $http.get(BASE_URL + "api/account");
        //Delete JWT token from storage
        delete $localStorage.authenticationToken;
        delete $sessionStorage.authenticationToken;
        return response;
      });
    }

    function loginWithToken(jwt, rememberMe) {
      var deferred = $q.defer();

      if (angular.isDefined(jwt)) {
        this.storeAuthenticationToken(jwt, rememberMe);
        deferred.resolve(jwt);
      } else {
        deferred.reject();
      }

      return deferred.promise;
    }

    function storeAuthenticationToken(jwt, rememberMe) {
      if (rememberMe) {
        $localStorage.authenticationToken = jwt;
      } else {
        $sessionStorage.authenticationToken = jwt;
      }
    }

    function getToken() {
      return $localStorage.authenticationToken || $sessionStorage.authenticationToken;
    }
  }
})();
