(function () {
  "use strict";
  /**
   * @ngdoc function
   * @name lariAidApp.controller:sessionCtrl
   * @description
   * # sessionCtrl
   * Controller of the lariAidApp
   */
  angular.module("lariAidApp").controller("sessionCtrl", [
    "$scope",
    "$interval",
    "$document",
    "$mdDialog",
    "communicationService",
    "Auth",
    "$sessionStorage",
    "Account",

    function ($scope, $interval, $document, $mdDialog, communicationService, Auth, $sessionStorage, Account) {
      var SESSION_BANNER_TIMEOUT_MINUTES = 2; //in minutes
      var timer;
      var sessionTimer;
      var SESSION_TIMEOUT = 900000; // in milliseconds
      var intervalFlag = false;
      var countDown;
      var interval;

      //Method to set session timeout
      $scope.setSessionTimeout = function () {
        var minutes = (SESSION_TIMEOUT / 60000) % 60;
        var seconds = (SESSION_TIMEOUT % 60000) / 1000;
        if (minutes < 10 && minutes.length != 2) minutes = "0" + minutes;
        if (seconds < 10 && seconds.length != 2) seconds = "0" + seconds;
        communicationService.setData("sessionTimer", minutes + ":" + seconds);
        sessionTimer = communicationService.getData("sessionTimer");
        countDown = document.getElementsByClassName("countDown");
        if (countDown != undefined && countDown != null) countDown.innerHTML = minutes + ":" + seconds;
        $scope.setInt();
      };

      //Method for Session time calculator
      $scope.setInt = function () {
        if ($sessionStorage.isLoggedIn) {
          interval = $interval(function () {
            if (angular.isDefined(interval)) {
              var location = window.location.href.split("#")[1];
              if (location == "/") {
                $scope.stop();
                $document.find("body").off("mousemove keydown DOMMouseScroll mousewheel mousedown touchstart");
              } else {
                var minutesLastFocus = undefined;
                if (!angular.isUndefined(communicationService.getData("timeLastFocus"))) {
                  var lastFocus = new Date(communicationService.getData("timeLastFocus"));
                  var m = new Date() - lastFocus;
                  minutesLastFocus = Math.floor(m / 60000);
                }
                if (intervalFlag == true) {
                  communicationService.setData("timeLastFocus", new Date());
                  $scope.setSessionTimeout();
                  $interval.cancel(interval);
                  intervalFlag = false;
                } else if (intervalFlag == false) {
                  communicationService.setData("isLoggedIn", $sessionStorage.isLoggedIn);
                  if (
                    !angular.isUndefined(communicationService.getData("isLoggedIn")) &&
                    communicationService.getData("isLoggedIn") == true
                  ) {
                    sessionTimer = communicationService.getData("sessionTimer");
                  } else {
                    minutes = 0;
                    seconds = 0;
                    communicationService.setData("sessionTimer", minutes + ":" + seconds);
                    sessionTimer = communicationService.getData("sessionTimer");
                  }
                  var conquer = sessionTimer.split(":");
                  var minutes = parseInt(conquer[0], 10);
                  var seconds = parseInt(conquer[1], 10);
                  var minute = "";
                  seconds -= 1;
                  if (minutes < 0) return clearInterval(interval);
                  if (seconds < 0 && minutes != 0) {
                    minutes -= 1;
                    seconds = 59;
                  }
                  if (minutes < 10 && minutes.length != 2) minutes = "0" + minutes;
                  if (seconds < 10 && length.seconds != 2) seconds = "0" + seconds;
                  if (
                    !angular.isUndefined(communicationService.getData("isLoggedIn")) &&
                    communicationService.getData("isLoggedIn") == true
                  ) {
                    communicationService.setData("sessionTimer", minutes + ":" + seconds);
                    sessionTimer = communicationService.getData("sessionTimer");
                  } else {
                    minutes = 0;
                    seconds = 0;
                    communicationService.setData("sessionTimer", minutes + ":" + seconds);
                    sessionTimer = communicationService.getData("sessionTimer");
                  }

                  $(".countDown").html(minutes + ":" + seconds);

                  if (minutes != 0) {
                    $(".second").css("display", "none");
                    $(".minute").css("display", "inline");
                  } else {
                    $(".second").css("display", "inline");
                    $(".minute").css("display", "none");
                  }
                  if (minutes >= SESSION_BANNER_TIMEOUT_MINUTES && seconds >= 0) {
                    $(".sessiondetails").css("display", "none");
                    //$mdDialog.hide();
                  } else if (minutes <= SESSION_BANNER_TIMEOUT_MINUTES && seconds <= 59) {
                    if (minutes == 0) {
                      countDown.innerHTML = seconds;
                    } else {
                      countDown.innerHTML = minutes + ":" + seconds;
                    }

                    if (!$(".sessionTimeoutPopup").hasClass("alive")) {
                      $(".sessiondetails").css("display", "block");
                      $(".sessionTimeoutPopup").addClass("alive");
                      $scope.openSessionWarningPopup("sessionWarningPopup");
                    }
                  }

                  if ((minutes == 0 && seconds == 0) || (minutesLastFocus != undefined && minutesLastFocus >= 30)) {
                    clearInterval(interval);
                    minutes = 0;
                    seconds = 0;
                    communicationService.setData("sessionTimer", minutes + ":" + seconds);
                    communicationService.setData("setSessionData", null);
                    $mdDialog.hide();
                    $sessionStorage.setSessionData = null;
                    $document.find("body").off("mousemove keydown DOMMouseScroll mousewheel mousedown touchstart");
                    $sessionStorage.sessionExpire = true;
                    $mdDialog.hide();
                    $(".sessionTimeoutPopup").removeClass("alive");
                    $scope.stop();
                    Auth.logout(false);
                  }
                }
              }
            } else {
              $scope.stop();
            }
          }, 1000);
        } else {
          //$('.sessionTimeoutPopup').removeClass("alive");
          $mdDialog.hide();
          // $state.go('login');
        }
      };

      $scope.stop = function () {
        if (angular.isDefined(interval)) {
          $mdDialog.hide();
          $(".sessionTimeoutPopup").removeClass("alive");

          $interval.cancel(interval);
          interval = undefined;
          intervalFlag = false;
        }
      };

      $document.find("body").on("mousemove keydown DOMMouseScroll mousewheel mousedown touchstart", checkAndResetIdle);

      function checkAndResetIdle() {
        if (!$(".sessionTimeoutPopup").hasClass("alive")) {
          $(".sessionTimeoutPopup").removeClass("alive");
          // $mdDialog.hide();
          $(".sessiondetails").css("display", "none");
          intervalFlag = true;
        }
      }

      $scope.openSessionWarningPopup = function (id) {
        $mdDialog.hide();
        $mdDialog.show({
          contentElement: angular.element("#" + id)
        });
      };

      $scope.keepAlive = function () {
        Account.get();
        $(".sessionTimeoutPopup").removeClass("alive");
        $mdDialog.hide();
        $(".sessiondetails").css("display", "none");
        intervalFlag = true;
      };
    }
  ]);
})();
