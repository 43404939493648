(function() {
    'use strict';
    
    var lariDevApp = angular
    .module('lariAidApp')
    lariDevApp.directive('commentDateFormat', commentDateFormat);
    lariDevApp.filter("link",linkFunction);
    
    commentDateFormat.$inject = ['dateFilter','$filter'];
    function commentDateFormat(dateFilter,$filter){
    	return {
    	    scope: {
    	        format: "@",
    	    	modval: "="
    	    },
    	    link: function(scope, element, attrs) {
    	    	if(scope.modval){
    	    		var dateVal = new Date(scope.modval);
    	    		dateVal = $filter('date')(dateVal, "MMM d, y h:mm:ss a");
        	    	scope.modval = dateVal;
    	    	}
    	    }
    	}
    };
    
    linkFunction.$inject = ['$filter'];
    function linkFunction($filter){
    	return function(input){
    		if(input){
	    		var joinComment ="";
	        	var strings=input;
	        	var re = new RegExp("(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})");
	        	var commentArray = strings.split(' ');
	
	        	for(var num=0;commentArray[num]!=null; num++){
	            	if (re.test(commentArray[num])) {var test = commentArray[num];
	            		commentArray[num] ="<a target='_blank' href='"+commentArray[num]+"'>"+commentArray[num]+"</a>";
	            	}
	        	}
	        	for(var num=0;commentArray[num]!=null; num++){
	            	joinComment +=commentArray[num]+' '; 
	        	}
	        	return joinComment;
    		}
    	}
    }
    
})();