(function () {
  "use strict";

  angular.module("lariAidApp").service("InvestigationService", InvestigationService);

  InvestigationService.$inject = ["$resource", "restService", "$q", "$rootScope"];

  function InvestigationService($resource, restService, $q, $rootScope) {
    this.loadFilterList = function (filterObj) {
      var deferred = $q.defer();
      var url = "investigations/status/count";
      if (filterObj.statusKey && filterObj.statusKey.length > 0) {
        filterObj.statusKey = undefined;
      }
      var data = restService.restCall(filterObj, url, "POST");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    this.getInvestigationLists = function (filterObj) {
      var deferred = $q.defer();
      var url = "allInvestigations";
      var data = restService.restCall(filterObj, url, "POST");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    this.getHeaderCount = function (filterObj) {
      //Clearing search key
      filterObj.searchKey = undefined;

      var deferred = $q.defer();
      var url = "investigations/headerCount";
      var data = restService.restCall(filterObj, url, "POST");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    this.getCompletedInvestigatorNames = function (investigationId) {
      var deferred = $q.defer();
      var url = "completed-investigator-names/" + investigationId;
      var data = restService.restCall("", url, "GETLIST");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    this.getReopenFlag = function (investigationId) {
      var deferred = $q.defer();
      var url = "investigation/reOpenStatus/" + investigationId;
      var data = restService.restCall("", url, "GET");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };
    this.getEntityIcon = function (investigationId) {
      var deferred = $q.defer();
      var url = "investigation/showEntityIcon/" + investigationId;
      var data = restService.restCall("", url, "GET");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };
    //to get entity items to show in entity review popup
    this.getEntityReviewItemsList = function (investigationId) {
      var deferred = $q.defer();
      var url = "investigation/getEntityReviewItems/" + investigationId;
      var data = restService.restCall("", url, "GETLIST");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };
  }
})();
