(function() {
    'use strict';
    
    var lariDevApp = angular
    .module('lariAidApp')
    lariDevApp.directive('investigationFormSidenav', InvestigationFormSidenav);
    
    function InvestigationFormSidenav () {
		return {
			restrict : 'AE',
			templateUrl : "app/investigation/prospects/profile/investigation-form/investigation.form.sidenav.html",
		};
    }
			
    
})();