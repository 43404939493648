(function() {
	'use strict';
	
	var lariDevApp = angular
    .module('lariAidApp')
    lariDevApp.controller('BirthMedicalController', BirthMedicalController);
	
	BirthMedicalController.$inject = ['$scope','$window', '$mdSidenav','Principal','$state','$mdMedia','$cookies','APP','BirthMedicalService','InvestigationFormService','$stateParams','$timeout','$mdDialog','$rootScope'];
	function BirthMedicalController ($scope,$window, $mdSidenav,Principal,$state,$mdMedia,$cookies,APP,BirthMedicalService,InvestigationFormService,$stateParams,$timeout,$mdDialog,$rootScope) {
		var vm = this;
		vm.dateOfVisit = '';
    	$scope.declarationDate = undefined;
    	vm.app = APP;
    	
    	$scope.birthMedical = {};
    	$scope.birthMedical.sections = [];
    	$scope.birthMedical.summary = {};
    	$scope.loadingBirthMedical = false;
    	$scope.interviewFlag = true;
    	$scope.recordFlag = true;
    	$scope.disablesaveflag = false;
    	var oldFormData = {};
    	
    	
    	//$scope.birthMedical.sections.questions = [];
    	vm.profileForm = $stateParams;
    	vm.myDate = new Date();
    	
    	/**
    	 * To get Current date
    	 */
    	vm.maxDate = new Date(
    	   vm.myDate.getFullYear(),
    	   vm.myDate.getMonth(),
    	   vm.myDate.getDate()
    	 );
        
    	vm.addAdditionalInstitutionForm = function(){
            var tempObj = {};
            tempObj.expanded = false;
            tempObj.questions = [];
            $scope.notApproved=false;
            $scope.birthMedical.sections.push(tempObj);
            vm.collapseAll($scope.birthMedical.sections.length - 1,0);
        };
    	
        vm.collapseAll = function(index,flag){
        	 var height = 0;
	            for(var i=0;i<$scope.birthMedical.sections.length;i++){
	            if(flag==0){
	            	height = height + $('.acc_'+index).closest('.md-accordion').offsetHeight + 42;
	            }
	            else{
	            	height = height + $('.acc_'+index).closest('.md-accordion')[0].offsetHeight + 42;
	            }
	            if(i == index){
	   	             if(!$scope.birthMedical.sections[i].expanded){
	   	            	 	$scope.formViewCall(index);
			   	            $scope.birthMedical.sections[i].expanded = !$scope.birthMedical.sections[i].expanded;
			                $('#birthMedical').animate({scrollTop: height}, 'slow');
			                
			                //Calling get documents based on accordion index
			                $scope.getUploadFiles(null,vm.app.REF_TERM.BIRTH_AND_MEDICAL_RECORDS,index)
			                
	   	             }else{
	   	            	    $scope.birthMedical.sections[i].expanded = !$scope.birthMedical.sections[i].expanded;
	   	             }
	            } else {
	            		$scope.birthMedical.sections[i].expanded = false;
	            	}
	            if($scope.birthMedical.sections[i].expanded){
	            	if($scope.birthMedical.sections[i].questions[0] == undefined){
	            		$scope.notApproved=false;
	            	}else{
	            	$scope.isEntityApproved($scope.birthMedical.sections[i].questions[0].birthMedical1_1_medical_institution_1.answerId);
	            	}
           	    	$scope.openAccordionIndex = i;
           	    }
	            }
       };
       
       vm.loadBirthMedicalFormDetails = function(){
    	   $scope.loadingBirthMedical = true;
		   vm.getBirthMedicalDetails();
    	  
       };
       
       vm.getBirthMedicalDetails = function(){
		   $scope.entityObj.searchKey = null;
    	   var sectionKey = vm.app.REF_TERM.BIRTH_AND_MEDICAL_RECORDS;
		   var formId = $scope.investigationFormId;
    	   var data = InvestigationFormService.getInvestigationFormBySectionId(sectionKey,formId);
		  	data.then(function(response){
		  		if(response.formVersion != null){
 		  			$scope.formVersion = response.formVersion;
 		  		}
		  		if(response.sectionId != null){
 		  			$scope.birthMedicalSectionId = response.sectionId;
 		  		}
		  		if(response.formDTO == null){
		  		   var tempObj = {};
		     	   tempObj.questions = [];
		     	   $scope.birthMedical.sections.push(tempObj);
		  		} else if(response.formDTO.sections.length == 0) {
				  	   if(response.formDTO.summary){
						     var tempObj = {};
						  	   tempObj.questions = [];
				  	           $scope.birthMedical.sections.push(tempObj);
				  	           $scope.birthMedical.summary = response.formDTO.summary;
				  	   } else {
						  	   var tempObj = {};
						  	   tempObj.questions = [];
				  	           $scope.birthMedical.sections.push(tempObj);
				  	   }
		  	    } else {
		  			$scope.birthMedical = response.formDTO;	  		
		  		}
		  	    //Set the object to restrict the initial save call
		  		oldFormData = angular.copy(response.formDTO);
		  		$scope.loadingBirthMedical = false;
		  		$scope.updateLastEditTime(response.updatedOn);
		   }, function(reason) {
			  return reason;
		   });  
       };
       
       vm.removeBirthAndMedicalForm = function(ev,id,indexValue){
    	   $scope.indexVal = indexValue;  
    	   $mdDialog.show({
    		   contentElement: angular.element('#'+id),
    		   targetEvent: ev,
    		   clickOutsideToClose: false,
    		   escapeToClose: false
    	   })
       };
        
        vm.closeConform = function(index){
        	  $scope.deleteLoader.flag = true;
        	$mdDialog.hide();
        	$scope.birthMedical.sections.splice(index,1);
        	if(index != 0){
           		vm.collapseAll(index-1,1);
           	}
        	 $scope.enableDeleteButton();
        };
        
        vm.closeCancel = function(){
        	$mdDialog.cancel();
        	$scope.interviewFlag = true;
        	$scope.recordFlag = true;
        };
        
        /* Method name           : saveBirthCertificateFormDetails()
         * Method Description 	: This method saves the birth certificate form details 
 	    */
        $scope.saveBirthMedicalFormDetails = function(){
           var isPutJsonSimilar = false;
           $rootScope.warningPopup.show = false; //To avoid showing popup while moving to other section if there is no edit in current section
     	   var saveDataObj = {};
     	   saveDataObj.formDTO = angular.copy($scope.birthMedical);
     	   saveDataObj.formVersion = $scope.formVersion;
     	   saveDataObj.investigationFormId = $scope.investigationFormId;
     	   saveDataObj.sectionId = $scope.birthMedicalSectionId;
	     	 for(var i=0;i<saveDataObj.formDTO.sections.length;i++){
	              if(!saveDataObj.formDTO.sections[i].questions){
	                  saveDataObj.formDTO.sections.splice(i,1);
	                  i--;
	              } else if(saveDataObj.formDTO.sections[i].questions.length == 0) {
	                  saveDataObj.formDTO.sections.splice(i,1);
	                  i--;
	              } else {
	                  delete saveDataObj.formDTO.sections[i].expanded;
	                  saveDataObj.formDTO.sections[i].sortOrder = i+1;
	              }
	          }
	     	
	     	/*	Set the temporary object to avoid unwanted save call. 
	      		Fist condition to avoid the initial save call for empty form 
	      		Second condition to avoid the initial save call if the section has any form data 
	      	*/
	     
            if(angular.isUndefined($scope.tempMedicalObj) && saveDataObj.formDTO.sections.length == 0 && oldFormData == null && 
                    (saveDataObj.formDTO.summary.comment == null || saveDataObj.formDTO.summary.comment == undefined)) {
                $scope.tempMedicalObj = angular.copy(saveDataObj);
            } else if(angular.isUndefined($scope.tempMedicalObj) && angular.equals(JSON.stringify(saveDataObj.formDTO), JSON.stringify(oldFormData))) {
         	   $scope.tempMedicalObj = angular.copy(saveDataObj);
            }  else if(angular.isUndefined($scope.tempMedicalObj) && !angular.equals(JSON.stringify(saveDataObj.formDTO), JSON.stringify(oldFormData))) {
            	$scope.tempMedicalObj = {};
            	$scope.tempMedicalObj.formDTO = angular.copy(oldFormData);
            } 
            
            if(!angular.isUndefined($scope.tempMedicalObj)){
            	if(angular.equals(JSON.stringify(saveDataObj.formDTO), JSON.stringify($scope.tempMedicalObj.formDTO))){
            		isPutJsonSimilar = true;	    
            	} 	    				
            }
	  
            if($scope.isOwner){
            	$scope.tempMedicalObj = angular.copy(saveDataObj);
            } else {
            	$scope.tempMedicalObj = undefined; //Every time temporary object will be newly updated for non owner 
            }

	      /* saveData flag - to ensure the save button is clicked in form*/
	    	if(!isPutJsonSimilar){	    		
	    		if($scope.isOwner || $scope.saveData){
	    			//Flag to set the section results are edited or not
	    			if(angular.isDefined($scope.birthMedicalCheckList) && angular.isDefined($scope.birthMedicalCheckListTempObj) && !angular.equals($scope.birthMedicalCheckList, $scope.birthMedicalCheckListTempObj)) {
	    				saveDataObj.isFormSectionResultEdited = true;
	    				$scope.saveAnalystFormTypeCheckList('birthMedical', true, false);
	    			}
	    			
	    			var data = InvestigationFormService.saveInvestigationForm(saveDataObj);
		  		  	data.then(function(response){
		  		  		if($state.current.name === vm.app.INVESTIGATION.STATE.BIRTH_MEDICAL_STATE)
		  		  		{
		  		  			$scope.updateLastEditTime(response.updatedOn);
		  		  		}
		  		  		$scope.uploadFileStatusUpdated();
		  		  	}, function(reason) {
		  			  return reason;
		  		  	});
	    		}	
	    		 $rootScope.warningPopup.show = true;
            } else if(($scope.isOwner || $scope.saveData) && angular.isDefined($scope.birthMedicalCheckList) && angular.isDefined($scope.birthMedicalCheckListTempObj) 
        			&& !angular.equals($scope.birthMedicalCheckList, $scope.birthMedicalCheckListTempObj)) {
				$scope.saveAnalystFormTypeCheckList('birthMedical', true);
			} 
        };
        
        vm.interviewFlag = function(flag, index){
       	 $scope.interviewFlag = flag;
       	 if(index){
       		 $scope.sectionIndex = index; 
       	 }
         };
        
        vm.addAdditionalInterviewForm = function(){
            var testObj = {};
            if($scope.interviewFlag == 1){
           	 $scope.birthMedical.sections[0].questions[0].birthMedical2_medical_institution_yes_2b_1.interviews.push(testObj);
            }
            if($scope.interviewFlag == 2){
           	 if($scope.sectionIndex){
           		 $scope.birthMedical.sections[$scope.sectionIndex].questions[0].birthMedical2_medical_institution_yes_2b_1.interviews.push(testObj);
           	 }
            }
        };
        vm.nextInvestigationForm = function(){
        	//$scope.saveAnalystFormTypeCheckList('birthMedical');
        	$scope.disablesaveflag = true;
        	$scope.saveData = true;
        	$scope.saveBirthMedicalFormDetails();
        	$rootScope.warningPopup.show = false;
			$scope.sectionNavigationFunc(vm.app.INVESTIGATION_FORM.NEIGHBOURHOOD_CANVASS_FORM);
        	//$state.go("neighbourhoodCanvas");
        	if($scope.investigationFormType == vm.app.INVESTIGATION.ANALYST_FORM){
				$scope.collapseAll(2);
			}
			$scope.updateLastEditTime(null);
		};
		
		 /* File Upload Sections */
		vm.uploadFileForSummary = function(isSummary,accordionIndex,idName,ev){
    		$("#birthMedicalSectionSummary").val(null);
 	        $('#birthMedicalSectionSummary').trigger('click'); 
 	       $('#birthMedicalSectionSummary').off('change').on('change', function() {
 	    	    var file = this.files[0];
 	    		var sectionId = $scope.birthMedicalSectionId; 
 	    		var questionKey = null;
    			if(isSummary == "true"){
    				questionKey = vm.app.INVESTIGATION_FORM.SUMMARY_KEY;
    			}
    			$scope.setOrginSectionFileUpload(vm.app.REF_TERM.BIRTH_AND_MEDICAL_RECORDS);
    			$scope.uploadFileInSectionSummary(ev,file,questionKey,sectionId,isSummary,null,idName,accordionIndex,null); 	    		
	        });
 		};

 		
 		/* Method Description 	: This method triggers auto save for birth certificate form */
        $scope.$watch('birthMedicalAutoSave', function(newValue, oldValue) {
          if(newValue != undefined && newValue != oldValue){
        	if(newValue && $scope.isOwner){
 					if($scope.formVersion && !$scope.isreadonly){
 						$scope.saveBirthMedicalFormDetails();
 	 					$rootScope.birthMedicalAutoSave = false;
					} else{
						$rootScope.birthMedicalAutoSave = false;
					}
 				}else{
 					$rootScope.birthMedicalAutoSave = false;
 				}
        	
 			}
 		});
        
        /**
		 * To find which accordion is open currently
		 */	
		vm.findOpenform = function(){
        	 if($scope.birthMedical.sections.length > 1){
        		 var j = -1;
        		 for(var i = 0;i < $scope.birthMedical.sections.length;i++){
            		 if($scope.birthMedical.sections[i].expanded == true){
            			 return ++i;
            		 }
            	 } 
        		 return j;
        	 }
        	 return 1;
         };
        
        /**
		 * Add the requested Entity
		 * 
		 */	
		$rootScope.birthMedicalEntityRequestsent = function(entity,locationObject,response){
	    	  var entityName = entity.entityName+", "+locationObject.cityObj.cityTxt+", "+locationObject.countryObj.countryTxt;
	    	  var openForm = vm.findOpenform();openForm--;
	    	  if(openForm != -1){
	    		  $scope.birthMedical.sections[openForm].questions[0].birthMedical1_1_medical_institution_1.answer = entityName;
	    		  $scope.birthMedical.sections[openForm].questions[0].birthMedical1_1_medical_institution_1.answerId = response.id;
	    		  $scope.birthMedical.sections[openForm].questions[0].birthMedical1_1_medical_institution_1.requested = true;
	    	  }
	    	  document.querySelector('.global-search-input input').value = entityName;
		};
		
      //Method for add or suggest entity
	    vm.entityRequestSuggest = function(searchKey,entityName){
	    	return $scope.entityRequestAddorSuggest(searchKey,entityName);
	   	};
	   	
	   	vm.checkIfFormEdited = function(){
    		$rootScope.warningPopup.show = false;
    		if(angular.isDefined($scope.tempMedicalObj) && 
    		angular.isDefined(oldFormData) && !angular.equals(oldFormData,$scope.tempMedicalObj)){
    			$rootScope.warningPopup.show = true;
    		}
    	}
	   	
	    //It will get triggered while exiting from current state
	   	$scope.$on('$destroy', function() {
	   		if(!$scope.isreadonly && $scope.isOwner) {
	   			$scope.saveBirthMedicalFormDetails();
	   		}
	   		$rootScope.warningPopup.show = false;
	   		$scope.uploadedFile = false;
        });
	   	
	   	//To open the confirmation pop up for remove the additional interview or records
    	vm.deleteIndex = function(event,id,index,isInterview){
			$scope.indexVal = index;
			$scope.isInterview = isInterview;
			$scope.interviewFlag = false;
			$scope.recordFlag = false;
           	$mdDialog.show({
       			  contentElement: angular.element('#'+id),
       		      targetEvent: event,
       		      clickOutsideToClose: false,
       		      escapeToClose: false,
       		      onRemoving: function (event, removePromise) {
    		    	  vm.closeCancel();
	   		   		}
       		    });
    	};
       	
    	//To remove the interview content form the form data
       	vm.deleteInterviewConfirm = function(index){
       		$scope.deleteLoader.flag = true;
       		if($scope.birthMedical.sections.length == 1){
	  			$scope.openAccordionIndex = 0;
	  		}
       		$scope.birthMedical.sections[$scope.openAccordionIndex].questions[0].birthMedical2_medical_institution_yes_2b_1.interviews.splice(index,1);
       	    vm.closeCancel();
       	 $scope.enableDeleteButton();
       	};
       	
       	//To remove the records form the form data
       	vm.deleteRecordConfirm = function(index){
       		$scope.deleteLoader.flag = true;
       		if($scope.birthMedical.sections.length == 1){
	  			$scope.openAccordionIndex = 0;
	  		}
       		$scope.birthMedical.sections[$scope.openAccordionIndex].questions[0].birth_medical_record_list.records.splice(index,1);
       	 vm.closeCancel();
       	 $scope.enableDeleteButton();
       	};
	}
})();