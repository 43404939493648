(function() {
    'use strict';

    angular
        .module('lariAidApp')
        .service('SideCommentsDirectiveService', SideCommentsDirectiveService);

    SideCommentsDirectiveService.$inject = ['$resource', 'restService', '$q'];
    
    function SideCommentsDirectiveService ($resource, restService, $q) {
    	
    	/**
    	 * To get the sideComments
    	 */
		this.getSideComments = function(formId,section) {
			var deferred = $q.defer();
    		var url = "side-comment/"+formId+"/"+section;
    		var data = restService.restCall("",url, 'GET');
    		data.$promise.then(function(response){
    			deferred.resolve(response);
    		},
    		function (error) {
    			deferred.reject('ERROR');
    		});
    		return deferred.promise;
		}
		
		/**
		 * To Save the Parent Comment
    	 * @param formId - investigationId
    	 * @param dataObj - Save Object
		 */
		this.saveParentComment = function(formId,dataObj){
    		var deferred = $q.defer();
    		var url = "side-comment-parent/"+formId;
    		var data = restService.restCall(dataObj, url, 'POST');
    		data.$promise.then(function(response){
    			deferred.resolve(response);
    		},
    		function (error) {
    			deferred.reject('ERROR');
    		});
    		return deferred.promise;
    	};
    	
    	/**
    	 * To Save the child comment
    	 * @param formId - investigationId
    	 * @param dataObj - Save Object
    	 * @param parentId - Parent Comment Id
    	 */
    	this.saveChildComment = function(formId,dataObj,parentId){
    		var deferred = $q.defer();
    		var url = "side-comment-child/"+formId+"/"+parentId;
    		var data = restService.restCall(dataObj, url, 'POST');
    		data.$promise.then(function(response){
    			deferred.resolve(response);
    		},
    		function (error) {
    			deferred.reject('ERROR');
    		});
    		return deferred.promise;
    	};
    	
    	/**
    	 * To delete a Parent Comment
    	 * @param formId - investigationId
    	 * @param parentId - Parent Comment Id
    	 */
    	this.deleteParentComment = function(formId,parentId){    		
    		var deferred = $q.defer();
    		var url = "side-comment-parent/"+formId+"/"+parentId+"/delete";
    		var data = restService.restCall("", url, 'DELETE');
    		data.$promise.then(function(response){
    			deferred.resolve(response);
    		},
    		function (error) {
    			deferred.reject('ERROR');
    		});
    		return deferred.promise;
    	};
    	
    	/**
    	 * To delete a Child Comment
    	 * @param commentId - Child comment Id which is to be deleted
    	 * @param parentId - Parent Comment Id
    	 */
    	this.deleteChildComment = function(parentId,commentId){    		
    		var deferred = $q.defer();
    		var url = "side-comment-child/"+parentId+"/"+commentId+"/delete";
    		var data = restService.restCall("", url, 'DELETE');
    		data.$promise.then(function(response){
    			deferred.resolve(response);
    		},
    		function (error) {
    			deferred.reject('ERROR');
    		});
    		return deferred.promise;
    	};
    	
    	/**
    	 * To Update a parent comment
    	 */
    	this.updateParentComment = function(formId,parentId,dataObj){
    		var deferred = $q.defer();
    		var url = "side-comment-parent/"+formId+"/"+parentId;
    		var data = restService.restCall(dataObj, url, 'PUT');
    		data.$promise.then(function(response){
    			deferred.resolve(response);
    		},
    		function (error) {
    			deferred.reject('ERROR');
    		});
    		return deferred.promise;
    	};
    	
    	/**
    	 * To Update a Child Comment
    	 */
    	this.updateChildComment = function(formId,parentId,commentId,dataObj){
    		var deferred = $q.defer();
    		var url = "side-comment-child/"+formId+"/"+parentId+"/"+commentId;
    		var data = restService.restCall(dataObj, url, 'PUT');
    		data.$promise.then(function(response){
    			deferred.resolve(response);
    		},
    		function (error) {
    			deferred.reject('ERROR');
    		});
    		return deferred.promise;
    	};
    	
    	/**
    	 * To delete side comments using section and accoidionIndex
    	 */
    	this.deleteCommentsByIndex = function(formId,section,accordionIndex){
    		var deferred = $q.defer();
    		var url = "side-comment/"+formId+"/"+section+"/"+accordionIndex+"/";
    		var data = restService.restCall("", url, 'DELETE');
    		data.$promise.then(function(response){
    			deferred.resolve(response);
    		},
    		function (error) {
    			deferred.reject('ERROR');
    		});
    		return deferred.promise;
    	};
    	
    	/**
    	 * Get side comments using section, accordionIndex and question
    	 */
    	this.getSideCommentsBySectionQuestion = function(formId,section,accordionIndex,question) {
    		var deferred = $q.defer();
    		var url = "side-comment-parent/"+formId+"/"+section+"/"+accordionIndex+"/"+question;
    		var data = restService.restCall("",url, 'GET');
    		data.$promise.then(function(response){
    			deferred.resolve(response);
    		},
    		function (error) {
    			deferred.reject('ERROR');
    		});
    		return deferred.promise;
    	};
    	
    	/**
    	 * Get side comments using section and accordionIndex
    	 */
    	this.getSideCommentsBySectionIndex = function(formId,section,accordionIndex) {
    		var deferred = $q.defer();
    		var url = "side-comment/"+formId+"/"+section+"/"+accordionIndex;
    		var data = restService.restCall("",url, 'GET');
    		data.$promise.then(function(response){
    			deferred.resolve(response);
    		},
    		function (error) {
    			deferred.reject('ERROR');
    		});
    		return deferred.promise;
    	};
		
    }
})();