(function() {
    'use strict';

    angular
        .module('lariAidApp')
        .config(stateConfig);
    stateConfig.$inject = ['$stateProvider','APP'];
    function stateConfig($stateProvider,APP) {
    	 $stateProvider.state('userprofile', {
             parent: 'userprospects',
             url: '/:id/profile',
             data: {
                 authorities: APP.GLOBAL.USER_GROUP
             },
             params: {
             	id:null,
             	userType:null
             },
             views: {
                 'userDetails': {
                     templateUrl: 'app/user/userprospects/profile/user.profile.html',
                     controller: 'UserProfileController',
                     controllerAs: 'vm'
                 }
             },resolve: {
                 mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                     if(localStorage && localStorage.getItem('lari_currentVersion') != null){
                        	$translatePartialLoader.addPart('profile.json?version='+localStorage.getItem('lari_currentVersion'));
                        }else{
                        	$translatePartialLoader.addPart('profile.json?timestamp='+new Date().getTime());
                        }
                     return $translate.refresh();
                 }],
                 mdTabIndex:['tabservice','$timeout', function (tabservice,$timeout) {
                 	tabservice.setIndex("1");
                 	$timeout(function() {
                 	    angular.element('#userProfile').triggerHandler('click');
                 	  });
                 }]
             }
    	 });
    }
})();