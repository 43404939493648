(function() {
	'use strict';

	angular.module('lariAidApp').directive('resize', resize);
	angular.module('lariAidApp').directive('resizePopup', resizePopup);
	//resize.inject['$window'];
	resize.$inject = ['$mdMedia'];
	function resize($mdMedia) {
		return function(scope, element) {
			var w = angular.element(window);
			var n,i,p,x;
			scope.media = $mdMedia;
			scope.getWindowDimensions = function() {
				return {
					'h' : w.height(),
					'w' : w.width()
				};
			};
			scope.$watch(scope.getWindowDimensions,
					function(newValue, oldValue) {
						scope.resizeFun(newValue, oldValue);
					}, true);

			w.bind('resize', function() {
				scope.$apply();
				scope.resizeFun(scope.getWindowDimensions());
			});
			
			scope.resizeFun = function(newValue, oldValue){
				n = angular.element('.headerTop');
				i = angular.element('.investigationHeaderTop');
				p = angular.element('.prospectHeaderSize');
				x = angular.element('.prospectHeaderXs');
				if(n.css('display') != 'none'){
					newValue.h = newValue.h - (n.height() || 0);
				}
				if(i.css('display') != 'none'){
					newValue.h = newValue.h - (i.height() || 0);
				}
				if(p.css('display') != 'none'){
					newValue.h = newValue.h - (p.height() || 0);
				}
				if(x.css('display') != 'none'){
					newValue.h = newValue.h - (x.height() || 0);
				}
				scope.windowHeight = newValue.h;
				scope.windowWidth = newValue.w;
			}
		}
	}
	function resizePopup() {
		return function(scope, element) {
			var w = angular.element('.popup');
			var n,i,p,x;
			scope.getWindowDimensions = function() {
				return {
					'h' : w.height(),
					'w' : w.width()
				};
			};
			scope.$watch(scope.getWindowDimensions,
					function(newValue, oldValue) {
						setTimeout(function(){
							scope.resizeFun(newValue, oldValue);
						},200);
					}, true);

			w.bind('resize', function() {
				scope.$apply();
				setTimeout(function(){
					scope.resizeFun(scope.getWindowDimensions());
				},500);
			});
			
			scope.resizeFun = function(newValue, oldValue){
				n = angular.element('.popupDiv');
				
				if(n.css('display') != 'none'){
					newValue.h = newValue.h - (n.height() || 0);
				}
				
				scope.windowPopHeight = newValue.h;
				scope.windowPopWidth = newValue.w;
			}
		}
	}
})();