(function() {
	'use strict';
	
	var lariDevApp = angular
    .module('lariAidApp')
    lariDevApp.controller('OtherController', OtherController);
	
	OtherController.$inject = ['$scope','$window', '$mdSidenav','Principal','$state','$mdMedia','$cookies','APP','OtherService','$stateParams','InvestigationFormService','$timeout','$rootScope'];
	function OtherController ($scope,$window, $mdSidenav,Principal,$state,$mdMedia,$cookies,APP,OtherService,$stateParams,InvestigationFormService,$timeout,$rootScope) {
		
		var vm = this;
		vm.app = APP;
		vm.profileForm = $stateParams;
		$scope.otherForm = {};
    	$scope.otherForm.sections = [];
    	$scope.otherForm.sections.questions = [];
    	//$scope.otherForm.summary = {};
    	$scope.loadingOther = false;
    	var oldFormData = {};
    	
    	
           vm.loadOther = function(){
        	   $scope.loadingOther = true;
    		   vm.getOtherDetails();
          	 
             };   
           
             vm.getOtherDetails = function (){
          	   $scope.loadingOther = true;
        	   var sectionKey = vm.app.REF_TERM.OTHER;
    		   var formId = $scope.investigationFormId;
        	   var data = InvestigationFormService.getInvestigationFormBySectionId(sectionKey,formId);
    		  	data.then(function(response){
    		  		if(response.formVersion != null){
     		  			$scope.formVersion = response.formVersion;
     		  		}
    		  		if(response.sectionId != null){
     		  			$scope.otherSectionId = response.sectionId;
     		  		}
    		  		if(response.formDTO == null){
    		  		   var tempObj = {};
    		     	   tempObj.questions = [];
    		     	   $scope.otherForm.sections.push(tempObj);
    		  		} else if(response.formDTO.sections.length == 0) {
					  	   var tempObj = {};
					  	   tempObj.questions = [];
			  	           $scope.otherForm.sections.push(tempObj);
    				  	   
    		  	    }else {
    		  			$scope.otherForm = response.formDTO;    		  			
    		  		}
    		  	    //Set the object to restrict the initial save call
		  			oldFormData = angular.copy(response.formDTO);
    		  		$scope.loadingOther = false;
    		  		$scope.updateLastEditTime(response.updatedOn);
    		  		
    		   }, function(reason) {
    			  return reason;
    		   });
           };
          
       /* Method name              : saveOtherDetails()
        * Method Description 	   : This method saves the other form details 
        */      
    	$scope.saveOtherDetails = function(){
    	   var isPutJsonSimilar = false;
    	   $rootScope.warningPopup.show = false; //To avoid showing popup while moving to other section if there is no edit in current section
      	   var saveDataObj = {};
      	   saveDataObj.formDTO = angular.copy($scope.otherForm);
      	   saveDataObj.formVersion = $scope.formVersion;
      	   saveDataObj.investigationFormId = $scope.investigationFormId;
      	   saveDataObj.sectionId = $scope.otherSectionId;
	      	 for(var i=0;i<saveDataObj.formDTO.sections.length;i++){
	             if(!saveDataObj.formDTO.sections[i].questions){
	                 saveDataObj.formDTO.sections.splice(i,1);
	             } else if(saveDataObj.formDTO.sections[i].questions.length == 0) {
	                 saveDataObj.formDTO.sections.splice(i,1);
	             } else {
	                 delete saveDataObj.formDTO.sections[i].expanded;
	                 saveDataObj.formDTO.sections[i].sortOrder = i+1;
	             }
	         }
	      	 
	      	 /*	Set the temporary object to avoid unwanted save call. 
   				Fist condition to avoid the initial save call for empty form 
   				Second condition to avoid the initial save call if the section has any form data 
	      	 */
             if(angular.isUndefined($scope.tempOtherObj) && saveDataObj.formDTO.sections.length == 0) {
                 $scope.tempOtherObj = angular.copy(saveDataObj);
             } else if(angular.isUndefined($scope.tempOtherObj) && angular.equals(JSON.stringify(saveDataObj.formDTO), JSON.stringify(oldFormData))) {
          	   $scope.tempOtherObj = angular.copy(saveDataObj);
             } else if(angular.isUndefined($scope.tempOtherObj) && !angular.equals(JSON.stringify(saveDataObj.formDTO), JSON.stringify(oldFormData))) {
            	 $scope.tempOtherObj = {};
            	 $scope.tempOtherObj.formDTO = angular.copy(oldFormData);
             } 

             if(!angular.isUndefined($scope.tempOtherObj)){
            	 if(angular.equals(JSON.stringify(saveDataObj.formDTO), JSON.stringify($scope.tempOtherObj.formDTO))){
            		 isPutJsonSimilar = true;	    			  
            	 } 
             }
             if($scope.isOwner){
            	 $scope.tempOtherObj = angular.copy(saveDataObj);
             } else {
            	 $scope.tempOtherObj = undefined; //Every time temporary object will be newly updated for non owner 
             }
	      	
	        /* saveData flag - to ensure the save button is clicked in form*/
	       if(!isPutJsonSimilar){
	    	   if($scope.isOwner || $scope.saveData){
	    		   var data = InvestigationFormService.saveInvestigationForm(saveDataObj);
		   		  	data.then(function(response){
		  		  		if($state.current.name === vm.app.INVESTIGATION.STATE.OTHER_STATE)
		  		  		{
		  		  			$scope.updateLastEditTime(response.updatedOn);
		  		  		}
		  		  		$scope.uploadFileStatusUpdated();
		   		  }, function(reason) {
		   			  return reason;
		   		  });
	    	   }
	    	   $rootScope.warningPopup.show = true;
	       }
         };
         
         vm.nextInvestigationForm = function(){
        	$scope.saveData = true;
        	$scope.saveOtherDetails(); 
        	//$state.go("summary");
        	$rootScope.warningPopup.show = false;
			$scope.sectionNavigationFunc(vm.app.INVESTIGATION_FORM.SUMMARY_FORM);
        	if($scope.investigationFormType == vm.app.INVESTIGATION.ANALYST_FORM){
				$scope.collapseAll(9);
			}
			$scope.updateLastEditTime(null);
 		 };
 		
 		 /* File Upload Sections */
         
         
         vm.uploadFileForSummary = function(isSummary,accordionIndex,idName,ev){
     		$("#other").val(null);
  	        $('#other').trigger('click'); 
  	        $('#other').off('change').on('change', function() {
	  	    	var file = this.files[0];
	     		var sectionId = $scope.otherSectionId;
	     		$scope.setOrginSectionFileUpload(vm.app.REF_TERM.OTHER);
	     		$scope.uploadFileInSectionSummary(ev,file,vm.app.INVESTIGATION_FORM.SUMMARY_KEY,sectionId,isSummary);
	        });
  		};

  		$scope.other_file_attach = function(element){
     		
  		};

  		/*vm.uploadFileInSectionSummary = function(file){
     		vm.submittedAsset = {};
     		vm.submittedAsset.fileName = file.name;
  		};*/
  			
		$scope.removeAttachment = function(element){
			$(element.target.parentElement).remove();
		};
		
		$rootScope.warningPopup.show = false;
		/* Method Description 	: This method triggers auto save for birth certificate form */
	      $scope.$watch('otherAutoSave', function(newValue, oldValue) {
				if(newValue != undefined && newValue != oldValue){					
					if(newValue && $scope.isOwner){						
							if($scope.formVersion && !$scope.isreadonly){
								$scope.saveOtherDetails();
								$rootScope.otherAutoSave = false;
							} else{
								$rootScope.otherAutoSave = false;
							}
						}else{
							$rootScope.otherAutoSave = false;
						}					
				}
			});
	       
	     //It will get triggered while exiting from current state
	      $scope.$on('$destroy', function() {
	    	  if(!$scope.isreadonly && $scope.isOwner) {
	    		  $scope.saveOtherDetails();
	    	  }
	    	  $rootScope.warningPopup.show = false;
	    	  $scope.uploadedFile = false;
	      });
	}
})();