(function() {
    'use strict';
    
    var lariDevApp = angular
    .module('lariAidApp')
    lariDevApp.directive('uploadFormFile', function()
    	{
	    	return {
				restrict : 'AE',			
				templateUrl: 'app/investigation/prospects/profile/investigation-form/upload-file-directive/upload.file.html',
				/*controller: 'uploadFileController',*/
				link: function(scope, element, attrs, ngModel) {
					if(attrs.idName){
						scope.idName = attrs.idName;
					}
					if(attrs.idFormName){
						scope.idFormName = attrs.idFormName;
					}
					if(attrs.isSummary){
						scope.isSummary = attrs.isSummary;
					}
					if(attrs.isReadOnly){
						scope.isReadOnly = attrs.isReadOnly;
					}
				}
			};
    	});
    
})();