(function(){
	'use strict';

    angular
        .module('lariAidApp')
        .config(stateConfig);
    stateConfig.$inject = ['$stateProvider','APP'];
    function stateConfig($stateProvider,APP) {
        $stateProvider.state('userprospects', {
            parent: 'users',
            url: '/:userType',
            data: {
                authorities: APP.GLOBAL.USER_GROUP
            },
            params: {
            	id:null,
            	userType:null
            },
            views: {
                'content@': {
                    templateUrl: 'app/user/userprospects/user.prospects.html',
                    controller: 'UserProspectsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    if(localStorage && localStorage.getItem('lari_currentVersion') != null){
                       	$translatePartialLoader.addPart('user.json?version='+localStorage.getItem('lari_currentVersion'));
                       }else{
                       	$translatePartialLoader.addPart('user.json?timestamp='+new Date().getTime());
                       }
                    return $translate.refresh();
                }]/*,
                mdTabIndex:['tabservice','$timeout', function (tabservice,$timeout) {
                	tabservice.setIndex("1");
                	$timeout(function() {
                	    angular.element('#userProfile').triggerHandler('click');
                	  },500);
                }]*/
            }
        })
    }
})();