(function() {
    'use strict';

    angular
        .module('lariAidApp')
        .config(stateConfig);
    stateConfig.$inject = ['$stateProvider','APP'];
    function stateConfig($stateProvider,APP) {
        $stateProvider
        .state('profile', {
            parent: 'prospects',
            url: '/:id/profile',
            data: {
                authorities: APP.GLOBAL.USER_GROUP
            },
            params: {
            	id:null,
            },
            views: {
                'sample': {
                    templateUrl: 'app/investigation/prospects/profile/profile.html',
                    controller: 'ProfileController',
                    controllerAs: 'vm'
                }
            },
            onExit: ['$sessionStorage', function ($sessionStorage) {
                delete $sessionStorage.selectedSectionIndex;
            }],
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    if(localStorage && localStorage.getItem('lari_currentVersion') != null){
                       	$translatePartialLoader.addPart('profile.json?version='+localStorage.getItem('lari_currentVersion'));
                       }else{
                       	$translatePartialLoader.addPart('profile.json?timestamp='+new Date().getTime());
                       }
                    return $translate.refresh();
                }],
                mdTabIndex:['tabservice','$timeout', function (tabservice,$timeout) {
                	tabservice.setIndex("1");
                	$timeout(function() {
                	    angular.element('#profile').triggerHandler('click');
                	  });
                }]
            }
        });
    }
})();
