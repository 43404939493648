(function () {
  "use strict";

  angular.module("lariAidApp").controller("LoginController", LoginController);

  LoginController.$inject = [
    "$rootScope",
    "$state",
    "$timeout",
    "Auth",
    "$sessionStorage",
    "communicationService",
    "APP",
    "$translate",
    "tmhDynamicLocale",
    "$http",
    "$sce",
    "BASE_URL",
    "OKTA_SAML_URL"
  ];

  function LoginController(
    $rootScope,
    $state,
    $timeout,
    Auth,
    $sessionStorage,
    communicationService,
    APP,
    $translate,
    tmhDynamicLocale,
    $http,
    $sce,
    BASE_URL,
    OKTA_SAML_URL
  ) {
    var vm = this;
    vm.showPassword = false;
    vm.authenticationError = false;
    vm.cancel = cancel;
    vm.credentials = {};
    vm.login = login;
    vm.password = null;
    vm.register = register;
    vm.rememberMe = true;
    vm.requestResetPassword = requestResetPassword;
    vm.username = null;
    vm.decryptpass = decryptpass;
    vm.encryptpass = encryptpass;
    vm.activated = false;
    vm.removeError = removeError;
    vm.forgotPassword = forgotPassword;
    vm.oktaSamlUrl = $sce.trustAsResourceUrl(OKTA_SAML_URL);
    vm.APP = APP;

    $timeout(function () {
      angular.element("#username").focus();
    });

    function cancel() {
      vm.credentials = {
        username: null,
        password: null
      };
      vm.authenticationError = false;
      $uibModalInstance.dismiss("cancel");
    }
    function getEnvironmentDetails() {
      $http({
        method: "GET",
        url: BASE_URL + "api/getEnvironmentDetails"
      }).then(function successCallback(response) {
        $sessionStorage.currentEnvironment = response.data.environment;
      });
    }

    function login(event) {
      vm.activated = true;
      event.preventDefault();
      Auth.login({
        username: vm.username,
        secretkey: vm.password
      })
        .then(function () {
          getEnvironmentDetails();
          vm.authenticationError = false;
          if (
            $state.current.name === "register" ||
            $state.current.name === "activate" ||
            $state.current.name === "finishReset" ||
            $state.current.name === "requestReset" ||
            $state.current.name === "login"
          ) {
            $state.go("investigations");
            $sessionStorage.isLoggedIn = true;
            communicationService.setData("isLoggedIn", true);
            //delete $sessionStorage.languageKey;
          }

          $rootScope.$broadcast("authenticationSuccess");

          // previousState was set in the authExpiredInterceptor before being redirected to login modal.
          // since login is successful, go to stored previousState and clear previousState
          if (Auth.getPreviousState()) {
            var previousState = Auth.getPreviousState();
            Auth.resetPreviousState();
            $state.go(previousState.name, previousState.params);
          }
        })
        .catch(function () {
          vm.activated = false;
          vm.authenticationError = true;
          vm.sessionExpire = false;
          //$timeout(function () { vm.authenticationError = false; }, 3000);
        });
    }

    function register() {
      $state.go("register");
    }

    function requestResetPassword() {
      $state.go("requestReset");
    }

    function decryptpass() {
      vm.showPassword = true;
    }

    function encryptpass() {
      vm.showPassword = false;
    }
    function removeError() {
      vm.authenticationError = false;
    }

    if ($sessionStorage.sessionExpire) {
      vm.sessionExpire = true;
      $timeout(function () {
        delete $sessionStorage.sessionExpire;
      }, 3000);
    }

    function forgotPassword() {
      Auth.forgotPassword().then(function (response) {
        vm.forgotPasswordUrl = response.url;
      });
    }
    vm.defaultLanguage = function () {
      if ($sessionStorage.languageKey != null && $sessionStorage.languageKey != "") {
        vm.lang = $sessionStorage.languageKey;
      } else {
        if(navigator.language.indexOf("es")>-1){
          vm.lang = "es" ;
        }
        else{
            vm.lang = "en";
        }

      }
      $translate.use(vm.lang);
      tmhDynamicLocale.set(vm.lang);
    };

    vm.changeLanguage = function (languageKey) {
      if (languageKey == vm.APP.GLOBAL.ENGLISH) {
        vm.lang = vm.APP.GLOBAL.EN;
        $sessionStorage.languageKey = vm.APP.GLOBAL.EN;
      } else if (languageKey === vm.APP.GLOBAL.SPANISH) {
        vm.lang = vm.APP.GLOBAL.ES;
        $sessionStorage.languageKey = vm.APP.GLOBAL.ES;
      }
      $translate.use(vm.lang);
      tmhDynamicLocale.set(vm.lang);
    };
  }
})();
