(function () {
  "use strict";

  angular.module("lariAidApp").service("LegacyLariService", LegacyLariService);

  LegacyLariService.$inject = ["$resource", "restService", "$q", "$rootScope"];

  function LegacyLariService($resource, restService, $q, $rootScope) {
    this.getRequestLists = function (filterObj) {
      var deferred = $q.defer();
      var ebisId = filterObj.searchKey;
      var url = "getDocumentsByEbisId" + "/" + ebisId;
      var data = restService.restCall("", url, "GET");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };
  }
})();
