(function() {
	'use strict';

    angular
        .module('lariAidApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','APP','$mdAriaProvider','$mdDateLocaleProvider'];
    
    function stateConfig($stateProvider,APP,$mdAriaProvider,$mdDateLocaleProvider) {
    	$stateProvider.state('library', {
    		parent: 'app',
            url: '/library',
            data: {
                authorities: APP.GLOBAL.USER_GROUP
            },
            views: {
                'dynamicView': {
                    templateUrl: 'app/library/library.management.html',
                    controller: 'LibraryController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                	if(localStorage && localStorage.getItem('lari_currentVersion') != null){
                       	$translatePartialLoader.addPart('library.json?version='+localStorage.getItem('lari_currentVersion'));
                       }else{
                       	$translatePartialLoader.addPart('library.json?timestamp='+new Date().getTime());
                       } 
                    return $translate.refresh();
                }]/*,
                mdTabIndex:['tabservice','$timeout', function (tabservice,$timeout) {
                	tabservice.setIndex("1");
                	$timeout(function() {
                	    angular.element('#request').triggerHandler('click');
                	  });
                }]*/
            }
            
    	});
    };
	
})();