(function() {
    'use strict';

    var lariDevApp = angular
        .module('lariAidApp')
        lariDevApp.controller('ActivityController', ActivityController);

    ActivityController.$inject = ['$scope','$window', '$mdSidenav','Principal','$state','$mdMedia','APP','ProspectActivityService','$stateParams','CommonService'];
    function ActivityController ($scope,$window, $mdSidenav,Principal,$state,$mdMedia,APP,ProspectActivityService,$stateParams,CommonService) {
    	var vm = this;
    	$scope.media = $mdMedia;
    	vm.APP = APP;
    	/*vm.user =  $cookies.getObject('sessionDetails');*/
    	vm.user = CommonService.getCookieUser().cookieUser;
    	vm.investigation = $stateParams;
    	
    	vm.activityLoader = true;
    	vm.activityDetails = function(investigationId){
        	if(investigationId){
        		var data = ProspectActivityService.getActivityDetails(investigationId);
        		  data.then(function(response){
					  vm.activityLoader = false;
					  $scope.translateActivityDate(response.investigationActivity)
        			  vm.activity = response;
        			  $scope.updateProspectName(response.prospectFirstName,response.prospectLastName);
        		  }, function(reason) {
        		return reason;
        		});
        	}
        };
        
        if(vm.investigation != null){
        	vm.activityDetails(vm.investigation.id);
        }
	
		//method for date split in response for spanish translation
		$scope.translateActivityDate = function (investigationActivity) {
			if (investigationActivity.length > 0) {
				angular.forEach(investigationActivity, function (activity) {
					if (activity.date != "" && activity.date != null) {
						activity.month = activity.date.substring(0, 3)
						activity.day = activity.date.substring(3, 7)
						activity.year = activity.date.substring(7, 12)
					}
				});
			}
		}
	}
})();
