(function () {
  "use strict";

  angular.module("lariAidApp").factory("authInterceptor", authInterceptor);

  authInterceptor.$inject = ["$localStorage", "$sessionStorage", '$injector'];

  function authInterceptor($localStorage, $sessionStorage, $injector) {
    function request(config) {
      // ignore if the url is NOT an API endpoint
      if (!/^https?/i.test(config.url)) {
        return config;
      }

      /*jshint camelcase: false */
      config.headers = config.headers || {};
      var token = $localStorage.authenticationToken || $sessionStorage.authenticationToken;
      if (token) {
        config.headers.Authorization = "Bearer " + token;
      } else {
        if (config.url.includes('/api/') && (config.url.includes('investigation') || config.url.includes('form') || config.url.includes('comment') || config.url.includes('user') ||
          config.url.includes('section') || config.url.includes('location') || config.url.includes('entity') || config.url.includes('request'))) {
          var Auth = $injector.get('Auth');
          Auth.logout(true, true);
        }
      }
      return config;
    }

    return { request };
  }
})();
