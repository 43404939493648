// Rename this directive to camelCase to enable camelcase for name fileds
angular.module('lariAidApp').directive('camelCaseDirective', function() {
    return {
      require: 'ngModel',
      link: function(scope, element, attrs, modelCtrl) {
        var capitalize = function(inputValue) {
          if (inputValue == undefined) inputValue = '';
          var capitalized = inputValue.replace(/\S*/g, function(txt){
        	  if(txt.charAt(0) == '"' || txt.charAt(0) == "'")
        		  {
        		  return txt.charAt(0).toUpperCase() + txt.substr(1,1).toUpperCase() + txt.substr(2).toLowerCase();
        		  }
          	else {
          		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          	}});            		       	  
          if (capitalized !== inputValue) {
        	  var cursorPosition = element[0].selectionStart;
        	  modelCtrl.$setViewValue(capitalized);
        	  modelCtrl.$render();
        	  element[0].selectionStart = element[0].selectionEnd = cursorPosition;
          }
          return capitalized;
        }
        modelCtrl.$parsers.push(capitalize);
        capitalize(scope[attrs.ngModel]); // capitalize initial value
      }
    };
  });