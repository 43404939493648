(function() {
    'use strict';

    angular
        .module('lariAidApp')
        .config(stateConfig);
    
    stateConfig.$inject = ['$stateProvider','APP'];
    
    function stateConfig($stateProvider,APP) {
        $stateProvider.state('locations', {
            parent: 'library',
            url: '/locations',
            data: {
                authorities: APP.GLOBAL.USER_GROUP
            },
            params: {
            	libraryType:null
            },
            views: {
                'libraryList': {
                    templateUrl: 'app/library/location/library.location.html',
                    controller: 'LibraryLocationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                	if(localStorage && localStorage.getItem('lari_currentVersion') != null){
                       	$translatePartialLoader.addPart('dashboard.json?version='+localStorage.getItem('lari_currentVersion'));
                       }else{
                       	$translatePartialLoader.addPart('dashboard.json?timestamp='+new Date().getTime());
                       }
                	if(localStorage && localStorage.getItem('lari_currentVersion') != null){
                       	$translatePartialLoader.addPart('library.json?version='+localStorage.getItem('lari_currentVersion'));
                       }else{
                       	$translatePartialLoader.addPart('library.json?timestamp='+new Date().getTime());
                       }
                    return $translate.refresh();
                }],
                mdTabIndex:['tabservice','$timeout', function (tabservice,$timeout) {
                	tabservice.setIndex("3");
                	$timeout(function() {
                	    angular.element('#location').triggerHandler('click');
                	  });
                }]
            }
        });
    }
})();