(function () {
  "use strict";

  var lariDevApp = angular.module("lariAidApp");
  lariDevApp.directive("manageAttachments", function () {
    return {
      restrict: "AE",
      controller: "ManageAttachmentsController",
      templateUrl:
        "app/investigation/prospects/profile/investigation-form/manage-attachments-directive/manage.attachments.html",
      scope: {
        idName: "=idName",
        idFormName: "=idFormName",
        assetList: "=assetList",
        isSummary: "=isSummary",
        isReadOnly: "=isReadOnly",
        recordFlag: "=recordFlag"
      },
      link: function (scope, element, attrs, ngModel) {}
    };
  });
})();
