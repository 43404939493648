(function () {
  "use strict";

  angular.module("lariAidApp").factory("notificationInterceptor", notificationInterceptor);

  notificationInterceptor.$inject = ["$q", "AlertService", "$localStorage", "VERSION"];

  function notificationInterceptor($q, AlertService, $localStorage, VERSION) {
    var service = {
      response: response
    };

    return service;

    function response(response) {
      $localStorage.lari_currentVersion = VERSION;

      //Reload page if the appversion miss match - For initial login page reload
      var currentVersion = $localStorage.lari_currentVersion;
      var appVersion = $localStorage.lari_appVersion;
      if (currentVersion && appVersion != currentVersion) {
        $localStorage.lari_appVersion = $localStorage.lari_currentVersion;
        setTimeout(function () {
          location.reload(true);
          window.location.href = window.location.href;
        });
      }

      var headers = Object.keys(response.headers())
        .filter(function (header) {
          return header.endsWith("app-alert") || header.endsWith("app-params");
        })
        .sort();
      var alertKey = response.headers(headers[0]);
      if (angular.isString(alertKey)) {
        AlertService.success(alertKey, { param: response.headers(headers[1]) });
      }
      return response;
    }
  }
})();
