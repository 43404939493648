(function() {
    'use strict';

    angular
        .module('lariAidApp')
        .service('DNAInformationService', DNAInformationService);

    DNAInformationService.$inject = ['$resource','restService','$q','$rootScope'];
    
    function DNAInformationService ($resource,restService,$q,$rootScope) {
    	
    }
    
})();