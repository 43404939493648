(function() {
    'use strict';

    angular
        .module('lariAidApp')
        .service('OtherService', OtherService);

    OtherService.$inject = ['$resource','restService','$q','$rootScope'];
    
    function OtherService ($resource,restService,$q,$rootScope) {
    	
    	
    		
    }
})();