(function () {
  "use strict";

  angular.module("lariAidApp").controller("UserController", UserController);

  UserController.$inject = [
    "$element",
    "BaseFactory",
    "APP",
    "UserService",
    "$mdMedia",
    "deviceDetector",
    "$sessionStorage",
    "tabservice",
    "$q",
    "$state",
    "$scope",
    "$mdSidenav",
    "StorageDelete",
    "$cookies",
    "CommonService",
    "$mdDialog",
    "$location",
    "$timeout",
    "$stateParams",
    "notifications"
  ];
  function UserController(
    $element,
    BaseFactory,
    APP,
    UserService,
    $mdMedia,
    deviceDetector,
    $sessionStorage,
    tabservice,
    $q,
    $state,
    $scope,
    $mdSidenav,
    StorageDelete,
    $cookies,
    CommonService,
    $mdDialog,
    $location,
    $timeout,
    $stateParams,
    notifications
  ) {
    var vm = this;
    var investigation = {};
    vm.media = $mdMedia;
    vm.selectedUserRoleList = [];
    vm.selectedCasesList = [];
    var qPromises = [];
    vm.userRoles = [];
    vm.investigationCases = [];
    vm.isCallForCount = false;
    vm.loadingListData = false;
    vm.loadingCountData = true;

    vm.APP = APP;
    vm.endless = vm.APP.GLOBAL.ENDLESS;

    vm.investigatorObj = {};
    vm.investigatorObj.language = [];
    vm.investigatorObj.phoneNumber = [];
    vm.investigatorObj.userCountryDTO = {};

    vm.userEndlessScrollRetain = {};

    if ($sessionStorage.userEndlessScrollRetain) {
      vm.userEndlessScrollRetain = $sessionStorage.userEndlessScrollRetain;
    }

    vm.checkUserIndex = function () {
      vm.userTabIndex = tabservice.getIndex();
    };
    vm.checkUserIndex();

    vm.toggleLeft = buildToggler("left");
    vm.toggleRight = buildToggler("right");

    vm.userFilter = {}; // Filters dropdown
    vm.userFilterObj = {}; // Post object
    vm.userFilterObj.from = 0;
    vm.userFilterObj.size = vm.APP.GLOBAL.DEFAULT_SIZE;
    vm.userFilterObj.userRoleKey = [];
    vm.userFilterObj.investigationStatusKey = [];

    var userListTotalLength = 0;

    vm.userList = [];
    var user = {};
    vm.loadingUserData = false;
    vm.allCountry =[];
		vm.selectedAdminList=[];
		vm.savedList=[];
		vm.countryFilter=[];//side filter param
		vm.countrySearchKey = '';//overall SearchKey
		$scope.countryInsideSearch = '';
		vm.regionSearchKey = '';
		vm.allCountries=false;
		vm.deletedItemList = [];
		vm.missingCountryDetails=[];
    vm.allCoutrySelect=[];
    vm.currentDate = new Date();
		vm.endDate = new Date();
		vm.selectAllCountryStartDate = new Date();
		vm.selectAllCountryStartDate='';
		vm.selectAllCountryEndDate = new Date();
		vm.selectAllCountryEndDate = '';
		vm.futureState;
		vm.isSaveCountryAdminDetails = true;
		$sessionStorage.isSaveCountryAdminDetails = true;    	


    //Get user from cookie storage
    vm.user = CommonService.getCookieUser();
    vm.userEmail = vm.user.cookieUser.login;
    vm.loggedUserRole = vm.user.loggedUserRole;
    vm.currentState = $state.current.name;

    vm.searchLocations = function (searchKey) {
      if (searchKey) {
        vm.locationLoader = true;
        var data = CommonService.getRefTermList(vm.APP.REF_TERM.COUNTRY, searchKey);
        data.then(function (response) {
          vm.locationLoader = false;
          vm.locationVO = response;
        });
      }
    };

    var clickingUserTimer;
    vm.getUserList = function (type) {
      clearTimeout(clickingUserTimer);

      clickingUserTimer = setTimeout(function () {
        vm.loadingUserData = true;
        if (type != vm.endless && vm.userEndlessScrollRetain && !vm.userEndlessScrollRetain.index) {
          vm.userList = [];
        }

        if (vm.userList && vm.userList.length == 0) {
          vm.userFilterObj.from = 0;
          userListTotalLength = 0;
        }

        //Set size of the list to the previous size
        if (vm.userEndlessScrollRetain && vm.userEndlessScrollRetain.index) {
          vm.userFilterObj.from = 0;
          vm.userFilterObj.size = vm.userEndlessScrollRetain.from;
        } else {
          vm.userFilterObj.size = vm.APP.GLOBAL.DEFAULT_SIZE;
        }

        /*else if(vm.userList.length > 0){
  	        		vm.userFilterObj.from = vm.userList.length;
  	        	}*/

        if (vm.userFilterObj.from < userListTotalLength || userListTotalLength == 0) {
          var data = UserService.getUserList(vm.userFilterObj);
          data.then(
            function (response) {
              vm.loadingUserData = false;
              vm.noMoreUsers = false;

              if (response && !angular.equals({}, response) && response.totalCount) {
                userListTotalLength = response.totalCount;
                angular.forEach(response.investigationUserlist, function (value, key) {
                  if (vm.userList == null) {
                    vm.userList = [];
                    vm.userList.push(value);
                  } else {
                    vm.userList.push(value);
                  }
                });
                vm.userFilterObj.from = vm.userList.length;

                if (vm.userEndlessScrollRetain && vm.userEndlessScrollRetain.index) {
                  var locationHash = vm.userEndlessScrollRetain.index;
                  $location.hash(locationHash);
                  $timeout(function () {
                    $location.url($location.path());
                  }, 500);
                }
                //Endless retain
                vm.userEndlessScrollRetain.from = vm.userFilterObj.from;

                //Remove index from this object
                if (vm.userEndlessScrollRetain.index) {
                  vm.userEndlessScrollRetain.index = undefined;
                }

                if (vm.userFilterObj.from == userListTotalLength) {
                  vm.loadingUserData = false;
                  vm.loadingListData = false;
                  vm.noMoreUsers = true;
                }

                if (vm.userFilterObj.from <= vm.APP.GLOBAL.DEFAULT_SIZE) {
                  vm.noMoreUsers = false;
                }
              } else if (angular.isUndefined(response)) {
                vm.loadingUserData = false;
                vm.loadingListData = false;
                vm.noMoreUsers = false;
              }
              vm.loadingListData = false;
            },
            function (reason) {
              vm.loadingListData = false;
              return reason;
            }
          );
        } else {
          vm.loadingListData = false;
          vm.loadingUserData = false;
          vm.loadingListData = false;
          vm.noMoreUsers = true;
        }
      }, 200);
    };

    vm.searchUser = function (userSearchKey) {
      vm.userFilterObj.searchKey = userSearchKey;
      $sessionStorage.subUserFilters = userSearchKey;
      if (userSearchKey.length > 1 || userSearchKey.length == 0) {
        vm.getUserList();
      }
      /*else {
        		  vm.userFilterObj.searchKey = userSearchKey;
        	 }*/
    };

    $scope.callListfun = function () {
      vm.getUserList(vm.endless);
    };

    $element.find("input").on("keydown", function (ev) {
      ev.stopPropagation();
    });

    function buildToggler(componentId) {
      return function () {
        $mdSidenav(componentId).toggle();
      };
    }
    vm.stateInternalDetails = function () {
      // The manager edit the country admin at time we call the warning popup functionality
      if (!($sessionStorage.isSaveCountryAdminDetails) && $sessionStorage.userManagementActiveState == vm.APP.USER.ADMIN) {
        vm.futureState = vm.APP.USER.INTERNAL;
        vm.showWarning('warningPopupMsg');
     } else {
        if ($sessionStorage.userManagementActiveState == vm.APP.USER.EXTERNAL || $sessionStorage.userManagementActiveState == vm.APP.USER.ADMIN) {
           StorageDelete.deleteUser();
        }
        $sessionStorage.userManagementActiveState = vm.APP.USER.INTERNAL;
        vm.userTabIndex = 1;
        $state.go(vm.APP.USER.INTERNAL);
     }
     $sessionStorage.futureState = vm.APP.USER.INTERNAL;
    };

    vm.stateExternalDetails = function () {
      if (!($sessionStorage.isSaveCountryAdminDetails) && $sessionStorage.userManagementActiveState == vm.APP.USER.ADMIN) {
        vm.futureState = vm.APP.USER.EXTERNAL;
        vm.showWarning('warningPopupMsg');
     } else {
        if ($sessionStorage.userManagementActiveState == vm.APP.USER.INTERNAL || $sessionStorage.userManagementActiveState == vm.APP.USER.ADMIN) {
           StorageDelete.deleteUser();
        }
        $sessionStorage.userManagementActiveState = vm.APP.USER.EXTERNAL;
        vm.userTabIndex = 2;
        $state.go(vm.APP.USER.EXTERNAL);
     
     }
     $sessionStorage.futureState = vm.APP.USER.EXTERNAL;
    };

    vm.setCurrentStateToAdmin = function () {
      if ($sessionStorage.userManagementActiveState == vm.APP.USER.INTERNAL || $sessionStorage.userManagementActiveState == vm.APP.USER.EXTERNAL) {
         StorageDelete.deleteUser();
      }
      $sessionStorage.userManagementActiveState = vm.APP.USER.ADMIN;
      vm.userTabIndex = 3;
      $state.go(vm.APP.USER.ADMIN);
   };

    vm.goProfile = function (id, index) {
      vm.userEndlessScrollRetain.index = "user" + index;
      $sessionStorage.userEndlessScrollRetain = vm.userEndlessScrollRetain;

      user.id = id;
      // $state.go("userprofile",user);
      $state.go("userprofile", user);
    };

    vm.clearOnClose = function (name, searchKey, dropValue) {
      if (!vm.userFilter[name] || !vm.userFilter[name].id) {
        vm[searchKey] = undefined;
        vm[dropValue] = undefined;
      }
    };

    vm.callSynchronizePromise = function () {
      qPromises = [];

      qPromises.push(vm.getRoleUser());
      qPromises.push(vm.getInvestigationCases());

      $q.all(qPromises).then(function (data) {
        vm.getUserList();
      });
    };

    vm.clearUser = function (modelName, postVariable, searchKeyModel) {
      if (vm.userFilter[modelName] || vm.userFilter[modelName].id) {
        vm.userFilter[modelName] = undefined;
        vm.userFilterObj[postVariable] = undefined;
        if (searchKeyModel) {
          vm[searchKeyModel] = undefined;
        }
      }

      if (vm.media("gt-sm")) {
        vm.callSynchronizePromise();
      }
    };

    /**
     * Function to update vm.userFilterObj from vm.userFilter
     * modelName - Either location or language name
     * postVariable - Assigning Either location or language id to vm.userFilterObj
     *
     */

    vm.updateUserFilterObj = function (modelName, postVariable) {
      if (vm.userFilter[modelName] && vm.userFilter[modelName].id) {
        vm.userFilterObj[postVariable] = vm.userFilter[modelName].id;
      }

      $sessionStorage.userFilter = vm.userFilter;
    };

    /**
     * Function to update vm.userFilterObj from selected filter lists
     * postVariable - Variable name in vm.userFilterObj
     * selectedList - Either vm.selectedUserRoleList or vm.selectedCasesList
     *
     */
    vm.updateUserFilterObjList = function (postVariable, selectedList) {
      vm.userFilterObj[postVariable] = [];
      angular.forEach(selectedList, function (data, key) {
        vm.userFilterObj[postVariable].push(data.id);
      });
    };

    vm.getRoleUser = function (isDefault) {
      vm.showUserRoleDiv = false;
      var data = UserService.getRoleUser(vm.userFilterObj);
      data.then(
        function (response) {
          vm.userRoles = response.userCountList;

          angular.forEach(vm.userRoles, function (data, key) {
            if (data.count > 0) {
              vm.showUserRoleDiv = true; // To hide entire user role div when all count is 0

              // Default RoleUser filter selected
              if (isDefault) {
                data.checked = true;
                vm.selectedUserRoleList.push(data);

                //Default RoleUser filter id selected
                vm.userFilterObj.userRoleKey.push(data.id);
              }
            }
          });

          if (!isDefault) {
            if ($sessionStorage.selectedUserRoleList) {
              vm.selectedUserRoleList = [];
              vm.selectedUserRoleList = $sessionStorage.selectedUserRoleList;
            }

            // Default select after refresh
            angular.forEach(vm.userRoles, function (data, index) {
              angular.forEach(vm.selectedUserRoleList, function (selected, key) {
                if (data.id == selected.id) {
                  data.checked = true;
                }
              });
            });

            /*for(var i=0;i<vm.userRoles.length;i++){
							for(var j=0;j<vm.selectedUserRoleList.length;j++){
								 if(vm.userRoles[i].id == vm.selectedUserRoleList[j].id){
									 vm.userRoles[i].checked = true;
									 break;
								 }  	  	 		
							}
				  	 	}*/
          }

          if (!vm.showUserRoleDiv) {
            vm.selectedUserRoleList = [];
            vm.userRoles = [];
          }
          vm.updateUserFilterObjList(vm.APP.USER.USER_ROLE_KEY, vm.selectedUserRoleList); // Update userFilterObj
          vm.loadingCountData = false;
        },
        function (reason) {
          vm.showUserRoleDiv = true;
          vm.loadingCountData = false;
          return reason;
        }
      );

      return data;
    };

    //Common function for toggle based on the filter type
    vm.toggleUser = function (item, list, type) {
      //To disable checkboxes
      if (vm.media("gt-sm")) {
        vm.loadingListData = true;
      }
      //var idx = list.indexOf(item);
      var idx;
      for (var i = 0; i < list.length; i++) {
        if (list[i].id == item.id) {
          idx = i;
        }
      }
      if (idx > -1) {
        list.splice(idx, 1);
      } else {
        list.push(item);
      }

      if (type == vm.APP.USER.USER_ROLE_FILTER) {
        // Role filter
        $sessionStorage.selectedUserRoleList = list;
        vm.updateUserFilterObjList(vm.APP.USER.USER_ROLE_KEY, vm.selectedUserRoleList);
      } else if (vm.APP.USER.INVESTIGATION_CASES) {
        // Investigation cases
        $sessionStorage.selectedCasesList = list;
        vm.updateUserFilterObjList(vm.APP.USER.INVESTIGATION_STATUS_KEY, vm.selectedCasesList);
      }

      //Trigger the count call
      if (vm.media("gt-sm")) {
        vm.loadingListData = true;
        vm.getRoleUser();
        vm.getInvestigationCases();
        vm.getUserList();
      }

      vm.deleteEndlessDetails();
    };

    vm.getInvestigationCases = function (isDefault) {
      vm.showInvestigationCasesDiv = false;
      var data = UserService.getInvestigationCases(vm.userFilterObj);
      data.then(
        function (response) {
          vm.investigationCases = response.userCountList;

          angular.forEach(vm.investigationCases, function (data, key) {
            if (data.count > 0) {
              vm.showInvestigationCasesDiv = true; // To hide entire user role div when all count is 0

              // Default RoleUser filter selected except deleted user and user with no active cases
              if (
                isDefault &&
                data.name != vm.APP.USER.DELETEDUSERS &&
                data.name != vm.APP.USER.USERWITHNOACTIVECASES
              ) {
                data.checked = true;
                vm.selectedCasesList.push(data);

                //Default RoleUser filter id selected
                vm.userFilterObj.investigationStatusKey.push(data.id);
              }
            }
          });

          if (!isDefault) {
            if ($sessionStorage.selectedCasesList) {
              vm.selectedCasesList = [];
              vm.selectedCasesList = $sessionStorage.selectedCasesList;
            }

            // Default select after refresh
            angular.forEach(vm.investigationCases, function (data, index) {
              angular.forEach(vm.selectedCasesList, function (selected, key) {
                if (data.id == selected.id) {
                  data.checked = true;
                }
              });
            });
          }

          if (!vm.showInvestigationCasesDiv) {
            vm.selectedCasesList = [];
            vm.investigationCases = [];
          }
          vm.updateUserFilterObjList(vm.APP.USER.INVESTIGATION_STATUS_KEY, vm.selectedCasesList); // Update userFilterObj
          vm.loadingCountData = false;
        },
        function (reason) {
          vm.showInvestigationCasesDiv = true;
          vm.loadingCountData = false;
          return reason;
        }
      );
      return data;
    };

    vm.setUserType = function () {
      if (vm.userTabIndex == 1) {
        vm.userFilterObj.isInternalUser = 1; //internal
        user.userType = vm.APP.USER.INTERNAL;
      } else if (vm.userTabIndex == 2) {
        vm.userFilterObj.isInternalUser = 0; //external
        user.userType = vm.APP.USER.EXTERNAL;
      }
      else if(vm.userTabIndex == 3){
        vm.userFilterObj.isInternalUser =0;
        user.userType = vm.APP.USER.ADMIN;
      }
    };
    vm.sideNavClose = function (id) {
      $mdSidenav(id).close();
    };

    vm.getUserSortedList = function (userSortBy, isCallRequired) {
      vm.userFilterObj.sortBy = userSortBy;
      if (userSortBy == vm.APP.USER.SORT_BY_COUNT) {
        vm.userFilterObj.sortOrder = vm.APP.GLOBAL.DESC;
      } else if (userSortBy == vm.APP.USER.SORT_BY_NAME) {
        vm.userFilterObj.sortOrder = vm.APP.GLOBAL.ASC;
      }

      $sessionStorage.userSortBy = userSortBy;

      if (!isCallRequired) {
        vm.getUserList(); //Prevent call from vm.userInit function.
      }
    };

    vm.userInit = function () {
      vm.sideNavClose("left");

      vm.sortOrderList = [
        {
          id: vm.APP.USER.SORT_BY_COUNT,
          value: vm.APP.USER.SORT_BY_COUNT_VALUE
        },
        {
          id: vm.APP.USER.SORT_BY_NAME,
          value: vm.APP.USER.SORT_BY_NAME_VALUE
        }
      ];

      //Default
      if (angular.isUndefined(vm.userSortBy) || angular.isUndefined(vm.userSortBy.value)) {
        vm.userFilterObj.sortBy = vm.APP.USER.SORT_BY_COUNT;
        vm.userFilterObj.sortOrder = vm.APP.GLOBAL.ASC;
      }

      //Sort by initialization through session storage
      if ($sessionStorage.userSortBy) {
        for (var i = 0; i < vm.sortOrderList.length; i++) {
          if (vm.sortOrderList[i].id == $sessionStorage.userSortBy) {
            vm.userSortBy = vm.sortOrderList[i];
            break;
          }
        }

        vm.getUserSortedList(vm.userSortBy.id, true); // To get sort by list
      }

      //Clearing investigation module session storage objects
      StorageDelete.deleteInvestigation();
      StorageDelete.deleteLibrary();

      if ($sessionStorage.subUserFilters) {
        vm.userSearch = $sessionStorage.subUserFilters;
        vm.userFilterObj.searchKey = vm.userSearch;
      }

      var data = BaseFactory.getLanguagesList();
      data.then(function (response) {
        vm.languagesVO = response;
      });

      if ($sessionStorage.selectedUserRoleList) {
        qPromises.push(vm.getRoleUser(false));
      } else {
        qPromises.push(vm.getRoleUser(true));
      }

      if ($sessionStorage.selectedCasesList) {
        qPromises.push(vm.getInvestigationCases(false));
      } else {
        qPromises.push(vm.getInvestigationCases(true));
      }

      $q.all(qPromises).then(function (data) {
        vm.getUserList();
      });

      //Retaining userFilter drop down value
      if ($sessionStorage.userFilter) {
        vm.userFilter = $sessionStorage.userFilter;
        var userFilterKeys = Object.keys(vm.userFilter);

        //Assigning vm.userFilter ids into Post Object for Listing
        var postVariable = "";
        angular.forEach(userFilterKeys, function (keyName, index) {
          if (keyName) {
            postVariable = keyName + "Id";
            if (vm.userFilter[keyName]) {
              vm.userFilterObj[postVariable] = vm.userFilter[keyName].id;
            }
          }
        });
      }

      vm.setUserType(); // Set isInternalUser in userFilterObject
    };

    //ADD INVESTIGATOR POPUP

    vm.openAddEditPopup = function (ev, id, flag) {
      vm.investigator = {};
      vm.investigator.comment = "";
      $scope.addInvestigatorForm = {};
      vm.popupTitleFlag = flag;
      vm.existFlag = false;
      vm.saveDisableFlag = false;
      vm.updateExistFlag = false;
      vm.updateDisableFlag = false;
      $mdDialog.show({
        targetEvent: ev,
        contentElement: "#" + id,

        //Added by senthil to set pristine while click ESC key in popup
        onRemoving: function (ev, removePromise) {
          $timeout(function () {
            angular.element("#cancelAddEditInvForm").triggerHandler("click");
          });
        },
        parent: angular.element(document.body)
      });
    };

    vm.initLanguage = function () {
      var data = BaseFactory.getLanguagesList();
      data.then(function (response) {
        vm.languagesVO = response;
      });
    };

    vm.clearLocation = function (searchKey, listValue) {
      vm[searchKey] = undefined;
      vm[listValue] = undefined;
    };

    vm.cancel = function (addInvestigatorForm) {
      addInvestigatorForm.$setPristine();
      vm.investigator = {};
      vm.investigator.emailAddress = "";
      $mdDialog.hide();
    };

    vm.saveInvestigator = function (investigator, addInvestigatorForm) {
      if (addInvestigatorForm.$valid && investigator) {
        vm.saveDisableFlag = true;
        if (investigator.country != null) {
          vm.investigatorObj.userCountryDTO.countryId = investigator.country.id;
          vm.investigatorObj.userCountryDTO.comment = investigator.comment;
        }

        angular.forEach(investigator.language, function (obj, index) {
          var lang = {};
          lang.languageId = obj.id;
          vm.investigatorObj.language.push(lang);
        });

        var phone = {};
        if (investigator.phoneNumber != null && investigator.phoneNumber != "") {
          phone.value = investigator.phoneNumber;
          phone.phoneType = vm.APP.USER.PHONETYPE;
          phone.phoneEntityType = vm.APP.USER.ENTITYTYPE;
        }

        vm.investigatorObj.phoneNumber.push(phone);

        vm.investigatorObj.firstName = investigator.firstName;
        vm.investigatorObj.lastName = investigator.lastName;
        if (investigator.emailAddress != null && investigator.emailAddress != "") {
          vm.investigatorObj.emailAddress = investigator.emailAddress;
        }

        var data = UserService.addInvestigator(vm.investigatorObj);
        data.then(
          function (response) {
            vm.cancel(addInvestigatorForm);
            $state.transitionTo($state.current, $stateParams, {
              reload: true,
              inherit: false,
              notify: true
            });
            notifications.showSuccess({ message: vm.APP.LIBRARY.USER_SAVE });
          },
          function (reason) {
            if (reason.status == 409) {
              vm.existFlag = true;
              vm.saveDisableFlag = false;
            } else {
              notifications.showError({ message: vm.APP.LIBRARY.USER_SAVE_PROBLEM });
            }
            $timeout(function () {
              vm.existFlag = false;
            }, 3000);
          }
        );
      }
    };

    vm.deleteEndlessDetails = function () {
      vm.userEndlessScrollRetain = {};
      delete $sessionStorage.userEndlessScrollRetain;
    };

      /**
       * Get the country admin details call 
       * @author Ganeshkumar.K
       */

      vm.getAdminDetails = function () {
        vm.getAllCountry();
        setTimeout(function () {
          vm.countryFilterIds = [];
          vm.countryAdminData = [];
          if (vm.countryFilter.length > 0) {
              vm.isFilterEnable = true;
              angular.forEach(vm.countryFilter, function (country, index) {
                vm.countryFilterIds.push(country.id);
              });
          }
          vm.adminLoader = true;
          vm.initialLoading = true;
          var data = UserService.getAdminDetails(vm.countryFilterIds, vm.countrySearchKey);
          data.then(function (response) {
                vm.countryAdminData = response;
                if (vm.savedList.length > 0) {
                    for (var i = 0; i < vm.countryAdminData.length; i++) {
                      for (var j = 0; j < vm.savedList.length; j++) {
                          if (vm.savedList[j].userId === vm.countryAdminData[i].userId) {
                            vm.countryAdminData.splice(i, 1);
                            vm.countryAdminData.push(vm.savedList[j]);
                          }
                      }
                    }
                }

                angular.forEach(vm.countryAdminData, function (adminData, index) {
                    if (adminData.selectedAll) {
                      adminData.country = '';
                      adminData.country = vm.allCountry;
                    }
                });
                angular.forEach(vm.countryAdminData, function (adminData, index) {
                    angular.forEach(adminData.country, function (selected, selectindex) {
                      angular.forEach(vm.allCountry, function (all, countryIndex) {
                          if (all.id == selected.id) {
                            adminData.country[selectindex] = all;
                          }
                          if (selected.name == 'No country') {
                            adminData.country = [];
                          }
                      });
                    });
                });
                if (vm.countryFilter.length == 0) {
                    vm.filterAllCountry = vm.allCountry;
                }
                vm.adminLoader = false;
              },
              function (error) {
                vm.adminLoader = false;
                vm.countryAdminData = [];
                notifications.showError({
                    message: vm.APP.LIBRARY.COUNTRY_ADMIN_GET_PROBLEM
                });

              }
          );
        }, 1000);
      };

      /**
      * Get the all prospect birth country details
      * @returns 
      */

      vm.getAllCountry = function () {
        var data = CommonService.getBirthCountry();
        data.then(function (response) {
          vm.allCountry = response;
        });
      };

      /**
      * Check if an item exists or not in the list.
      * @param {Array} item 
      * @param {Array} list 
      * @returns {boolean}
      */

      vm.exists = function (item, list) {
        return list.indexOf(item) > -1;
      };

      /**
      * common Save call for countryAdmin Details
      */

      vm.saveCountryAdminDetails = function () {
        vm.isSaveCountryAdminDetails = false;
        if (vm.savedList.length > 0) {
          angular.forEach(vm.savedList, function (list, index) {
              list.updatedByUserEmail = vm.userEmail;
              if (list.startDate != null) {
                list.startDate = new Date(list.startDate);
              }
              if (list.endDate != null) {
                list.endDate = new Date(list.endDate);
              }

              if (list.country.length == vm.allCountry.length) {
                list.selectedAll = true;
                list.country = [];
              } else {
                list.selectedAll = false;
              }
          });
          vm.adminLoader = true;
          var data = UserService.saveAdminDetails(vm.savedList);
          data.then(function (response) {
              if (response) {
                notifications.showSuccess({
                    message: vm.APP.LIBRARY.COUNTRY_ADMIN_SAVE
                });
                vm.savedList = [];
                vm.selectedAdminList = [];
                vm.clearSelectedList();
                vm.getAdminDetails();
                $mdDialog.hide();
              }
          }, function (reason) {
              vm.adminLoader = false;
              if (reason) {
                notifications.showError(
                  reason.data.body.message
                );
              }
          });
          vm.isSaveCountryAdminDetails = true;
          $sessionStorage.isSaveCountryAdminDetails = true;
        }
      };

      vm.toggle = function (item, list) {
        var idx = list.indexOf(item);
        if (idx > -1) {
          list.splice(idx, 1);
        } else {
          list.push(item);
        }
        if (list.length == 0) {
          vm.clearSelectedList();
        }
      };

      vm.toggleAll = function () {
        if (vm.selectedAdminList.length === vm.countryAdminData.length) {
          vm.selectedAdminList = [];
          vm.clearSelectedList();
        } else if (vm.selectedAdminList.length === 0 || vm.selectedAdminList.length > 0) {
          vm.selectedAdminList = vm.countryAdminData.slice(0);
        }
      };
      /**
      * method for filters selectall countries 
      */

      vm.selectAllCountries = function () {
        vm.countryFilter = [];
        if (!vm.allCountries) {
          angular.forEach(vm.filterAllCountry, function (item) {
              item.hidden = true;
          });
          $timeout(function () {
              angular.forEach(vm.filterAllCountry, function (item) {
                vm.countryFilter.push(item);
              });
              vm.regionsLoader = false;
          }, 0);

        } else {
          angular.forEach(vm.filterAllCountry, function (item) {
              item.hidden = false;
          });
          vm.regionsLoader = false;
        }
      };

      /**
      * show the dialog box when click a edit button in admins tab
      * @param {Event} $event 
      * @param {String} id 
      * @param {*} index 
      */

      vm.showDialog = function ($event, id, index) {
        if (!(angular.isUndefined(index))) {
          vm.dialogIndex = index;
        }
        $mdDialog.show({
          contentElement: '#' + id,
          parent: angular.element(document.body),
          targetEvent: $event,
          clickOutsideToClose: true
        });
      };
      /**
      * Close method for Dialog box
      * @author Ganeshkumar.K
      */

      vm.closeDialog = function () {
        if (vm.missingCountryDetails.length > 0) {
          angular.forEach(vm.missingCountryDetails, function (data, index) {
              var idx = vm.deletedItemList.indexOf(data);
              if (idx == -1) {
                vm.deletedItemList.push(data);
              }
          });
          $mdDialog.hide();
          vm.missingCountryDetails = [];
        } else {
          $mdDialog.hide();
        }
      };

      /**
      * 
      * @param {*} selectedList 
      * @param {*} overallData 
      * @param {*} type 
      * @return {Boolean}
      */
      vm.isChecked = function (selectedList, overallData, type) {
        if (selectedList != null && overallData != null) {
          if (type == 'country') {
              selectedList.selectedAll = (selectedList.country.length === overallData.length);
              return selectedList.length === overallData.length;
          } else if (type == 'overallSelect') {
              vm.selectedAll = (selectedList.length === overallData.length);
              return selectedList.length === overallData.length;
          } else {
              return selectedList.length === overallData.length;
          }
        }
      };
      /**
      * functionality for overall select
      */

      vm.setCountryForSelectedPlayer = function (type) {
        if (vm.selectedAdminList.length > 0) {
          if (type == 'country') {
              angular.forEach(vm.selectedAdminList, function (list, index) {
                angular.forEach(vm.countryAdminData, function (data, index) {
                    if (data.userId == list.userId) {
                      data.country = '';
                      data.country = vm.allCoutrySelect;
                      vm.changedAdminList(data);
                    }
                });
              });
          } else if (type == 'startDate') {
              angular.forEach(vm.selectedAdminList, function (list, index) {
                angular.forEach(vm.countryAdminData, function (data, countryindex) {
                    if (data.userId == list.userId && data.country.length > 0) {
                      data.startDate = vm.selectAllCountryStartDate;
                      vm.changedAdminList(data);
                    }
                });
              });

          } else if (type == 'endDate') {
              angular.forEach(vm.selectedAdminList, function (list, index) {
                angular.forEach(vm.countryAdminData, function (data, countryindex) {
                    if (data.userId == list.userId && data.startDate != null && data.country.length > 0) {
                      data.endDate = vm.selectAllCountryEndDate;
                      vm.changedAdminList(data);
                    }
                });
              });
          } else if (type == 'selectedAll') {
              if (!vm.selectedAll) {
                vm.allCoutrySelect = vm.allCountry;
                angular.forEach(vm.selectedAdminList, function (list, index) {
                    angular.forEach(vm.countryAdminData, function (data, countryindex) {
                      if (data.userId == list.userId) {
                          data.country = '';
                          data.country = vm.allCoutrySelect;
                          vm.changedAdminList(data);
                      }
                    });
                });

              } else {
                vm.allCoutrySelect = [];
                angular.forEach(vm.selectedAdminList, function (list, index) {
                    angular.forEach(vm.countryAdminData, function (data, countryindex) {
                      if (data.userId == list.userId) {
                          data.country = '';
                          data.country = vm.allCoutrySelect;
                          vm.changedAdminList(data);
                      }
                    });
                });
              }
          }
        }
      };

      /**
      * Get the minimum date based on each admin's start date.
      * @param {Date} startDate 
      * @since 25-11-2023
      * @author Ganeshkumar.k
      * 
      */

      vm.getMinDate = function (startDate) {
        vm.minDate = new Date(startDate);
      };

      /**
      * clear the overall selectedlist 
      */

      vm.clearSelectedList = function () {
        if (vm.selectedAdminList.length == 0) {
          vm.allCoutrySelect = [];
          vm.selectAllCountryStartDate = '';
          vm.selectAllCountryEndDate = '';
        }
      };
      /**
      * when user edit the country admin the details will saved in savedList
      * @param {*} countryDetails 
      * 
      */

      vm.changedAdminList = function (countryDetails) {
        vm.clearEndDateBasedOnStartDate(countryDetails);
        var idx = vm.savedList.indexOf(countryDetails);
        if (idx > -1) {
          vm.savedList.splice(idx, 1);
          vm.savedList.push(countryDetails);
        } else {
          vm.savedList.push(countryDetails);
        }
        var tmp = false;
        angular.forEach(vm.savedList, function (list, index) {
          if (list.country.length > 0 && angular.isUndefined(list.startDate)) {
              tmp = true;
          }
        });
        if ($sessionStorage.isSaveCountryAdminDetails) {
          delete $sessionStorage.isSaveCountryAdminDetails
          $sessionStorage.isSaveCountryAdminDetails = false;
        }
        vm.isSaveCountryAdminDetails = tmp;
      };

      /**
      * save the country admin details when list is changed 
      */

      vm.isChanged = function (country) {
        if (!vm.initialLoading) {
          vm.changedAdminList(country);
        }
      };
      /**
      * show the warning message to user when move to next tab without saving the country admin details
      * @param {Element} popupId 
      */

      vm.showWarning = function (popupId) {
        var event = this;
        $mdDialog.show({
          contentElement: angular.element('#' + popupId),
          targetEvent: event,
          clickOutsideToClose: false,
          escapeToClose: false
        });
      };

      /**
      * The function call when user select yes in the warning popup
      * 
      */


      vm.changeSection = function () {
        if ($sessionStorage.userManagementActiveState == vm.APP.USER.ADMIN) {
          StorageDelete.deleteUser();
        }
        if ($sessionStorage.futureState != null) {
          vm.futureState = $sessionStorage.futureState;
          $sessionStorage.userManagementActiveState = vm.futureState;
          $state.go(vm.futureState);
          if (vm.futureState == vm.APP.USER.INTERNAL) {
              vm.userTabIndex = 1;
          } else if (vm.futureState == vm.APP.USER.EXTERNAL) {
              vm.userTabIndex = 2;
          }
          vm.setUserType();
        }
      };

      vm.selectAllClick = function (country) {
        if (!country.selectedAll) {
          country.country = '';
          country.country = vm.allCountry;
          vm.changedAdminList(country);
        } else {
          country.country = [];
          vm.changedAdminList(country);
        }
      }
      /**
      * Set the placeholder for country Dropdown
      * @since 06-12-2023	 
      * @param {*} country 
      * @param {*} index 
      * @param {*} type 
      */

      vm.setCountryPlaceholder = function (country, index, type) {
        if (type == 'dialog') {
          if (!angular.isUndefined(country)) {
              if (country.length > 1) {
                var element = document.getElementById("dialogCountry-" + index);
                var header = (country.length == vm.allCountry.length ? "All Countries Selected" : "(" + country.length + ")" + ' ' + vm.APP.INVESTIGATION.COUNTRIES + ' ' + "selected");
                this.setHeaderContent(element, header);
              }
          }
        } else if (type == 'SelectAll') {
          if (!angular.isUndefined(country)) {
              if (country.length > 1) {
                var element = document.getElementById("OverSelectAll");
                var header = (country.length == vm.allCountry.length ? "All Countries Selected" : "(" + country.length + ")" + ' ' + vm.APP.INVESTIGATION.COUNTRIES + ' ' + "selected");
                this.setHeaderContent(element, header);
              }
          }
        } else if (type == 'DialogSelectAll') {
          if (!angular.isUndefined(country)) {
              if (country.length > 1) {
                var element = document.getElementById("dialogCountrySelectAll");
                var header = (country.length == vm.allCountry.length ? "All Countries Selected" : "(" + country.length + ")" + ' ' + vm.APP.INVESTIGATION.COUNTRIES + ' ' + "selected");
                this.setHeaderContent(element, header);
              }
          }
        } else if (type == 'sideFilter') {
          var element = document.getElementById("sideFilter");
          if (country.length == vm.allCountry.length) {
              var header = "All Countries Selected";
              this.setHeaderContent(element, header);
          }
        } else {
          if (!angular.isUndefined(country)) {
              if (country.length > 1) {
                var element = document.getElementById("counrtryDropdown-" + index);
                var header = (country.length == vm.allCountry.length ? "All Countries Selected" : "(" + country.length + ")" + ' ' + vm.APP.INVESTIGATION.COUNTRIES + ' ' + "selected");
                this.setHeaderContent(element, header);
              }
          }
        }
      };
      /**
      * Set the Headercontent based on element
      * @param {HTMLAnchorElement} element 
      * @param {String} header 
      */

      vm.setHeaderContent = function (element, header) {
        if (element != null) {
          var child1 = element.children[0];
          if (child1 != null) {
              var child2 = child1.children[0];
              if (child2 != null) {
                child2.innerHTML = header;
              }
          }
        }
      }


      /**
      * Clear the filter value 
      */

      vm.clear = function () {
        if (vm.isFilterEnable) {
          vm.countryFilter = [];
          vm.allCountries = false;
          vm.isFilterEnable = false;
          vm.getAdminDetails();
        } else if (vm.countryFilter.length > 0) {
          vm.countryFilter = [];
          vm.allCountries = false;
          vm.isFilterEnable = false;
          vm.getAdminDetails();

        }
      };

      /**
      * Individual save call for save the country Admin details
      */

      vm.individualSaveCountryDetails = function () {
        var tempList = [];
        if (vm.dialogIndex != null) {
          tempList.push(vm.countryAdminData[vm.dialogIndex]);
        }
        var idx = vm.savedList.indexOf(vm.countryAdminData[vm.dialogIndex]);
        if (idx > -1) {
          vm.savedList.splice(idx, 1);
        }
        if (tempList.length > 0) {
          angular.forEach(tempList, function (list, index) {
              list.updatedByUserEmail = vm.userEmail;
              if (list.startDate != null) {
                list.startDate = new Date(list.startDate);
              }
              if (list.endDate != null) {
                list.endDate = new Date(list.endDate);
              }

              if (list.country.length == vm.allCountry.length) {
                list.selectedAll = true;
                list.country = [];
              } else {
                list.selectedAll = false;
              }
          });
          vm.adminLoader = true;
          var data = UserService.saveAdminDetails(tempList);
          data.then(function (response) {
              if (response) {
                notifications.showSuccess({
                    message: vm.APP.LIBRARY.COUNTRY_ADMIN_SAVE
                });
                vm.isSaveCountryAdminDetails = (vm.savedList.length == 0 ? true : false);
                $sessionStorage.isSaveCountryAdminDetails = vm.isSaveCountryAdminDetails;
                $mdDialog.hide();
                vm.selectedAdminList = [];
                vm.clearSelectedList();
                vm.getAdminDetails();

              }
          }, function (reason) {
              if (reason) {
                notifications.showError({
                    message: vm.APP.LIBRARY.COUNTRY_ADMIN_SAVE_PROBLEM
                });
                vm.adminLoader = false;
                $mdDialog.hide();
                vm.isSaveCountryAdminDetails = (vm.savedList.length == 0 ? true : false);
                $sessionStorage.isSaveCountryAdminDetails = vm.isSaveCountryAdminDetails;
              }
          });
        }

      };

      /**
      * 
      * @param {*} index 
      * show the warning popup when country admin have active investigation
      */

      vm.checkInvestigationWarning = function (index) {
        vm.missingCountryDetails = [];
        if (index > -1) {
          var tempSelectedCountryDetails = [];
          angular.forEach(vm.countryAdminData[index].country, function (country, idx) {
              tempSelectedCountryDetails.push(country.id);
          });
          angular.forEach(vm.countryAdminData[index].assignCountry, function (assignCountry, cidx) {
              var idx = tempSelectedCountryDetails.indexOf(assignCountry.country.id);
              if (idx == -1) {
                vm.missingCountryDetails.push(assignCountry);
              }
          });
        }
        //overall 
        else {
          angular.forEach(vm.selectedAdminList, function (adminData, aidx) {
              var tempSelectedCountryDetails = [];
              angular.forEach(adminData.country, function (country, cidx) {
                tempSelectedCountryDetails.push(country.id);
              });

              angular.forEach(adminData.assignCountry, function (assignCountry, aidx) {
                var idx = tempSelectedCountryDetails.indexOf(assignCountry.country.id);
                if (idx == -1) {
                    vm.missingCountryDetails.push(assignCountry);
                }

              });

          });
        }
        if (vm.deletedItemList.length > 0) {
          for(var i=0;i<vm.missingCountryDetails.length;i++) {
              var idx = vm.deletedItemList.indexOf(data);
              if (idx > -1) {
                vm.missingCountryDetails.splice(index, 1);
              }

          }
        }

        if (vm.missingCountryDetails.length > 0) {
          vm.showWarning('warningPopupMsgForActiveInvestigation');
        }
      };

      /**
      * check the MandatoryField for individuals 
      * @param {Object} individualCountryAdminData 
      * @returns {Boolean}
      */
      vm.checkMandatoryFieldForIndividualCountryAdmin = function (individualCountryAdminData) {
        if (angular.isDefined(individualCountryAdminData)) {
          if (vm.savedList.indexOf(individualCountryAdminData) == -1) {
              return true;
          } else {
              return (individualCountryAdminData.country.length > 0 && angular.isUndefined(individualCountryAdminData.startDate) ? true : false);
          }
        }
      };

      /**
	 * Clear the endDate based on a start date
	 * @param {Object} countryData 
	 */

	vm.clearEndDateBasedOnStartDate= function(countryData){
		var stateDateTemp = new Date(countryData.startDate);
		var endDateTemp = new Date(countryData.endDate);
		if(angular.isDefined(countryData.country) && stateDateTemp > endDateTemp){
			countryData.endDate = '';
		}
	};

  };
})();
