(function() {
	'use strict';

    angular
        .module('lariAidApp')
        .controller('LibraryLocationController', LibraryLocationController);
    
    LibraryLocationController.$inject = ['$rootScope','$scope','$state','$stateParams','$mdSidenav','$element','APP','tabservice','$mdMedia','LibraryLocationService','$q','$timeout','$sessionStorage','LibraryRequestService','$mdDialog','CommonService','communicationService','LibraryService','notifications'];
    
    function LibraryLocationController($rootScope,$scope,$state,$stateParams,$mdSidenav,$element,APP,tabservice,$mdMedia,LibraryLocationService,$q,$timeout,$sessionStorage,LibraryRequestService,$mdDialog,CommonService,communicationService,LibraryService,notifications){
    	
    	var vm = this;
    	vm.APP = APP;
    	vm.library = $stateParams;
    	vm.media = $mdMedia;
    	//Get user from cookie storage
 		vm.user = CommonService.getCookieUser();
 		vm.loggedUserRole = vm.user.loggedUserRole;
    	vm.libraryLocObj = {};
    	vm.filterObj = {};
    	var listTotalLength = 0;
    	vm.filterObj.from = 0;
        vm.filterObj.size = vm.APP.GLOBAL.DEFAULT_SIZE;
        vm.locationList = [];
  	    vm.loadingData = false;
	    vm.endless = vm.APP.GLOBAL.ENDLESS;
	    vm.searchLocationCountryList = [];
	    vm.searchLocationProvinceList = [];
	    
	    $scope.libraryLocationObject = {};
	    $scope.libraryLocationObject.countryObj = {};
	    $scope.libraryLocationObject.provinceObj = {};
	    $scope.libraryLocationObject.cityObj = {};
	    $scope.libraryLocationObject.sectorObj = {};
	    $scope.libraryLocationInputSearchObject = {};
	    $scope.libraryLocationInputSearchObject.country = ''; 
    	$scope.libraryLocationInputSearchObject.province = '';
    	$scope.libraryLocationInputSearchObject.city = '';
    	$scope.libraryLocationInputSearchObject.countrySearchKey = '';
    	$scope.libraryLocationInputSearchObject.provinceSearchKey = '';
    	$scope.libraryLocationInputSearchObject.citySearchKey = '';
    	$scope.libraryLocationInputSearchObject.sectorSearchKey = '';
    	$scope.libraryLocationInputSearchObject.isAddOrSaveLocationCountry = false;
    	$scope.libraryLocationInputSearchObject.isAddOrSaveLocationProvince = false;
    	$scope.libraryLocationInputSearchObject.isAddOrSaveLocationCity = false;
    	$scope.libraryLocationInputSearchObject.isAddOrSaveLocationSector = false;
    	
    	$scope.libraryLocationInputObject = {};
    	$scope.libraryLocationInputObject.country = false;
    	$scope.libraryLocationInputObject.province = false;
    	$scope.libraryLocationInputObject.city = false;
    	$scope.libraryLocationInputObject.sector = false;
    	
 		vm.editLocationObject = {};
    	vm.editLocationObject.countryObj = {};
    	vm.editLocationObject.provinceObj = {};
    	vm.editLocationObject.cityObj = {};
    	vm.editLocationObject.sectorObj = {};
    	
    	$scope.editLocationInputSearchObject = {};
    	$scope.editLocationInputSearchObject.country = ''; 
    	$scope.editLocationInputSearchObject.province = '';
    	$scope.editLocationInputSearchObject.city = '';
    	$scope.editLocationInputSearchObject.countrySearchKey = '';
    	$scope.editLocationInputSearchObject.provinceSearchKey = '';
    	$scope.editLocationInputSearchObject.citySearchKey = '';
    	$scope.editLocationInputSearchObject.sectorSearchKey = '';
    	$scope.editLocationInputSearchObject.isAddOrSaveLocationCountry = false;
    	$scope.editLocationInputSearchObject.isAddOrSaveLocationProvince = false;
    	$scope.editLocationInputSearchObject.isAddOrSaveLocationCity = false;
    	$scope.editLocationInputSearchObject.isAddOrSaveLocationSector = false;
    	
    	$scope.editLocationInputObject = {};
    	$scope.editLocationInputObject.country = false;
    	$scope.editLocationInputObject.province = false;
    	$scope.editLocationInputObject.city = false;
    	$scope.editLocationInputObject.sector = false;
    	
    	$scope.libraryAddEntityInputSearchObject = {};
	    $scope.libraryAddEntityInputSearchObject.country = ''; 
    	$scope.libraryAddEntityInputSearchObject.province = '';
    	$scope.libraryAddEntityInputSearchObject.city = '';
    	$scope.libraryAddEntityInputSearchObject.countrySearchKey = '';
    	$scope.libraryAddEntityInputSearchObject.provinceSearchKey = '';
    	$scope.libraryAddEntityInputSearchObject.citySearchKey = '';
    	$scope.libraryAddEntityInputSearchObject.sectorSearchKey = '';
    	$scope.libraryAddEntityInputSearchObject.isAddOrSaveLocationCountry = false;
    	$scope.libraryAddEntityInputSearchObject.isAddOrSaveLocationProvince = false;
    	$scope.libraryAddEntityInputSearchObject.isAddOrSaveLocationCity = false;
    	$scope.libraryAddEntityInputSearchObject.isAddOrSaveLocationSector = false;
    	
    	vm.loggedUserRole = CommonService.getCookieUser().loggedUserRole;
    	
	    vm.isHorizon = false;  	  
  	  	vm.searchLocationDetails = function(searchKeyTxt) {
  		  vm.filterObj.searchKey = searchKeyTxt;
    	  if(searchKeyTxt.length > 1 || searchKeyTxt.length == 0){
    		 vm.getLocationList();
    	  }
    	  /*else {
    		 vm.filters.searchKey = searchKeyTxt;
    	 }*/
  	  }
  	  	
  	  vm.locationProfileList = [{
		  	"id":vm.APP.LIBRARY.ALL,
		  	"val":"allProfile"
	    },{
		  	"id":vm.APP.LIBRARY.COUNTRY,
		  	"val":"countryProfile"
	    },
	    {
	    	"id":vm.APP.LIBRARY.PROVINCE,
	    	"val":"provinceProfile"
	    },
	    {
	    	"id":vm.APP.LIBRARY.CITY,
	    	"val":"cityProfile"
	    },
	    {
	    	"id":vm.APP.LIBRARY.SECTOR,
	    	"val":"sectorProfile"
	  }];
  	  
  	  vm.getlocationProfileList = function(profileList,isCallRequired) {
     	vm.filterObj.profileList = profileList;
     	if(profileList == vm.APP.LIBRARY.ALL) {
     		vm.filterObj.locationProfileKey= '';
     	} else if (profileList == vm.APP.LIBRARY.COUNTRY) {
     		vm.filterObj.locationProfileKey = vm.APP.LIBRARY.COUNTRY_CAPS;
     	}else if (profileList == vm.APP.LIBRARY.PROVINCE) {
     		vm.filterObj.locationProfileKey = vm.APP.LIBRARY.PROVINCE_CAPS;
     	}else if (profileList == vm.APP.LIBRARY.CITY) {
     		vm.filterObj.locationProfileKey = vm.APP.LIBRARY.CITY_CAPS;
     	}else if (profileList == vm.APP.LIBRARY.SECTOR) {
     		vm.filterObj.locationProfileKey = vm.APP.LIBRARY.SECTOR_CAPS;
     	}
     	
     	     	
     	if(!isCallRequired){
     		vm.getLocationList('');  //Prevent call from vm.investigationInit function.
     	}
   	  }
  	  
  	  vm.locationSortedList = [{
  		  	"id":"approvedOn",
  		  	"val":"sortbyapproved"
	    },
	    {
	    	"id":"sector,city,province,country",
	    	"val":"sortbyname"
	  }];
  	  
  	  
  	  vm.getlocationSortedList = function(sortBy,isCallRequired) {
    	vm.filterObj.sortBy = sortBy;
    	if(sortBy == 'approvedOn') {
    		vm.filterObj.sortOrder = 'desc';
    	} else if (sortBy == 'sector,city,province,country') {
    		vm.filterObj.sortOrder = 'asc';
    	}
    	
    	$sessionStorage.locationSortBy = sortBy;
    	
    	if(!isCallRequired){
    		vm.getLocationList('');  //Prevent call from vm.investigationInit function.
    	}
  	  }
  	  
  	  //Sort by initialization through session storage
  	  if($sessionStorage.locationSortBy){
  		for(var i=0;i<vm.locationSortedList.length;i++){
  			if(vm.locationSortedList[i].id == $sessionStorage.locationSortBy){
  				vm.sortBy = vm.locationSortedList[i];
  				break;
  			}
  		}
  		vm.getlocationSortedList(vm.sortBy.id,true);  // To get sort by list 
  	  }
  	  
  	  vm.getLocationList = function(type) {
  		if($scope.libraryLocationObject.countryObj && $scope.libraryLocationObject.countryObj.countryId) {
    		vm.filterObj.countryId = $scope.libraryLocationObject.countryObj.countryId;
    	}  else {
    		vm.filterObj.countryId = '';
    	}
    	if($scope.libraryLocationObject.provinceObj && $scope.libraryLocationObject.provinceObj.provinceId) {
    		vm.filterObj.provinceId = $scope.libraryLocationObject.provinceObj.provinceId;
    	} else {
    		vm.filterObj.provinceId = '';
    	}
    	if($scope.libraryLocationObject.cityObj && $scope.libraryLocationObject.cityObj.cityId) {
    		vm.filterObj.cityId =  $scope.libraryLocationObject.cityObj.cityId;
    	} else {
    		vm.filterObj.cityId = '';
    	}
    	if($scope.libraryLocationObject.sectorObj && $scope.libraryLocationObject.sectorObj.sectorId) {
    		vm.filterObj.sectorId =  $scope.libraryLocationObject.sectorObj.sectorId;
    	} else {
    		vm.filterObj.sectorId = '';
    	}
    	if(angular.isUndefined(vm.sortBy) || angular.isUndefined(vm.sortBy.val)) {
    		vm.filterObj.sortBy = 'approvedOn';
    		vm.filterObj.sortOrder = 'desc';
    	} 
    	vm.loadingData = true;
  		  if(type != vm.endless){
  			  vm.locationList = [];
  		  }
  		  if(vm.locationList && vm.locationList.length == 0){
  			  vm.filterObj.from = 0;
  			  listTotalLength = 0;
	      } else if(vm.locationList.length > 0){
    		vm.filterObj.from = vm.locationList.length;
    	  }
  		  if(vm.locationSearchKey != undefined && (vm.locationSearchKey.length > 1 || vm.locationSearchKey.length == 0) ){
  			  vm.filterObj.searchKey = vm.locationSearchKey;
  		  }
  		  
  		  $sessionStorage.locFilter = $scope.libraryLocationObject;
  		  
  		  if(vm.filterObj.from < listTotalLength || listTotalLength == 0){
  			  var data = LibraryLocationService.getLocationLists(vm.filterObj);
  			  data.then(function(response){
  				  vm.loadingData = false;
  				  vm.noMoreMembers = false;
  				  if(response && !angular.equals({}, response) && response.totalCount){
  					  listTotalLength = response.totalCount;
						  //approvedDate split in response for spanish translation
						  angular.forEach(response.approvedLocationDTOList, function (value, key) {
							  if (value.approvedDate != "" && value.approvedDate != null) {
								  value.approvedLocationMonth = value.approvedDate.substring(0, 3)
								  value.approvedLocationDay = value.approvedDate.substring(3, 7)
								  value.approvedLocationYear = value.approvedDate.substring(7, 12)
							  }
  						  if(vm.locationList == null){
  							  vm.locationList = [];
  							  vm.locationList.push(value);
  						  } else{
  							  vm.locationList.push(value);
  						  }
  					  });
  					  vm.filterObj.from = vm.locationList.length;
  					  if(vm.filterObj.from == listTotalLength){
  						  vm.loadingData = false;
  						  vm.noMembers = false;
  						  vm.noMoreMembers = true;
	       			  }
  					  if(vm.filterObj.from <= vm.APP.GLOBAL.DEFAULT_SIZE){
  						  vm.noMoreMembers = false;
  					  }
  				  } else if(angular.isUndefined(response)){
  					  vm.loadingData  = false;
  					  vm.noMembers = true;
  					  vm.noMoreMembers = false;
  				  }
  				if($sessionStorage.locFilter && Object.keys($sessionStorage.locFilter).length > 0){
    		  		  $scope.libraryLocationObject = $sessionStorage.locFilter;
    			} 
  			 }, function(reason) {
  				 vm.loadingData  = false;
  				 return reason;
  			 });
    	 } else {
    		 vm.loadingData = false;
    		 vm.noMoreMembers = true;
    		 vm.noMembers = false;
	   	 }
  	  } 
  	  
  	if($sessionStorage.locFilter && Object.keys($sessionStorage.locFilter).length > 0){
			$scope.libraryLocationObject = $sessionStorage.locFilter;
			vm.getLocationList();
		}
  	  
  	  $scope.callListfun = function(){
  		  vm.getLocationList(vm.endless);
  	  };
  	  
  	$scope.mapId = '';
  	vm.locationProfileShow = function(event,location){
  		$scope.isConflict = false;
  		vm.existFlag = false;
  		var id='locationProfilePopupId';
  		$scope.mapId = location.locationMappingId;
  		var data = LibraryRequestService.getRequestProfileById(location.locationMappingId);
  		data.then(function(response) {
  			vm.locationProfile = response;
  			//Set LocationType 
  			if(vm.locationProfile.sectorTxt != null){
  				vm.locationTypeName = vm.APP.LIBRARY.SECTOR;
  			} else if(vm.locationProfile.cityTxt != null){
  				vm.locationTypeName = vm.APP.LIBRARY.CITY;
  			} else if(vm.locationProfile.provinceTxt != null){
  				vm.locationTypeName = vm.APP.LIBRARY.PROVINCE;
  			} else if(vm.locationProfile.countryTxt != null){
  				vm.locationTypeName = vm.APP.LIBRARY.COUNTRY;
  			}
    	});
  		$mdDialog.show({
 			targetEvent: event,
 			contentElement:'#'+id,
 			parent: angular.element(document.body)
	    	});
  	}
  	
	vm.cancel = function(){ /* isCancel is parameter*/
   		$mdDialog.hide();
   		$scope.isConflict = false;
   		$scope.showEditpage = false;
   		$scope.btnViewChange = false;
   		$scope.showEditpage = false;
   	   	$scope.isProvinceEdit = false;
   	   	$scope.isCityEdit = false;
   	   	$scope.isSectorEdit = false;
   	   	$scope.isCountryEdit = false;
   	   	$scope.isEmptyProvinceVal = false;
   	   	$scope.isEmptySectorVal = false;
   	   	$scope.isEmptyCityVal = false;
   	   	$scope.isEmptyCountryVal = false;
		vm.locationProfile = {};
   		/*if(isCancel) {
   			$scope.editLocationInputSearchObject = {};
   	    	$scope.editLocationInputSearchObject.country = ''; 
   	    	$scope.editLocationInputSearchObject.province = '';
   	    	$scope.editLocationInputSearchObject.city = '';
   	    	$scope.editLocationInputSearchObject.countrySearchKey = '';
   	    	$scope.editLocationInputSearchObject.provinceSearchKey = '';
   	    	$scope.editLocationInputSearchObject.citySearchKey = '';
   	    	$scope.editLocationInputSearchObject.sectorSearchKey = '';
   	    	$scope.editLocationInputSearchObject.isAddOrSaveLocationCountry = false;
   	    	$scope.editLocationInputSearchObject.isAddOrSaveLocationProvince = false;
   	    	$scope.editLocationInputSearchObject.isAddOrSaveLocationCity = false;
   	    	$scope.editLocationInputSearchObject.isAddOrSaveLocationSector = false;
   		}*/
   	};
   	
   	vm.sideNavClose = function () {
    	$mdSidenav('right').close();
    }
   	
   	$scope.showEditpage = false;
   	$scope.btnViewChange = false;
   	$scope.isProvinceEdit = false;
   	$scope.isCityEdit = false;
   	$scope.isSectorEdit = false;
   	$scope.isCountryEdit = false;
   	$scope.isEmptyProvinceVal = false;
   	$scope.isEmptySectorVal = false;
   	$scope.isEmptyCityVal = false;
   	$scope.isEmptyCountryVal = false;
   	$scope.editProfileType = '';
   	vm.locationProfileDetail = {};
   	
   	vm.editLocationProfile = function(mapId) {
   		var data = LibraryRequestService.getRequestProfileById(mapId);
  		data.then(function(response) {
  			vm.locationProfileDetail = response;
  			$scope.showEditpage = true;
  	   		$scope.btnViewChange = true;
  	   		vm.countryValidationFlag = false;
  	   		vm.provinceValidationFlag = false;
  	   		vm.cityValidationFlag = false;
  	   		vm.sectorValidationFlag = false;
  	   		vm.isUpdateLocationDisabled =false;
  	   		
  	   		communicationService.setData('isAddOrSaveLocation',true);
	  	   	vm.editLocationObject.countryObj = LibraryService.getCountryObj(vm.locationProfileDetail);
			vm.editLocationObject.provinceObj = LibraryService.getProvinceObj(vm.locationProfileDetail);
			vm.editLocationObject.cityObj = LibraryService.getCityObj(vm.locationProfileDetail);
			vm.editLocationObject.sectorObj = LibraryService.getSectorObj (vm.locationProfileDetail);
			
			if(vm.editLocationObject.countryObj.countryId != null && vm.editLocationObject.countryObj.countryId != '') {
				$scope.editProfileType = 'country';
				$scope.isCountryEdit = true;
				$scope.isEmptyCountryVal = false;
				if(vm.editLocationObject.sectorObj.sectorId == null || vm.editLocationObject.sectorObj.sectorId == '' || vm.editLocationObject.sectorObj.sectorId == undefined) {
					$scope.isEmptySectorVal = true;
				} 
				if(vm.editLocationObject.cityObj.cityId == null || vm.editLocationObject.cityObj.cityId == '' || vm.editLocationObject.cityObj.cityId == undefined) {
					$scope.isEmptyCityVal = true;
				} 
				if(vm.editLocationObject.provinceObj.provinceId == null || vm.editLocationObject.provinceObj.provinceId == '' || vm.editLocationObject.provinceObj.provinceId == undefined) {
					$scope.isEmptyProvinceVal = true;
				} 
			} else {
				$scope.isCountryEdit = false;
			}
			if(vm.editLocationObject.provinceObj.provinceId != null && vm.editLocationObject.provinceObj.provinceId != '') {
				$scope.editProfileType = 'province';
				$scope.isProvinceEdit = true;
				$scope.isEmptyProvinceVal = false;
				if(vm.editLocationObject.sectorObj.sectorId == null || vm.editLocationObject.sectorObj.sectorId == '' || vm.editLocationObject.sectorObj.sectorId == undefined) {
					$scope.isEmptySectorVal = true;
				} 
				if(vm.editLocationObject.cityObj.cityId == null || vm.editLocationObject.cityObj.cityId == '' || vm.editLocationObject.cityObj.cityId == undefined) {
					$scope.isEmptyCityVal = true;
				}
				if(vm.editLocationObject.countryObj.countryId) {
					$scope.isCountryEdit = false;
				}
			} else {
				$scope.isProvinceEdit = false;
			}
			if(vm.editLocationObject.cityObj.cityId != null && vm.editLocationObject.cityObj.cityId != '') {
				$scope.editProfileType = 'city';
				$scope.isCityEdit = true;
				$scope.isEmptyCityVal = false;
				if(vm.editLocationObject.sectorObj.sectorId == null || vm.editLocationObject.sectorObj.sectorId == '' || vm.editLocationObject.sectorObj.sectorId == undefined) {
					$scope.isEmptySectorVal = true;
				}
				if(vm.editLocationObject.provinceObj.provinceId && vm.editLocationObject.countryObj.countryId) {
					$scope.isProvinceEdit = false;
					$scope.isCountryEdit = false;
				}
			} else {
				$scope.isCityEdit = false;
			}
			if(vm.editLocationObject.sectorObj.sectorId != null && vm.editLocationObject.sectorObj.sectorId != '') {
				$scope.editProfileType = 'sector';
				$scope.isSectorEdit = true;
				$scope.isEmptySectorVal = false;
				if(vm.editLocationObject.provinceObj.provinceId && vm.editLocationObject.cityObj.cityId && vm.editLocationObject.countryObj.countryId) {
					$scope.isProvinceEdit = false;
					$scope.isCityEdit = false;
					$scope.isCountryEdit = false;
				}
			} else {
				$scope.isSectorEdit = false;
			}
    	});
   	}
   	
   	vm.locationUpdateDetail = {};
   	vm.updateLocationDetail = function(event) {
   		vm.isUpdateLocationDisabled = true;
		if($scope.editProfileType == 'country') {
   			vm.locationUpdateDetail.id = vm.editLocationObject.countryObj.countryId;
   			vm.locationUpdateDetail.name = vm.editLocationObject.countryObj.countryTxt;
   			if(vm.editLocationObject.countryObj.countryTxt == null || vm.editLocationObject.countryObj.countryTxt == ''){
				vm.countryValidationFlag = true;
			}
   		} else if($scope.editProfileType == 'province') {
   			vm.locationUpdateDetail.id = vm.editLocationObject.provinceObj.provinceId;
   			vm.locationUpdateDetail.name = vm.editLocationObject.provinceObj.provinceTxt;
   			if(vm.editLocationObject.provinceObj.provinceTxt == null || vm.editLocationObject.provinceObj.provinceTxt == ''){
				vm.provinceValidationFlag = true;
			}
   		}  else if($scope.editProfileType == 'city') {
   			vm.locationUpdateDetail.id = vm.editLocationObject.cityObj.cityId;
   			vm.locationUpdateDetail.name = vm.editLocationObject.cityObj.cityTxt;
   			if(vm.editLocationObject.cityObj.cityTxt == null || vm.editLocationObject.cityObj.cityTxt == ''){
				vm.cityValidationFlag = true;
			}
   		} else if($scope.editProfileType == 'sector') {
   			vm.locationUpdateDetail.id = vm.editLocationObject.sectorObj.sectorId;
   			vm.locationUpdateDetail.name = vm.editLocationObject.sectorObj.sectorTxt;
   			if(vm.editLocationObject.sectorObj.sectorTxt == null || vm.editLocationObject.sectorObj.sectorTxt == ''){
				vm.sectorValidationFlag = true;
			}
   		}
		if(!vm.countryValidationFlag && !vm.provinceValidationFlag && !vm.cityValidationFlag && !vm.sectorValidationFlag) {
			var data = LibraryService.editIndividualLocation(vm.locationUpdateDetail);
			data.then(function(response){
				if(response && response.id) {
					vm.cancel();
					vm.getLocationList();
					notifications.showSuccess({message: vm.APP.LIBRARY.LOCATION_EDITED});
					vm.isUpdateLocationDisabled = false;
				}
			}, function(reason) {
				if(reason.status == 409){
		        	vm.existFlag = true;
		        } else{
		        	notifications.showError({message: vm.APP.LIBRARY.LOCATION_EDITED_PROBLEM});
		        }
				vm.isUpdateLocationDisabled = false;
			});
		} else 
			vm.isUpdateLocationDisabled = false;
		
		$timeout(function () { 
       		vm.countryValidationFlag = false;
       		vm.provinceValidationFlag = false;
       		vm.cityValidationFlag = false;
       		vm.sectorValidationFlag = false;
       		vm.existFlag = false;
   		}, 3000);
   	}
   	
   	vm.closeConfirmDialog = function(event) {
   		vm.existFlag = false;
   		$mdDialog.show({
 			targetEvent: event,
 			contentElement:'#locationProfilePopupId',
 			parent: angular.element(document.body)
	    });
   	}
   	
   	$scope.isConflict = false;
   	vm.deleteLocation = function(event) {
   		vm.isDeleteLocationDisable = true;
   		var data = LibraryService.deleteLocationDetail($scope.mapId);
		data.then(function(response){
			$scope.isConflict = false;
			$mdDialog.hide();
			vm.getLocationList();
			notifications.showSuccess({message: vm.APP.LIBRARY.LOCATION_DELETED});
			vm.isDeleteLocationDisable = false;
		}, function(err){
			if(err.status == '409') {
				$scope.isConflict = true;
				$scope.errorConflictMsg = vm.APP.LIBRARY.DELETE_PROBLEM;
			}else{
				notifications.showError({message: vm.APP.LIBRARY.LOCATION_DELETED_PROBLEM});
			}
			vm.isDeleteLocationDisable = false;
		});
   	}
   	
   	vm.deleteConfirmDialog = function(event, id) {
   		$scope.isConflict = false;
   		vm.isDeleteLocationDisable = false;
   		$mdDialog.show({
 			targetEvent: event,
 			contentElement:'#'+id,
 			parent: angular.element(document.body)
	    });
   	}
   	
   	$scope.enableUpdateCall = false;
   	vm.updateLocationProfileDetail = function(event) {
   		vm.editedLocationDetail = {};
   		if(vm.editLocationObject.countryObj && vm.editLocationObject.countryObj.countryId != null) {
   			vm.editedLocationDetail.countryId = vm.editLocationObject.countryObj.countryId;
   			vm.editedLocationDetail.countryTxt = vm.editLocationObject.countryObj.countryTxt;
   			$scope.enableUpdateCall = true;
   		} else if($scope.editLocationInputSearchObject.countrySearchKey != null && $scope.editLocationInputSearchObject.countrySearchKey != '') {
   			vm.editedLocationDetail.countryTxt = $scope.editLocationInputSearchObject.countrySearchKey;
   			$scope.enableUpdateCall = true;
   		} else {
   			$scope.enableUpdateCall = false;
   		}
   		if(vm.editLocationObject.provinceObj && vm.editLocationObject.provinceObj.provinceId != null) {
   			if(vm.editLocationObject.countryObj && vm.editLocationObject.countryObj.countryId != null) {
   				vm.editedLocationDetail.provinceId = vm.editLocationObject.provinceObj.provinceId;
   	   			vm.editedLocationDetail.provinceTxt = vm.editLocationObject.provinceObj.provinceTxt;
   	   			$scope.enableUpdateCall = true;
   			} else if($scope.editLocationInputSearchObject.countrySearchKey != null && $scope.editLocationInputSearchObject.countrySearchKey != '') {
   				vm.editedLocationDetail.provinceId = vm.editLocationObject.provinceObj.provinceId;
   	   			vm.editedLocationDetail.provinceTxt = vm.editLocationObject.provinceObj.provinceTxt;
   				$scope.enableUpdateCall = true;
   			} else {
   				$scope.enableUpdateCall = false;
   			} 
   		} else if($scope.editLocationInputSearchObject.provinceSearchKey != null && $scope.editLocationInputSearchObject.provinceSearchKey != '') {
   			if(vm.editLocationObject.countryObj && vm.editLocationObject.countryObj.countryId != null) {
   				vm.editedLocationDetail.provinceTxt = $scope.editLocationInputSearchObject.provinceSearchKey;
   	   			$scope.enableUpdateCall = true;
   			} else if($scope.editLocationInputSearchObject.countrySearchKey != null && $scope.editLocationInputSearchObject.countrySearchKey != '') {
   				vm.editedLocationDetail.provinceTxt = $scope.editLocationInputSearchObject.provinceSearchKey;
   				$scope.enableUpdateCall = true;
   			} else {
   				$scope.enableUpdateCall = false;
   			}
   		}
   		if(vm.editLocationObject.cityObj && vm.editLocationObject.cityObj.cityId != null) {
   			if((vm.editLocationObject.countryObj && vm.editLocationObject.countryObj.countryId != null) && (vm.editLocationObject.provinceObj && vm.editLocationObject.countryObj.provinceId != null)) {
   				vm.editedLocationDetail.cityId = vm.editLocationObject.cityObj.cityId;
   	   			vm.editedLocationDetail.cityTxt = vm.editLocationObject.cityObj.cityTxt;
   	   			$scope.enableUpdateCall = true;
   			} else if((vm.editLocationObject.countryObj && vm.editLocationObject.countryObj.countryId != null) && ($scope.editLocationInputSearchObject.provinceSearchKey != null && $scope.editLocationInputSearchObject.provinceSearchKey != '')) {
   				vm.editedLocationDetail.cityId = vm.editLocationObject.cityObj.cityId;
   	   			vm.editedLocationDetail.cityTxt = vm.editLocationObject.cityObj.cityTxt;
   				$scope.enableUpdateCall = true;
   			} else if(($scope.editLocationInputSearchObject.countrySearchKey != null && $scope.editLocationInputSearchObject.countrySearchKey != '') && ($scope.editLocationInputSearchObject.provinceSearchKey != null && $scope.editLocationInputSearchObject.provinceSearchKey != '')) {
   				vm.editedLocationDetail.cityId = vm.editLocationObject.cityObj.cityId;
   	   			vm.editedLocationDetail.cityTxt = vm.editLocationObject.cityObj.cityTxt;
   				$scope.enableUpdateCall = true;
   			} else {
   				$scope.enableUpdateCall = false;
   			}
   		} else if($scope.editLocationInputSearchObject.citySearchKey != null && $scope.editLocationInputSearchObject.citySearchKey != '') {
   			if((vm.editLocationObject.countryObj && vm.editLocationObject.countryObj.countryId != null) && (vm.editLocationObject.provinceObj && vm.editLocationObject.countryObj.provinceId != null)) {
   				vm.editedLocationDetail.cityTxt = $scope.editLocationInputSearchObject.citySearchKey;
   	   			$scope.enableUpdateCall = true;
   			} else if((vm.editLocationObject.countryObj && vm.editLocationObject.countryObj.countryId != null) && ($scope.editLocationInputSearchObject.provinceSearchKey != null && $scope.editLocationInputSearchObject.provinceSearchKey != '')) {
   				vm.editedLocationDetail.cityTxt = $scope.editLocationInputSearchObject.citySearchKey;
   				$scope.enableUpdateCall = true;
   			} else if(($scope.editLocationInputSearchObject.countrySearchKey != null && $scope.editLocationInputSearchObject.countrySearchKey != '') && ($scope.editLocationInputSearchObject.provinceSearchKey != null && $scope.editLocationInputSearchObject.provinceSearchKey != '')) {
   				vm.editedLocationDetail.cityTxt = $scope.editLocationInputSearchObject.citySearchKey;
   				$scope.enableUpdateCall = true;
   			} else {
   				$scope.enableUpdateCall = false;
   			}
   		}
   		if(vm.editLocationObject.sectorObj && vm.editLocationObject.sectorObj.sectorId != null) {
   			if((vm.editLocationObject.countryObj && vm.editLocationObject.countryObj.countryId != null) && (vm.editLocationObject.provinceObj && vm.editLocationObject.countryObj.provinceId != null)
   					&& (vm.editLocationObject.cityObj && vm.editLocationObject.cityObj.cityId != null)) {
   				vm.editedLocationDetail.sectorId = vm.editLocationObject.sectorObj.sectorId;
   	   			vm.editedLocationDetail.sectorTxt = vm.editLocationObject.sectorObj.sectorTxt;
   				$scope.enableUpdateCall = true;
   			} else if((vm.editLocationObject.countryObj && vm.editLocationObject.countryObj.countryId != null) && (vm.editLocationObject.provinceObj && vm.editLocationObject.countryObj.provinceId != null)
   					&& ($scope.editLocationInputSearchObject.citySearchKey != null && $scope.editLocationInputSearchObject.citySearchKey != '')) {
   				vm.editedLocationDetail.sectorId = vm.editLocationObject.sectorObj.sectorId;
   	   			vm.editedLocationDetail.sectorTxt = vm.editLocationObject.sectorObj.sectorTxt;
   				$scope.enableUpdateCall = true;
   			} else if((vm.editLocationObject.countryObj && vm.editLocationObject.countryObj.countryId != null) && ($scope.editLocationInputSearchObject.provinceSearchKey != null && $scope.editLocationInputSearchObject.provinceSearchKey != '')
   					&& ($scope.editLocationInputSearchObject.citySearchKey != null && $scope.editLocationInputSearchObject.citySearchKey != '')) {
   				vm.editedLocationDetail.sectorId = vm.editLocationObject.sectorObj.sectorId;
   	   			vm.editedLocationDetail.sectorTxt = vm.editLocationObject.sectorObj.sectorTxt;
   				$scope.enableUpdateCall = true;
   			} else if(($scope.editLocationInputSearchObject.countrySearchKey != null && $scope.editLocationInputSearchObject.countrySearchKey != '') && ($scope.editLocationInputSearchObject.provinceSearchKey != null && $scope.editLocationInputSearchObject.provinceSearchKey != '')
   					&& ($scope.editLocationInputSearchObject.citySearchKey != null && $scope.editLocationInputSearchObject.citySearchKey != '')) {
				vm.editedLocationDetail.sectorId = vm.editLocationObject.sectorObj.sectorId;
				vm.editedLocationDetail.sectorTxt = vm.editLocationObject.sectorObj.sectorTxt;
				$scope.enableUpdateCall = true;
   			} else {
   				$scope.enableUpdateCall = false;
   			}
   		} else if($scope.editLocationInputSearchObject.sectorSearchKey != null && $scope.editLocationInputSearchObject.sectorSearchKey != '') {
   			if((vm.editLocationObject.countryObj && vm.editLocationObject.countryObj.countryId != null) && (vm.editLocationObject.provinceObj && vm.editLocationObject.countryObj.provinceId != null)
   					&& (vm.editLocationObject.cityObj && vm.editLocationObject.cityObj.cityId != null)) {
   	   			vm.editedLocationDetail.sectorTxt = $scope.editLocationInputSearchObject.sectorSearchKey;
   				$scope.enableUpdateCall = true;
   			} else if((vm.editLocationObject.countryObj && vm.editLocationObject.countryObj.countryId != null) && (vm.editLocationObject.provinceObj && vm.editLocationObject.countryObj.provinceId != null)
   					&& ($scope.editLocationInputSearchObject.citySearchKey != null && $scope.editLocationInputSearchObject.citySearchKey != '')) {
   				vm.editedLocationDetail.sectorTxt = $scope.editLocationInputSearchObject.sectorSearchKey;
   				$scope.enableUpdateCall = true;
   			} else if((vm.editLocationObject.countryObj && vm.editLocationObject.countryObj.countryId != null) && ($scope.editLocationInputSearchObject.provinceSearchKey != null && $scope.editLocationInputSearchObject.provinceSearchKey != '')
   					&& ($scope.editLocationInputSearchObject.citySearchKey != null && $scope.editLocationInputSearchObject.citySearchKey != '')) {
   				vm.editedLocationDetail.sectorTxt = $scope.editLocationInputSearchObject.sectorSearchKey;
   				$scope.enableUpdateCall = true;
   			} else if(($scope.editLocationInputSearchObject.countrySearchKey != null && $scope.editLocationInputSearchObject.countrySearchKey != '') && ($scope.editLocationInputSearchObject.provinceSearchKey != null && $scope.editLocationInputSearchObject.provinceSearchKey != '')
   					&& ($scope.editLocationInputSearchObject.citySearchKey != null && $scope.editLocationInputSearchObject.citySearchKey != '')) {
   				vm.editedLocationDetail.sectorTxt = $scope.editLocationInputSearchObject.sectorSearchKey;
				$scope.enableUpdateCall = true;
   			} else {
   				$scope.enableUpdateCall = false;
   			}
   		}
   		if($scope.enableUpdateCall) {
   			var data = LibraryService.editLocation(vm.editedLocationDetail);
   			data.then(function(response){
   				if(response && response.id) {
   					communicationService.setData('isAddOrSaveLocation',false);
   					vm.cancel(true);
   					$timeout(function(){
   						vm.openSuccessDialog('successAddLocation',event);
   					},100);
   				}
   			});
   		}
   	}
  	  
  }
})();