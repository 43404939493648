(function () {
  "use strict";

  angular.module("lariAidApp").controller("LibraryController", LibraryController);

  LibraryController.$inject = [
    "$scope",
    "$state",
    "$stateParams",
    "$mdSidenav",
    "$element",
    "APP",
    "tabservice",
    "$mdMedia",
    "$sessionStorage",
    "$cookies",
    "LibraryService",
    "$mdDialog",
    "CommonService",
    "LibraryEntityService",
    "$q",
    "communicationService",
    "LibraryLocationService",
    "$timeout",
    "StorageDelete",
    "notifications",
    "$rootScope"
  ];

  function LibraryController(
    $scope,
    $state,
    $stateParams,
    $mdSidenav,
    $element,
    APP,
    tabservice,
    $mdMedia,
    $sessionStorage,
    $cookies,
    LibraryService,
    $mdDialog,
    CommonService,
    LibraryEntityService,
    $q,
    communicationService,
    LibraryLocationService,
    $timeout,
    StorageDelete,
    notifications,
    $rootScope
  ) {
    var vm = this;
    vm.APP = APP;
    vm.endless = vm.APP.GLOBAL.ENDLESS;
    vm.library = $stateParams;
    vm.media = $mdMedia;
    vm.requestFlag = false;
    vm.location = {};
    vm.isHide = false;

    vm.entityLocationObject = {};
    vm.entityLocationObject.countryObj = {};
    vm.entityLocationObject.provinceObj = {};
    vm.entityLocationObject.cityObj = {};
    vm.entityLocationObject.sectorObj = {};

    vm.isHorizon = true;
    //Get user from cookie storage
    vm.user = CommonService.getCookieUser();
    vm.loggedUserRole = vm.user.loggedUserRole;
    vm.library.libraryType = $state.current.name;

    $scope.isHorizon = false;
    vm.saveOrAddLocationObject = {};
    vm.saveOrAddLocationObject.countryObj = {};
    vm.saveOrAddLocationObject.provinceObj = {};
    vm.saveOrAddLocationObject.cityObj = {};
    vm.saveOrAddLocationObject.sectorObj = {};
    $scope.isAddOrSaveLocation = false;
    communicationService.setData("isAddOrSaveLocation", $scope.isAddOrSaveLocation);

    vm.addLocationObject = {};
    vm.addLocationObject.countryObj = {};
    vm.addLocationObject.provinceObj = {};
    vm.addLocationObject.cityObj = {};
    vm.addLocationObject.sectorObj = {};

    vm.addLocationInputSearchObject = {};
    vm.updateEntityLocation = true;

    $scope.addLocationInputObject = {};
    $scope.addLocationInputObject.country = false;
    $scope.addLocationInputObject.province = false;
    $scope.addLocationInputObject.city = false;
    $scope.addLocationInputObject.sector = false;

    $scope.libraryAddEntityInputSearchObject = {};
    $scope.libraryAddEntityInputSearchObject.country = "";
    $scope.libraryAddEntityInputSearchObject.province = "";
    $scope.libraryAddEntityInputSearchObject.city = "";
    $scope.libraryAddEntityInputSearchObject.countrySearchKey = "";
    $scope.libraryAddEntityInputSearchObject.provinceSearchKey = "";
    $scope.libraryAddEntityInputSearchObject.citySearchKey = "";
    $scope.libraryAddEntityInputSearchObject.sectorSearchKey = "";
    $scope.libraryAddEntityInputSearchObject.isAddOrSaveLocationCountry = false;
    $scope.libraryAddEntityInputSearchObject.isAddOrSaveLocationProvince = false;
    $scope.libraryAddEntityInputSearchObject.isAddOrSaveLocationCity = false;
    $scope.libraryAddEntityInputSearchObject.isAddOrSaveLocationSector = false;
    $scope.commentSize = {};
    $scope.commentSize.valid = false;
    $scope.disableSaveButton = false;

    vm.checkLibraryIndex = function () {
      vm.libraryTabIndex = tabservice.getIndex();
    };

    vm.checkLibraryIndex();

    vm.country = "";

    vm.toggleLeft = buildToggler("left");
    vm.toggleRight = buildToggler("right");

    $element.find("input").on("keydown", function (ev) {
      ev.stopPropagation();
    });

    function buildToggler(componentId) {
      return function () {
        $mdSidenav(componentId).toggle();
      };
    }

    vm.sideNavClose = function (id) {
      $mdSidenav(id).close();
    };

    vm.stateRequestDetails = function () {
      vm.library.libraryType = vm.APP.LIBRARY.REQUEST;
      $state.go("request", vm.library);
    };

    vm.stateEntityDetails = function () {
      vm.library.libraryType = vm.APP.LIBRARY.ENTITY;
      $state.go("entities", vm.library);
    };

    vm.stateLocationDetails = function () {
      vm.library.libraryType = vm.APP.LIBRARY.LOCATION;
      $state.go("locations", vm.library);
    };

    vm.cancel = function (formName) {
      $mdDialog.hide();
    };

    vm.getEntityTypeList = function () {
      var entityTypeData = CommonService.getRefTermList(vm.APP.REF_TERM.ENTITY_PROFILE);
      entityTypeData.then(function (response) {
        vm.entityTypeList = response;
      });
    };

    vm.libraryInit = function () {
      vm.sideNavClose("left");
      vm.getEntityTypeList();
      //StorageDelete.deleteInvestigation(); ---------Commenting this line so that the Analyst form can be opened properly on browser back from the Library Section.
      StorageDelete.deleteUser();
    };

    vm.libraryInit();

    //ADD NEW ENTITY POPUP
    $scope.openNewEntity = function (ev, id, flag) {
      vm.entity = {}; //Clear Form fields
      vm.entityLocationObject = {};
      vm.entityLocationObject.countryObj = {};
      vm.entityLocationObject.provinceObj = {};
      vm.entityLocationObject.cityObj = {};
      vm.entityLocationObject.sectorObj = {};
      $scope.addNewEntityForm = {};
      vm.editFlag = false;
      vm.isHide = false;
      vm.updateFlag = false;
      vm.existFlag = false;
      vm.isEntityDisable = false;

      vm.flag = flag;
      // Flag 0: New Entity Request, Flag 1: New Entity, Flag 2: Update Entity
      if (vm.flag == 2) {
        vm.editFlag = true;
      }

      $mdDialog.show({
        targetEvent: ev,
        contentElement: "#" + id,
        parent: angular.element(document.body)
      });
    };

    vm.cancel = function (addNewEntityForm) {
      addNewEntityForm.$setPristine();
      vm.entity = {}; //Clear Form fields
      vm.entity.contactEmail = "";
      vm.entity.contactPerson = "";
      $scope.addNewEntityForm = {};
      $mdDialog.hide();
      $rootScope.$emit("cancelFormData");
    };

    vm.addNewEntity = function (entity, addNewEntityForm, libraryAddEntityInputSearchObject, entityLocationObject, flag) {
      vm.isEntityDisable = true;
      if (addNewEntityForm.$valid && entity) {
        vm.entityObj = {};
        vm.entityObj.comments = entity.comments;
        vm.entityObj.contactEmail = entity.contactEmail;
        vm.entityObj.contactPersonName = entity.contactPersonName;
        vm.entityObj.contactPhoneNumber = entity.contactPhoneNumber;
        vm.entityObj.entityTypeId = entity.entityTypeSearchKey.id;
        if (entity.flag == undefined || entity.flag == "") {
          entity.flag = false;
        }
        vm.entityObj.flag = entity.flag;
        vm.entityObj.name = entity.entityName;
        if (libraryAddEntityInputSearchObject.countrySearchKey == "" && libraryAddEntityInputSearchObject.provinceSearchKey == "" && libraryAddEntityInputSearchObject.sectorSearchKey == "" && libraryAddEntityInputSearchObject.citySearchKey == "") {
          libraryAddEntityInputSearchObject.isAddOrSaveLocationSector = false;
        } else if (libraryAddEntityInputSearchObject.countrySearchKey != undefined || libraryAddEntityInputSearchObject.provinceSearchKey != undefined || libraryAddEntityInputSearchObject.sectorSearchKey != undefined || libraryAddEntityInputSearchObject.citySearchKey != undefined) {
          libraryAddEntityInputSearchObject.isAddOrSaveLocationSector = true;
        }
        if ((libraryAddEntityInputSearchObject.isAddOrSaveLocationCity == true || libraryAddEntityInputSearchObject.isAddOrSaveLocationCountry == true || libraryAddEntityInputSearchObject.isAddOrSaveLocationProvince == true || libraryAddEntityInputSearchObject.isAddOrSaveLocationSector == true)) {
          vm.locationObj = {};
          vm.locationObj.countryId = entityLocationObject.countryObj.countryId;
          vm.locationObj.countryTxt = libraryAddEntityInputSearchObject.countrySearchKey;
          vm.locationObj.provinceId = entityLocationObject.provinceObj.provinceId;
          vm.locationObj.provinceTxt = libraryAddEntityInputSearchObject.provinceSearchKey;
          vm.locationObj.cityId = entityLocationObject.cityObj.cityId;
          vm.locationObj.cityTxt = libraryAddEntityInputSearchObject.citySearchKey;
          vm.locationObj.sectorId = null;
          if (libraryAddEntityInputSearchObject.sectorSearchKey != null) {
            vm.locationObj.sectorTxt = libraryAddEntityInputSearchObject.sectorSearchKey;
          } else {
            vm.locationObj.sectorTxt = null;
          }
          $scope.validateUpdateEntityLocation();
          if (vm.updateEntityLocation) {
            $scope.disableSaveButton = true;
            var data = LibraryService.saveNewLocation(vm.locationObj);
            data.then(function (response) {
              $scope.disableSaveButton = false;
              vm.entityObj.locationMappingId = response.id;
              if (flag == 1) {
                var data = LibraryService.addNewEntity(vm.entityObj);
              } else if (flag == 2) {
                vm.entityObj.id = entity.id;
                var data = LibraryService.updateEntity(vm.entityObj);
              }

              data.then(function (response) {
                if (flag == 2) {
                  notifications.showSuccess({ message: vm.APP.LIBRARY.ENTITY_UPDATE });
                } else {
                  notifications.showSuccess({ message: vm.APP.LIBRARY.ENTITY_ADD });
                }
                $state.transitionTo($state.current, $stateParams, {
                  reload: true,
                  inherit: false,
                  notify: true
                });
                vm.isEntityDisable = false;
              }, function (reason) {
                $scope.disableSaveButton = false;

                if (reason.status == 409) {
                  vm.existFlag = true;
                } else if (reason.status == 400 && reason.data.fieldErrors[0].message == "Size" && reason.data.fieldErrors[0].field == "comments") {
                  $scope.commentSize.valid = true;
                } else if (flag == 2) {
                  notifications.showError({ message: vm.APP.LIBRARY.ENTITY_UPDATE_PROBLEM });
                } else {
                  notifications.showError({ message: vm.APP.LIBRARY.ENTITY_ADD_PROBLEM });
                }
                vm.isEntityDisable = false;
              });
            });
          }
          else {
            vm.isEntityDisable = false;
          }
        }

        else {
          vm.isEntityDisable = false;
          vm.entityObj.locationMappingId = vm.entityLocationObject.cityObj.locationMappingId;
          if (!angular.isUndefinedOrNull(vm.entityObj.locationMappingId)) {
            vm.isEntityDisable = true;
            if (flag == 1) {
              var data = LibraryService.addNewEntity(vm.entityObj);
            } else if (flag == 2) {
              vm.entityObj.id = entity.id;
              var data = LibraryService.updateEntity(vm.entityObj);
            }

            data.then(function (response) {
              if (flag == 2) {
                notifications.showSuccess({ message: vm.APP.LIBRARY.ENTITY_UPDATE });
              } else {
                notifications.showSuccess({ message: vm.APP.LIBRARY.ENTITY_ADD });
              }
              $state.transitionTo($state.current, $stateParams, {
                reload: true,
                inherit: false,
                notify: true
              });
              vm.isEntityDisable = false;
            }, function (reason) {

              if (reason.status == 409) {
                vm.existFlag = true;
              } else if (reason.status == 400 && reason.data.fieldErrors[0].message == "Size" && reason.data.fieldErrors[0].field == "comments") {
                $scope.commentSize.valid = true;
              } else if (flag == 2) {
                notifications.showError({ message: vm.APP.LIBRARY.ENTITY_UPDATE_PROBLEM });
              } else {
                notifications.showError({ message: vm.APP.LIBRARY.ENTITY_ADD_PROBLEM });
              }
              vm.isEntityDisable = false;
            });
          }
        }
        //vm.cancel(addNewEntityForm);
        $timeout(function () {
          vm.existFlag = false;
        }, 3000);
      } else {
        vm.isEntityDisable = false;
      }
    };
    function fieldValidation(entityLocationObject, addNewEntityForm, libraryAddEntityInputSearchObject) {
      var isValid = true;
      if ((entityLocationObject.countryObj
        && !entityLocationObject.countryObj.countryId) && (!libraryAddEntityInputSearchObject.countrySearchKey)) {
        addNewEntityForm.country.$error = true;
        isValid = false;
      }
      if ((entityLocationObject.provinceObj
        && !entityLocationObject.provinceObj.provinceId) && (!libraryAddEntityInputSearchObject.provinceSearchKey)) {
        addNewEntityForm.province.$error = true;
        isValid = false;
      }
      if ((entityLocationObject.cityObj
        && !entityLocationObject.cityObj.cityId) && (!libraryAddEntityInputSearchObject.citySearchKey)) {
        addNewEntityForm.city.$error = true;
        isValid = false;
      }
      return isValid;
    }
    //ADD ENTITY REQUEST
    vm.saveNewEntity = function (entity, addNewEntityForm, libraryAddEntityInputSearchObject, entityLocationObject) {
      if (addNewEntityForm.$valid && entity && fieldValidation(entityLocationObject, addNewEntityForm, libraryAddEntityInputSearchObject)) {
        vm.entityObj = {};
        vm.entityObj.comments = entity.comments;
        vm.entityObj.contactEmail = entity.contactEmail;
        vm.entityObj.contactPersonName = entity.contactPersonName;
        vm.entityObj.contactPhoneNumber = entity.contactPhoneNumber;
        vm.entityObj.entityTypeId = entity.entityTypeSearchKey.id;
        if (entity.flag == undefined || entity.flag == "") {
          entity.flag = false;
        }
        vm.entityObj.flag = entity.flag;
        vm.entityObj.name = vm.entity.entityName;

        if (libraryAddEntityInputSearchObject.isAddOrSaveLocationCity == true || libraryAddEntityInputSearchObject.isAddOrSaveLocationCountry == true || libraryAddEntityInputSearchObject.isAddOrSaveLocationProvince == true || libraryAddEntityInputSearchObject.isAddOrSaveLocationSector == true) {
          vm.locationObj = {};
          vm.locationObj.countryId = entityLocationObject.countryObj.countryId;
          vm.locationObj.countryTxt = libraryAddEntityInputSearchObject.countrySearchKey;
          vm.locationObj.provinceId = entityLocationObject.provinceObj.provinceId;
          vm.locationObj.provinceTxt = libraryAddEntityInputSearchObject.provinceSearchKey;
          vm.locationObj.cityId = entityLocationObject.cityObj.cityId;
          vm.locationObj.cityTxt = libraryAddEntityInputSearchObject.citySearchKey;
          vm.locationObj.sectorId = null;
          vm.locationObj.sectorTxt = libraryAddEntityInputSearchObject.sectorSearchKey;
          $scope.disableSaveButton = true;
          var data = LibraryService.saveNewLocation(vm.locationObj);
          data.then(function (response) {
            $scope.disableSaveButton = false;
            vm.entityObj.locationMappingId = response.id;
            var data = LibraryService.saveNewEntity(vm.entityObj);
            data.then(function (response) {
              if (response.entityExists) {
                notifications.showSuccess({ message: vm.APP.LIBRARY.ENTITY_REQUEST_EXISTS });
              }
              else {
                notifications.showSuccess({ message: vm.APP.LIBRARY.ENTITY_REQUEST_SEND });
              }
              $state.transitionTo($state.current, $stateParams, {
                reload: true,
                inherit: false,
                notify: true
              });
            }, function (reason) {
              $scope.disableSaveButton = false;
              notifications.showError({ message: vm.APP.LIBRARY.ENTITY_REQUEST_SEND_PROBLEM });
            });
            vm.cancel(addNewEntityForm);
          });
        }
        else {
          vm.entityObj.locationMappingId = angular.isUndefinedOrNull(vm.entityLocationObject.cityObj.locationMappingId) ? vm.entityLocationObject.cityObj.id : vm.entityLocationObject.cityObj.locationMappingId;
          var data = LibraryService.saveNewEntity(vm.entityObj);
          data.then(function (response) {
            if (response.entityExists) {
              notifications.showSuccess({ message: vm.APP.LIBRARY.ENTITY_REQUEST_EXISTS });
            }
            else {
              notifications.showSuccess({ message: vm.APP.LIBRARY.ENTITY_REQUEST_SEND });
            }
            $state.transitionTo($state.current, $stateParams, {
              reload: true,
              inherit: false,
              notify: true
            });
          }, function (reason) {
            notifications.showError({ message: vm.APP.LIBRARY.ENTITY_REQUEST_SEND_PROBLEM });
          });
          vm.cancel(addNewEntityForm);
        }
      }
    };

    vm.locRegTypeValid = false;
    //ADD LOCATION REQUEST
    vm.saveLocation = function (entity, addNewLocationRequestForm) {
      if (vm.locationRequestType) {
        vm.locRegTypeValid = false;
        if (addNewLocationRequestForm.$valid && entity) {
          vm.locationObj = {};
          if (vm.locationRequestType.name == vm.APP.LIBRARY.COUNTRY) {
            vm.locationObj.countryId = null;
            vm.locationObj.countryTxt = vm.location.country;
            vm.locationObj.requestTypeId = vm.locationRequestType.id;
          } else if (vm.locationRequestType.name == vm.APP.LIBRARY.PROVINCE) {
            if (vm.addLocationObject.countryObj != undefined) {
              vm.locationObj.countryId = vm.addLocationObject.countryObj.countryId;
              vm.locationObj.countryTxt = vm.addLocationObject.countryObj.countryTxt;
            } else {
              vm.locationObj.countryId = null;
              vm.locationObj.countryTxt = vm.location.country;
            }
            vm.locationObj.provinceId = null;
            vm.locationObj.provinceTxt = vm.location.province;
            vm.locationObj.requestTypeId = vm.locationRequestType.id;
          } else if (vm.locationRequestType.name == vm.APP.LIBRARY.CITY) {
            if (vm.addLocationObject.countryObj != undefined) {
              vm.locationObj.countryId = vm.addLocationObject.countryObj.countryId;
              vm.locationObj.countryTxt = vm.addLocationObject.countryObj.countryTxt;
            }
            else {
              vm.locationObj.countryId = null;
              vm.locationObj.countryTxt = vm.location.country;
            }
            if (vm.addLocationObject.provinceObj != undefined) {
              vm.locationObj.provinceId = vm.addLocationObject.provinceObj.provinceId;
              vm.locationObj.provinceTxt = vm.addLocationObject.provinceObj.provinceTxt;
            }
            else {
              vm.locationObj.provinceId = null;
              vm.locationObj.provinceTxt = vm.location.province;
            }
            vm.locationObj.cityId = null;
            vm.locationObj.cityTxt = vm.location.city;
            vm.locationObj.requestTypeId = vm.locationRequestType.id;
          } else {
            if (vm.addLocationObject.countryObj != undefined) {
              vm.locationObj.countryId = vm.addLocationObject.countryObj.countryId;
              vm.locationObj.countryTxt = vm.addLocationObject.countryObj.countryTxt;
            }
            else {
              vm.locationObj.countryId = null;
              vm.locationObj.countryTxt = vm.location.country;
            }
            if (vm.addLocationObject.provinceObj != undefined) {
              vm.locationObj.provinceId = vm.addLocationObject.provinceObj.provinceId;
              vm.locationObj.provinceTxt = vm.addLocationObject.provinceObj.provinceTxt;
            }
            else {
              vm.locationObj.provinceId = null;
              vm.locationObj.provinceTxt = vm.location.province;
            }
            if (vm.addLocationObject.cityObj != undefined) {
              vm.locationObj.cityId = vm.addLocationObject.cityObj.cityId;
              vm.locationObj.cityTxt = vm.addLocationObject.cityObj.cityTxt;
            } else {
              vm.locationObj.cityId = null;
              vm.locationObj.cityTxt = vm.location.city;
            }
            vm.locationObj.sectorId = null;
            vm.locationObj.sectorTxt = vm.location.sector;
            vm.locationObj.requestTypeId = vm.locationRequestType.id;
          }
          $scope.disableSaveButton = true;
          var data = LibraryService.saveNewLocation(vm.locationObj);
          data.then(function (response) {
            $scope.disableSaveButton = false;
            vm.stateRequestDetails();
            $state.transitionTo($state.current, $stateParams, {
              reload: true,
              inherit: false,
              notify: true
            });
            if (response.isAlreadyExists != null) {
              notifications.showSuccess({ message: vm.APP.LIBRARY.LOCATION_EXISTS });
            }
            else {
              notifications.showSuccess({ message: vm.APP.LIBRARY.LOCATION_REQUEST_SEND });
            }
          }, function (reason) {
            $scope.disableSaveButton = false;
            notifications.showError({ message: vm.APP.LIBRARY.LOCATION_REQUEST_SEND_PROBLEM });
          });

          $mdDialog.hide();
          //vm.cancelLocation(addlocationRequestForm, 0);
        }
      } else {
        vm.locRegTypeValid = true;
      }
    };
    /*vm.clearLocation = function(){
   vm.entity = {};
   vm.saveOrAddLocationObject = {};
      vm.saveOrAddLocationObject.countryObj = {};
     vm.saveOrAddLocationObject.provinceObj = {};
     vm.saveOrAddLocationObject.cityObj = {};
     vm.saveOrAddLocationObject.sectorObj = {};
   vm.entity.entityTypeSearchKey = copyEntityType;
   vm.isHide =	false;
 };*/

    //ADD LOCATION REQUEST POPUP
    vm.openLocationRequest = function (ev, id, flag) {
      vm.locationVO = {};
      vm.addLocationObject = {};
      vm.isReplaceCountry = false;
      vm.isReplaceProvince = false;
      vm.isReplaceCity = false;
      vm.isReplaceSector = false;
      vm.requestFlagSectorInput = false;
      vm.requestFlagCityInput = false;
      vm.requestFlagProvinceInput = false;
      vm.requestFlagCountryInput = false;
      vm.locRegTypeValid = false;
      vm.existFlag = false;
      vm.validationMSGFlagProvinceCity = false;
      vm.validationMSGFlagProvince = false;
      vm.validationMSGFlagCity = false;
      vm.isAddLocationDisable = false;

      var locationRefset = CommonService.getRefTermList(vm.APP.LIBRARY.LOCATION_CONST);
      locationRefset.then(function (response) {
        vm.locationVO = response;
      });
      vm.flag = flag;
      if (flag == 1) {
        $scope.isAddOrSaveLocation = true;
        communicationService.setData('isAddOrSaveLocation', $scope.isAddOrSaveLocation);
      }
      $scope.isValidEnable = false;
      vm.addLocationInputSearchObject = {};
      vm.saveOrAddLocationObject = {};
      vm.saveOrAddLocationObject.countryObj = {};
      vm.saveOrAddLocationObject.provinceObj = {};
      vm.saveOrAddLocationObject.cityObj = {};
      vm.saveOrAddLocationObject.sectorObj = {};
      $scope.isHorizon = true;
      $mdDialog.show({
        targetEvent: ev,
        contentElement: '#' + id,
        parent: angular.element(document.body)
      });
    };

    $scope.isValidEnable = false;
    vm.addLocationInputSearchObject.isInputEmptyCountry = false;
    vm.addLocationInputSearchObject.isInputEmptyProvince = false;
    vm.addLocationInputSearchObject.isInputEmptyCity = false;
    vm.addLocationInputSearchObject.isInputEmptySector = false;

    $scope.checkMandatoryFields = function (type) {
      if ($scope.isAddOrSaveLocation) {
        if (type == vm.APP.LIBRARY.COUNTRY_CAPS) {
          if (vm.saveOrAddLocationObject.countryObj && vm.saveOrAddLocationObject.countryObj.countryId != null && vm.saveOrAddLocationObject.countryObj.countryId != '') {
            if ((vm.addLocationInputSearchObject.provinceSearchKey != null && vm.addLocationInputSearchObject.provinceSearchKey != '')
              || (vm.addLocationInputSearchObject.citySearchKey != null && vm.addLocationInputSearchObject.citySearchKey != '')
              || (vm.addLocationInputSearchObject.sectorSearchKey != null && vm.addLocationInputSearchObject.sectorSearchKey != '')) {
              $scope.isValidEnable = true;
            } else {
              $scope.isValidEnable = false;
            }
          } else if (vm.addLocationInputSearchObject.countrySearchKey != null && vm.addLocationInputSearchObject.countrySearchKey != '') {
            $scope.isValidEnable = true;
          } else {
            $scope.isValidEnable = false;
          }
        } else if (type == vm.APP.LIBRARY.PROVINCE_CAPS) {
          if (vm.saveOrAddLocationObject.countryObj && vm.saveOrAddLocationObject.countryObj.countryId != null && vm.saveOrAddLocationObject.countryObj.countryId != '') {
            if (vm.saveOrAddLocationObject.provinceObj && vm.saveOrAddLocationObject.provinceObj.provinceId != null && vm.saveOrAddLocationObject.provinceObj.provinceId != '') {
              if ((vm.addLocationInputSearchObject.citySearchKey != null && vm.addLocationInputSearchObject.citySearchKey != '')
                || (vm.addLocationInputSearchObject.sectorSearchKey != null && vm.addLocationInputSearchObject.sectorSearchKey != '')) {
                $scope.isValidEnable = true;
              } else {
                $scope.isValidEnable = false;
              }
            } else {
              if (vm.addLocationInputSearchObject.provinceSearchKey != null && vm.addLocationInputSearchObject.provinceSearchKey != '') {
                $scope.isValidEnable = true;
              }
            }
            vm.addLocationInputSearchObject.isInputEmptyCountry = false;
          } else if (vm.addLocationInputSearchObject.countrySearchKey != null && vm.addLocationInputSearchObject.countrySearchKey != '') {
            if (vm.addLocationInputSearchObject.provinceSearchKey != null && vm.addLocationInputSearchObject.provinceSearchKey != '') {
              $scope.isValidEnable = true;
            }
            vm.addLocationInputSearchObject.isInputEmptyCountry = false;
          } else {
            vm.addLocationInputSearchObject.isInputEmptyCountry = true;
            $scope.isValidEnable = false;
          }
        } else if (type == vm.APP.LIBRARY.CITY_CAPS) {
          if ((vm.saveOrAddLocationObject.countryObj && vm.saveOrAddLocationObject.countryObj.countryId != null && vm.saveOrAddLocationObject.countryObj.countryId != '')
            && (vm.saveOrAddLocationObject.provinceObj && vm.saveOrAddLocationObject.provinceObj.provinceId != null && vm.saveOrAddLocationObject.provinceObj.provinceId != '')) {
            if ((vm.saveOrAddLocationObject.cityObj && vm.saveOrAddLocationObject.cityObj.cityId != null && vm.saveOrAddLocationObject.cityObj.cityId != '')) {
              if ((vm.addLocationInputSearchObject.sectorSearchKey != null && vm.addLocationInputSearchObject.sectorSearchKey != '')) {
                $scope.isValidEnable = true;
              } else {
                $scope.isValidEnable = false;
              }
            } else {
              if (vm.addLocationInputSearchObject.citySearchKey != null && vm.addLocationInputSearchObject.citySearchKey != '') {
                $scope.isValidEnable = true;
              }
            }
            vm.addLocationInputSearchObject.isInputEmptyCountry = false;
            vm.addLocationInputSearchObject.isInputEmptyProvince = false;
          } else if (vm.saveOrAddLocationObject.countryObj && vm.saveOrAddLocationObject.countryObj.countryId != null && vm.saveOrAddLocationObject.countryObj.countryId != '') {
            if ((vm.addLocationInputSearchObject.provinceSearchKey != null && vm.addLocationInputSearchObject.provinceSearchKey != '')
              && (vm.addLocationInputSearchObject.citySearchKey != null && vm.addLocationInputSearchObject.citySearchKey != '')) {
              $scope.isValidEnable = true;
            } else {
              $scope.isValidEnable = false;
            }
            vm.addLocationInputSearchObject.isInputEmptyCountry = false;
            vm.addLocationInputSearchObject.isInputEmptyProvince = false;
          } else if ((vm.addLocationInputSearchObject.countrySearchKey != null && vm.addLocationInputSearchObject.countrySearchKey != '')
            && (vm.addLocationInputSearchObject.provinceSearchKey != null && vm.addLocationInputSearchObject.provinceSearchKey != '')
            && (vm.addLocationInputSearchObject.citySearchKey != null && vm.addLocationInputSearchObject.citySearchKey != '')) {
            $scope.isValidEnable = true;
            vm.addLocationInputSearchObject.isInputEmptyCountry = false;
            vm.addLocationInputSearchObject.isInputEmptyProvince = false;
          } else {
            vm.addLocationInputSearchObject.isInputEmptyCountry = true;
            vm.addLocationInputSearchObject.isInputEmptyProvince = true;
            $scope.isValidEnable = false;
          }
        } else if (type == vm.APP.LIBRARY.SECTOR_CAPS) {
          if ((vm.saveOrAddLocationObject.countryObj && vm.saveOrAddLocationObject.countryObj.countryId != null && vm.saveOrAddLocationObject.countryObj.countryId != '')
            && (vm.saveOrAddLocationObject.provinceObj && vm.saveOrAddLocationObject.provinceObj.provinceId != null && vm.saveOrAddLocationObject.provinceObj.provinceId != '')
            && (vm.saveOrAddLocationObject.cityObj && vm.saveOrAddLocationObject.cityObj.cityId != null && vm.saveOrAddLocationObject.cityObj.cityId != '')) {
            if ((vm.saveOrAddLocationObject.sectorObj && vm.saveOrAddLocationObject.sectorObj.sectorId != null && vm.saveOrAddLocationObject.sectorObj.sectorId != '')) {
              $scope.isValidEnable = false;
            } else {
              if (vm.addLocationInputSearchObject.sectorSearchKey != null && vm.addLocationInputSearchObject.sectorSearchKey != '') {
                $scope.isValidEnable = true;
              }
            }
            vm.addLocationInputSearchObject.isInputEmptyCountry = false;
            vm.addLocationInputSearchObject.isInputEmptyProvince = false;
            vm.addLocationInputSearchObject.isInputEmptyCity = false;
          } else if ((vm.saveOrAddLocationObject.countryObj && vm.saveOrAddLocationObject.countryObj.countryId != null && vm.saveOrAddLocationObject.countryObj.countryId != '')
            && (vm.saveOrAddLocationObject.provinceObj && vm.saveOrAddLocationObject.provinceObj.provinceId != null && vm.saveOrAddLocationObject.provinceObj.provinceId != '')) {
            if ((vm.addLocationInputSearchObject.citySearchKey != null && vm.addLocationInputSearchObject.citySearchKey != '') && (vm.addLocationInputSearchObject.sectorSearchKey != null && vm.addLocationInputSearchObject.sectorSearchKey != '')) {
              $scope.isValidEnable = true;
            }
            vm.addLocationInputSearchObject.isInputEmptyCountry = false;
            vm.addLocationInputSearchObject.isInputEmptyProvince = false;
            vm.addLocationInputSearchObject.isInputEmptyCity = false;
          } else if (vm.saveOrAddLocationObject.countryObj && vm.saveOrAddLocationObject.countryObj.countryId != null && vm.saveOrAddLocationObject.countryObj.countryId != '') {
            if ((vm.addLocationInputSearchObject.provinceSearchKey != null && vm.addLocationInputSearchObject.provinceSearchKey != '') && (vm.addLocationInputSearchObject.citySearchKey != null && vm.addLocationInputSearchObject.citySearchKey != '')
              && (vm.addLocationInputSearchObject.sectorSearchKey != null && vm.addLocationInputSearchObject.sectorSearchKey != '')) {
              $scope.isValidEnable = true;
            }
            vm.addLocationInputSearchObject.isInputEmptyCountry = false;
            vm.addLocationInputSearchObject.isInputEmptyProvince = false;
            vm.addLocationInputSearchObject.isInputEmptyCity = false;
          } else if ((vm.addLocationInputSearchObject.countrySearchKey != null && vm.addLocationInputSearchObject.countrySearchKey != '') && (vm.addLocationInputSearchObject.provinceSearchKey != null && vm.addLocationInputSearchObject.provinceSearchKey != '')
            && (vm.addLocationInputSearchObject.citySearchKey != null && vm.addLocationInputSearchObject.citySearchKey != '') && (vm.addLocationInputSearchObject.sectorSearchKey != null && vm.addLocationInputSearchObject.sectorSearchKey != '')) {
            $scope.isValidEnable = true;
            vm.addLocationInputSearchObject.isInputEmptyCountry = false;
            vm.addLocationInputSearchObject.isInputEmptyProvince = false;
            vm.addLocationInputSearchObject.isInputEmptyCity = false;
          } else {
            vm.addLocationInputSearchObject.isInputEmptyCountry = true;
            vm.addLocationInputSearchObject.isInputEmptyProvince = true;
            vm.addLocationInputSearchObject.isInputEmptyCity = true;
            $scope.isValidEnable = false;
          }
        }
      }
    }

    vm.checkLocationObj = function (type) {
      //Sector Location validation
      if (type == vm.APP.LIBRARY.SECTOR_CAPS) {
        if (vm.addLocationObj.cityTxt == undefined) {
          vm.validationMSGFlagCity = true;
        }
        if (vm.addLocationObj.provinceTxt == undefined) {
          vm.validationMSGFlagProvince = true;
        }
      }
      //City Location validation
      if (type == vm.APP.LIBRARY.CITY_CAPS) {
        if (vm.addLocationObj.provinceTxt == undefined) {
          vm.validationMSGFlagProvince = true;
        }
      }

      $timeout(function () {
        vm.validationMSGFlagProvince = false;
        vm.validationMSGFlagCity = false;
      }, 3000);
    }

    vm.addLocation = function (addlocationRequestForm, flag, event) {
      vm.existFlag = false;
      vm.validationMSGFlagProvince = false;
      vm.validationMSGFlagCity = false;
      vm.isAddLocationDisable = true;
      vm.addLocationObj = {};
      if (vm.saveOrAddLocationObject.countryObj && vm.saveOrAddLocationObject.countryObj.countryId != null && vm.saveOrAddLocationObject.countryObj.countryId != '') {
        vm.addLocationObj.countryId = vm.saveOrAddLocationObject.countryObj.countryId;
        vm.addLocationObj.countryTxt = vm.saveOrAddLocationObject.countryObj.countryTxt;
      } else {
        if (vm.addLocationInputSearchObject.countrySearchKey != null && vm.addLocationInputSearchObject.countrySearchKey != '') {
          vm.addLocationObj.countryTxt = vm.addLocationInputSearchObject.countrySearchKey;
        }
      }
      if (vm.saveOrAddLocationObject.provinceObj && vm.saveOrAddLocationObject.provinceObj.provinceId != null && vm.saveOrAddLocationObject.provinceObj.provinceId != '') {
        vm.addLocationObj.provinceId = vm.saveOrAddLocationObject.provinceObj.provinceId;
        vm.addLocationObj.provinceTxt = vm.saveOrAddLocationObject.provinceObj.provinceTxt;
      } else {
        if (vm.addLocationInputSearchObject.provinceSearchKey != null && vm.addLocationInputSearchObject.provinceSearchKey != '') {
          vm.addLocationObj.provinceTxt = vm.addLocationInputSearchObject.provinceSearchKey;
        }
      }
      if (vm.saveOrAddLocationObject.cityObj && vm.saveOrAddLocationObject.cityObj.cityId != null && vm.saveOrAddLocationObject.cityObj.cityId != '') {
        vm.addLocationObj.cityId = vm.saveOrAddLocationObject.cityObj.cityId;
        vm.addLocationObj.cityTxt = vm.saveOrAddLocationObject.cityObj.cityTxt;
      } else {
        if (vm.addLocationInputSearchObject.citySearchKey != null && vm.addLocationInputSearchObject.citySearchKey != '') {
          vm.addLocationObj.cityTxt = vm.addLocationInputSearchObject.citySearchKey;
          vm.checkLocationObj(vm.APP.LIBRARY.CITY_CAPS);
        }
      }
      if (vm.saveOrAddLocationObject.sectorObj && vm.saveOrAddLocationObject.sectorObj.sectorId != null && vm.saveOrAddLocationObject.sectorObj.sectorId != '') {
        vm.addLocationObj.sectorId = vm.saveOrAddLocationObject.sectorObj.sectorId;
        vm.addLocationObj.sectorTxt = vm.saveOrAddLocationObject.sectorObj.sectorTxt;
      } else {
        if (vm.addLocationInputSearchObject.sectorSearchKey != null && vm.addLocationInputSearchObject.sectorSearchKey != '') {
          vm.addLocationObj.sectorTxt = vm.addLocationInputSearchObject.sectorSearchKey;
          vm.checkLocationObj(vm.APP.LIBRARY.SECTOR_CAPS);
        }
      }
      //Location/Entity request from library section will always have isForm value false
      vm.addLocationObj.isForm = false;
      vm.addLocationObj
      if (vm.addLocationObj.countryTxt && !vm.validationMSGFlagProvince && !vm.validationMSGFlagCity) {
        var data = LibraryService.addNewLocation(vm.addLocationObj);
        data.then(function (response) {
          if (response && response.id) {
            vm.cancelLocation(addlocationRequestForm, flag);
            $state.transitionTo($state.current, $stateParams, {
              reload: true,
              inherit: false,
              notify: true
            });
            notifications.showSuccess({ message: vm.APP.LIBRARY.LOCATION_ADD });
            vm.isAddLocationDisable = false;
          }
        }, function (reason) {
          if (reason.status == 409) {
            vm.existFlag = true;
          } else {
            notifications.showError({ message: vm.APP.LIBRARY.LOCATION_ADD_PROBLEM });
          }
          $timeout(function () {
            vm.existFlag = false;
          }, 3000);
          vm.isAddLocationDisable = false;
          return reason;
        });
      } else {
        if (!vm.validationMSGFlagProvince && !vm.validationMSGFlagCity) {
          vm.addLocationInputSearchObject.isInputEmptyCountry = true;
        }
        vm.isAddLocationDisable = false;
      }
    }

    vm.hidePopup = function () {
      $mdDialog.hide();
    }

    vm.openSuccessDialog = function (id, event) {
      $mdDialog.show({
        targetEvent: event,
        contentElement: '#' + id,
        parent: angular.element(document.body)
      });
    }

    vm.cancelLocation = function (addlocationRequestForm, flag) {
      if (flag == 1) {
        $scope.isAddOrSaveLocation = false;
        communicationService.setData('isAddOrSaveLocation', $scope.isAddOrSaveLocation);
      }
      $scope.isHorizon = false;
      addlocationRequestForm.$setPristine();
      vm.addLocationInputSearchObject = {};
      vm.locationRequestType = {};
      vm.saveOrAddLocationObject = {};
      vm.saveOrAddLocationObject.countryObj = {};
      vm.saveOrAddLocationObject.provinceObj = {};
      vm.saveOrAddLocationObject.cityObj = {};
      vm.saveOrAddLocationObject.sectorObj = {};
      $mdDialog.hide();
    };

    vm.clearOnClose = function (searchKey, dropValue) {
      vm[searchKey] = undefined;
      vm[dropValue] = undefined;
      vm.entityNameLoader = false;
    }

    vm.searchEntity = function (searchKey, entityTypeId) {
      vm.entityNameLoader = true;
      if (searchKey && searchKey.length >= 2) {
        var deferred = $q.defer();
        vm.entityNameList = [];

        var data = LibraryService.getEntityNamesList(searchKey, entityTypeId);
        data.then(function (response) {
          vm.entityNameLoader = false;
          if (response.length > 0) {
            vm.isHide = true;
            vm.entityNameList = response;
            vm.entity.entityName = searchKey;
          } else {
            vm.clearEntity();
            vm.entity.entityName = searchKey;
            vm.isHide = true;
          }
          deferred.resolve(vm.entityNameList);
        });
        return deferred.promise;
      }
    };

    vm.getLocationMappingDataById = function (locationMappingId) {
      var locationData = CommonService.getLocationById(locationMappingId);
      locationData.then(function (response) {
        var locationObj = response;

        vm.entityLocationObject.countryObj = LibraryService.getCountryObj(locationObj);
        vm.entityLocationObject.provinceObj = LibraryService.getProvinceObj(locationObj);
        vm.entityLocationObject.cityObj = LibraryService.getCityObj(locationObj);
        vm.entityLocationObject.sectorObj = LibraryService.getSectorObj(locationObj);
        //Set Location mapping Id edit entity
        vm.entityLocationObject.countryObj.locationMappingId = locationObj.id;
        vm.entityLocationObject.provinceObj.locationMappingId = locationObj.id;
        vm.entityLocationObject.cityObj.locationMappingId = locationObj.id;
        vm.entityLocationObject.sectorObj.locationMappingId = locationObj.id;

      });
    };

    vm.getEntityProfileById = function (entityId) {
      if (entityId) {
        var entityProfile = {};
        var data = LibraryService.getEntityProfileById(entityId);
        data.then(function (response) {
          entityProfile = response;
          vm.entity.contactPersonName = entityProfile.contactPersonName;
          vm.entity.contactPhoneNumber = entityProfile.contactPhoneNumber;
          vm.entity.contactEmail = entityProfile.contactEmail;
          vm.entity.flag = entityProfile.flag;
          vm.entity.comments = entityProfile.comments;
          vm.getLocationMappingDataById(entityProfile.locationMappingId);
        });
      }
    };

    vm.clearEntity = function () {
      var copyEntityType = vm.entity.entityTypeSearchKey;
      vm.entity = {};
      vm.entityLocationObject = {};
      vm.entityLocationObject.countryObj = {};
      vm.entityLocationObject.provinceObj = {};
      vm.entityLocationObject.cityObj = {};
      vm.entityLocationObject.sectorObj = {};
      vm.entity.entityTypeSearchKey = copyEntityType;
      vm.isHide = false;
    };

    vm.getEntityTypeById = function (entityTypeId) {
      angular.forEach(vm.entityTypeList, function (data, key) {
        if (data.id == entityTypeId) {
          vm.entity.entityTypeSearchKey = data;
        }
      });
    }

    $scope.showEntityProfileById = function (entityId) {
      vm.entity = {};
      vm.entityPopupLoader = true;
      var data = LibraryService.getEntityProfileById(entityId);
      data.then(function (response) {
        vm.entity = response;
        //vm.entity.entityName = LibraryService.getEntityNameObj(vm.entity);
        vm.entity.entityName = vm.entity.name;
        vm.getLocationMappingDataById(vm.entity.locationMappingId);
        vm.getEntityTypeById(vm.entity.entityTypeId);
        vm.entityPopupLoader = false;
      });
    };

    vm.openEntityDeletePopup = function (event, id, entityId) {
      vm.entityId = entityId;
      vm.isDeleteEntityDisable = false;
      $mdDialog.show({
        targetEvent: event,
        contentElement: '#' + id,
        parent: angular.element(document.body)
      });
    };

    vm.deleteEntity = function (entityId) {
      vm.isDeleteEntityDisable = true;
      var data = LibraryService.deleteEntity(entityId);
      data.then(function (response) {
        notifications.showSuccess({ message: vm.APP.LIBRARY.ENTITY_DELETE });
        $state.transitionTo($state.current, $stateParams, {
          reload: true,
          inherit: false,
          notify: true
        });
        vm.isDeleteEntityDisable = false;
      }, function (reason) {
        notifications.showError({ message: vm.APP.LIBRARY.ENTITY_DELETE_PROBLEM });
        vm.isDeleteEntityDisable = false;
        return reason;
      });
    };

    vm.changeEditFlag = function () {
      vm.editFlag = false;
      vm.updateFlag = true;
      vm.isHide = true;
    };

    vm.showLocation = function () {
      var requestType;
      vm.addLocationObject = {};
      vm.location.country = null;
      vm.location.province = null;
      vm.location.city = null;
      vm.location.sector = null;

      if (vm.locationRequestType) {
        vm.locRegTypeValid = false;
      }
      //vm.isEnableSave=false;
      if (vm.locationRequestType.name != null) {
        requestType = vm.locationRequestType.name;
      }
      if (requestType == vm.APP.LIBRARY.COUNTRY) {
        vm.isShowCountry = false;
        vm.requestFlagProvinceInput = false;
        vm.requestFlagCityInput = false;
        vm.requestFlagSectorInput = false;
        vm.isShowProvince = true;
        vm.isShowCity = true;
        vm.isShowSector = true;
      } else if (requestType == vm.APP.LIBRARY.PROVINCE) {
        vm.isShowCountry = false;
        vm.isReplaceCountry = false;
        vm.requestFlagCountryInput = false;
        vm.requestFlagCityInput = false;
        vm.requestFlagSectorInput = false;
        vm.isShowProvince = false;
        vm.isReplaceProvince = false;
        vm.isShowCity = true;
        vm.isShowSector = true;
      } else if (requestType == vm.APP.LIBRARY.CITY) {
        vm.isReplaceCountry = false;
        vm.isReplaceProvince = false;
        vm.requestFlagCountryInput = false;
        vm.requestFlagProvinceInput = false;
        vm.requestFlagSectorInput = false;
        vm.isShowCountry = false;
        vm.isShowProvince = false;
        vm.isShowCity = false;
        vm.isReplaceCity = false;
        vm.isShowSector = true;
      } else {
        vm.isShowCountry = false;
        vm.isShowProvince = false;
        vm.isShowCity = false;
        vm.isShowSector = false;
        vm.isReplaceSector = false;
        vm.isReplaceCountry = false;
        vm.isReplaceCity = false;
        vm.isReplaceProvince = false;
        vm.requestFlagCityInput = false;
        vm.requestFlagCountryInput = false;
        vm.requestFlagProvinceInput = false;
      }
    }

    vm.searchRequestLocation = function (searchText, type) {
      if (searchText && searchText.length >= 2) {
        vm.locLoader = true;
        var deferred = $q.defer();
        vm.libraryLocObj = {};
        vm.libraryLocObj.searchKey = searchText;
        if (vm.addLocationObject) {
          if (vm.addLocationObject.countryObj && vm.addLocationObject.countryObj.countryId != null && vm.addLocationObject.countryObj.countryId != '') {
            vm.libraryLocObj.countryId = vm.addLocationObject.countryObj.countryId;
          }
          if (vm.addLocationObject.provinceObj && vm.addLocationObject.provinceObj.provinceId != null && vm.addLocationObject.provinceObj.provinceId != '') {
            vm.libraryLocObj.provinceId = vm.addLocationObject.provinceObj.provinceId;
          }
          if (vm.addLocationObject.cityObj && vm.addLocationObject.cityObj.cityId != null && vm.addLocationObject.cityObj.cityId != '') {
            vm.libraryLocObj.cityId = vm.addLocationObject.cityObj.cityId;
          }
          if (vm.addLocationObject.sectorObj && vm.addLocationObject.sectorObj.sectorId != null && vm.addLocationObject.sectorObj.sectorId != '') {
            vm.libraryLocObj.sectorId = vm.addLocationObject.sectorObj.sectorId;
          }
        }
        var data = LibraryLocationService.loadAllLocationsByType(vm.libraryLocObj, type);
        data.then(function (response) {
          vm.locLoader = false;
          if (response.autoSuggestionLocationDTOList) {
            if (type == vm.APP.LIBRARY.COUNTRY_CAPS) {
              vm.searchCountryList = response.autoSuggestionLocationDTOList;
            } else if (type == vm.APP.LIBRARY.PROVINCE_CAPS) {
              vm.searchProvinceList = response.autoSuggestionLocationDTOList;
            } else if (type == vm.APP.LIBRARY.CITY_CAPS) {
              vm.searchCityList = response.autoSuggestionLocationDTOList;
            } else if (type == vm.APP.LIBRARY.SECTOR_CAPS) {
              vm.searchSectorList = response.autoSuggestionLocationDTOList;
            }
          }
          if (type == vm.APP.LIBRARY.COUNTRY_CAPS) {
            deferred.resolve(vm.searchCountryList);
          } else if (type == vm.APP.LIBRARY.PROVINCE_CAPS) {
            deferred.resolve(vm.searchProvinceList);
          } else if (type == vm.APP.LIBRARY.CITY_CAPS) {
            deferred.resolve(vm.searchCityList);
          } else if (type == vm.APP.LIBRARY.SECTOR_CAPS) {
            deferred.resolve(vm.searchSectorList);
          }

        });
        return deferred.promise;
      }
    };

    vm.clear = function (type) {
      // vm.isEnableSave=false;
      if (type == vm.APP.LIBRARY.COUNTRY_CAPS) {
        vm.addLocationObject.countryObj = {};
        vm.requestFlagCountryInput = false;
        vm.isReplaceCountry = false;
        vm.location.country = undefined;

      } if (type == vm.APP.LIBRARY.PROVINCE_CAPS) {
        vm.addLocationObject.provinceObj = {};
        vm.requestFlagProvinceInput = false;
        vm.isReplaceProvince = false;
        vm.location.province = undefined;
      }
      if (type == vm.APP.LIBRARY.CITY_CAPS) {
        vm.addLocationObject.cityObj = {};
        vm.requestFlagCityInput = false;
        vm.isReplaceCity = false;
        vm.location.city = undefined;
      }
      if (type == vm.APP.LIBRARY.SECTOR_CAPS) {
        vm.addLocationObject.sectorObj = {};
        vm.requestFlagSectorInput = false;
        vm.isReplaceSector = false;
        vm.location.sector = undefined;
      }
    }

    vm.countryChange = function () {
      if (vm.country == "") {
        $scope.libraryAddEntityInputSearchObject.country = "";
        $scope.libraryAddEntityInputSearchObject.province = "";
        $scope.libraryAddEntityInputSearchObject.city = "";
        $scope.libraryAddEntityInputSearchObject.sector = "";
        vm.clear('COUNTRY');
        $scope.libraryAddEntityInputSearchObject.country = undefined;
      }
    }

    vm.countrySearchChange = function () {
      if (vm.location.country == "") {
        vm.clear('COUNTRY');
        vm.isReplaceCountry = false;
        vm.requestFlagCountryInput = false;
        vm.addLocationObject.countryObj = undefined;
      }
    }

    vm.provinceChange = function () {
      if (vm.province == "") {
        $scope.libraryAddEntityInputSearchObject.province = "";
        $scope.libraryAddEntityInputSearchObject.city = "";
        $scope.libraryAddEntityInputSearchObject.sector = "";
        vm.clear('PROVINCE');
        $scope.libraryAddEntityInputSearchObject.province = undefined;
      }
    }

    vm.provinceSearchChange = function () {
      if (vm.location.province == "") {
        vm.clear('PROVINCE');
        vm.isAddOrSaveLocationCountry = false;
        vm.addLocationObject.provinceObj = undefined;
      }
    }

    vm.cityChange = function () {
      if (vm.city == "") {
        $scope.libraryAddEntityInputSearchObject.city = "";
        $scope.libraryAddEntityInputSearchObject.sector = "";
        vm.clear('CITY');
        $scope.libraryAddEntityInputSearchObject.city = undefined;
      }
    }

    vm.citySearchChange = function () {
      if (vm.location.city == "") {
        vm.clear('CITY');
        vm.isAddOrSaveLocationCity = false;
        vm.addLocationObject.cityObj = undefined;
      }
    }

    vm.sectorChange = function () {
      if (vm.sector == "") {
        $scope.libraryAddEntityInputSearchObject.sector = "";
        vm.clear('SECTOR');
        $scope.libraryAddEntityInputSearchObject.sector = undefined;
      }
    }

    vm.sectorSearchChange = function () {
      if (vm.location.sector == "") {
        vm.clear('SECTOR');
        vm.isAddOrSaveLocationSector = false;
        vm.addLocationObject.sectorObj = undefined;
      }
    }

    vm.clearFields = function (addlocationRequestForm) {
      vm.addLocationObject = {};
      $scope.libraryAddEntityInputSearchObject = {};
      addlocationRequestForm.$setPristine();
    };
    vm.clearName = function (name, searchKey, dropValue) {
      vm[searchKey] = undefined;
      vm[dropValue] = undefined;
    }

    vm.replaceInputField = function () {
      var requestType;
      if (vm.locationRequestType != null) {
        requestType = vm.locationRequestType.name;
      }
      if (requestType == vm.APP.LIBRARY.COUNTRY) {
        if (vm.country != null || vm.location.country != null) {
          //vm.isEnableSave=true;
        }
        if (vm.isShowCountry == false && vm.addLocationObject.countryObj == undefined && vm.country != null && vm.searchCountryList.length == 0) {
          vm.isReplaceCountry = true;
          vm.requestFlagCountryInput = true;
          vm.location.country = vm.country;
        }
      }
      if (requestType == vm.APP.LIBRARY.PROVINCE) {
        if (vm.province != null) {
          //vm.isEnableSave=true;
        }
        //allow user to select country which is not in system
        if (vm.isShowCountry == false && vm.addLocationObject.countryObj == undefined && vm.country != null && vm.searchCountryList.length == 0) {
          vm.isReplaceCountry = true;
          vm.requestFlagCountryInput = true;
          vm.location.country = vm.country;
        }
        if (vm.isShowProvince == false && vm.addLocationObject.provinceObj == undefined && vm.province != null && vm.searchProvinceList.length == 0) {
          vm.location.province = vm.province;
          vm.isReplaceProvince = true;
          vm.requestFlagProvinceInput = true;
          //vm.isReplaceCountry = false;
        }
      }
      if (requestType == vm.APP.LIBRARY.CITY) {
        if (vm.city != null) {
          //vm.isEnableSave=true;
        }
        //allow user to select country which is not in system
        if (vm.isShowCountry == false && vm.addLocationObject.countryObj == undefined && vm.country != null && vm.searchCountryList.length == 0) {
          vm.isReplaceCountry = true;
          vm.requestFlagCountryInput = true;
          vm.location.country = vm.country;
        }
        //allow user to select province which is not in system
        if (vm.isShowProvince == false && vm.addLocationObject.provinceObj == undefined && vm.province != null && vm.searchProvinceList.length == 0) {
          vm.location.province = vm.province;
          vm.isReplaceProvince = true;
          vm.requestFlagProvinceInput = true;
          //vm.isReplaceCountry = false;
        }
        if (vm.isShowCity == false && vm.addLocationObject.cityObj == undefined && vm.city != null && vm.searchCityList.length == 0) {
          vm.location.city = vm.city;
          vm.isReplaceCity = true;
          vm.requestFlagCityInput = true;
          //vm.isReplaceCountry = false;
        }
      }
      if (requestType == vm.APP.LIBRARY.SECTOR) {
        if (vm.sector != null) {
          //vm.isEnableSave=true;
        }
        //allow user to select country which is not in system
        if (vm.isShowCountry == false && vm.addLocationObject.countryObj == undefined && vm.country != null && vm.searchCountryList.length == 0) {
          vm.isReplaceCountry = true;
          vm.requestFlagCountryInput = true;
          vm.location.country = vm.country;
        }
        //allow user to select province which is not in system
        if (vm.isShowProvince == false && vm.addLocationObject.provinceObj == undefined && vm.province != null && vm.searchProvinceList.length == 0) {
          vm.location.province = vm.province;
          vm.isReplaceProvince = true;
          vm.requestFlagProvinceInput = true;
          //vm.isReplaceCountry = false;
        }
        //allow user to select city which is not in system
        if (vm.isShowCity == false && vm.addLocationObject.cityObj == undefined && vm.city != null && vm.searchCityList.length == 0) {
          vm.location.city = vm.city;
          vm.isReplaceCity = true;
          vm.requestFlagCityInput = true;
          //vm.isReplaceCountry = false;
        }
        if (vm.isShowSector == false && vm.addLocationObject.sectorObj == undefined && vm.sector != null && vm.searchSectorList.length == 0) {
          vm.location.sector = vm.sector;
          vm.isReplaceSector = true;
          vm.requestFlagSectorInput = true;
          //vm.isReplaceCountry = false;
        }
      }
    }

    vm.saveValid = function (input) {
      if (input.type == 'COUNTRY') {
        if (vm.addLocationObject.countryObj && vm.addLocationObject.countryObj.countryId != "" && !$scope.libraryAddEntityInputSearchObject.isAddOrSaveLocationCountry) {
          $scope.libraryAddEntityInputSearchObject.country = vm.addLocationObject.countryObj.countryTxt;
          vm.location.country = vm.addLocationObject.countryObj.countryTxt;
        } else if (vm.addLocationObject.countryObj && $scope.addLocationObject.countryObj.countryTxt == $scope.libraryAddEntityInputSearchObject.country) {
          $scope.addLocationObject.countryObj.countryId = undefined;
        }
        $scope.libraryAddEntityInputSearchObject.countrySearchKey = $scope.libraryAddEntityInputSearchObject.country;
        vm.country = $scope.libraryAddEntityInputSearchObject.countrySearchKey;
      }

      if (input.type == 'PROVINCE') {
        if (vm.addLocationObject.provinceObj && vm.addLocationObject.provinceObj.provinceId != "" && !$scope.libraryAddEntityInputSearchObject.isAddOrSaveLocationProvince) {
          $scope.libraryAddEntityInputSearchObject.province = vm.addLocationObject.provinceObj.provinceTxt;
        } else if (vm.addLocationObject.provinceObj && $scope.addLocationObject.provinceObj.provinceTxt == $scope.libraryAddEntityInputSearchObject.province) {
          $scope.addLocationObject.provinceObj.provinceId != undefined;
        }
        $scope.libraryAddEntityInputSearchObject.provinceSearchKey = $scope.libraryAddEntityInputSearchObject.province;
        vm.province = $scope.libraryAddEntityInputSearchObject.provinceSearchKey;
      }

      if (input.type == 'CITY') {
        if (vm.addLocationObject.cityObj && vm.addLocationObject.cityObj.cityId != "" && !$scope.libraryAddEntityInputSearchObject.isAddOrSaveLocationCity) {
          $scope.libraryAddEntityInputSearchObject.city = vm.addLocationObject.cityObj.cityTxt;
        } else if (vm.addLocationObject.cityObj && $scope.addLocationObject.cityObj.cityTxt == $scope.libraryAddEntityInputSearchObject.city) {
          $scope.addLocationObject.cityObj.cityId != undefined;
        }
        $scope.libraryAddEntityInputSearchObject.citySearchKey = $scope.libraryAddEntityInputSearchObject.city;
        vm.city = $scope.libraryAddEntityInputSearchObject.citySearchKey;
      }

      if (input.type == 'SECTOR') {
        if (vm.addLocationObject.sectorObj && vm.addLocationObject.sectorObj.sectorId != "" && !$scope.libraryAddEntityInputSearchObject.isAddOrSaveLocationSector) {
          $scope.libraryAddEntityInputSearchObject.sector = vm.addLocationObject.sectorObj.sectorTxt;
        } else if (vm.addLocationObject.sectorObj && $scope.addLocationObject.sectorObj.sectorTxt == $scope.libraryAddEntityInputSearchObject.sector) {
          $scope.addLocationObject.sectorObj.sectorId != undefined;
        }
        $scope.libraryAddEntityInputSearchObject.sectorSearchKey = $scope.libraryAddEntityInputSearchObject.sector;
        vm.sector = $scope.libraryAddEntityInputSearchObject.sectorSearchKey;
      }
    };

    vm.populatePreFillDetails = function (locationObj) {
      if (locationObj) {
        $scope.copyLocationObj = angular.copy(locationObj);
        if ($scope.copyLocationObj.countryId != null) {
          vm.addLocationObject.countryObj = $scope.copyLocationObj;
        }
        if ($scope.copyLocationObj.provinceId != null) {
          vm.addLocationObject.provinceObj = $scope.copyLocationObj;
        }
        if ($scope.copyLocationObj.cityId != null) {
          vm.addLocationObject.cityObj = $scope.copyLocationObj;
        }
        if ($scope.copyLocationObj.sectorId != null) {
          vm.addLocationObject.sectorObj = $scope.copyLocationObj;
        }
      }
    }

    $scope.setLibraryType = function () {
      vm.library.libraryType = $state.current.name;
    };

    $scope.validateUpdateEntityLocation = function () {
      vm.updateEntityLocation = true;
      if (angular.isUndefinedOrNull(vm.locationObj.cityTxt) || angular.isUndefinedOrNull(vm.locationObj.provinceTxt) || angular.isUndefinedOrNull(vm.locationObj.countryTxt)) {
        vm.updateEntityLocation = false;
      }
    };

  }

})();

