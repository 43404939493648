(function () {
  "use strict";

  var lariDevApp = angular.module("lariAidApp");
  lariDevApp.directive("previewImage", function () {
    return {
      restrict: "AE",
      templateUrl: "app/investigation/prospects/profile/investigation-form/preview-image-directive/preview.image.html",
      scope: {
        assetList: "=assetList",
        assetSelectedCount: "=assetSelectedCount",
        isReadOnly: "=isReadOnly",
        baseUrl: "=baseUrl"
      },
      controller: "ManageAttachmentsController",
      link: function (scope, element, attrs, ngModel) {}
    };
  });
})();
