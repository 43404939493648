(function() {
    'use strict';

    angular
        .module('lariAidApp')
        .config(stateConfig);
    stateConfig.$inject = ['$stateProvider','APP'];
    function stateConfig($stateProvider,APP) {
        $stateProvider.state('internal', {
            parent: 'users',
            url: '/internal',
            data: {
                authorities: APP.GLOBAL.USER_GROUP
            },
            params: {
            	id:null,
            },
            views: {
                'userList': {
                    templateUrl: 'app/user/internal/user.html',
                    controller: 'UserController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    if(localStorage && localStorage.getItem('lari_currentVersion') != null){
                       	$translatePartialLoader.addPart('user.json?version='+localStorage.getItem('lari_currentVersion'));
                       }else{
                       	$translatePartialLoader.addPart('user.json?timestamp='+new Date().getTime());
                       }
                    return $translate.refresh();
                }],
                mdTabIndex:['tabservice','$timeout', function (tabservice,$timeout) {
                	tabservice.setIndex("1");
                	$timeout(function() {
                	    angular.element('#internalUser').triggerHandler('click');
                	  });
                }]
            }
        });
    }
})();
