(function() {
	'use strict';
	
	var lariDevApp = angular
    .module('lariAidApp')
    lariDevApp.controller('NeighbourhoodCanvasController', NeighbourhoodCanvasController);
	NeighbourhoodCanvasController.$inject = ['$scope','$window', '$mdSidenav','Principal','$state','$mdMedia','$cookies','APP','NeighbourhoodCanvasService','InvestigationFormService','$stateParams','$timeout','Upload','$mdDialog','$rootScope'];
	function NeighbourhoodCanvasController ($scope,$window, $mdSidenav,Principal,$state,$mdMedia,$cookies,APP,NeighbourhoodCanvasService,InvestigationFormService,$stateParams,$timeout,Upload,$mdDialog,$rootScope) {
		
		var vm = this;
		vm.dateOfVisit = '';
		vm.app = APP;
    	$scope.declarationDate = undefined;
        $scope.neighbourhoodCanvasForm = {};
        $scope.neighbourhoodCanvasForm.sections = [];
        //$scope.neighbourhoodCanvasForm.sections.questions = [];
        $scope.neighbourhoodCanvasForm.summary = {};
        $scope.loadingNeighbourhood = false;
        vm.entityLocationObject = {};
		vm.entityLocationObject.countryObj = {};
		vm.entityLocationObject.provinceObj = {};
		vm.entityLocationObject.cityObj = {};
		vm.entityLocationObject.sectorObj = {};
        vm.inputSearchObject = {};
        vm.LocationObject = {};
        $scope.interviewFlag = true;
        $scope.disablesaveflag = false;
        var oldFormData = {};
        vm.APP = APP;
        
        vm.loadNeighbourhoodCanvasFormDetails = function(){
          $scope.loadingNeighbourhood = true;
          vm.getNeighborhoodCanvassDetails();
     	 
        };
        
        vm.getNeighborhoodCanvassDetails = function (){
  		   var sectionKey = vm.app.REF_TERM.NEIGHBORHOOD_CANVAS;
  		   var formId = $scope.investigationFormId;
      	   var data = InvestigationFormService.getInvestigationFormBySectionId(sectionKey,formId);
  		  	data.then(function(response){
  		  		if(response.formVersion != null){
   		  			$scope.formVersion = response.formVersion;
   		  		}
  		  		if(response.sectionId != null){
		  			$scope.neighbourhoodSectionId = response.sectionId;
		  		}
  		  		if(response.formDTO == null){
  		  		   var tempObj = {};
  		     	   tempObj.questions = [];
  		     	   $scope.neighbourhoodCanvasForm.sections.push(tempObj);
  		  		} else if(response.formDTO.sections.length == 0) {
				  	   if(response.formDTO.summary){
						     var tempObj = {};
						  	   tempObj.questions = [];
				  	           $scope.neighbourhoodCanvasForm.sections.push(tempObj);
				  	           $scope.neighbourhoodCanvasForm.summary = response.formDTO.summary;
				  	   } else {
						  	   var tempObj = {};
						  	   tempObj.questions = [];
				  	           $scope.neighbourhoodCanvasForm.sections.push(tempObj);
				  	   }
		  	    } else {
  		  			$scope.neighbourhoodCanvasForm = angular.copy(response.formDTO); 		  			
	  		  		$scope.setUpOldFormData();
  		  			$scope.setLocationResponse(0);	
  		  		}
  		  	    //Set the object to restrict the initial save call
  		  		oldFormData = angular.copy(response.formDTO);
  		  		$scope.loadingNeighbourhood = false;
		  		$scope.updateLastEditTime(response.updatedOn);
  		   }, function(reason) {
  			  return reason;
  		   });
        };
        
        /* Method name          : saveNeighbourhoodCanvasFormDetails()
         * Method Description 	: This method saves the NeighbourhoodCanvas form details 
 	     */
        $scope.saveNeighbourhoodCanvasFormDetails = function(){
           var isPutJsonSimilar = false;
           $rootScope.warningPopup.show = false; //To avoid showing popup while moving to other section if there is no edit in current section
      	   var saveDataObj = {};
      	   //save location
      	   var formNumber = vm.findOpenform();
      	   //vm.saveLocation(formNumber);
      	   if(formNumber != -1){
      		 $scope.saveLocation(--formNumber);   
      	   }
      	   saveDataObj.formDTO = angular.copy($scope.neighbourhoodCanvasForm);
      	   saveDataObj.formVersion = $scope.formVersion;
      	   saveDataObj.investigationFormId = $scope.investigationFormId;
      	   saveDataObj.sectionId = $scope.neighbourhoodSectionId;
	      	 for(var i=0;i<saveDataObj.formDTO.sections.length;i++){
	             if(!saveDataObj.formDTO.sections[i].questions){
	                 saveDataObj.formDTO.sections.splice(i,1);
	                 i--;
	             } else if(saveDataObj.formDTO.sections[i].questions.length == 0) {
	                 saveDataObj.formDTO.sections.splice(i,1);
	                 i--;
	             } else {
	                 delete saveDataObj.formDTO.sections[i].expanded;
	                 saveDataObj.formDTO.sections[i].sortOrder = i+1;
	             }
	         }
	      	 
	      	/*	Set the temporary object to avoid unwanted save call. 
  				Fist condition to avoid the initial save call for empty form 
  				Second condition to avoid the initial save call if the section has any form data 
	      	*/
            if(angular.isUndefined($scope.tempNeighbourhoodObj) && saveDataObj.formDTO.sections.length == 0 && oldFormData == null && 
                    (saveDataObj.formDTO.summary.comment == null || saveDataObj.formDTO.summary.comment == undefined)) {
                $scope.tempNeighbourhoodObj = angular.copy(saveDataObj);
            } else if(angular.isUndefined($scope.tempNeighbourhoodObj) && angular.equals(JSON.stringify(saveDataObj.formDTO), JSON.stringify(oldFormData))) {
         	   $scope.tempNeighbourhoodObj = angular.copy(saveDataObj);
            } else if(angular.isUndefined($scope.tempNeighbourhoodObj) && !angular.equals(JSON.stringify(saveDataObj.formDTO), JSON.stringify(oldFormData))) {
            	$scope.tempNeighbourhoodObj = {};
            	$scope.tempNeighbourhoodObj.formDTO = angular.copy(oldFormData);
            }
	      	
            if(!angular.isUndefined($scope.tempNeighbourhoodObj)){
            	if(angular.equals(JSON.stringify(saveDataObj.formDTO), JSON.stringify($scope.tempNeighbourhoodObj.formDTO))){
            		isPutJsonSimilar = true;	    			   
            	} 
            }
	      	
            if($scope.isOwner){
            	$scope.tempNeighbourhoodObj = angular.copy(saveDataObj);
            } else {
            	$scope.tempNeighbourhoodObj = undefined; //Every time temporary object will be newly updated for non owner  
            }
            
            /* saveData flag - to ensure the save button is clicked in form*/
	       if(!isPutJsonSimilar){
	    	   if($scope.isOwner || $scope.saveData){
	    		   //Flag to set the section results are edited or not
	    		   if(angular.isDefined($scope.neighbourhoodCheckList) && angular.isDefined($scope.neighbourhoodCheckListTempObj) && !angular.equals($scope.neighbourhoodCheckList, $scope.neighbourhoodCheckListTempObj)) {
	    			   saveDataObj.isFormSectionResultEdited = true;
	    			   $scope.saveAnalystFormTypeCheckList('neighbourhood', true, false);
	    		   }
	    		   
	    		   var data = InvestigationFormService.saveInvestigationForm(saveDataObj);
		   		  	data.then(function(response){
		  		  		if($state.current.name === vm.app.INVESTIGATION.STATE.NEIGHBOURHOOD_STATE)
		  		  		{
		  		  			$scope.updateLastEditTime(response.updatedOn);
		  		  		}
		  		  		$scope.uploadFileStatusUpdated();
		   		  }, function(reason) {
		   			  return reason;
		   		  }); 
	    	   }
	    	   $rootScope.warningPopup.show = true;
	       } else if(($scope.isOwner || $scope.saveData) && angular.isDefined($scope.neighbourhoodCheckList) && angular.isDefined($scope.neighbourhoodCheckListTempObj) 
	    		   && !angular.equals($scope.neighbourhoodCheckList, $scope.neighbourhoodCheckListTempObj)) {
			   $scope.saveAnalystFormTypeCheckList('neighbourhood', true);
		   }
         };
         
         /**
          * Save the Location Object
          */
         $scope.saveLocation = function(index){    	 
        	 //for(var i=0;i<$scope.neighbourhoodCanvasForm.sections.length;i++){
        	 var i = index;
      		   if($scope.neighbourhoodCanvasForm.sections[i].questions[0] && $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1){
      			   if($scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1.answer == 'answer_yes'){
      				   $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1a = {};
      				   $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1b = {};
      				   $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1c = {};
      				   $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1d = {};
      				   
          			   if(vm.entityLocationObject.countryObj && !vm.inputSearchObject.isAddOrSaveLocationCountry && vm.entityLocationObject.countryObj.countryTxt != null && vm.entityLocationObject.countryObj.countryTxt != ''){
                			 $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1a.answer = vm.entityLocationObject.countryObj.countryTxt;
                			 $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1a.subAnswer = vm.entityLocationObject.countryObj.countryId;
          			   }else if(vm.inputSearchObject.isAddOrSaveLocationCountry && vm.inputSearchObject.countrySearchKey != null && vm.inputSearchObject.countrySearchKey != ''){
          				   	 $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1a.answer = vm.inputSearchObject.countrySearchKey;
                		    // $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1a.subAnswer = null;
          			   }/*else{
          				     $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1a.answer = null;
                  		     $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1a.subAnswer = null;
          			   }*/
          			   
          			   if(vm.entityLocationObject.provinceObj && !vm.inputSearchObject.isAddOrSaveLocationProvince && vm.entityLocationObject.provinceObj.provinceTxt != null && vm.entityLocationObject.provinceObj.provinceTxt != ''){
                			 $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1b.answer = vm.entityLocationObject.provinceObj.provinceTxt;
                			 $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1b.subAnswer = vm.entityLocationObject.provinceObj.provinceId;
  	  	  			   }else if(vm.inputSearchObject.isAddOrSaveLocationProvince && vm.inputSearchObject.provinceSearchKey != null && vm.inputSearchObject.provinceSearchKey != ''){
	  	  	  				 $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1b.answer = vm.inputSearchObject.provinceSearchKey;
	  	  	  				 //$scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1b.subAnswer = null;
  	  	  			   }/*else{
  	    				     $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1b.answer = null;
  	            		     $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1b.subAnswer = null;
  	    			   }*/
          			   
          			   if(vm.entityLocationObject.cityObj && !vm.inputSearchObject.isAddOrSaveLocationCity && vm.entityLocationObject.cityObj.cityTxt != null && vm.entityLocationObject.cityObj.cityTxt != ''){
    	          			 $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1c.answer = vm.entityLocationObject.cityObj.cityTxt;
                			 $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1c.subAnswer = vm.entityLocationObject.cityObj.cityId;
  	  	  			   }else if(vm.inputSearchObject.isAddOrSaveLocationCity && vm.inputSearchObject.citySearchKey != null && vm.inputSearchObject.citySearchKey != ''){
	  	  	  				 $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1c.answer = vm.inputSearchObject.citySearchKey;
	  	  	  				// $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1c.subAnswer = null;
  	  	  			   }/*else{
  	    				     $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1c.answer = null;
  	            		     $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1c.subAnswer = null;
  	    			   }*/
          			 
          			   if(vm.entityLocationObject.sectorObj && !vm.inputSearchObject.isAddOrSaveLocationSector && vm.entityLocationObject.sectorObj.sectorTxt != null && vm.entityLocationObject.sectorObj.sectorTxt != ''){
    	         			 $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1d.answer = vm.entityLocationObject.sectorObj.sectorTxt;
                			 $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1d.subAnswer = vm.entityLocationObject.sectorObj.sectorId;
  	  	 			   }else if(vm.inputSearchObject.isAddOrSaveLocationSector && vm.inputSearchObject.sectorSearchKey != null && vm.inputSearchObject.sectorSearchKey != ''){
	  	  	 				 $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1d.answer = vm.inputSearchObject.sectorSearchKey;
	  	  	 				 //$scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1d.subAnswer = null;
  	  	 			   }/*else{
  	    				     $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1d.answer = null;
  	            		     $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1d.subAnswer = null;
  	    			   }*/
          			   //Set the requested flag
          			   $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1a.requested = false;
          			   $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1b.requested = false;
          			   $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1c.requested = false;
          			   $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1d.requested = false;
      			   }
      		   }        		          		   
      	   //}
         };
         
         vm.initializeLocations = function(){
        	 vm.inputSearchObject = {  
           		  "country": "",
           		  "countrySearchKey": "",
           		  "isAddOrSaveLocationCountry": false,
           		  "isAddOrSaveLocationProvince": false,
           		  "isAddOrSaveLocationCity": false,
           		  "isAddOrSaveLocationSector": false,
           		  "citySearchKey": "",
           		  "sectorSearchKey": "",
           		  "provinceSearchKey": "",
           		  "province": "",
           		  "city": "",
           		  "sector": ""};
           vm.LocationObject = {
       		"sectorObj": {
   						"sectorTxt": null,
   						"sectorId":null
   				},
   			"countryObj": {
   					    "countryTxt": null,
   					    "countryId": null
   				  		},
   			"provinceObj": {       			    
   					    "provinceTxt": null,
   					    "provinceId": null
   					  },
   			"cityObj": {
   					    "cityTxt": null,
   					    "cityId": null
   					  }
   				};
         };
         
         $scope.setLocationResponse = function(index){
        	 //for(var i=0;i<$scope.neighbourhoodCanvasForm.sections.length;i++){
        	 var i = index;
        	 vm.initializeLocations();
        	 if($scope.neighbourhoodCanvasForm != null && $scope.neighbourhoodCanvasForm.sections != null && $scope.neighbourhoodCanvasForm.sections[i].questions[0] && $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1){
        		 
	      		   if($scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1.answer == 'answer_yes'){
	      			   
      			   if($scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1a != null && $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1a.subAnswer != null){
      				 vm.LocationObject.countryObj.countryTxt = $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1a.answer;
        			 vm.LocationObject.countryObj.countryId = $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1a.subAnswer;
      			   }else if($scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1a != null && $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1a.answer != null){
					vm.LocationObject.countryObj.countryTxt = $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1a.answer; 
					vm.inputSearchObject.isAddOrSaveLocationCountry = true;
      				 vm.inputSearchObject.countrySearchKey = $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1a.answer;
      			   }
      			   if($scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1b != null && $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1b.subAnswer != null){
      				 vm.LocationObject.provinceObj.provinceTxt = $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1b.answer;
        			 vm.LocationObject.provinceObj.provinceId = $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1b.subAnswer;
      			   }else if($scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1b != null && $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1b.answer != null){
					vm.LocationObject.provinceObj.provinceTxt = $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1b.answer; 
					vm.inputSearchObject.isAddOrSaveLocationProvince = true;
      				 vm.inputSearchObject.provinceSearchKey = $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1b.answer;
      			   }
      			   
      			   if($scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1c != null && $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1c.subAnswer != null){
      				 vm.LocationObject.cityObj.cityTxt = $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1c.answer;
       			     vm.LocationObject.cityObj.cityId = $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1c.subAnswer;
      			   }else if($scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1c != null && $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1c.answer != null){
					vm.LocationObject.cityObj.cityTxt = $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1c.answer; 
					vm.inputSearchObject.isAddOrSaveLocationCity = true;
      				 vm.inputSearchObject.citySearchKey = $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1c.answer;
      			   }
      			   
      			   if($scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1d != null && $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1d.subAnswer != null){
      				 vm.LocationObject.sectorObj.sectorTxt = $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1d.answer;
        			 vm.LocationObject.sectorObj.sectorId = $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1d.subAnswer;
      			   }else if($scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1d != null && $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1d.answer != null){
					vm.LocationObject.sectorObj.sectorTxt = $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1d.answer;
					vm.inputSearchObject.isAddOrSaveLocationSector = true;
      				 vm.inputSearchObject.sectorSearchKey = $scope.neighbourhoodCanvasForm.sections[i].questions[0].neighborhood_1d.answer;
      			   }
	      		   }
             	}
		        	$timeout(function(){
	  					vm.setOpenFormLocation();
						$rootScope.triggerPopulatePreFillDetails(vm.LocationObject.countryObj, vm.LocationObject.provinceObj, vm.LocationObject.cityObj, vm.LocationObject.sectorObj);
	  					$rootScope.loadValid();
						},500);       	 
         };
         
         vm.setOpenFormLocation = function(){
        	 
        		  vm.entityLocationObject.countryObj = vm.LocationObject.countryObj;
		  		  vm.entityLocationObject.provinceObj = vm.LocationObject.provinceObj;
		  		  vm.entityLocationObject.cityObj = vm.LocationObject.cityObj;
		  		  vm.entityLocationObject.sectorObj = vm.LocationObject.sectorObj;
        	 
         };
         
         vm.findOpenform = function(){
        	 if($scope.neighbourhoodCanvasForm.sections.length > 1){
        		 var j = -1;
        		 for(var i = 0;i < $scope.neighbourhoodCanvasForm.sections.length;i++){
            		 if($scope.neighbourhoodCanvasForm.sections[i].expanded == true){
            			 return ++i;
            		 }
            	 } 
        		 return j;
        	 }
        	 return 1;
         };
         
         vm.saveLocation = function(openForm){
        	 var i = 0;
        	 if(openForm != 1){
        		 i = --openForm;
        	 } 
        	 vm.LocationObject[i].countryObj = vm.entityLocationObject.countryObj;
        	 vm.LocationObject[i].provinceObj = vm.entityLocationObject.provinceObj;
        	 vm.LocationObject[i].cityObj = vm.entityLocationObject.cityObj;
        	 vm.LocationObject[i].sectorObj = vm.entityLocationObject.sectorObj;
         };
         
         vm.interviewFlag = function(flag, index){
        	 $scope.interviewFlag = flag;
        	 if(index){
        		 $scope.sectionIndex = index; 
        	 }
          };
         
         vm.addAdditionalInterviewForm = function(){
             var testObj = {};
             if($scope.interviewFlag == 1){
            	 $scope.neighbourhoodCanvasForm.sections[0].questions[0].neighborhood_1.interviews.push(testObj);
             }
             if($scope.interviewFlag == 2){
            	 if($scope.sectionIndex){
            		 $scope.neighbourhoodCanvasForm.sections[$scope.sectionIndex].questions[0].neighborhood_1.interviews.push(testObj);
            	 }
             }
         };
          
         vm.loadLocations = function(index){
        	 var formNumber = vm.findOpenform();
        	 formNumber--;
        	 if(formNumber > 0){
        		 $scope.saveLocation(formNumber); 
        	 }
        	 $scope.setLocationResponse(index);
         };
         
          /* Method name           : collapseAll()
      	 * Method Description 	 : This method is used to collapse the accordion 
  		 */
          vm.collapseAll = function(index, flag){
        	  var height = 0;
        	  vm.loadLocations(index);
	            for(var i=0;i<$scope.neighbourhoodCanvasForm.sections.length;i++){
	            	 if(flag==0){
	 	            	height = height + $('.acc_'+index).closest('.md-accordion').offsetHeight + 42;
	 	            }
	 	            else{
	 	            	height = height + $('.acc_'+index).closest('.md-accordion')[0].offsetHeight + 42;
	 	            }
	            if(i == index){
	   	             if(!$scope.neighbourhoodCanvasForm.sections[i].expanded){
	   	            	 	$scope.formViewCall(index);
			   	            $scope.neighbourhoodCanvasForm.sections[i].expanded = !$scope.neighbourhoodCanvasForm.sections[i].expanded;
			                $('#neighbourhoodCanvas').animate({scrollTop: height}, 'slow');
			                
			                //Calling get documents based on accordion index
			                $scope.getUploadFiles(null,vm.app.REF_TERM.NEIGHBORHOOD_CANVAS,index)
	   	             }else{
	   	            	    $scope.neighbourhoodCanvasForm.sections[i].expanded = !$scope.neighbourhoodCanvasForm.sections[i].expanded;
	   	             }
	            } else {
	            		$scope.neighbourhoodCanvasForm.sections[i].expanded = false;
	            	}
	            if($scope.neighbourhoodCanvasForm.sections[i].expanded){
           	    	$scope.openAccordionIndex = i;
           	    }
	            }
         };
         
         
         /* Method name           : addAdditionalInstitutionForm()
          * Method Description 	: This method adds Neighborhood canvas form to list of birth certificate form 
  	    */
         vm.addAdditionalInstitutionForm = function(){
             var tempObj = {};
             tempObj.questions = [];
             tempObj.expanded = false;
             $scope.neighbourhoodCanvasForm.sections.push(tempObj);
             vm.collapseAll($scope.neighbourhoodCanvasForm.sections.length - 1,0);
         };
         
         /* Method name           : removeBirthCertificateForm()
          * Method Description 	: This method removes the added Neighborhood canvas form from list of birth certificate form
  	    */
         /*vm.removeneighbourhoodCanvasForm = function(index){
      	   $scope.neighbourhoodCanvasForm.sections.splice(index,1);
         };*/
         vm.removeneighbourhoodCanvasForm = function(ev,id,indexValue){
        	 $scope.indexVal = indexValue; 
        	 $mdDialog.show({
        		 contentElement: angular.element('#'+id),
        		 targetEvent: ev,
        		 clickOutsideToClose: false,
        		 escapeToClose: false
        	 })
         };
         
         vm.closeConform = function(index){
        	 $scope.deleteLoader.flag = true;
         	$mdDialog.hide();
         	$scope.neighbourhoodCanvasForm.sections.splice(index,1);
         	if(index != 0){
           		vm.collapseAll(index-1,1);
           	}
         	 $scope.enableDeleteButton();
         };
         
         vm.closeCancel = function(){
         	$mdDialog.cancel();
         	$scope.interviewFlag = true;
         };
         
         /* File Upload Sections */
         
         vm.uploadFileForSummary = function(isSummary,accordionIndex,idName,ev){
     		$("#neighborhoodSectionSummary").val(null);
  	        $('#neighborhoodSectionSummary').trigger('click'); 
  	        $('#neighborhoodSectionSummary').off('change').on('change', function() {
	  	    	var file = this.files[0];
	     		var sectionId = $scope.neighbourhoodSectionId;
	     		var questionKey = null;
    			if(isSummary == "true"){
    				questionKey = vm.app.INVESTIGATION_FORM.SUMMARY_KEY;
    			}
    			$scope.setOrginSectionFileUpload(vm.app.REF_TERM.NEIGHBORHOOD_CANVAS);
    			$scope.uploadFileInSectionSummary(ev,file,questionKey,sectionId,isSummary,null,idName,accordionIndex,null);
	        });
  		};

		vm.nextInvestigationForm = function(){
			//$scope.saveAnalystFormTypeCheckList('neighbourhood');
			$scope.disablesaveflag = true;
			$scope.saveData = true;
			$scope.saveNeighbourhoodCanvasFormDetails();
			$rootScope.warningPopup.show = false;
			$scope.sectionNavigationFunc(vm.app.INVESTIGATION_FORM.FAMILY_FORM);
			//$state.go("familyInformation");
			if($scope.investigationFormType == vm.app.INVESTIGATION.ANALYST_FORM){
				$scope.collapseAll(3);
			}
			$scope.updateLastEditTime(null);
		};
		
		vm.deleteIndex = function(event,id,index){
			//$scope.saveNeighbourhoodCanvasFormDetails();		
			$scope.indexVal = index;
			$scope.interviewFlag = false;
			$mdDialog.show({
				contentElement: angular.element('#'+id),
				targetEvent: event,
				clickOutsideToClose: false,
				escapeToClose: false,
				onRemoving: function (event, removePromise) {
					vm.closeCancel();
				}
			});
			//vm.getNeighborhoodCanvassDetails();
		};
       	 
       	vm.deleteInterviewConfirm = function(index){
       		$scope.deleteLoader.flag = true;
       		/*var formNumber = vm.findOpenform();
       	    formNumber--;
       	    $scope.neighbourhoodCanvasForm.sections[formNumber].questions[0].neighborhood_1.interviews.splice(index,1);*/
       		if($scope.neighbourhoodCanvasForm.sections.length == 1){
	  			$scope.openAccordionIndex = 0;
	  		}
       		$scope.neighbourhoodCanvasForm.sections[$scope.openAccordionIndex].questions[0].neighborhood_1.interviews.splice(index,1);
       	    vm.closeCancel();
       	    $scope.enableDeleteButton();
       	};
		

           
		 /* Method Description 	: This method triggers auto save form */

	       $scope.$watch('neighbourhoodCanvasAutoSave', function(newValue, oldValue) {
				if(newValue != undefined && newValue != oldValue){									
						if($scope.isOwner && newValue){
							if($scope.formVersion && !$scope.isreadonly){
								$scope.saveNeighbourhoodCanvasFormDetails();
								$rootScope.neighbourhoodCanvasAutoSave = false;
							} else{
								$rootScope.neighbourhoodCanvasAutoSave = false;
							}
						}else{
							$rootScope.neighbourhoodCanvasAutoSave = false;
						}				
				}
			});
	       
	       /**
	   		* To find which accordion is open currently
	   		*/	
			vm.findOpenform = function(){
	        	 if($scope.neighbourhoodCanvasForm.sections.length > 1){
	        		 var j = -1;
	        		 for(var i = 0;i < $scope.neighbourhoodCanvasForm.sections.length;i++){
	            		 if($scope.neighbourhoodCanvasForm.sections[i].expanded == true){
	            			 return ++i;
	            		 }
	            	 } 
	        		 return j;
	        	 }
	        	 return 1;
	         };
	       
	       /**
	        * 
	        */
	       $scope.formLocationRequest = function($event){
	    	   //$scope.addLocationInputSearchObject = inputSearchObject;
		       $scope.openLocationRequest($event,'locationRequestPopupId',1,vm.inputSearchObject,vm.entityLocationObject);
	       };
	       
	       /**
	        * 
	        */
	       $rootScope.requestSuccess = function(response){
	    	   var openForm = vm.findOpenform();openForm--;
			   if(response.isAlreadyExists && !response.isApproved) {
				$scope.neighbourhoodCanvasForm.sections[openForm].questions[0].neighborhood_1.requestAlreadyApplied = true;
			}
	    	   $scope.neighbourhoodCanvasForm.sections[openForm].questions[0].neighborhood_1.requested = true;
	    	   $scope.neighbourhoodCanvasForm.sections[openForm].questions[0].neighborhood_1.answerId = response.id;
	       };

	     //It will get triggered while exiting from current state
	       $scope.$on('$destroy', function() {
	    	   if(!$scope.isreadonly && $scope.isOwner) {
	    		   $scope.saveNeighbourhoodCanvasFormDetails();
	    	   }
	    	   $rootScope.warningPopup.show = false;
	    	   $scope.uploadedFile = false;
	        });
	       
	       //Check the location request is approved or not?
	       vm.checkLocationRequest = function(question) {
	    	   var openForm = vm.findOpenform();openForm--;
	    	   var data = InvestigationFormService.getRequestProfileById(question.neighborhood_1.answerId);
	    	   data.then(function(response) {
	    		   var locationObj = response;
	    		   var currentForm = vm.findOpenform();currentForm--;
	    		   if(currentForm == openForm && locationObj.isApproved) {
	    			   question.neighborhood_1a.answer = locationObj.countryTxt;
	    			   question.neighborhood_1a.subAnswer = locationObj.countryId;
	    			   question.neighborhood_1b.answer = locationObj.provinceTxt;
	    			   question.neighborhood_1b.subAnswer = locationObj.provinceId;
	    			   question.neighborhood_1c.answer = locationObj.cityTxt;
	    			   question.neighborhood_1c.subAnswer = locationObj.cityId;
	    			   question.neighborhood_1d.answer = locationObj.sectorTxt;
	    			   question.neighborhood_1d.subAnswer = locationObj.sectorId;
	    			   question.neighborhood_1.requested = false;
	    			   question.neighborhood_1.subAnswer = "true";
	    			   $scope.setLocationResponse(openForm);
	    			   //Show the nothification message
	    			   notifications.showSuccess({message: vm.APP.LIBRARY.LOCATION_REQUEST});
	    		   } else {
	    			   question.neighborhood_1.subAnswer = "false"; 
	    		   }
	    	   });
	       }
	       
	       //To set the location object while cancel the request popup 
	       $rootScope.setInputObjectAndModel = function(inputSearchObject,locationObject){
	    	   vm.inputSearchObject = angular.copy(inputSearchObject);
	    	   vm.entityLocationObject = angular.copy(locationObject);
	       };
	       
	       //Set up oldFormObject
	       $scope.setUpOldFormData = function() {
	    	   
	    	   angular.forEach(oldFormData.sections, function(data, key){
	    		   vm.initializeLocations();
	    		   
	    		   /*OldFormObject to Temporary variables
	    		    * 
	    		    */
	    		   if(data.questions[0] && data.questions[0].neighborhood_1){
		      		   if(data.questions[0].neighborhood_1.answer == 'answer_yes'){
		      			   if(data.questions[0].neighborhood_1a != null &&  data.questions[0].neighborhood_1a.subAnswer != null){
		      				 vm.LocationObject.countryObj.countryTxt =  data.questions[0].neighborhood_1a.answer;
		        			 vm.LocationObject.countryObj.countryId =  data.questions[0].neighborhood_1a.subAnswer;
		      			   }else if( data.questions[0].neighborhood_1a != null &&  data.questions[0].neighborhood_1a.answer != null){
		      				 vm.inputSearchObject.isAddOrSaveLocationCountry = true;
		      				 vm.inputSearchObject.countrySearchKey =  data.questions[0].neighborhood_1a.answer;
		      			   }
		      			   if( data.questions[0].neighborhood_1b != null &&  data.questions[0].neighborhood_1b.subAnswer != null){
		      				 vm.LocationObject.provinceObj.provinceTxt =  data.questions[0].neighborhood_1b.answer;
		        			 vm.LocationObject.provinceObj.provinceId =  data.questions[0].neighborhood_1b.subAnswer;
		      			   }else if( data.questions[0].neighborhood_1b != null &&  data.questions[0].neighborhood_1b.answer != null){
		      				 vm.inputSearchObject.isAddOrSaveLocationProvince = true;
		      				 vm.inputSearchObject.provinceSearchKey =  data.questions[0].neighborhood_1b.answer;
		      			   }
		      			   
		      			   if( data.questions[0].neighborhood_1c != null &&  data.questions[0].neighborhood_1c.subAnswer != null){
		      				 vm.LocationObject.cityObj.cityTxt =  data.questions[0].neighborhood_1c.answer;
		       			     vm.LocationObject.cityObj.cityId =  data.questions[0].neighborhood_1c.subAnswer;
		      			   }else if( data.questions[0].neighborhood_1c != null &&  data.questions[0].neighborhood_1c.answer != null){
		      				 vm.inputSearchObject.isAddOrSaveLocationCity = true;
		      				 vm.inputSearchObject.citySearchKey =  data.questions[0].neighborhood_1c.answer;
		      			   }
		      			   
		      			   if( data.questions[0].neighborhood_1d != null &&  data.questions[0].neighborhood_1d.subAnswer != null){
		      				 vm.LocationObject.sectorObj.sectorTxt =  data.questions[0].neighborhood_1d.answer;
		        			 vm.LocationObject.sectorObj.sectorId =  data.questions[0].neighborhood_1d.subAnswer;
		      			   }else if( data.questions[0].neighborhood_1d != null &&  data.questions[0].neighborhood_1d.answer != null){
		      				 vm.inputSearchObject.isAddOrSaveLocationSector = true;
		      				 vm.inputSearchObject.sectorSearchKey =  data.questions[0].neighborhood_1d.answer;
		      			   }
		      			   
		      			   /*
		      			    * Set the organized values to the oldFormObject
		      			    * 
		      			    * */
		      			   
	      				    data.questions[0].neighborhood_1a = {};
	      				    data.questions[0].neighborhood_1b = {};
	      				    data.questions[0].neighborhood_1c = {};
	      				    data.questions[0].neighborhood_1d = {};
	      				    
	      				   
	          			   if(vm.LocationObject.countryObj && !vm.inputSearchObject.isAddOrSaveLocationCountry){
	                			  data.questions[0].neighborhood_1a.answer = vm.LocationObject.countryObj.countryTxt;
	                			  data.questions[0].neighborhood_1a.subAnswer = vm.LocationObject.countryObj.countryId;
	          			   }else if(vm.inputSearchObject.isAddOrSaveLocationCountry){
	          				   	  data.questions[0].neighborhood_1a.answer = vm.inputSearchObject.countrySearchKey;
	                		      data.questions[0].neighborhood_1a.subAnswer = null;
	          			   }else{
	          				      data.questions[0].neighborhood_1a.answer = null;
	                  		      data.questions[0].neighborhood_1a.subAnswer = null;
	          			   }
	          			   
	          			   if(vm.LocationObject.provinceObj && !vm.inputSearchObject.isAddOrSaveLocationProvince){
	                			  data.questions[0].neighborhood_1b.answer = vm.LocationObject.provinceObj.provinceTxt;
	                			  data.questions[0].neighborhood_1b.subAnswer = vm.LocationObject.provinceObj.provinceId;
	  	  	  			   }else if(vm.inputSearchObject.isAddOrSaveLocationProvince){
		  	  	  				  data.questions[0].neighborhood_1b.answer = vm.inputSearchObject.provinceSearchKey;
		  	  	  				  data.questions[0].neighborhood_1b.subAnswer = null;
	  	  	  			   }else{
	  	    				      data.questions[0].neighborhood_1b.answer = null;
	  	            		      data.questions[0].neighborhood_1b.subAnswer = null;
	  	    			   }
	          			   
	          			   if(vm.LocationObject.cityObj && !vm.inputSearchObject.isAddOrSaveLocationCity){
	    	          			  data.questions[0].neighborhood_1c.answer = vm.LocationObject.cityObj.cityTxt;
	                			  data.questions[0].neighborhood_1c.subAnswer = vm.LocationObject.cityObj.cityId;
	  	  	  			   }else if(vm.inputSearchObject.isAddOrSaveLocationCity){
		  	  	  				  data.questions[0].neighborhood_1c.answer = vm.inputSearchObject.citySearchKey;
		  	  	  				  data.questions[0].neighborhood_1c.subAnswer = null;
	  	  	  			   }else{
	  	    				      data.questions[0].neighborhood_1c.answer = null;
	  	            		      data.questions[0].neighborhood_1c.subAnswer = null;
	  	    			   }
	          			 
	          			   if(vm.LocationObject.sectorObj && !vm.inputSearchObject.isAddOrSaveLocationSector){
	    	         			  data.questions[0].neighborhood_1d.answer = vm.LocationObject.sectorObj.sectorTxt;
	                			  data.questions[0].neighborhood_1d.subAnswer = vm.LocationObject.sectorObj.sectorId;
	  	  	 			   }else if(vm.inputSearchObject.isAddOrSaveLocationSector){
		  	  	 				  data.questions[0].neighborhood_1d.answer = vm.inputSearchObject.sectorSearchKey;
		  	  	 				  data.questions[0].neighborhood_1d.subAnswer = null;
	  	  	 			   }else{
	  	    				      data.questions[0].neighborhood_1d.answer = null;
	  	            		      data.questions[0].neighborhood_1d.subAnswer = null;
	  	    			   }
	      			   	}
	      		   	}
	    	   	});
	       }
	}
        
})();