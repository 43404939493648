angular
	.module('lariAidApp').directive("dpBlurFormat",['$filter',function($filter){
	   return {
		    	restrict:"A",
		    	require: "ngModel", 
		    	link: function(scope, element, attributes, ngModel){
			    	$(element).find('input').on('blur', function(){
					   var viewValue = ngModel.$viewValue;
					    	if(viewValue){
					    	var updatedViewValue = $filter('date')(viewValue,'MMM dd, yyyy');
					    	//ngModel.$setViewValue(updatedViewValue);
					    	$(this).val(updatedViewValue);
				    	}
			    	});
			   }
	   }
}]);