(function () {
  "use strict";

  angular.module("lariAidApp").factory("ProfileService", ProfileService);

  ProfileService.$inject = ["$http", "$localStorage", "BASE_URL"];

  function ProfileService($http, $localStorage, BASE_URL) {
    var dataPromise;

    var service = {
      getProfileInfo: getProfileInfo
    };

    return service;

    function getProfileInfo() {
      if (angular.isUndefined(dataPromise)) {
        var url = BASE_URL + "api/profile-info";
        dataPromise = $http.get(url).then(function (result) {
          if (result.data.activeProfiles) {
            var response = {};
            response.activeProfiles = result.data.activeProfiles;
            response.ribbonEnv = result.data.ribbonEnv;
            response.inProduction = result.data.activeProfiles.indexOf("prod") !== -1;
            response.swaggerEnabled = result.data.activeProfiles.indexOf("swagger") !== -1;

            //Reload page if the appversion miss match
            var currentVersion = $localStorage.currentVersion;
            var appVersion = $localStorage.appVersion;
            if (currentVersion && appVersion != currentVersion) {
              $localStorage.appVersion = $localStorage.currentVersion;
              setTimeout(function () {
                location.reload(true);
                window.location.href = window.location.href;
              });
            }

            return response;
          }
        });
      }
      return dataPromise;
    }
  }
})();
