/**
 * @ngdoc service
 * @name restService
 * @description Service in the LARI-AID.
 */
(function () {
  "use strict";

  angular.module("lariAidApp").factory("restService", [
    "$resource",
    "BASE_URL",
    function ($resource, BASE_URL) {
      return new ajaxCall($resource, BASE_URL);
    }
  ]);

  function ajaxCall(resource, BASE_URL) {
    this.resource = resource;
    this.restCall = function (data, url1, method) {
      var timestamp = new Date().getTime();
      if (url1.indexOf("?") != -1) {
        url1 = url1 + "&timestamp=" + timestamp;
      } else if (url1.indexOf("?") == -1) {
        url1 = url1 + "?timestamp=" + timestamp;
      }
      var url = BASE_URL + "api/" + url1;

      if (method == "GET") {
        var getDetail = resource(url);
        return getDetail.get();
      } else if (method == "GETLIST") {
        var getDetails = resource(url);
        return getDetails.query();
      } else if (method == "POST") {
        var saveDetail = resource(url);
        var promise = saveDetail.save(data);
        return promise;
      } else if (method == "PUT") {
        var updateDetail = resource(url, null, { update: { method: "PUT" } });
        var promise = updateDetail.update(data);
        return promise;
      } else if (method == "DELETE") {
        var deleteDetail = resource(url, null, { delete_method: { method: "DELETE" } });
        var promise = deleteDetail.delete_method();
        return promise;
      }
    };
  }
})();
