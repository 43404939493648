(function() {
    'use strict';

    angular
        .module('lariAidApp')
        .service('BirthMedicalService', BirthMedicalService);

    BirthMedicalService.$inject = ['$resource','restService','$q','$rootScope'];
    
    function BirthMedicalService ($resource,restService,$q,$rootScope) {
    	
    	
    }
})();