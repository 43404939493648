(function() {
    'use strict';

    angular
        .module('lariAidApp')
        .service('SchoolInformationService', SchoolInformationService);

    SchoolInformationService.$inject = ['$resource','restService','$q','$rootScope'];
    
    function SchoolInformationService ($resource,restService,$q,$rootScope) {
    	
    	
    		
    }
})();