(function () {
  "use strict";

  angular.module("lariAidApp").factory("stateHandler", stateHandler);

  stateHandler.$inject = [
    "$rootScope",
    "$translate",
    "JhiLanguageService",
    "translationHandler",
    "$window",
    "VERSION",
    "$translatePartialLoader",
    "$localStorage"
  ];

  function stateHandler(
    $rootScope,
    $translate,
    JhiLanguageService,
    translationHandler,
    $window,
    VERSION,
    $translatePartialLoader,
    $localStorage
  ) {
    function initialize() {
      $rootScope.VERSION = VERSION;

      var stateChangeStart = $rootScope.$on("$stateChangeStart", function (event, toState, toStateParams, fromState) {
        $rootScope.unauthorized = {};
        $rootScope.unauthorized.isUnauthorized = false;
        $rootScope.toState = toState;
        $rootScope.toStateParams = toStateParams;
        $rootScope.fromState = fromState;

        //Reload page if the appversion miss match
        var currentVersion = $localStorage.lari_currentVersion;
        var appVersion = $localStorage.lari_appVersion;
        if (currentVersion && appVersion != currentVersion) {
          $localStorage.lari_appVersion = $localStorage.lari_currentVersion;
          setTimeout(function () {
            location.reload(true);
            window.location.href = window.location.href;
          });
        }
        // Redirect to a state with an external URL (http://stackoverflow.com/a/30221248/1098564)
        if (toState.external) {
          event.preventDefault();
          $window.open(toState.url, "_self");
        }

        if (localStorage && localStorage.getItem("lari_currentVersion") != null) {
          $translatePartialLoader.addPart("login.json?version=" + localStorage.getItem("lari_currentVersion"));
        } else {
          $translatePartialLoader.addPart("login.json?timestamp=" + new Date().getTime());
        }

        // Update the language
        JhiLanguageService.getCurrent().then(function (language) {
          $translate.use(language);
        });
      });

      var stateChangeSuccess = $rootScope.$on(
        "$stateChangeSuccess",
        function (event, toState, toParams, fromState, fromParams) {
          var titleKey = "global.title";
          $rootScope.fromStateParams = fromParams;
          // Set the page title key to the one configured in state or use default one
          if (toState.data.pageTitle) {
            titleKey = toState.data.pageTitle;
          }
          translationHandler.updateTitle(titleKey);
        }
      );

      $rootScope.$on("$destroy", function () {
        if (angular.isDefined(stateChangeStart) && stateChangeStart !== null) {
          stateChangeStart();
        }
        if (angular.isDefined(stateChangeSuccess) && stateChangeSuccess !== null) {
          stateChangeSuccess();
        }
      });
    }

    return { initialize };
  }
})();
