(function(){
	'use strict'; 

angular
  .module('lariAidApp')
  .factory('communicationService', function () {
	var details = [];
	return {
	    setData: function (key, data) {
	     details[key] = data;
	    },
	    getData: function (key) {
	        return details[key];
	    }
	}
	});
})();