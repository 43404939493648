(function () {
  "use strict";

  angular.module("lariAidApp").controller("LibraryAddRequestController", LibraryAddRequestController);

  LibraryAddRequestController.$inject = [
    "$scope",
    "$state",
    "$stateParams",
    "$mdSidenav",
    "APP",
    "tabservice",
    "$mdMedia",
    "$sessionStorage",
    "$cookies",
    "LibraryService",
    "$mdDialog",
    "CommonService",
    "LibraryEntityService",
    "$q",
    "communicationService",
    "LibraryLocationService",
    "$timeout",
    "StorageDelete",
    "notifications",
    "$rootScope",
    "InvestigationFormService"
  ];

  function LibraryAddRequestController(
    $scope,
    $state,
    $stateParams,
    $mdSidenav,
    APP,
    tabservice,
    $mdMedia,
    $sessionStorage,
    $cookies,
    LibraryService,
    $mdDialog,
    CommonService,
    LibraryEntityService,
    $q,
    communicationService,
    LibraryLocationService,
    $timeout,
    StorageDelete,
    notifications,
    $rootScope,
    InvestigationFormService
  ) {
    var vm = this;
    $scope.APP = APP;
    $scope.endless = $scope.APP.GLOBAL.ENDLESS;
    $scope.library = $stateParams;
    $scope.media = $mdMedia;
    $scope.requestFlag = false;
    $scope.location = {};
    $scope.isHide = false;
    $scope.entityOrLocation = false;
    $scope.disableSaveButton = false;

    $scope.entityLocationObject = {};
    $scope.entityLocationObject.countryObj = {};
    $scope.entityLocationObject.provinceObj = {};
    $scope.entityLocationObject.cityObj = {};
    $scope.entityLocationObject.sectorObj = {};

    $scope.isHorizon = true;
    //Get user from cookie storage
    $scope.user = CommonService.getCookieUser();
    $scope.loggedUserRole = $scope.user.loggedUserRole;
    $scope.library.libraryType = $state.current.name;

    $scope.isHorizon = false;
    $scope.saveOrAddLocationObject = {};
    $scope.saveOrAddLocationObject.countryObj = {};
    $scope.saveOrAddLocationObject.provinceObj = {};
    $scope.saveOrAddLocationObject.cityObj = {};
    $scope.saveOrAddLocationObject.sectorObj = {};
    $scope.isAddOrSaveLocation = false;
    communicationService.setData("isAddOrSaveLocation", $scope.isAddOrSaveLocation);

    $scope.addLocationObject = {};
    $scope.addLocationObject.countryObj = {};
    $scope.addLocationObject.provinceObj = {};
    $scope.addLocationObject.cityObj = {};
    $scope.addLocationObject.sectorObj = {};

    $scope.addLocationInputSearchObject = {};

    $scope.addLocationInputObject = {};
    $scope.addLocationInputObject.country = false;
    $scope.addLocationInputObject.province = false;
    $scope.addLocationInputObject.city = false;
    $scope.addLocationInputObject.sector = false;

    $scope.libraryAddEntityInputSearchObject = {};
    $scope.libraryAddEntityInputSearchObject.country = "";
    $scope.libraryAddEntityInputSearchObject.province = "";
    $scope.libraryAddEntityInputSearchObject.city = "";
    $scope.libraryAddEntityInputSearchObject.countrySearchKey = "";
    $scope.libraryAddEntityInputSearchObject.provinceSearchKey = "";
    $scope.libraryAddEntityInputSearchObject.citySearchKey = "";
    $scope.libraryAddEntityInputSearchObject.sectorSearchKey = "";
    $scope.libraryAddEntityInputSearchObject.isAddOrSaveLocationCountry = false;
    $scope.libraryAddEntityInputSearchObject.isAddOrSaveLocationProvince = false;
    $scope.libraryAddEntityInputSearchObject.isAddOrSaveLocationCity = false;
    $scope.libraryAddEntityInputSearchObject.isAddOrSaveLocationSector = false;

    $scope.disableButton = {};
    $scope.disableButton.isDisabled = false;
    $scope.country = "";

    $scope.cancel = function (formName) {
      $mdDialog.hide();
    };

    //ADD NEW ENTITY POPUP
    $scope.openNewEntity = function (ev, id, flag) {
      //$scope.entity = {}; //Clear Form fields 
      $scope.libraryAddEntityInputSearchObject = {};
      $scope.entityLocationObject = {};
      $scope.entityLocationObject.countryObj = {};
      $scope.entityLocationObject.provinceObj = {};
      $scope.entityLocationObject.cityObj = {};
      $scope.entityLocationObject.sectorObj = {};
      $scope.addNewEntityForm = {};
      $scope.modelObject = {};
      $scope.inputsearchObject = {};
      $scope.editFlag = false;
      $scope.isHide = false;
      $scope.updateFlag = false;
      $scope.existFlag = false;
      $scope.entity.inputSearchText = '';
      $scope.flag = flag;
      if ($scope.entityObj.searchKey != null) {
        $scope.setEntityName($scope.entityObj.searchKey);
      } else {
        $scope.setEntityResponseName();
      }

      $mdDialog.show({
        targetEvent: ev,
        contentElement: '#' + id,
        parent: angular.element(document.body)
      });
    };

    $scope.cancel = function (addNewEntityForm) {
      addNewEntityForm.$setPristine();
      $scope.entity = {}; //Clear Form fields 
      $scope.entity.contactEmail = '';
      $scope.entity.contactPerson = '';
      $scope.addNewEntityForm = {};
      $mdDialog.hide();
    };

    //ADD ENTITY REQUEST
    $scope.saveNewEntity = function (entity, addNewEntityForm, locationObject, libraryAddEntityInputSearchObject) {
      if (addNewEntityForm.$valid && entity) {
        $scope.entityObj = {};
        $scope.entityObj.comments = entity.comments;
        $scope.entityObj.contactEmail = entity.contactEmail;
        $scope.entityObj.contactPersonName = entity.contactPersonName;
        $scope.entityObj.contactPhoneNumber = entity.contactPhoneNumber;
        $scope.entityObj.entityTypeId = entity.entityTypeSearchKey.id;
        $scope.entityType = entity.entityTypeSearchKey.key;
        if (entity.flag == undefined || entity.flag == "") {
          entity.flag = false;
        }
        $scope.entityObj.flag = entity.flag;
        $scope.entityObj.name = $scope.entity.entityName;
        $scope.entityObj.isForm = true;
        if (locationObject.cityObj.locationMappingId == undefined || locationObject.provinceObj.locationMappingId == undefined || locationObject.countryObj.locationMappingId == undefined) {
          $scope.entityOrLocation = true;
          $scope.saveLocation(locationObject, libraryAddEntityInputSearchObject, $scope.entityObj, entity, addNewEntityForm, $scope.entityOrLocation);
        }
        else {
          $scope.entityObj.locationMappingId = locationObject.cityObj.locationMappingId;
          var data = LibraryService.saveNewEntity($scope.entityObj);
          data.then(function (response) {
            if ($scope.entityType == $scope.APP.INVESTIGATION.MEDICAL_INSTITUTION) {
              $rootScope.birthMedicalEntityRequestsent(entity, locationObject, response);
            }
            if ($scope.entityType == $scope.APP.INVESTIGATION.LOCAL_REGISTRY || $scope.entityType == $scope.APP.INVESTIGATION.NATIONAL_REGISTRY) {
              $rootScope.birthCertificateEntityRequestsent(entity, locationObject, response);
            }
            if ($scope.entityType == $scope.APP.INVESTIGATION.RELIGIOUS_INSTITUTION) {
              $rootScope.residencyEntityRequestsent(entity, locationObject, response);
            }
            if ($scope.entityType == $scope.APP.INVESTIGATION.SCHOOL) {
              $rootScope.schoolEntityRequestsent(entity, locationObject, response);
            }
            if ($scope.entityType == $scope.APP.INVESTIGATION.DNA_CENTER) {
              $rootScope.dnaEntityRequestsent(entity, locationObject, response);
            }
            if (response.entityExists) {
              notifications.showSuccess({ message: $scope.APP.LIBRARY.ENTITY_REQUEST_EXISTS });
            }
            else {
              notifications.showSuccess({ message: $scope.APP.LIBRARY.ENTITY_REQUEST_SEND });
            }
          }, function (reason) {
            notifications.showError({ message: $scope.APP.LIBRARY.ENTITY_REQUEST_SEND_PROBLEM });
          });
          $scope.cancel(addNewEntityForm);
        }
      }
    };

    $scope.locRegTypeValid = false;

    //ADD LOCATION REQUEST POPUP
    $scope.openLocationRequest = function (ev, id, flag, inputSearchObject, locationObject) {
      $scope.flag = flag;
      $scope.formInputSearchObject = angular.copy(inputSearchObject);
      $scope.formLocationObject = angular.copy(locationObject);
      $scope.addLocationInputSearchObject = inputSearchObject;
      $scope.saveOrAddLocationObject = locationObject;
      $scope.isHorizon = true;
      $scope.disableButton.isDisabled = false;
      $mdDialog.show({
        targetEvent: ev,
        contentElement: '#' + id,
        parent: angular.element(document.body)
      });
    };

    $scope.isValidEnable = false;
    $scope.addLocationInputSearchObject.isInputEmptyCountry = false;
    $scope.addLocationInputSearchObject.isInputEmptyProvince = false;
    $scope.addLocationInputSearchObject.isInputEmptyCity = false;
    $scope.addLocationInputSearchObject.isInputEmptySector = false;

    $scope.checkMandatoryFields = function (type) {
      if ($scope.isAddOrSaveLocation) {
        if (type == $scope.APP.LIBRARY.COUNTRY_CAPS) {
          if ($scope.saveOrAddLocationObject.countryObj && $scope.saveOrAddLocationObject.countryObj.countryId != null && $scope.saveOrAddLocationObject.countryObj.countryId != '') {
            if (($scope.addLocationInputSearchObject.provinceSearchKey != null && $scope.addLocationInputSearchObject.provinceSearchKey != '')
              || ($scope.addLocationInputSearchObject.citySearchKey != null && $scope.addLocationInputSearchObject.citySearchKey != '')
              || ($scope.addLocationInputSearchObject.sectorSearchKey != null && $scope.addLocationInputSearchObject.sectorSearchKey != '')) {
              $scope.isValidEnable = true;
            } else {
              $scope.isValidEnable = false;
            }
          } else if ($scope.addLocationInputSearchObject.countrySearchKey != null && $scope.addLocationInputSearchObject.countrySearchKey != '') {
            $scope.isValidEnable = true;
          } else {
            $scope.isValidEnable = false;
          }
        } else if (type == $scope.APP.LIBRARY.PROVINCE_CAPS) {
          if ($scope.saveOrAddLocationObject.countryObj && $scope.saveOrAddLocationObject.countryObj.countryId != null && $scope.saveOrAddLocationObject.countryObj.countryId != '') {
            if ($scope.saveOrAddLocationObject.provinceObj && $scope.saveOrAddLocationObject.provinceObj.provinceId != null && $scope.saveOrAddLocationObject.provinceObj.provinceId != '') {
              if (($scope.addLocationInputSearchObject.citySearchKey != null && $scope.addLocationInputSearchObject.citySearchKey != '')
                || ($scope.addLocationInputSearchObject.sectorSearchKey != null && $scope.addLocationInputSearchObject.sectorSearchKey != '')) {
                $scope.isValidEnable = true;
              } else {
                $scope.isValidEnable = false;
              }
            } else {
              if ($scope.addLocationInputSearchObject.provinceSearchKey != null && $scope.addLocationInputSearchObject.provinceSearchKey != '') {
                $scope.isValidEnable = true;
              }
            }
            $scope.addLocationInputSearchObject.isInputEmptyCountry = false;
          } else if ($scope.addLocationInputSearchObject.countrySearchKey != null && $scope.addLocationInputSearchObject.countrySearchKey != '') {
            if ($scope.addLocationInputSearchObject.provinceSearchKey != null && $scope.addLocationInputSearchObject.provinceSearchKey != '') {
              $scope.isValidEnable = true;
            }
            $scope.addLocationInputSearchObject.isInputEmptyCountry = false;
          } else {
            $scope.addLocationInputSearchObject.isInputEmptyCountry = true;
            $scope.isValidEnable = false;
          }
        } else if (type == $scope.APP.LIBRARY.CITY_CAPS) {
          if (($scope.saveOrAddLocationObject.countryObj && $scope.saveOrAddLocationObject.countryObj.countryId != null && $scope.saveOrAddLocationObject.countryObj.countryId != '')
            && ($scope.saveOrAddLocationObject.provinceObj && $scope.saveOrAddLocationObject.provinceObj.provinceId != null && $scope.saveOrAddLocationObject.provinceObj.provinceId != '')) {
            if (($scope.saveOrAddLocationObject.cityObj && $scope.saveOrAddLocationObject.cityObj.cityId != null && $scope.saveOrAddLocationObject.cityObj.cityId != '')) {
              if (($scope.addLocationInputSearchObject.sectorSearchKey != null && $scope.addLocationInputSearchObject.sectorSearchKey != '')) {
                $scope.isValidEnable = true;
              } else {
                $scope.isValidEnable = false;
              }
            } else {
              if ($scope.addLocationInputSearchObject.citySearchKey != null && $scope.addLocationInputSearchObject.citySearchKey != '') {
                $scope.isValidEnable = true;
              }
            }
            $scope.addLocationInputSearchObject.isInputEmptyCountry = false;
            $scope.addLocationInputSearchObject.isInputEmptyProvince = false;
          } else if ($scope.saveOrAddLocationObject.countryObj && $scope.saveOrAddLocationObject.countryObj.countryId != null && $scope.saveOrAddLocationObject.countryObj.countryId != '') {
            if (($scope.addLocationInputSearchObject.provinceSearchKey != null && $scope.addLocationInputSearchObject.provinceSearchKey != '')
              && ($scope.addLocationInputSearchObject.citySearchKey != null && $scope.addLocationInputSearchObject.citySearchKey != '')) {
              $scope.isValidEnable = true;
            } else {
              $scope.isValidEnable = false;
            }
            $scope.addLocationInputSearchObject.isInputEmptyCountry = false;
            $scope.addLocationInputSearchObject.isInputEmptyProvince = false;
          } else if (($scope.addLocationInputSearchObject.countrySearchKey != null && $scope.addLocationInputSearchObject.countrySearchKey != '')
            && ($scope.addLocationInputSearchObject.provinceSearchKey != null && $scope.addLocationInputSearchObject.provinceSearchKey != '')
            && ($scope.addLocationInputSearchObject.citySearchKey != null && $scope.addLocationInputSearchObject.citySearchKey != '')) {
            $scope.isValidEnable = true;
            $scope.addLocationInputSearchObject.isInputEmptyCountry = false;
            $scope.addLocationInputSearchObject.isInputEmptyProvince = false;
          } else {
            $scope.addLocationInputSearchObject.isInputEmptyCountry = true;
            $scope.addLocationInputSearchObject.isInputEmptyProvince = true;
            $scope.isValidEnable = false;
          }
        } else if (type == $scope.APP.LIBRARY.SECTOR_CAPS) {
          if (($scope.saveOrAddLocationObject.countryObj && $scope.saveOrAddLocationObject.countryObj.countryId != null && $scope.saveOrAddLocationObject.countryObj.countryId != '')
            && ($scope.saveOrAddLocationObject.provinceObj && $scope.saveOrAddLocationObject.provinceObj.provinceId != null && $scope.saveOrAddLocationObject.provinceObj.provinceId != '')
            && ($scope.saveOrAddLocationObject.cityObj && $scope.saveOrAddLocationObject.cityObj.cityId != null && $scope.saveOrAddLocationObject.cityObj.cityId != '')) {
            if (($scope.saveOrAddLocationObject.sectorObj && $scope.saveOrAddLocationObject.sectorObj.sectorId != null && $scope.saveOrAddLocationObject.sectorObj.sectorId != '')) {
              $scope.isValidEnable = false;
            } else {
              if ($scope.addLocationInputSearchObject.sectorSearchKey != null && $scope.addLocationInputSearchObject.sectorSearchKey != '') {
                $scope.isValidEnable = true;
              }
            }
            $scope.addLocationInputSearchObject.isInputEmptyCountry = false;
            $scope.addLocationInputSearchObject.isInputEmptyProvince = false;
            $scope.addLocationInputSearchObject.isInputEmptyCity = false;
          } else if (($scope.saveOrAddLocationObject.countryObj && $scope.saveOrAddLocationObject.countryObj.countryId != null && $scope.saveOrAddLocationObject.countryObj.countryId != '')
            && ($scope.saveOrAddLocationObject.provinceObj && $scope.saveOrAddLocationObject.provinceObj.provinceId != null && $scope.saveOrAddLocationObject.provinceObj.provinceId != '')) {
            if (($scope.addLocationInputSearchObject.citySearchKey != null && $scope.addLocationInputSearchObject.citySearchKey != '') && ($scope.addLocationInputSearchObject.sectorSearchKey != null && $scope.addLocationInputSearchObject.sectorSearchKey != '')) {
              $scope.isValidEnable = true;
            }
            $scope.addLocationInputSearchObject.isInputEmptyCountry = false;
            $scope.addLocationInputSearchObject.isInputEmptyProvince = false;
            $scope.addLocationInputSearchObject.isInputEmptyCity = false;
          } else if ($scope.saveOrAddLocationObject.countryObj && $scope.saveOrAddLocationObject.countryObj.countryId != null && $scope.saveOrAddLocationObject.countryObj.countryId != '') {
            if (($scope.addLocationInputSearchObject.provinceSearchKey != null && $scope.addLocationInputSearchObject.provinceSearchKey != '') && ($scope.addLocationInputSearchObject.citySearchKey != null && $scope.addLocationInputSearchObject.citySearchKey != '')
              && ($scope.addLocationInputSearchObject.sectorSearchKey != null && $scope.addLocationInputSearchObject.sectorSearchKey != '')) {
              $scope.isValidEnable = true;
            }
            $scope.addLocationInputSearchObject.isInputEmptyCountry = false;
            $scope.addLocationInputSearchObject.isInputEmptyProvince = false;
            $scope.addLocationInputSearchObject.isInputEmptyCity = false;
          } else if (($scope.addLocationInputSearchObject.countrySearchKey != null && $scope.addLocationInputSearchObject.countrySearchKey != '') && ($scope.addLocationInputSearchObject.provinceSearchKey != null && $scope.addLocationInputSearchObject.provinceSearchKey != '')
            && ($scope.addLocationInputSearchObject.citySearchKey != null && $scope.addLocationInputSearchObject.citySearchKey != '') && ($scope.addLocationInputSearchObject.sectorSearchKey != null && $scope.addLocationInputSearchObject.sectorSearchKey != '')) {
            $scope.isValidEnable = true;
            $scope.addLocationInputSearchObject.isInputEmptyCountry = false;
            $scope.addLocationInputSearchObject.isInputEmptyProvince = false;
            $scope.addLocationInputSearchObject.isInputEmptyCity = false;
          } else {
            $scope.addLocationInputSearchObject.isInputEmptyCountry = true;
            $scope.addLocationInputSearchObject.isInputEmptyProvince = true;
            $scope.addLocationInputSearchObject.isInputEmptyCity = true;
            $scope.isValidEnable = false;
          }
        }
      }
    }

    $scope.hidePopup = function () {
      $mdDialog.hide();
    }

    $scope.openSuccessDialog = function (id, event) {
      $mdDialog.show({
        targetEvent: event,
        contentElement: '#' + id,
        parent: angular.element(document.body)
      });
    }

    $scope.cancelLocation = function (addlocationRequestForm, flag) {
      if (flag == 1) {
        $scope.isAddOrSaveLocation = false;
        communicationService.setData('isAddOrSaveLocation', $scope.isAddOrSaveLocation);
        $scope.addLocationInputSearchObject.isInputEmptyCountry = false;
        communicationService.setData('isInputEmptyCountry', $scope.addLocationInputSearchObject.isInputEmptyCountry);
        $scope.addLocationInputSearchObject.isInputEmptyProvince = false;
        communicationService.setData('isInputEmptyProvince', $scope.addLocationInputSearchObject.isInputEmptyProvince);
        $scope.addLocationInputSearchObject.isInputEmptyCity = false;
        communicationService.setData('isInputEmptyCity', $scope.addLocationInputSearchObject.isInputEmptyCity);
        $scope.addLocationInputSearchObject.isInputEmptySector = false;
        communicationService.setData('isInputEmptySector', $scope.addLocationInputSearchObject.isInputEmptySector);
      }
      $scope.isHorizon = false;
      addlocationRequestForm.$setPristine();
      $scope.addLocationInputSearchObject = {};
      $scope.locationRequestType = {};
      $scope.saveOrAddLocationObject = {};
      $scope.saveOrAddLocationObject.countryObj = {};
      $scope.saveOrAddLocationObject.provinceObj = {};
      $scope.saveOrAddLocationObject.cityObj = {};
      $scope.saveOrAddLocationObject.sectorObj = {};
      //To set the form location object in neighborhood canvass form
      $rootScope.setInputObjectAndModel($scope.formInputSearchObject, $scope.formLocationObject);
      $mdDialog.hide();
    };

    $scope.clearOnClose = function (searchKey, dropValue) {
      $scope[searchKey] = undefined;
      $scope[dropValue] = undefined;
      $scope.entityNameLoader = false;
    }

    $scope.setEntityResponseName = function () {
      var sectionKey = $scope.currentSection;
      var formId = $scope.investigationFormId;
      var data = InvestigationFormService.getInvestigationFormBySectionId(sectionKey, formId);
      data.then(function (response) {
        if (response != null) {
          if ($scope.currentState == "bithcerticateForm") {
            $scope.setEntityName(response.formDTO.sections[0].questions[0].birthCertificate_1a.answer);
          } else if ($scope.currentState == "birthAndMedicalForm") {
            $scope.setEntityName(response.formDTO.sections[0].questions[0].birthMedical1_1_medical_institution_1.answer);
          } else if ($scope.currentState == "schoolInfoForm") {
            $scope.setEntityName(response.formDTO.sections[0].questions[0].schoolInformation_1_label.answer);
          } else if ($scope.currentState == "religiousInstitutionForm") {
            $scope.setEntityName(response.formDTO.sections[0].questions[0].religiousInstitution_q1_yes_nameofInstitution.answer);
          } else if ($scope.currentState == "dnaInfoForm") {
            $scope.setEntityName(response.formDTO.sections[0].questions[0].dnaInformation_1a_2.answer);
          }
        }
      });
    }

    $scope.setEntityName = function (entityName) {
      if (entityName != null) {
        if ($scope.currentState == "bithcerticateForm" || $scope.currentState == "birthAndMedicalForm" || $scope.currentState == "schoolInfoForm" || $scope.currentState == "religiousInstitutionForm" || $scope.currentState == "dnaInfoForm") {
          $scope.isHide = true;
          $scope.entity.entityName = entityName;
        }
      }
    }

    $scope.searchEntity = function (searchKey, entityTypeId) {
      $scope.entityNameLoader = true;
      if (searchKey && searchKey.length >= 2) {
        var deferred = $q.defer();
        $scope.entityNameList = [];

        var data = LibraryService.getEntityNamesList(searchKey, entityTypeId);
        data.then(function (response) {
          $scope.entityNameLoader = false;
          if (response.length > 0) {
            $scope.isHide = true;
            $scope.entityNameList = response;
            $scope.entity.entityName = searchKey;
          } else {
            $scope.clearEntity();
            $scope.entity.entityName = searchKey;
            $scope.entity.inputSearchText = searchKey;
            $scope.isHide = true;
          }
          deferred.resolve($scope.entityNameList);
        });
        return deferred.promise;
      }
    };

    $scope.getLocationMappingDataById = function (locationMappingId) {
      var locationData = CommonService.getLocationById(locationMappingId);
      locationData.then(function (response) {
        var locationObj = response;

        $scope.entityLocationObject.countryObj = LibraryService.getCountryObj(locationObj);
        $scope.entityLocationObject.provinceObj = LibraryService.getProvinceObj(locationObj);
        $scope.entityLocationObject.cityObj = LibraryService.getCityObj(locationObj);
        $scope.entityLocationObject.sectorObj = LibraryService.getSectorObj(locationObj);
        //Set Location mapping Id edit entity
        $scope.entityLocationObject.countryObj.locationMappingId = locationObj.id;
        $scope.entityLocationObject.provinceObj.locationMappingId = locationObj.id;
        $scope.entityLocationObject.cityObj.locationMappingId = locationObj.id;
        $scope.entityLocationObject.sectorObj.locationMappingId = locationObj.id;

      });
    };

    $scope.getEntityProfileById = function (entityId) {
      if (entityId) {
        var entityProfile = {};
        var data = LibraryService.getEntityProfileById(entityId);
        data.then(function (response) {
          entityProfile = response;
          $scope.entity.contactPersonName = entityProfile.contactPersonName;
          $scope.entity.contactPhoneNumber = entityProfile.contactPhoneNumber;
          $scope.entity.contactEmail = entityProfile.contactEmail;
          $scope.entity.flag = entityProfile.flag;
          $scope.entity.comments = entityProfile.comments;
          $scope.getLocationMappingDataById(entityProfile.locationMappingId);
        });
      }
    };

    $scope.clearEntity = function () {
      var copyEntityType = $scope.entity.entityTypeSearchKey;
      $scope.entity = {};
      $scope.entity.entityName = '';
      //$scope.inputSearchText = '-----';
      $scope.entityNameList = [];
      $scope.libraryAddEntityInputSearchObject = {};
      $scope.entityLocationObject = {};
      $scope.entityLocationObject.countryObj = {};
      $scope.entityLocationObject.provinceObj = {};
      $scope.entityLocationObject.cityObj = {};
      $scope.entityLocationObject.sectorObj = {};
      $scope.entity.entityTypeSearchKey = copyEntityType;
      $scope.isHide = false;
    };

    $scope.getEntityTypeById = function (entityTypeId) {
      angular.forEach($scope.entityTypeList, function (data, key) {
        if (data.id == entityTypeId) {
          $scope.entity.entityTypeSearchKey = data;
        }
      });
    }

    $scope.showEntityProfileById = function (entityId) {
      $scope.entity = {};
      $scope.entityPopupLoader = true;
      var data = LibraryService.getEntityProfileById(entityId);
      data.then(function (response) {
        $scope.entity = response;
        //$scope.entity.entityName = LibraryService.getEntityNameObj($scope.entity);
        $scope.entity.entityName = $scope.entity.name;
        $scope.getLocationMappingDataById($scope.entity.locationMappingId);
        $scope.getEntityTypeById($scope.entity.entityTypeId);
        $scope.entityPopupLoader = false;
      });
    };

    $scope.openEntityDeletePopup = function (ev, id, entityId) {
      $scope.entityId = entityId;
      $mdDialog.show({
        targetEvent: event,
        contentElement: '#' + id,
        parent: angular.element(document.body)
      });
    };

    $scope.deleteEntity = function (entityId) {
      var data = LibraryService.deleteEntity(entityId);
      data.then(function (response) {
        notifications.showSuccess({ message: $scope.APP.LIBRARY.ENTITY_DELETE });
        $state.transitionTo($state.current, $stateParams, {
          reload: true,
          inherit: false,
          notify: true
        });
      }, function (response) {
        notifications.showError({ message: $scope.APP.LIBRARY.ENTITY_DELETE_PROBLEM });
        return reason;
      });
    };

    $scope.changeEditFlag = function () {
      $scope.editFlag = false;
      $scope.updateFlag = true;
      $scope.isHide = true;
    };

    $scope.searchRequestLocation = function (searchText, type) {
      if (searchText && searchText.length >= 2) {
        var deferred = $q.defer();
        $scope.libraryLocObj = {};
        $scope.libraryLocObj.searchKey = searchText;
        if ($scope.addLocationObject) {
          if ($scope.addLocationObject.countryObj && $scope.addLocationObject.countryObj.countryId != null && $scope.addLocationObject.countryObj.countryId != '') {
            $scope.libraryLocObj.countryId = $scope.addLocationObject.countryObj.countryId;
          }
          if ($scope.addLocationObject.provinceObj && $scope.addLocationObject.provinceObj.provinceId != null && $scope.addLocationObject.provinceObj.provinceId != '') {
            $scope.libraryLocObj.provinceId = $scope.addLocationObject.provinceObj.provinceId;
          }
          if ($scope.addLocationObject.cityObj && $scope.addLocationObject.cityObj.cityId != null && $scope.addLocationObject.cityObj.cityId != '') {
            $scope.libraryLocObj.cityId = $scope.addLocationObject.cityObj.cityId;
          }
          if ($scope.addLocationObject.sectorObj && $scope.addLocationObject.sectorObj.sectorId != null && $scope.addLocationObject.sectorObj.sectorId != '') {
            $scope.libraryLocObj.sectorId = $scope.addLocationObject.sectorObj.sectorId;
          }
        }
        $scope.locLoader = true;
        var data = LibraryLocationService.loadAllLocationsByType($scope.libraryLocObj, type);
        data.then(function (response) {
          $scope.locLoader = false;
          if (response.autoSuggestionLocationDTOList) {
            if (type == $scope.APP.LIBRARY.COUNTRY_CAPS) {
              $scope.searchCountryList = response.autoSuggestionLocationDTOList;
            } else if (type == $scope.APP.LIBRARY.PROVINCE_CAPS) {
              $scope.searchProvinceList = response.autoSuggestionLocationDTOList;
            } else if (type == $scope.APP.LIBRARY.CITY_CAPS) {
              $scope.searchCityList = response.autoSuggestionLocationDTOList;
            } else if (type == $scope.APP.LIBRARY.SECTOR_CAPS) {
              $scope.searchSectorList = response.autoSuggestionLocationDTOList;
            }
          }
          if (type == $scope.APP.LIBRARY.COUNTRY_CAPS) {
            deferred.resolve($scope.searchCountryList);
          } else if (type == $scope.APP.LIBRARY.PROVINCE_CAPS) {
            deferred.resolve($scope.searchProvinceList);
          } else if (type == $scope.APP.LIBRARY.CITY_CAPS) {
            deferred.resolve($scope.searchCityList);
          } else if (type == $scope.APP.LIBRARY.SECTOR_CAPS) {
            deferred.resolve($scope.searchSectorList);
          }

        });
        return deferred.promise;
      }
    };


    $scope.setLibraryType = function () {
      $scope.library.libraryType = $state.current.name;
    };

    //Add location Request
    $scope.saveLocation = function (locationObject, addLocationInputSearchObject, entityObj, entity, addNewEntityForm, entityOrLocation) {
      if ((!angular.isUndefinedOrNull(addLocationInputSearchObject.countrySearchKey) && addLocationInputSearchObject.countrySearchKey != "") || (locationObject.countryObj && locationObject.countryObj.countryId)) {

        $scope.locRegTypeValid = false;
        $scope.validationMSGFlagCity = false;
        $scope.validationMSGFlagProvince = false;
        $scope.locationObj = {};
        $scope.isValidLocation = false;
        //$rootScope.loadValid();

        if (locationObject.countryObj && locationObject.countryObj.countryId) {
          $scope.locationObj.countryId = locationObject.countryObj.countryId;
          $scope.locationObj.countryTxt = locationObject.countryObj.countryTxt;
        } else {
          $scope.locationObj.countryTxt = addLocationInputSearchObject.countrySearchKey;
        }

        if (locationObject.provinceObj && locationObject.provinceObj.provinceId) {
          $scope.locationObj.provinceId = locationObject.provinceObj.provinceId;
          $scope.locationObj.provinceTxt = locationObject.provinceObj.provinceTxt;
        } else if (addLocationInputSearchObject.provinceSearchKey != "") {
          $scope.locationObj.provinceTxt = addLocationInputSearchObject.provinceSearchKey;
        } else if (addLocationInputSearchObject.provinceSearchKey == "" || addLocationInputSearchObject.provinceSearchKey == null) {
          $scope.validationMSGFlagProvince = true;
        }

        if (locationObject.cityObj && locationObject.cityObj.cityId) {
          $scope.locationObj.cityId = locationObject.cityObj.cityId;
          $scope.locationObj.cityTxt = locationObject.cityObj.cityTxt;
        } else if (addLocationInputSearchObject.citySearchKey != "") {
          $scope.locationObj.cityTxt = addLocationInputSearchObject.citySearchKey;
          $scope.checkLocationObj('CITY', $scope.locationObj);
        } else if (addLocationInputSearchObject.citySearchKey == "" || addLocationInputSearchObject.citySearchKey == null) {
          $scope.validationMSGFlagCity = true;
        }

        if (locationObject.sectorObj && locationObject.sectorObj.sectorId) {
          $scope.locationObj.sectorId = locationObject.sectorObj.sectorId;
          $scope.locationObj.sectorTxt = locationObject.sectorObj.sectorTxt;
        } else if (addLocationInputSearchObject.sectorSearchKey != "") {
          $scope.locationObj.sectorTxt = addLocationInputSearchObject.sectorSearchKey;
          $scope.checkLocationObj('SECTOR', $scope.locationObj);
        } else if (locationObject.cityObj && locationObject.cityObj.cityId && addLocationInputSearchObject.sector == "" && addLocationInputSearchObject.sectorSearchKey == "") {
          $scope.isValidLocation = true;
        }


        $scope.locationObj.isForm = true;
        if (!$scope.validationMSGFlagProvince) {
          if (!$scope.isValidLocation) {
            if (!$scope.validationMSGFlagCity) {
              $scope.disableSaveButton = true;
              var data = LibraryService.saveNewLocation($scope.locationObj);
              data.then(function (response) {
                $scope.disableSaveButton = false;
                if (response.isApproved && $state.current.name == "neighbourhoodCanvas") {
                  $scope.isValidLocation = true;
                  $timeout(function () {
                    $scope.isValidLocation = false;
                  }, 3000);
                  $scope.disableButton.isDisabled = false;
                } else {
                  $scope.hidePopup();
                  if (entityOrLocation) {
                    $scope.entityObj.locationMappingId = response.id;
                    var data = LibraryService.saveNewEntity($scope.entityObj);
                    data.then(function (response1) {
                      locationObject.countryObj.countryTxt = response.countryTxt;
                      locationObject.cityObj.cityTxt = response.provinceTxt;
                      if ($scope.entityType == $scope.APP.INVESTIGATION.MEDICAL_INSTITUTION) {
                        $rootScope.birthMedicalEntityRequestsent(entity, locationObject, response1);
                      }
                      if ($scope.entityType == $scope.APP.INVESTIGATION.LOCAL_REGISTRY || $scope.entityType == $scope.APP.INVESTIGATION.NATIONAL_REGISTRY) {
                        $rootScope.birthCertificateEntityRequestsent(entity, locationObject, response1);
                      }
                      if ($scope.entityType == $scope.APP.INVESTIGATION.RELIGIOUS_INSTITUTION) {
                        $rootScope.residencyEntityRequestsent(entity, locationObject, response1);
                      }
                      if ($scope.entityType == $scope.APP.INVESTIGATION.SCHOOL) {
                        $rootScope.schoolEntityRequestsent(entity, locationObject, response1);
                      }
                      if ($scope.entityType == $scope.APP.INVESTIGATION.DNA_CENTER) {
                        $rootScope.dnaEntityRequestsent(entity, locationObject, response1);
                      }
                      if (response1.entityExists) {
                        notifications.showSuccess({ message: $scope.APP.LIBRARY.ENTITY_REQUEST_EXISTS });
                      }
                      else {
                        notifications.showSuccess({ message: $scope.APP.LIBRARY.ENTITY_REQUEST_SEND });
                      }
                    }, function (reason) {
                      $scope.disableSaveButton = false;
                      notifications.showError({ message: $scope.APP.LIBRARY.ENTITY_REQUEST_SEND_PROBLEM });
                    });
                    $scope.cancel(addNewEntityForm);
                  }
                  $rootScope.requestSuccess(response);
                  $scope.disableButton.isDisabled = false;

                  if (response.isAlreadyExists && !response.isApproved) {
                    notifications.showSuccess({ message: $scope.APP.LIBRARY.LOCATION_EXISTS });
                  } else {
                    notifications.showSuccess({ message: $scope.APP.LIBRARY.LOCATION_REQUEST_SEND });
                  }
                }

              }, function (reason) {
                $scope.disableButton.isDisabled = false;

                notifications.showError({ message: vm.APP.LIBRARY.LOCATION_REQUEST_SEND_PROBLEM });
              });

            } else {
              addLocationInputSearchObject.isInputEmptyCity = true;
              $timeout(function () {
                addLocationInputSearchObject.isInputEmptyCity = false;
              }, 3000);
            }

          } else {
            $timeout(function () {
              $scope.isValidLocation = false;
            }, 3000);
          }
        } else {
          addLocationInputSearchObject.isInputEmptyProvince = true;
          $scope.validationMSGFlagCity = false;
          $timeout(function () {
            addLocationInputSearchObject.isInputEmptyProvince = false;
          }, 3000);
        }
      }
      else {
        addLocationInputSearchObject.isInputEmptyCountry = true;
        $scope.validationMSGFlagcountry = true;
        $timeout(function () {
          addLocationInputSearchObject.isInputEmptyCountry = false;
        }, 3000);
      }
    };

    $scope.checkLocationObj = function (type, addLocationObj) {
      //Sector Location validation
      if (type == 'SECTOR') {

        if (angular.isUndefinedOrNull(addLocationObj.cityTxt)) {
          $scope.validationMSGFlagCity = true;
        }

        if (angular.isUndefinedOrNull(addLocationObj.provinceTxt)) {
          $scope.validationMSGFlagProvince = true;
        }

      }

      //City Location validation
      if (type == 'CITY') {
        if (angular.isUndefinedOrNull(addLocationObj.provinceTxt)) {
          $scope.validationMSGFlagProvince = true;
        }
      }

    }

    $scope.replaceSelect = function () {
      if ($scope.locationRequestNew.requestTypeName == $scope.APP.LIBRARY.COUNTRY && $scope.locationRequestNew.countryObj == undefined && $scope.requestLocationInputSearchObject.country != null) {
        $scope.locationRequestNew.countryObj = {};
        $scope.requestLocationInputSearchObject.isHideCountry = true;
        $scope.relpaceinputfieldObject.country = true;
        $scope.relpaceinputfieldObject.countryKey = $scope.requestLocationInputSearchObject.country;
        $scope.locationRequestNew.countryObj.countryTxt = $scope.relpaceinputfieldObject.countryKey;
      }

      if ($scope.locationRequestNew.requestTypeName == $scope.APP.LIBRARY.PROVINCE && $scope.locationRequestNew.countryObj && $scope.locationRequestNew.countryObj.countryId != null && $scope.locationRequestNew.provinceObj == undefined && $scope.requestLocationInputSearchObject.province != null) {
        $scope.locationRequestNew.provinceObj = {};
        $scope.requestLocationInputSearchObject.isHideProvince = true;
        $scope.relpaceinputfieldObject.province = true;
        $scope.relpaceinputfieldObject.provinceKey = $scope.requestLocationInputSearchObject.province;
        $scope.locationRequestNew.provinceObj.provinceTxt = $scope.relpaceinputfieldObject.provinceKey;
      }

      if ($scope.locationRequestNew.requestTypeName == $scope.APP.LIBRARY.CITY && $scope.locationRequestNew.countryObj && $scope.locationRequestNew.countryObj.countryId != null && $scope.locationRequestNew.provinceObj && $scope.locationRequestNew.provinceObj.provinceId != null && $scope.locationRequestNew.cityObj == undefined && $scope.requestLocationInputSearchObject.city != null) {
        $scope.locationRequestNew.cityObj = {};
        $scope.requestLocationInputSearchObject.isHideCity = true;
        $scope.relpaceinputfieldObject.city = true;
        $scope.relpaceinputfieldObject.cityKey = $scope.requestLocationInputSearchObject.city;
        $scope.locationRequestNew.cityObj.cityTxt = $scope.relpaceinputfieldObject.cityKey;
      }
      if ($scope.locationRequestNew.requestTypeName == $scope.APP.LIBRARY.SECTOR && $scope.locationRequestNew.countryObj && $scope.locationRequestNew.countryObj.countryId != null
        && $scope.locationRequestNew.provinceObj && $scope.locationRequestNew.provinceObj.provinceId != null
        && $scope.locationRequestNew.cityObj && $scope.locationRequestNew.cityObj.cityId != null
        && ($scope.locationRequestNew.sectorObj.sectorTxt == undefined || $scope.locationRequestNew.sectorObj == null)
        && $scope.requestLocationInputSearchObject.sector != null) {
        $scope.locationRequestNew.sectorObj = {};
        $scope.requestLocationInputSearchObject.isHideSector = true;
        $scope.relpaceinputfieldObject.sector = true;
        $scope.relpaceinputfieldObject.sectorKey = $scope.requestLocationInputSearchObject.sector;
        $scope.locationRequestNew.sectorObj.sectorTxt = $scope.relpaceinputfieldObject.sectorKey;
      }
    }

    angular.isUndefinedOrNull = function (val) {
      return angular.isUndefined(val) || val == ""
    }

    $rootScope.$on('enableAddLocationButton', function () {
			$scope.disableButton.isDisabled = false;
		});

  }

})();

