(function () {
  "use strict";

  angular.module("lariAidApp").factory("Account", Account);

  Account.$inject = [
    "$resource",
    "BASE_URL",
    "$cookies",
    "$sessionStorage",
    "communicationService",
    "$http",
    "$rootScope"
  ];

  function Account($resource, BASE_URL, $cookies, $sessionStorage, communicationService, $http, $rootScope) {
    var service = $resource(
      BASE_URL + "api/account",
      {},
      {
        get: {
          method: "GET",
          params: {},
          isArray: false,
          interceptor: {
            response: function (response) {
              // expose response
              $http({
                method: "GET",
                url: BASE_URL + "api/getEnvironmentDetails"
              }).then(function successCallback(response) {
                $sessionStorage.currentEnvironment = response.data.environment;
                $rootScope.$broadcast("setheader");
              });

              if (response.data.authorities[0] === "LARI_INVESTIGATOR") {
                if (navigator.language.indexOf("es") > -1) {
                  response.data.langKey = "es";
                }
                else {
                  response.data.langKey = "en";
                }
              } else {

                response.data.langKey = "en";
              }
              var sessionDetails = {
                firstName: response.data.firstName,
                lastName: response.data.lastName,
                authorities: response.data.authorities,
                langKey: response.data.langKey,
                login: response.data.login
              };

              $cookies.putObject("sessionDetails", sessionDetails);
              $sessionStorage.isLoggedIn = true;
              communicationService.setData("isLoggedIn", true);

              return response;
            }
          }
        }
      }
    );

    return service;
  }
})();