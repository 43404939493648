(function(){
	var lariDevApp = angular
	.module('lariAidApp')
	lariDevApp.controller('UserActivityController',UserActivityController);
	UserActivityController.$inject = ['$scope','$window', '$mdSidenav','Principal','$state','$mdMedia','APP','UserActivityService','$stateParams','CommonService'];
    function UserActivityController($scope,$window, $mdSidenav,Principal,$state,$mdMedia,APP,UserActivityService,$stateParams,CommonService) {
    	var vm = this;
    	$scope.media = $mdMedia;
    	vm.APP = APP;
    	/*vm.user =  $cookies.getObject('sessionDetails');*/
    	vm.user = CommonService.getCookieUser().cookieUser;
    	
    	vm.userDetails = $stateParams;
    	vm.userActivityLoader = true;
    	vm.userActivityDetails = function(userId){
        	if(userId){
        		var data = UserActivityService.getUserActivityDetails(userId);
        		  data.then(function(response){
        			  vm.userActivityLoader = false;
        			  vm.activity = response.investigationActivity;
        			  $scope.updateUserName(response.investigatorFirstName,response.investigatorLastName);
        		  }, function(reason) {
        		return reason;
        		});
        	}
        };
    	
    	if(vm.userDetails != null){
        	vm.userActivityDetails(vm.userDetails.id);
        }
    }
})();