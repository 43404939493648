(function() {
	'use strict';
	
	var lariDevApp = angular
    .module('lariAidApp')
    lariDevApp.controller('SchoolInformationController', SchoolInformationController);
	
	SchoolInformationController.$inject = ['$scope','$window', '$mdSidenav','Principal','$state','$mdMedia','$cookies','APP','SchoolInformationService','InvestigationFormService','$stateParams','$timeout','$mdDialog','$rootScope'];
	function SchoolInformationController ($scope,$window, $mdSidenav,Principal,$state,$mdMedia,$cookies,APP,SchoolInformationService,InvestigationFormService,$stateParams,$timeout,$mdDialog,$rootScope) {
		
		var vm = this;
		vm.dateOfVisit = '';
		vm.app = APP;
		
        $scope.schoolInformation = {};
        $scope.schoolInformation.sections = [];
        //$scope.schoolInformation.sections.questions = [];
        $scope.schoolInformation.summary = {};
        $scope.loadingSchool = false;
        $scope.interviewFlag = true;
		$scope.recordFlag = true;
		$scope.disablesaveflag = false;
		var oldFormData = {};
        
        vm.loadschoolInformationDetails = function(){
        	$scope.loadingSchool = true;
    		vm.getSchoolInformationDetails();
       	  	
          };   
        
          vm.getSchoolInformationDetails = function (){
			$scope.entityObj.searchKey = null;
        	  var sectionKey = vm.app.REF_TERM.SCHOOL_INFORMATION;
        	  var formId = $scope.investigationFormId;
        	  var data = InvestigationFormService.getInvestigationFormBySectionId(sectionKey,formId);
        	  data.then(function(response){
        		  if(response.formVersion != null){
        			  $scope.formVersion = response.formVersion;
        		  }
		  		  if(response.sectionId != null){
		  			  $scope.schoolInfoSectionId = response.sectionId;
		  		  }
      		  	  if(response.formDTO == null){
      		  		  var tempObj = {};
      		  		  tempObj.questions = [];
      		  		  $scope.schoolInformation.sections.push(tempObj);
      		  	  } else if(response.formDTO.sections.length == 0) {
      		  		  if(response.formDTO.summary){
      		  			  var tempObj = {};
      		  			  tempObj.questions = [];
      		  			  $scope.schoolInformation.sections.push(tempObj);
      		  			  $scope.schoolInformation.summary = response.formDTO.summary;
      		  		  } else {
      		  			  var tempObj = {};
      		  			  tempObj.questions = [];
      		  			  $scope.schoolInformation.sections.push(tempObj);
      		  		  }
      		  	  } else {
      		  		  $scope.schoolInformation = response.formDTO;      		  		  
      		  	  }
      		      //Set the object to restrict the initial save call
  		  		  oldFormData = angular.copy(response.formDTO);
      		  	  $scope.loadingSchool = false;
      		  	  $scope.updateLastEditTime(response.updatedOn);
        	  }, function(reason) {
        		  return reason;
        	  });
          };
          
          /* Method name            : saveschoolInformationDetails()
	       * Method Description 	: This method saves the school form details 
	 	   */ 
         $scope.saveschoolInformationDetails = function(){
           var isPutJsonSimilar = false;
           $rootScope.warningPopup.show = false; //To avoid showing popup while moving to other section if there is no edit in current section
       	   var saveDataObj = {};
       	   saveDataObj.formDTO = angular.copy($scope.schoolInformation);
       	   saveDataObj.formVersion = $scope.formVersion;
       	   saveDataObj.investigationFormId = $scope.investigationFormId;
       	   saveDataObj.sectionId = $scope.schoolInfoSectionId;
	       	for(var i=0;i<saveDataObj.formDTO.sections.length;i++){
	            if(!saveDataObj.formDTO.sections[i].questions){
	                saveDataObj.formDTO.sections.splice(i,1);
	                i--;
	            } else if(saveDataObj.formDTO.sections[i].questions.length == 0 || (saveDataObj.formDTO.sections[i].questions.length == 1 && saveDataObj.formDTO.sections[i].questions[0].schoolInformation_1_label.answer == "")) {
	                saveDataObj.formDTO.sections.splice(i,1);
	                i--;
	            } else {
	                delete saveDataObj.formDTO.sections[i].expanded;
	                saveDataObj.formDTO.sections[i].sortOrder = i+1;
	            }
	        }
	       	
	       	/*	Set the temporary object to avoid unwanted save call. 
      			Fist condition to avoid the initial save call for empty form 
      			Second condition to avoid the initial save call if the section has any form data 
	       	*/
            if(angular.isUndefined($scope.tempSchoolObj) && ((saveDataObj.formDTO.sections != null && saveDataObj.formDTO.sections[0] != null && saveDataObj.formDTO.sections[0].questions != null &&
            		saveDataObj.formDTO.sections[0].questions.length == 1 && saveDataObj.formDTO.sections[0].questions[0].schoolInformation_1_label.answer == "") || 
                    saveDataObj.formDTO.sections.length == 0 && oldFormData == null) && (saveDataObj.formDTO.summary.comment == null || saveDataObj.formDTO.summary.comment == undefined)) {
                $scope.tempSchoolObj = angular.copy(saveDataObj);
            } else if(angular.isUndefined($scope.tempSchoolObj) && angular.equals(JSON.stringify(saveDataObj.formDTO), JSON.stringify(oldFormData))) {
         	   $scope.tempSchoolObj = angular.copy(saveDataObj);
            } else if(angular.isUndefined($scope.tempSchoolObj) && !angular.equals(JSON.stringify(saveDataObj.formDTO), JSON.stringify(oldFormData))) {
            	$scope.tempSchoolObj = {};
            	$scope.tempSchoolObj.formDTO = angular.copy(oldFormData);
            } 
	       	
            if(!angular.isUndefined($scope.tempSchoolObj)){
            	if(angular.equals(JSON.stringify(saveDataObj.formDTO), JSON.stringify($scope.tempSchoolObj.formDTO))){
            		isPutJsonSimilar = true;	    			  
            	} 
            }
            if($scope.isOwner){
            	$scope.tempSchoolObj = angular.copy(saveDataObj);
            } else {
            	$scope.tempSchoolObj = undefined; //Every time temporary object will be newly updated for non owner 
            }

	       	/* saveData flag - to ensure the save button is clicked in form*/
	       	if(!isPutJsonSimilar){
	       		if($scope.isOwner || $scope.saveData){
	       			//Flag to set the section results are edited or not
	       			if(angular.isDefined($scope.schoolCheckList) && angular.isDefined($scope.schoolCheckListTempObj) && !angular.equals($scope.schoolCheckList, $scope.schoolCheckListTempObj)) {
	       				saveDataObj.isFormSectionResultEdited = true;
	       				$scope.saveAnalystFormTypeCheckList('school', true, false);
	       			}
	       			
	       			var data = InvestigationFormService.saveInvestigationForm(saveDataObj);
	    		  	data.then(function(response){
		  		  		if($state.current.name === vm.app.INVESTIGATION.STATE.SCHOOL_INFORMATION_STATE)
		  		  		{
		  		  			$scope.updateLastEditTime(response.updatedOn);
		  		  		}
		  		  		$scope.uploadFileStatusUpdated();
	    		  	}, function(reason) {
	    			  return reason;
	    		  	});
	       		}
	       	 $rootScope.warningPopup.show = true;
	       	} else if(($scope.isOwner || $scope.saveData) && angular.isDefined($scope.schoolCheckList) && angular.isDefined($scope.schoolCheckListTempObj)
	       			&& !angular.equals($scope.schoolCheckList, $scope.schoolCheckListTempObj)) {
   				$scope.saveAnalystFormTypeCheckList('school', true);
   			}
          };
          
          vm.interviewFlag = function(flag, index){
         	 $scope.interviewFlag = flag;
         	 if(index){
         		 $scope.sectionIndex = index; 
         	 }
           };
          
          vm.addAdditionalInterviewForm = function(){
              var testObj = {};
              if($scope.interviewFlag == 1){
             	 $scope.schoolInformation.sections[0].questions[0].schoolInformation_2_yes_2b_Interviewee.interviews.push(testObj);
              }
              if($scope.interviewFlag == 2){
             	 if($scope.sectionIndex){
             	   $scope.schoolInformation.sections[$scope.sectionIndex].questions[0].schoolInformation_2_yes_2b_Interviewee.interviews.push(testObj);
             	 }
              }
          };
           
           /* Method name           : collapseAll()
       	 * Method Description 	 : This method is used to collapse the accordion 
   		 */
          vm.collapseAll = function(index,flag){
        	  var height = 0;
        	  for(var i=0;i<$scope.schoolInformation.sections.length;i++){
        		  if(flag==0){
        			  height = height + $('.acc_'+index).closest('.md-accordion').offsetHeight + 42;
        		  }
        		  else{
        			  height = height + $('.acc_'+index).closest('.md-accordion')[0].offsetHeight + 42;
        		  }
        		  if(i == index){
        			  if(!$scope.schoolInformation.sections[i].expanded){
        				  $scope.formViewCall(index);
        				  $scope.schoolInformation.sections[i].expanded = !$scope.schoolInformation.sections[i].expanded;
        				  $('#schoolInformation').animate({scrollTop: height}, 'slow');
		    
        				  //Calling get documents based on accordion index
        				  $scope.getUploadFiles(null,vm.app.REF_TERM.SCHOOL_INFORMATION,index)
        			  }else{
        				  $scope.schoolInformation.sections[i].expanded = !$scope.schoolInformation.sections[i].expanded;
        			  }
        		  } else {
        			  $scope.schoolInformation.sections[i].expanded = false;
        		  }
        		  if($scope.schoolInformation.sections[i].expanded){
        			  if($scope.schoolInformation.sections[i].questions[0] == undefined){
        				  $scope.notApproved=false;
        			  }else{
        			  $scope.isEntityApproved($scope.schoolInformation.sections[i].questions[0].schoolInformation_1_label.answerId);
        			  }
        			  $scope.openAccordionIndex = i;
        		  }
        	  }
          };
          
          
          /* Method name           : addAdditionalInstitutionForm()
           * Method Description 	: This method adds Neighborhood canvas form to list of birth certificate form 
   	    */
          vm.addAdditionalInstitutionForm = function(){
              var tempObj = {};
              tempObj.questions = [];
              tempObj.expanded = false;
              $scope.notApproved=false;
              $scope.schoolInformation.sections.push(tempObj);
              vm.collapseAll($scope.schoolInformation.sections.length - 1,0);
          };
          
          /* Method name           : removeschoolInformation()
           * Method Description 	: This method removes the added Neighborhood canvas form from list of birth certificate form
   	    */
          /*vm.removeschoolInformation = function(index){
       	   $scope.schoolInformation.sections.splice(index,1);
          };*/
          vm.removeschoolInformation = function(ev,id,indexValue){
        	  $scope.indexVal = indexValue;  
        	  $mdDialog.show({
        		  contentElement: angular.element('#'+id),
        		  targetEvent: ev,
        		  clickOutsideToClose: false,
        		  escapeToClose: false
        	  })
          };
          
          vm.closeConform = function(index){
        	  $scope.deleteLoader.flag = true;
          	$mdDialog.hide();
          	$scope.schoolInformation.sections.splice(index,1);
          	if(index != 0){
           		vm.collapseAll(index-1,1);
           	}	
          	 $scope.enableDeleteButton();
          };
          
          vm.closeCancel = function(){
        	  $mdDialog.cancel();
        	  $scope.interviewFlag = true;
			  $scope.recordFlag = true;
          };
          
		vm.profileForm = $stateParams;
		
		vm.nextInvestigationForm = function(){
			//$scope.saveAnalystFormTypeCheckList('school');
			$scope.disablesaveflag = true;
			$scope.saveData = true;
			$scope.saveschoolInformationDetails();
			$rootScope.warningPopup.show = false;
			$scope.sectionNavigationFunc(vm.app.INVESTIGATION_FORM.RELIGIOUS_FORM);
			//$state.go("religiousInstitution");
			if($scope.investigationFormType == vm.app.INVESTIGATION.ANALYST_FORM){
				$scope.collapseAll(5);
			}
			$scope.updateLastEditTime(null);
		};

		/* File Upload Sections */
        
        vm.uploadFileForSummary = function(isSummary,accordionIndex,idName,ev){
    		$("#schoolInformationSectionSummary").val(null);
 	        $('#schoolInformationSectionSummary').trigger('click'); 
 	       $('#schoolInformationSectionSummary').off('change').on('change', function() {
 	    	   	var file = this.files[0];
 	    		var sectionId = $scope.schoolInfoSectionId;
 	    		var questionKey = null;
    			if(isSummary == "true"){
    				questionKey = vm.app.INVESTIGATION_FORM.SUMMARY_KEY;
    			}
    			$scope.setOrginSectionFileUpload(vm.app.REF_TERM.SCHOOL_INFORMATION);
    			$scope.uploadFileInSectionSummary(ev,file,questionKey,sectionId,isSummary,null,idName,accordionIndex,null);
	        });
 		};

 		$rootScope.warningPopup.show = false;
 		/* Method Description 	: This method triggers auto save for birth certificate form */
        $scope.$watch('schoolInfoAutoSave', function(newValue, oldValue) {
 			if(newValue != undefined && newValue != oldValue){ 				
 				if(newValue && $scope.isOwner){
	 					if($scope.formVersion && !$scope.isreadonly){
	 						$scope.saveschoolInformationDetails();
	 	 					$rootScope.schoolInfoAutoSave = false;
						} else{
							$rootScope.schoolInfoAutoSave = false;
						}
	 				}else{
	 					$rootScope.schoolInfoAutoSave = false;
	 				} 				
 			}
 		});
        
        /**
		 * To find which accordion is open currently
		 */	
		vm.findOpenform = function(){
        	 if($scope.schoolInformation.sections.length > 1){
        		 var j = -1;
        		 for(var i = 0;i < $scope.schoolInformation.sections.length;i++){
            		 if($scope.schoolInformation.sections[i].expanded == true){
            			 return ++i;
            		 }
            	 } 
        		 return j;
        	 }
        	 return 1;
         };
			
		/**
		 * Add the requested Entity
		 * 
		 */	
         $rootScope.schoolEntityRequestsent = function(entity,locationObject,response){
	    	  var entityName = entity.entityName+", "+locationObject.cityObj.cityTxt+", "+locationObject.countryObj.countryTxt;
	    	  var openForm = vm.findOpenform();openForm--;
	    	  if(openForm != -1){
	    		  $scope.schoolInformation.sections[openForm].questions[0].schoolInformation_1_label.answer = entityName;
	    		  $scope.schoolInformation.sections[openForm].questions[0].schoolInformation_1_label.answerId = response.id;
	    		  $scope.schoolInformation.sections[openForm].questions[0].schoolInformation_1_label.requested = true;
	    	  }
	    	  document.querySelector('.global-search-input input').value = entityName;
	    	  $scope.selectedtext = entityName;
		};
		
      //Method for add or suggest entity
	    vm.entityRequestSuggest = function(searchKey,entityName){
	    	return $scope.entityRequestAddorSuggest(searchKey,entityName);
	   	};
	   	
	  //It will get triggered while exiting from current state
	   	$scope.$on('$destroy', function() {
	   		if(!$scope.isreadonly && $scope.isOwner) {
	   			$scope.saveschoolInformationDetails();
        	}
	   		$rootScope.warningPopup.show = false;
	   		$scope.uploadedFile = false;
	   	});
	   	
	   	//To open the confirmation pop up for remove the additional interview or records
    	vm.deleteIndex = function(event,id,index,isInterview){
			$scope.indexVal = index;
			$scope.isInterview = isInterview;
			$scope.interviewFlag = false;
			$scope.recordFlag = false;
			$mdDialog.show({
				contentElement: angular.element('#'+id),
				targetEvent: event,
				clickOutsideToClose: false,
				escapeToClose: false,
				onRemoving: function (event, removePromise) {
					vm.closeCancel();
				}
			});
    	};
       	
    	//To remove the interview content form the form data
       	vm.deleteInterviewConfirm = function(index){
       		$scope.deleteLoader.flag = true;
       		if($scope.schoolInformation.sections.length == 1){
	  			$scope.openAccordionIndex = 0;
	  		}
       		$scope.schoolInformation.sections[$scope.openAccordionIndex].questions[0].schoolInformation_2_yes_2b_Interviewee.interviews.splice(index,1);
       	    vm.closeCancel();
       	    $scope.enableDeleteButton();
       	};
       	
       	//To remove the records form the form data
       	vm.deleteRecordConfirm = function(index){
       		$scope.deleteLoader.flag = true;
       		if($scope.schoolInformation.sections.length == 1){
	  			$scope.openAccordionIndex = 0;
	  		}
       		$scope.schoolInformation.sections[$scope.openAccordionIndex].questions[0].school_record_list.records.splice(index,1);
       	    vm.closeCancel();
       	    $scope.enableDeleteButton();
       	};
	}
})();
