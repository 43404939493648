(function(){
	'use strict';

	angular.module('lariAidApp').directive('validateEmail', emailValidation);
	
	function emailValidation(){
		/*var EMAIL_REGEXP = /^[A-Za-z0-9_-]+(\.[A-Za-z0-9]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,4})$/;*/
		var EMAIL_REGEXP = /^[A-Za-z\u00C0-\u017F0-9_-]+(\.[A-Za-z\u00C0-\u017F0-9]+)*@[A-Za-z\u00C0-\u017F0-9-]+(\.[A-Za-z\u00C0-\u017F0-9-]+)*(\.[A-Za-z\u00C0-\u017F]{2,4})$/;

		return {
		    require: 'ngModel',
		    restrict: 'A',
		    link: function(scope, elm, attrs, ctrl) {
		      // only apply the validator if ngModel is present and Angular has added the email validator
		      if (ctrl && ctrl.$validators.email) {
	
		        // this will overwrite the default Angular email validator
		        ctrl.$validators.email = function(modelValue) {
		          return ctrl.$isEmpty(modelValue) || EMAIL_REGEXP.test(modelValue);
		        };
		      }
		    }
		  };
	}
	
})();