(function() {
	'use strict';
	angular.module('lariAidApp').directive('noSpecialChar', specialCharValidation);
	//resize.inject['$window'];
	
	function specialCharValidation() {
		 return {
	          require: 'ngModel',
	          restrict: 'A',
	          link: function(scope, element, attrs, modelCtrl) {
	        	 /*attrs.$observe('allowedChar',function() {
	        		 console.log("attrs.allowedChar>>>>"+attrs.allowedChar)
	          	});*/
	        	  
	            modelCtrl.$parsers.push(function(inputValue) {
	            	var allowedChar = '';
	            	if(attrs.allowedChar){
	            		allowedChar = attrs.allowedChar;
	            	}
	            	/* var expr = "[^\\w\\s."+allowedChar+"][À-ú][À-ÿ]";
	            	 var expr = "[^\\w\u00C0-\u017F\\s."+allowedChar+"]";
	            	var expr = "[^\\w\\s."+allowedChar+"]";*/
	            	/*This is used to allow words including latin letters
	            	var expr = "[^\\w\u00C0-\u017F\\s."+allowedChar+"]";*/
	            	var expr = allowedChar;
	            	 var myRegExp = new RegExp(expr,'gi');
	            	 
	              if (inputValue == undefined)
	                return ''
	              scope.cleanInputValue = inputValue.replace(myRegExp, '');
	              if (scope.cleanInputValue != inputValue) {
	                modelCtrl.$setViewValue(scope.cleanInputValue);
	                modelCtrl.$render();
	              }
	              return scope.cleanInputValue;
	            });
	          }
	        }
		
	}
})();