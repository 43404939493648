(function() {
	'use strict';

	angular.module('lariAidApp').directive('limitTo', LimitTo);
	//resize.inject['$window'];
	
	function LimitTo() {
		 return {
			 restrict: "A",
		        link: function(scope, elem, attrs) {
		            var limit = parseInt(attrs.limitTo);
		            angular.element(elem).on("keypress", function(e) {
		                if (this.value.length == limit) e.preventDefault();
		            });
		        }
		 	}
		}
})();