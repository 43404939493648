(function () {
  "use strict";

  angular.module("lariAidApp").controller("NavbarController", NavbarController);

  NavbarController.$inject = [
    "$state",
    "$scope",
    "Auth",
    "Principal",
    "ProfileService",
    "LoginService",
    "$mdSidenav",
    "$cookies",
    "APP",
    "$timeout",
    "$sessionStorage",
    "CommonService",
    "JhiLanguageService",
    "$translate",
    "tmhDynamicLocale",
    "$controller",
    "$rootScope"
  ];

  function NavbarController(
    $state,
    $scope,
    Auth,
    Principal,
    ProfileService,
    LoginService,
    $mdSidenav,
    $cookies,
    APP,
    $timeout,
    $sessionStorage,
    CommonService,
    JhiLanguageService,
    $translate,
    tmhDynamicLocale,
    $controller,
    $rootScope
  ) {
    angular.extend(this, $controller("sessionCtrl", { $scope: $scope }));

    var vm = this;

    vm.isNavbarCollapsed = true;
    vm.isAuthenticated = Principal.isAuthenticated;
    vm.APP = APP;

    if (!angular.isUndefined($cookies.getObject("sessionDetails"))) {
      /*vm.user = $cookies.getObject('sessionDetails');*/
      vm.user = CommonService.getCookieUser().cookieUser;
      vm.loggedUser = vm.user.authorities[0];
      vm.loginName = vm.user.firstName + " " + vm.user.lastName;
    }

    ProfileService.getProfileInfo().then(function (response) {
      vm.inProduction = response.inProduction;
      vm.swaggerEnabled = response.swaggerEnabled;
    });

    vm.login = login;
    vm.logout = logout;
    vm.toggleNavbar = toggleNavbar;
    vm.collapseNavbar = collapseNavbar;
    vm.$state = $state;
    vm.languageName = languageName;
    vm.changeLanguage = changeLanguage;

    function languageName() {
      JhiLanguageService.getAll().then(function (languages) {
        vm.languages = languages;
      });
    }

    vm.defaultLang = function (langKey) {
      if ($sessionStorage.selectedLanguage && vm.APP.GLOBAL.ROLE_INVESTIGATOR == vm.loggedUser) {
        vm.lang = $sessionStorage.selectedLanguage;
      } else {
        vm.lang = langKey;
      }
      $sessionStorage.selectedLanguage = vm.lang;
      $rootScope.language = $sessionStorage.selectedLanguage;
      $translate.use(vm.lang);
      tmhDynamicLocale.set(vm.lang);
    };

    function changeLanguage(languageKey) {
      vm.lang = languageKey;
      if (vm.lang == vm.APP.GLOBAL.ENGLISH) {
        vm.lang = vm.APP.GLOBAL.EN;
      } else if (vm.lang == vm.APP.GLOBAL.SPANISH) {
        vm.lang = vm.APP.GLOBAL.ES;
      }
      $sessionStorage.selectedLanguage = vm.lang;
      $translate.use(vm.lang);
      tmhDynamicLocale.set(vm.lang);
      $rootScope.language = $sessionStorage.selectedLanguage;
    }

    if (!angular.isUndefined($cookies.getObject("NG_TRANSLATE_LANG_KEY")) && $sessionStorage.selectedLanguage) {
      vm.lang = $cookies.getObject("NG_TRANSLATE_LANG_KEY");
    }

    if ($sessionStorage.selectedLanguage) {
      vm.lang = $sessionStorage.selectedLanguage;
    }

    function login() {
      collapseNavbar();
      // $state.go('login');
      //LoginService.open();
    }

    function logout() {
      //$translate.use('en');
      collapseNavbar();
      Auth.resetPreviousState();
      Auth.logout(true);
      // $state.go('login');
      delete $sessionStorage.selectedLanguage;
    }

    function toggleNavbar() {
      vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
    }

    function collapseNavbar() {
      vm.isNavbarCollapsed = true;
    }

    $scope.close = function () {
      $mdSidenav("left").close();
    };

    $scope.$on("$stateChangeSuccess", function (event) {
      $timeout(function () {
        $(window).trigger("resize");
      }, 500);
    });
    function setHeaderColor() {
      if ($sessionStorage.currentEnvironment && $sessionStorage.currentEnvironment.toLowerCase() != "prod") {
        $(".headerTop").css("background-color", "#008080");
      }
    }

    setHeaderColor();

    $scope.$on("setheader", function () {
      setHeaderColor();
    });
  }
})();
