(function() {
    'use strict';
    
    var lariDevApp = angular
    .module('lariAidApp')
    lariDevApp.controller('BirthCertificateController', BirthCertificateController);
    
    BirthCertificateController.$inject = ['$element','$scope','$window', '$mdSidenav','Principal','$state','$mdMedia','$cookies','APP','BirthCertificateService','$stateParams','$rootScope','InvestigationFormService','$timeout','Upload','$mdDialog','$q','$sessionStorage'];
    function BirthCertificateController ($element,$scope,$window, $mdSidenav,Principal,$state,$mdMedia,$cookies,APP,BirthCertificateService,$stateParams,$rootScope,InvestigationFormService,$timeout,Upload,$mdDialog,$q,$sessionStorage) {
    	var vm = this;
    	vm.dateOfVisit = '';
    	$scope.declarationDate = undefined;
    	vm.profileForm = $stateParams;
    	vm.myDate = new Date();
    	$scope.interviewFlag = true;
    	vm.entityObj = {};
    	vm.entityObj.entityTypeId = [];
    	vm.entityObj.searchKey = {};
    	vm.app = APP;
    	$scope.entityNameSearchKey = {};
    	vm.entityList = [];
    	$scope.loadingBirthCertificate = false;
    	vm.langKey = $cookies.getObject('NG_TRANSLATE_LANG_KEY');
    	$scope.disablesaveflag = false;
    	
    	/**
    	 * To get Current date
    	 */
    	vm.maxDate = new Date(
    	   vm.myDate.getFullYear(),
    	   vm.myDate.getMonth(),
    	   vm.myDate.getDate()
    	 );
    	/*************** Birth Certificate Functional Implementation ****************/
        
        $scope.birthCertificate = {};
        $scope.birthCertificate.sections = [];
        $scope.birthCertificate.sections.questions = [];
        $scope.birthCertificate.summary = {};
        vm.radioButtonFlag = [];
        var oldFormData = {};
        
        
        /* Method name           : collapseAll()
    	 * Method Description 	 : This method is used to collapse the accordion 
		 */
        vm.collapseAll = function(index,flag){
        	 var height = 0;
	            for(var i=0;i<$scope.birthCertificate.sections.length;i++){
		            if(flag==0){
		            	height = height + $('.acc_'+index).closest('.md-accordion').offsetHeight + 42;
		            }
		            else{
		            	height = height + $('.acc_'+index).closest('.md-accordion')[0].offsetHeight + 42;
		            }
		            if(i == index){
		   	             if(!$scope.birthCertificate.sections[i].expanded){
		   	            	 	$scope.formViewCall(index);
				   	            $scope.birthCertificate.sections[i].expanded = !$scope.birthCertificate.sections[i].expanded;
				                $('#birthCertificate').animate({scrollTop: height}, 'slow');
				                
				                //Calling get documents based on accordion index
				                $scope.getUploadFiles(null,vm.app.REF_TERM.BIRTH_CERTIFICATE,index)
		   	             }else{
		   	            	    $scope.birthCertificate.sections[i].expanded = !$scope.birthCertificate.sections[i].expanded;
		   	             }
		            } else {
		            		$scope.birthCertificate.sections[i].expanded = false;
		            	}
		            if($scope.birthCertificate.sections[i].expanded){
		            	if($scope.birthCertificate.sections[i].questions[0] == undefined){
		            		$scope.isApproved=false;
		            	}else{
		            	$scope.isEntityApproved($scope.birthCertificate.sections[i].questions[0].birthCertificate_1a.answerId);
		            	}
	           	    	$scope.openAccordionIndex = i;
	           	    }
	            }
       };
       
       /* Method name           : loadBirthCertificateFormDetails()
        * Method Description 	: This method loads the birth certificate form details
	    */
       vm.loadBirthCertificateFormDetails = function(){
    	   vm.getBirthCertificateDetails();
       };
       
       vm.getBirthCertificateDetails = function(){
		   $scope.entityObj.searchKey = null;
    	   $scope.loadingBirthCertificate = true;
    	   var sectionKey = vm.app.REF_TERM.BIRTH_CERTIFICATE;
		   var formId = $scope.investigationFormId;
    	   var data = InvestigationFormService.getInvestigationFormBySectionId(sectionKey,formId);
		  	data.then(function(response){
		  		if(response.formVersion != null){
 		  			$scope.formVersion = response.formVersion;
 		  		}
		  		if(response.sectionId != null){
 		  			$scope.birthCertificateSectionId = response.sectionId;
 		  		}
		  		if(response.formDTO == null){
		  		   var tempObj = {};
		     	   tempObj.questions = [];
		     	   $scope.birthCertificate.sections.push(tempObj);
		  		} else if(response.formDTO.sections.length == 0) {
				  	   if(response.formDTO.summary){
						     var tempObj = {};
						  	   tempObj.questions = [];
						  	         $scope.birthCertificate.sections.push(tempObj);
						  	         $scope.birthCertificate.summary = response.formDTO.summary;
				  	   } else {
						  	   var tempObj = {};
						  	   tempObj.questions = [];
				  	           $scope.birthCertificate.sections.push(tempObj);
				  	   }
		  	    }else {
		  			$scope.birthCertificate = response.formDTO;		  			
		  	    }
		  	   //Set the object to restrict the initial save call
		  		oldFormData = angular.copy(response.formDTO);
		  		$scope.loadingBirthCertificate = false;
		  		$scope.updateLastEditTime(response.updatedOn);
		  		
		   }, function(reason) {
			  return reason;
		   });
       }
       
       /* Method name           : assignDateDiffValue()
        * Method Description     : This method binds the date difference value to difference question
        */
       vm.assignDateDiffValue = function(dateVal,declaredDob){
    	   if(declaredDob){
    		  // var dobDate = declaredDob;
    		   var dobDate = $scope.timzoneFormat(declaredDob);
    	   } else{
    		   var dobDate = new Date($scope.investigationDateOfBirth);
    	   }
    	   vm.trueflag =true;
    	  // var dobDate = new Date($scope.investigationDateOfBirth);
    	   /*var m1 = moment(dobDate,'YYYY-MM-DD');
           var m2 = moment(dateVal,'YYYY-MM-DD');
           dateVal = moment.preciseDiff(m1, m2); 
           if(dateVal == ''){
               dateVal = '0 days'
           }*/
    	   var dateValue = $scope.timzoneFormat(dateVal);
    	   
    	   $scope.dateDiff ={};
    	  
    	   var a = moment(dobDate,'YYYY-MM-DD');
    	   var b = moment(dateValue,'YYYY-MM-DD');
    	   
    	   $scope.years = a.diff(b, 'year');
    	   b.add($scope.years, 'years');

    	   $scope.months = a.diff(b, 'months');
    	   b.add($scope.months, 'months');

    	   $scope.days = a.diff(b, 'days');
    	   
    	   if($scope.years < 0){
    		   $scope.years = $scope.years * (-1);
    	   }
    	   
    	   if($scope.months < 0){
    		   $scope.months = $scope.months * (-1);
    	   }
    	   
    	   if($scope.days < 0){
    		   $scope.days = $scope.days * (-1);
    	   }
    	   $scope.years = $scope.years.toString();
    	   $scope.months = $scope.months.toString();
    	   $scope.days = $scope.days.toString();
       };
       
       /* Method name            : declaredPersonsDob()
        * Method Description     : This method returns the prospect's date of birth to date picker with time zone
        */
       $scope.declaredPersonsDob = function(dateValue,declaredPerson){	
    	   	  if(angular.isUndefined(dateValue) && declaredPerson == 'birth_prospect'){    	  
    	   	  var dob = new Date($scope.investigationDateOfBirth);
    	   	  dob.setMinutes(dob.getMinutes() - dob.getTimezoneOffset());
    	   	  return dob.toISOString();
    	 	  }else{
    	 		  return dateValue;
    	 	  }
       };
       
       /* Method name           : saveBirthCertificateFormDetails()
        * Method Description 	: This method saves the birth certificate form details 
	    */
       $scope.saveBirthCertificateFormDetails = function(){
    	   var isPutJsonSimilar = false;
    	   $rootScope.warningPopup.show = false;  //To avoid showing popup while moving to other section if there is no edit in current section
    	   var saveDataObj = {};
    	   saveDataObj.formDTO = angular.copy($scope.birthCertificate);
    	   saveDataObj.formVersion = $scope.formVersion;
    	   saveDataObj.investigationFormId = $scope.investigationFormId;
    	   saveDataObj.sectionId = $scope.birthCertificateSectionId;
    	   var registry = {};
           for(var i=0;i<saveDataObj.formDTO.sections.length;i++){
               if(!saveDataObj.formDTO.sections[i].questions){
                   saveDataObj.formDTO.sections.splice(i,1);
                   i--;
               } else if(saveDataObj.formDTO.sections[i].questions.length == 0 || (saveDataObj.formDTO.sections[i].questions.length == 1 && 
            		   saveDataObj.formDTO.sections[i].questions[0].birthCertificate_1a.answer == "" && saveDataObj.formDTO.sections[i].questions[0].birthCertificate_1b == null)) {
                   saveDataObj.formDTO.sections.splice(i,1);
                   i--;
               } else{
                   delete saveDataObj.formDTO.sections[i].expanded;
                   saveDataObj.formDTO.sections[i].sortOrder = i+1;
               }
               
           }
           /*	Set the temporary object to avoid unwanted save call. 
     			Fist condition to avoid the initial save call for empty form 
     			Second condition to avoid the initial save call if the section has any form data 
           */
           if(angular.isUndefined($scope.tempBCObj) && ((saveDataObj.formDTO.sections != null && saveDataObj.formDTO.sections[0] != null &&  saveDataObj.formDTO.sections[0].questions != null && 
        		   saveDataObj.formDTO.sections[0].questions.length == 1 && saveDataObj.formDTO.sections[0].questions[0].birthCertificate_1a.answer == "" && 
                   saveDataObj.formDTO.sections[0].questions[0].birthCertificate_1b == null && saveDataObj.formDTO.sections[0].questions[0].birthCertificate1 == null) ||  saveDataObj.formDTO.sections.length == 0) && 
                   (saveDataObj.formDTO.summary.comment == null || saveDataObj.formDTO.summary.comment == undefined) && oldFormData == null) {
               $scope.tempBCObj = angular.copy(saveDataObj);
           } else if(angular.isUndefined($scope.tempBCObj) && angular.equals(JSON.stringify(saveDataObj.formDTO), JSON.stringify(oldFormData))) {
        	   $scope.tempBCObj = angular.copy(saveDataObj);
           } else if(!angular.isUndefinedOrNull(oldFormData) && (!angular.isUndefinedOrNull(oldFormData.sections) && oldFormData.sections.length > 0) && 
                   (!angular.isUndefined(oldFormData.sections[0].questions) && oldFormData.sections[0].questions.length > 0) && oldFormData.sections[0].questions[0].birthCertificate_1a && 
                   (oldFormData.sections[0].questions[0].birthCertificate_1a.answer == null || oldFormData.sections[0].questions[0].birthCertificate_1a.answer == "") && 
                   angular.isUndefined(oldFormData.sections[0].questions[0].birthCertificate_1b)){
        	   oldFormData.sections = [];       	  
        	   $scope.tempBCObj = {};
        	   $scope.tempBCObj.formDTO = angular.copy(oldFormData);
           } else if(angular.isUndefined($scope.tempBCObj) && !angular.equals(JSON.stringify(saveDataObj.formDTO), JSON.stringify(oldFormData))) {
        	   $scope.tempBCObj = {};
        	   $scope.tempBCObj.formDTO = angular.copy(oldFormData);
           } 
           	 
    	   if(!angular.isUndefined($scope.tempBCObj)){
    		   if(angular.equals(JSON.stringify(saveDataObj.formDTO), JSON.stringify($scope.tempBCObj.formDTO))){
    			   isPutJsonSimilar = true;    			   
	    		 }
    	   }
    	   if($scope.isOwner){
    		   $scope.tempBCObj = angular.copy(saveDataObj);
    	   } else {
    		   $scope.tempBCObj = undefined; //Every time temporary object will be newly updated for non owner 
    	   }
    	
    	   /* saveData flag - to ensure the save button is clicked in form*/
    	   if(!isPutJsonSimilar) {    		  
    		   if($scope.isOwner || $scope.saveData) {
    			   //Flag to set the section results are edited or not
    			   if(angular.isDefined($scope.birthCertificateCheckList) && angular.isDefined($scope.birthCertificateCheckListTempObj) && !angular.equals($scope.birthCertificateCheckList, $scope.birthCertificateCheckListTempObj)) {
    				   saveDataObj.isFormSectionResultEdited = true;
        			   $scope.saveAnalystFormTypeCheckList('birthCertificate', true, false);
    			   }
    			   else 
    				   saveDataObj.isFormSectionResultEdited = false;
    			   
    			   var data = InvestigationFormService.saveInvestigationForm(saveDataObj);
    			   data.then(function(response) {
    				   if($state.current.name === vm.app.INVESTIGATION.STATE.BIRTH_CERTIFICATE_STATE)
    					   $scope.updateLastEditTime(response.updatedOn);
    				   $scope.uploadFileStatusUpdated();
    			   }, function(reason) {
    				   return reason;
    			   });
    		   }
    		   $rootScope.warningPopup.show = true;
    	   } else if(($scope.isOwner || $scope.saveData) && angular.isDefined($scope.birthCertificateCheckList) && 
    			   angular.isDefined($scope.birthCertificateCheckListTempObj) && !angular.equals($scope.birthCertificateCheckList, $scope.birthCertificateCheckListTempObj)) {
    		   $scope.saveAnalystFormTypeCheckList('birthCertificate', true);
    	   }
       };
       
       /* Method name           : addAdditionalInstitutionForm()
        * Method Description 	: This method adds birth certificate form to list of birth certificate form 
	    */
       vm.addAdditionalInstitutionForm = function(){
           var tempObj = {};
           tempObj.expanded = false;
           tempObj.questions = [];
           $rootScope.notApproved=false;
           $scope.birthCertificate.sections.push(tempObj);
           //For local/National confirmation popup, If new birth certificate added it mu
           vm.radioButtonFlag[$scope.birthCertificate.sections.length-1] = false;
           vm.collapseAll($scope.birthCertificate.sections.length - 1,0);
       };
       
       /* Method name           : removeBirthCertificateForm()
        * Method Description 	: This method removes the added birth certificate form from list of birth certificate form
	    */
      /* vm.removeBirthCertificateForm = function(index){
    	   $scope.birthCertificate.sections.splice(index,1);
       };*/
       vm.removeBirthCertificateForm = function(ev,id,indexValue){
    	   $scope.indexVal = indexValue;  
    	   $mdDialog.show({
    		   contentElement: angular.element('#'+id),
    		   targetEvent: ev,
    		   clickOutsideToClose: false,
    		   escapeToClose: false
    	   })
       };
       
       vm.closeConform = function(index){
    	   $scope.deleteLoader.flag = true;
       	$mdDialog.hide();
       	$scope.birthCertificate.sections.splice(index,1);
       	if(index != 0){
       		vm.collapseAll(index-1,1);
       	}	
        $scope.enableDeleteButton();
       };
       
       vm.closeCancel = function(){
    	   $mdDialog.cancel();
       	   $scope.interviewFlag = true;
       	   $scope.recordFlag = true;
       };
       
      
       /* Method Description 	: This method triggers auto save for birth certificate form */
       $scope.$watch('birthCertificateAutoSave', function(newValue, oldValue) {
    	  if(newValue != undefined && newValue != oldValue){
    		if(newValue && $scope.isOwner){
  					if($scope.formVersion && !$scope.isreadonly){
  						$scope.saveBirthCertificateFormDetails();
  						$rootScope.birthCertificateAutoSave = false;
  					} else{
  						$rootScope.birthCertificateAutoSave = false;
  					}
  				}else{
  					$rootScope.birthCertificateAutoSave = false;
  				}
    		 
			}
		});
       
           vm.interviewFlag = function(flag, index){
          	 $scope.interviewFlag = flag;
          	 if(index){
          		 $scope.sectionIndex = index; 
          	 }
            };
           
           vm.addAdditionalInterviewForm = function(){
               var testObj = {};
               if($scope.interviewFlag == 1){
              	 $scope.birthCertificate.sections[0].questions[0].birthCertificate2b.interviews.push(testObj);
               }
               if($scope.interviewFlag == 2){
              	 if($scope.sectionIndex){
              		 $scope.birthCertificate.sections[$scope.sectionIndex].questions[0].birthCertificate2b.interviews.push(testObj);
              	 }
               }
           };
           
           /* File Upload Sections */
           vm.uploadFileForSummary = function(isSummary,accordionIndex,idName,ev){
       		    $("#birthCertificateSectionSummary").val(null);
    	        $('#birthCertificateSectionSummary').trigger('click'); 
    	        $('#birthCertificateSectionSummary').off('change').on('change', function() {
    	        	var file = this.files[0];
        			var sectionId = $scope.birthCertificateSectionId;
        			var questionKey = null;
        			if(isSummary == "true"){
        				questionKey = vm.app.INVESTIGATION_FORM.SUMMARY_KEY;
        			}
        			$scope.setOrginSectionFileUpload(vm.app.REF_TERM.BIRTH_CERTIFICATE);
        			$scope.uploadFileInSectionSummary(ev,file,questionKey,sectionId,isSummary,null,idName,accordionIndex,null);
    	        });
    		};

    		
    			vm.nextInvestigationForm = function(){
    				//$scope.saveAnalystFormTypeCheckList('birthCertificate');    	
    				$scope.disablesaveflag = true;
    				$scope.saveData = true;
    				$scope.saveBirthCertificateFormDetails();
    				//$state.go("birthMedicalRecord");
    				$rootScope.warningPopup.show = false;
    				$scope.sectionNavigationFunc(vm.app.INVESTIGATION_FORM.BIRTH_AND_MEDICAL_FORM);
    				if($scope.investigationFormType == vm.app.INVESTIGATION.ANALYST_FORM){
    					$scope.collapseAll(1);
    				}
    				$scope.updateLastEditTime(null);
    			};
    		
    		/**
    		 * To find which accordion is open currently
    		 */	
			vm.findOpenform = function(){
	        	 if($scope.birthCertificate.sections.length > 1){
	        		 var j = -1;
	        		 for(var i = 0;i < $scope.birthCertificate.sections.length;i++){
	            		 if($scope.birthCertificate.sections[i].expanded == true){
	            			 return ++i;
	            		 }
	            	 } 
	        		 return j;
	        	 }
	        	 return 1;
	         };
    			
    		/**
    		 * Add the requested Entity
    		 * 
    		 */	
    		$rootScope.birthCertificateEntityRequestsent = function(entity,locationObject,response){
		    	  var entityName = entity.entityName+", "+locationObject.cityObj.cityTxt+", "+locationObject.countryObj.countryTxt;
		    	  var openForm = vm.findOpenform();openForm--;
		    	  if(openForm != -1){
		    		  $scope.birthCertificate.sections[openForm].questions[0].birthCertificate_1a.answer = entityName;
		    		  $scope.birthCertificate.sections[openForm].questions[0].birthCertificate_1a.answerId = response.id;
		    		  $scope.birthCertificate.sections[openForm].questions[0].birthCertificate_1a.requested = true;
		    		  $scope.birthCertificate.sections[openForm].questions[0].birthCertificate_1b.answerId=response.entityTypeId;
		    		  if(response.entityTypeRefTermKey == vm.app.INVESTIGATION.LOCAL_REGISTRY){
		    			  $scope.birthCertificate.sections[openForm].questions[0].birthCertificate_1b.answer = vm.app.GLOBAL.LOCAL_REGISTRY;
		    		  }else if(response.entityTypeRefTermKey == vm.app.INVESTIGATION.NATIONAL_REGISTRY){
		    			  $scope.birthCertificate.sections[openForm].questions[0].birthCertificate_1b.answer = vm.app.GLOBAL.NATIONAL_REGISTRY;
		    		  }
		    		  
		    	  }
		    	  document.querySelector('.global-search-input input').value = entityName;
		    	  $scope.selectedtext = entityName;
    		};
    			    			
    			//Method for add or suggest entity
    		    vm.entityRequestSuggest = function(searchKey,entityName,index){
    		    	vm.radioButtonFlag[index] = true;
    		    	return $scope.entityRequestAddorSuggest(searchKey,entityName);
    		   	};

    		   	$scope.radioChangeCheck = function(ev,id,value,index){
    		   			if(vm.radioButtonFlag[index]){
    		   				$scope.radioButtonChange(ev,id,value,index);
    		   			}
    		   	}
    		   	$scope.radioButtonChange = function(ev,id,value,index){
    		   		vm.radioButtonValue = value;
    		   		if(vm.radioButtonFlag[index]){
    		   			if($sessionStorage.selectedLanguage != null){
        	        		vm.selectedLang = $sessionStorage.selectedLanguage;
        	        	}
    		   			vm.currentIndex = index;
		    			$mdDialog.show({
		    	   			 contentElement: angular.element('#'+id),
		    	   		      targetEvent: ev,
		    	   		      clickOutsideToClose:false,
		    	   		      escapeToClose: false,
		    	   		      onShowing: function (event, showPromise) { 
		    	   		    	  vm.yesToProceed = false;
		    	   		   		},
		    	   		      onRemoving: function (event, removePromise) { 
		    	   		    	  if(!vm.yesToProceed){
		    	   		    		  vm.backToOriginalValue(vm.radioButtonValue);
		    	   		    	  }
		    	   		   		}
		    	   		    });
    		   		}
    		   	}
    		   	vm.backToOriginalValue = function(value){
    		   		angular.forEach($scope.birthCertificate.sections,function(data, key) {
    		   			if(data.questions && key == vm.currentIndex){
    		   				angular.forEach(data.questions, function(dataQuestions,key){
    		   					if(dataQuestions.birthCertificate_1b) {
    		   						if(value == vm.app.GLOBAL.LOCAL_REGISTRY){
    		   							dataQuestions.birthCertificate_1b.answer = vm.app.GLOBAL.NATIONAL_REGISTRY;
    		   						 }else if(value == vm.app.GLOBAL.NATIONAL_REGISTRY){
    		   							dataQuestions.birthCertificate_1b.answer = vm.app.GLOBAL.LOCAL_REGISTRY;
    		   						}
    		   					}
    		   				});
    		   			}
    		   		});
    		   		$mdDialog.cancel();
    	    	}
    	    	vm.changeRadioValue = function() {
    	    		vm.yesToProceed = true;
    	    		angular.forEach($scope.birthCertificate.sections,function(data, key) {
    		   			if(data.questions && key == vm.currentIndex){
    		   				angular.forEach(data.questions, function(dataQuestions,key){
    		   					if(dataQuestions.birthCertificate_1a) {
    		   						dataQuestions.birthCertificate_1a = {};
    		   					}
    		   					if(dataQuestions.birthCertificate_2) {
    		   						dataQuestions.birthCertificate_2 = {};
    		   					}
    		   					if(dataQuestions.birthCertificate_2a) {
    		   						dataQuestions.birthCertificate_2a = {};
    		   					}
    		   					if(dataQuestions.birthCertificate2b) {
    		   						dataQuestions.birthCertificate2b = {};
    		   					}
    		   					if(dataQuestions.birthCertificate_3) {
    		   						dataQuestions.birthCertificate_3 = {};
    		   					}
    		   					if(dataQuestions.birthCertificate_3aYes) {
    		   						dataQuestions.birthCertificate_3aYes = {};
    		   					}
    		   					if(dataQuestions.birthCertificate_3aNo) {
    		   						dataQuestions.birthCertificate_3aNo = {};
    		   					}
    		   					if(dataQuestions.birthCertificate_4) {
    		   						dataQuestions.birthCertificate_4 = {};
    		   					}
    		   					if(dataQuestions.birthCertificate_5) {
    		   						dataQuestions.birthCertificate_5 = {};
    		   					}
    		   					if(dataQuestions.birthCertificate_6) {
    		   						dataQuestions.birthCertificate_6 = {};
    		   					}
    		   				});
    		   			}
    		   		});
    	    		$mdDialog.cancel();
    	    	}
    	    	vm.birthInit = function(){
    	    		vm.radioButtonFlag = [];
    	    		angular.forEach($scope.birthCertificate.sections,function(data, key) {
    		   			if(data.questions){
    		   				angular.forEach(data.questions, function(dataQuestions,index){
    		   					if(dataQuestions.birthCertificate_1b != undefined && dataQuestions.birthCertificate_1b != null) {
    		   						vm.radioButtonFlag[key] = true;
    		   					}
    		   					else{
    		   						vm.radioButtonFlag[key] = false;
    		   					}
    		   				});
    		   			}
    		   		});
    	    		
    	    	}
    	    	//vm.birthInit();
    	    	$scope.timzoneFormat = function(date){
    	    		 var dt = new Date(date);
	                 // 'undo' the timezone offset again (so we end up on the original date again)
	                 dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
	                 return dt;
    	    	};
    	    	
    	    	//It will get triggered while exiting from current state
    	    	$scope.$on('$destroy', function() {
    	    		if(!$scope.isreadonly && $scope.isOwner) {
    	    			$scope.saveBirthCertificateFormDetails();
    		   		}
    	    		$rootScope.warningPopup.show = false;   
    	    		$scope.uploadedFile = false;
    	        });
    	        
    	    	//To open the confirmation pop up for remove the additional interview or records
    	    	vm.deleteIndex = function(event,id,index,isInterview){
					$scope.indexVal = index;
					$scope.isInterview = isInterview;
					$scope.interviewFlag = false;
					$scope.recordFlag = false;
					$mdDialog.show({
						contentElement: angular.element('#'+id),
						targetEvent: event,
						clickOutsideToClose: false,
						escapeToClose: false,
						onRemoving: function (event, removePromise) {
							vm.closeCancel();
						}
					});
    	    	};
    	       	
    	    	//To remove the interview content form the form data
    	       	vm.deleteInterviewConfirm = function(index){
    	       		$scope.deleteLoader.flag = true;
    	       		if($scope.birthCertificate.sections.length == 1){
      		  			$scope.openAccordionIndex = 0;
      		  		}
    	       		$scope.birthCertificate.sections[$scope.openAccordionIndex].questions[0].birthCertificate2b.interviews.splice(index,1);
    	       		vm.closeCancel();
    	             $scope.enableDeleteButton();
    	       	};
    	       	
    	       	//To remove the records form the form data
    	       	vm.deleteRecordConfirm = function(index){
    	       		$scope.deleteLoader.flag = true;
    	       		if($scope.birthCertificate.sections.length == 1){
      		  			$scope.openAccordionIndex = 0;
      		  		}
    	       		$scope.birthCertificate.sections[$scope.openAccordionIndex].questions[0].birthCertificate_3aYes.records.splice(index,1);
    	       	    vm.closeCancel();
    	       	    $scope.enableDeleteButton();
    	       	};   	       	    	  
    	    	//It will get triggered while exiting from current state
    	    	$scope.$on('$destroy', function() {
                    if(!$scope.isreadonly && $scope.isOwner) {
    	    			$scope.saveBirthCertificateFormDetails();
    	    		}
                    $rootScope.warningPopup.show = false;
                    $scope.uploadedFile = false;
    	        });
    }
})();