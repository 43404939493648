(function () {
  "use strict";

  angular.module("lariAidApp").controller("LegacyLariController", LegacyLariController);

  LegacyLariController.$inject = [
    "$scope",
    "$state",
    "$stateParams",
    "$mdSidenav",
    "$element",
    "APP",
    "tabservice",
    "$mdMedia",
    "$sessionStorage",
    "$cookies",
    "LegacyLariService",
    "$mdDialog",
    "CommonService",
    "$q",
    "communicationService",
    "$timeout",
    "StorageDelete",
    "notifications",
    "$http",
    "BASE_URL"
  ];

  function LegacyLariController(
    $scope,
    $state,
    $stateParams,
    $mdSidenav,
    $element,
    APP,
    tabservice,
    $mdMedia,
    $sessionStorage,
    $cookies,
    LegacyLariService,
    $mdDialog,
    CommonService,
    $q,
    communicationService,
    $timeout,
    StorageDelete,
    notifications,
    $http,
    BASE_URL
  ) {
    var vm = this;
    var requestListTotalLength = 0;
    var clickingTimerRequest;

    vm.APP = APP;
    vm.state = $state;
    vm.currentState = vm.state.current.name;
    vm.user = CommonService.getCookieUser();
    vm.loggedUserRole = vm.user.loggedUserRole;
    vm.loadingListData = false;
    vm.requestFilterObj = {};
    vm.requestFileObj = {};
    vm.requestList = [];
    vm.media = $mdMedia;
    vm.search = true;
    vm.prospectId = "";
    vm.endless = vm.APP.GLOBAL.ENDLESS;
    vm.noResult = false;

    vm.getRequestList = function (type) {
      if (!vm.media("gt-sm")) {
        vm.loadingRequestData = true;
      }
      clearTimeout(clickingTimerRequest);
      clickingTimerRequest = setTimeout(function () {
        if (type != vm.endless) {
          vm.requestList = [];
        }
        var data = LegacyLariService.getRequestLists(vm.requestFilterObj);
        data.then(
          function (response) {
            vm.loadingRequestData = false;
            if (response && !angular.equals({}, response)) {
              vm.prospectId = response.prospectId;
              if (vm.prospectId == null) vm.noResult = true;
              angular.forEach(response.documents, function (value, key) {
                if (vm.requestList == null) {
                  vm.requestList = [];
                  vm.requestList.push(value);
                } else {
                  vm.requestList.push(value);
                }
              });
            } else if (angular.isUndefined(response)) {
              vm.loadingRequestData = false;
            }
          },
          function (reason) {
            vm.noResult = true;
            vm.loadingRequestData = false;
            if (reason.data.statusCode == 404) {
              notifications.showError({ message: vm.APP.LEGACYLARI.NO_EBIS_ID_FOUND });
            } else if (reason.data.statusCode == 409) {
              notifications.showError({ message: vm.APP.LEGACYLARI.UNABLE_TO_CONNECT_LEGACY_LARI_SYSTEM });
            } else if (reason.data.statusCode == 500) {
              notifications.showError({ message: vm.APP.LEGACYLARI.INTERNAL_SERVER_ERROR });
            }
            return reason;
          }
        );
      }, 200);
    };

    vm.searchRequest = function (requestSearchKey) {
      vm.requestFilterObj.searchKey = requestSearchKey;
      if (requestSearchKey.length > 3) {
        vm.loadingRequestData = true;
        vm.search = false;
        vm.noResult = false;
        vm.getRequestList();
      } else {
        vm.loadingRequestData = false;
        vm.noResult = false;
        vm.search = true;
      }
    };

    vm.downloadFile = function (fileType, index) {
      vm.loadingRequestData = true;
      vm.requestFileObj = vm.requestList[index];
      vm.requestFileObj.prospectId = vm.prospectId;
      var queryParams = {
        prospectId: vm.prospectId,
        documentId: vm.requestFileObj.id,
        fileTypeId: vm.requestFileObj.fileTypeId
      };

      var paramString = Object.entries(queryParams)
        .map(function (entry) {
          return entry[0] + "=" + entry[1];
        })
        .join("&");
      $http.get(BASE_URL + "api/getDocumentData?" + paramString, { responseType: "arraybuffer" }).then(
        function successCallback(response) {
          var contentType = response.headers("Content-Type");
          var contentDisposition = response.headers("Content-Disposition");
          var link = document.createElement("a");
          link.target = "_blank";

          // if there is no fileName header & the content type is JSON, this will return a link
          if (!contentDisposition && contentType === "application/json") {
            var decoded = new TextDecoder().decode(response.data);
            var body = JSON.parse(decoded);
            link.href = body.link;
          } else {
            var file = new Blob([response.data], { type: contentType });
            link.href = window.URL.createObjectURL(file);
            var header = response.headers()["content-disposition"];
            var regex = /.*filename="(.+)"$/i.exec(header || "");
            link.download = regex ? regex[1] : vm.requestFileObj.fileName;
          }

          link.click();
          vm.loadingRequestData = false;
          $q.resolve(fileURL);
        },
        function errorCallback(response) {
          if (response.status == 404) {
            notifications.showError({ message: vm.APP.LEGACYLARI.NO_DOCUMENT_FOUND });
          } else if (response.status == 409) {
            notifications.showError({ message: vm.APP.LEGACYLARI.ERROR_DOWNLOADING_DOCUMENT });
          } else if (response.status == 500) {
            notifications.showError({ message: vm.APP.LEGACYLARI.INTERNAL_SERVER_ERROR });
          } else if (response.status == 400) {
            notifications.showError({ message: vm.APP.LEGACYLARI.BAD_REQUEST });
          }
          vm.loadingRequestData = false;
        }
      );
    };
  }
})();
