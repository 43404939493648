(function () {
  "use strict";

  angular.module("lariAidApp").service("LibraryEntityService", LibraryEntityService);

  LibraryEntityService.$inject = ["$resource", "restService", "$q", "$rootScope", "$http", "BASE_URL"];

  function LibraryEntityService($resource, restService, $q, $rootScope, $http, BASE_URL) {
    this.getEntityTypeList = function () {
      var deferred = $q.defer();
      var url = "entity-profiles/status/count";
      var data = restService.restCall("", url, "GET");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    var deferredEntity = $q.defer();
    this.getAllEntityProfiles = function (filterObj) {
      if (deferredEntity != undefined) {
        deferredEntity.resolve();
        deferredEntity = $q.defer();
      }
      var url = BASE_URL + "api/approved-entity-profiles";
      var promise = $http.post(url, filterObj, { timeout: deferredEntity.promise }).then(function (response) {
        return response.data;
      });

      return promise;
    };
  }
})();
