(function() {
    'use strict';
    
    var lariDevApp = angular
    .module('lariAidApp')
    lariDevApp.directive('investigationComment', function()
    	{
	    	return {
				restrict : 'AE',				
				templateUrl: 'app/investigation/prospects/profile/investigation-form/comment-directive/investigationform.comment.html',
				controller: 'InvestigationFormCommentController',
				scope :{
					modelName : '=modelValue',
					cmtId : '=commentId',
					formId : '@',
					questionKey : '@',
					sectionId : '@',
					reqValue : '=reqVal',
					readOnly : '@',
					indexval : '@',
					parentindex : '@'
				},
				link: function(scope, element, attrs, ngModel) {
					if(scope.readOnly == 'false'){
						if(!scope.reqValue){
							if (scope.modelName && !angular.equals(scope.modelName,{})){
								scope.showComment = true;
							} else {
								scope.showComment = false;
							}
						}
					} else{
						if(scope.modelName && !angular.equals(scope.modelName,{})){
							scope.showDisableComment = true;
						} else {
							scope.showDisableComment = false;
						}
					}
					
				}
			};
    	});
    
})();