(function() {
	'use strict';

	angular.module('lariAidApp').directive('commonSearch', commonSearch);
	//commonSearch.inject['$http'];
	
	function commonSearch() {
		return {	
		  scope: {
		    callback: '&searchCallback'
		  },
		  link: function(scope, element, attrs) {
		 var typingTimer;
		 element.bind('keydown' , function(event){
		 clearTimeout(typingTimer);
		 typingTimer = setTimeout(function(){
		 scope.callback({ $event: event });
		 },1000);
		 });
		    }
		};
	}
})();