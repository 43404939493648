(function() {
    'use strict';

    angular
        .module('lariAidApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('error', {
                parent: 'app',
                url: '/error',
                data: {
                    authorities: [],
                    pageTitle: 'error.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/layouts/error/error.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        if(localStorage && localStorage.getItem('lari_currentVersion') != null){
                           	$translatePartialLoader.addPart('error.json?version='+localStorage.getItem('lari_currentVersion'));
                           }else{
                           	$translatePartialLoader.addPart('error.json?timestamp='+new Date().getTime());
                           }
                        return $translate.refresh();
                    }]
                }
            })
            .state('login', {
                parent: 'app',
                url: '/',
                data: {
                    authorities: []
                },
                views: {
                    'fillPage@': {
                        templateUrl: 'app/components/login/login.html',
                        controller:  'LoginController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        if(localStorage && localStorage.getItem('lari_currentVersion') != null){
                           	$translatePartialLoader.addPart('login.json?version='+localStorage.getItem('lari_currentVersion'));
                           }else{
                           	$translatePartialLoader.addPart('login.json?timestamp='+new Date().getTime());
                           }
                        return $translate.refresh();
                    }],
                       'redirect' : ['Principal', '$state', '$timeout', function(Principal, $state, $timeout){
                           $timeout(function(){
                               //if ($cookies.getObject('sessionDetails') && $cookies.getObject('sessionDetails').firstName != null && $cookies.getObject('sessionDetails').firstName != ''){
                               if(Principal.isAuthenticated()) {
                                   $state.go('investigations');
                                   return true;
                               } else {
                                   $state.go('login');
                                   return false;
                               }
                           }, 700)
                        }]
                }
            })
             .state('accessdenied', {
                parent: 'app',
                url: '/',
                data: {
                    authorities: []
                },
                views: {
                    'fillPage@': {
                        templateUrl: 'app/components/login/login.html',
                        controller:  'LoginController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    	if(localStorage && localStorage.getItem('lari_currentVersion') != null){
                           	$translatePartialLoader.addPart('error.json?version='+localStorage.getItem('lari_currentVersion'));
                           }else{
                           	$translatePartialLoader.addPart('error.json?timestamp='+new Date().getTime());
                           }
                        return $translate.refresh();
                    }]
                }
            })
            ;
    }
})();