(function () {
  "use strict";

  angular.module("lariAidApp").factory("Auth", Auth);

  Auth.$inject = [
    "$rootScope",
    "$cookies",
    "$state",
    "$sessionStorage",
    "$q",
    "$translate",
    "Principal",
    "AuthServerProvider",
    "Account",
    "Register",
    "Activate",
    "Password",
    "PasswordResetInit",
    "PasswordResetFinish",
    "communicationService",
    "StorageDelete",
    "restService",
    "$location"
  ];

  function Auth(
    $rootScope,
    $cookies,
    $state,
    $sessionStorage,
    $q,
    $translate,
    Principal,
    AuthServerProvider,
    Account,
    Register,
    Activate,
    Password,
    PasswordResetInit,
    PasswordResetFinish,
    communicationService,
    StorageDelete,
    restService,
    $location
  ) {
    var service = {
      activateAccount: activateAccount,
      authorize: authorize,
      changePassword: changePassword,
      createAccount: createAccount,
      getPreviousState: getPreviousState,
      login: login,
      logout: logout,
      loginWithToken: loginWithToken,
      resetPasswordFinish: resetPasswordFinish,
      resetPasswordInit: resetPasswordInit,
      resetPreviousState: resetPreviousState,
      storePreviousState: storePreviousState,
      updateAccount: updateAccount,
      forgotPassword: forgotPassword
    };

    return service;

    function activateAccount(key, callback) {
      var cb = callback || angular.noop;

      return Activate.get(
        key,
        function (response) {
          return cb(response);
        },
        function (err) {
          return cb(err);
        }.bind(this)
      ).$promise;
    }

    function authorize(force) {
      return Principal.identity(force).then(function () {
        var isAuthenticated = Principal.isAuthenticated();
        // an authenticated user can't access to login and register pages
        if (
          isAuthenticated &&
          $rootScope.toState.parent === "account" &&
          ($rootScope.toState.name === "login" || $rootScope.toState.name === "register")
        ) {
          $state.go("home");
        }

        // recover and clear previousState after external login redirect (e.g. oauth2)
        if (isAuthenticated && !$rootScope.fromState.name && getPreviousState()) {
          var previousState = getPreviousState();
          resetPreviousState();
          $state.go(previousState.name, previousState.params);
        }

        if (
          $rootScope.toState.data.authorities &&
          $rootScope.toState.data.authorities.length > 0 &&
          !Principal.hasAnyAuthority($rootScope.toState.data.authorities)
        ) {
          if (isAuthenticated) {
            // user is signed in but not authorized for desired state
            $state.go("accessdenied");
          } else {
            // user is not authenticated. stow the state they wanted before you
            // send them to the login service, so you can return them when you're done
            if ($rootScope.toState.url !== "/") {
              storePreviousState($rootScope.toState.name, $rootScope.toStateParams);
            }

            // now, send them to the signin state so they can log in
            $state.go("accessdenied").then(function () {
              //LoginService.open();
              $state.go("login");
            });
          }
        }
      });
    }

    function changePassword(newPassword, callback) {
      var cb = callback || angular.noop;

      return Password.save(
        newPassword,
        function () {
          return cb();
        },
        function (err) {
          return cb(err);
        }
      ).$promise;
    }

    function createAccount(account, callback) {
      var cb = callback || angular.noop;

      return Register.save(
        account,
        function () {
          return cb(account);
        },
        function (err) {
          this.logout();
          return cb(err);
        }.bind(this)
      ).$promise;
    }

    function login() {
      var cb = angular.noop;
      var deferred = $q.defer();
      var currentUrl = $location.absUrl();
      var res = currentUrl.split("=");
      if (res.length > 1) {
        currentUrl = res.slice(-1).pop();
        res = currentUrl.split("%");

        AuthServerProvider.login(res[0])
          .then(function (response) {
            // response.data has the idtoken
            Principal.identity(true).then(function (account) {
              // After the login the language will be changed to
              // the language selected by the user during his registration
              if (account !== null) {
                $translate.use(account.langKey).then(function () {
                  $translate.refresh();
                });
              }
              var sessionDetails = {
                firstName: account.firstName,
                lastName: account.lastName,
                authorities: account.authorities,
                langKey: account.langKey
              };
              $cookies.putObject("sessionDetails", sessionDetails);
              deferred.resolve(response);
            });
          })
          .catch(
            function (err) {
              this.logout();
              deferred.reject(err);
              return cb(err);
            }.bind(this)
          );
      }

      return deferred.promise;
    }
    function loginWithToken(jwt, rememberMe) {
      return AuthServerProvider.loginWithToken(jwt, rememberMe);
    }

    function logout(isLoggedOut, isTokenNotAvailable) {
      if (isTokenNotAvailable) {
        AuthServerProvider.logout(false);
      } else {
        AuthServerProvider.logout(isLoggedOut);
      }
      Principal.authenticate(null);
      $cookies.remove("sessionDetails");
      //$cookies.remove('NG_TRANSLATE_LANG_KEY');
      $state.go("login");

      //Clearing session storage values
      StorageDelete.deleteInvestigation();
      StorageDelete.deleteUser();
      StorageDelete.deleteLibrary();
      StorageDelete.deleteSessionValue();
      communicationService.setData("isLoggedIn", false);
      communicationService.setData("timeLastFocus", undefined);
    }

    function resetPasswordFinish(keyAndPassword, callback) {
      var cb = callback || angular.noop;

      return PasswordResetFinish.save(
        keyAndPassword,
        function () {
          return cb();
        },
        function (err) {
          return cb(err);
        }
      ).$promise;
    }

    function resetPasswordInit(mail, callback) {
      var cb = callback || angular.noop;

      return PasswordResetInit.save(
        mail,
        function () {
          return cb();
        },
        function (err) {
          return cb(err);
        }
      ).$promise;
    }

    function updateAccount(account, callback) {
      var cb = callback || angular.noop;

      return Account.save(
        account,
        function () {
          return cb(account);
        },
        function (err) {
          return cb(err);
        }.bind(this)
      ).$promise;
    }

    function getPreviousState() {
      var previousState = $sessionStorage.previousState;
      return previousState;
    }

    function resetPreviousState() {
      delete $sessionStorage.previousState;
    }

    function storePreviousState(previousStateName, previousStateParams) {
      var previousState = { name: previousStateName, params: previousStateParams };
      $sessionStorage.previousState = previousState;
    }

    function forgotPassword() {
      var deferred = $q.defer();
      var url = "forget-password-url";
      var data = restService.restCall("", url, "GET");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    }
  }
})();
