(function () {
  "use strict";

  var lariDevApp = angular.module("lariAidApp");
  lariDevApp.controller("ProfileController", ProfileController);

  ProfileController.$inject = [
    "$rootScope",
    "$scope",
    "$window",
    "$mdDialog",
    "$mdSidenav",
    "Principal",
    "$state",
    "$mdMedia",
    "APP",
    "ProspectProfileService",
    "$stateParams",
    "CommonService",
    "$element",
    "$sessionStorage",
    "InvestigationService",
    "$timeout",
    "$q",
    "BASE_URL",
    "$cookies"
  ];
  function ProfileController(
    $rootScope,
    $scope,
    $window,
    $mdDialog,
    $mdSidenav,
    Principal,
    $state,
    $mdMedia,
    APP,
    ProspectProfileService,
    $stateParams,
    CommonService,
    $element,
    $sessionStorage,
    InvestigationService,
    $timeout,
    $q,
    BASE_URL,
    $cookies
  ) {
    var vm = this;
    $scope.mediaCheck = $mdMedia;
    vm.APP = APP;
    vm.downloadAPI = BASE_URL + vm.APP.INVESTIGATION.DOWNLOAD_API;
    vm.user = CommonService.getCookieUser().cookieUser;
    vm.investigation = $stateParams;
    vm.show = {};
    vm.show.flag = false;
    vm.list = [];
    vm.Investigator = "";
    var originatorEv;
    vm.assign = assign;
    vm.data = {};
    vm.assinerRepeat = false;
    vm.profileForm = {};
    vm.profile = {};
    vm.analystAssignerRepeat = false;
    vm.multipleForm = false;
    var qPromises = [];

    vm.userAssign = {};
    vm.userFilterObj = {};
    vm.userFilterObj.userRoleKey = [];

    vm.loggedUserRole = CommonService.getCookieUser().loggedUserRole;

    vm.lang = CommonService.getCookieLanguage();

    vm.noInvestigationFormExistForCountryAdmin = false;
    vm.test = {};
    vm.assignerObj = {};

    vm.showCloseCases = false;
    vm.showOpenCases = true;

    vm.listTotalLength = 0;
    vm.listFrom = 0;
    vm.startSet = 0;
    vm.size = 15;
    $sessionStorage.isView = false;
    $scope.isReassign = false;
    $scope.investigationFormId = null;
    $scope.unAssignerId = null;
    vm.deleteFormObj = {};
    vm.reopenFormObj = {};

    vm.profileDetailLoader = true;
    vm.profileDataLoader = true;
    vm.requestedList = [];
    $scope.byPassInvestigationFormId = null;
    $scope.assignerId = null;
    vm.goInvestigation = false;
    vm.rcCompleted = true;
    vm.openFormObj = {};

    vm.profileDetails = function (investigationId) {
      if (!isNaN(investigationId)) {
        vm.profileProspectData(investigationId);
        vm.profileInvestigationData(investigationId);
      } else {
        $rootScope.unauthorized.isUnauthorized = true;
      }
    };

    vm.profileProspectData = function (investigationId) {
      if (investigationId) {
        var data = ProspectProfileService.getProfileData(investigationId);
        data.then(
          function (response) {
            vm.profileDataLoader = false;
            //dateOfBirth split in response for spanish translation
            if (response.dateOfBirth != "" && response.dateOfBirth != null) {
              response.monthDob = response.dateOfBirth.substring(0, 3)
              response.dayDob = response.dateOfBirth.substring(3, 7)
              response.yearDob = response.dateOfBirth.substring(7, 12)
            }
            vm.profileData = response;
            $scope.updateProspectName(response.firstName, response.lastName);
          },
          function (reason) {
            return reason;
          }
        );
      }
    };

    vm.profileInvestigationData = function (investigationId) {
      if (investigationId) {
        var data = ProspectProfileService.getProfileInvestigationData(investigationId);
        data.then(
          function (response) {
            vm.profileDetailLoader = false;
            //openInvestigation Date seperation for language translation
            $scope.translateOpenInvestigation(response)
            //closedInvestigation Date seperation for language translation
            $scope.translateClosedInvestigation(response)
            vm.profile = response;
            vm.ebisDocumentsList(investigationId);
            vm.findInvestigationId();
          },
          function (reason) {
            if (reason.status == 409) {
              vm.profileDetailLoader = false;
              vm.isAnalystForm = false;
              vm.goInvestigation = true;
              var popupId = vm.APP.INVESTIGATION.INVESTIGATIONDELETEDPUPID;
              $mdDialog.show({
                contentElement: angular.element("#" + popupId),
                clickOutsideToClose: false,
                escapeToClose: false
              });
            }
          }
        );
      }
    };

    vm.ebisDocumentsList = function (investigationId) {
      if (investigationId) {
        vm.documentLoader = true;
        var data = ProspectProfileService.getEbisDocuments(investigationId);
        data.then(
          function (response) {
            vm.documentLoader = false;
            $scope.documents = [];
            $scope.documents = response;
          },
          function (reason) {
            return reason;
          }
        );
      }
    };

    vm.assignee = function (event, id, formtype) {
      vm.assignDisableFlag = true;
      vm.assinerRepeat = false;
      vm.analystAssignerRepeat = false;
      vm.isAnalystAssigned = false;
      if (formtype == "assignInvestigatorPopup") {
        vm.formType = "INVESTIGATOR_FORM";
      }
      if (formtype == "assignAnalystPopup") {
        vm.formType = "ANALYST_FORM";
      }
      vm.assignerObj.investigationId = vm.investigation.id;
      vm.assignerObj.formType = vm.formType;
      vm.assignerObj.assignerId = id;
      if ($scope.isReassign) {
        vm.assignerObj.unAssignerId = $scope.unAssignerId;
        vm.assignerObj.investigationFormId = $scope.investigationFormId;
        var data = ProspectProfileService.saveReAssignee(vm.assignerObj);
      } else {
        var data = ProspectProfileService.saveAssignee(vm.assignerObj);
      }
      data.then(
        function (response) {
          vm.show.flag = true;
          vm.profileDetails(vm.investigation.id);
          $mdDialog.hide();
        },
        function (reject) {
          vm.assinerRepeat = true;
          if (reject.data.description != vm.APP.INVESTIGATION.ALREADR_ASSIGNED && formtype == "assignAnalystPopup") {
            vm.analystAssignerRepeat = true;
          }
          if (reject.data.description == vm.APP.INVESTIGATION.ALREADR_ASSIGNED) {
            vm.isAnalystAssigned = true;
          }
        }
      );
    };
    function assign(result, ev, id) {
      $mdDialog.hide(result);
      vm.test = result;
      vm.data = angular.copy(vm.test);
      vm.assignDisableFlag = false;
      $mdDialog.show({
        contentElement: angular.element("#" + id),
        targetEvent: ev,
        clickOutsideToClose: false
      });
    }
    vm.searchAssign = function (searchKey) {
      vm.userFilterObj.searchKey = searchKey;
      if (searchKey.length > 1 || searchKey.length == 0) {
        vm.searchAnalystorInvestigator();
      }
    };

    vm.updateAssignUserFilterObjList = function (postVariable, modelName) {
      vm.userFilterObj.isInternalUser = 1;
      vm.userFilterObj[postVariable] = [];
      if (vm.userAssign[modelName] || vm.userFilter[modelName].id) {
        if (vm.userAssign[modelName].userRole == vm.APP.GLOBAL.INVESTIGATOR) {
          vm.userFilterObj.isInternalUser = 0;
        }

        if (vm.userAssign[modelName]) {
          vm.userFilterObj[postVariable].push(vm.userAssign[modelName].id);
        }
      }
    };

    vm.searchAnalystorInvestigator = function (type) {
      if (type != "endless") {
        $scope.assignInvestigatorVO = [];
      }
      if ($scope.assignInvestigatorVO && $scope.assignInvestigatorVO.length == 0) {
        vm.listFrom = 0;
        vm.listTotalLength = 0;
        vm.startSet = 0;
      } else if ($scope.assignInvestigatorVO.length > 0) {
        vm.listFrom = $scope.assignInvestigatorVO.length;
      }

      if (vm.listFrom < vm.listTotalLength || vm.listTotalLength == 0) {
        vm.assignInvestigatorLoader = true;
        vm.userFilterObj.from = vm.listFrom;
        vm.userFilterObj.size = vm.size;
        var data = ProspectProfileService.getUserList(vm.userFilterObj);
        data.then(function (response) {
          if (type != "endless") {
            $scope.assignInvestigatorVO = [];
          }
          if (response && !angular.equals({}, response) && response.totalCount > 0) {
            vm.assignInvestigatorLoader = false;
            vm.startSet++;
            vm.listTotalLength = response.totalCount;
            angular.forEach(response.investigationUserlist, function (boat, key) {
              if ($scope.assignInvestigatorVO == null) {
                $scope.assignInvestigatorVO = [];
                $scope.assignInvestigatorVO.push(boat);
              } else {
                $scope.assignInvestigatorVO.push(boat);
              }
            });
            vm.listFrom = $scope.assignInvestigatorVO.length;
            if (vm.listFrom == vm.listTotalLength) {
              vm.assignInvestigatorLoader = false;
              vm.noInvestigators = false;
              vm.noMoreInvestigators = true;
            }

            if (vm.listFrom <= vm.APP.GLOBAL.DEFAULT_SIZE) {
              vm.noMoreInvestigators = false;
            }
          } else if (angular.isUndefined(response.investigationUserlist)) {
            vm.assignInvestigatorLoader = false;
            vm.noInvestigators = true;
            vm.noMoreInvestigators = false;
          } else if (response && !angular.equals({}, response) && response.totalCount == 0) {
            vm.assignInvestigatorLoader = false;
            vm.noInvestigators = true;
            vm.noMoreInvestigators = false;
          }
        });
      } else {
        vm.assignInvestigatorLoader = false;
        vm.noMoreInvestigators = true;
        vm.noInvestigators = false;
      }
    };

    $scope.callListfun = function () {
      vm.searchAnalystorInvestigator("endless");
    };
    //Handling Reassign Investigation Form
    vm.openReAssignPopup = function (ev, id, role, invList) {
      $scope.isReassign = true;
      $scope.investigationFormId = invList.investigationFormId;
      $scope.unAssignerId = invList.assignerId;
      $scope.reassignFiratName = invList.assignerFirstName;
      $scope.reassignLastName = invList.assignerLastName;
      vm.openAssignPopup(ev, id, role, 1, $scope.isReassign);
    };

    vm.openAssignPopup = function (ev, id, role, isInitial, isreassign) {
      //Handling Reassign
      if (isreassign == undefined) {
        $scope.isReassign = false;
      }
      vm.assinerRepeat = false;
      var searchKey = null;
      var isExternalInvestigator = 1;
      if (role == "INVESTIGATOR") {
        role = vm.APP.GLOBAL.INVESTIGATOR;
        $scope.investigatorPopup = 1;
      } else if (role == "ANALYST") {
        $scope.investigatorPopup = 0;
      }
      if (isInitial == 1) {
        vm.userAssign.role = undefined;
        $scope.searchInvObj.assignInvestigatorSearchKey = "";
        $scope.searchInvObj = {};
      }
      if (isInitial == 0 && vm.userAssign.role != null) {
        role = vm.userAssign.role.userRole;
      }
      var data = ProspectProfileService.getRoleList();
      data.then(function (response) {
        vm.rolesList = response;
        vm.deletedIndex = [];
				//if role is country admin disable the analyst option
				angular.forEach(vm.rolesList,function(obj,index){
					if(vm.loggedUserRole === vm.APP.GLOBAL.ROLE_COUNTRY_ADMIN){
						if((obj.userRole === "ANALYST") || (obj.userRole == "COUNTRY ADMIN")){
							vm.deletedIndex.push(index);
						}
					}
					// remove the country admin option while open a assign analyst option
					if($scope.investigatorPopup == 0 && obj.userRole == "COUNTRY ADMIN"){
						vm.rolesList.splice(index,1);
					}
					});

					if(vm.deletedIndex.length > 0){
						angular.forEach(vm.deletedIndex,function(data){
							vm.rolesList.splice(data,1);
						});
					}
        angular.forEach(vm.rolesList, function (obj, index) {
          if (obj.userRole == role) {
            vm.userAssign.role = obj;
            vm.updateAssignUserFilterObjList("userRoleKey", "role");
            vm.searchAnalystorInvestigator();
          }
        });
      });
      $mdDialog.show({
        contentElement: angular.element("#" + id),
        targetEvent: ev,
        clickOutsideToClose: true
      });
    };

    $scope.searchInvObj = {};
    $scope.searchInvObj.assignInvestigatorSearchKey = "";
    vm.search = function () {
      $scope.searchInvObj.assignInvestigatorSearchKey = "";
      vm.userFilterObj.searchKey = "";
      $scope.assignInvestigatorVO = [];
    };

    vm.openDeletePopup = function (ev, id, formData) {
      //Check the form is active or not before open the form
      qPromises = [];
      qPromises.push(vm.checkActiveForm(formData.investigationFormId, ev, formData.investigationFormType));
      $q.all(qPromises).then(function (data) {
        vm.errorInvestigatorFlag = false;
        vm.errorFormCompletedFlag = false;
        if (formData.investigationFormType == vm.APP.INVESTIGATION.ANALYST_FORM) {
          vm.deleteFormObj.formType = vm.APP.INVESTIGATION.ANALYST_FORM;
          $scope.investigatorPopup = 0;
        }
        if (formData.investigationFormType == vm.APP.INVESTIGATION.INVESTIGATOR_FORM) {
          vm.deleteFormObj.formType = vm.APP.INVESTIGATION.INVESTIGATOR_FORM;
          $scope.investigatorPopup = 1;
        }

        vm.deleteFormObj.assignerId = formData.assignerId;
        vm.deleteFormObj.investigationId = vm.investigation.id;
        vm.deleteFormObj.investigationFormId = formData.investigationFormId;
        $mdDialog.show({
          contentElement: angular.element("#" + id),
          targetEvent: ev,
          clickOutsideToClose: true
        });
      });
    };

    vm.togglepopupLeft = buildToggler("popupLeft");
    vm.toggleRight = buildToggler("right");
    function buildToggler(componentId) {
      return function () {
        $mdSidenav(componentId).toggle();
      };
    }
    if (vm.investigation != null) {
      vm.profileDetails(vm.investigation.id);
    }

    vm.openInvestigationForm = function (ev, formType, isStatusUpdate) {
      vm.profileForm.investigationFormId = vm.profile.formId;
      $sessionStorage.selectedSectionIndex = 0;
      $sessionStorage.formType = formType;
      if (isStatusUpdate) {
        $state.go("profileForm", vm.profileForm);
      } else {
        //Check the form is active or not before open the form
        qPromises = [];
        qPromises.push(vm.checkActiveForm(vm.profileForm.investigationFormId, ev, formType));
        $q.all(qPromises).then(function (data) {
          $state.go("profileForm", vm.profileForm);
        });
      }
    };

    vm.openMenu = function ($mdOpenMenu, ev) {
      originatorEv = ev;
      $mdOpenMenu(ev);
    };

    $scope.cancel = function () {
      $mdDialog.hide();
    };

    vm.openClosedCases = function () {
      if (vm.showCloseCases) {
        vm.showCloseCases = false;
      } else {
        vm.showCloseCases = true;
      }
    };
    vm.openOpenedCases = function () {
      if (vm.showOpenCases) {
        vm.showOpenCases = false;
      } else {
        vm.showOpenCases = true;
      }
    };

    $scope.loadDocuments = function () {
      //TODO Need to call the service with the respective parameters and translate to the corresponding documents
      /*var data =  ProspectProfileService.getDocuments();
	    	data.then(function(response){
	    		$scope.documents = 	response;
	    		$scope.birthRecords = response.birthRecords;
	          	$scope.schoolRecords = response.schoolRecords;
	          	$scope.familyRecords = response.familyRecords;
	          	$scope.neighborhoodRecords = response.neighborhoodRecords;
	          	$scope.dnaRecords = response.dnaRecords;
	    	},function(reject){

	    	}*/

      //Sample Json to test the Accordion
      var obj = {
        count: "7",
        documentList: [
          {
            externalLink:
              "http://10.211.50.62:9024/credential-content/organizations/98c556b8-281e-45p2-8da1-2701f1043d4/asset/587751caea96c85ee4917343/binary",
            file: "Sample Document",
            user: "Williams"
          },
          {
            externalLink:
              "http://10.211.50.62:9024/credential-content/organizations/98c556b8-281e-45p2-8da1-2701f1043d4/asset/587751caea96c85ee4917343/binary",
            file: "Test Document",
            user: "Michael"
          }
        ]
      };
      $scope.documents = obj;
      $scope.birthRecords = obj.documentList;
      $scope.schoolRecords = obj.documentList;
      $scope.familyRecords = obj.documentList;
      $scope.neighborhoodRecords = obj.documentList;
      $scope.dnaRecords = obj.documentList;
      $scope.religiousRecords = obj.documentList;
    };

    vm.toggleLeft = toggleLeft("right");

    function toggleLeft(navID) {
      return function () {
        $mdSidenav(navID).toggle();
      };
    }

    vm.viewform = function (investigationFormId, ev, formType) {
      $sessionStorage.isView = false;
      $sessionStorage.selectedSectionIndex = 0;
      $sessionStorage.formType = formType;
      vm.profileForm.investigationFormId = investigationFormId;
      //Check the form is active or not before open the form
      qPromises = [];
      qPromises.push(vm.checkActiveForm(vm.profileForm.investigationFormId, ev, formType));
      $q.all(qPromises).then(function (data) {
        $state.go("profileForm", vm.profileForm);
      });
    };

    //Method to open OPEN ANALYST POPUP
    vm.openAnalystFormPopup = function (ev, id) {
      //Check the form is active or not before open the form
      qPromises = [];
      qPromises.push(
        vm.checkActiveForm(
          vm.profile.openInvestigationList[0].analystFormInProgressList[0].investigationFormId,
          ev,
          vm.APP.INVESTIGATION.ANALYST_FORM
        )
      );
      $q.all(qPromises).then(function (data) {
        if ((vm.investigationId = vm.profile.openInvestigationList != null)) {
          vm.investigationId = vm.profile.openInvestigationList[0].investigationId;
        }
        var data = InvestigationService.getCompletedInvestigatorNames(vm.investigationId);
        data.then(function (response) {
          vm.investigatorForm = response;
          vm.openAnalystForm = {};
          if (vm.investigatorForm.length > 0) {
            //length has been checked to verify that the response has only external investigators or with investigators.
            vm.selectFlag = false;
            $mdDialog.show({
              targetEvent: ev,
              contentElement: "#" + id,
              parent: angular.element(document.body)
            });
          } else {
            vm.openAnalystForm.analystForm = {};
            vm.openAnalystForm.analystForm.checked = true;
            vm.openSelectedForm(vm.openAnalystForm, ev);
          }
        });
      });
    };

    vm.cancel = function () {
      $mdDialog.hide();
    };

    vm.removeSelection = function () {
      if (vm.openAnalystForm.investigatorForm != undefined && vm.openAnalystForm.investigatorForm.id != null) {
        vm.openAnalystForm.investigatorForm = {};
      }
    };

    vm.removeCheckBox = function () {
      if (vm.openAnalystForm.analystForm != undefined && vm.openAnalystForm.analystForm.checked) {
        vm.openAnalystForm.analystForm.checked = false;
      }
    };

    vm.openSelectedForm = function (analystForm, ev) {
      if (analystForm == undefined) {
        vm.selectFlag = true;
      } else if (analystForm.investigatorForm != undefined && analystForm.investigatorForm.id != null) {
        $sessionStorage.isView = true;
        $sessionStorage.analystFormId =
          vm.profile.openInvestigationList[0].analystFormInProgressList[0].investigationFormId;
        $sessionStorage.formType = vm.APP.INVESTIGATION.INVESTIGATOR_FORM;
        vm.profileForm.investigationFormId = analystForm.investigatorForm.id;

        //Check the form is active or not before open the form
        qPromises = [];
        qPromises.push(
          vm.checkActiveForm(vm.profileForm.investigationFormId, ev, vm.APP.INVESTIGATION.INVESTIGATOR_FORM)
        );
        $q.all(qPromises).then(function (data) {
          $state.go("profileForm", vm.profileForm);
        });
      } else if (analystForm.analystForm.checked) {
        $sessionStorage.isView = false;
        $sessionStorage.selectedSectionIndex = 0;
        $sessionStorage.formType = vm.APP.INVESTIGATION.ANALYST_FORM;
        vm.profileForm.investigationFormId =
          vm.profile.openInvestigationList[0].analystFormInProgressList[0].investigationFormId;

        //Check the form is active or not before open the form
        qPromises = [];
        qPromises.push(vm.checkActiveForm(vm.profileForm.investigationFormId, ev, vm.APP.INVESTIGATION.ANALYST_FORM));
        $q.all(qPromises).then(function (data) {
          $state.go("profileForm", vm.profileForm);
        });
      }
    };

    //Set Investigator Form Id to copy content to analyst Form
    vm.setCopyFormId = function (investigatorObj, ev) {
      $sessionStorage.analystFormId =
        vm.profile.openInvestigationList[0].analystFormInProgressList[0].investigationFormId;
      vm.analystForm = {};
      vm.analystForm.investigatorForm = {};
      if (investigatorObj != null) {
        vm.analystForm.investigatorForm.id = investigatorObj.investigationFormId;
        vm.openSelectedForm(vm.analystForm, ev);
      }
    };

    vm.deleteConfirm = function () {
      vm.deleteDisableFlag = true;
      vm.errorInvestigatorFlag = false;
      vm.errorFormCompletedFlag = false;
      vm.isAnalystForm = false;
      var data = ProspectProfileService.deleteAssignee(vm.deleteFormObj);
      data.then(
        function (response) {
          vm.deleteDisableFlag = false;
          vm.profileDetails(vm.investigation.id);
          $mdDialog.hide();
        },
        function (reason) {
          if (vm.deleteFormObj.formType == vm.APP.INVESTIGATION.ANALYST_FORM) {
            vm.isAnalystForm = true;
          }
          if (reason.status == 409) {
            vm.deleteDisableFlag = false;
            vm.errorInvestigatorFlag = true;
            vm.errorMsg = reason.data.message;
          } else if (reason.status == 404) {
            vm.deleteDisableFlag = false;
            vm.errorFormCompletedFlag = true;
            vm.errorMsg = reason.data.message;
          }
        }
      );
    };

    /**
     * investigationFormId - Investigation Form Id or Analyst Form Id
     * formType - Investigator form, Analyst Form or RC Form
     */
    vm.getPdfReport = function (investigationFormId, formType, firstName, lastName, investigationId) {
      var formReportId;
      vm.profileDataLoader = true;
      vm.reportFileName = "";
      if (firstName != null) {
        vm.reportFileName += firstName + "_";
      }

      if (lastName != null) {
        vm.reportFileName += lastName + "_";
      }

      if (formType == vm.APP.INVESTIGATION.INVESTIGATOR_FORM) {
        formReportId = 0;
        vm.reportFileName += vm.APP.INVESTIGATION.INVESTIGATOR_REPORT_NAME;
      } else if (formType == vm.APP.INVESTIGATION.ANALYST_FORM) {
        formReportId = 1;
        vm.reportFileName += vm.APP.INVESTIGATION.ANALYST_REPORT_NAME;
      } else if (formType == vm.APP.INVESTIGATION.REVIEW_COMMITTEE_REPORT_NAME) {
        formReportId = 2;
        vm.reportFileName += vm.APP.INVESTIGATION.REVIEW_COMMITTEE_REPORT_NAME;
      }
      vm.reportFileName += vm.APP.GLOBAL.PDF;
      if (investigationFormId && investigationId) {
        var cookieUser = $cookies.getObject('sessionDetails');
				var url = BASE_URL + vm.APP.INVESTIGATION.REPORT_API+investigationFormId+"/"+formReportId+"/"+investigationId+"?user="+cookieUser.login;
        var timestamp = new Date().getTime();
        vm.reportUrl = url + "&timestamp=" + timestamp;
      }
      vm.profileDataLoader = false;
    };

    vm.getInvestigationFormId = function (openInv) {
      var investigationFormId;
      if (openInv) {
        if (openInv.analystFormInProgressList === undefined || openInv.analystFormInProgressList.length == 0) {
          investigationFormId = openInv.analystFormList[0].investigationFormId;
        } else if (openInv.analystFormList === undefined || openInv.analystFormList.length == 0) {
          investigationFormId = openInv.analystFormInProgressList[0].investigationFormId;
        }
      }
      return investigationFormId;
    };

    vm.sentToAnalyst = function (ev, id, formobj) {
      vm.reopenAnalystFlag = false;
      $mdDialog.show({
        contentElement: angular.element("#" + id),
        targetEvent: ev,
        clickOutsideToClose: false
      });
      if (formobj.investigationFormType == vm.APP.INVESTIGATION.ANALYST_FORM) {
        vm.reopenAnalystFlag = true;
      }
      if (formobj.investigationFormType == vm.APP.INVESTIGATION.INVESTIGATOR_FORM) {
        vm.reopenAnalystFlag = false;
      }

      vm.reopenFormObj.assignerId = formobj.assignerId;
      vm.reopenFormObj.investigationId = vm.investigation.id;
      vm.reopenFormObj.investigationFormId = formobj.investigationFormId;
      vm.reopenFormObj.formType = formobj.investigationFormType;
    };

    vm.sentToAnalystConfirm = function () {
      vm.sendBackDisableFlag = true;
      var data = ProspectProfileService.reopenAnalyst(vm.reopenFormObj);
      data.then(
        function (response) {
          vm.sendBackDisableFlag = false;
          vm.profileDetails(vm.investigation.id);
          $mdDialog.hide();
        },
        function (reason) {
          vm.sendBackDisableFlag = false;
        }
      );
    };

    vm.sentToRCConfirm = function (ev, id, investigationToStatus, isManagerreview) {
      $scope.investigationToStatus = investigationToStatus;
      $scope.isManagerreview = isManagerreview;
      $mdDialog.show({
        contentElement: angular.element("#" + id),
        targetEvent: ev,
        clickOutsideToClose: false
      });
    };
    /**
     * function to send back the investigation status to manger review
     * after receiving response redirect to the investigation listing page
     */
    vm.reopenForm = function (investigationId) {
      var data = ProspectProfileService.reopenForMangerReview(investigationId);
      data.then(function (response) {
        $scope.hideBtn = true;
        $state.go("investigations"); //redirect to investigation listing page
      });
    };

    vm.sentToRC = function () {
      vm.sendToRCDisableFlag = true;
      var data = ProspectProfileService.sentToRC($scope.investigationToStatus, vm.investigation.id);
      data.then(function (response) {
        vm.sendToRCDisableFlag = false;
        if ($scope.isManagerreview == 1) {
          vm.createRCSession();
        } else {
          vm.profileDetails(vm.investigation.id);
        }
        $mdDialog.hide();
      });
    };

    vm.sentToeBisConfirm = function (ev, id, investigationStatus, investigationFormId) {
      if (investigationFormId == undefined) {
        $scope.isRCResult = 1;
      } else {
        $scope.isRCResult = 0;
      }
      $scope.ebisInvestigationFormId = investigationFormId;
      $scope.investigationStatus = investigationStatus;
      $mdDialog.show({
        contentElement: angular.element("#" + id),
        targetEvent: ev,
        clickOutsideToClose: false
      });
    };
    vm.erroreBisFlag = false;
    vm.sentToeBis = function (ev) {
      var eBisObj = {};
      var isManagerReview;
      vm.eBisResultLoader = true;
      if ($scope.investigationStatus == vm.APP.INVESTIGATION_STATUS.MANAGER_REVIEW) {
        isManagerReview = 1;
      } else {
        isManagerReview = 0;
      }
      eBisObj.isManagerReview = isManagerReview;
      eBisObj.investigationId = vm.investigation.id;
      eBisObj.investigationFormId = $scope.ebisInvestigationFormId;

      var data = ProspectProfileService.sentToeBis(eBisObj);
      data.then(
        function (response) {
          vm.errorMsg = response.message;
          vm.sentToeBisSuccess(ev, "sentToeBisSuccess", response);
        },
        function (reason) {
          vm.erroreBisFlag = true;
          vm.errorMsg = reason.data.message;
          vm.sentToeBisSuccess(ev, "sentToeBisSuccess", reason);
        }
      );
    };

    vm.sentToeBisSuccess = function (ev, id, response) {
      vm.eBisResultLoader = false;
      $mdDialog.show({
        contentElement: angular.element("#" + id),
        targetEvent: ev,
        clickOutsideToClose: false
      });
    };

    vm.closeResult = function () {
      $mdDialog.cancel();
      if (!vm.erroreBisFlag) {
        vm.profileDetails(vm.investigation.id);
      }
    };

    //Update the Investigation Status
    vm.updateInvestigationStatus = function (formType, ev) {
      vm.openFormObj = {};
      vm.openFormObj.disabled = true;
      vm.assignObj = {};
      vm.assignObj.investigationId = vm.investigation.id;
      vm.assignObj.formType = formType;
      //No need to update the investigation Status
      vm.assignObj.assignerId = 0;

      //Check the form is active or not before open the form
      qPromises = [];
      qPromises.push(vm.checkActiveForm(vm.profile.formId, ev, formType));
      $q.all(qPromises).then(function (data) {
        var data = ProspectProfileService.updateInvestigationStatus(vm.assignObj);
        data.then(function (response) {
          vm.openInvestigationForm(ev, formType, true);
        });
      });
    };

    vm.openAnalystForm = function (ev) {
      vm.profileForm.investigationFormId =
        vm.profile.openInvestigationList[0].analystFormInProgressList[0].investigationFormId;
      $sessionStorage.selectedSectionIndex = 0;
      $sessionStorage.formType = vm.APP.INVESTIGATION.ANALYST_FORM;
      //Check the form is active or not before open the form
      qPromises = [];
      qPromises.push(vm.checkActiveForm(vm.profileForm.investigationFormId, ev, vm.APP.INVESTIGATION.ANALYST_FORM));
      $q.all(qPromises).then(function (data) {
        $state.go("profileForm", vm.profileForm);
      });
    };

    vm.createRCSession = function () {
      vm.createRCObj = {};
      vm.createRCObj.investigationId = vm.investigation.id;
      var data = ProspectProfileService.createRCSession(vm.createRCObj);
      data.then(function (response) {
        vm.profileDetails(vm.investigation.id);
      });
    };

    vm.openRCSession = function (RCResultObj, isOpen) {
      vm.RCSessionData = {};
      vm.RCSessionData.investigationRCId = RCResultObj.investigationRCId;
      vm.RCSessionData.investigationId = vm.investigation.id;
      vm.RCSessionData.isOpenCase = isOpen;
      $state.go("rcDetails", vm.RCSessionData);
    };

    vm.deleteRCSession = function (ev, id, RCResultObj) {
      $scope.investigationRCId = RCResultObj.investigationRCId;
      $scope.investigationId = vm.investigation.id;
      $mdDialog.show({
        contentElement: angular.element("#" + id),
        targetEvent: ev,
        clickOutsideToClose: false
      });
    };

    vm.deleteRCSessionConfirm = function () {
      vm.deleteRCDisableFlag = true;
      var data = ProspectProfileService.deleteRCSession($scope.investigationId, $scope.investigationRCId);
      data.then(function (response) {
        $mdDialog.hide();
        vm.deleteRCDisableFlag = false;
        vm.profileDetails(vm.investigation.id);
      });
    };

    vm.openByPassInvestigationConfirmPopup = function (ev, id, invObj) {
      $scope.byPassInvestigationFormId = invObj.investigationFormId;
      $scope.assignerId = invObj.assignerId;
      $mdDialog.show({
        contentElement: angular.element("#" + id),
        targetEvent: ev,
        clickOutsideToClose: false
      });
    };

    vm.byPassInvestigationForm = function () {
      vm.bypassSuccessFlag = false;
      vm.bypPassLoader = true;
      vm.assignerObj.investigationId = vm.investigation.id;
      vm.assignerObj.formType = vm.APP.INVESTIGATION.INVESTIGATOR_FORM;
      vm.assignerObj.assignerId = $scope.assignerId;
      vm.assignerObj.investigationFormId = $scope.byPassInvestigationFormId;
      var data = ProspectProfileService.byPassOpenInvestigationForm(vm.assignerObj);
      data.then(function (response) {
        vm.bypassSuccessFlag = true;
        vm.bypPassLoader = false;
        vm.noInvestigationFormExistForCountryAdmin = response.noInvestigationFormExistForCountryAdmin;
      });
    };

    vm.cancelBypass = function () {
      vm.bypassSuccessFlag = undefined;
      vm.bypPassLoader = undefined;
      $mdDialog.hide();
      if (!vm.noInvestigationFormExistForCountryAdmin) {
        vm.profileDetails(vm.investigation.id);
      }
      else {
        $state.go("investigations");
      }
    };

    //Method to get the reopen/reassign flag for investigation
    vm.getReopenFlag = function (investigationId) {
      if (vm.APP.INVESTIGATION.ENABLEREOPENFLAG == vm.APP.GLOBAL.TRUE) {
        vm.reopenInvestigator = false;
        vm.reopenAnalyst = false;
        vm.reopenInvestigatorInprogress = false;
        vm.reopenAnalystInprogress = false;
        var data = InvestigationService.getReopenFlag(investigationId);
        data.then(function (response) {
          var reopenFlagDetails = response;
          vm.reopenInvestigator = reopenFlagDetails.investigatorFormReOpen;
          vm.reopenAnalyst = reopenFlagDetails.analystFormReOpen;
          vm.reopenInvestigatorInprogress = reopenFlagDetails.investigatorFormStatus;
          vm.reopenAnalystInprogress = reopenFlagDetails.analystFormStatus;
        });
      }
    };

    //Check the user has assigned to both investigator/analyst form
    vm.findInvestigationId = function () {
      vm.multipleForm = false;
      if (vm.profile.isOwner == 1) {
        if (
          vm.profile.openInvestigationList.length > 0 &&
          vm.profile.openInvestigationList[0].analystFormInProgressList.length > 0 &&
          vm.profile.openInvestigationList[0].analystFormInProgressList[0].isOwner == 1
        ) {
          vm.analystFormId = vm.profile.openInvestigationList[0].analystFormInProgressList[0].investigationFormId;
          angular.forEach(vm.profile.openInvestigationList[0].investigatorFormInProgressList, function (data, index) {
            if (data.isOwner == 1) {
              vm.invFormId = data.investigationFormId;
              vm.multipleForm = true;
            }
          });
        }
      }
    };

    vm.openForm = function (formType, formId, ev) {
      $sessionStorage.formType = formType;
      if (formType == vm.APP.INVESTIGATION.INVESTIGATOR_FORM) {
        $sessionStorage.isView = false;
        vm.profileForm.investigationFormId = formId;
        //Check the form is active or not before open the form
        qPromises = [];
        qPromises.push(vm.checkActiveForm(vm.profileForm.investigationFormId, ev, formType));
        $q.all(qPromises).then(function (data) {
          $state.go("profileForm", vm.profileForm);
        });
      } else if (formType == vm.APP.INVESTIGATION.ANALYST_FORM) {
        $sessionStorage.isView = false;
        $sessionStorage.selectedSectionIndex = 0;
        vm.profileForm.investigationFormId = formId;
        //Check the form is active or not before open the form
        qPromises = [];
        qPromises.push(vm.checkActiveForm(vm.profileForm.investigationFormId, ev, formType));
        $q.all(qPromises).then(function (data) {
          $state.go("profileForm", vm.profileForm);
        });
      }
    };

    vm.checkActiveForm = function (formId, ev, formType) {
      var data = ProspectProfileService.getActiveInvestigationForm(formId);
      data.then(
        function (response) {},
        function (reason) {
          if (formType == vm.APP.INVESTIGATION.ANALYST_FORM) {
            vm.isAnalystForm = true;
          }
          var popupId = vm.APP.INVESTIGATION.INVESTIGATIONDELETEDPUPID;
          $mdDialog.show({
            contentElement: angular.element("#" + popupId),
            targetEvent: ev,
            clickOutsideToClose: false
          });
        }
      );
      return data;
    };

    vm.gokToInvestigation = function () {
      delete $sessionStorage.isView;
      $state.go("investigations");
    };

    vm.closeForm = function ($event) {
      $mdDialog.cancel();
      $state.go("investigations");
    };

    vm.completeInvestigationConfirmation = function (event, id, invObj) {
      vm.assignObj = {};
      vm.assignObj.formType = invObj.investigationFormType;
      vm.assignObj.assignerId = invObj.assignerId;
      vm.assignObj.investigationId = vm.investigation.id;
      vm.assignObj.investigationFormId = invObj.investigationFormId;

      vm.submitInvestigatorFlag = false;
      vm.submitInvestigatorFlagError = false;

      //Check the form is active or not before open the form
      qPromises = [];
      qPromises.push(vm.checkActiveForm(invObj.investigationFormId, event, invObj.investigationFormType));
      $q.all(qPromises).then(function (data) {
        $mdDialog.show({
          contentElement: angular.element("#" + id),
          targetEvent: event,
          clickOutsideToClose: false
        });
      });
    };

    vm.cancelSubmission = function () {
      vm.submitInvestigatorFlag = false;
      vm.submitInvestigatorLoader = false;
      vm.submitInvestigatorFlagError = false;
      $mdDialog.hide();
      vm.profileDetails(vm.investigation.id);
    };

    vm.completeInvestigation = function (assignerObj) {
      vm.submitInvestigatorFlag = false;
      vm.submitInvestigatorLoader = true;
      var data = ProspectProfileService.completeNonDRInvestigation(assignerObj);
      data.then(
        function (response) {
          vm.submitInvestigatorFlag = true;
          vm.submitInvestigatorLoader = false;
        },
        function (error) {
          vm.submitInvestigatorFlagError = true;
        }
      );
    };

    vm.cancelSubmission = function () {
      vm.submitInvestigatorFlag = false;
      vm.submitInvestigatorLoader = false;
      $mdDialog.hide();
      vm.profileDetails(vm.investigation.id);
    };
    //Function to enable assign to case button for completed RC investigation
    vm.isAllRcCompleted = function (openInvestigationList) {
      angular.forEach(openInvestigationList, function (value, key) {
        if (value.rcStatusKey != "RC_COMPLETED") {
          vm.rcCompleted = false;
          return rcCompleted;
        }
      });
    };
    $scope.translateRcMeetingDate = function (value) {
      if (value.rcDetailsList.length > 0) {
        angular.forEach(value.rcDetailsList, function (rcValue) {
          if (rcValue.rcMeetingDate != "" && rcValue.rcMeetingDate != null) {
            rcValue.monthRcMeetingDate = rcValue.rcMeetingDate.substring(0, 3)
            rcValue.dayRcMeetingDate = rcValue.rcMeetingDate.substring(3, 7)
            rcValue.yearRcMeetingDate = rcValue.rcMeetingDate.substring(7, 12)
          }
        });
      }

    }

    $scope.translateAnalystReportDate = function (value) {
      if (value.analystFormList.length > 0) {
        angular.forEach(value.analystFormList, function (analystValue) {
          if (analystValue.investigationFormDate != "" && analystValue.investigationFormDate != null) {
            analystValue.monthAnalystReportDate = analystValue.investigationFormDate.substring(0, 3)
            analystValue.dayAnalystReportDate = analystValue.investigationFormDate.substring(3, 7)
            analystValue.yearAnalystReportDate = analystValue.investigationFormDate.substring(7, 12)
          }
        });
      }
    }

    $scope.translateAnalystInprogressReportDate = function (value) {
      if (value.analystFormInProgressList.length > 0) {
        angular.forEach(value.analystFormInProgressList, function (analystInProgressValue) {
          if (analystInProgressValue.investigationFormDate != "" && analystInProgressValue.investigationFormDate != null) {
            analystInProgressValue.monthInprogressDate = analystInProgressValue.investigationFormDate.substring(0, 3)
            analystInProgressValue.dayInprogressDate = analystInProgressValue.investigationFormDate.substring(3, 7)
            analystInProgressValue.yearInprogressDate = analystInProgressValue.investigationFormDate.substring(7, 12)
          }
        });
      }
    }

    $scope.translateInvestigatorInprogressReportDate = function (value) {
      if (value.investigatorFormInProgressList.length > 0) {
        angular.forEach(value.investigatorFormInProgressList, function (inProgressValue) {
          if (inProgressValue.investigationFormDate != "" && inProgressValue.investigationFormDate != null) {
            inProgressValue.monthInprogressDate = inProgressValue.investigationFormDate.substring(0, 3)
            inProgressValue.dayInprogressDate = inProgressValue.investigationFormDate.substring(3, 7)
            inProgressValue.yearInprogressDate = inProgressValue.investigationFormDate.substring(7, 12)
          }
        });
      }
    }

    $scope.translateInvestigatorCompletedReportDate = function (value) {
      if (value.investigatorFormCompletedList.length > 0) {
        angular.forEach(value.investigatorFormCompletedList, function (completedValue) {
          if (completedValue.investigationFomCompeledDate != "" && completedValue.investigationFomCompeledDate != null &&
            completedValue.investigationFormDate != "" && completedValue.investigationFormDate != null) {
            completedValue.monthCompletedDate = completedValue.investigationFomCompeledDate.substring(0, 3)
            completedValue.dayCompletedDate = completedValue.investigationFomCompeledDate.substring(3, 7)
            completedValue.yearCompletedDate = completedValue.investigationFomCompeledDate.substring(7, 12)

            completedValue.monthInvestigationFormDate = completedValue.investigationFormDate.substring(0, 3)
            completedValue.dayInvestigationFormDate = completedValue.investigationFormDate.substring(3, 7)
            completedValue.yearInvestigationFormDate = completedValue.investigationFormDate.substring(7, 12)
          }
        });
      }
    }

    $scope.translateRemovedDate = function (value) {
      if (value.removedFormList.length > 0) {
        angular.forEach(value.removedFormList, function (removedValue) {
          if (removedValue.investigationFomCompeledDate != "" && removedValue.investigationFomCompeledDate != null &&
            removedValue.investigationFormDate != "" && removedValue.investigationFormDate != null) {
            removedValue.monthRemovedDate = removedValue.investigationFomCompeledDate.substring(0, 3)
            removedValue.dayRemovedDate = removedValue.investigationFomCompeledDate.substring(3, 7)
            removedValue.yearRemovedDate = removedValue.investigationFomCompeledDate.substring(7, 12)

            removedValue.monthinvestigationFormDate = removedValue.investigationFormDate.substring(0, 3)
            removedValue.dayinvestigationFormDate = removedValue.investigationFormDate.substring(3, 7)
            removedValue.yearinvestigationFormDate = removedValue.investigationFormDate.substring(7, 12)
          }
        });
      }
    }

    $scope.translateInvestigationFomCompeledDate = function (value) {
      if (value.investigatorFormCompletedList.length > 0) {
        angular.forEach(value.investigatorFormCompletedList, function (completedValue) {
          if (completedValue.investigationFomCompeledDate != "" && completedValue.investigationFomCompeledDate != null) {
            completedValue.monthInvestigationFomCompeledDate = completedValue.investigationFomCompeledDate.substring(0, 3)
            completedValue.dayInvestigationFomCompeledDate = completedValue.investigationFomCompeledDate.substring(3, 7)
            completedValue.yearInvestigationFomCompeledDate = completedValue.investigationFomCompeledDate.substring(7, 12)
          }
          if (completedValue.investigationFormDate != "" && completedValue.investigationFormDate != null) {
            completedValue.monthInvestigationFormDate = completedValue.investigationFormDate.substring(0, 3)
            completedValue.dayInvestigationFormDate = completedValue.investigationFormDate.substring(3, 7)
            completedValue.yearInvestigationFormDate = completedValue.investigationFormDate.substring(7, 12)

          }
        });
      }

    }

    $scope.translateOpenInvestigation = function (response) {
      if (response.openInvestigationList.length > 0) {
        angular.forEach(response.openInvestigationList, function (value) {
          if (value.investigationDateOpened != "" && value.investigationDateOpened != null) {
            value.monthInvestigationDateOpened = value.investigationDateOpened.substring(0, 3)
            value.dayInvestigationDateOpened = value.investigationDateOpened.substring(3, 7)
            value.yearInvestigationDateOpened = value.investigationDateOpened.substring(7, 12)
          }
          //methods to split date in response for spanish translation
          $scope.translateRcMeetingDate(value)
          $scope.translateAnalystReportDate(value)
          $scope.translateAnalystInprogressReportDate(value)
          $scope.translateInvestigatorInprogressReportDate(value)
          $scope.translateInvestigatorCompletedReportDate(value)
          $scope.translateRemovedDate(value)

        });
      }
    }

    $scope.translateClosedInvestigation = function (response) {
      if (response.closedInvestigationList.length > 0) {
        angular.forEach(response.closedInvestigationList, function (closedValue) {
          if (closedValue.investigationDateOpened != "" && closedValue.investigationDateOpened != null) {
            closedValue.monthInvestigationDateOpened = closedValue.investigationDateOpened.substring(0, 3)
            closedValue.dayInvestigationDateOpened = closedValue.investigationDateOpened.substring(3, 7)
            closedValue.yearInvestigationDateOpened = closedValue.investigationDateOpened.substring(7, 12)
          }
          if (closedValue.investigationResultStartTime != "" && closedValue.investigationResultStartTime != null) {
            closedValue.monthInvestigationResultStartTime = closedValue.investigationResultStartTime.substring(0, 3)
            closedValue.dayInvestigationResultStartTime = closedValue.investigationResultStartTime.substring(3, 7)
            closedValue.yearInvestigationResultStartTime = closedValue.investigationResultStartTime.substring(7, 12)

          }
          $scope.translateInvestigationFomCompeledDate(closedValue)
          $scope.translateAnalystReportDate(closedValue)
          $scope.translateRemovedDate(closedValue)

        });
      }
    }
  }
})();
