(function () {
  "use strict";

  angular.module("lariAidApp").factory("Principal", Principal);

  Principal.$inject = ["$q", "$location", "Account", "AuthServerProvider"];

  function Principal($q, $location, Account, AuthServerProvider) {
    var _identity,
      _authenticated = false;

    var service = {
      authenticate,
      hasAnyAuthority,
      hasAuthority,
      identity,
      isAuthenticated,
      isIdentityResolved
    };

    return service;

    function authenticate(identity) {
      _identity = identity;
      _authenticated = identity !== null;
    }

    function hasAnyAuthority(authorities) {
      if (!_authenticated || !_identity || !_identity.authorities) {
        return false;
      }

      for (var i = 0; i < authorities.length; i++) {
        if (_identity.authorities.indexOf(authorities[i]) !== -1) {
          return true;
        }
      }

      return false;
    }

    function hasAuthority(authority) {
      if (!_authenticated) {
        return $q.when(false);
      }

      return this.identity().then(
        function (_id) {
          return _id.authorities && _id.authorities.indexOf(authority) !== -1;
        },
        function () {
          return false;
        }
      );
    }

    function identity(force) {
      var deferred = $q.defer();

      if (force === true) {
        _identity = undefined;
      }

      // check and see if we have retrieved the identity data from the server.
      // if we have, reuse it by immediately resolving
      if (angular.isDefined(_identity)) {
        deferred.resolve(_identity);

        return deferred.promise;
      }

      // retrieve the identity data from the server, update the identity object, and then resolve.
      const match = /\?Authorization=(\d+)%.*$/i.exec($location.absUrl());
      if (!!match) {
        // using the match, find the id_token
        const tokenId = match[1];
        AuthServerProvider.login(tokenId)
          .then(() => {
            // remove the Authorization from the Address Bar. Due to AngularJs limitations,
            // we cannot set the $locationProvider to HTML5 mode (since we are serving the content
            // statically)
            const url = new URL(window.location.origin + "/" + window.location.hash);
            window.location.href = url.href;
          })
          .catch(getAccountCatch);
      } else {
        Account.get().$promise.then(getAccountThen).catch(getAccountCatch);
      }

      return deferred.promise;

      function getAccountThen(account) {
        _identity = account.data;
        _authenticated = true;
        deferred.resolve(_identity);
      }

      function getAccountCatch(e) {
        _identity = null;
        _authenticated = false;
        deferred.reject(e);
      }
    }

    function isAuthenticated() {
      return _authenticated;
    }

    function isIdentityResolved() {
      return angular.isDefined(_identity);
    }
  }
})();
