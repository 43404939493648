(function() {
    'use strict';

    angular
        .module('lariAidApp')
        .config(stateConfig);
    stateConfig.$inject = ['$stateProvider','APP'];
    function stateConfig($stateProvider,APP) {
    	$stateProvider
    	.state('rcHeader', {
            parent: 'profile',
            data: {
                authorities: APP.GLOBAL.USER_GROUP
            },
            params: {
            	investigationRCId:null
            },
            views: {
             'content@': {
                    templateUrl: 'app/investigation/prospects/profile/investigation-form/investigation.form.header.html',
                    controller: 'InvestigationFormHeaderController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    if(localStorage && localStorage.getItem('lari_currentVersion') != null){
                       	$translatePartialLoader.addPart('profile.json?version='+localStorage.getItem('lari_currentVersion'));
                       }else{
                       	$translatePartialLoader.addPart('profile.json?timestamp='+new Date().getTime());
                       }
                    return $translate.refresh();
                }]
            }
        })
    	.state('rcDetails', {
            parent: 'rcHeader',
            url: '/:investigationRCId/RC',
            data: {
                authorities: APP.GLOBAL.USER_GROUP
            },
            params: {
            	investigationRCId:null,
            	isOpenCase:null
            },
            views: {
             'invesForm': {
                    templateUrl: 'app/investigation/prospects/profile/rc/rc.details.html',
                    controller: 'RCDetailsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                	if(localStorage && localStorage.getItem('lari_currentVersion') != null){
                       	$translatePartialLoader.addPart('profile.json?version='+localStorage.getItem('lari_currentVersion'));
                       }else{
                       	$translatePartialLoader.addPart('profile.json?timestamp='+new Date().getTime());
                       }
                	if(localStorage && localStorage.getItem('lari_currentVersion') != null){
                       	$translatePartialLoader.addPart('rcDetails.json?version='+localStorage.getItem('lari_currentVersion'));
                       }else{
                       	$translatePartialLoader.addPart('rcDetails.json?timestamp='+new Date().getTime());
                       }
                    return $translate.refresh();
                }]
            }
        });
    }
})();