(function() {
    'use strict';

    var lariDevApp = angular.module('lariAidApp')
    lariDevApp.controller('RCDetailsController', RCDetailsController);

    RCDetailsController.$inject = ['$element', '$scope', '$window', '$mdSidenav', 'Principal', '$state', '$mdMedia', '$cookies', 'APP', 'RCDetailsService', '$stateParams', '$mdDialog', 'CommonService','$sessionStorage','$sce','notifications','$timeout'];

    function RCDetailsController($element, $scope, $window, $mdSidenav, Principal, $state, $mdMedia, $cookies, APP, RCDetailsService, $stateParams, $mdDialog, CommonService,$sessionStorage,$sce,notifications,$timeout) {

        var vm = this;
        vm.app = APP;
        $scope.mediaCheck = $mdMedia;
        vm.RCSessionData = $stateParams;
        vm.RCMeetingData = {};
        vm.investigation = {};
        vm.formProfile = {};
        vm.currentStateName = $state.current.name;
        vm.RCMeetingDataOldObj = {};
    	vm.loggedUserRole = CommonService.getCookieUser().loggedUserRole;

        
        vm.isCaseOpen = vm.RCSessionData.isOpenCase;
        
        if(vm.isCaseOpen != undefined && vm.isCaseOpen != null){
        	$sessionStorage.isOpenCase = vm.RCSessionData.isOpenCase;
        }
        else{
        	 vm.isCaseOpen = $sessionStorage.isOpenCase;
        }
        //$scope.resizingRCPage is to recalculate the window height
        $scope.resizingRCPage= true;
        vm.getRCSessionData = function() {
            $scope.loadingRCSessionData = true;
            $scope.investigationRCId = vm.RCSessionData.investigationRCId;
            $scope.investigationId = vm.RCSessionData.id;
            var data = RCDetailsService.getRCSessionData($scope.investigationId, $scope.investigationRCId);
            data.then(function (response) {
                if (response.prospectDob != "" && response.prospectDob != null) {
                    response.prospectDobMonth = response.prospectDob.substring(0, 3)
                    response.prospectDobDay = response.prospectDob.substring(3, 7)
                    response.prospectDobYear = response.prospectDob.substring(7, 12)
                }
                vm.RCMeetingData = response;
                vm.RCMeetingDataOldObj = angular.copy(response);
                $scope.loadingRCSessionData = false;
                $scope.resizingRCPage = false;
                $timeout(function(){
                	 $scope.resizingRCPage = true;
                },0);
                
                //$scope.timzoneFormat(vm.RCMeetingData.assignmentDate);
                $scope.updateProspectName(vm.RCMeetingData.prospectFirstName,vm.RCMeetingData.prospectLastName,vm.RCMeetingData.investigationType,vm.RCMeetingData.investigationId);
                vm.formProfile.firstName = vm.RCMeetingData.prospectFirstName;
                vm.formProfile.lastName = vm.RCMeetingData.prospectLastName;
                vm.formProfile.investigationType = vm.RCMeetingData.investigationType;
                $scope.rcDetailsId = vm.RCMeetingData.rcDetailsId;
                if (vm.RCMeetingData.maxDate != null) {
                    vm.RCMeetingData.maxDate = new Date(vm.RCMeetingData.maxDate);
                }
                if (vm.RCMeetingData.minDate != null) {
                    vm.RCMeetingData.minDate = new Date(vm.RCMeetingData.minDate);
                }
            });
        };

        vm.saveRCSessionData = function(ev) {
            if (vm.RCMeetingData.assignmentDate != null) {
            	vm.RCMeetingData.assignmentDate = $scope.timzoneFormat(vm.RCMeetingData.assignmentDate);
                vm.RCMeetingData.assignmentDate = moment(vm.RCMeetingData.assignmentDate).format('YYYY-MM-DD');
            }
            vm.RCMeetingData.id = vm.RCSessionData.investigationRCId;
            vm.RCMeetingData.investigationId = vm.RCSessionData.id;
            vm.RCMeetingData.rcDetailsId = $scope.rcDetailsId;
            var valid = vm.validation(vm.RCMeetingData);
            if (valid == 0) {
                vm.saveRCSessionDataCall();
            } else {
                var id;
                if (valid == 1) {
                    id = 'fillOutDate';
                } else if (valid == 2) {
                    id = 'tabledConfirm';
                } else if (valid == 3) {
                    id = 'fillResult';
                }
                $mdDialog.show({
                    contentElement: angular.element('#' + id),
                    targetEvent: ev,
                    clickOutsideToClose: false
                });
            }
        };

        vm.backProfile = function() {
            vm.investigation.id = vm.RCSessionData.id;
            $state.go("profile", vm.investigation);
        };

        $scope.timzoneFormat = function(date) {
            var dt = new Date(date);
            // 'undo' the timezone offset again (so we end up on the original date again)
            dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
            return dt;
        };

        vm.validation = function(RCMeetingDataObj) {
            var isvalid = 0;
            if (RCMeetingDataObj.assignmentDate == null && RCMeetingDataObj.ageResult != null && RCMeetingDataObj.identityResult != null) {
                isvalid = 1;
            } else if ((RCMeetingDataObj.ageResult != null && RCMeetingDataObj.ageResult.refTermKey == vm.app.REF_TERM.RC_TABLED) || (RCMeetingDataObj.identityResult != null && RCMeetingDataObj.identityResult.refTermKey == vm.app.REF_TERM.RC_TABLED)) {
                isvalid = 2;
            } else if ((RCMeetingDataObj.ageResult == null && RCMeetingDataObj.identityResult != null) || (RCMeetingDataObj.ageResult != null && RCMeetingDataObj.identityResult == null)) {
            	isvalid = 3;
            }
            return isvalid;
        };

        vm.closePopup = function() {
            $mdDialog.cancel();
        };

        vm.resultConfirm = function(ev) {
        	$mdDialog.hide();
            if (vm.RCMeetingData.ageResult == null) {
                vm.RCMeetingData.ageResult = {};
                vm.RCMeetingData.ageResult.refTermKey = vm.app.REF_TERM.RC_TABLED;
            } else {
                vm.RCMeetingData.ageResult.refTermKey = vm.app.REF_TERM.RC_TABLED;
            }
            if (vm.RCMeetingData.identityResult == null) {
                vm.RCMeetingData.identityResult = {};
                vm.RCMeetingData.identityResult.refTermKey = vm.app.REF_TERM.RC_TABLED;
            } else {
                vm.RCMeetingData.identityResult.refTermKey = vm.app.REF_TERM.RC_TABLED;
            }
            if (vm.RCMeetingData.assignmentDate == null && vm.RCMeetingData.ageResult != null && vm.RCMeetingData.identityResult != null) {
            	$mdDialog.show({
                    contentElement: angular.element('#fillOutDate'),
                    targetEvent: ev,
                    clickOutsideToClose: false
                });
            } else {
            	 vm.saveRCSessionDataCall();
            }
        };
        
        vm.objectCompare = function() {
        	var valid = false;
        	if(vm.RCMeetingDataOldObj.assignmentDate != vm.RCMeetingData.assignmentDate){
        		valid = true;
        	} 
        	if(!angular.equals(vm.RCMeetingData.ageResult,vm.RCMeetingDataOldObj.ageResult) ){
        		valid = true;
        	}
        	if(!angular.equals(vm.RCMeetingData.identityResult,vm.RCMeetingDataOldObj.identityResult)){
        		valid = true;
        	} 
        	if(vm.RCMeetingData.commentData != (vm.RCMeetingDataOldObj.commentData!= null && vm.RCMeetingDataOldObj.commentData) ){
        		valid = true;
        	}
        	
        	return valid;
        };
        
        vm.saveRCSessionDataCall = function() {
        	$mdDialog.hide();
        	var equal = vm.objectCompare();
        	if(equal){
        		 var data = RCDetailsService.saveRCSessionData(vm.RCMeetingData);
                 data.then(function(response) {
                     vm.investigation.id = vm.RCSessionData.id;
                     /* To show any success message while the user successful update the RC meeting details. */
                     notifications.showSuccess({message: vm.app.INVESTIGATION.RC_DETAILS_SAVE});
                     $state.go("profile", vm.investigation);
                 });
        	} else {
        		vm.investigation.id = vm.RCSessionData.id;
                $state.go("profile", vm.investigation);
        	}
           
        };
        
      //It will get triggered while exiting from current state
    	$scope.$on('$destroy', function() {
    		delete $sessionStorage.isOpenCase;
        });
    	
    	$scope.trustAsHTML = function(htmlContent){
	   		return $sce.trustAsHtml(htmlContent);
	   	}
    }
})();