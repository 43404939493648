(function () {
  "use strict";

  angular.module("lariAidApp").service("LibraryLocationService", LibraryLocationService);

  LibraryLocationService.$inject = ["$resource", "restService", "$q", "$rootScope", "$http", "BASE_URL"];

  function LibraryLocationService($resource, restService, $q, $rootScope, $http, BASE_URL) {
    this.loadAllLocationsByType = function (postObj, type) {
      var deferred = $q.defer();
      var url = "locations/" + type;
      var data = restService.restCall(postObj, url, "POST");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    var deferredLocation = $q.defer();
    this.getLocationLists = function (filterObj) {
      if (deferredLocation != undefined) {
        deferredLocation.resolve();
        deferredLocation = $q.defer();
      }

      var url = BASE_URL + "api/approved-location-mappings";
      var promise = $http.post(url, filterObj, { timeout: deferredLocation.promise }).then(function (response) {
        return response.data;
      });

      return promise;
    };
    this.validateLocationMapping = function(locationMappingObject) {
			var deferred = $q.defer();
    		var url = "location-mapping/validate";
    		var data = restService.restCall(locationMappingObject, url, 'POST');
    		data.$promise.then(function(response){
    			deferred.resolve(response);
    		},
    		function (error) {
    			deferred.reject('ERROR');
    		});
    		return deferred.promise;
		}

  }
})();
