(function() {
    'use strict';

    angular
        .module('lariAidApp')
        .service('LibraryService', LibraryService);

    LibraryService.$inject = ['$resource','restService','$q','$rootScope'];
    
    function LibraryService ($resource,restService,$q,$rootScope) {
    	
    	
    	/*this.loadRequestFilterList = function(filterObj) {
    		var deferred = $q.defer();
    		var url = "";
    		if(filterObj.statusKey && filterObj.statusKey.length > 0) {
    			filterObj.statusKey = undefined;
    		}
    		var data = restService.restCall(filterObj, url, 'POST');
    		data.$promise.then(function(response){
    			deferred.resolve(response);
    		},
    		function (error) {
    			deferred.reject('ERROR');
    		});
    		return deferred.promise;
    	};*/
    	
    	this.addNewEntity = function(entityObj){
    		var deferred = $q.defer();
    		var url = "entity-profiles";
    		var data = restService.restCall(entityObj, url, 'POST');
    		data.$promise.then(function(response){
    			deferred.resolve(response);
    		},
    		function (error) {
    			deferred.reject(error);
    		});
    		return deferred.promise;
    	};
    	
    	this.updateEntity = function(entityObj){
    		var deferred = $q.defer();
    		var url = "entity-profiles";
    		var data = restService.restCall(entityObj, url, 'PUT');
    		data.$promise.then(function(response){
    			deferred.resolve(response);
    		},
    		function (error) {
    			deferred.reject(error);
    		});
    		return deferred.promise;
    	}; 
    	
    	this.deleteEntity = function(entityId){
    		var deferred = $q.defer();
    		var url = "entity-profiles/"+entityId;
    		var data = restService.restCall('', url, 'DELETE');
    		data.$promise.then(function(response){
    			deferred.resolve(response);
    		},
    		function (error) {
    			deferred.reject('ERROR');
    		});
    		return deferred.promise;
    	}; 
    	
    	this.saveNewEntity = function(entityObj){
    		var deferred = $q.defer();
    		var url = "entity-profiles/request";
    		var data = restService.restCall(entityObj, url, 'POST');
    		data.$promise.then(function(response){
    			deferred.resolve(response);
    		},
    		function (error) {
    			deferred.reject('ERROR');
    		});
    		return deferred.promise;
    	}
    	
    	this.saveNewLocation = function(requestObj){
    		var deferred = $q.defer();
    		var url = "location-mappings/request";
    		var data = restService.restCall(requestObj, url, 'POST');
    		data.$promise.then(function(response){
    			deferred.resolve(response);
    		},
    		function (error) {
    			deferred.reject('error');
    		});
    		return deferred.promise;
    	}
    	
    	this.getEntityProfileById = function(entityId) {
    		var deferred = $q.defer();
    		var url = "entity-profiles/"+entityId;
    		var data = restService.restCall('', url, 'GET');
    		data.$promise.then(function(response){
    			deferred.resolve(response);
    		},
    		function (error) {
    			deferred.reject('ERROR');
    		});
    		return deferred.promise;
    	};
    	
    	this.getEntityNamesList = function(searchKey, entityTypeId){
    		var deferred = $q.defer();
    		var url = "approved-entity-profile-names/"+entityTypeId+"?searchKey="+searchKey;
    		var data = restService.restCall('', url, 'GETLIST');
    		data.$promise.then(function(response){
    			deferred.resolve(response);
    		},
    		function (error) {
    			deferred.reject('ERROR');
    		});
    		return deferred.promise;
    	}
    	
    	this.addNewLocation = function(saveLocObj){
    		var deferred = $q.defer();
    		var url = "location-mappings";
    		var data = restService.restCall(saveLocObj, url, 'POST');
    		data.$promise.then(function(response){
    			deferred.resolve(response);
    		},
    		function (error) {
    			deferred.reject(error);
    		});
    		return deferred.promise;
    	}
    	
    	this.editIndividualLocation = function(saveLocObj){
    		var deferred = $q.defer();
    		var url = "locations";
    		var data = restService.restCall(saveLocObj, url, 'PUT');
    		data.$promise.then(function(response){
    			deferred.resolve(response);
    		},
    		function (error) {
    			deferred.reject(error);
    		});
    		return deferred.promise;
    	}
    	
    	this.deleteLocationDetail = function(mapId){
    		var deferred = $q.defer();
    		var url = "location-mappings/"+mapId;
    		var data = restService.restCall('', url, 'DELETE');
    		data.$promise.then(function(response){
    			deferred.resolve(response);
    		},
    		function (error) {
    			deferred.reject(error);
    		});
    		return deferred.promise;
    	}
    	
    	this.getCountryObj = function(fromRequest){
	  		var countryObj = {};
    		countryObj.countryId = fromRequest.countryId;
    		countryObj.countryTxt = fromRequest.countryTxt;
    		return countryObj;
	  	};
	  	
	  	this.getProvinceObj = function(fromRequest){
	  		var provinceObj = {};
    		provinceObj.provinceId = fromRequest.provinceId;
    		provinceObj.provinceTxt = fromRequest.provinceTxt;
    		return provinceObj;
	  	}
	  	
	  	this.getCityObj = function(fromRequest){
	  		var cityObj = {};
    		cityObj.cityId = fromRequest.cityId;
    		cityObj.cityTxt = fromRequest.cityTxt;
    		return cityObj;
	  	};
	  	
	  	this.getSectorObj = function(fromRequest){
	  		var sectorObj = {};
    		sectorObj.sectorId = fromRequest.sectorId;
    		sectorObj.sectorTxt = fromRequest.sectorTxt;
    		return sectorObj;
	  	}
	  	
	  	this.getEntityNameObj = function(fromRequest){
	  		var entityNameObj = {};
	  		entityNameObj.id = fromRequest.id;
	  		entityNameObj.name = fromRequest.name;
	  		return entityNameObj;
	  	};
		
    }
})();
