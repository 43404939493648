(function(){
	'use strict'; 

angular
  .module('lariAidApp')
  .service('investigationFormIndexService', function () {
	  
		  var sectionValue = '';
		  
		  this.setInvestigationValue = function(value) {
			  sectionValue = value;
		  };
	
	     this.getInvestigationValue = function() {
	        return sectionValue;
	     };
	});
})();