(function() {
    'use strict';
    
    var lariDevApp = angular
    .module('lariAidApp')
    lariDevApp.directive('formattedDate', formattedDate);
    
    formattedDate.$inject = ['dateFilter'];
    function formattedDate(dateFilter){
    	return {
    	    scope: {
    	        format: "@",
    	    	modval: "="
    	    },
    	    link: function(scope, element, attrs) {
    	    	if(scope.modval){
    	    		var dateVal = new Date(scope.modval);
        	    	scope.modval = dateVal;
    	    	}
    	    }
    	}
    };
    
})();