(function () {
  "use strict";

  angular.module("lariAidApp").service("UserProfileService", UserProfileService);
  UserProfileService.$inject = ["$resource", "restService", "$q", "$rootScope"];

  function UserProfileService($resource, restService, $q, $rootScope) {
    this.getUserProfileDetails = function (userId) {
      var deferred = $q.defer();
      var url = "users/" + userId + "/profile";
      var data = restService.restCall("", url, "GET");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    this.getInvestigatorDetails = function (userId) {
      var deferred = $q.defer();
      var url = "investigator/" + userId + "/investigatorProfile";
      var data = restService.restCall("", url, "GET");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    this.getInvestigatorActiveCaseCount = function (userId) {
      var deferred = $q.defer();
      var url = "investigator/activeCaseCount/" + userId;
      var data = restService.restCall("", url, "GET");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    this.getReopenFlag = function (investigationId) {
      var deferred = $q.defer();
      var url = "investigation/reOpenStatus/" + investigationId;
      var data = restService.restCall("", url, "GET");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };
  }
})();
