(function(){
	'use strict';
	angular
	.module('lariAidApp')
	.service('UserActivityService',UserActivityService)
	UserActivityService.$inject = ['$resource','restService','$q','$rootScope'];
	
	function UserActivityService($resource,restService,$q,$rootScope){
		this.getUserActivityDetails = function(userId){
			var deferred = $q.defer();
			var url = "investigator/activity/"+userId;
			var data = restService.restCall("",url,'GET');
    		data.$promise.then(function(response){
    			deferred.resolve(response);
    		},
    		function (error) {
    			deferred.reject('ERROR');
    		});
    		return deferred.promise;
    	};
	}
})();