(function () {
  "use strict";

  angular.module("lariAidApp").service("UserService", UserService);

  UserService.$inject = ["restService", "$q"];

  function UserService(restService, $q) {
    this.getRoleUser = function (userFilterObj) {
      var deferred = $q.defer();
      var url = "users/role/count";
      var data = restService.restCall(userFilterObj, url, "POST");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    this.getInvestigationCases = function (userFilterObj) {
      var deferred = $q.defer();
      var url = "users/cases/count";
      var data = restService.restCall(userFilterObj, url, "POST");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    this.getUserList = function (userFilterObj) {
      var deferred = $q.defer();
      var url = "users/list";
      var data = restService.restCall(userFilterObj, url, "POST");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    this.addInvestigator = function (investigatorObj) {
      var deferred = $q.defer();
      var url = "createInvestigator";
      var data = restService.restCall(investigatorObj, url, "POST");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };

    this.updateInvestigator = function (investigatorObj) {
      var deferred = $q.defer();
      var url = "updateInvestigator";
      var data = restService.restCall(investigatorObj, url, "PUT");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };

    this.getAdminDetails = function (countryFilter, searchKey) {
      var deferred = $q.defer();
      var url = "countryAdmin/getCountryAdminDetails";
      url += "?countryFilter=" + countryFilter + "&searchKey=" + searchKey;
      var data = restService.restCall("", url, 'GETLIST');
      data.$promise.then(function (response) {
            deferred.resolve(response);
         },
         function (error) {
            deferred.reject('ERROR');
         });
      return deferred.promise;
   };
   
   this.saveAdminDetails = function (savedList) {
      var deferred = $q.defer();
      var url = "countryAdmin/saveOrUpdateCountryVisibility"
      var data = restService.restCall(savedList, url, 'POST');
      data.$promise.then(function (response) {
            deferred.resolve(response);
         },
         function (error) {
            deferred.reject(error);
         });
      return deferred.promise;
   };
   
  }
})();
