(function () {
  "use strict";

  angular.module("lariAidApp").config(stateConfig);

  stateConfig.$inject = ["$stateProvider"];

  function stateConfig($stateProvider) {
    $stateProvider.state("logs", {
      parent: "admin",
      url: "/logs",
      data: {
        authorities: ["ROLE_ADMIN"],
        pageTitle: "logs.title"
      },
      views: {
        "content@": {
          templateUrl: "app/admin/logs/logs.html",
          controller: "LogsController",
          controllerAs: "vm"
        }
      },
      resolve: {
        translatePartialLoader: [
          "$translate",
          "$translatePartialLoader",
          function ($translate, $translatePartialLoader) {
            if (localStorage && localStorage.getItem("lari_currentVersion") != null) {
              $translatePartialLoader.addPart("logs.json?version=" + localStorage.getItem("lari_currentVersion"));
            } else {
              $translatePartialLoader.addPart("logs.json?timestamp=" + new Date().getTime());
            }
            return $translate.refresh();
          }
        ]
      }
    });
  }
})();
