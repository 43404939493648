(function () {
  "use strict";

  angular.module("lariAidApp").controller("LibraryRequestController", LibraryRequestController);

  LibraryRequestController.$inject = [
    "$rootScope",
    "$scope",
    "$state",
    "$stateParams",
    "$sessionStorage",
    "$mdSidenav",
    "$element",
    "APP",
    "tabservice",
    "$mdMedia",
    "LibraryRequestService",
    "$q",
    "StorageDelete",
    "$mdDialog",
    "LibraryService",
    "CommonService",
    "$timeout",
    "notifications",
    "communicationService"
  ];

  function LibraryRequestController(
    $rootScope,
    $scope,
    $state,
    $stateParams,
    $sessionStorage,
    $mdSidenav,
    $element,
    APP,
    tabservice,
    $mdMedia,
    LibraryRequestService,
    $q,
    StorageDelete,
    $mdDialog,
    LibraryService,
    CommonService,
    $timeout,
    notifications,
    communicationService
  ) {
    var vm = this;
    vm.APP = APP;
    vm.state = $state;
    vm.currentState = vm.state.current.name;
    //Get user from cookie storage
    vm.user = CommonService.getCookieUser();
    vm.loggedUserRole = vm.user.loggedUserRole;

    vm.library = $stateParams;
    vm.media = $mdMedia;
    vm.openRequest = [];
    vm.closedRequest = [];
    vm.selectedRequestList = [];
    vm.requestList = [];
    vm.requestFilterObj = {};
    var requestListTotalLength = 0;
    vm.loadingRequestData = false;
    vm.requestFilterObj.from = 0;
    vm.requestFilterObj.size = vm.APP.GLOBAL.DEFAULT_SIZE;
    vm.requestFilterObj.statusKey = [];
    var qPromises = [];
    vm.endless = vm.APP.GLOBAL.ENDLESS;
    vm.entityRequestOld = {};
    vm.entityRequestNew = {};
    vm.entityPopupId;
    vm.popUpId;
    vm.requestDuration = null;

    vm.filterCountLoader = false;
    vm.loadingListData = false;

    vm.locationRequestNew = {};
    vm.locationRequestNew.countryObj = {};
    vm.locationRequestNew.provinceObj = {};
    vm.locationRequestNew.cityObj = {};
    vm.locationRequestNew.sectorObj = {};
    vm.inputIsHideObject = {};

    vm.entityRequestOld.countryObj = {};
    vm.entityRequestOld.provinceObj = {};
    vm.entityRequestOld.cityObj = {};
    vm.entityRequestOld.sectorObj = {};

    vm.relpaceinputfieldObject = {};
    vm.relpaceinputfieldObject.country = false;
    vm.relpaceinputfieldObject.province = false;
    vm.relpaceinputfieldObject.city = false;
    vm.relpaceinputfieldObject.sector = false;
    vm.mergeFlag = false;
    vm.mergeEntity = false;

    vm.closedRequests = vm.APP.LIBRARY.CLOSED_REQUESTS;
    vm.locationIdValidation = false;

    vm.getAllRequestFilters = function (isDefault, formRequest) {
      ///vm.showOpenRequestDiv = false;
      //vm.showCloseRequestDiv = false;
      vm.filterCountLoader = true;
      var data = LibraryRequestService.getRequestFilterList(formRequest);
      data.then(
        function (response) {
          vm.openRequest = response.OPEN_LIBRARY_REQUESTS;
          vm.closedRequest = response.CLOSED_LIBRARY_REQUESTS;
          vm.filterCountLoader = false;
          angular.forEach(vm.openRequest, function (data, key) {
            // Default RoleUser filter selected
            if (isDefault) {
              if (data.name == vm.APP.LIBRARY.RECENTY_ADD) {
                data.checked = true;
                vm.selectedRequestList.push(data);

                vm.requestFilterObj.statusKey.push(data.id);
              }
            }
          });
          if (!isDefault) {
            if ($sessionStorage.selectedRequestList) {
              vm.selectedRequestList = [];
              vm.selectedRequestList = $sessionStorage.selectedRequestList;
            }

            // Default select after refresh
            angular.forEach(vm.closedRequest, function (data, index) {
              angular.forEach(vm.selectedRequestList, function (selected, key) {
                if (data.id == selected.id) {
                  data.checked = true;
                }
              });
            });
            angular.forEach(vm.openRequest, function (data, index) {
              angular.forEach(vm.selectedRequestList, function (selected, key) {
                if (data.id == selected.id) {
                  data.checked = true;
                }
              });
            });
          }
          vm.updateRequestFilterObjList(vm.APP.LIBRARY.REQUEST_STATUS_KEY, vm.selectedRequestList); // Update userFilterObj
        },
        function (reason) {
          vm.loadingListData = false;
          return reason;
        }
      );
      return data;
    };

    vm.sideNavClose = function (id) {
      $mdSidenav(id).close();
    };

    //Common function for toggle based on the filter type
    vm.toggleRequest = function (item, list) {
      //To disable checkboxes
      if (vm.media("gt-sm")) {
        vm.loadingListData = true;
      }
      var idx;
      for (var i = 0; i < list.length; i++) {
        if (list[i].id == item.id) {
          idx = i;
        }
      }
      if (idx > -1) {
        list.splice(idx, 1);
      } else {
        list = [];
        list.push(item);
      }
      vm.selectedRequestList = list;

      vm.unSelectCall(vm.openRequest, item);
      vm.unSelectCall(vm.closedRequest, item);

      $sessionStorage.selectedRequestList = list;
      vm.updateRequestFilterObjList(vm.APP.LIBRARY.REQUEST_STATUS_KEY, vm.selectedRequestList);

      if (vm.media("gt-sm")) {
        qPromises.push(vm.getAllRequestFilters(false, vm.formRequest));
        $q.all(qPromises).then(function (data) {
          vm.getRequestList();
        });
      }
    };
    vm.unSelectCall = function (list, item) {
      angular.forEach(list, function (data, index) {
        if (data.id != item.id) {
          data.checked = false;
        }
      });
    };
    vm.updateRequestFilterObjList = function (postVariable, selectedList) {
      vm.requestFilterObj[postVariable] = [];
      angular.forEach(selectedList, function (data, key) {
        vm.requestFilterObj[postVariable].push(data.id);
      });
    };
    $scope.callListfun = function () {
      vm.getRequestList(vm.endless);
    };

    var clickingTimerRequest;
    vm.getRequestList = function (type) {
      if (!vm.media("gt-sm")) {
        vm.loadingListData = true;
      }
      clearTimeout(clickingTimerRequest);

      clickingTimerRequest = setTimeout(function () {
        vm.loadingRequestData = true;

        if (type != vm.endless) {
          vm.requestList = [];
        }

        if (vm.requestList && vm.requestList.length == 0) {
          vm.requestFilterObj.from = 0;
          requestListTotalLength = 0;
        } else if (vm.requestList.length > 0) {
          vm.requestFilterObj.from = vm.requestList.length;
        }

        if ($sessionStorage.formRequest) {
          vm.requestFilterObj.formRequest = $sessionStorage.formRequest;
        } else {
          vm.requestFilterObj.formRequest = false;
        }

        if (vm.requestFilterObj.from < requestListTotalLength || requestListTotalLength == 0) {
          var data = LibraryRequestService.getRequestLists(vm.requestFilterObj);
          data.then(
            function (response) {
              vm.loadingRequestData = false;
              vm.noMoreRequests = false;

              if (response && !angular.equals({}, response) && response.totalCount) {
                requestListTotalLength = response.totalCount;
                angular.forEach(response.libraryRequestDTOList, function (value, key) {
                  if (vm.requestList == null) {
                    vm.requestList = [];
                    vm.requestList.push(value);
                  } else {
                    vm.requestList.push(value);
                  }
                });
                vm.requestFilterObj.from = vm.requestList.length;

                if (vm.requestFilterObj.from == requestListTotalLength) {
                  vm.loadingRequestData = false;
                  vm.noMoreRequests = true;
                }

                if (vm.requestFilterObj.from <= vm.APP.GLOBAL.DEFAULT_SIZE) {
                  vm.noMoreRequests = false;
                }
              } else if (angular.isUndefined(response)) {
                vm.loadingRequestData = false;
                vm.noMoreRequests = false;
              }
              vm.loadingListData = false;
            },
            function (reason) {
              return reason;
              vm.loadingListData = false;
            }
          );
        } else {
          vm.loadingRequestData = false;
          vm.loadingListData = false;
          vm.noMoreRequests = true;
        }
      }, 200);
    };

    vm.searchRequest = function (requestSearchKey) {
      vm.requestFilterObj.searchKey = requestSearchKey;
      if (requestSearchKey.length > 1 || requestSearchKey.length == 0) {
        vm.getRequestList();
      }
    };

    vm.getRequestSortedList = function (requestSortBy, isCallRequired) {
      vm.requestFilterObj.sortBy = requestSortBy;
      if (requestSortBy == vm.APP.LIBRARY.REQUEST_SORT_BY_COUNT) {
        vm.requestFilterObj.sortOrder = vm.APP.GLOBAL.ASC;
      } else if (requestSortBy == vm.APP.LIBRARY.REQUEST_SORT_BY_NAME) {
        vm.requestFilterObj.sortOrder = vm.APP.GLOBAL.ASC;
      }

      $sessionStorage.requestSortBy = requestSortBy;

      if (!isCallRequired) {
        vm.getRequestList(); //Prevent call from vm.userInit function.
      }
    };

    vm.getLocationMappingDataById = function (locationMappingId, disable) {
      var locationData = CommonService.getLocationById(locationMappingId);
      locationData.then(function (response) {
        var responseObj = response;
        vm.requestDuration = responseObj.noOfDays;
        //Set location objects
        if (!disable) {
          vm.entityRequestOld.countryObj = LibraryService.getCountryObj(responseObj);
          vm.entityRequestOld.provinceObj = LibraryService.getProvinceObj(responseObj);
          vm.entityRequestOld.cityObj = LibraryService.getCityObj(responseObj);
          vm.entityRequestOld.sectorObj = LibraryService.getSectorObj(responseObj);
        }
        vm.entityRequestNew.countryObj = LibraryService.getCountryObj(responseObj);
        vm.entityRequestNew.provinceObj = LibraryService.getProvinceObj(responseObj);
        vm.entityRequestNew.cityObj = LibraryService.getCityObj(responseObj);
        vm.entityRequestNew.sectorObj = LibraryService.getSectorObj(responseObj);
      });
    };

    vm.getEntityTypeById = function (entityTypeId, disable) {
      //var entityTypeData = CommonService.getRefTermList(vm.APP.REF_TERM.ENTITY_PROFILE);
      //entityTypeData.then(function(response) {
      if (vm.entityTypeList) {
        angular.forEach(vm.entityTypeList, function (data, key) {
          if (data.id == entityTypeId) {
            if (!disable) {
              vm.entityRequestOld.entityType = data;
            }
            vm.entityRequestNew.entityType = data;
            vm.requestEntityType = data.name;
          }
        });
      }

      //});
    };

    vm.clearEntity = function () {
      var copyEntityType = vm.entityRequestNew.entityType;
      vm.entityRequestNew = {};
      vm.entityRequestNew.countryObj = {};
      vm.entityRequestNew.provinceObj = {};
      vm.entityRequestNew.cityObj = {};
      vm.entityRequestNew.sectorObj = {};
      vm.entityNameSearchText = "";
      vm.entityRequestNew.flag = false; // The field flag is mandatory. So default value is set to this  field
      vm.entityRequestNew.entityType = copyEntityType;
      vm.isHide = false;
      vm.isDisable = false;
    };

    vm.clearOnClose = function (searchKey, dropValue) {
      vm[searchKey] = undefined;
      vm[dropValue] = undefined;
      vm.entityNameLoader = false;
    };

    vm.searchEntity = function (searchKey, entityTypeId) {
      if (searchKey && searchKey.length >= 2) {
        var deferred = $q.defer();
        vm.entityNameList = [];
        vm.entityNameLoader = true;
        vm.isNewEntityName = false;
        var data = LibraryService.getEntityNamesList(searchKey, entityTypeId);
        data.then(function (response) {
          vm.entityNameLoader = false;
          if (response.length > 0) {
            vm.entityNameList = response;
            vm.isHide = false;
          } else {
            vm.entityNameSearchText = searchKey;
            vm.isHide = true;
            vm.isNewEntityName = true;
            vm.entityRequestNew.entityName = {};
          }
          deferred.resolve(vm.entityNameList);
        });
        return deferred.promise;
      }
    };

    //Get Merged Entity Object
    vm.getMergedObj = function (entityId) {
      vm.entityRequestNew = {};
      var data = LibraryService.getEntityProfileById(entityId);
      data.then(function (response) {
        vm.entityRequestNew = response;
        vm.entityRequestNew.entityName = LibraryService.getEntityNameObj(vm.entityRequestNew);

        if (vm.entityTypeList) {
          angular.forEach(vm.entityTypeList, function (data, key) {
            if (data.id == vm.entityRequestNew.entityTypeId) {
              vm.entityRequestNew.entityType = data;
            }
          });
        }

        var locationData = CommonService.getLocationById(vm.entityRequestNew.locationMappingId);
        locationData.then(function (response) {
          var responseObj = response;
          vm.entityRequestNew.countryObj = LibraryService.getCountryObj(responseObj);
          vm.entityRequestNew.provinceObj = LibraryService.getProvinceObj(responseObj);
          vm.entityRequestNew.cityObj = LibraryService.getCityObj(responseObj);
          vm.entityRequestNew.sectorObj = LibraryService.getSectorObj(responseObj);
        });
      });
    };

    vm.getRequestPopupId = function (ev, requestType) {
      vm.id = "";

      //Check closed status
      var idx = vm.closedRequests.indexOf(vm.request.requestStatus);

      if (requestType == vm.APP.LIBRARY.LOCATION_REQUEST) {
        //View Location Request Profile for other users
        vm.id = vm.APP.LIBRARY.REQ_LOC_PROFILE_ID;
        if (vm.loggedUserRole == vm.APP.GLOBAL.MANAGER || vm.isRequestMerged) {
          vm.id = vm.APP.LIBRARY.REQ_LOC_ID;
          if (idx > -1) {
            vm.id = vm.APP.LIBRARY.REQ_LOC_PROFILE_ID;
          }
        }
      } else if (requestType == vm.APP.LIBRARY.ENTITY_REQUEST) {
        vm.id = vm.APP.LIBRARY.REQ_ENTITY_PROFILE_ID;
        if (vm.loggedUserRole == vm.APP.GLOBAL.MANAGER || vm.isRequestMerged) {
          vm.id = vm.APP.LIBRARY.REQ_ENTITY_ID;
          // For read only popup if the requestStatus as closed
          if (idx > -1) {
            vm.id = vm.APP.LIBRARY.REQ_ENTITY_PROFILE_ID;
          }
        }
      }
      vm.showRequestDialog(ev, vm.id);
    };

    vm.getEntityProfileById = function (entityId, disable, ev) {
      vm.entityProfile = {};
      vm.entityPopupLoader = true;
      vm.entityId = entityId;
      vm.mergedEntityId = undefined;
      vm.entityLocationMappingId = undefined;
      vm.isMergeRequest = false;
      vm.isRequestMerged = false;
      var data = LibraryService.getEntityProfileById(entityId);
      data.then(function (response) {
        var responseObj = response;
        if (disable) {
          vm.isDisable = true;
          vm.isMergeRequest = true;
          vm.mergedEntityId = response.id;
        } else {
          vm.entityRequestOld = angular.copy(responseObj);
          vm.entityNameSearchText = vm.entityRequestOld.name;
          //isForm is to know whether the request is from form section or Library Section
          vm.entityRequestOld.isForm = responseObj.isForm;
        }
        vm.entityRequestNew = angular.copy(responseObj);
        vm.entityRequestNew.entityName = LibraryService.getEntityNameObj(responseObj);
        vm.entityLocationMappingId = responseObj.locationMappingId;
        vm.getLocationMappingDataById(vm.entityLocationMappingId, disable);
        vm.getEntityTypeById(responseObj.entityTypeId, disable);
        vm.entityPopupLoader = false;
        //Get Merged Entity Profile Details
        if (responseObj.isMerged) {
          vm.isRequestMerged = true;
          vm.getMergedObj(responseObj.mergedEntityId);
        }
        if (!disable) {
          vm.getRequestPopupId(ev, vm.APP.LIBRARY.ENTITY_REQUEST);
        }
      });
    };

    vm.setFormLocationValues = function () {
      if (vm.locationRequestOld.countryId == null && vm.locationRequestOld.countryTxt != null) {
        vm.formCountryRequest = true;
        vm.locationRequestNew.countryObj.countryId = -1;
        vm.locationRequestNew.provinceObj.provinceId = -1;
        vm.locationRequestNew.cityObj.cityId = -1;
      } else if (
        vm.locationRequestOld.provinceId == null &&
        vm.locationRequestOld.provinceTxt != null &&
        vm.locationRequestOld.provinceTxt != ""
      ) {
        vm.formProvinceRequest = true;
        vm.addNewLocationObject.country = false;
        vm.locationRequestNew.provinceObj.provinceId = -1;
        vm.locationRequestNew.cityObj.cityId = -1;
      } else if (
        vm.locationRequestOld.cityId == null &&
        vm.locationRequestOld.cityTxt != null &&
        vm.locationRequestOld.cityTxt != ""
      ) {
        vm.formCityRequest = true;
        vm.addNewLocationObject.country = false;
        vm.addNewLocationObject.province = false;
        vm.locationRequestNew.cityObj.cityId = -1;
      } else if (
        vm.locationRequestOld.sectorId == null &&
        vm.locationRequestOld.sectorTxt != null &&
        vm.locationRequestOld.sectorTxt != ""
      ) {
        vm.addNewLocationObject.country = false;
        vm.addNewLocationObject.province = false;
        vm.addNewLocationObject.city = false;
        vm.locationRequestNew.sectorObj.sectorId = -1;
      }

      if (
        vm.locationRequestOld.sectorTxt != null &&
        vm.locationRequestOld.sectorTxt != "" &&
        (vm.locationRequestOld.countryId == null ||
          vm.locationRequestOld.provinceId == null ||
          vm.locationRequestOld.cityId == null)
      ) {
        vm.locationRequestNew.sectorObj.sectorId = -1;
      } else if (
        (vm.locationRequestOld.provinceTxt == null || vm.locationRequestOld.provinceTxt == "") &&
        vm.locationRequestOld.provinceId == null
      ) {
        vm.inputIsHideObject.province = true;
        vm.inputIsHideObject.city = true;
        vm.inputIsHideObject.sector = true;
      } else if (
        (vm.locationRequestOld.cityTxt == null || vm.locationRequestOld.cityTxt == "") &&
        vm.locationRequestOld.cityId == null
      ) {
        vm.inputIsHideObject.city = true;
        vm.inputIsHideObject.sector = true;
      } else if (
        (vm.locationRequestOld.sectorTxt == null || vm.locationRequestOld.sectorTxt == "") &&
        vm.locationRequestOld.sectorId == null
      ) {
        vm.inputIsHideObject.sector = true;
      }

      //Set input search object for directive
      if (vm.locationRequestOld.countryTxt != null && vm.locationRequestOld.countryTxt != "") {
        vm.requestLocationInputSearchObject.countrySearchKey = vm.locationRequestOld.countryTxt;
      }
      if (vm.locationRequestOld.provinceTxt != null && vm.locationRequestOld.provinceTxt != "") {
        vm.requestLocationInputSearchObject.provinceSearchKey = vm.locationRequestOld.provinceTxt;
      }
      if (vm.locationRequestOld.cityTxt != null && vm.locationRequestOld.cityTxt != "") {
        vm.requestLocationInputSearchObject.citySearchKey = vm.locationRequestOld.cityTxt;
      }
      if (vm.locationRequestOld.sectorTxt != null && vm.locationRequestOld.sectorTxt != "") {
        vm.requestLocationInputSearchObject.sectorSearchKey = vm.locationRequestOld.sectorTxt;
      }
    };

    vm.requestLocationInputSearchObject = {};
    vm.getRequestProfileById = function (locationId, ev) {
      vm.locationProfile = {};
      vm.requestLocationInputSearchObject = {};
      vm.addNewLocationObject = {};

      vm.requestLocationInputSearchObject.country = "";
      vm.requestLocationInputSearchObject.province = "";
      vm.requestLocationInputSearchObject.city = "";
      vm.requestLocationInputSearchObject.sector = "";

      vm.entityPopupLoader = true;
      vm.isRequestMerged = false;
      vm.inputIsHideObject.country = false;
      vm.inputIsHideObject.province = false;
      vm.inputIsHideObject.city = false;
      vm.inputIsHideObject.sector = false;
      vm.relpaceinputfieldObject.country = false;
      vm.relpaceinputfieldObject.province = false;
      vm.relpaceinputfieldObject.city = false;
      vm.relpaceinputfieldObject.sector = false;
      vm.requestLocationInputSearchObject.isHideCountry = false;
      vm.requestLocationInputSearchObject.isHideProvince = false;
      vm.requestLocationInputSearchObject.isHideCity = false;
      vm.requestLocationInputSearchObject.isHideSector = false;
      vm.formCountryRequest = false;
      vm.formProvinceRequest = false;
      vm.formCityRequest = false;
      vm.addNewLocationObject.country = true;
      vm.addNewLocationObject.province = true;
      vm.addNewLocationObject.city = true;
      vm.addNewLocationObject.sector = true;
      var data = LibraryRequestService.getRequestProfileById(locationId);
      data.then(function (response) {
        vm.locationRequestOld = response;
        vm.locationProfile = response;
        vm.locationRequestNew = angular.copy(vm.locationRequestOld);
        vm.requestDuration = vm.locationRequestNew.noOfDays;
        //Set location objects
        vm.locationRequestNew.countryObj = LibraryService.getCountryObj(vm.locationRequestOld);
        vm.locationRequestNew.provinceObj = LibraryService.getProvinceObj(vm.locationRequestOld);
        vm.locationRequestNew.cityObj = LibraryService.getCityObj(vm.locationRequestOld);
        vm.locationRequestNew.sectorObj = LibraryService.getSectorObj(vm.locationRequestOld);
        //Set LocationType
        if (vm.locationRequestOld.requestTypeName != null) {
          vm.locationTypeName = vm.locationRequestOld.requestTypeName;
        } else if (vm.locationRequestOld.requestTypeName == null) {
          vm.locationTypeName = vm.APP.LIBRARY.FORM_LOCATION_REQUEST;
        }

        //Get merged location obj
        if (vm.locationRequestNew.isMerged) {
          vm.isRequestMerged = true;
          var mergedData = LibraryRequestService.getRequestProfileById(vm.locationRequestNew.mergedLocationMappingId);
          mergedData.then(function (response) {
            vm.locationRequestNew = response;
          });
        }
        //For Showing new request in md-select drop down
        else {
          if (vm.locationRequestNew.requestTypeName == vm.APP.LIBRARY.COUNTRY) {
            vm.inputIsHideObject.province = true;
            vm.inputIsHideObject.city = true;
            vm.inputIsHideObject.sector = true;
            vm.locationRequestNew.countryObj.countryId = -1;
          } else if (vm.locationRequestNew.requestTypeName == vm.APP.LIBRARY.PROVINCE) {
            vm.inputIsHideObject.city = true;
            vm.inputIsHideObject.sector = true;
            vm.addNewLocationObject.country = false;
            vm.locationRequestNew.provinceObj.provinceId = -1;
          } else if (vm.locationRequestNew.requestTypeName == vm.APP.LIBRARY.CITY) {
            vm.inputIsHideObject.sector = true;
            vm.addNewLocationObject.country = false;
            vm.addNewLocationObject.province = false;
            vm.locationRequestNew.cityObj.cityId = -1;
          } else if (vm.locationRequestNew.requestTypeName == vm.APP.LIBRARY.SECTOR) {
            vm.addNewLocationObject.country = false;
            vm.addNewLocationObject.province = false;
            vm.addNewLocationObject.city = false;
            vm.locationRequestNew.sectorObj.sectorId = -1;
          }

          //Set the form request values
          if (vm.locationTypeName == vm.APP.LIBRARY.FORM_LOCATION_REQUEST) {
            vm.setFormLocationValues();
          }
        }
        vm.getRequestPopupId(ev, vm.APP.LIBRARY.LOCATION_REQUEST);
        vm.entityPopupLoader = false;
      });
    };

    vm.getLocationPopup = function (ev, entityRequestNew, requestEntityForm) {
      vm.getRequestProfileById(entityRequestNew.locationMappingId, ev);
    };

    vm.entityApprove = function (entityRequestNew, requestEntityForm) {
      var approveEntityRequest = {};
      vm.msgFlag = false;
      vm.isEntityApproveDisable = true;
      vm.locationIdValidation = false;
			var locationValidation = communicationService.getData('ValidateEntityLocationField');
			vm.validateLocationIds(locationValidation);
      if (entityRequestNew && requestEntityForm.$valid) {
        //Approve or Alterd
        if (!vm.isMergeRequest) {
          // For new entity name selection
          if (vm.isNewEntityName) {
            approveEntityRequest.name = vm.entityNameSearchText;
          }
          // For default entity name or change from dropdown
          else {
            approveEntityRequest.name = vm.entityRequestNew.entityName.name;
          }

          if (vm.entityRequestNew.entityType != null) {
            approveEntityRequest.entityTypeId = vm.entityRequestNew.entityType.id;
          }
          if (vm.entityId != null) {
            approveEntityRequest.id = vm.entityId;
          }

          approveEntityRequest.comments = entityRequestNew.comments;
          approveEntityRequest.flag = entityRequestNew.flag;
          approveEntityRequest.contactEmail = entityRequestNew.contactEmail;
          approveEntityRequest.contactPersonName = entityRequestNew.contactPersonName;
          approveEntityRequest.contactPhoneNumber = entityRequestNew.contactPhoneNumber;
          
					if(locationValidation && vm.locationIdValidation){
						if(locationValidation.id){
							approveEntityRequest.locationMappingId = locationValidation.id;
						}
					 	else if(locationValidation.locationMappingId) {
							approveEntityRequest.locationMappingId = locationValidation.locationMappingId;
						}
					} else if (
            entityRequestNew.cityObj.locationMappingId != null &&
            entityRequestNew.cityObj.locationMappingId != undefined
          ) {
            approveEntityRequest.locationMappingId = entityRequestNew.cityObj.locationMappingId;
          } else {
            approveEntityRequest.locationMappingId = vm.entityLocationMappingId;
          }
        }

        // Merged Request
        else {
          approveEntityRequest = angular.copy(vm.entityRequestOld);
          if (vm.isMergeRequest && vm.mergedEntityId != null) {
            approveEntityRequest.mergedEntityId = vm.mergedEntityId;
          }
        }
        if (vm.mergeEntity) {
          approveEntityRequest.isEntityMerge = true;
        } else {
          approveEntityRequest.isEntityMerge = false;
        }

        var data = LibraryRequestService.entityApprove(approveEntityRequest);
        data.then(
          function (response) {
            communicationService.setData('ValidateEntityLocationField','');
            vm.cancel(requestEntityForm);
            notifications.showSuccess({ message: vm.APP.LIBRARY.ENTITY_REQUEST });
            vm.requestInit();
            vm.isEntityApproveDisable = false;
            vm.mergeEntity = false;
            vm.msgFlag = false;
          },
          function (reason) {
            if (reason.data.statusCode == 409) {
              vm.isEntityApproveDisable = false;
              vm.msgFlag = true;
              vm.mergeEntity = true;
            } else if (reason.data.statusCode == 400) {
              //if entity approved before location
              vm.isEntityApproveDisable = false;
              vm.msgFlagForLocation = true;
              $timeout(function () {
                vm.msgFlag = false;
                vm.entityPopupId = approveEntityRequest.id;
                vm.popUpId = vm.APP.LIBRARY.ENTITY_REQ;
              }, 5000);
            } else {
              notifications.showError({ message: vm.APP.LIBRARY.ENTITY_REQUEST_PROBLEM });
            }
            return reason;
          }
        );
      } else {
        vm.isEntityApproveDisable = false;
      }
    };

    /*vm.goRequest = function(ev,request){
	  		vm.msgFlag = false;
	  		var id;
	  		vm.request = request;

	  		//Check closed status
	  		var idx = vm.closedRequests.indexOf(vm.request.requestStatus);

  			if(request.requestTypeName == vm.APP.LIBRARY.LOCATION_REQUEST){
  				//View Location Request Profile for other users
  				id = vm.APP.LIBRARY.REQ_LOC_PROFILE_ID;
  				if(vm.loggedUserRole == vm.APP.GLOBAL.MANAGER){
  					id = vm.APP.LIBRARY.REQ_LOC_ID;
  	  				if(idx > -1){
  	  					id = vm.APP.LIBRARY.REQ_LOC_PROFILE_ID;
  	  				}
  				}
	   			vm.getRequestProfileById(vm.request.id);
	   		}
  			else if(request.requestTypeName == vm.APP.LIBRARY.ENTITY_REQUEST){
  				id = vm.APP.LIBRARY.REQ_ENTITY_PROFILE_ID;
  				if(vm.loggedUserRole == vm.APP.GLOBAL.MANAGER) {
  					id = vm.APP.LIBRARY.REQ_ENTITY_ID;
  		   		    // For read only popup if the requestStatus as closed
  	  				if(idx > -1){
  	  					id = vm.APP.LIBRARY.REQ_ENTITY_PROFILE_ID;
  	  				}
  				}
	   			vm.getEntityProfileById(vm.request.id,false); 
	   		}
	   		vm.showRequestDialog(ev,id,request);
	   	};*/

    vm.goRequest = function (ev, request) {
      vm.msgFlag = false;
      vm.isEntityApproveDisable = false;
      vm.isLocationApproveDisable = false;
      vm.request = request;

      if (request.requestTypeName == vm.APP.LIBRARY.LOCATION_REQ) {
        vm.getRequestProfileById(vm.request.id, ev);
      } else if (request.requestTypeName == vm.APP.LIBRARY.ENTITY_REQ) {
        vm.getEntityProfileById(vm.request.id, false, ev);
      }
    };

    vm.showRequestDialog = function ($event, id) {
      //vm.request = request;
      vm.isDisable = false;
      vm.isHide = true;
      vm.isNewEntityName = true;
      $mdDialog.show({
        targetEvent: $event,
        contentElement: "#" + id,
        parent: angular.element(document.body)
      });
    };

    vm.cancel = function (form) {
      form.$setPristine();
      $mdDialog.hide();
      vm.msgFlagForLocation = false;
      vm.isEntityApproveDisable = false;
      if ($scope.modelObject) {
        $scope.modelObject = {};
      }
      if ($scope.inputsearchObject) {
        $scope.inputsearchObject = {};
      }
    };

    vm.getEntityTypeList = function () {
      var entityTypeData = CommonService.getRefTermList(vm.APP.REF_TERM.ENTITY_PROFILE);
      entityTypeData.then(function (response) {
        vm.entityTypeList = response;
      });
    };

    //Show the validation message for library location request
    vm.checkValidation = function (locationRequestNew) {
      if (locationRequestNew.requestTypeName == vm.APP.LIBRARY.PROVINCE) {
        if (
          locationRequestNew.countryObj == undefined ||
          locationRequestNew.countryObj.countryId == null ||
          locationRequestNew.countryObj.countryId == undefined
        ) {
          vm.requestLocationInputSearchObject.countrySearchKey = "";
        }
      } else if (locationRequestNew.requestTypeName == vm.APP.LIBRARY.CITY) {
        if (
          locationRequestNew.countryObj == undefined ||
          locationRequestNew.countryObj.countryId == null ||
          locationRequestNew.countryObj.countryId == undefined
        ) {
          vm.requestLocationInputSearchObject.countrySearchKey = "";
        }
        if (
          locationRequestNew.provinceObj == undefined ||
          locationRequestNew.provinceObj.provinceId == null ||
          locationRequestNew.provinceObj.provinceId == undefined
        ) {
          vm.requestLocationInputSearchObject.provinceSearchKey = "";
        }
      } else if (locationRequestNew.requestTypeName == vm.APP.LIBRARY.SECTOR) {
        if (
          locationRequestNew.countryObj == undefined ||
          locationRequestNew.countryObj.countryId == null ||
          locationRequestNew.countryObj.countryId == undefined
        ) {
          vm.requestLocationInputSearchObject.countrySearchKey = "";
        }
        if (
          locationRequestNew.provinceObj == undefined ||
          locationRequestNew.provinceObj.provinceId == null ||
          locationRequestNew.provinceObj.provinceId == undefined
        ) {
          vm.requestLocationInputSearchObject.provinceSearchKey = "";
        }
        if (
          locationRequestNew.cityObj == undefined ||
          locationRequestNew.cityObj.cityId == null ||
          locationRequestNew.cityObj.cityId == undefined
        ) {
          vm.requestLocationInputSearchObject.citySearchKey = "";
        }
        if (
          locationRequestNew.sectorObj == undefined ||
          locationRequestNew.sectorObj.sectorTxt == null ||
          locationRequestNew.sectorObj.sectorTxt == ""
        ) {
          vm.sectorValidationFlag = true; //Show sector validation message
          $timeout(function () {
            vm.sectorValidationFlag = false;
          }, 5000);
        }
      }
    };

    //Approve the library location request
    vm.approvAlterLocationRequest = function (locationRequestNew, requestLocationForm) {
      vm.approveObj = {};
      vm.approveObj.countryObj = {};
      vm.approveObj.provinceObj = {};
      vm.approveObj.cityObj = {};
      vm.approveObj.sectorObj = {};
      vm.sectorValidationFlag = false;
      vm.isLocationApproveDisable = true;
      if (locationRequestNew && requestLocationForm.$valid) {
        vm.approveObj.id = vm.locationRequestNew.id;
        vm.approveObj.requestTypeId = locationRequestNew.requestTypeId;
        vm.approveObj.requestTypeName = locationRequestNew.requestTypeName;
        if (vm.locationRequestNew.requestTypeName == vm.APP.LIBRARY.COUNTRY) {
          vm.approveObj.countryId = locationRequestNew.countryObj.countryId;
          vm.approveObj.countryTxt = locationRequestNew.countryObj.countryTxt;
          if (locationRequestNew.countryObj && locationRequestNew.countryObj.locationMappingId != null) {
            vm.approveObj.mergedLocationMappingId = locationRequestNew.countryObj.locationMappingId;
          } else if (locationRequestNew.countryObj.countryId == -1) {
            vm.approveObj.countryId = null;
          }
        }
        if (locationRequestNew.requestTypeName == vm.APP.LIBRARY.PROVINCE) {
          vm.approveObj.countryId = locationRequestNew.countryObj.countryId;
          vm.approveObj.countryTxt = locationRequestNew.countryObj.countryTxt;
          vm.approveObj.provinceId = locationRequestNew.provinceObj.provinceId;
          vm.approveObj.provinceTxt = locationRequestNew.provinceObj.provinceTxt;
          if (
            locationRequestNew.provinceObj &&
            locationRequestNew.countryObj &&
            locationRequestNew.provinceObj.locationMappingId != null
          ) {
            vm.approveObj.mergedLocationMappingId = locationRequestNew.provinceObj.locationMappingId;
          } else if (vm.locationRequestNew.provinceObj.provinceId == -1) {
            vm.approveObj.provinceId = null;
          }
        }
        if (locationRequestNew.requestTypeName == vm.APP.LIBRARY.CITY) {
          vm.approveObj.countryId = locationRequestNew.countryObj.countryId;
          vm.approveObj.countryTxt = locationRequestNew.countryObj.countryTxt;
          vm.approveObj.provinceId = locationRequestNew.provinceObj.provinceId;
          vm.approveObj.provinceTxt = locationRequestNew.provinceObj.provinceTxt;
          vm.approveObj.cityId = locationRequestNew.cityObj.cityId;
          vm.approveObj.cityTxt = locationRequestNew.cityObj.cityTxt;
          if (
            locationRequestNew.countryObj &&
            locationRequestNew.cityObj.locationMappingId != null &&
            locationRequestNew.cityObj
          ) {
            vm.approveObj.mergedLocationMappingId = vm.locationRequestNew.cityObj.locationMappingId;
          } else if (locationRequestNew.cityObj.cityId == -1) {
            vm.approveObj.cityId = null;
          }
        }
        if (locationRequestNew.requestTypeName == vm.APP.LIBRARY.SECTOR) {
          vm.approveObj.countryId = locationRequestNew.countryObj.countryId;
          vm.approveObj.countryTxt = locationRequestNew.countryObj.countryTxt;
          vm.approveObj.provinceId = locationRequestNew.provinceObj.provinceId;
          vm.approveObj.provinceTxt = locationRequestNew.provinceObj.provinceTxt;
          vm.approveObj.cityId = locationRequestNew.cityObj.cityId;
          vm.approveObj.cityTxt = locationRequestNew.cityObj.cityTxt;
          vm.approveObj.sectorId = locationRequestNew.sectorObj.sectorId;
          vm.approveObj.sectorTxt = locationRequestNew.sectorObj.sectorTxt;
          if (
            locationRequestNew.countryObj &&
            locationRequestNew.sectorObj.locationMappingId != null &&
            locationRequestNew.cityObj &&
            locationRequestNew.sectorObj
          ) {
            vm.approveObj.mergedLocationMappingId = locationRequestNew.sectorObj.locationMappingId;
          } else if (locationRequestNew.sectorObj.sectorId == -1) {
            vm.approveObj.sectorId = null;
          }
          if (vm.approveObj.sectorTxt == null || vm.approveObj.sectorTxt == "") {
            vm.sectorValidationFlag = true; //Show sector validation message
            $timeout(function () {
              vm.sectorValidationFlag = false;
            }, 5000);
          }
        }
        if (vm.mergeFlag) {
          vm.approveObj.isMerge = true;
        } else {
          vm.approveObj.isMerge = false;
        }
        if (!vm.sectorValidationFlag) {
          var data = LibraryRequestService.saveApprovedRequestLists(vm.approveObj);
          data.then(
            function (response) {
              vm.msgFlag = false;
              vm.mergeFlag = false;
              vm.state.transitionTo(vm.state.current, vm.library, {
                reload: true,
                inherit: false,
                notify: true
              });
              $mdDialog.hide();
              notifications.showSuccess({ message: vm.APP.LIBRARY.LOCATION_REQUEST });
            },
            function (reason) {
              if (reason.data.statusCode == 409) {
                vm.msgFlag = true;
                vm.mergeFlag = true;
              } else {
                notifications.showError({ message: vm.APP.LIBRARY.LOCATION_REQUEST_PROBLEM });
              }
              vm.isLocationApproveDisable = false;
              return reason;
            }
          );
        }
      } else {
        vm.isLocationApproveDisable = false;
        vm.checkValidation(locationRequestNew);
      }
    };

    vm.replaceCountry = function () {
      vm.locationRequestNew.countryObj = {};
      vm.requestLocationInputSearchObject.isHideCountry = true;
      vm.relpaceinputfieldObject.country = true;
    };

    vm.replaceProvince = function () {
      vm.locationRequestNew.provinceObj = {};
      vm.requestLocationInputSearchObject.isHideProvince = true;
      vm.relpaceinputfieldObject.province = true;
    };

    vm.replaceCity = function () {
      vm.locationRequestNew.cityObj = {};
      vm.requestLocationInputSearchObject.isHideCity = true;
      vm.relpaceinputfieldObject.city = true;
    };

    vm.replaceSector = function () {
      vm.locationRequestNew.sectorObj = {};
      vm.requestLocationInputSearchObject.isHideSector = true;
      vm.relpaceinputfieldObject.sector = true;
    };

    vm.formReplaceSelect = function () {
      if (
        vm.formCountryRequest &&
        vm.locationRequestNew.countryObj == undefined &&
        vm.requestLocationInputSearchObject.country != null &&
        vm.requestLocationInputSearchObject.country != ""
      ) {
        vm.replaceCountry();
        vm.replaceProvince();
        vm.replaceCity();
        vm.relpaceinputfieldObject.countryKey = vm.requestLocationInputSearchObject.country;
        vm.locationRequestNew.countryObj.countryTxt = vm.relpaceinputfieldObject.countryKey;
      } else if (
        vm.formProvinceRequest &&
        vm.locationRequestNew.countryObj &&
        vm.locationRequestNew.countryObj.countryId != null &&
        vm.locationRequestNew.provinceObj == undefined &&
        vm.requestLocationInputSearchObject.province != null &&
        vm.requestLocationInputSearchObject.province != ""
      ) {
        vm.replaceProvince();
        vm.replaceCity();
        vm.relpaceinputfieldObject.provinceKey = vm.requestLocationInputSearchObject.province;
        vm.locationRequestNew.provinceObj.provinceTxt = vm.relpaceinputfieldObject.provinceKey;
      } else if (
        vm.formCityRequest &&
        vm.locationRequestNew.countryObj &&
        vm.locationRequestNew.countryObj.countryId != null &&
        vm.locationRequestNew.provinceObj &&
        vm.locationRequestNew.provinceObj.provinceId != null &&
        vm.locationRequestNew.cityObj == undefined &&
        vm.requestLocationInputSearchObject.city != null &&
        vm.requestLocationInputSearchObject.city != ""
      ) {
        vm.replaceCity();
        vm.relpaceinputfieldObject.cityKey = vm.requestLocationInputSearchObject.city;
        vm.locationRequestNew.cityObj.cityTxt = vm.relpaceinputfieldObject.cityKey;
      }

      if (
        vm.requestLocationInputSearchObject.sector != null &&
        vm.locationRequestNew.sectorObj == undefined &&
        vm.locationRequestNew.sectorObj.sectorId == null
      ) {
        vm.replaceSector();
      }
    };

    vm.replaceSelect = function () {
      if (vm.requestLocationInputSearchObject.country != null || vm.relpaceinputfieldObject.countryKey != null) {
        vm.isEnableSave = true;
      }
      if (
        vm.locationRequestNew.requestTypeName == vm.APP.LIBRARY.COUNTRY &&
        vm.locationRequestNew.countryObj == undefined &&
        vm.requestLocationInputSearchObject.country != null
      ) {
        vm.locationRequestNew.countryObj = {};
        vm.requestLocationInputSearchObject.isHideCountry = true;
        vm.relpaceinputfieldObject.country = true;
        vm.relpaceinputfieldObject.countryKey = vm.requestLocationInputSearchObject.country;
        vm.locationRequestNew.countryObj.countryTxt = vm.relpaceinputfieldObject.countryKey;
      }

      if (
        vm.locationRequestNew.requestTypeName == vm.APP.LIBRARY.PROVINCE &&
        vm.locationRequestNew.countryObj &&
        vm.locationRequestNew.countryObj.countryId != null &&
        vm.locationRequestNew.provinceObj == undefined &&
        vm.requestLocationInputSearchObject.province != null
      ) {
        vm.locationRequestNew.provinceObj = {};
        vm.requestLocationInputSearchObject.isHideProvince = true;
        vm.relpaceinputfieldObject.province = true;
        vm.relpaceinputfieldObject.provinceKey = vm.requestLocationInputSearchObject.province;
        vm.locationRequestNew.provinceObj.provinceTxt = vm.relpaceinputfieldObject.provinceKey;
      }

      if (
        vm.locationRequestNew.requestTypeName == vm.APP.LIBRARY.CITY &&
        vm.locationRequestNew.countryObj &&
        vm.locationRequestNew.countryObj.countryId != null &&
        vm.locationRequestNew.provinceObj &&
        vm.locationRequestNew.provinceObj.provinceId != null &&
        vm.locationRequestNew.cityObj == undefined &&
        vm.requestLocationInputSearchObject.city != null
      ) {
        vm.locationRequestNew.cityObj = {};
        vm.requestLocationInputSearchObject.isHideCity = true;
        vm.relpaceinputfieldObject.city = true;
        vm.relpaceinputfieldObject.cityKey = vm.requestLocationInputSearchObject.city;
        vm.locationRequestNew.cityObj.cityTxt = vm.relpaceinputfieldObject.cityKey;
      }
      if (
        vm.locationRequestNew.requestTypeName == vm.APP.LIBRARY.SECTOR &&
        vm.locationRequestNew.countryObj &&
        vm.locationRequestNew.countryObj.countryId != null &&
        vm.locationRequestNew.provinceObj &&
        vm.locationRequestNew.provinceObj.provinceId != null &&
        vm.locationRequestNew.cityObj &&
        vm.locationRequestNew.cityObj.cityId != null &&
        (vm.locationRequestNew.sectorObj.sectorTxt == undefined || vm.locationRequestNew.sectorObj == null) &&
        vm.requestLocationInputSearchObject.sector != null
      ) {
        vm.locationRequestNew.sectorObj = {};
        vm.requestLocationInputSearchObject.isHideSector = true;
        vm.relpaceinputfieldObject.sector = true;
        vm.relpaceinputfieldObject.sectorKey = vm.requestLocationInputSearchObject.sector;
        vm.locationRequestNew.sectorObj.sectorTxt = vm.relpaceinputfieldObject.sectorKey;
      }
      //Replace input fields for Analyst Form Request
      if (vm.locationTypeName == vm.APP.LIBRARY.FORM_LOCATION_REQUEST) {
        vm.formReplaceSelect();
      }
    };

    vm.requestInit = function () {
      //StorageDelete.deleteInvestigation();---------Commenting this line so that the Analyst form can be opened properly on browser back from the Library Section.
      StorageDelete.deleteUser();

      $scope.setLibraryType();

      vm.sortOrderList = [
        {
          id: vm.APP.LIBRARY.REQUEST_SORT_BY_COUNT,
          value: vm.APP.LIBRARY.REQUEST_SORT_BY_COUNT_VALUE
        },
        {
          id: vm.APP.LIBRARY.REQUEST_SORT_BY_NAME,
          value: vm.APP.LIBRARY.REQUEST_SORT_BY_NAME_VALUE
        }
      ];
      //Default
      if (angular.isUndefined(vm.requestSortBy) || angular.isUndefined(vm.requestSortBy.value)) {
        vm.requestFilterObj.sortBy = vm.APP.LIBRARY.REQUEST_SORT_BY_COUNT;
        vm.requestFilterObj.sortOrder = vm.APP.GLOBAL.ASC;
      }

      //Sort by initialization through session storage
      if ($sessionStorage.requestSortBy) {
        for (var i = 0; i < vm.sortOrderList.length; i++) {
          if (vm.sortOrderList[i].id == $sessionStorage.requestSortBy) {
            vm.requestSortBy = vm.sortOrderList[i];
            break;
          }
        }

        vm.getRequestSortedList(vm.requestSortBy.id, true); // To get sort by list
      }
      if ($sessionStorage.formRequest) {
        vm.formRequest = $sessionStorage.formRequest;
      } else {
        vm.formRequest = false;
      }
      if ($sessionStorage.selectedRequestList) {
        qPromises.push(vm.getAllRequestFilters(false, vm.formRequest));
      } else {
        qPromises.push(vm.getAllRequestFilters(true, vm.formRequest));
      }

      $q.all(qPromises).then(function (data) {
        vm.getRequestList();
      });

      vm.getEntityTypeList();
    };

    //Show the validation message for form location request
    vm.validation = function () {
      if (vm.approveObj.countryTxt == null || vm.approveObj.countryTxt == "" || vm.approveObj.countryTxt == undefined) {
        vm.requestLocationInputSearchObject.countrySearchKey = "";
        vm.validationFlag = true;
      }
      if (
        vm.approveObj.provinceTxt == null ||
        vm.approveObj.provinceTxt == "" ||
        vm.approveObj.provinceTxt == undefined
      ) {
        vm.requestLocationInputSearchObject.provinceSearchKey = "";
        vm.validationFlag = true;
      }
      if (vm.approveObj.cityTxt == null || vm.approveObj.cityTxt == "" || vm.approveObj.cityTxt == undefined) {
        vm.requestLocationInputSearchObject.citySearchKey = "";
        vm.validationFlag = true;
      }
      if (vm.locationRequestOld.sectorTxt != null && vm.locationRequestOld.sectorTxt != "") {
        if (vm.approveObj.sectorTxt == null || vm.approveObj.sectorTxt == "" || vm.approveObj.sectorTxt == undefined) {
          vm.validationFlag = true;
          vm.sectorValidationFlag = true; //Show sector validation message
        }
      }
      $timeout(function () {
        vm.validationFlag = false;
        vm.sectorValidationFlag = false;
      }, 5000);
    };

    //Approve/Alter Location Request from Analyst Form
    vm.approveAlterFormLocationRequest = function (locationRequestNew, inputSearchObject, ev) {
      vm.approveObj = {};
      vm.sectorValidationFlag = false;
      vm.validationFlag = false;
      vm.isLocationApproveDisable = true;
      if (locationRequestNew) {
        vm.approveObj.id = locationRequestNew.id;

        if (
          (vm.locationRequestOld.countryId == null || inputSearchObject.countryAltered) &&
          inputSearchObject.countrySearchKey != null &&
          inputSearchObject.countrySearchKey != ""
        ) {
          vm.approveObj.countryTxt = inputSearchObject.countrySearchKey;
        } else if (
          locationRequestNew.countryObj != undefined &&
          locationRequestNew.countryObj.countryId != -1 &&
          locationRequestNew.countryObj.countryId != null
        ) {
          vm.approveObj.countryTxt = locationRequestNew.countryObj.countryTxt;
          vm.approveObj.countryId = locationRequestNew.countryObj.countryId;
        }

        if (
          (vm.locationRequestOld.provinceId == null || inputSearchObject.provinceAltered) &&
          vm.locationRequestOld.provinceTxt != null &&
          vm.locationRequestOld.provinceTxt != "" &&
          inputSearchObject.provinceSearchKey != null &&
          inputSearchObject.provinceSearchKey != ""
        ) {
          vm.approveObj.provinceTxt = inputSearchObject.provinceSearchKey;
        } else if (
          vm.locationRequestOld.provinceTxt != null &&
          vm.locationRequestOld.provinceTxt != "" &&
          locationRequestNew.provinceObj != undefined
        ) {
          vm.approveObj.provinceTxt = locationRequestNew.provinceObj.provinceTxt;
          vm.approveObj.provinceId = locationRequestNew.provinceObj.provinceId;
        }

        if (
          (vm.locationRequestOld.cityId == null || inputSearchObject.cityAltered) &&
          vm.locationRequestOld.cityTxt != null &&
          vm.locationRequestOld.cityTxt != "" &&
          inputSearchObject.citySearchKey != null &&
          inputSearchObject.citySearchKey != ""
        ) {
          vm.approveObj.cityTxt = inputSearchObject.citySearchKey;
        } else if (
          vm.locationRequestOld.cityTxt != null &&
          vm.locationRequestOld.cityTxt != "" &&
          locationRequestNew.cityObj != undefined
        ) {
          vm.approveObj.cityTxt = locationRequestNew.cityObj.cityTxt;
          vm.approveObj.cityId = locationRequestNew.cityObj.cityId;
        }

        if (
          locationRequestNew.cityObj != undefined &&
          locationRequestNew.cityObj.cityId != -1 &&
          locationRequestNew.cityObj.cityId != null
        ) {
          //Set merged Location mapping Id Value for City
          if (
            (vm.formCountryRequest || vm.formProvinceRequest || vm.formCityRequest) &&
            locationRequestNew.sectorObj.sectorId != -1 &&
            angular.isUndefinedOrNull(locationRequestNew.sectorObj.sectorId)
          ) {
            vm.approveObj.mergedLocationMappingId = vm.locationRequestNew.cityObj.locationMappingId;
          }
        }

        if (
          vm.locationRequestOld.sectorTxt != null &&
          vm.locationRequestOld.sectorTxt != "" &&
          locationRequestNew.sectorObj != undefined &&
          locationRequestNew.sectorObj.sectorId != -1 &&
          locationRequestNew.sectorObj.sectorId != null
        ) {
          vm.approveObj.sectorId = locationRequestNew.sectorObj.sectorId;
          vm.approveObj.sectorTxt = locationRequestNew.sectorObj.sectorTxt;
          vm.approveObj.mergedLocationMappingId = vm.locationRequestNew.sectorObj.locationMappingId; //Set merged Location mapping Id Value for City
        } else if (
          (vm.locationRequestOld.sectorTxt != null && locationRequestNew.sectorObj.sectorId == -1) ||
          locationRequestNew.sectorObj.sectorId == undefined
        ) {
          vm.approveObj.sectorTxt = locationRequestNew.sectorObj.sectorTxt;
          if (
            inputSearchObject.sectorSearchKey != null &&
            (inputSearchObject.sectorSearchKey != "" || inputSearchObject.sectorAltered)
          ) {
            vm.approveObj.sectorTxt = inputSearchObject.sectorSearchKey;
          }
        }
        //Check the location object for validation
        vm.validation();
        vm.approveObj.isMerge = vm.mergeFlag ? true : false;

        if (!vm.validationFlag) {
          var data = LibraryRequestService.saveApprovedRequestLists(vm.approveObj);
          data.then(
            function (response) {
              vm.msgFlag = false;
              $mdDialog.hide();
              notifications.showSuccess({ message: vm.APP.LIBRARY.LOCATION_REQUEST });
              vm.isLocationApproveDisable = false;
              if (vm.entityPopupId && vm.popUpId == vm.APP.LIBRARY.ENTITY_REQ) {
                $timeout(function () {
                  vm.msgFlagForLocation = false;
                  vm.popUpId = "";
                  vm.getEntityProfileById(vm.entityPopupId, false, ev);
                }, 1000);
              } else {
                vm.state.transitionTo(vm.state.current, vm.library, {
                  reload: true,
                  inherit: false,
                  notify: true
                });
              }
            },
            function (reason) {
              if (reason.data.statusCode == 409) {
                vm.msgFlag = true;
                vm.mergeFlag = true;
              } else {
                notifications.showError({ message: vm.APP.LIBRARY.LOCATION_REQUEST_PROBLEM });
              }
              vm.isLocationApproveDisable = false;
              return reason;
            }
          );
        } else {
          vm.isLocationApproveDisable = false;
        }
      } else {
        vm.isLocationApproveDisable = false;
      }
    };

    vm.formRequestChange = function (formRequest) {
      if (vm.media("gt-sm")) {
        vm.loadingListData = true;
      }
      $sessionStorage.formRequest = formRequest;
      vm.requestInit();
    };

    vm.requestInit();

		vm.validateLocationIds = function(locationValidation){
			if(locationValidation && locationValidation.countryId && locationValidation.provinceId && locationValidation.cityId){
				vm.locationIdValidation = true;
			}
		};
    
  }
})();
