(function() {
    'use strict';

    angular
        .module('lariAidApp')
        .config(stateConfig);
    stateConfig.$inject = ['$stateProvider','APP'];
    function stateConfig($stateProvider,APP) {
    	$stateProvider
    	.state('investigationForm', {
            parent: 'profile',
            data: {
                authorities: APP.GLOBAL.USER_GROUP
            },
            params: {
            	investigationFormId:null
            },
            views: {
             'content@': {
                    templateUrl: 'app/investigation/prospects/profile/investigation-form/investigation.form.header.html',
                    controller: 'InvestigationFormHeaderController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {                  
                    if(localStorage && localStorage.getItem('lari_currentVersion') != null){
                       	$translatePartialLoader.addPart('profile.json?version='+localStorage.getItem('lari_currentVersion'));
                       }else{
                       	$translatePartialLoader.addPart('profile.json?timestamp='+new Date().getTime());
                       }
                    if(localStorage && localStorage.getItem('lari_currentVersion') != null){
                       	$translatePartialLoader.addPart('library.json?version='+localStorage.getItem('lari_currentVersion'));
                       }else{
                       	$translatePartialLoader.addPart('library.json?timestamp='+new Date().getTime());
                       }                
                    return $translate.refresh();
                }]
            }
        })
    	.state('profileForm', {
            parent: 'investigationForm',
            //url: '/investigationForm',
            data: {
                authorities: APP.GLOBAL.USER_GROUP
            },
            params: {
            	investigationFormId:null
            },
            views: {
                'invesForm': {
                    templateUrl: 'app/investigation/prospects/profile/investigation-form/investigation.form.html',
                    controller: 'InvestigationFormController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    if(localStorage && localStorage.getItem('lari_currentVersion') != null){
                       	$translatePartialLoader.addPart('profile.json?version='+localStorage.getItem('lari_currentVersion'));
                       }else{
                       	$translatePartialLoader.addPart('profile.json?timestamp='+new Date().getTime());
                       }
                    
                    return $translate.refresh();
                }],
                sectionIndex:['investigationFormIndexService','$timeout', function (investigationFormIndexService,$timeout) {
                	investigationFormIndexService.setInvestigationValue("bithcerticateForm");
                	/*$timeout(function() {
                	    angular.element('#profile').triggerHandler('click');
                	 });*/
                }]
            }
        });
    }
})();