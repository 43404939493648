(function() {
	'use strict';

    angular
        .module('lariAidApp')
        .controller('SideCommentsDirectiveController', SideCommentsDirectiveController);
    
    SideCommentsDirectiveController.$inject = ['$rootScope','$scope','$state','$stateParams','$mdSidenav','$element','APP','tabservice','$mdMedia','SideCommentsDirectiveService','$q','$timeout','$sessionStorage','CommonService','$filter','$mdDialog'];
    
    function SideCommentsDirectiveController($rootScope,$scope,$state,$stateParams,$mdSidenav,$element,APP,tabservice,$mdMedia,SideCommentsDirectiveService,$q,$timeout,$sessionStorage,CommonService,$filter,$mdDialog){
    	$scope.showFlag = false;
    	$scope.showReplyButton = true;
    	$scope.selectedSortOrder;	
    	$scope.mediaCheck = $mdMedia;
    	$scope.limit = 3;
    	$scope.valueBeforeEdit ="";
    	$scope.app = APP;
    	
    	//Get user from cookie storage
 		$scope.user = CommonService.getCookieUser();
    	
    	$scope.editMode = false;
    	$scope.loggedInUser = CommonService.getCookieUser().cookieUser;
    	
    	/**
    	 * To show all comments in popup
    	 */
    	$scope.showAllComments = function(){
    		$scope.limit = $scope.modelObject.formQuestionCommentDTOs.length;
    	};
    	
    	/**
    	 * To Show less Comments
    	 */
    	$scope.showLessComments = function(){
    		$scope.limit = 3;
    	};
    	
    	/**
    	 * To open or close the comments box
    	 */
    	$scope.showComment = function(questionName){
    		$scope.showFlag = !$scope.showFlag;
    		$scope.loader = false;
    		$timeout(function(){
    			$("#SideCommentsDiv"+questionName).click(function(e){e.stopPropagation();});
    			
    			$("#SideCommentsDiv"+questionName).on('mouseover', function (e) {
    	        	if($rootScope.sideCommentVisible){
    	        		$rootScope.sideCommentVisible = false;
    	        	}
    	        });
    			
    			$("#SideCommentsDiv"+questionName).on('click', function (e) {
    	        	if($rootScope.sideCommentVisible){
    	        		$rootScope.sideCommentVisible = false;
    	        	}
    	        });
    			 
    		},50);
    		
    		//To maintain the access to the form
    		$scope.changeVisibilityTrue();
    	};
    	
    	/**
    	 * To show or hide the Reply box
    	 */
    	$scope.hideReply = function(){
        	$scope.showReplyButton = !$scope.showReplyButton;
        }
    	
    	/**
    	 * To show/hide Edit and Delete option
    	 */
        $scope.openMenu = function($mdOpenMenu, ev) {
            $mdOpenMenu(ev);
        };
        
        /**
         * To edit the comment
         */
        $scope.editComment = function(sortOrder){
        	$scope.selectedSortOrder = sortOrder;
        	$scope.editMode = !$scope.editMode;
        	$scope.openPopup = false;
        	if($scope.showReplyButton == false) {
        		$scope.showReplyButton = true;
        	}
        };
        
        /**
         * To Update a comment
         */
        $scope.updateComment = function(changedComment){
        	angular.forEach($scope.modelObject.formQuestionCommentDTOs,function(data,key){
        		if(data.id == changedComment.id){
        			$scope.valueBeforeEdit = data.commentData;
        		}
        	});
        	if(changedComment.commentData != ""){
        		if(changedComment.commentData != $scope.valueBeforeEdit){  
            		if(changedComment.sortOrder == 0){
                		//update Parent
                		$scope.updateParentComment(changedComment);
                	}else{
                		//update Child
                		$scope.updateChildComment(changedComment);
                	}       		
            	}else{
            		$scope.cancelCommentUpdate();
            	}
            	$scope.valueBeforeEdit = "";
        	}
        };
        
        /**
         * To Update a Parent Comment
         */
        $scope.updateParentComment = function(comment){
        	var formId = $scope.investigationId;
        	var parentId = $scope.modelObject.formQuestionCommentDTOs[0].id;
        	var dataObj = {};
        	
        	dataObj.questionKey = $scope.questionName;
        	dataObj.formQuestionCommentDTOs = [];
        	
        	var tempObj ={};
        	tempObj.commentData = comment.commentData;
        	
        	dataObj.formQuestionCommentDTOs.push(tempObj);
        	
        	var data = SideCommentsDirectiveService.updateParentComment(formId,parentId,dataObj);
 		  	data.then(function(response){
 		  		$scope.updateResponse(comment,response);
 		  	}, function(reason) {
 			  return reason;
 		  	});

        };
        
        /**
         * To update Child Comment
         */
        $scope.updateChildComment = function(comment){
        	var formId = $scope.investigationId;
        	var parentId = $scope.modelObject.formQuestionCommentDTOs[0].id;
        	var dataObj = {};
        	
        	dataObj.questionKey = $scope.questionName;
        	dataObj.formQuestionCommentDTOs = [];
        	
        	var tempObj ={};
        	tempObj.commentData = comment.commentData;
        	tempObj.sideCommentParentId = comment.sideCommentParentId;
        	tempObj.id = comment.id;
        	
        	dataObj.formQuestionCommentDTOs.push(tempObj);
        	
        	var data = SideCommentsDirectiveService.updateChildComment(formId,parentId,comment.id,dataObj);
 		  	data.then(function(response){
 		  		$scope.updateResponse(comment,response);
 		  	}, function(reason) {
 			  return reason;
 		  	});

        };
        
        /**
         * To set the updated response
         */
        $scope.updateResponse = function(changedComment,response){
        	
        	changedComment.isEdited = true;
        	var dateVal = response.updatedOn;
    	    dateVal = $filter('date')(dateVal, "MMM d, y h:mm:ss a");
        	changedComment.updatedOn = dateVal;
        	$scope.editMode = false;
        	$scope.selectedSortOrder = undefined;
        	var data = SideCommentsDirectiveService.getSideCommentsBySectionQuestion($scope.investigationId,$scope.formName,$scope.accordionIndex,$scope.questionName);
        	data.then(function(response){
        		$scope.modelObject = response;
        		$scope.copyModelObject = angular.copy($scope.modelObject);
        	})
        };
               
        /**
         * To Cancel a comment edit
         */
        $scope.cancelCommentUpdate = function(comment){
        	if(!angular.isUndefinedOrNull(comment)) {
        		angular.forEach($scope.copyModelObject.formQuestionCommentDTOs, function(data,key){
            		if(data.id == comment.id && data.commentData != comment.commentData) {
            			comment.commentData = data.commentData;
            		}
            	})
        	}
        	
        	
        	$scope.editMode = false;
        	$scope.selectedSortOrder = undefined;
        };
        
        /**
         * To add a new comment
         */
        $scope.addComment = function(comment){
        	if($scope.user.loggedUser == $scope.app.GLOBAL.ROLE_READ_ONLY){
        		return;
        	}
        	if(!angular.isUndefinedOrNull(comment)){
            	$scope.responsedata = {};
            	if($scope.modelObject.formQuestionCommentDTOs.length == 0){
            		$scope.saveParentComment(comment);
            	}else{
            		$scope.saveChildComment(comment);
            	}       	
        	}      	
        };
        
        /**
         * To Get the Comment List before adding or editing a comment
         */
        $scope.getCommentDTO = function(comment,newCommentFlag){
    		$scope.loader = true;
        	var data = SideCommentsDirectiveService.getSideCommentsBySectionQuestion($scope.investigationId,$scope.formName,$scope.accordionIndex,$scope.questionName);
        	data.then(function(response){
        		$scope.modelObject = response;
        		if(angular.equals($scope.modelObject,{})){
        			$scope.setEmptyModelObject();
        		}
        		if(newCommentFlag == $scope.app.GLOBAL.TRUE){
            		$scope.addComment(comment);
        		}else{
        			$scope.updateComment(comment);
        		}
        		$scope.loader = false;
        	})
        };
        
        /**
         * Set comment data object
         */
        $scope.setCommentObject = function(comment){
        	$scope.tempComment = {};
    		$scope.tempComment.sortOrder = $scope.modelObject.formQuestionCommentDTOs.length;
        	$scope.tempComment.commenterId;
        	$scope.tempComment.isOwner = true;
        	$scope.tempComment.commentData = comment;
        	$scope.tempComment.isEdited = false;
        	
        	$scope.tempComment.commenterName = $scope.responsedata.commenterName;
        	$scope.tempComment.createdOn = $scope.responsedata.createdOn;
        	$scope.tempComment.updatedOn = $scope.responsedata.updatedOn;
        	$scope.tempComment.id = $scope.responsedata.id;
        	$scope.tempComment.isParentComment = true;
        	if($scope.modelObject.formQuestionCommentDTOs.length != 0){
            	$scope.tempComment.sideCommentParentId = $scope.modelObject.formQuestionCommentDTOs[0].id;
            	$scope.tempComment.isParentComment = false;
            	$scope.hideReply();
        	}

        	$scope.modelObject.formQuestionCommentDTOs.push($scope.tempComment);
        	if(!$scope.mediaCheck('gt-sm')) {
  	  			$scope.limit = $scope.modelObject.formQuestionCommentDTOs.length;
  	  		}
        	$scope.replyComment="";
        	$scope.copyModelObject = angular.copy($scope.modelObject);
        };
        
        /**
         * To Save Parent Comment
         */
        $scope.saveParentComment = function(comment){
        	var dataObj = {};
        	var formId = $scope.investigationId;
        	var responseData = {};

        	dataObj.type = $scope.formName;
        	dataObj.accordionIndex = $scope.accordionIndex;
        	dataObj.formQuestionDTOs = [];
        	
        	var tempObj ={};
        	tempObj.questionKey = $scope.questionName;
        	tempObj.formQuestionCommentDTOs =[];
        	
        	var tempComment = {};
        	tempComment.commentData = comment;
        	
        	tempObj.formQuestionCommentDTOs.push(tempComment);
        	dataObj.formQuestionDTOs.push(tempObj);
       	
        	var data = SideCommentsDirectiveService.saveParentComment($scope.investigationId,dataObj);
        	data.then(function(response){
        		$scope.responsedata = response;
        		//$rootScope.getSectionDTOs($scope.investigationId,$scope.formName,$scope.accordionIndex);
        		$scope.setCommentObject(comment);
 		  	}, function(reason) {
 			  return reason;
 		  	});
        };
        
        /**
         * To save a child comment
         */
        $scope.saveChildComment =function(comment){
        	var parentId = $scope.modelObject.formQuestionCommentDTOs[0].id;
        	var dataObj ={};
        	var responseData = {};
        	var formId = $scope.investigationId;
        	dataObj.questionKey = $scope.questionName;
        	dataObj.formQuestionCommentDTOs =[];
        	
        	var tempComment = {};
        	tempComment.commentData = comment;
        	tempComment.sideCommentParentId = parentId;
        	
        	dataObj.formQuestionCommentDTOs.push(tempComment);
        	
        	var data = SideCommentsDirectiveService.saveChildComment(formId,dataObj,parentId);
 		  	data.then(function(response){
 		  		$scope.responsedata = response;
 		  		//$rootScope.getSectionDTOs($scope.investigationId,$scope.formName,$scope.accordionIndex);
        		$scope.setCommentObject(comment);
 		  	}, function(reason) {
 			  return reason;
 		  	});
        };
                
        /**
         * Clear Text Field
         */
        $scope.clearTxt = function(comment){
        	comment = "";
        	$scope.commentContent = "";
        };
        
        /**
         * To Delete a comment
         */
        $scope.deleteComment = function(commentSortId,commentId){
        	$scope.openPopup = false;
        	$scope.arrayIndex = "";
        	var data = SideCommentsDirectiveService.getSideCommentsBySectionQuestion($scope.investigationId,$scope.formName,$scope.accordionIndex,$scope.questionName);
        	data.then(function(response){
        		$scope.modelObject = response;
        		
        		if(angular.equals($scope.modelObject,{})){
        			$scope.setEmptyModelObject();
        		}else{
        			var parentId = $scope.modelObject.formQuestionCommentDTOs[0].id;
        		}
        		
            	if(commentSortId == 0 && $scope.modelObject.formQuestionCommentDTOs.length != 0){
            		//call Parent delete api
                	SideCommentsDirectiveService.deleteParentComment($scope.investigationId,parentId);
            		$scope.modelObject.formQuestionCommentDTOs = [];
            		$scope.limit = 3;
            	}else if($scope.modelObject.formQuestionCommentDTOs.length > 1 && commentSortId < $scope.modelObject.formQuestionCommentDTOs.length){
            		//commentSortId--;
                	SideCommentsDirectiveService.deleteChildComment(parentId,commentId);

                	angular.forEach($scope.modelObject.formQuestionCommentDTOs,function(data,key){
                		if(data.id == commentId) {
                			$scope.arrayIndex = key;
                		}
                	});
                	
                	$scope.modelObject.formQuestionCommentDTOs.splice($scope.arrayIndex,1);
                	for(var i=0;i < $scope.modelObject.formQuestionCommentDTOs.length;i++){
                		$scope.modelObject.formQuestionCommentDTOs[i].sortOrder = i;
                	}
            	}
            	$scope.copyModelObject = angular.copy($scope.modelObject);
        	});
        };
                
        /**
         * To return true value if the variable is Undefined/Null/Empty
         */
        angular.isUndefinedOrNull = function(val) {
	  	    return angular.isUndefined(val) || val == "" || val == null
	  	};
	  	
	  	/**
	  	 * To close the pop-up screen for moible or Ipad
	  	 */
	  	$scope.closeMobileSideComment = function(){
    		$mdDialog.cancel();
    	};
    	
    	/**
    	 * To Create Empty Model Object
    	 */
	  	$scope.setEmptyModelObject = function(){
	  		var tempObj = {};
  			tempObj.questionKey = $scope.questionName;
  			tempObj.formQuestionCommentDTOs = [];
  			$scope.modelObject = tempObj;
	  	};
	  	
	  	/**
	  	 * Init function
	  	 */
	  	$scope.getModelObject = function(){
	  		
	  		if($scope.objectList && $scope.objectList.length != 0){	  			
	  			angular.forEach($scope.objectList, function(data, key) {
    				if(data.questionKey == $scope.questionName){
    					$scope.modelObject = {};
    					$scope.modelObject = data;    	
    					$scope.copyModelObject = angular.copy($scope.modelObject);
    				}
    			});
	  		}
	  		
	  		$scope.popupId = $scope.questionName+$scope.accordionIndex;
	  		
	  		if(angular.isUndefinedOrNull($scope.modelObject)){
	  			$scope.setEmptyModelObject();
	  		}
	  	};
	  	//init call
	  	$scope.getModelObject();
	  	    
          $scope.changeVisibilityFalse = function(){
              $rootScope.sideCommentVisible = false;
          };
          
          $scope.changeVisibilityTrue = function(){
              $rootScope.sideCommentVisible = true;
          };

          /**
           * To open the pop-up for mobile designs
           */
          $scope.showMobileSideComment = function(ev,id){
  	  		$scope.showAllComments();
  	  		$scope.loader = false;
  	  		$scope.editLoader = false;
      		$mdDialog.show({                   
                  targetEvent: ev,
                  contentElement: '#'+id,
                  parent: angular.element(document.body),
                  clickOutsideToClose:true,
                  escapeToClose: true
             });
      	};
      	
	  	
    }
})();