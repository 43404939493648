(function() {
	'use strict';
	
	var lariDevApp = angular
    .module('lariAidApp')
    lariDevApp.controller('ResidencyQuestionsController', ResidencyQuestionsController);
	
	ResidencyQuestionsController.$inject = ['$scope','$window', '$mdSidenav','Principal','$state','$mdMedia','$cookies','APP','ResidencyQuestionsService','$stateParams','InvestigationFormService','$timeout','$rootScope','$mdDialog'];
	function ResidencyQuestionsController ($scope,$window, $mdSidenav,Principal,$state,$mdMedia,$cookies,APP,ResidencyQuestionsService,$stateParams,InvestigationFormService,$timeout,$rootScope,$mdDialog) {
		
		var vm = this;
		vm.app = APP;
		vm.profileForm = $stateParams;
		$scope.residencyQuestions = {};
    	$scope.residencyQuestions.sections = [];
    	//$scope.residencyQuestions.sections.questions = [];
    	$scope.residencyQuestions.summary = {};
    	$scope.loadingResidency = false;
    	var oldFormData = {};
    	
           vm.addAdditionalSchoolForm = function(){
               var testObj = {};
              $scope.residencyQuestions.sections[0].questions[0].residency2_2a.interviews.push(testObj);
           };
           
           vm.addAdditionalCountryForm = function(){
               var testObj1 = {};
               $scope.residencyQuestions.sections[0].questions[0].residency1_1a.interviews.push(testObj1);
           };
    	
           vm.initLoadResidencyQuestions = function(){
        	   $scope.loadingResidency = true;
    		   vm.getResidencyQuestionDetails();
          	 
             };   
           
             vm.getResidencyQuestionDetails = function (){
            	 var sectionKey = vm.app.REF_TERM.RESIDENCY_QUESTIONS;
        		   var formId = $scope.investigationFormId;
            	   var data = InvestigationFormService.getInvestigationFormBySectionId(sectionKey,formId);
        		  	data.then(function(response){
        		  		if(response.formVersion != null){
         		  			$scope.formVersion = response.formVersion;
         		  		}
        		  		if(response.sectionId != null){
    			  			$scope.residencySectionId = response.sectionId;
    			  		}
        		  		if(response.formDTO == null){
        		  		   var tempObj = {};
        		     	   tempObj.questions = [];
        		     	   $scope.residencyQuestions.sections.push(tempObj);
        		  		} else if(response.formDTO.sections.length == 0) {
       				  	   if(response.formDTO.summary){
  						     var tempObj = {};
  						  	   tempObj.questions = [];
  				  	           $scope.residencyQuestions.sections.push(tempObj);
  				  	           $scope.residencyQuestions.summary = response.formDTO.summary;
	  				  	   } else {
	  						  	   var tempObj = {};
	  						  	   tempObj.questions = [];
	  				  	           $scope.residencyQuestions.sections.push(tempObj);
	  				  	   }
	  		  	       } else {
	  		  	    	   $scope.residencyQuestions = response.formDTO;	  		  	    	   
	  		  	       }
        		  	    //Set the object to restrict the initial save call
	  		  	    	oldFormData = angular.copy(response.formDTO);
	        		  	$scope.loadingResidency = false;
	        		  	$scope.updateLastEditTime(response.updatedOn);
	        		   }, function(reason) {
	        			  return reason;
	        		   });
             };
             
	     /* Method name            : saveResidencyQuestionsDetails()
	      * Method Description 	   : This method saves the residency form details 
	 	  */     
    	$scope.saveResidencyQuestionsDetails = function(){
    	   var isPutJsonSimilar = false;
    	   $rootScope.warningPopup.show = false; //To avoid showing popup while moving to other section if there is no edit in current section
      	   var saveDataObj = {};
      	   saveDataObj.formDTO = angular.copy($scope.residencyQuestions);
      	   saveDataObj.formVersion = $scope.formVersion;
      	   saveDataObj.investigationFormId = $scope.investigationFormId;
      	   saveDataObj.sectionId = $scope.residencySectionId;
	      	 for(var i=0;i<saveDataObj.formDTO.sections.length;i++){
	             if(!saveDataObj.formDTO.sections[i].questions){
	                 saveDataObj.formDTO.sections.splice(i,1);
	                 i++;
	             } else if(saveDataObj.formDTO.sections[i].questions.length == 0) {
	                 saveDataObj.formDTO.sections.splice(i,1);
	                 i++;
	             } else {
	                 delete saveDataObj.formDTO.sections[i].expanded;
	                 saveDataObj.formDTO.sections[i].sortOrder = i+1;
	             }
	         }
	      	 
	      	 /*	Set the temporary object to avoid unwanted save call. 
   				Fist condition to avoid the initial save call for empty form 
   				Second condition to avoid the initial save call if the section has any form data 
	      	 */
             if(angular.isUndefined($scope.tempResidencyObj) && saveDataObj.formDTO.sections.length == 0 && oldFormData == null && 
                     (saveDataObj.formDTO.summary.comment == null || saveDataObj.formDTO.summary.comment == undefined)) {
                 $scope.tempResidencyObj = angular.copy(saveDataObj);
             } else if(angular.isUndefined($scope.tempResidencyObj) && angular.equals(JSON.stringify(saveDataObj.formDTO), JSON.stringify(oldFormData))) {
          	   $scope.tempResidencyObj = angular.copy(saveDataObj);
             } else if(angular.isUndefined($scope.tempResidencyObj) && !angular.equals(JSON.stringify(saveDataObj.formDTO), JSON.stringify(oldFormData))) {
            	 $scope.tempResidencyObj = {};
            	 $scope.tempResidencyObj.formDTO = angular.copy(oldFormData);
             } 
	      	
             if(!angular.isUndefined($scope.tempResidencyObj)){
            	 if(angular.equals(JSON.stringify(saveDataObj.formDTO), JSON.stringify($scope.tempResidencyObj.formDTO))){
            		 isPutJsonSimilar = true;	    			  
            	 } 
             }
             if($scope.isOwner){
            	 $scope.tempResidencyObj = angular.copy(saveDataObj);
             } else {
            	 $scope.tempResidencyObj = undefined; //Every time temporary object will be newly updated for non owner 
             }

	      	/* saveData flag - to ensure the save button is clicked in form*/
	       if(!isPutJsonSimilar){
	    	   if($scope.isOwner || $scope.saveData){
	    		   var data = InvestigationFormService.saveInvestigationForm(saveDataObj);
		   		  	data.then(function(response){
		  		  		if($state.current.name === vm.app.INVESTIGATION.STATE.RESIDENCY_STATE)
		  		  		{
		  		  			$scope.updateLastEditTime(response.updatedOn);
		  		  		}
		  		  		$scope.uploadFileStatusUpdated();
		   		  }, function(reason) {
		   			  return reason;
		   		  });
	    	   }
	    	   $rootScope.warningPopup.show = true;
	       }
         };
         
         vm.nextInvestigationForm = function(){
        	$scope.saveData = true;
        	$scope.saveResidencyQuestionsDetails(); 
        	//$state.go("other");
        	$rootScope.warningPopup.show = false;
            $scope.sectionNavigationFunc(vm.app.INVESTIGATION_FORM.OTHER_FORM);
        	if($scope.investigationFormType == vm.app.INVESTIGATION.ANALYST_FORM){
				$scope.collapseAll(8);
			}
			$scope.updateLastEditTime(null);
 		 };
 		
 		 /* File Upload Sections */
         
         
         vm.uploadFileForSummary = function(isSummary,accordionIndex,idName,ev){
     		$("#residencySectionSummary").val(null);
  	        $('#residencySectionSummary').trigger('click'); 
  	        $('#residencySectionSummary').off('change').on('change', function() {
	  	    	var file = this.files[0];
	     		var sectionId = $scope.residencySectionId;
	     		$scope.setOrginSectionFileUpload(vm.app.REF_TERM.RESIDENCY_QUESTIONS);
	     		$scope.uploadFileInSectionSummary(ev,file,vm.app.INVESTIGATION_FORM.SUMMARY_KEY,sectionId,isSummary);
	        });
  		};

  		/*vm.uploadFileInSectionSummary = function(file){
     		vm.submittedAsset = {};
     		vm.submittedAsset.fileName = file.name;
  		};*/
  			
		$scope.removeAttachment = function(element){
			$(element.target.parentElement).remove();
		};
		
		 $rootScope.warningPopup.show = false;
		/* Method Description 	: This method triggers auto save for birth certificate form */
	       $scope.$watch('residencyQuestionsAutoSave', function(newValue, oldValue) {
				if(newValue != undefined && newValue != oldValue){					
					if(newValue && $scope.isOwner){						
							if($scope.formVersion && !$scope.isreadonly){
								$scope.saveResidencyQuestionsDetails();
								$rootScope.residencyQuestionsAutoSave = false;
							} else{
								$rootScope.residencyQuestionsAutoSave = false;
							}
						}else{
							$rootScope.residencyQuestionsAutoSave = false;
						}					
				}
			});
	       
	     //It will get triggered while exiting from current state
	       $scope.$on('$destroy', function() {
	    	   if(!$scope.isreadonly && $scope.isOwner) {
	    		   $scope.saveResidencyQuestionsDetails();
	        	}
	    	   $rootScope.warningPopup.show = false;
	    	   $scope.uploadedFile = false;
	       });
	       
	       vm.closeCancel = function(){
	    	   $mdDialog.cancel();
	       };

		   	//To open the confirmation pop up for remove the additional interview or records
	       vm.deleteIndex = function(event,id,index,isCountry){
	    	   $scope.indexVal = index;
	    	   $scope.isCountry = isCountry;
	    	   $mdDialog.show({
	    		   contentElement: angular.element('#'+id),
	    		   targetEvent: event,
	    		   clickOutsideToClose: false,
	    		   escapeToClose: false,
	    		   onRemoving: function (event, removePromise) {
	    			   vm.closeCancel();
	    		   }
	    	   });
	       };
	       	
	    	//To remove the school records form the form data
	       	vm.deleteSchoolConfirm = function(index){
	       		$scope.residencyQuestions.sections[0].questions[0].residency2_2a.interviews.splice(index,1)
	       	    vm.closeCancel();
	       	};
	       	
	       	//To remove the country records form the form data
	       	vm.deleteCountryConfirm = function(index){
	       		$scope.residencyQuestions.sections[0].questions[0].residency1_1a.interviews.splice(index,1)
	       	    vm.closeCancel();
	       	};
	}
})();