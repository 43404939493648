(function () {
  "use strict";

  var lariDevApp = angular.module("lariAidApp");
  lariDevApp.controller("ManageAttachmentsController", ManageAttachmentsController);

  ManageAttachmentsController.$inject = [
    "$scope",
    "$mdMedia",
    "$mdDialog",
    "CommonService",
    "InvestigationFormService",
    "APP",
    "$window",
    "BASE_URL"
  ];
  function ManageAttachmentsController(
    $scope,
    $mdMedia,
    $mdDialog,
    CommonService,
    InvestigationFormService,
    APP,
    $window,
    BASE_URL
  ) {
    var vm = this;
    $scope.assetSelectedCount = 0;
	$scope.baseUrl = BASE_URL;
    vm.lang = CommonService.getCookieLanguage();
    $scope.media = $mdMedia;
    vm.APP = APP;
    vm.managePopupId = {};
    $scope.showDeleteConfirmPopup = false;
    $scope.isAllSelected = false;

    //To open the manage attachment popup.
    $scope.showDialog = function (event, popupId) {
      angular.forEach($scope.assetList, function (itm) {
        itm.isSelected = false;
      });
      $scope.assetSelectedCount = 0;
      $scope.isAllSelected = false;
      if ($scope.assetList && $scope.assetList.length > 0) {
        $mdDialog.show({
          contentElement: angular.element("#" + popupId + "_" + $scope.idName),
          parent: angular.element(document.body),
          targetEvent: event,
          clickOutsideToClose: false,
          escapeToClose: false
        });
      } else {
        $mdDialog.hide();
      }
    };
    $scope.closeCancel = function () {
      $mdDialog.hide();
      $scope.showDeleteConfirmPopup = false;
    };
    $scope.closeOpen = function (event) {
      //$mdDialog.hide();
      $scope.recordFlag = true;
      $scope.showDeleteConfirmPopup = false;
      $scope.showDialog(event, "manageAttachmentPopupId");
    };

    //Method to view the attachment in seperate tab. Prevent the parent element select functionality
    $scope.viewAttachment = function (url, assetId, event) {
      var previewUrl =  $scope.baseUrl + url + assetId + "/LARI_AID";
      $window.open(previewUrl, "_blank");
      if (event.stopPropagation) event.stopPropagation();
      if (event.preventDefault) event.preventDefault();
      event.cancelBubble = true;
      event.returnValue = false;
    };

    /*to select and deselect the preview image*/
    $scope.toggle = function (isSelected, event) {
      if (
        event.target.className != vm.APP.INVESTIGATION_FORM.VIEW_BUTTON_CLICK &&
        $scope.isReadOnly == vm.APP.GLOBAL.FALSE.toLowerCase()
      ) {
        if (isSelected) {
          $scope.assetSelectedCount--;
          $scope.isAllSelected = false;
        } else {
          $scope.assetSelectedCount++;
        }
        if ($scope.assetList.length == $scope.assetSelectedCount) {
          $scope.isAllSelected = true;
        } else {
					$scope.isAllSelected = false;
			  }
        return !isSelected;
      }
    };

    /*To select all the preview image in the popup*/
    $scope.toggleAll = function () {
      if(($scope.assetList.length != $scope.assetSelectedCount) || ($scope.assetSelectedCount == 0)){$scope.assetSelectedCount = angular.copy($scope.assetList.length);
        angular.forEach($scope.assetList, function (itm) {
          itm.isSelected = true;
        });
        $scope.isAllSelected = true;
      } else {
        $scope.assetSelectedCount = 0;
        angular.forEach($scope.assetList, function (itm) {
          itm.isSelected = false;
        });
        $scope.isAllSelected = false;
      }
    };

    /*To open the delete confirmation warn popup*/
    $scope.deleteAllFileConfirm = function (event, assetList) {
      $scope.recordFlag = false;
      $scope.assetList = assetList;
      $scope.showDeleteConfirmPopup = true;
    };

    /*
     * To delete the selected elements from the popup
     */
    $scope.deleteMultipleFile = function (event, assetList) {
      var assetFullList = angular.copy(assetList);
      $scope.assetDeleteList = [];
      angular.forEach(assetFullList, function (asset) {
        if (asset.isSelected) {
          $scope.assetDeleteList.push(asset.id);
        }
      });
      $scope.assetList = $scope.assetList.filter(function (asset) {
        return asset.isSelected == false;
      });
      var data = InvestigationFormService.deleteAllFiles($scope.assetDeleteList);
      data.then(function (response) {
        //$mdDialog.hide();
        $scope.recordFlag = true;
        $scope.showDeleteConfirmPopup = false;
        $scope.showDialog(event, "manageAttachmentPopupId");
      });
    };
  }
})();
