(function() {
	'use strict';

	angular.module('lariAidApp').directive('focus', focus);
	//resize.inject['$window'];
	
	function focus() {
			  return {
			    restrict: 'A',
			    require: ['^^mdSelect', '^ngModel'],
			    link: function(scope, element, controller) {
			      scope.$watch(function () {
			        var foundElement = element;
			        while (!foundElement.hasClass('md-select-menu-container')) {
			          foundElement = foundElement.parent();
			        }
			        return foundElement.hasClass('md-active');
			      	}, function (newVal) {
				        if (newVal) {
				            element.focus();
				        }
			      	});
			    }
			  }
	}
})();