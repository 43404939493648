(function() {
	'use strict';

	var lariDevApp = angular
	.module('lariAidApp')
	lariDevApp.controller('SummaryController', SummaryController);

	SummaryController.$inject = ['$scope','$window', '$mdSidenav','Principal','$state','$mdMedia','$cookies','APP','SummaryService','$stateParams','$timeout','InvestigationFormService','$mdDialog','$rootScope','tmhDynamicLocale'];
	function SummaryController ($scope,$window, $mdSidenav,Principal,$state,$mdMedia,$cookies,APP,SummaryService,$stateParams,$timeout,InvestigationFormService,$mdDialog,$rootScope,tmhDynamicLocale) {

		var vm = this;
		vm.profileForm = $stateParams;
		vm.investigation = {};

		$scope.summaryForm = {};
		$scope.summaryForm.sections = [];
		//$scope.summaryForm.sections.questions = [];
		$scope.loadingSummary = false;
		var oldFormData = {};
		vm.noInvestigationFormExistForCountryAdmin = false;

		vm.APP = APP;

		vm.loadSummaryFormDetails = function(){
			$scope.loadingSummary = true;
			vm.getSummaryDetails();

		};

		vm.getSummaryDetails = function (){
			var sectionKey = vm.APP.REF_TERM.SUMMARY;
			var formId = $scope.investigationFormId;
			var data = InvestigationFormService.getInvestigationFormBySectionId(sectionKey,formId);
			data.then(function(response){
				if(response.formVersion != null){
					$scope.formVersion = response.formVersion;
				}
				if(response.sectionId != null){
					$scope.summarySectionId = response.sectionId;
				}
				if(response.formDTO == null){
					var tempObj = {};
					tempObj.questions = [];
					$scope.summaryForm.sections = [];
					$scope.summaryForm.sections.push(tempObj);
				} else {
					$scope.summaryForm = response.formDTO;
					//Set the object to restrict the initial save call
					oldFormData = angular.copy(response.formDTO);
				}
				$scope.loadingSummary = false;
				$scope.updateLastEditTime(response.updatedOn);
			}, function(reason) {
				return reason;
			});
		};


		vm.saveSideNavCheckList = function(){
			if($scope.investigationFormType == vm.APP.INVESTIGATION.ANALYST_FORM){
				$scope.saveAnalystFormTypeCheckList('birthCertificate');
				$scope.saveAnalystFormTypeCheckList('birthMedical');
				$scope.saveAnalystFormTypeCheckList('neighbourhood');
				$scope.saveAnalystFormTypeCheckList('family');
				$scope.saveAnalystFormTypeCheckList('school');
				$scope.saveAnalystFormTypeCheckList('religious');
			}  
		};

		/* Method name               : saveSummaryFormDetails()
		 * Method Description 	   : This method saves the summary form details 
		 */ 
		$scope.saveSummaryFormDetails = function(submitResult){
			var isPutJsonSimilar = false;
			vm.isGoBackDisable = true;
			var saveDataObj = {};
			$rootScope.warningPopup.show = false; //To avoid showing popup while moving to other section if there is no edit in current section
			if(submitResult == 1 && $scope.investigationFormType == vm.APP.INVESTIGATION.INVESTIGATOR_FORM){
				if($scope.summaryForm.sections[0].questions[0] == undefined){
					$scope.summaryForm.sections[0].questions[0] = {};
				}
				if($scope.summaryForm.sections[0].questions[0].summary_age == null || $scope.summaryForm.sections[0].questions[0].summary_age == undefined){
					$scope.summaryForm.sections[0].questions[0].summary_age = {};
					$scope.summaryForm.sections[0].questions[0].summary_age.answer = vm.APP.INVESTIGATION.SUMMARY_NA;
				}
				if($scope.summaryForm.sections[0].questions[0].summary_identity == null || $scope.summaryForm.sections[0].questions[0].summary_identity == undefined){
					$scope.summaryForm.sections[0].questions[0].summary_identity = {};
					$scope.summaryForm.sections[0].questions[0].summary_identity.answer = vm.APP.INVESTIGATION.SUMMARY_NA;
				}
			}
			if(submitResult == 2 && $scope.investigationFormType == vm.APP.INVESTIGATION.INVESTIGATOR_FORM){
				if($scope.summaryForm.sections[0].questions[0] != undefined && $scope.summaryForm.sections[0].questions[0].summary_age != null && $scope.summaryForm.sections[0].questions[0].summary_age.answer == vm.APP.INVESTIGATION.SUMMARY_NA){
					$scope.summaryForm.sections[0].questions[0].summary_age = undefined;
				}
				if($scope.summaryForm.sections[0].questions[0] != undefined && $scope.summaryForm.sections[0].questions[0].summary_identity != null && $scope.summaryForm.sections[0].questions[0].summary_identity.answer == vm.APP.INVESTIGATION.SUMMARY_NA){
					$scope.summaryForm.sections[0].questions[0].summary_identity = undefined;
				}
			}
			saveDataObj.formDTO = angular.copy($scope.summaryForm);
			saveDataObj.formVersion = $scope.formVersion;
			saveDataObj.investigationFormId = $scope.investigationFormId;
			saveDataObj.sectionId = $scope.summarySectionId;

			/*	Set the temporary object to avoid unwanted save call. 
 				Fist condition to avoid the initial save call for empty form 
 				Second condition to avoid the initial save call if the section has any form data 
			 */
			if(angular.isUndefined($scope.tempSummaryObj) && saveDataObj.formDTO.sections[0].questions.length == 0) {
               $scope.tempSummaryObj = angular.copy(saveDataObj);
			} else if(angular.isUndefined($scope.tempSummaryObj) && angular.equals(JSON.stringify(saveDataObj.formDTO), JSON.stringify(oldFormData))) {
        	   $scope.tempSummaryObj = angular.copy(saveDataObj);
			}  else if(angular.isUndefined($scope.tempSummaryObj) && !angular.equals(JSON.stringify(saveDataObj.formDTO), JSON.stringify(oldFormData))) {
				$scope.tempSummaryObj = {};
				$scope.tempSummaryObj.formDTO = angular.copy(oldFormData);
			} 

			if(!angular.isUndefined($scope.tempSummaryObj)){
				if(angular.equals(JSON.stringify(saveDataObj.formDTO), JSON.stringify($scope.tempSummaryObj.formDTO))){
					isPutJsonSimilar = true;					
				}
			}
			if($scope.isOwner){
				$scope.tempSummaryObj = angular.copy(saveDataObj);
			} else {
				$scope.tempSummaryObj = undefined; //Every time temporary object will be newly updated for non owner 
			}
			
			if(!isPutJsonSimilar){
				if($scope.isOwner || $scope.saveData){
					var data = InvestigationFormService.saveInvestigationForm(saveDataObj);
					data.then(function(response){
		  		  		if($state.current.name === vm.APP.INVESTIGATION.STATE.SUMMARY_STATE)
		  		  			$scope.updateLastEditTime(response.updatedOn);
					}, function(reason) {						
						return reason;
					});	
				}								
				$rootScope.warningPopup.show = true;
				vm.isGoBackDisable = false;
			} else {
				vm.isGoBackDisable = false;
			}
		};
		vm.summaryValidationFunc = function(event){
			$scope.summaryValidation(event);
		};

		/*vm.reportResult = function(ev,id){
      		$mdDialog.show({
     			 contentElement: angular.element('#'+id),
     		      targetEvent: ev,
     		      clickOutsideToClose:true
     		    })
        };*/

		vm.backProfile = function () {
			if (!vm.noInvestigationFormExistForCountryAdmin) {
				$mdDialog.hide();
				vm.investigation.id = $scope.investigationID;
				$state.go("profile", vm.investigation);
			}
			else {
				$mdDialog.hide();
				$state.go("investigations");
			}
		};

		vm.gobackInvestigationForm = function(){
			$mdDialog.cancel();
		};

		vm.reportResult = function(event,resultPopup,errorPopup){
			//Trigger the save while another user submitting the form
			if(!$scope.isOwner) {
				$scope.saveData = true;
				$scope.saveSummaryFormDetails(0);
			}
			vm.formSubmitDisableFlag = true;
			var ageResult;
			var identityResult;
			var language = tmhDynamicLocale.get();
			if($scope.summaryForm != null && $scope.summaryForm.sections.length >0 && $scope.summaryForm.sections[0].questions.length > 0 && 
					$scope.summaryForm.sections[0].questions[0].summary_age != null && $scope.summaryForm.sections[0].questions[0].summary_age.answer == vm.APP.INVESTIGATION.SUMMARY_CONFIMED){
				ageResult =  vm.APP.REF_TERM.FORM_CONFIRMED;
			}else if($scope.summaryForm != null && $scope.summaryForm.sections.length >0 && $scope.summaryForm.sections[0].questions.length > 0 && 
					$scope.summaryForm.sections[0].questions[0].summary_age != null && $scope.summaryForm.sections[0].questions[0].summary_age.answer == vm.APP.INVESTIGATION.SUMMARY_FAILED){
				ageResult =  vm.APP.REF_TERM.FORM_FAILED;
			}else if($scope.summaryForm != null && $scope.summaryForm.sections.length >0 && $scope.summaryForm.sections[0].questions.length > 0 && 
					$scope.summaryForm.sections[0].questions[0].summary_age != null && $scope.summaryForm.sections[0].questions[0].summary_age.answer == vm.APP.INVESTIGATION.SUMMARY_UNDETERMINED){
				ageResult =  vm.APP.REF_TERM.FORM_UNDETERMINED;
			}else {
				ageResult = vm.APP.REF_TERM.FORM_NA;
			}

			if($scope.summaryForm != null && $scope.summaryForm.sections.length >0 && $scope.summaryForm.sections[0].questions.length > 0 && 
					$scope.summaryForm.sections[0].questions[0].summary_identity != null && $scope.summaryForm.sections[0].questions[0].summary_identity.answer == vm.APP.INVESTIGATION.SUMMARY_CONFIMED){
				identityResult =  vm.APP.REF_TERM.FORM_CONFIRMED;
			}else if($scope.summaryForm != null && $scope.summaryForm.sections.length >0 && $scope.summaryForm.sections[0].questions.length > 0 && 
					$scope.summaryForm.sections[0].questions[0].summary_identity != null && $scope.summaryForm.sections[0].questions[0].summary_identity.answer == vm.APP.INVESTIGATION.SUMMARY_FAILED){
				identityResult =  vm.APP.REF_TERM.FORM_FAILED;
			}else if($scope.summaryForm != null && $scope.summaryForm.sections.length >0 && $scope.summaryForm.sections[0].questions.length > 0 && 
					$scope.summaryForm.sections[0].questions[0].summary_identity != null && $scope.summaryForm.sections[0].questions[0].summary_identity.answer == vm.APP.INVESTIGATION.SUMMARY_UNDETERMINED){
				identityResult =  vm.APP.REF_TERM.FORM_UNDETERMINED;
			}else {
				identityResult = vm.APP.REF_TERM.FORM_NA;
			}
			var formLanguage = tmhDynamicLocale.get();
			if(formLanguage == vm.APP.GLOBAL.EN){
				formLanguage = vm.APP.GLOBAL.ENGLISH;
			}else if(formLanguage == APP.GLOBAL.ES){
				formLanguage = vm.APP.GLOBAL.SPANISH;
			}
			var data = InvestigationFormService.saveInvestigatorResult($scope.investigationFormId,ageResult,identityResult,formLanguage);
			data.then(function(response){
				vm.formSubmitDisableFlag = false;
				vm.noInvestigationFormExistForCountryAdmin = response.noInvestigationFormExistForCountryAdmin;
				if (vm.noInvestigationFormExistForCountryAdmin) {
					resultPopup = "countryAdminWarningPopup";
				}
				$mdDialog.show({
					contentElement: angular.element('#'+resultPopup),
					targetEvent: event,
					clickOutsideToClose: false,
					escapeToClose: false,
					onRemoving: function (event, removePromise) { 
						vm.backProfile();
					}
				})
			}, function(reason) {
				if(reason.status == 409){
					$mdDialog.show({
						contentElement: angular.element('#'+errorPopup),
						targetEvent: event,
						clickOutsideToClose: false,
						escapeToClose: false
					})
				}
			});
		}

		vm.submitInvestigationForm = function(ev,id){
			//$scope.saveData = true;
			if($scope.investigationFormType == vm.APP.INVESTIGATION.INVESTIGATOR_FORM && $scope.summaryForm != null && $scope.summaryForm.sections.length >0 && $scope.summaryForm.sections[0].questions.length > 0 && 
					($scope.summaryForm.sections[0].questions[0].summary_age == null || $scope.summaryForm.sections[0].questions[0].summary_age == undefined || 
							$scope.summaryForm.sections[0].questions[0].summary_identity == null || $scope.summaryForm.sections[0].questions[0].summary_identity == undefined)){
				$scope.saveSummaryFormDetails(1);
			}
			//Trigger the save call while click the Save & Close button 
			if(!$scope.isOwner && id == vm.APP.INVESTIGATION_FORM.SUMMARY_CLOSE_FORM) {
				$scope.saveData = true;
				$scope.saveSummaryFormDetails(0);
			}
			$mdDialog.show({
				contentElement: angular.element('#'+id),
				targetEvent: ev,
				clickOutsideToClose:false,
				escapeToClose: false
			})
		};

		$rootScope.validationCompleted = function(event){
			if($scope.summaryForm.sections[0].questions[0] == undefined){
				$scope.summaryForm.sections[0].questions[0] = {};
			}
			if($scope.summaryForm.sections[0].questions[0].summary_age != null && $scope.summaryForm.sections[0].questions[0].summary_identity != null){
				vm.submitInvestigationForm(event,'formConformPopup');
			}else{
				vm.submitInvestigationForm(event,'fillOutSummary');
			}

		}

		$rootScope.warningPopup.show = false;
		/* Method Description 	: This method triggers auto save for birth certificate form */
		$scope.$watch('summaryAutoSave', function(newValue, oldValue) {
			if(newValue != undefined && newValue != oldValue){				
				if(newValue && $scope.isOwner){					
						if($scope.formVersion && !$scope.isreadonly){
							$scope.saveSummaryFormDetails(0);
							$rootScope.summaryAutoSave = false;
						} else{
							$rootScope.summaryAutoSave = false;
						}
					}else{
						$rootScope.summaryAutoSave = false;
					}				
			}
		});

		//It will get triggered while exiting from current state
		$scope.$on('$destroy', function() {
			if(!$scope.isreadonly && $scope.isOwner) {
				$scope.saveSummaryFormDetails(0);
			}
			$rootScope.warningPopup.show = false;
		});
	}
})();