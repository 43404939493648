(function() {
    'use strict';

    angular
        .module('lariAidApp')
        .service('FormLocationDirectiveService', FormLocationDirectiveService);

    FormLocationDirectiveService.$inject = ['$resource', 'restService', '$q'];
    
    function FormLocationDirectiveService ($resource, restService, $q) {
    	
		this.loadAllLocationsByType = function(postObj,type) {
			var deferred = $q.defer();
    		var url = "locations/"+type;
    		var data = restService.restCall(postObj, url, 'POST');
    		data.$promise.then(function(response){
    			deferred.resolve(response);
    		},
    		function (error) {
    			deferred.reject('ERROR');
    		});
    		return deferred.promise;
		}
		
    }
})();