(function() {
    'use strict';

    angular
        .module('lariAidApp')
        .config(stateConfig);
    stateConfig.$inject = ['$stateProvider','APP'];
    function stateConfig($stateProvider,APP) {
    	$stateProvider
    	.state('familyInformation', {
            parent: 'profileForm',
            url: '/investigationForm/:investigationFormId/familyInformation',
            data: {
                authorities: APP.GLOBAL.USER_GROUP
            },
            params: {
            	investigationFormId:null
            },
            views: {
                'dynamicViewForInvestigationForm': {
                    templateUrl: 'app/investigation/prospects/profile/investigation-form/family-information/family.information.html',
                    controller: 'FamilyInformationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    if(localStorage && localStorage.getItem('lari_currentVersion') != null){
                       	$translatePartialLoader.addPart('familyInformationQuestion.json?version='+localStorage.getItem('lari_currentVersion'));
                       }else{
                       	$translatePartialLoader.addPart('familyInformationQuestion.json?timestamp='+new Date().getTime());
                       }
                    return $translate.refresh();
                }],
                sectionIndex:['investigationFormIndexService','$timeout', function (investigationFormIndexService,$timeout) {
                	investigationFormIndexService.setInvestigationValue("familyInfoForm");
                	/*$timeout(function() {
                	    angular.element('#profile').triggerHandler('click');
                	 });*/
                }]
            }
        });
    }
})();