(function() {
	'use strict';

    angular
        .module('lariAidApp').directive('formLocationFilter',locationDropFilter);
    
    function locationDropFilter(){
			return {
			    templateUrl: 'app/investigation/prospects/profile/investigation-form/location-directive/form.locationdirective.html',
				controller:'FormLocationDirectiveController',
			    transclude:true,
				replace: true,
			    restrict: 'E',
				scope:{
					modelObject: '=modelObject',
					call: '&',
					horizon: '=horizonFlag',
					validation:'=validationRequired',
					addNewLocation:'=?',
					isRequested:'=?',
					formName:'=formName',
					isValidation:"=",
					savevalid: '&',
					inputsearchObject: '=inputsearchObject',
					disableFlag: '=disableFlag',
					ishideObject:'=ishideObject',
					relpaceinputfield: '&',
					relpaceinputfieldObject:'=relpaceinputfieldObject',
					media: '=media',
					addnewlocationObject: '=addnewlocationObject'
				},
			    link: function(scope, element, attrs) {
			    }
		    }
			
		}
    
})();