(function() {
    'use strict';
    
    var lariDevApp = angular
    .module('lariAidApp')
    lariDevApp.controller('InvestigationFormHeaderController', InvestigationFormHeaderController);
    
    InvestigationFormHeaderController.$inject = ['$element','$scope','$window', '$mdSidenav','Principal','$state','$mdMedia','$cookies','APP','InvestigationFormService','$stateParams','$location','investigationFormIndexService','$document','$interval','$rootScope','$sessionStorage','Upload','$timeout','$q','$mdDialog','CommonService','SideCommentsDirectiveService','$filter','$controller'];
    function InvestigationFormHeaderController ($element,$scope,$window, $mdSidenav,Principal,$state,$mdMedia,$cookies,APP,InvestigationFormService,$stateParams,$location,investigationFormIndexService,$document,$interval,$rootScope,$sessionStorage,Upload,$timeout,$q,$mdDialog,CommonService,SideCommentsDirectiveService,$filter,$controller) {

    	var vm = this;
    	
    	$scope.mediaCheck = $mdMedia;
    	vm.profileForm = $stateParams;
    	vm.formProfile = {};
    	vm.investigation = [];
    	$scope.investigationFormId = vm.profileForm.investigationFormId;
    	vm.app = APP;
    	vm.user = CommonService.getCookieUser().cookieUser;
    	
    	$scope.updateProspectName = function(firstName,lastName,investigationType,investigationId){
    		vm.formProfile.firstName = firstName;
    		vm.formProfile.lastName = lastName;
    		vm.formProfile.investigationType = investigationType;
    		vm.formProfile.investigationId = investigationId;
    	};
    	
    	/*Investigation Form's Section sidenav  Starts Here*/
    	$scope.sectionLeft = buildToggler('formLeft');
        $scope.toggleRight = buildToggler('formRight');

        function buildToggler(componentId) {
          return function() {
            $mdSidenav(componentId).toggle();
          };
        }
        /*Investigation Form's Section sidenav Ends Here */
        
        vm.backProfile = function(){
        	vm.investigation.id = vm.formProfile.investigationId;
    		$state.go("profile",vm.investigation);
    		
    	};
    	
    }
    
})();