(function() {
    'use strict';

    angular
        .module('lariAidApp')
        .service('ProspectActivityService', ProspectActivityService);

    ProspectActivityService.$inject = ['$resource','restService','$q','$rootScope'];
    
    function ProspectActivityService ($resource,restService,$q,$rootScope) {
    	
    	this.getActivityDetails= function(investigationId){
    		var deferred = $q.defer();
    		var url = "investigations/activity/"+investigationId;
    		var data = restService.restCall("", url, 'GET');
    		data.$promise.then(function(response){
    			deferred.resolve(response);
    		},
    		function (error) {
    			deferred.reject('ERROR');
    		});
    		return deferred.promise;
    	};
    		
    }
})();
