(function() {
    'use strict';

    angular
        .module('lariAidApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','APP','$mdAriaProvider','$mdDateLocaleProvider'];

    function stateConfig($stateProvider,APP,$mdAriaProvider,$mdDateLocaleProvider) {
    		
    	    $mdAriaProvider.disableWarnings();
    	    
    	  //Date Format Provider
    		$mdDateLocaleProvider.formatDate = function(date) {
    		    return date ? moment(date).format('MMM D, Y') : '';
    		  };
    		  
    		  $mdDateLocaleProvider.parseDate = function(dateString) {
    			  if(/^[0-9-]*$/.test(dateString) == true){
    					  var m = moment(dateString, 'D-M-YYYY', true);
    					  return m.isValid() ? m.toDate() : new Date(NaN);
    			  }else if(/^[0-9/]*$/.test(dateString) == true){
    					  var m = moment(dateString, 'D/M/YYYY', true);
    					  return m.isValid() ? m.toDate() : new Date(NaN);
    			  } 
  				  	  var m = moment(dateString, 'MMM D, Y', true);
	  				  if(m.isValid()){
	  					  return m.toDate();
	  				  }else{
	  					  var m = moment(dateString, 'MMMM D, Y', true);
	  					  return m.isValid() ? m.toDate() : new Date(NaN);
	  				  }
    		  };
    		      	
        	$stateProvider.state('investigations', {
            parent: 'app',
            url: '/investigations',
            data: {
                authorities: APP.GLOBAL.USER_GROUP
            },
            views: {
                'dynamicView': {
                    templateUrl: 'app/investigation/investigation.html',
                    controller: 'InvestigationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    if(localStorage && localStorage.getItem('lari_currentVersion') != null){
                       	$translatePartialLoader.addPart('dashboard.json?version='+localStorage.getItem('lari_currentVersion'));
                    	$translatePartialLoader.addPart('library.json?version='+localStorage.getItem('lari_currentVersion'));
                       }else{
                       	$translatePartialLoader.addPart('dashboard.json?timestamp='+new Date().getTime());
                    	$translatePartialLoader.addPart('library.json?timestamp='+new Date().getTime());
                       }
                    return $translate.refresh();
                }],
                'redirect' : ['Principal', '$state', '$timeout', function(Principal, $state, $timeout){
       	    		$timeout(function(){
       		    		//if ($cookies.getObject('sessionDetails') && $cookies.getObject('sessionDetails').firstName != null && $cookies.getObject('sessionDetails').firstName != ''){
       	    			if(Principal.isAuthenticated()) {
       		    			//$state.go('investigations');
       		    			return true;
       		    		} else {
       		    			$state.go('login');
       		    			return false;
       		    		}
       	    		}, 700)
       	    	 }]
            }
        });
    }
})();
