(function() {
	'use strict';
	
	var lariDevApp = angular
    .module('lariAidApp')
    lariDevApp.controller('FamilyInformationController', FamilyInformationController);
	
	FamilyInformationController.$inject = ['$scope','$window','$mdSidenav','Principal','$sessionStorage','StorageDelete','$state','$mdMedia','$cookies','APP','FamilyInformationService','InvestigationFormService','$stateParams','$timeout','$mdDialog','$rootScope','$sce','deviceDetector'];
	function FamilyInformationController ($scope,$window,$mdSidenav,Principal,$sessionStorage,StorageDelete,$state,$mdMedia,$cookies,APP,FamilyInformationService,InvestigationFormService,$stateParams,$timeout,$mdDialog,$rootScope,$sce,deviceDetector) {
		
		var vm = this;
		vm.app = APP;
		vm.media = $mdMedia;
       $scope.familyInformation = {};
       vm.siblingsCount = 1;
	   vm.familyMembersCount = 0;
       //$scope.familyInformation.photo.questions=[];
       $scope.familyInformation.sections =[];
       $scope.familyInformation.summary = {};
       var oldFormData = {};
       $scope.siblingsSelectAll = {};
       var fromAlert=false;
       $scope.disablesaveflag = false;
       vm.familyInformationSectionPhoto = "familyInformationSectionPhoto";
       vm.familyPhotos = "familyPhotos";

       vm.removingMember = {};
        
       vm.profileForm = $stateParams;
       $scope.loadingFamilyInfo = false;
       
       vm.relationTypeList = [
                              {
                            	  "value":"Aunt"
                              },
                              {
                            	  "value":"Grandfather"
                              },
                              {
                            	  "value":"Grandmother"
                              },
                              {
                            	  "value":"Guardian"
                              },
                              {
                            	  "value":"Step-parent"
                              },
                              {
                            	  "value":"Uncle"
                              },{
                            	  "value":"Others"
                              }
                              ];
       
        $scope.familyMember = [
             	            {
             		    		"id":"0",
             		    		"value":"Father"
             		    	},
             		    	{
             		    		"id":"1",
             		    		"value":"Mother"
             		    	},
             		    	{
             		    		"id":"2",
             		    		"value":"Prospect"
             		    	},
             		    	{
             		    		"id":"3",
             		    		"value":"Siblings"
             		    	}
             	        ];
      
 	   $scope.familyMember[0].expanded = true;
 	           
 	   vm.collapseAll = function(index,flag){
     	    var height = 0;
     	    var value;
	     	   if(index.value && index.value == 'PFM' && index.id > 3){
	     		    value = index.id;
	     	    }else if(index.value){
	     	        value = index.id;
	     	    }else{
	     	        value = index;
	     	    }
	            for(var i=0;i<$scope.familyMember.length;i++){
		            if(flag==0){
		            	height = height + $('.acc_'+value).closest('.md-accordion').offsetHeight + 70;
		            }
		            else{
		            	height = height + $('.acc_'+value).closest('.md-accordion')[0].offsetHeight + 70;
		            }
		            if(i == value){
		   	             if(!$scope.familyMember[i].expanded){
		   	            	    $scope.formViewCall(value);
				   	            $scope.familyMember[i].expanded = !$scope.familyMember[i].expanded;
				                $('#familyInformation').animate({scrollTop: height}, 'slow');
				                
				                //Calling get documents based on accordion index
				                $scope.getUploadFiles(null,vm.app.REF_TERM.FAMILY_INFORMATION,value);
		   	             }else{
		   	            	    $scope.familyMember[i].expanded = !$scope.familyMember[i].expanded;
		   	             }
		            } else {
		            	  
		            		$scope.familyMember[i].expanded = false;
		            	}
	            }
       };
 	   
 	   
        vm.loadFamilyInformationDetails = function(){
        	$scope.loadingFamilyInfo = true;
        		vm.getFamilyInformationDetails();
          
        };
        
        vm.getFamilyInformationDetails = function(){
        	 var sectionKey = vm.app.REF_TERM.FAMILY_INFORMATION;
        	   var formId = $scope.investigationFormId;
        	   var data = InvestigationFormService.getInvestigationFormBySectionId(sectionKey,formId);
    		  	data.then(function(response){
    		  		if(response.formVersion != null){
    		  			$scope.formVersion = response.formVersion;
    		  		}
    		  		if(response.sectionId != null){
    		  			$scope.familyInfoSectionId = response.sectionId;
    		  		}
    		  		if(response.formDTO == null){
	      		  		for(var i=0;i<$scope.familyMember.length;i++){
	      	            	var tempObj = {};
	      	                tempObj.questions=[];
	      	                $scope.familyInformation.sections[i] = tempObj;
	      	                $scope.familyInformation.sections[i].sortOrder = i+1;
	      	                if($scope.familyMember[i].value != vm.app.GLOBAL.SIBLINGS){
	      	                	 $scope.familyInformation.sections[i].type = "PFM";
	      	                } else {
	      	                	 $scope.familyInformation.sections[i].type = vm.app.GLOBAL.SIBLINGS;
	      	                }
	      	            }
	      		  		$scope.familyInformation.photo = {};
	      		  		$scope.familyInformation.photo.questions = [];
	      		  		vm.add = 0;
    		  		} 
    		  		else {
    		  		   $scope.familyMember = [];
    		  			$scope.familyInformation = angular.copy(response.formDTO);
    		  			for(var i=0,s=1,f=1;i<response.formDTO.sections.length;i++){
    		  				var familyTempObj = {};
    		  				familyTempObj.id = i;
    		  				familyTempObj.value = response.formDTO.sections[i].type;
    		  				$scope.familyMember.push(familyTempObj);
	      		  			$scope.familyMember[i].id = i;
		  			    	if($scope.familyMember[i].value == vm.app.GLOBAL.SIBLINGS){
		  	    	        	$scope.familyMember[i].sortOrder = s;
		  	    	        	vm.siblingsCount = s;s++;
		      	        	}
		  			    	if($scope.familyMember[i].value == 'PFM' && $scope.familyMember[i].id != 0  && $scope.familyMember[i].id != 1  && $scope.familyMember[i].id != 2){
		  	    	        	$scope.familyMember[i].sortOrder = f;
		  	    	        	vm.familyMembersCount = f;f++;
		      	        	}
    		  			}
    		  		   $scope.familyMember[0].expanded = true;
    		  		    //$scope.familyMember.push(response.formDTO.sections);
    		  		    vm.add = 1;
    		  			if(response.formDTO.photo == null){
	      		  			$scope.familyInformation.photo = {};
	          		  		$scope.familyInformation.photo.questions = [];
    		  			}
    		  			if(response.formDTO.sections.length == 0){
    		  				if(response.formDTO.summary){
   						     var tempObj = {};
   						  	   tempObj.questions = [];
   				  	           $scope.familyInformation.sections.push(tempObj);
   				  	           $scope.familyInformation.summary = response.formDTO.summary;
	   				  	    } else {
	   						  	   var tempObj = {};
	   						  	   tempObj.questions = [];
	   				  	           $scope.familyInformation.sections.push(tempObj);
	   				  	   }
    		  			}
    		  			//Set the object to restrict the initial save call
    		  			//oldFormData = angular.copy($scope.familyInformation);
    		  			
    		  		}
    		  		oldFormData = angular.copy($scope.familyInformation);
    		  		if(response.formDTO == null || response.formDTO.summary == null) {
    		  			oldFormData.summary = {};
    		  			oldFormData.summary.question = "familyInformation_summary_comment";
    		  		}
    		  		$scope.loadingFamilyInfo = false;
    		  		var indexValue = vm.app.REF_TERM.FIVE_HUNDRED_AND_ONE;
    		  		angular.forEach($scope.familyMember, function(data, key) {
    	 				  if(data.id > 3 && data.value == "PFM") {
    	 					  data.index = indexValue;
    	 					  indexValue++;
    	 				  }
    	 			  });
    		  		$scope.updateLastEditTime(response.updatedOn);
    		  		
    		   }, function(reason) {
    			  return reason;
    		   });
        };
        
        vm.familyMemberCount = 0;
        vm.addfamilyInfoForm = function(PFMorSiblings){
        	var testObj = {};
        	var max = 0;
	        testObj.questions = [];	       
	        testObj.expanded = true;
        	 if(PFMorSiblings == vm.app.GLOBAL.SIBLINGS){
         		vm.siblingsCount++;
         		for(var i=0;i<$scope.familyMember.length;i++){
         			$scope.familyMember[i].expanded = true;
    	        	if($scope.familyMember[i].value == vm.app.GLOBAL.SIBLINGS){
    	        		max = $scope.familyMember[i].id; 	
    	        	}
    	        }max++;
    	        testObj.type = PFMorSiblings;
    	        $scope.familyInformation.sections.splice(max,0,testObj);
    	        var tempObj = {};
    	        tempObj.id = max;
    	        tempObj.value = PFMorSiblings;
    	        tempObj.sortOrder = vm.siblingsCount;
    	        tempObj.expanded = false;
    	       /* if($scope.familyMember[max].expanded){
    	        	$scope.familyMember[max].id = max + 1;
    	        	$scope.familyMember[max].value = "PFM";
    	        	$scope.familyMember[max].expanded = false;
    	        }*/
    	        $scope.familyMember.splice(max,0,tempObj);
    	        for(var i=0;i<$scope.familyMember.length;i++){
    	        	$scope.familyMember[i].id = i;   	
    	        }
    	        vm.collapseAll(max, 0);
         	}else if(PFMorSiblings ==  vm.app.GLOBAL.PFM){
         		vm.familyMembersCount++;
         		testObj.type = PFMorSiblings;
         		var temp = $scope.familyMember.length;
    	        $scope.familyInformation.sections.push(testObj);
    	        var tempObj = {}; 
    	        tempObj.id = temp;
    	        tempObj.value = PFMorSiblings;
    	        tempObj.sortOrder = vm.familyMembersCount;
    	        tempObj.index = vm.app.REF_TERM.FIVE_HUNDRED + vm.familyMembersCount;
    	        $scope.familyMember.push(tempObj);    
    	        vm.collapseAll($scope.familyInformation.sections.length - 1, 0);
         	}
	         
	        //vm.checkIndex('add'); 
        };
        /**
         * 
         * It imports data froom BC to Family informations
         * 
         */
        vm.importedSibblings;
        vm.siblingPopupButtonClick= function(){
        
        		$scope.disableConfirm = true;
	        	var sibItr=0;
	        	var max;
	        	var selectedList = [];
	        	var numberOfSiblingsAdded=0;
	        	
	        	angular.forEach(vm.dataList, function(data, key){
	        		if(data.selected == true){
	        			selectedList.push(data);
	        		}
	        	});
	        	        	
	        	angular.forEach($scope.familyInformation.sections, function(data, index){
	        		if(data.type == vm.app.GLOBAL.SIBLINGS){
		        		max = $scope.familyMember[index].id; 	
		        	}
	        	})
	        	
	        	//Add the new sibling if the last sibling 
	        	angular.forEach(selectedList, function(data, index){
	        		if($scope.familyInformation.sections[max].questions.length == 0 && index==0){}
	        		else {
	        			vm.addfamilyInfoForm(vm.app.GLOBAL.SIBLINGS);
	        		}
	        	});
	        	//Replace the last sibling if the sibling details are empty and create the 
		        angular.forEach($scope.familyInformation.sections, function(data,index){
		        	if($scope.familyInformation.sections[index].type == vm.app.GLOBAL.SIBLINGS){
		        		if(index >= max && $scope.familyInformation.sections[index].questions.length == 0 && sibItr < selectedList.length )
		        		{
	       				    $scope.familyInformation.sections[index].questions = selectedList[sibItr].questions;
	       				 sibItr++;
		        		}
		        	}
		        });
	        	
		        vm.closeCancel();
				
				/**
				 * This conditions are use to have a record of "is Sibling Imported Already?" it stores the flag as a subanser in familyInformation_prospect_1_2 of
				 * the familyInformation object and make a save call.
				 */
				if ($scope.familyInformation.sections[0].questions.length == 0){				
					$scope.familyInformation.sections[0].questions = [];
					$scope.familyInformation.sections[0].questions[0] = {};
					$scope.familyInformation.sections[0].questions[0].familyInformation_prospect_1_2 = {};
					$scope.familyInformation.sections[0].questions[0].familyInformation_prospect_1_2.subAnswer = vm.app.GLOBAL.SIBLINGIMPORTED;
					$scope.saveFamilyInformationDetails();
				} else if (!$scope.familyInformation.sections[0].questions[0].familyInformation_prospect_1_2) {				
					$scope.familyInformation.sections[0].questions[0].familyInformation_prospect_1_2 = {};
					$scope.familyInformation.sections[0].questions[0].familyInformation_prospect_1_2.subAnswer = vm.app.GLOBAL.SIBLINGIMPORTED;
					$scope.saveFamilyInformationDetails();
				} else if ($scope.familyInformation.sections[0].questions[0].familyInformation_prospect_1_2) {				
					$scope.familyInformation.sections[0].questions[0].familyInformation_prospect_1_2.subAnswer = vm.app.GLOBAL.SIBLINGIMPORTED;
					$scope.saveFamilyInformationDetails();
				} 
        	
        }
                
        vm.removefamilyInfoForm = function(ev,id,member){
        	vm.removingMember = member;
        	$mdDialog.show({
        		contentElement: angular.element('#'+id),
        		targetEvent: ev,
        		clickOutsideToClose: false,
        		escapeToClose: false
        	})
        };
	       
	       vm.closeConform = function(members){   
	    	   $scope.deleteLoader.flag = true;
	    	   if(members.value == vm.app.GLOBAL.SIBLINGS){
	        		vm.siblingsCount--;    		
	        	}else if(members.value == vm.app.GLOBAL.PFM){
	        		vm.familyMembersCount--;	
	        	}
		    	for(var i=0;i<$scope.familyMember.length;i++){
	   	        	if($scope.familyMember[i].id == members.id){
	   	        		 $scope.familyMember.splice(members.id, 1);
	  			    	 $scope.familyInformation.sections.splice(members.id,1);
	   	        	}
	   	        }
			    for(var i=0,s=1,f=1;i<$scope.familyMember.length;i++){
			    	$scope.familyMember[i].id = i;
			    	if($scope.familyMember[i].value == vm.app.GLOBAL.SIBLINGS){
	    	        	$scope.familyMember[i].sortOrder = s;s++;
    	        	}
			    	if($scope.familyMember[i].value == vm.app.GLOBAL.PFM && $scope.familyMember[i].id != 0  && $scope.familyMember[i].id != 1  && $scope.familyMember[i].id != 2){
	    	        	$scope.familyMember[i].sortOrder = f;f++;
    	        	}
    	        }
			    //vm.checkIndex('del'); 
			    $mdDialog.hide();
			    $scope.enableDeleteButton();
			    var index = 1;
			    angular.forEach($scope.familyInformation.sections, function(item){
    				item.sortOrder = index;
    				index++;
			    });
	       };
	       
	       vm.closeCancel = function(){
	    	   $mdDialog.cancel();
	    	   //Set the selected sibling count
	    	   vm.numberOfSiblingsAdded = 0;
	       };
        
	       /* Method name           : saveFamilyInformationDetails()
	        * Method Description 	: This method saves the family form details 
	 	    */
	       $scope.saveFamilyInformationDetails = function(){
		       var isPutJsonSimilar = false;
		       $rootScope.warningPopup.show = false; //To avoid showing popup while moving to other section if there is no edit in current section
	      	   var saveDataObj = {};
	      	   saveDataObj.formDTO = angular.copy($scope.familyInformation);
	      	   saveDataObj.formVersion = $scope.formVersion;
	      	   saveDataObj.investigationFormId = $scope.investigationFormId;
	      	   saveDataObj.sectionId = $scope.familyInfoSectionId;
	      	    var saveDataObjSiblingsCount = angular.copy(vm.siblingsCount);  
	      	    
                // Index should starts from 3, because Father, Mother, Prospect, Siblings accordions should present compulsory. 
	      	   for(var i=3;i<saveDataObj.formDTO.sections.length;i++){   
	      		   if(!saveDataObj.formDTO.sections[i].questions){
	      			   saveDataObj.formDTO.sections.splice(i,1);
	      			   i--;
	      		   } else if(saveDataObj.formDTO.sections[i].questions.length == 0 && saveDataObj.formDTO.sections[i].type == vm.app.GLOBAL.SIBLINGS && saveDataObjSiblingsCount > 1) {
	      			   saveDataObj.formDTO.sections.splice(i,1); //Empty Sibling accordions were removed while saving if its count was greater than 1
	      			   saveDataObjSiblingsCount--;
	      			   i--;
	      		   } else if(saveDataObj.formDTO.sections[i].questions.length == 0 &&   saveDataObj.formDTO.sections[i].type == vm.app.GLOBAL.PFM) {
	      			  saveDataObj.formDTO.sections.splice(i,1); //Empty Family Member accordions were removed while saving
	      			  i--;
	      		   }else {
	      			   //delete saveDataObj.formDTO.sections[i].expanded;
	      			   saveDataObj.formDTO.sections[i].sortOrder = i+1; 
	      		   }
	      	   }
      	   
	      	   /*	Set the temporary object to avoid unwanted save call. 
	      			Fist condition to avoid the initial save call for empty form 
	      			Second condition to avoid the initial save call if the section has any form data 
	      	   */	      	   
               if(angular.isUndefined($scope.tempFamilyObj) && saveDataObj.formDTO.sections.length == 4 && angular.equals(JSON.stringify(saveDataObj.formDTO), JSON.stringify(oldFormData)) && 
            		   saveDataObj.formDTO.sections[0].questions.length == 0 && 
                       saveDataObj.formDTO.sections[1].questions.length == 0 && saveDataObj.formDTO.sections[2].questions.length == 0 && 
                       saveDataObj.formDTO.sections[3].questions.length == 0 && saveDataObj.formDTO.photo.questions.length == 0 &&
                       (saveDataObj.formDTO.summary.comment == null || saveDataObj.formDTO.summary.comment == undefined)) {
                   $scope.tempFamilyObj = angular.copy(saveDataObj);
               } else if(angular.isUndefined($scope.tempFamilyObj) && angular.equals(JSON.stringify(saveDataObj.formDTO), JSON.stringify(oldFormData))) {
            	   $scope.tempFamilyObj = angular.copy(saveDataObj);
               } else if(angular.isUndefined($scope.tempFamilyObj) && !angular.isUndefined(oldFormData) && !angular.isUndefined(oldFormData.sections) && !angular.equals(JSON.stringify(saveDataObj.formDTO), JSON.stringify(oldFormData))){
            	          var oldFormDataSiblingsCount = 0;           	 
            	   angular.forEach(oldFormData.sections,function(element){
            		   if (element.type == vm.app.GLOBAL.SIBLINGS){
            			   oldFormDataSiblingsCount++; // Count of Sibling accordions from old data in db
            		   }
            	   });
            	// Index should starts from 3, because Father, Mother, Prospect, Siblings accordions should present compulsory.
            	   for(var i=3;i<oldFormData.sections.length;i++){
    	      		   if(!oldFormData.sections[i].questions){
    	      			  oldFormData.sections.splice(i,1);	 
    	      			  i--;   	      			  
    	      		   } else if(oldFormData.sections[i].questions.length == 0 && oldFormData.sections[i].type == vm.app.GLOBAL.SIBLINGS && oldFormDataSiblingsCount > 1) {
    	      			  oldFormData.sections.splice(i,1); //Empty Sibling accordions were removed in old data from db if its count was greater than 1
    	      			  oldFormDataSiblingsCount--;
    	      			  i--;
    	      		   } else if(oldFormData.sections[i].questions.length == 0 && oldFormData.sections[i].type == vm.app.GLOBAL.PFM) {
    	      			  oldFormData.sections.splice(i,1);  //Empty Family Member accordions were removed in old data from db 
    	      			  i--;
    	      		   } 
    	      		   else{   	
    	      			  oldFormData.sections[i].sortOrder = i+1; 
    	      		   }
    	      	   }
            	   $scope.tempFamilyObj = {};
            	   $scope.tempFamilyObj.formDTO = angular.copy(oldFormData);    	      	   
               } else if(angular.isUndefined($scope.tempFamilyObj) && !angular.equals(JSON.stringify(saveDataObj.formDTO), JSON.stringify(oldFormData))) {
            	   $scope.tempFamilyObj = {};
            	   $scope.tempFamilyObj.formDTO = angular.copy(oldFormData);
               } 
               if(!angular.isUndefined($scope.tempFamilyObj)){
	    		   if(angular.equals(JSON.stringify(saveDataObj.formDTO), JSON.stringify($scope.tempFamilyObj.formDTO))){
	    			   isPutJsonSimilar = true;	    			
	    		   }
	    	   }

               if($scope.isOwner){
	    	       $scope.tempFamilyObj = angular.copy(saveDataObj);
               } else {
            	   $scope.tempFamilyObj = undefined; //Every time temporary object will be newly updated for non owner  
               }
               
               /* saveData flag - to ensure the save button is clicked in form*/
	    	   if(!isPutJsonSimilar){
	    		   if($scope.isOwner || $scope.saveData){
	    			   //Flag to set the section results are edited or not
	    			   if(angular.isDefined($scope.familyCheckList) && angular.isDefined($scope.familyCheckListTempObj) && !angular.equals($scope.familyCheckList, $scope.familyCheckListTempObj)) {
	    				   saveDataObj.isFormSectionResultEdited = true;
	    				   $scope.saveAnalystFormTypeCheckList('family', true, false);
	    			   }
	    			   
	    			   var data = InvestigationFormService.saveInvestigationForm(saveDataObj);
			   		  	data.then(function(response){
			  		  		if($state.current.name === vm.app.INVESTIGATION.STATE.FAMILY_INFORMATION_STATE)
			  		  		{
			  		  			$scope.updateLastEditTime(response.updatedOn);
			  		  		}
			  		  		$scope.uploadFileStatusUpdated();
			   		  }, function(reason) {
			   			  return reason;
			   		  });
	    		   }
	    		   $rootScope.warningPopup.show = true;
	    	   } else if(($scope.isOwner || $scope.saveData) && angular.isDefined($scope.familyCheckList) && angular.isDefined($scope.familyCheckListTempObj)
	    			   && !angular.equals($scope.familyCheckList, $scope.familyCheckListTempObj)) {
				   $scope.saveAnalystFormTypeCheckList('family', true);
			   }
         };
         
         /* File Upload Sections */
         
         vm.uploadFileForSummary = function(isSummary,accordionIndex,idName,ev){
     		$("#familyInformationSectionSummary").val(null);
  	        $('#familyInformationSectionSummary').trigger('click'); 
  	        $('#familyInformationSectionSummary').off('change').on('change', function() {
	  	    	var file = this.files[0];
	     		var sectionId = $scope.familyInfoSectionId;
	     		var questionKey = null;
    			if(isSummary == "true"){
    				questionKey = vm.app.INVESTIGATION_FORM.SUMMARY_KEY;
    			}
    			$scope.setOrginSectionFileUpload(vm.app.REF_TERM.FAMILY_INFORMATION);
    			$scope.uploadFileInSectionSummary(ev,file,questionKey,sectionId,isSummary,'summary',idName,accordionIndex,idName,null);
	        });
  		};
    
         vm.nextInvestigationForm = function(){
        	//$scope.saveAnalystFormTypeCheckList('family');
        	 $scope.disablesaveflag = true;
        	$scope.saveData = true;
        	$scope.saveFamilyInformationDetails(); 
        	if($scope.uploadFileSectionFamilyPhotoList && $scope.uploadFileSectionFamilyPhotoList.length > 0){
            	vm.saveFamilyPhotoSectionDescription($scope.uploadFileSectionFamilyPhotoList[0].uploadList);
        	}
        	$rootScope.warningPopup.show = false;
			$scope.sectionNavigationFunc(vm.app.INVESTIGATION_FORM.SCHOOL_FORM);
        	//$state.go("schoolInformation");
        	if($scope.investigationFormType == vm.app.INVESTIGATION.ANALYST_FORM){
				$scope.collapseAll(4);
			}
			$scope.updateLastEditTime(null);
 		 };
 		 
 		vm.familyPhotoUploadFileForSummary = function(id,idName,ev){
      		$("#familyPhotoSectionSummary").val(null);
   	        $('#familyPhotoSectionSummary').trigger('click'); 
   	        $('#familyPhotoSectionSummary').off('change').on('change', function() {
   	        	var file = this.files[0];
   	      		var sectionId = $scope.familyInfoSectionId;
   	      		var questionKey = vm.app.INVESTIGATION_FORM.FAMILY_QUESTIONS.QUESTION1_PHOTO;
   	      	    $scope.uploadFileInSectionSummary(ev,file,questionKey,sectionId,false,id,idName); // as the family photo section is not a summary section, the boolean isSumamry is set to false 
	        });
   		};
   		
   		$scope.attachedPhotoRemove = function(event,answer,id){
   			if($scope.uploadFileSectionFamilyPhotoList[0]){
   				if(answer == vm.app.GLOBAL.ANSWER_NO && $scope.uploadFileSectionFamilyPhotoList[0].uploadList.length > 0){
   					$mdDialog.show({                   
   						targetEvent: event,
   						contentElement: '#'+id,
   						parent: angular.element(document.body),
   						clickOutsideToClose: false,
   						escapeToClose: false,
   	   	                onShowing: function (event, showPromise) { 
		   		    	  vm.yesToProceed = false;
		   		   		},
   	   	                onRemoving: function (event, removePromise) { 
   	   	                	if(!vm.yesToProceed){
   	   	                		vm.gobacktoForm();
   	   	                	}
   	   	                }
   	   	           });
   	   			}
   				else{
   					vm.yesToProceed = true;
   				}
   			}	
   			
   			//To remove NO comments while switching to Yes under family info questions.
   			if(answer == vm.app.GLOBAL.ANSWER_YES){
   				if($scope.familyInformation != null && $scope.familyInformation.photo.questions[0] != null && $scope.familyInformation.photo.questions[0].familyInformation_familyPhoto_1 != null && 
   	        			$scope.familyInformation.photo.questions[0].familyInformation_familyPhoto_1.comment != null && $scope.familyInformation.photo.questions[0].familyInformation_familyPhoto_1.comment.comment != null ){
   	        		 $scope.familyInformation.photo.questions[0].familyInformation_familyPhoto_1.comment.comment = "";
   	        	}
   			}
   		};
   		
   		vm.setRemovalFlag = function(){
   			vm.yesToProceed = true;
   		};
   		
   		$rootScope.warningPopup.show = false;
   		// Method Description 	: This method triggers auto save for birth certificate form 
        $scope.$watch('familyInfoAutoSave', function(newValue, oldValue) {
 			if(newValue != undefined && newValue != oldValue){ 				
 				if(newValue && $scope.isOwner){ 				
 	 					if($scope.formVersion && !$scope.isreadonly){
 	 						$scope.saveFamilyInformationDetails();
 	 						vm.saveFamilyPhotoSectionDescription($scope.uploadFileSectionFamilyPhotoList[0].uploadList);
 	 	 					$rootScope.familyInfoAutoSave = false;
 						} else{
 							$rootScope.familyInfoAutoSave = false;
 						}
 	 				}else{
 	 					$rootScope.familyInfoAutoSave = false;
 	 				} 				
 			}
 		});
        
        vm.gobacktoForm = function(){	
			$scope.familyInformation.photo.questions[0].familyInformation_familyPhoto_1.answer = 'answer_yes';
			$mdDialog.cancel();
		};
		
		vm.saveFamilyPhotoSectionDescription = function(uploadFamilyPhotoObj){
			 var isPutJsonSimilar = false;
			 var savePhotoObj = {};
			 if(uploadFamilyPhotoObj != undefined && uploadFamilyPhotoObj != null && uploadFamilyPhotoObj.length > 0){
			     savePhotoObj =  angular.copy($scope.uploadFileSectionFamilyPhotoList[0].uploadList);
			 }
	      
			 angular.forEach(savePhotoObj, function(item){
	                if(!angular.isUndefined(item.isSelected)){
	                           delete item.isSelected;
	                }});
			 
			 if(!angular.isUndefined($scope.tempFamilyPhotoObject) && !angular.isUndefined(savePhotoObj) && savePhotoObj.length > 0){
				 if(angular.equals(savePhotoObj, $scope.tempFamilyPhotoObject)){
					 isPutJsonSimilar = true;
				 }
			 } else {
				 isPutJsonSimilar = true;
			 }
             if($scope.isOwner) {
    	    	 $scope.tempFamilyPhotoObject = angular.copy(savePhotoObj);
             }
             
	    	 if(!isPutJsonSimilar){
	    		 if($scope.isOwner || $scope.saveData){
		    		 var data = InvestigationFormService.saveFamilyPhotoDescriptions(uploadFamilyPhotoObj);
		    		 data.then(function(response){
		    		 });
	    		 }
	    		 $rootScope.warningPopup.show = true;
	    	 }
        };
        
        //It will get triggered while exiting from current state
        $scope.$on('$destroy', function() {
        	if(!$scope.isreadonly && $scope.isOwner) {
        		$scope.saveFamilyInformationDetails();
        	}
        	$rootScope.warningPopup.show = false;
        	$scope.uploadedFile = false;
        });
        
        $scope.trustAsHTML = function(htmlContent){
	   		return $sce.trustAsHtml(htmlContent);
	   	}
        
        vm.setFamilyPhotoValue = function() {
        	if(angular.isDefined($scope.familyInformation) && angular.isDefined($scope.familyInformation.photo) && $scope.familyInformation.photo.questions.length > 0 &&
        	       	$scope.familyInformation.photo.questions[0].familyInformation_familyPhoto_1.answer == vm.app.GLOBAL.ANSWER_NO) {
        		vm.yesToProceed = true;
        	}
        };
        
        vm.openimportFamilyPopup = function(event,popupId) {
        	vm.dataList = [];
        	if($sessionStorage.selectedLanguage == vm.app.GLOBAL.ES) {
        		vm.language = true;
        	} else {
        		vm.language = false;
        	}
        	if(deviceDetector.raw.browser.firefox) {
        		vm.isFireFox = true;
        	} else {
        		vm.isFireFox = false;
        	}
        	
        	$scope.notselected = true;
        	$scope.siblingsSelectAll.checked = false;
        	vm.contentWaitForResponse=true;
        	var formId = $scope.investigationFormId;
        	
        	if(vm.language != undefined){
        		 var lang = vm.language;
        	}
        	var dataList = InvestigationFormService.getFamilyInformationService(formId,lang);
        	dataList.then(function(response) {
        		vm.dataList = response;
        		vm.contentWaitForResponse=false;

        		angular.forEach(vm.dataList, function(item){
        			item.selected = false;
                	
        		}); 
        	});
        	vm.popupShow(event,popupId);
        }
        
        $scope.selectedCount = function(){
        	vm.numberOfSiblingsAdded=0;
        	vm.selectedSiblings='';
        	angular.forEach(vm.dataList, function(data, key){
        		if(data.selected == true){
            		vm.numberOfSiblingsAdded++;
        		}
        	});

        }
        $scope.toggleSelect = function(){
        	var notselected = true;
        	var isSelected = true;
        	
        	
        	angular.forEach(vm.dataList, function(item){
        		if(!item.selected){
        			isSelected = false;
        		}else{
        			notselected = false;
        		}
    		}); 
			$scope.notselected = notselected;
        	$scope.siblingsSelectAll.checked = isSelected;
        	$scope.selectedCount();
        }
        
        vm.selectAll = function(){
        	if($scope.siblingsSelectAll.checked){
        		$scope.notselected = true;
        		angular.forEach(vm.dataList, function(item){
        			item.selected = false;
        		});
        	}else{
        		$scope.notselected = false;
        		angular.forEach(vm.dataList, function(item){
        			item.selected = true;
        		});
        	}
        	$scope.selectedCount();
        };
        /**
         * 
         *  alert dialoge to import siblings
         * 
         */
        vm.popupShow = function(event, popupId){
        	if(!fromAlert){
        		vm.numberOfSiblingsAdded=0;        		
        	}  
        	fromAlert=false;
        	$mdDialog.show({
				contentElement: angular.element('#' + popupId),
				targetEvent: event,
				clickOutsideToClose: false,
				escapeToClose: false
			});
        }
        vm.siblingImportAlert = function(event,alertId) {
        	$scope.disableConfirm = false;
        	$mdDialog.show({
				contentElement: angular.element('#' + alertId),
				targetEvent: event,
				clickOutsideToClose: false,
				escapeToClose: false
			});
        }
        vm.hideSiblingImportAlert = function(event,alertId,popupId) {
        	fromAlert=true;
        	vm.popupShow(event,popupId);
        	$mdDialog.hide({
				contentElement: angular.element('#' + alertId),
				targetEvent: event,
				clickOutsideToClose: false,
				escapeToClose: false
			});
        }

	}
})();