(function() {
	'use strict';

    angular
        .module('lariAidApp')
        .controller('LibraryEntityController', LibraryEntityController);
    
    LibraryEntityController.$inject = ['$rootScope','$scope','$state','$stateParams','$mdSidenav','$element','APP','tabservice','$mdMedia','$sessionStorage','LibraryEntityService','$q','$cookies','$mdDialog','CommonService','LibraryService','$timeout'];
    
    function LibraryEntityController($rootScope,$scope,$state,$stateParams,$mdSidenav,$element,APP,tabservice,$mdMedia,$sessionStorage,LibraryEntityService,$q,$cookies,$mdDialog,CommonService,LibraryService,$timeout){
    	
    	var vm = this;
    	vm.APP = APP;
    	vm.library = $stateParams;
    	vm.media = $mdMedia;
    	var qPromises = [];
    	
    	vm.initialLoader = false;
    	vm.loadingListData = false;
    	
    	//Get user from cookie storage
 		vm.user = CommonService.getCookieUser();
 		vm.loggedUserRole = vm.user.loggedUserRole;

 		vm.libraryEnityLocationObject = {};
	    vm.libraryEnityLocationObject.countryObj = {};
    	vm.libraryEnityLocationObject.provinceObj = {};
    	vm.libraryEnityLocationObject.cityObj = {};
    	vm.libraryEnityLocationObject.sectorObj = {}; 	
    	
    	$scope.libraryEntityInputSearchObject = {};
	    $scope.libraryEntityInputSearchObject.country = ''; 
    	$scope.libraryEntityInputSearchObject.province = '';
    	$scope.libraryEntityInputSearchObject.city = '';
    	$scope.libraryEntityInputSearchObject.countrySearchKey = '';
    	$scope.libraryEntityInputSearchObject.provinceSearchKey = '';
    	$scope.libraryEntityInputSearchObject.citySearchKey = '';
    	$scope.libraryEntityInputSearchObject.sectorSearchKey = '';
    	$scope.libraryEntityInputSearchObject.isAddOrSaveLocationCountry = false;
    	$scope.libraryEntityInputSearchObject.isAddOrSaveLocationProvince = false;
    	$scope.libraryEntityInputSearchObject.isAddOrSaveLocationCity = false;
    	$scope.libraryEntityInputSearchObject.isAddOrSaveLocationSector = false;
    	
    	
    	$scope.libraryEntityInputObject = {};
    	$scope.libraryEntityInputObject.country = false;
    	$scope.libraryEntityInputObject.province = false;
    	$scope.libraryEntityInputObject.city = false;
    	$scope.libraryEntityInputObject.sector = false;
    	
 		
    	var approvedEntityListTotalLength = 0;
    	vm.selectedEntityType = [];
    	vm.entityFilterObj = {};
    	vm.entityFilterObj.from = 0;
        vm.entityFilterObj.size = vm.APP.GLOBAL.DEFAULT_SIZE;
    	vm.approvedEntityList = [];    	
	    vm.endless = vm.APP.GLOBAL.ENDLESS;
	    vm.entityFilterObj.entityTypeId = [];
    	
    	vm.toggleLeft = buildToggler('left');
        vm.toggleRight = buildToggler('right');
        
        vm.currentUser = CommonService.getCookieUser();
        
        function buildToggler(componentId) {
            return function() {
              $mdSidenav(componentId).toggle();
            }
        };
        
        vm.sideNavClose = function(id){
     		$mdSidenav(id).close();
     	};
     	
     	$scope.callListfun = function(){
  	    	vm.getEntityProfilesList(vm.endless);
     	};
     	
     	vm.getLocationMappingId = function(){
     		
     		if($sessionStorage.libraryEnityLocationObject && Object.keys($sessionStorage.libraryEnityLocationObject).length > 0){
     			vm.libraryEnityLocationObject = $sessionStorage.libraryEnityLocationObject;
     		}
     		
     		$sessionStorage.libraryEnityLocationObject = vm.libraryEnityLocationObject;
     		
     		vm.entityFilterObj.locationMappingId = '';
        	if(vm.libraryEnityLocationObject.sectorObj && vm.libraryEnityLocationObject.sectorObj.sectorId) {
        		//vm.entityFilterObj.locationMappingId =  vm.libraryEnityLocationObject.sectorObj.locationMappingId;
        		vm.entityFilterObj.sectorId = vm.libraryEnityLocationObject.sectorObj.sectorId;
        	} else {
        		vm.entityFilterObj.sectorId = '';
        	}
        	if(vm.libraryEnityLocationObject.cityObj && vm.libraryEnityLocationObject.cityObj.cityId) {
        		//vm.entityFilterObj.locationMappingId =  vm.libraryEnityLocationObject.cityObj.locationMappingId;
        		vm.entityFilterObj.cityId =  vm.libraryEnityLocationObject.cityObj.cityId;
        	} else {
        		vm.entityFilterObj.cityId = '';
        	} 
        	if(vm.libraryEnityLocationObject.provinceObj && vm.libraryEnityLocationObject.provinceObj.provinceId) {
        		//vm.entityFilterObj.locationMappingId = vm.libraryEnityLocationObject.provinceObj.locationMappingId;
        		vm.entityFilterObj.provinceId = vm.libraryEnityLocationObject.provinceObj.provinceId;
        	} else {
        		vm.entityFilterObj.provinceId = '';
        	} 
        	if(vm.libraryEnityLocationObject.countryObj && vm.libraryEnityLocationObject.countryObj.countryId) {
        		//vm.entityFilterObj.locationMappingId = vm.libraryEnityLocationObject.countryObj.locationMappingId;
        		vm.entityFilterObj.countryId = vm.libraryEnityLocationObject.countryObj.countryId;
        	} else {
        		vm.entityFilterObj.countryId = '';
        	}
        	
     	};
    	
     	var clickingTimerEntity;
     	vm.getEntityProfilesList = function(type) {

     		clearTimeout(clickingTimerEntity);
     		clickingTimerEntity = setTimeout(function(){

     		//To disable check box
  	    	if(!vm.media('gt-sm')) {
				 vm.loadingListData = true;
			 }
    		//$timeout(function(){

	    		vm.getLocationMappingId();
	        	
	  	    	if(type != vm.endless){
	  	    		vm.approvedEntityList = [];
	        	}
	  	    	
	  	    	if(vm.approvedEntityList && vm.approvedEntityList.length == 0){
	        		vm.entityFilterObj.from = 0;
	        		approvedEntityListTotalLength = 0;
			    }
	        	else if(vm.approvedEntityList.length > 0){
	        		vm.entityFilterObj.from = vm.approvedEntityList.length;
	        	}
	  	    	vm.loadingEntityProfileListData = true;
	  	    	if(vm.entityFilterObj.from < approvedEntityListTotalLength || approvedEntityListTotalLength == 0){
		  	    	var data = LibraryEntityService.getAllEntityProfiles(vm.entityFilterObj);
		    		data.then(function(response){
		    			vm.loadingEntityProfileListData = false;
						vm.noMoreEntities = false;
		    			
			    		if(response && !angular.equals({}, response) && response.totalCount){
			    			approvedEntityListTotalLength = response.totalCount;
							angular.forEach(response.approvedEntityProfileDTOList, function (value, key) {
								//approvedDate split in response for spanish translation
								if (value.approvedDate != "" && value.approvedDate != null) {
									value.approvedMonth = value.approvedDate.substring(0, 3)
									value.approvedDay = value.approvedDate.substring(3, 7)
									value.approvedYear = value.approvedDate.substring(7, 12)
								}
			       				if(vm.approvedEntityList == null){
			       					vm.approvedEntityList = [];
			       					vm.approvedEntityList.push(value);
					        	 }
					        	 else{
					        		 vm.approvedEntityList.push(value);
					        	 }
			   			    });
			   			 	vm.entityFilterObj.from = vm.approvedEntityList.length;
			   			 	
			   				if(vm.entityFilterObj.from == approvedEntityListTotalLength){
			       				vm.loadingEntityProfileListData = false;
			       				vm.noMoreEntities = true;
			       			  }
			   				
			   				if(vm.entityFilterObj.from <= vm.APP.GLOBAL.DEFAULT_SIZE){
			   					vm.noMoreEntities = false;
			   				}
		   				
			    		}
			    		else if(angular.isUndefined(response)){
		        			vm.loadingEntityProfileListData  = false;
		        			vm.noMoreEntities = false;
	       		        } 
			    		vm.loadingListData = false;
			    	}, function(reason) {
			    		vm.loadingListData = false;
			        		return reason;
		     		});
	  	    	}
	  	    	else{
	  	    		vm.loadingListData = false;
	        		vm.loadingEntityProfileListData = false;
	        		vm.noMoreEntities = true;
	        		vm.loadingListData = false;
		   	   }
    		},200);
    	};
    	
    	vm.callSynchronizePromise = function(){
    		qPromises = [];
   		 
   		 	qPromises.push(vm.getEntityTypeList());
   		 
   		 	$q.all(qPromises)
   		 	.then(function(data){
	   	    	vm.getEntityProfilesList();
	   	    });
    	};
    	
    	vm.searchEntity = function(entitySearchKey){
    		vm.entityFilterObj.searchKey = entitySearchKey;
    		if(entitySearchKey.length >1 || entitySearchKey.length == 0){
    			vm.getEntityProfilesList();
    		}
 	    };
 	    
 	    vm.updateEntityFilterObjList = function(postVariable,selectedList) {
 	    	vm.entityFilterObj[postVariable] = [];
 	    	angular.forEach(selectedList, function(data, key) {
	  			vm.entityFilterObj[postVariable].push(data.id);
		  	});
 	    };
    	
    	//Common function for toggle based on the filter type
    	vm.toggleEntity = function (item, list) {
    		//To disable checkboxes
    		if(vm.media('gt-sm')) {
    			vm.loadingListData = true;
    		}
    		var idx;
   		    for(var i=0; i<list.length;i++) {
		          if(list[i].id == item.id) {
		        	  idx = i;
		          }
   		    }
   		    if (idx > -1) {
   		    	list.splice(idx, 1);
   		    }
   		    else {
   		    	//list = [];
   		    	list.push(item);
   		    }
   		    vm.selectedEntityType = list;
   		 
   		    //vm.unSelectCall(vm.entityTypeList,item);
	    	$sessionStorage.selectedEntityType = list;
	    	vm.updateEntityFilterObjList(vm.APP.LIBRARY.ENTITY_TYPE_ID,vm.selectedEntityType);
  		    
  		    if(vm.media('gt-sm')){
  		    	vm.loadingListData = true;
  		    	vm.getEntityTypeList();
  		    	vm.getEntityProfilesList();
  		    }
  		    
    	};
  		/*vm.unSelectCall = function(list,item){
  			angular.forEach(list, function(data, index) {
 					if(data.id != item.id){
 						data.checked = false;
 					}
				});
  		};*/
    	
    	vm.getEntityTypeList = function(isDefault) {
    		vm.showEntityTypeDiv = false;    		
    		var data = LibraryEntityService.getEntityTypeList();
        	data.then(function(response) {
        		vm.entityTypeList = [];
        		vm.entityTypeList = response.ENTITY;
        		angular.forEach(vm.entityTypeList, function(data, index) {
        			if(data.count > 0){
    		  			vm.showEntityTypeDiv = true; // To hide entire entity type div when all count is 0	
    		  			
    		  		// By default select all filters
                		if(isDefault) {
                			data.checked = true;
                			//Add the entity type to selected entity type list
                			vm.selectedEntityType.push(data);
                			//Add the entity type id to entityFilterObj
                			vm.entityFilterObj.entityTypeId.push(data.id);
                		}
            		}
  				});
        		
        		if(!isDefault){
		  	  	 	if($sessionStorage.selectedEntityType){
		  	  	 		vm.selectedEntityType = [];
	  	  				vm.selectedEntityType = $sessionStorage.selectedEntityType;
	  	  				vm.updateEntityFilterObjList(vm.APP.LIBRARY.ENTITY_TYPE_ID,vm.selectedEntityType);
	  	  			}
		  	  	 	
		  	  	 	// Default select after refresh
			  	  	angular.forEach(vm.entityTypeList, function(data, index) {
				  	  	angular.forEach(vm.selectedEntityType, function(selected, key){
		  					if(data.id == selected.id){
		  						data.checked = true;
		  					}
			  	  		});
	  				});
	  	  		}
	  	  		if(!vm.showEntityTypeDiv){
	  	  			vm.selectedEntityType = [];
	  	  		}
        	}, function(reason) {
        		vm.loadingListData = false;
        		return reason;
        	});
        	
        	return data;
    	};
    	
    	vm.getEntitySortedList = function(entitySortBy,isCallRequired) {
         	vm.entityFilterObj.sortBy = entitySortBy;
         	if(entitySortBy == vm.APP.LIBRARY.ENTITY_SORT_BY_COUNT) {
         		vm.entityFilterObj.sortOrder = vm.APP.GLOBAL.DESC;
         	} else if (entitySortBy == vm.APP.LIBRARY.ENTITY_SORT_BY_NAME) {
         		vm.entityFilterObj.sortOrder = vm.APP.GLOBAL.ASC;
         	}
         	
         	$sessionStorage.entitySortBy = entitySortBy;
         	
         	if(!isCallRequired){
         		vm.getEntityProfilesList();  //Prevent call from vm.userInit function.
         	}
         	
    	}
    	
    	vm.entityInit = function() {
    		vm.initialLoader = true;	
    		vm.sortOrderList = [{
                	"id":vm.APP.LIBRARY.ENTITY_SORT_BY_COUNT,
                	"value":"sortbyapproved"
                },{
                	"id":vm.APP.LIBRARY.ENTITY_SORT_BY_NAME,
                	"value":"sortbyname"
                }];
         		
         		//Default
    		if(angular.isUndefined(vm.entitySortBy) || angular.isUndefined(vm.entitySortBy.value)) {
    			vm.entityFilterObj.sortBy = vm.APP.LIBRARY.ENTITY_SORT_BY_COUNT;
    			vm.entityFilterObj.sortOrder = vm.APP.GLOBAL.DESC;
    		};
         		
         		//Sort by initialization through session storage
    		if($sessionStorage.entitySortBy){
    			for(var i=0;i<vm.sortOrderList.length;i++){
    				if(vm.sortOrderList[i].id == $sessionStorage.entitySortBy){
    					vm.entitySortBy = vm.sortOrderList[i];
    					break;
    				}
    			}
            		
    			vm.getEntitySortedList(vm.entitySortBy.id,true);  // To get sort by list 
    		};
    		
    		if($sessionStorage.selectedEntityType){
	   			   qPromises.push(vm.getEntityTypeList(false));
	   		 }
    		else{
	   			   qPromises.push(vm.getEntityTypeList(true));
	   		}
    		
    		$q.all(qPromises)
   	    	.then(function(data){
   	    		vm.initialLoader = false;
   	    		vm.getEntityProfilesList();
   	    	});
        	
            	
            	 
 	   		   //Retaining entityFilter drop down value
    		if($sessionStorage.entityFilter){
    			vm.entityFilter = $sessionStorage.entityFilter;
    			var entityFilterKeys = Object.keys(vm.entityFilter);
 			  
    			//Assigning vm.entityFilter ids into Post Object for Listing
    			var postVariable = "";
    			angular.forEach(entityFilterKeys, function(keyName, index) {
    				if(keyName){
    					postVariable = keyName + "Id";
    					if(vm.entityFilter[keyName]){
    						vm.entityFilterObj[postVariable] = vm.entityFilter[keyName].id;
    					}
    				}
    			}); 
    		};
    		
    	};
    	
    	//vm.entityInit();
    	
    }
	
})();