(function () {
  "use strict";

  angular.module("lariAidApp").controller("FormLocationDirectiveController", FormLocationDirectiveController);

  FormLocationDirectiveController.$inject = [
    "$rootScope",
    "$scope",
    "$state",
    "$stateParams",
    "$mdSidenav",
    "$element",
    "APP",
    "tabservice",
    "$mdMedia",
    "LibraryLocationService",
    "$q",
    "$timeout",
    "$sessionStorage",
    "communicationService"
  ];

  function FormLocationDirectiveController(
    $rootScope,
    $scope,
    $state,
    $stateParams,
    $mdSidenav,
    $element,
    APP,
    tabservice,
    $mdMedia,
    LibraryLocationService,
    $q,
    $timeout,
    $sessionStorage,
    communicationService
  ) {
    var vm = this;
    vm.countrychange = {};
    vm.provincechange = {};
    vm.citychange = {};
    vm.sectorchange = {};
    vm.country = {};
    vm.country1 = {};
    vm.province = {};
    vm.province1 = {};
    vm.city = {};
    vm.city1 = {};
    vm.sector = {};
    vm.sector1 = {};
    vm.APP = APP;
    vm.library = $stateParams;
    vm.media = $mdMedia;
    vm.locationList = [];
    vm.loadingData = false;
    vm.addnewlocationObject = {};
    $scope.searchLocationCountryList = [];
    $scope.searchLocationProvinceList = [];
    $scope.disableButton = {};
    $scope.searchLocationCityList = [];
    $scope.searchLocationSectorList = [];
    $scope.locLoader = false;
    //$scope.modelObject = {};
    $scope.addNewLocation;
    $scope.isRequested;
    /*$scope.inputsearchObject = {  
              "country": "",
              "countrySearchKey": "",
              "isAddOrSaveLocationCountry": false,
              "isAddOrSaveLocationProvince": false,
              "isAddOrSaveLocationCity": false,
              "isAddOrSaveLocationSector": false,
              "citySearchKey": "",
              "sectorSearchKey": "",
              "provinceSearchKey": "",
              "province": "",
              "city": "",
              "sector": ""};*/

    $scope.currentState = $state.current.name;

    $element.find("input").on("keydown", function (ev) {
      ev.stopPropagation();
    });

    $scope.checkRequiredFields = function (modelName, fieldId) {
      var result = false;
      if ($scope.modelObject[modelName] && $scope.modelObject[modelName][fieldId]) {
        result = true;
      }
      return true;
    };

    $scope.searchLocation = function (searchText, type, delayCall) {
      if (searchText && searchText.length >= 2) {
        $scope.inputsearchObject.isInputEmptyCountry = false;
        var deferred = $q.defer();
        $scope.libraryLocObj = {};
        $scope.libraryLocObj.searchKey = searchText;
        if (type == 'COUNTRY') {
          $scope.modelObject.countryObj = {};
          $scope.validateLocationId(type);
          $scope.existingSearchLocation(type);
          $scope.inputsearchObject.isAddOrSaveLocationCountry = ($scope.cleanInputValue) ? true : false;//To clear the Country Field while searching
        } else if (type == 'PROVINCE') {
          $scope.modelObject.provinceObj = {};
          $scope.validateLocationId(type);
          $scope.existingSearchLocation(type);
          $scope.inputsearchObject.isAddOrSaveLocationProvince = ($scope.cleanInputValue) ? true : false;//To clear the Province Field while searching 
        } else if (type == 'CITY') {
          $scope.modelObject.cityObj = {};
          $scope.validateLocationId(type);
          $scope.existingSearchLocation(type);
          $scope.inputsearchObject.isAddOrSaveLocationCity = ($scope.cleanInputValue) ? true : false;//To clear the City Field while searching
        } else if (type == 'SECTOR') {
          $scope.modelObject.sectorObj = {};
          $scope.inputsearchObject.isAddOrSaveLocationSector = ($scope.cleanInputValue) ? true : false;//To clear the Sector Field while searching
        }
        if ($scope.modelObject) {
          if ($scope.modelObject.countryObj && $scope.modelObject.countryObj.countryId != null) {
            $scope.libraryLocObj.countryId = $scope.modelObject.countryObj.countryId;
          }
          if ($scope.modelObject.provinceObj && $scope.modelObject.provinceObj.provinceId != null) {
            $scope.libraryLocObj.provinceId = $scope.modelObject.provinceObj.provinceId;
          }
          if ($scope.modelObject.cityObj && $scope.modelObject.cityObj.cityId != null) {
            $scope.libraryLocObj.cityId = $scope.modelObject.cityObj.cityId;
          }
          if ($scope.modelObject.sectorObj && $scope.modelObject.sectorObj.sectorId != null) {
            $scope.libraryLocObj.sectorId = $scope.modelObject.sectorObj.sectorId;
          }
        }
        if (communicationService.getData('isAddOrSaveLocation')) {
          if (type == 'COUNTRY' && $scope.currentStatevalid != "locations") {
            if ($scope.inputsearchObject.country) {
              $scope.locLoader = true;
              var data = LibraryLocationService.loadAllLocationsByType($scope.libraryLocObj, type);
              data.then(function (response) {
                $scope.locLoader = false;
                if (response.autoSuggestionLocationDTOList.length > 0) {
                  if (type == 'COUNTRY') {
                    $scope.searchLocationCountryList = response.autoSuggestionLocationDTOList;
                    if (communicationService.getData('isAddOrSaveLocation')) {
                      $scope.inputsearchObject.countrySearchKey = '';
                      $scope.inputsearchObject.isInputEmptyCountry = false;
                      $scope.inputsearchObject.isInputEmptyProvince = false;
                      $scope.inputsearchObject.isInputEmptyCity = false;
                      $scope.inputsearchObject.isInputEmptySector = false;
                      $scope.inputsearchObject.isAddOrSaveLocationCountry = false;
                    }
                  } else if (type == 'PROVINCE') {
                    $scope.searchLocationProvinceList = response.autoSuggestionLocationDTOList;
                  } else if (type == 'CITY') {
                    $scope.searchLocationCityList = response.autoSuggestionLocationDTOList;
                  } else if (type == 'SECTOR') {
                    $scope.searchLocationSectorList = response.autoSuggestionLocationDTOList;
                  }
                } else {
                  if (type == 'COUNTRY') {
                    $scope.searchLocationCountryList = response.autoSuggestionLocationDTOList;
                  } else if (type == 'PROVINCE') {
                    $scope.searchLocationProvinceList = response.autoSuggestionLocationDTOList;
                  } else if (type == 'CITY') {
                    $scope.searchLocationCityList = response.autoSuggestionLocationDTOList;
                  } else if (type == 'SECTOR') {
                    $scope.searchLocationSectorList = response.autoSuggestionLocationDTOList;
                  }
                  $scope.loadNewInputFields(type, searchText);
                }
                if (type == 'COUNTRY') {
                  deferred.resolve($scope.searchLocationCountryList);
                } else if (type == 'PROVINCE') {
                  deferred.resolve($scope.searchLocationProvinceList);
                } else if (type == 'CITY') {
                  deferred.resolve($scope.searchLocationCityList);
                } else if (type == 'SECTOR') {
                  deferred.resolve($scope.searchLocationSectorList);
                }
              });
              return deferred.promise;
            } else {
              $scope.loadNewInputFields(type, searchText);
            }
          } else {
            $scope.getListCall(type, searchText);
          }
        } else {
          $scope.getListCall(type, searchText, delayCall);
        }
      }
    };

    $scope.loadNewInputFields = function (type, searchText) {
      if (type == 'COUNTRY') {
        $scope.inputsearchObject.countrySearchKey = searchText;
        $scope.inputsearchObject.country = searchText;
        $scope.inputsearchObject.isAddOrSaveLocationCountry = true;
        vm.addnewlocationObject.country = true;
        vm.addnewlocationObject.province = true;
        vm.addnewlocationObject.city = true;
        vm.addnewlocationObject.sector = true;
        $scope.inputsearchObject.countryAltered = true;

        if (!$scope.inputsearchObject.isInputEmptySector && !$scope.inputsearchObject.isInputEmptyCity && !$scope.inputsearchObject.isInputEmptyProvince) {
          $scope.inputsearchObject.isAddOrSaveLocationSector = true;
          $scope.inputsearchObject.sectorAltered = true;
          $scope.inputsearchObject.isAddOrSaveLocationCity = true;
          $scope.inputsearchObject.cityAltered = true;
          $scope.inputsearchObject.isAddOrSaveLocationProvince = true;
          $scope.inputsearchObject.provinceAltered = true;
        } else {
          $scope.inputsearchObject.isAddOrSaveLocationSector = false;
          $scope.inputsearchObject.sectorAltered = false;
          $scope.inputsearchObject.isAddOrSaveLocationCity = false;
          $scope.inputsearchObject.cityAltered = false;
          $scope.inputsearchObject.isAddOrSaveLocationProvince = false;
          $scope.inputsearchObject.provinceAltered = false;
        }

      } else if (type == 'PROVINCE') {
        $scope.inputsearchObject.provinceSearchKey = searchText;
        $scope.inputsearchObject.province = searchText;
        $scope.inputsearchObject.isAddOrSaveLocationProvince = true;
        vm.addnewlocationObject.province = true;
        vm.addnewlocationObject.city = true;
        vm.addnewlocationObject.sector = true;
        $scope.inputsearchObject.provinceAltered = true;

        if (!$scope.inputsearchObject.isInputEmptySector && !$scope.inputsearchObject.isInputEmptyCity) {
          $scope.inputsearchObject.isAddOrSaveLocationSector = true;
          $scope.inputsearchObject.sectorAltered = true;
          $scope.inputsearchObject.isAddOrSaveLocationCity = true;
          $scope.inputsearchObject.cityAltered = true;
        } else {
          $scope.inputsearchObject.isAddOrSaveLocationSector = false;
          $scope.inputsearchObject.sectorAltered = false;
          $scope.inputsearchObject.isAddOrSaveLocationCity = false;
          $scope.inputsearchObject.cityAltered = false;
        }

      } else if (type == 'CITY') {
        $scope.inputsearchObject.citySearchKey = searchText;
        $scope.inputsearchObject.city = searchText;
        $scope.inputsearchObject.isAddOrSaveLocationCity = true;
        vm.addnewlocationObject.city = true;
        vm.addnewlocationObject.sector = true;
        $scope.inputsearchObject.cityAltered = true;

        if (!$scope.inputsearchObject.isInputEmptySector) {
          $scope.inputsearchObject.isAddOrSaveLocationSector = true;
          $scope.inputsearchObject.sectorAltered = true;
        } else {
          $scope.inputsearchObject.isAddOrSaveLocationSector = false;
          $scope.inputsearchObject.sectorAltered = false;
        }

      } else if (type == 'SECTOR') {
        $scope.inputsearchObject.sectorSearchKey = searchText;
        $scope.inputsearchObject.sector = searchText;
        $scope.inputsearchObject.isAddOrSaveLocationSector = true;
        vm.addnewlocationObject.sector = true;
        $scope.inputsearchObject.sectorAltered = true;
      }
      // $scope.addInputSearchObject();
    }

    $scope.getListCall = function (type, searchText, delayCall) {
      var deferred = $q.defer();
      $scope.locLoader = true;
      var data = LibraryLocationService.loadAllLocationsByType($scope.libraryLocObj, type);
      data.then(function (response) {
        $scope.locLoader = false;
        if (response.autoSuggestionLocationDTOList.length > 0) {
          if (type == 'COUNTRY') {
            $scope.searchLocationCountryList = response.autoSuggestionLocationDTOList;
            if (delayCall) {
              $scope.populatePreFillDetails($scope.searchLocationCountryList[0]);
            }
          } else if (type == 'PROVINCE') {
            $scope.searchLocationProvinceList = response.autoSuggestionLocationDTOList;
            if ($scope.currentStatevalid === "locations") {
              if ($scope.inputsearchObject.isAddOrSaveLocationCountry || $scope.inputsearchObject.isAddOrSaveLocationProvince || $scope.inputsearchObject.isAddOrSaveLocationCity || $scope.inputsearchObject.isAddOrSaveLocationSector) {

                communicationService.setData('isAddOrSaveLocation', false);
              }


            }
            if (communicationService.getData('isAddOrSaveLocation')) {
              $scope.inputsearchObject.isInputEmptyCountry = false;
              $scope.inputsearchObject.isInputEmptyProvince = false;
              //$scope.inputsearchObject.provinceSearchKey = '';
              $scope.inputsearchObject.isAddOrSaveLocationCountry = false;
              $scope.inputsearchObject.isAddOrSaveLocationProvince = false;
            }
            if (delayCall) {
              $scope.populatePreFillDetails($scope.searchLocationProvinceList[0]);
            }
          } else if (type == 'CITY') {
            $scope.searchLocationCityList = response.autoSuggestionLocationDTOList;
            if ($scope.currentStatevalid === "locations") {
              if ($scope.inputsearchObject.isAddOrSaveLocationCountry == true || $scope.inputsearchObject.isAddOrSaveLocationProvince == true || $scope.inputsearchObject.isAddOrSaveLocationCity == true || $scope.inputsearchObject.isAddOrSaveLocationSector == true) {

                communicationService.setData('isAddOrSaveLocation', false);
              }
            }
            if (communicationService.getData('isAddOrSaveLocation')) {
              $scope.inputsearchObject.isInputEmptyCountry = false;
              $scope.inputsearchObject.isInputEmptyProvince = false;
              $scope.inputsearchObject.isInputEmptyCity = false;
              //$scope.inputsearchObject.citySearchKey = '';
              $scope.inputsearchObject.isAddOrSaveLocationCountry = false;
              $scope.inputsearchObject.isAddOrSaveLocationProvince = false;
              $scope.inputsearchObject.isAddOrSaveLocationCity = false;
            }
            if (delayCall) {
              $scope.populatePreFillDetails($scope.searchLocationCityList[0]);
            }
          } else if (type == 'SECTOR') {
            $scope.searchLocationSectorList = response.autoSuggestionLocationDTOList;
            if ($scope.currentStatevalid === "locations") {
              if ($scope.inputsearchObject.isAddOrSaveLocationCountry == true || $scope.inputsearchObject.isAddOrSaveLocationProvince == true || $scope.inputsearchObject.isAddOrSaveLocationCity == true || $scope.inputsearchObject.isAddOrSaveLocationSector == true) {

                communicationService.setData('isAddOrSaveLocation', false);
              }
            }
            if (communicationService.getData('isAddOrSaveLocation')) {
              $scope.inputsearchObject.isInputEmptyCountry = false;
              $scope.inputsearchObject.isInputEmptyProvince = false;
              $scope.inputsearchObject.isInputEmptyCity = false;
              $scope.inputsearchObject.isInputEmptySector = false;
              //$scope.inputsearchObject.sectorSearchKey = '';
              $scope.inputsearchObject.isAddOrSaveLocationCountry = false;
              $scope.inputsearchObject.isAddOrSaveLocationProvince = false;
              $scope.inputsearchObject.isAddOrSaveLocationCity = false;
              $scope.inputsearchObject.isAddOrSaveLocationSector = false;
            }
          }
        } else {
          if (type == 'COUNTRY') {
            $scope.searchLocationCountryList = response.autoSuggestionLocationDTOList;
          } else if (type == 'PROVINCE') {
            $scope.searchLocationProvinceList = response.autoSuggestionLocationDTOList;
          } else if (type == 'CITY') {
            $scope.searchLocationCityList = response.autoSuggestionLocationDTOList;
          } else if (type == 'SECTOR') {
            $scope.searchLocationSectorList = response.autoSuggestionLocationDTOList;
          }
          $scope.loadNewInputFields(type, searchText);
        }
        if (type == 'COUNTRY') {
          deferred.resolve($scope.searchLocationCountryList);
        } else if (type == 'PROVINCE') {
          deferred.resolve($scope.searchLocationProvinceList);
        } else if (type == 'CITY') {
          deferred.resolve($scope.searchLocationCityList);
        } else if (type == 'SECTOR') {
          deferred.resolve($scope.searchLocationSectorList);
        }
        $rootScope.$emit('enableAddLocationButton');

      });
      return deferred.promise;
    }

    $scope.clear = function (type) {
      if (type == 'COUNTRY') {
        $scope.modelObject.countryObj = {};
        if ($scope.modelObject.provinceObj && $scope.modelObject.provinceObj.provinceId) {
          $scope.modelObject.provinceObj = {};
        }
        if ($scope.modelObject.cityObj && $scope.modelObject.cityObj.cityId) {
          $scope.modelObject.cityObj = {};
        }
        if ($scope.modelObject.sectorObj && $scope.modelObject.sectorObj.sectorId) {
          $scope.modelObject.sectorObj = {};
        }
        if (communicationService.getData('isAddOrSaveLocation')) {
          $scope.inputsearchObject.isInputEmptyCountry = false;
          $scope.inputsearchObject.isInputEmptyProvince = false;
          $scope.inputsearchObject.isInputEmptyCity = false;
          $scope.inputsearchObject.isInputEmptySector = false;
          $scope.inputsearchObject.countrySearchKey = '';
          $scope.inputsearchObject.provinceSearchKey = '';
          $scope.inputsearchObject.citySearchKey = '';
          $scope.inputsearchObject.sectorSearchKey = '';
          $scope.inputsearchObject.isAddOrSaveLocationCountry = false;
          $scope.inputsearchObject.isAddOrSaveLocationProvince = false;
          $scope.inputsearchObject.isAddOrSaveLocationCity = false;
          $scope.inputsearchObject.isAddOrSaveLocationSector = false;
        }

      } else if (type == 'PROVINCE') {
        $scope.modelObject.provinceObj = {};
        if ($scope.modelObject.cityObj && $scope.modelObject.cityObj.cityId) {
          $scope.modelObject.cityObj = {};
        }
        if ($scope.modelObject.sectorObj && $scope.modelObject.sectorObj.sectorId) {
          $scope.modelObject.sectorObj = {};
        }
        if (communicationService.getData('isAddOrSaveLocation')) {
          $scope.inputsearchObject.isInputEmptyProvince = false;
          $scope.inputsearchObject.isInputEmptyCity = false;
          $scope.inputsearchObject.isInputEmptySector = false;
          $scope.inputsearchObject.provinceSearchKey = '';
          $scope.inputsearchObject.citySearchKey = '';
          $scope.inputsearchObject.sectorSearchKey = '';
          $scope.inputsearchObject.isAddOrSaveLocationProvince = false;
          $scope.inputsearchObject.isAddOrSaveLocationCity = false;
          $scope.inputsearchObject.isAddOrSaveLocationSector = false;
        }
        if (vm.currentState == vm.APP.LIBRARY.ENTITY) {
          $scope.searchLocation($scope.modelObject.countryObj.countryTxt, 'COUNTRY', true);
        }

      } else if (type == 'CITY') {
        $scope.modelObject.cityObj = {};
        if ($scope.modelObject.sectorObj && $scope.modelObject.sectorObj.sectorId) {
          $scope.modelObject.sectorObj = {};
        }
        if (communicationService.getData('isAddOrSaveLocation')) {
          $scope.inputsearchObject.isInputEmptyCity = false;
          $scope.inputsearchObject.isInputEmptySector = false;
          $scope.inputsearchObject.citySearchKey = '';
          $scope.inputsearchObject.sectorSearchKey = '';
          $scope.inputsearchObject.isAddOrSaveLocationCity = false;
          $scope.inputsearchObject.isAddOrSaveLocationSector = false;
        }
        if (vm.currentState == vm.APP.LIBRARY.ENTITY) {
          $scope.searchLocation($scope.modelObject.provinceObj.provinceTxt, 'PROVINCE', true);
        }

      } else if (type == 'SECTOR') {
        $scope.modelObject.sectorObj = {};
        if (communicationService.getData('isAddOrSaveLocation')) {
          $scope.inputsearchObject.isInputEmptySector = false;
          $scope.inputsearchObject.sectorSearchKey = '';
          $scope.inputsearchObject.isAddOrSaveLocationSector = false;
        }
        if (vm.currentState == vm.APP.LIBRARY.ENTITY) {
          $scope.searchLocation($scope.modelObject.cityObj.cityTxt, 'CITY', true);
        }
      }
    }


    $scope.populatePreFillDetails = function (locationObj, isFromValidation) {
      if (locationObj) {
        if(locationObj.id || locationObj.locationMappingId)
		  	{
			  	communicationService.setData('ValidateEntityLocationField', locationObj);
			  }
        $scope.copyLocationObj = angular.copy(locationObj);
        if ($scope.copyLocationObj.countryId != null) {
          $scope.modelObject.countryObj = $scope.copyLocationObj;
          $scope.inputsearchObject.country = $scope.modelObject.countryObj.countryTxt;
          $scope.inputsearchObject.countrySearchKey = $scope.modelObject.countryObj.countryTxt;

          $scope.inputsearchObject.isAddOrSaveLocationCountry = false;
        } else if (isFromValidation && ($scope.copyLocationObj.countryTxt == "" || angular.isUndefinedOrNull($scope.copyLocationObj.countryTxt))) {
          $scope.inputsearchObject.isAddOrSaveLocationCountry = false;
        }
        if ($scope.copyLocationObj.provinceId != null) {
          $scope.modelObject.provinceObj = $scope.copyLocationObj;
          $scope.inputsearchObject.province = $scope.modelObject.provinceObj.provinceTxt;
          $scope.inputsearchObject.provinceSearchKey = $scope.modelObject.provinceObj.provinceTxt;

          $scope.inputsearchObject.isAddOrSaveLocationProvince = false;
        } else if (isFromValidation && ($scope.copyLocationObj.provinceTxt == "" || angular.isUndefinedOrNull($scope.copyLocationObj.provinceTxt))) {
          $scope.inputsearchObject.isAddOrSaveLocationProvince = false;
        }
        if ($scope.copyLocationObj.cityId != null) {
          $scope.modelObject.cityObj = $scope.copyLocationObj;
          $scope.inputsearchObject.city = $scope.modelObject.cityObj.cityTxt;
          $scope.inputsearchObject.citySearchKey = $scope.modelObject.cityObj.cityTxt;

          $scope.inputsearchObject.isAddOrSaveLocationCity = false;
        } else if (isFromValidation && ($scope.copyLocationObj.cityTxt == "" || angular.isUndefinedOrNull($scope.copyLocationObj.cityTxt))) {
          $scope.inputsearchObject.isAddOrSaveLocationCity = false;
        }
        if ($scope.copyLocationObj.sectorId != null) {
          $scope.modelObject.sectorObj = $scope.copyLocationObj;
          $scope.inputsearchObject.sector = $scope.modelObject.sectorObj.sectorTxt;
          $scope.inputsearchObject.sectorSearchKey = $scope.modelObject.sectorObj.sectorTxt;

          $scope.inputsearchObject.isAddOrSaveLocationSector = false;
        } else if (isFromValidation && ($scope.copyLocationObj.sectorTxt == "" || angular.isUndefinedOrNull($scope.copyLocationObj.sectorTxt))) {
          $scope.inputsearchObject.isAddOrSaveLocationSector = false;
        }

        if (!angular.isUndefinedOrNull($scope.modelObject.cityObj.locationMappingId) && !angular.isUndefinedOrNull($scope.modelObject.provinceObj.locationMappingId)
          && $scope.modelObject.cityObj.locationMappingId != $scope.modelObject.provinceObj.locationMappingId) {
          $scope.inputsearchObject.isAddOrSaveLocationCity = true;
          $scope.modelObject.cityObj.locationMappingId = undefined;

          if (!angular.isUndefinedOrNull($scope.copyLocationObj.sectorTxt) && $scope.copyLocationObj.sectorTxt != "") {
            $scope.inputsearchObject.isAddOrSaveLocationSector = true;
          }
        }
      }
    }

    $scope.countryChange = function () {
      vm.addnewlocationObject = {};
      vm.addnewlocationObject.country = true;
      vm.addnewlocationObject.province = true;
      vm.addnewlocationObject.city = true;
      vm.addnewlocationObject.sector = true;
      if ($scope.inputsearchObject.countrySearchKey == "") {
        $scope.modelObject.countryObj = {};
        $scope.inputsearchObject.country = "";
        $scope.formName.country.$error = true;
        $scope.inputsearchObject.isAddOrSaveLocationCountry = false;
        $scope.inputsearchObject.province = $scope.inputsearchObject.provinceSearchKey;
        $scope.inputsearchObject.isAddOrSaveLocationProvince = true;
        $scope.inputsearchObject.city = $scope.inputsearchObject.citySearchKey;
        $scope.inputsearchObject.isAddOrSaveLocationCity = true;
        $scope.inputsearchObject.sector = $scope.inputsearchObject.sectorSearchKey;
        $scope.inputsearchObject.isAddOrSaveLocationSector = true;
        $scope.existingSearchLocation("COUNTRY");
        if (angular.isUndefinedOrNull($scope.inputsearchObject.provinceSearchKey) && angular.isUndefinedOrNull($scope.inputsearchObject.citySearchKey)
          && angular.isUndefinedOrNull($scope.inputsearchObject.sectorSearchKey)) {
          $scope.inputsearchObject.isAddOrSaveLocationProvince = false;
          $scope.inputsearchObject.isAddOrSaveLocationCity = false;
          $scope.inputsearchObject.isAddOrSaveLocationSector = false;
        }
      } else {
        $scope.formName.country.$error = false;
      }
    };

    $scope.countrySearchChange = function () {
      if ($scope.inputsearchObject.country == "") {
        $scope.inputsearchObject.countrySearchKey = "";
        $scope.countryChange();
        $scope.formName.country.$error = true;
      } else {
        $scope.formName.country.$error = false;
      }
    };

    $scope.provinceChange = function () {
      vm.addnewlocationObject = {};
      vm.addnewlocationObject.province = true;
      vm.addnewlocationObject.city = true;
      vm.addnewlocationObject.sector = true;
      $scope.locationAltered();
      if ($scope.inputsearchObject.provinceSearchKey == "") {

        $scope.inputsearchObject.province = "";
        $scope.formName.province.$error = true;
        if (($scope.modelObject.countryObj && !angular.isUndefinedOrNull($scope.modelObject.countryObj.countryId) && !angular.isUndefinedOrNull($scope.inputsearchObject.countrySearchKey)) || !$scope.inputsearchObject.isAddOrSaveLocationCountry) {
          $scope.inputsearchObject.isAddOrSaveLocationCity = true;
          $scope.inputsearchObject.isAddOrSaveLocationProvince = false;
          $scope.inputsearchObject.isAddOrSaveLocationSector = true;
          $scope.existingSearchLocation("PROVINCE");
          if (angular.isUndefinedOrNull($scope.inputsearchObject.citySearchKey) && angular.isUndefinedOrNull($scope.inputsearchObject.sectorSearchKey)) {
            $scope.inputsearchObject.isAddOrSaveLocationCity = false;
            $scope.inputsearchObject.isAddOrSaveLocationSector = false;
          }
          $scope.modelObject.provinceObj = {};
          $scope.modelObject.cityObj.cityId = null;
          $scope.modelObject.sectorObj.sectorId = null;
          $scope.inputsearchObject.city = $scope.inputsearchObject.citySearchKey;
          $scope.inputsearchObject.sector = $scope.inputsearchObject.sectorSearchKey;
        }
        else {
          vm.addnewlocationObject.country = true;
        }
      } else {
        $scope.formName.province.$error = false;
      }
    };

    $scope.provinceSearchChange = function () {
      if ($scope.inputsearchObject.province == "") {
        $scope.inputsearchObject.provinceSearchKey = "";
        $scope.inputsearchObject.isAddOrSaveLocationCountry = false;
        $scope.provinceChange();
        $scope.formName.province.$error = true;
      } else {
        $scope.formName.province.$error = false;
      }
    };

    $scope.cityChange = function () {
      vm.addnewlocationObject = {};
      vm.addnewlocationObject.city = true;
      $scope.locationAltered();
      if ($scope.inputsearchObject.citySearchKey == "") {
        $scope.inputsearchObject.city = "";
        if (($scope.modelObject.provinceObj && !angular.isUndefinedOrNull($scope.modelObject.provinceObj.provinceId) && !angular.isUndefinedOrNull($scope.inputsearchObject.provinceSearchKey)) || !$scope.inputsearchObject.isAddOrSaveLocationProvince) {
          $scope.inputsearchObject.isAddOrSaveLocationCity = false;
          $scope.formName.city.$error = true;
          $scope.modelObject.cityObj = {};
        }
      } else {
        vm.addnewlocationObject.province = true;
        $scope.formName.city.$error = false;
      }
    };

    $scope.citySearchChange = function () {
      if ($scope.inputsearchObject.city == "") {
        $scope.inputsearchObject.isAddOrSaveLocationCity = false;
        $scope.inputsearchObject.citySearchKey = "";
        $scope.cityChange();
        $scope.formName.city.$error = true;
      } else {
        $scope.formName.city.$error = false;
      }
      if ($scope.isRequested) {
        $scope.isRequested = false;
      }
    };

    $scope.sectorChange = function () {
      vm.addnewlocationObject = {};
      vm.addnewlocationObject.sector = true;
      $scope.locationAltered();
      if ($scope.inputsearchObject.sectorSearchKey == "") {
        $scope.inputsearchObject.sector = "";
        if (($scope.modelObject.cityObj && !angular.isUndefinedOrNull($scope.modelObject.cityObj.cityId) && !angular.isUndefinedOrNull($scope.inputsearchObject.citySearchKey)) || !$scope.inputsearchObject.isAddOrSaveLocationCity) {
          vm.addnewlocationObject.city = true;
          $scope.inputsearchObject.isAddOrSaveLocationSector = false;
          $scope.clear('SECTOR');
        }
      }
      else {
        vm.addnewlocationObject.city = true;
      }
    };

    $scope.sectorSearchChange = function () {
      if ($scope.inputsearchObject.sector == "") {
        $scope.inputsearchObject.isAddOrSaveLocationSector = false;
        $scope.inputsearchObject.sectorSearchKey = "";
        $scope.sectorChange();
      }
      if ($scope.isRequested) {
        $scope.isRequested = false;
      }
    };

    $scope.clearName = function (name, searchKey, dropValue) {
      $scope[searchKey] = undefined;
      $scope[dropValue] = undefined;
    };

    $scope.replaceInputField = function () {
      var requestType;
      if (vm.locationRequestType != null) {
        requestType = vm.locationRequestType.name;
      }
      if (requestType == vm.APP.LIBRARY.COUNTRY) {
        if (vm.country != null || vm.location.country != null) {
          //vm.isEnableSave=true;
        }
        if (vm.isShowCountry == false && vm.addLocationObject.countryObj == undefined && vm.country != null) {
          vm.isReplaceCountry = true;
          vm.requestFlagCountryInput = true;
          vm.location.country = vm.country;
        }
      }
      if (requestType == vm.APP.LIBRARY.PROVINCE) {
        if (vm.province != null) {
          //vm.isEnableSave=true;
        }
        if (vm.isShowProvince == false && vm.addLocationObject.countryObj != null && vm.addLocationObject.provinceObj == undefined && vm.province != null) {
          vm.location.province = vm.province;
          vm.isReplaceProvince = true;
          vm.requestFlagProvinceInput = true;
          //vm.isReplaceCountry = false;
        }
      }
      if (requestType == vm.APP.LIBRARY.CITY) {
        if (vm.city != null) {
          //vm.isEnableSave=true;
        }
        if (vm.isShowCity == false && vm.addLocationObject.countryObj != null && vm.addLocationObject.provinceObj != null && vm.addLocationObject.cityObj == undefined && vm.city != null) {
          vm.location.city = vm.city;
          vm.isReplaceCity = true;
          vm.requestFlagCityInput = true;
          //vm.isReplaceCountry = false;
        }
      }
      if (requestType == vm.APP.LIBRARY.SECTOR) {
        if (vm.city != null) {
          //vm.isEnableSave=true;
        }
        if (vm.isShowSector == false && vm.addLocationObject.countryObj != null && vm.addLocationObject.provinceObj != null && vm.addLocationObject.cityObj != null && vm.addLocationObject.sectorObj == null && vm.sector != null) {
          vm.location.sector = vm.sector;
          vm.isReplaceSector = true;
          vm.requestFlagSectorInput = true;
          //vm.isReplaceCountry = false;
        }
      }
    };

    $rootScope.loadValid = function () {
      $scope.saveValid({ type: 'COUNTRY' });
      $scope.saveValid({ type: 'PROVINCE' });
      $scope.saveValid({ type: 'CITY' });
      $scope.saveValid({ type: 'SECTOR' });
    };

    // this method is used for set the given values in all location
    $scope.saveValid = function (input) {
      vm.countrychange = "";
      vm.provincechange = "";
      vm.citychange = "";
      vm.sectorchange = "";
      $scope.currentStatevalid = $state.current.name;
      if (input.type == "COUNTRY") {
        if($scope.currentStatevalid == "investigations"){
          if ($scope.modelObject.countryObj && $scope.modelObject.countryObj.countryTxt != null && $scope.modelObject.countryObj.countryTxt != undefined) {
            vm.countrychange = $scope.modelObject.countryObj.countryTxt;
          }else if($scope.inputsearchObject.country != undefined){
            vm.countrychange = $scope.inputsearchObject.country;
          }
        }else{
        if (($scope.currentStatevalid != "entities") && ($scope.currentStatevalid != "request")) {
          if ($scope.inputsearchObject.country != vm.country && $scope.cleanInputValue == $scope.inputsearchObject.country) {
            vm.countrychange = $scope.inputsearchObject.country;
          } else if ($scope.inputsearchObject.countrySearchKey != vm.country1 && $scope.cleanInputValue == $scope.inputsearchObject.countrySearchKey) {
            vm.countrychange = $scope.inputsearchObject.countrySearchKey;
          } else if ($scope.inputsearchObject.countrySearchKey != undefined && $scope.inputsearchObject.country != undefined && $scope.inputsearchObject.countrySearchKey == vm.country1 && $scope.inputsearchObject.country == vm.country) {
            vm.countrychange = $scope.inputsearchObject.country
          } else if ($scope.modelObject.countryObj && $scope.modelObject.countryObj.countryTxt != null && $scope.modelObject.countryObj.countryTxt != undefined) {
            vm.countrychange = $scope.modelObject.countryObj.countryTxt;
          } else if ($scope.inputsearchObject.countrySearchKey != undefined && $scope.inputsearchObject.country != undefined && $scope.inputsearchObject.countrySearchKey == vm.country1) {
            vm.countrychange = $scope.inputsearchObject.countrySearchKey;
          } else if ($scope.inputsearchObject.countrySearchKey != vm.country1) {
            vm.countrychange = $scope.inputsearchObject.country;
          }
        } else {
          if ($scope.modelObject.countryObj && $scope.modelObject.countryObj.countryTxt != null && $scope.modelObject.countryObj.countryTxt != undefined) {
            vm.countrychange = $scope.modelObject.countryObj.countryTxt;
          } else if ($scope.inputsearchObject.countrySearchKey != vm.country1 && $scope.inputsearchObject.countrySearchKey != undefined && $scope.cleanInputValue == $scope.inputsearchObject.countrySearchKey) {
            vm.countrychange = $scope.inputsearchObject.countrySearchKey;
          } else if ($scope.inputsearchObject.country != vm.country && $scope.inputsearchObject.country != undefined && $scope.cleanInputValue == $scope.inputsearchObject.country) {
            vm.countrychange = $scope.inputsearchObject.country;
          } else if ($scope.inputsearchObject.countrySearchKey != undefined && $scope.inputsearchObject.country != undefined && $scope.inputsearchObject.countrySearchKey == vm.country1 && $scope.inputsearchObject.country == vm.country) {
            vm.countrychange = $scope.inputsearchObject.country;
          } else if ($scope.inputsearchObject.countrySearchKey != undefined && $scope.inputsearchObject.country != undefined && $scope.inputsearchObject.countrySearchKey == vm.country1) {
            vm.countrychange = $scope.inputsearchObject.countrySearchKey;
          } else if ($scope.inputsearchObject.countrySearchKey != vm.country1) {
            vm.countrychange = $scope.inputsearchObject.country;
          }
        }
      }
        if (vm.countrychange == "") {
          vm.countrychange = null;
        }
        if ($scope.modelObject.countryObj && $scope.modelObject.countryObj.countryId != "" && (!$scope.inputsearchObject.isAddOrSaveLocationCountry || $scope.currentStatevalid != "neighbourhoodCanvas")) {
          $scope.modelObject.countryObj.countryTxt = vm.countrychange;
          $scope.inputsearchObject.country = vm.countrychange;
          $scope.inputsearchObject.countrySearchKey = vm.countrychange;
        } else if ($scope.modelObject.countryObj && $scope.modelObject.countryObj.countryTxt == $scope.inputsearchObject.country) {
          $scope.modelObject.countryObj.countryId = undefined;
          $scope.inputsearchObject.country = $scope.modelObject.countryObj.countryTxt;
        } else {
          $scope.inputsearchObject.country = vm.countrychange;
          $scope.inputsearchObject.countrySearchKey = vm.countrychange;
        }
        vm.country = angular.copy($scope.inputsearchObject.country);
        vm.country1 = angular.copy($scope.inputsearchObject.countrySearchKey);
      }
      if (input.type == "PROVINCE") {
        if($scope.currentStatevalid == "investigations"){
          if ($scope.modelObject.provinceObj && $scope.modelObject.provinceObj.provinceTxt != null && $scope.modelObject.provinceObj.provinceTxt != undefined) {
            vm.provincechange = $scope.modelObject.provinceObj.provinceTxt;
          }else if($scope.inputsearchObject.province != undefined){
            vm.provincechange = $scope.inputsearchObject.province;
          }
        }else{
        if (($scope.currentStatevalid != "entities") && ($scope.currentStatevalid != "request")) {
          if ($scope.inputsearchObject.province != vm.province && $scope.cleanInputValue == $scope.inputsearchObject.province) {
            vm.provincechange = $scope.inputsearchObject.province;
          } else if ($scope.inputsearchObject.provinceSearchKey != vm.province1 && $scope.cleanInputValue == $scope.inputsearchObject.provinceSearchKey) {
            vm.provincechange = $scope.inputsearchObject.provinceSearchKey;
          } else if ($scope.inputsearchObject.provinceSearchKey != undefined && $scope.inputsearchObject.province != undefined && $scope.inputsearchObject.provinceSearchKey == vm.province1 && $scope.inputsearchObject.province == vm.province) {
            vm.provincechange = $scope.inputsearchObject.province;
          } else if ($scope.modelObject.provinceObj && $scope.modelObject.provinceObj.provinceTxt != null && $scope.modelObject.provinceObj.provinceTxt != undefined) {
            vm.provincechange = $scope.modelObject.provinceObj.provinceTxt;
          } else if ($scope.inputsearchObject.provinceSearchKey != undefined && $scope.inputsearchObject.province != undefined && $scope.inputsearchObject.provinceSearchKey == vm.province1) {
            vm.provincechange = $scope.inputsearchObject.provinceSearchKey;
          } else if ($scope.inputsearchObject.provinceSearchKey != vm.province1) {
            vm.provincechange = $scope.inputsearchObject.province;
          }
        } else {
          if ($scope.modelObject.provinceObj && $scope.modelObject.provinceObj.provinceTxt != null && $scope.modelObject.provinceObj.provinceTxt != undefined) {
            vm.provincechange = $scope.modelObject.provinceObj.provinceTxt;
          } else if ($scope.inputsearchObject.provinceSearchKey != vm.province1 && $scope.cleanInputValue == $scope.inputsearchObject.provinceSearchKey) {
            vm.provincechange = $scope.inputsearchObject.provinceSearchKey;
          } else if ($scope.inputsearchObject.province != vm.province && $scope.cleanInputValue == $scope.inputsearchObject.province) {
            vm.provincechange = $scope.inputsearchObject.province;
          } else if ($scope.inputsearchObject.provinceSearchKey != undefined && $scope.inputsearchObject.province != undefined && $scope.inputsearchObject.provinceSearchKey == vm.province1 && $scope.inputsearchObject.province == vm.province) {
            vm.provincechange = $scope.inputsearchObject.province;
          } else if ($scope.inputsearchObject.provinceSearchKey != undefined && $scope.inputsearchObject.province != undefined && $scope.inputsearchObject.provinceSearchKey == vm.province1) {
            vm.provincechange = $scope.inputsearchObject.provinceSearchKey;
          } else if ($scope.inputsearchObject.provinceSearchKey != vm.province1) {
            vm.provincechange = $scope.inputsearchObject.province;
          }
        }
      }
        if (vm.provincechange == "") {
          vm.provincechange = null;
        }
        if ($scope.modelObject.provinceObj && $scope.modelObject.provinceObj.provinceId != "" && (!$scope.inputsearchObject.isAddOrSaveLocationProvince || $scope.currentStatevalid != "neighbourhoodCanvas")) {
          $scope.modelObject.provinceObj.provinceTxt = vm.provincechange;
          $scope.inputsearchObject.province = vm.provincechange;
          $scope.inputsearchObject.provinceSearchKey = vm.provincechange;
        } else if ($scope.modelObject.provinceObj && $scope.modelObject.provinceObj.provinceTxt == $scope.inputsearchObject.province) {
          $scope.modelObject.provinceObj.provinceId != undefined;
          $scope.inputsearchObject.province = $scope.modelObject.provinceObj.provinceTxt;
        } else {
          $scope.inputsearchObject.province = vm.provincechange;
          $scope.inputsearchObject.provinceSearchKey = vm.provincechange;
        }
        vm.province = angular.copy($scope.inputsearchObject.province);
        vm.province1 = angular.copy($scope.inputsearchObject.provinceSearchKey);
      }

      if (input.type == "CITY") {
        if($scope.currentStatevalid == "investigations"){
          if ($scope.modelObject.cityObj && $scope.modelObject.cityObj.cityTxt != null && $scope.modelObject.cityObj.cityTxt != undefined) {
            vm.citychange = $scope.modelObject.cityObj.cityTxt;
          }else if($scope.inputsearchObject.city != undefined){
            vm.citychange = $scope.inputsearchObject.city;
          }
        }else{
        if (($scope.currentStatevalid != "entities") && ($scope.currentStatevalid != "request")) {
          if ($scope.inputsearchObject.city != vm.city && $scope.cleanInputValue == $scope.inputsearchObject.city) {
            vm.citychange = $scope.inputsearchObject.city;
          } else if ($scope.inputsearchObject.citySearchKey != vm.city1 && $scope.cleanInputValue == $scope.inputsearchObject.citySearchKey) {
            vm.citychange = $scope.inputsearchObject.citySearchKey;
          } else if ($scope.inputsearchObject.citySearchKey != undefined && $scope.inputsearchObject.city != undefined && $scope.inputsearchObject.citySearchKey == vm.city1 && $scope.inputsearchObject.city == vm.city) {
            vm.citychange = $scope.inputsearchObject.city;
          } else if ($scope.modelObject.cityObj && $scope.modelObject.cityObj.cityTxt != null && $scope.modelObject.cityObj.cityTxt != undefined) {
            vm.citychange = $scope.modelObject.cityObj.cityTxt;
          } else if ($scope.inputsearchObject.citySearchKey != undefined && $scope.inputsearchObject.city != undefined && $scope.inputsearchObject.citySearchKey == vm.city1) {
            vm.citychange = $scope.inputsearchObject.citySearchKey;
          } else if ($scope.inputsearchObject.citySearchKey != vm.city1) {
            vm.citychange = $scope.inputsearchObject.city;
          }
        } else {
          if ($scope.modelObject.cityObj && $scope.modelObject.cityObj.cityTxt != null && $scope.modelObject.cityObj.cityTxt != undefined) {
            vm.citychange = $scope.modelObject.cityObj.cityTxt;
          } else if ($scope.inputsearchObject.citySearchKey != vm.city1 && $scope.cleanInputValue == $scope.inputsearchObject.citySearchKey) {
            vm.citychange = $scope.inputsearchObject.citySearchKey;
          } else if ($scope.inputsearchObject.city != vm.city && $scope.cleanInputValue == $scope.inputsearchObject.city) {
            vm.citychange = $scope.inputsearchObject.city;
          } else if ($scope.inputsearchObject.citySearchKey != undefined && $scope.inputsearchObject.city != undefined && $scope.inputsearchObject.citySearchKey == vm.city1 && $scope.inputsearchObject.city == vm.city) {
            vm.citychange = $scope.inputsearchObject.city;
          } else if ($scope.inputsearchObject.citySearchKey != undefined && $scope.inputsearchObject.city != undefined && $scope.inputsearchObject.citySearchKey == vm.city1) {
            vm.citychange = $scope.inputsearchObject.citySearchKey;
          } else if ($scope.inputsearchObject.citySearchKey != vm.city1) {
            vm.citychange = $scope.inputsearchObject.city;
          }
        }
      }
        if (vm.citychange == "") {
          vm.citychange = null;
        }
        if ($scope.modelObject.cityObj && $scope.modelObject.cityObj.cityId != "" && (!$scope.inputsearchObject.isAddOrSaveLocationCity || $scope.currentStatevalid != "neighbourhoodCanvas")) {
          $scope.modelObject.cityObj.cityTxt = vm.citychange;
          $scope.inputsearchObject.city = vm.citychange;
          $scope.inputsearchObject.citySearchKey = vm.citychange;
        } else if ($scope.modelObject.cityObj && $scope.modelObject.cityObj.cityTxt == $scope.inputsearchObject.city) {
          $scope.inputsearchObject.city = $scope.modelObject.cityObj.cityTxt;
          $scope.modelObject.cityObj.cityId != undefined;
        } else {
          $scope.inputsearchObject.city = vm.citychange;
          $scope.inputsearchObject.citySearchKey = vm.citychange;
        }
        vm.city = angular.copy($scope.inputsearchObject.city);
        vm.city1 = angular.copy($scope.inputsearchObject.citySearchKey);
      }

      if (input.type == "SECTOR") {
        if($scope.currentStatevalid == "investigations"){
          if ($scope.modelObject.sectorObj && $scope.modelObject.sectorObj.sectorTxt != null && $scope.modelObject.sectorObj.sectorTxt != undefined) {
            vm.sectorchange = $scope.modelObject.sectorObj.sectorTxt;
          }else if($scope.inputsearchObject.sector != undefined){
            vm.sectorchange = $scope.inputsearchObject.sector;
          }
        }else{
        if (($scope.currentStatevalid != "entities") && ($scope.currentStatevalid != "request")) {
          if ($scope.inputsearchObject.sector != vm.sector && $scope.cleanInputValue == $scope.inputsearchObject.sector) {
            vm.sectorchange = $scope.inputsearchObject.sector;
          } else if ($scope.inputsearchObject.sectorSearchKey != vm.sector1 && $scope.cleanInputValue == $scope.inputsearchObject.sectorSearchKey) {
            vm.sectorchange = $scope.inputsearchObject.sectorSearchKey;
          } else if ($scope.inputsearchObject.sectorSearchKey != undefined && $scope.inputsearchObject.sector != undefined && $scope.inputsearchObject.sectorSearchKey == vm.sector1 && $scope.inputsearchObject.sector == vm.sector) {
            vm.sectorchange = $scope.inputsearchObject.sector;
          } else if ($scope.modelObject.sectorObj && $scope.modelObject.sectorObj.sectorTxt != null && $scope.modelObject.sectorObj.sectorTxt != undefined) {
            vm.sectorchange = $scope.modelObject.sectorObj.sectorTxt;
          } else if ($scope.inputsearchObject.sectorSearchKey != undefined && $scope.inputsearchObject.sector != undefined && $scope.inputsearchObject.sectorSearchKey == vm.sector1) {
            vm.sectorchange = $scope.inputsearchObject.sectorSearchKey;
          } else if ($scope.inputsearchObject.sectorSearchKey != vm.sector1) {
            vm.sectorchange = $scope.inputsearchObject.sector;
          }
        } else {
          if ($scope.modelObject.sectorObj && $scope.modelObject.sectorObj.sectorTxt != null && $scope.modelObject.sectorObj.sectorTxt != undefined && $scope.currentStatevalid === "entities") {
            vm.sectorchange = $scope.modelObject.sectorObj.sectorTxt;
          } else if ($scope.inputsearchObject.sectorSearchKey != vm.sector1 && $scope.inputsearchObject.sectorSearchKey != undefined && $scope.cleanInputValue == $scope.inputsearchObject.sectorSearchKey) {
            vm.sectorchange = $scope.inputsearchObject.sectorSearchKey;
          } else if ($scope.inputsearchObject.sector != vm.sector && $scope.cleanInputValue == $scope.inputsearchObject.sector && $scope.inputsearchObject.sector != undefined) {
            vm.sectorchange = $scope.inputsearchObject.sector;
          } else if ($scope.modelObject.sectorObj && $scope.modelObject.sectorObj.sectorTxt != vm.sector2 && $scope.currentStatevalid === "request") {
            vm.sectorchange = $scope.modelObject.sectorObj.sectorTxt;
          } else if ($scope.inputsearchObject.sectorSearchKey != undefined && $scope.inputsearchObject.sector != undefined && $scope.inputsearchObject.sectorSearchKey == vm.sector1 && $scope.inputsearchObject.sector == vm.sector) {
            vm.sectorchange = $scope.inputsearchObject.sector;
          } else if ($scope.inputsearchObject.sectorSearchKey != undefined && $scope.inputsearchObject.sector != undefined && $scope.inputsearchObject.sectorSearchKey == vm.sector1) {
            vm.sectorchange = $scope.inputsearchObject.sectorSearchKey;
          } else if ($scope.inputsearchObject.sectorSearchKey != vm.sector1) {
            vm.sectorchange = $scope.inputsearchObject.sector;
          }

        }
      }
        if (vm.sectorchange == "") {
          vm.sectorchange = null;
        }
        if ($scope.modelObject.sectorObj && $scope.modelObject.sectorObj.sectorId != "" && (!$scope.inputsearchObject.isAddOrSaveLocationSector || $scope.currentStatevalid != "neighbourhoodCanvas")) {
          $scope.modelObject.sectorObj.sectorTxt = vm.sectorchange;
          $scope.inputsearchObject.sector = vm.sectorchange;
          $scope.inputsearchObject.sectorSearchKey = vm.sectorchange;
        } else if ($scope.modelObject.sectorObj && $scope.modelObject.sectorObj.sectorTxt == $scope.inputsearchObject.sector) {
          $scope.modelObject.sectorObj.sectorId != undefined;
          $scope.inputsearchObject.sector = $scope.modelObject.sectorObj.sectorTxt;
        } else {
          $scope.inputsearchObject.sector = vm.sectorchange;
          $scope.inputsearchObject.sectorSearchKey = vm.sectorchange;
        }
        vm.sector = angular.copy($scope.inputsearchObject.sector);
        vm.sector1 = angular.copy($scope.inputsearchObject.sectorSearchKey);
        vm.sector2 = angular.copy($scope.modelObject.sectorObj);
      }
    };

    //Clear new Country Location input container fields
    vm.clearNewCountryFields = function () {
      $scope.inputsearchObject.isHideCountry = false;
      if ($scope.relpaceinputfieldObject != undefined) {
        $scope.relpaceinputfieldObject.country = false;
        $scope.relpaceinputfieldObject.countryKey = undefined;
      }
    };

    //Clear new Province Location input container fields
    vm.clearNewProvinceFields = function () {
      $scope.inputsearchObject.isHideProvince = false;
      if ($scope.relpaceinputfieldObject != undefined) {
        $scope.relpaceinputfieldObject.province = false;
        $scope.relpaceinputfieldObject.provinceKey = undefined;
      }
    };

    //Clear new City Location input container fields
    vm.clearNewCityFields = function () {
      $scope.inputsearchObject.isHideCity = false;
      if ($scope.relpaceinputfieldObject != undefined) {
        $scope.relpaceinputfieldObject.city = false;
        $scope.relpaceinputfieldObject.cityKey = undefined;
      }
    };

    //Clear new Sector Location input container fields
    vm.clearNewSectorFields = function () {
      $scope.inputsearchObject.isHideSector = false;
      if ($scope.relpaceinputfieldObject != undefined) {
        $scope.relpaceinputfieldObject.sector = false;
        $scope.relpaceinputfieldObject.sectorKey = undefined;
      }
    };

    angular.isUndefinedOrNull = function (val) {
      return angular.isUndefined(val) || val == "" || val == null;
    }

    $scope.existingSearchLocation = function (type) {
      if ($scope.modelObject) {
        if (type == 'COUNTRY') {
          if ($scope.modelObject.provinceObj && $scope.modelObject.provinceObj.provinceTxt) {
            $scope.inputsearchObject.provinceSearchKey = $scope.modelObject.provinceObj.provinceTxt;
            $scope.inputsearchObject.province = $scope.modelObject.provinceObj.provinceTxt;
          }
        }
        if (type == 'COUNTRY' || type == 'PROVINCE') {
          if ($scope.modelObject.cityObj && $scope.modelObject.cityObj.cityTxt) {
            $scope.inputsearchObject.citySearchKey = $scope.modelObject.cityObj.cityTxt;
            $scope.inputsearchObject.city = $scope.modelObject.cityObj.cityTxt;
          }
        }
        if ($scope.modelObject.sectorObj && $scope.modelObject.sectorObj.sectorTxt) {
          $scope.inputsearchObject.sectorSearchKey = $scope.modelObject.sectorObj.sectorTxt;
          $scope.inputsearchObject.sector = $scope.modelObject.sectorObj.sectorTxt;
        }
      }
    }


    $scope.locationAltered = function () {
      if ($scope.inputsearchObject) {
        if ($scope.inputsearchObject.countryAltered) {
          vm.addnewlocationObject.country = true;
        }
        if ($scope.inputsearchObject.provinceAltered) {
          vm.addnewlocationObject.province = true;
        }
        if ($scope.inputsearchObject.cityAltered) {
          vm.addnewlocationObject.city = true;
        }
        if ($scope.inputsearchObject.sectorAltered) {
          vm.addnewlocationObject.sector = true;
        }
      }
    }

    $scope.validateLocationId = function (type) {

      if ($scope.modelObject) {
        if (type == "COUNTRY") {
          if ($scope.modelObject.provinceObj && $scope.modelObject.provinceObj.provinceId) {
            $scope.modelObject.provinceObj.provinceId = null;
          }
        }
        if (type == "COUNTRY" || type == "PROVINCE") {
          if ($scope.modelObject.cityObj && $scope.modelObject.cityObj.cityId) {
            $scope.modelObject.cityObj.cityId = null;
          }
        }
        if (type == "COUNTRY" || type == "PROVINCE" || type == "CITY") {
          if ($scope.modelObject.sectorObj && $scope.modelObject.sectorObj.sectorId) {
            $scope.modelObject.sectorObj.sectorId = null;
          }
        }
      }
    }

    $rootScope.$on('cancelFormData', function () {
      if ($scope.modelObject) {
        $scope.modelObject = {};
      }
      if ($scope.inputsearchObject) {
        $scope.inputsearchObject = {};
      }
    });

    // Validate the location mapping on change of every location field
    $scope.validateLocationMapping = function () {
      $scope.locationMappingObject = {};
      $scope.locationMappingObject.countryTxt = $scope.inputsearchObject.country;
      $scope.locationMappingObject.provinceTxt = $scope.inputsearchObject.province;
      $scope.locationMappingObject.cityTxt = $scope.inputsearchObject.city;
      $scope.locationMappingObject.sectorTxt = $scope.inputsearchObject.sector;
      $scope.valid = false;
      var data = LibraryLocationService.validateLocationMapping($scope.locationMappingObject);
      data.then(function (response) {
        $scope.populatePreFillDetails(response, true);

      });

    };

    $rootScope.triggerPopulatePreFillDetails = function (countryObj, provinceObj, cityObj, sectorObj) {
      // Set Country Details
      $scope.modelObject.countryObj = angular.copy(countryObj);
      $scope.inputsearchObject.country = $scope.modelObject.countryObj.countryTxt;
      $scope.inputsearchObject.countrySearchKey = $scope.modelObject.countryObj.countryTxt;

      // Set Province Details
      $scope.modelObject.provinceObj = angular.copy(provinceObj);
      $scope.inputsearchObject.province = $scope.modelObject.provinceObj.provinceTxt;
      $scope.inputsearchObject.provinceSearchKey = $scope.modelObject.provinceObj.provinceTxt;

      // Set City Details
      $scope.modelObject.cityObj = angular.copy(cityObj);
      $scope.inputsearchObject.city = $scope.modelObject.cityObj.cityTxt;
      $scope.inputsearchObject.citySearchKey = $scope.modelObject.cityObj.cityTxt;

      // Set Sector Details
      $scope.modelObject.sectorObj = angular.copy(sectorObj);
      $scope.inputsearchObject.sector = $scope.modelObject.sectorObj.sectorTxt;
      $scope.inputsearchObject.sectorSearchKey = $scope.modelObject.sectorObj.sectorTxt;
    };

  }
})();
