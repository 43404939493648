(function() {
	'use strict';

    angular
        .module('lariAidApp').directive('formSideComment',formSideComment);
    
    function formSideComment(){
			return {
			    templateUrl: 'app/investigation/prospects/profile/investigation-form/sideComment-directive/side.commentdirective.html',
				controller:'SideCommentsDirectiveController',
			    transclude:true,
				replace: true,
			    restrict: 'E',
				scope:{
					formName:'=formName',
					investigationId:"=investigationId",
					disableFlag: '=disableFlag',
					modelObject: '@',
					questionName: '=questionName',
					objectList: '=objectList',
					accordionIndex: '=accordionIndex'
				},
			    link: function(scope, element, attrs) {
			    }
		    }
			
		}
    
})();