(function () {
  "use strict";

  angular.module("lariAidApp").service("ProspectProfileService", ProspectProfileService);

  ProspectProfileService.$inject = ["$resource", "restService", "$q", "$rootScope"];

  function ProspectProfileService($resource, restService, $q, $rootScope) {
    this.getProfileData = function (investigationId) {
      var deferred = $q.defer();
      var url = "investigations/" + investigationId + "/prospect/profileData";
      var data = restService.restCall("", url, "GET");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    this.getProfileInvestigationData = function (investigationId) {
      var deferred = $q.defer();
      var url = "investigations/" + investigationId + "/prospect/investigationData";
      var data = restService.restCall("", url, "GET");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };

    this.getEbisDocuments = function (investigationId) {
      var deferred = $q.defer();
      var url = "investigation/ebisDocuments/" + investigationId;
      var data = restService.restCall("", url, "GETLIST");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    this.getRoleList = function () {
      var deferred = $q.defer();
      var url = "userRoles";
      var data = restService.restCall("", url, "GETLIST");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };
    this.getUserList = function (userFilterObj) {
      var deferred = $q.defer();
      var url = "users/assign";
      var data = restService.restCall(userFilterObj, url, "POST");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };
    this.saveAssignee = function (assignerObj) {
      var deferred = $q.defer();
      var url = "investigation-form/assign-user";
      var data = restService.restCall(assignerObj, url, "POST");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };
    this.saveReAssignee = function (assignerObj) {
      var deferred = $q.defer();
      var url = "investigation-form/reassign-user";
      var data = restService.restCall(assignerObj, url, "POST");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };
    //Method to Delete Investigation Form
    this.deleteAssignee = function (deleteFormObj) {
      var deferred = $q.defer();
      var url = "investigation-form/delete-user";
      var data = restService.restCall(deleteFormObj, url, "POST");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };
    /**
     * Method to Get Documents
     */
    this.getDocuments = function () {
      var deferred = $q.defer();
      var url = "TODO";
      var data = restService.restCall("", url, "GET");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    //Method to Reopen Analyst (or) Investigation Form
    this.reopenAnalyst = function (reopenFormObj) {
      var deferred = $q.defer();
      var url = "investigation-form/reopen-form";
      var data = restService.restCall(reopenFormObj, url, "POST");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    //Method to Update status for RC
    this.sentToRC = function (investigationToStatus, investigationId) {
      var deferred = $q.defer();
      var url = "investigation-statuses/update/" + investigationId;
      if (investigationToStatus != null) {
        url = url + "?toStatuKey=" + investigationToStatus;
      }
      var data = restService.restCall("", url, "POST");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    //Method for Sent To eBis
    this.sentToeBis = function (eBisObj) {
      var deferred = $q.defer();
      var url = "ebis/send";
      var data = restService.restCall(eBisObj, url, "POST");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };

    //Method to update the Investigation status
    this.updateInvestigationStatus = function (assignObj) {
      var deferred = $q.defer();
      var url = "investigation-form/update-investigation-status";
      var data = restService.restCall(assignObj, url, "POST");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    //Method to Create RC Session.
    this.createRCSession = function (createRCObj) {
      var deferred = $q.defer();
      var url = "investigation-rcs/" + createRCObj.investigationId;
      var data = restService.restCall(createRCObj, url, "POST");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    //Method to Delete RC Session.
    this.deleteRCSession = function (investigationId, investigationRCId) {
      var deferred = $q.defer();
      var url = "investigation-rcs/" + investigationId + "/" + investigationRCId;
      var data = restService.restCall("", url, "DELETE");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    //Method to bypass the investigation form
    this.byPassOpenInvestigationForm = function (assignerObj) {
      var deferred = $q.defer();
      var url = "investigation-form/bypass";
      var data = restService.restCall(assignerObj, url, "PUT");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    //Method to get the form active status
    this.getActiveInvestigationForm = function (formId) {
      var deferred = $q.defer();
      var url = "investigation-form/" + formId + "/active";
      var data = restService.restCall("", url, "GET");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };

    //Method to bypass the investigation form
    this.completeNonDRInvestigation = function (assignerObj) {
      var deferred = $q.defer();
      var url = "investigation-form/update-investigations-status";
      var data = restService.restCall(assignerObj, url, "POST");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };
    this.reopenForMangerReview = function (investigationId) {
      var deferred = $q.defer();
      var url = "reopen-form-manager-review/" + investigationId;
      var data = restService.restCall("", url, "POST");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };
  }
})();
