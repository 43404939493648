(function(){
	'use strict'; 

angular
  .module('lariAidApp')
  .service('StorageDelete', ['$sessionStorage', function ($sessionStorage) {
	  	//Clearing filters value
	  
	    this.deleteInvestigation = function(index) {
	    	//investigation
            delete $sessionStorage.subFilters;
            delete $sessionStorage.selectedItemList;
            delete $sessionStorage.prevFilterList;
            delete $sessionStorage.prevClosedList;  
            delete $sessionStorage.sortBy;
            delete $sessionStorage.myInvestigation;
            delete $sessionStorage.formType;
            delete $sessionStorage.endlessScrollRetain;
            delete $sessionStorage.isRetainedCloseFilter;
	    };

	    this.deleteUser = function() {
	    	 //user
            delete $sessionStorage.selectedUserRoleList;
            delete $sessionStorage.userFilter;
            delete $sessionStorage.selectedCasesList;
            delete $sessionStorage.userEndlessScrollRetain;
            delete $sessionStorage.subUserFilters;
            delete $sessionStorage.userManagementActiveState;
	    };
	    
	  //library
	    this.deleteLibrary  = function(){
	    	
	    	//Entity
	    	delete $sessionStorage.selectedEntityType;
	    	delete $sessionStorage.libraryEnityLocationObject;
	    	delete $sessionStorage.entitySortBy;
	    	
	    	//Request
	    	delete $sessionStorage.selectedRequestList;
	    	delete $sessionStorage.requestSortBy;
	    	delete $sessionStorage.formRequest;
	    	
	    	// location
	    	delete $sessionStorage.locationSortBy;
	    	delete $sessionStorage.locFilter;
	    	
	    };
        
        //session
        this.deleteSessionValue = function() {
             //session timeout handling
            delete $sessionStorage.isLoggedIn;
            delete $sessionStorage.setSessionData;
            delete $sessionStorage.currentEnvironment;
        };


	
	}]
  );
})();