(function() {
    'use strict';

    var lariDevApp = angular
        .module('lariAidApp')
        lariDevApp.controller('ProspectsController', ProspectsController);
    ProspectsController.$inject = ['$scope','$window', '$mdSidenav','Principal','$state','$stateParams','$timeout','tabservice','$location','$sessionStorage','CommonService','APP'];
    function ProspectsController ($scope,$window, $mdSidenav,Principal,$state,$stateParams,$timeout,tabservice,$location,$sessionStorage,CommonService,APP) {
    	var vm=this;
    	vm.back = back;
    	vm.investigation = $stateParams;
    	vm.APP = APP;
    	//Get logged User Role
    	vm.user = CommonService.getCookieUser();
 		vm.loggedUserRole = vm.user.loggedUserRole;
    	
    	$scope.updateProspectName = function(firstName,lastName){
    		vm.prospectName = firstName + " "+lastName;
    	};
    	
    	// Set the active md tab while refresh the page
    	
    	vm.checkIndex = function(){
    		vm.tabIndex = tabservice.getIndex();
    	};
    	
    	vm.checkIndex();
        /*Investigation Form's Section sidenav  Starts Here*/
    	$scope.sectionLeft = buildToggler('formLeft');
        $scope.toggleRight = buildToggler('formRight');

        function buildToggler(componentId) {
          return function() {
            $mdSidenav(componentId).toggle();
          };
        }
        /*Investigation Form's Section sidenav Ends Here */
        
    	$scope.WindowPopupHeight=window.innerHeight;
    	function back(){
    		delete $sessionStorage.isView;
    		$state.go("investigations");
    	}
    	$scope.stateProfileDetails = function(){
     		$state.go("profile",vm.investigation); 
     	};
     	
     	$scope.stateActivity = function(){
     		$state.go("activity",vm.investigation);
     	};
     	
     	/*vm.checkState = function(){
     		vm.currentState =  $state.current.name;
     	};*/
     	
     	
     	 vm.toggleLeft = toggleLeft('right');
	     	
    	  function toggleLeft(navID) {
    	      return function() {
    	        $mdSidenav(navID).toggle()
    	      };
    	  }
    }
})();
