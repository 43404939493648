(function() {
    'use strict';

    angular
        .module('lariAidApp')
        .service('RCDetailsService', RCDetailsService);

    RCDetailsService.$inject = ['$resource','restService','$q','$rootScope'];
    
    function RCDetailsService ($resource,restService,$q,$rootScope) {
    	
    	//Method to Get RC Session Data.
    	this.getRCSessionData= function(investigationId,investigationRCId){
    		var deferred = $q.defer();
    		var url = "investigation-rcs/"+investigationId+"/"+investigationRCId;
    		var data = restService.restCall("", url, 'GET');
    		data.$promise.then(function(response){
    			deferred.resolve(response);
    		},
    		function (error) {
    			deferred.reject('ERROR');
    		});
    		return deferred.promise;
    	};
    	
    	//Method to save RC Session Data.
    	this.saveRCSessionData= function(RCMeetingData){
    		var deferred = $q.defer();
    		var url = "investigation-rcs/"+RCMeetingData.investigationId+"/"+RCMeetingData.id;
    		var data = restService.restCall(RCMeetingData, url, 'PUT');
    		data.$promise.then(function(response){
    			deferred.resolve(response);
    		},
    		function (error) {
    			deferred.reject('ERROR');
    		});
    		return deferred.promise;
    	};
    }
})();