(function() {
    'use strict';

    angular
        .module('lariAidApp')
        .factory('LoginService', LoginService);

    LoginService.$inject = [];

    function LoginService () {
        var service = {
            open: open
        };

        var modalInstance = null;
        var resetModal = function () {
            modalInstance = null;
        };

        return service;

        function open () {
            if (modalInstance !== null) return;
            modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/components/login/login.html',
                controller: 'LoginController',
                controllerAs: 'vm',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        if(localStorage && localStorage.getItem('lari_currentVersion') != null){
                           	$translatePartialLoader.addPart('login.json?version='+localStorage.getItem('lari_currentVersion'));
                           }else{
                           	$translatePartialLoader.addPart('login.json?timestamp='+new Date().getTime());
                           }
                        return $translate.refresh();
                    }]
                }
            });
            modalInstance.result.then(
                resetModal,
                resetModal
            );
        }
    }
})();
