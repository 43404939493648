(function() {
    'use strict';

    angular
        .module('lariAidApp')
        .service('SummaryService', SummaryService);

    SummaryService.$inject = ['$resource','restService','$q','$rootScope'];
    
    function SummaryService ($resource,restService,$q,$rootScope) {
    	
    	
    		
    }
})();