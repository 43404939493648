(function() {
	'use strict';

	angular.module('lariAidApp').directive('onlyDigits', OnlyDigits);
	//resize.inject['$window'];
	
	function OnlyDigits() {
		return {
	        restrict: 'A',
    		require: 'ngModel',
    	    link: function (scope, element, attrs, modelCtrl) {
    	    	modelCtrl.$parsers.push(function(inputValue) {
	            	var allowedChar = '';
	            	if(attrs.allowedChar){
	            		allowedChar = attrs.allowedChar;
	            	}
	            	/*var expr = "[^0-9"+allowedChar+"]";*/
	            	var expr = allowedChar;
	            	 var myRegExp = new RegExp(expr,'gi');
	            	 
	              if (inputValue == undefined)
	                return ''
	              scope.cleanInputValue = inputValue.replace(myRegExp, '');
	              if (scope.cleanInputValue != inputValue) {
	                modelCtrl.$setViewValue(scope.cleanInputValue);
	                modelCtrl.$render();
	              }
	              return scope.cleanInputValue;
    	    	});
	    	}
    	};
		}
	
})();