(function() {
	'use strict';

    angular
        .module('lariAidApp')
        .controller('LibraryLocationDirectiveController', LibraryLocationDirectiveController);
    
    LibraryLocationDirectiveController.$inject = ['$rootScope','$scope','$state','$stateParams','$mdSidenav','$element','APP','tabservice','$mdMedia','LibraryLocationService','$q','$timeout','$sessionStorage','communicationService'];
    
    function LibraryLocationDirectiveController($rootScope,$scope,$state,$stateParams,$mdSidenav,$element,APP,tabservice,$mdMedia,LibraryLocationService,$q,$timeout,$sessionStorage,communicationService){
    	
    	var vm = this;
    	vm.APP = APP;
    	vm.library = $stateParams;
    	vm.media = $mdMedia;
        vm.locationList = [];
  	    vm.loadingData = false;
	    $scope.searchLocationCountryList = [];
	    $scope.searchLocationProvinceList = [];
	    $scope.searchLocationCityList = [];
	    $scope.searchLocationSectorList = [];
	    $scope.locLoader = false;
	    $scope.modelObject = {};
	    
	    vm.currentState = $state.current.name;
	    
	    $element.find('input').on('keydown', function(ev) {
            ev.stopPropagation();
        });
	    
	    $scope.checkRequiredFields = function(modelName,fieldId){
	    	var result = false;
	    	if($scope.modelObject[modelName] && $scope.modelObject[modelName][fieldId]){
       		 	result = true;
   		 	}
    		 return result;
	    };
	    
	    $scope.searchLocation = function(searchText,type, delayCall){
			  if(searchText && searchText.length >= 2){
				  $scope.inputsearchObject.isInputEmptyCountry = false;
				  var deferred = $q.defer();
				  $scope.libraryLocObj = {};
				  $scope.libraryLocObj.searchKey = searchText;
				  if($scope.modelObject) {
					  if($scope.modelObject.countryObj && $scope.modelObject.countryObj.countryId != null) {
						  $scope.libraryLocObj.countryId = $scope.modelObject.countryObj.countryId;
	  				  }
	  				  if($scope.modelObject.provinceObj && $scope.modelObject.provinceObj.provinceId != null ) {
	  					$scope.libraryLocObj.provinceId = $scope.modelObject.provinceObj.provinceId;
	  				  }
	  				  if($scope.modelObject.cityObj && $scope.modelObject.cityObj.cityId != null) {
	  					$scope.libraryLocObj.cityId = $scope.modelObject.cityObj.cityId;
	  				  }
	  				  if($scope.modelObject.sectorObj && $scope.modelObject.sectorObj.sectorId != null) {
	  					$scope.libraryLocObj.sectorId = $scope.modelObject.sectorObj.sectorId;
	  				  }
				  }
				  if(communicationService.getData('isAddOrSaveLocation')) {
					  if(type == 'COUNTRY') {
						  if($scope.inputsearchObject.country) {
							  $scope.locLoader = true;
							  var data = LibraryLocationService.loadAllLocationsByType($scope.libraryLocObj,type);
							  data.then(function(response){
								  $scope.locLoader = false;
								  if(response.autoSuggestionLocationDTOList.length > 0){
									  if(type == 'COUNTRY') {
										  $scope.searchLocationCountryList = response.autoSuggestionLocationDTOList;
										  if(communicationService.getData('isAddOrSaveLocation')) {
											  	$scope.inputsearchObject.countrySearchKey = '';
								  				$scope.inputsearchObject.isInputEmptyCountry = false;
								  				$scope.inputsearchObject.isInputEmptyProvince = false;
								  		        $scope.inputsearchObject.isInputEmptyCity = false;
								  		       	$scope.inputsearchObject.isInputEmptySector = false;
											  	$scope.inputsearchObject.isAddOrSaveLocationCountry = false;
											  	$scope.inputsearchObject.isAddOrSaveLocationProvince = false;
											  	$scope.inputsearchObject.isAddOrSaveLocationCity = false;
											  	$scope.inputsearchObject.isAddOrSaveLocationSector = false;
								  			}
									  } else if(type == 'PROVINCE') {
										  $scope.searchLocationProvinceList = response.autoSuggestionLocationDTOList;
									  } else if(type == 'CITY') {
										  $scope.searchLocationCityList = response.autoSuggestionLocationDTOList;
									  } else if(type == 'SECTOR') {
										  $scope.searchLocationSectorList = response.autoSuggestionLocationDTOList;
									  }
								  } else {
									  if(type == 'COUNTRY') {
										  $scope.searchLocationCountryList = response.autoSuggestionLocationDTOList;
									  } else if(type == 'PROVINCE') {
										  $scope.searchLocationProvinceList = response.autoSuggestionLocationDTOList;
									  } else if(type == 'CITY') {
										  $scope.searchLocationCityList = response.autoSuggestionLocationDTOList;
									  } else if(type == 'SECTOR') {
										  $scope.searchLocationSectorList = response.autoSuggestionLocationDTOList;
									  }
									  $scope.loadNewInputFields(type,searchText);
								  }
								  if(type == 'COUNTRY') {
									  deferred.resolve($scope.searchLocationCountryList);
								  } else if(type == 'PROVINCE') {
									  deferred.resolve($scope.searchLocationProvinceList);
								  } else if(type == 'CITY') {
									  deferred.resolve($scope.searchLocationCityList);
								  } else if(type == 'SECTOR') {
									  deferred.resolve($scope.searchLocationSectorList);
								  }
							  });
							  return deferred.promise;
						  } else {
							  $scope.loadNewInputFields(type,searchText);
						  }
					  } else {
						  $scope.getListCall(type,searchText);
					  }
				  } else {
					  $scope.getListCall(type,searchText,delayCall);
				  }
			  }
	  };
	  
	  $scope.loadNewInputFields = function(type,searchText) {
		  if(type == 'COUNTRY') {
			  $scope.inputsearchObject.countrySearchKey = searchText;
			  $scope.inputsearchObject.isAddOrSaveLocationCountry = true;
			  $scope.inputsearchObject.isAddOrSaveLocationProvince = true;
			  $scope.inputsearchObject.isAddOrSaveLocationCity = true;
			  $scope.inputsearchObject.isAddOrSaveLocationSector = true;
		  }  else if(type == 'PROVINCE') {
			  $scope.inputsearchObject.provinceSearchKey = searchText;
			  $scope.inputsearchObject.isAddOrSaveLocationProvince = true;
			  $scope.inputsearchObject.isAddOrSaveLocationCity = true;
			  $scope.inputsearchObject.isAddOrSaveLocationSector = true;
		  } else if(type == 'CITY') {
			  $scope.inputsearchObject.citySearchKey = searchText;
			  $scope.inputsearchObject.isAddOrSaveLocationCity = true;
			  $scope.inputsearchObject.isAddOrSaveLocationSector = true;
		  } else if(type == 'SECTOR') {
			  $scope.inputsearchObject.sectorSearchKey = searchText;
			  $scope.inputsearchObject.isAddOrSaveLocationSector = true;
		  } 
	  }
	  
	  $scope.getListCall = function(type,searchText,delayCall) {
		  var deferred = $q.defer();
		  $scope.locLoader = true;
		  var data = LibraryLocationService.loadAllLocationsByType($scope.libraryLocObj,type);
		  data.then(function(response){
			  $scope.locLoader = false;
			  if(response.autoSuggestionLocationDTOList.length > 0){
				  if(type == 'COUNTRY') {
					  $scope.searchLocationCountryList = response.autoSuggestionLocationDTOList;
					  if(delayCall) {
						  $scope.populatePreFillDetails($scope.searchLocationCountryList[0]);
					  }
				  } else if(type == 'PROVINCE') {
					  $scope.searchLocationProvinceList = response.autoSuggestionLocationDTOList;
					  if(communicationService.getData('isAddOrSaveLocation')) {
			  				$scope.inputsearchObject.isInputEmptyCountry = false;
			  		       	$scope.inputsearchObject.isInputEmptyProvince = false;
			  		       	$scope.inputsearchObject.provinceSearchKey = '';
			  		       	$scope.inputsearchObject.isAddOrSaveLocationCountry = false;
						  	$scope.inputsearchObject.isAddOrSaveLocationProvince = false;
						  	$scope.inputsearchObject.isAddOrSaveLocationCity = false;
						  	$scope.inputsearchObject.isAddOrSaveLocationSector = false;
			  			}
					  if(delayCall) {
						  $scope.populatePreFillDetails($scope.searchLocationProvinceList[0]);
					  }
				  } else if(type == 'CITY') {
					  $scope.searchLocationCityList = response.autoSuggestionLocationDTOList;
					  if(communicationService.getData('isAddOrSaveLocation')) {
			  				$scope.inputsearchObject.isInputEmptyCountry = false;
			  		       	$scope.inputsearchObject.isInputEmptyProvince = false;
			  		       	$scope.inputsearchObject.isInputEmptyCity = false;
			  		       	$scope.inputsearchObject.citySearchKey = '';
			  		       	$scope.inputsearchObject.isAddOrSaveLocationCountry = false;
						  	$scope.inputsearchObject.isAddOrSaveLocationProvince = false;
						  	$scope.inputsearchObject.isAddOrSaveLocationCity = false;
						  	$scope.inputsearchObject.isAddOrSaveLocationSector = false;
			  		  }
					  if(delayCall) {
						  $scope.populatePreFillDetails($scope.searchLocationCityList[0]);
					  }
				  } else if(type == 'SECTOR') {
					  $scope.searchLocationSectorList = response.autoSuggestionLocationDTOList;
					  if(communicationService.getData('isAddOrSaveLocation')) {
			  				$scope.inputsearchObject.isInputEmptyCountry = false;
			  		       	$scope.inputsearchObject.isInputEmptyProvince = false;
			  		        $scope.inputsearchObject.isInputEmptyCity = false;
			  		       	$scope.inputsearchObject.isInputEmptySector = false;
				  		  	$scope.inputsearchObject.sectorSearchKey = '';
				  		  	$scope.inputsearchObject.isAddOrSaveLocationCountry = false;
						  	$scope.inputsearchObject.isAddOrSaveLocationProvince = false;
						  	$scope.inputsearchObject.isAddOrSaveLocationCity = false;
						  	$scope.inputsearchObject.isAddOrSaveLocationSector = false;
			  		  }
				  }
			  } else {
				  if(type == 'COUNTRY') {
					  $scope.searchLocationCountryList = response.autoSuggestionLocationDTOList;
				  } else if(type == 'PROVINCE') {
					  $scope.searchLocationProvinceList = response.autoSuggestionLocationDTOList;
				  } else if(type == 'CITY') {
					  $scope.searchLocationCityList = response.autoSuggestionLocationDTOList;
				  } else if(type == 'SECTOR') {
					  $scope.searchLocationSectorList = response.autoSuggestionLocationDTOList;
				  }
				  $scope.loadNewInputFields(type,searchText);
			  }
			  if(type == 'COUNTRY') {
				  deferred.resolve($scope.searchLocationCountryList);
			  } else if(type == 'PROVINCE') {
				  deferred.resolve($scope.searchLocationProvinceList);
			  } else if(type == 'CITY') {
				  deferred.resolve($scope.searchLocationCityList);
			  } else if(type == 'SECTOR') {
				  deferred.resolve($scope.searchLocationSectorList);
			  }
			  
		  });
		  return deferred.promise;
	  }
	    
	  $scope.clear = function(type){
  		if(type == 'COUNTRY') {
  			$scope.modelObject.countryObj = {};
  			if($scope.modelObject.provinceObj && $scope.modelObject.provinceObj.provinceId) {
  				$scope.modelObject.provinceObj = {};
  			}
  			if($scope.modelObject.cityObj && $scope.modelObject.cityObj.cityId) {
  				$scope.modelObject.cityObj = {};
  			}
  			if($scope.modelObject.sectorObj && $scope.modelObject.sectorObj.sectorId) {
  				$scope.modelObject.sectorObj = {};
  			}
  			if(communicationService.getData('isAddOrSaveLocation')) {
  				$scope.inputsearchObject.isInputEmptyCountry = false;
  		       	$scope.inputsearchObject.isInputEmptyProvince = false;
  		       	$scope.inputsearchObject.isInputEmptyCity = false;
  		       	$scope.inputsearchObject.isInputEmptySector = false;
  		        $scope.inputsearchObject.countrySearchKey = '';
  		        $scope.inputsearchObject.provinceSearchKey = '';
  		        $scope.inputsearchObject.citySearchKey = '';
  		        $scope.inputsearchObject.sectorSearchKey = '';
			    $scope.inputsearchObject.isAddOrSaveLocationCountry = false;
			    $scope.inputsearchObject.isAddOrSaveLocationProvince = false;
			    $scope.inputsearchObject.isAddOrSaveLocationCity = false;
			    $scope.inputsearchObject.isAddOrSaveLocationSector = false;
  			}
  			vm.clearNewCountryFields();
  			vm.clearNewProvinceFields();
  			vm.clearNewCityFields();
  			vm.clearNewSectorFields();
  		} else if(type == 'PROVINCE') {
  			$scope.modelObject.provinceObj = {};
  			if($scope.modelObject.cityObj && $scope.modelObject.cityObj.cityId) {
  				$scope.modelObject.cityObj = {};
  			}
  			if($scope.modelObject.sectorObj && $scope.modelObject.sectorObj.sectorId) {
  				$scope.modelObject.sectorObj = {};
  			}
  			if(communicationService.getData('isAddOrSaveLocation')) {
  		       	$scope.inputsearchObject.isInputEmptyProvince = false;
  		       	$scope.inputsearchObject.isInputEmptyCity = false;
  		       	$scope.inputsearchObject.isInputEmptySector = false;
		        $scope.inputsearchObject.provinceSearchKey = '';
		        $scope.inputsearchObject.citySearchKey = '';
		        $scope.inputsearchObject.sectorSearchKey = '';
			    $scope.inputsearchObject.isAddOrSaveLocationProvince = false;
			    $scope.inputsearchObject.isAddOrSaveLocationCity = false;
			    $scope.inputsearchObject.isAddOrSaveLocationSector = false;
  			}
  			if(vm.currentState == vm.APP.LIBRARY.ENTITY) {
  				$scope.searchLocation($scope.modelObject.countryObj.countryTxt,'COUNTRY', true);
  			}
  			vm.clearNewProvinceFields();
  			vm.clearNewCityFields();
  			vm.clearNewSectorFields();
  		}else if(type == 'CITY') {
			$scope.modelObject.cityObj = {};
  			if($scope.modelObject.sectorObj && $scope.modelObject.sectorObj.sectorId) {
  				$scope.modelObject.sectorObj = {};
  			}
  			if(communicationService.getData('isAddOrSaveLocation')) {
  		       	$scope.inputsearchObject.isInputEmptyCity = false;
  		       	$scope.inputsearchObject.isInputEmptySector = false;
  		       	$scope.inputsearchObject.citySearchKey = '';
		        $scope.inputsearchObject.sectorSearchKey = '';
			    $scope.inputsearchObject.isAddOrSaveLocationCity = false;
			    $scope.inputsearchObject.isAddOrSaveLocationSector = false;
  			}
  			if(vm.currentState == vm.APP.LIBRARY.ENTITY) {
  				$scope.searchLocation($scope.modelObject.provinceObj.provinceTxt,'PROVINCE', true);
  			}
  			vm.clearNewCityFields();
  			vm.clearNewSectorFields();
  		} else if(type == 'SECTOR') {
			$scope.modelObject.sectorObj = {};
			if(communicationService.getData('isAddOrSaveLocation')) {
  		       	$scope.inputsearchObject.isInputEmptySector = false;
		        $scope.inputsearchObject.sectorSearchKey = '';
			    $scope.inputsearchObject.isAddOrSaveLocationSector = false;
  			}
			if(vm.currentState == vm.APP.LIBRARY.ENTITY) {
				$scope.searchLocation($scope.modelObject.cityObj.cityTxt,'CITY', true);
			}
			vm.clearNewSectorFields();
  		}
  	  }
  	
	  $scope.populatePreFillDetails = function(locationObj) {
  		  if(locationObj) {
  			$scope.copyLocationObj = angular.copy(locationObj);
			  if($scope.copyLocationObj.countryId != null ) {
				  $scope.modelObject.countryObj = $scope.copyLocationObj;
			  }
			  if($scope.copyLocationObj.provinceId != null) {
				$scope.modelObject.provinceObj = $scope.copyLocationObj;
			  }
			  if($scope.copyLocationObj.cityId != null) {
				$scope.modelObject.cityObj = $scope.copyLocationObj;
			  }
			  if($scope.copyLocationObj.sectorId != null) {
				$scope.modelObject.sectorObj = $scope.copyLocationObj;
			  }
		  }
  	  }
	  
	  $scope.clearName = function(name,searchKey,dropValue){
		  if(!communicationService.getData('isAddOrSaveLocation')) {
			  $scope.inputsearchObject[searchKey] = undefined;
			  $scope[dropValue] = undefined;
			  $scope.inputsearchObject.countrySearchKey = '';
	          $scope.inputsearchObject.provinceSearchKey = '';
	          $scope.inputsearchObject.citySearchKey = '';
	          $scope.inputsearchObject.sectorSearchKey = '';
	          $scope.inputsearchObject.isAddOrSaveLocationCountry = false;
		      $scope.inputsearchObject.isAddOrSaveLocationProvince = false;
		      $scope.inputsearchObject.isAddOrSaveLocationCity = false;
		      $scope.inputsearchObject.isAddOrSaveLocationSector = false;
		  } else {
			  $scope.inputsearchObject[searchKey] = undefined;
			  $scope[dropValue] = undefined;
		  }
  	  }
	  
	  $scope.replaceInputField = function(){
		  var requestType;
		  	if(vm.locationRequestType != null){
		  		requestType=vm.locationRequestType.name;
		  	}
			 if(requestType == vm.APP.LIBRARY.COUNTRY){
				  if(vm.country != null || vm.location.country != null){
					  vm.isEnableSave=true;
				  }
				  if(vm.isShowCountry == false && vm.addLocationObject.countryObj == undefined && vm.country != null){
					  vm.isReplaceCountry = true;
					  vm.requestFlagCountryInput=true;
					  vm.location.country=vm.country;
				  }
			 }
			 if(requestType == vm.APP.LIBRARY.PROVINCE){
				 if(vm.province != null){
				  vm.isEnableSave=true;
				 }
				 if(vm.isShowProvince == false && vm.addLocationObject.countryObj != null && vm.addLocationObject.provinceObj == undefined && vm.province != null){
					  vm.location.province=vm.province;
					  vm.isReplaceProvince = true;
					  vm.requestFlagProvinceInput=true;
					 //vm.isReplaceCountry = false;
				 }
			 }
			 if(requestType == vm.APP.LIBRARY.CITY){
				  if(vm.city != null){
					  vm.isEnableSave=true;
				  }
				  if(vm.isShowCity == false && vm.addLocationObject.countryObj != null && vm.addLocationObject.provinceObj != null && vm.city != null){
					  vm.location.city=vm.city;
					  vm.isReplaceCity = true;
					  vm.requestFlagCityInput=true;
					  //vm.isReplaceCountry = false;
				  }
			 }
			 if(requestType == vm.APP.LIBRARY.SECTOR){
				 if(vm.city != null){
					  vm.isEnableSave=true;
				  }
				  if(vm.isShowSector == false && vm.addLocationObject.countryObj != null && vm.addLocationObject.provinceObj != null && vm.addLocationObject.cityObj != null && vm.sector != null){
					  vm.location.sector=vm.sector;
					  vm.isReplaceSector = true;
					  vm.requestFlagSectorInput=true;
					  //vm.isReplaceCountry = false;
				  }
			 }
		  }
	  
	  //Clear new Country Location input container fields
	  vm.clearNewCountryFields = function(){
		  	$scope.inputsearchObject.isHideCountry = false;
		  	if($scope.relpaceinputfieldObject != undefined) {
		  		$scope.relpaceinputfieldObject.country = false;
				$scope.relpaceinputfieldObject.countryKey = undefined;
		  	}
	  };
	  
	  //Clear new Province Location input container fields
	  vm.clearNewProvinceFields = function() {
		  	$scope.inputsearchObject.isHideProvince = false;
		  	if($scope.relpaceinputfieldObject != undefined) {
		  		$scope.relpaceinputfieldObject.province = false;
				$scope.relpaceinputfieldObject.provinceKey = undefined;
		  	}
	  };
	  
	  //Clear new City Location input container fields
	  vm.clearNewCityFields = function() {
		  	$scope.inputsearchObject.isHideCity = false;
		  	if($scope.relpaceinputfieldObject != undefined) {
		  		$scope.relpaceinputfieldObject.city = false;
				$scope.relpaceinputfieldObject.cityKey = undefined;
		  	}
	  };
	
	  //Clear new Sector Location input container fields
	  vm.clearNewSectorFields = function() {
		  	$scope.inputsearchObject.isHideSector = false;
		  	if($scope.relpaceinputfieldObject != undefined) {
		  		$scope.relpaceinputfieldObject.sector = false;
				$scope.relpaceinputfieldObject.sectorKey = undefined;
		  	}
	  };
  	   	  
    }
})();