(function(){
	'use strict'; 

angular
  .module('lariAidApp')
  .service('tabservice', function () {
	  
	  this.setIndex = function(index) {
	        this.index = index;
	    };

	    this.getIndex = function() {
	        return this.index ? this.index : 0 ;
	    };
	});
})();