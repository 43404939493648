/**
 * 
 */(function() {
    'use strict';

    angular
        .module('lariAidApp')
        .factory('BaseFactory', BaseFactory);

    BaseFactory.$inject = ['$resource','restService','$q','$rootScope'];
    var clubList;
    var languageList;
    function BaseFactory ($resource,restService,$q,$rootScope) {
    	return {
    		getClubLists : function(){
          		var deferred = $q.defer();
          		if(!clubList){
            		var url = "organizations/org";
            		var data = restService.restCall("", url, 'GETLIST');
            		data.$promise.then(function(response){
            			clubList = response;
            			deferred.resolve(response);
            		},
            		function (error) {
            			deferred.reject('ERROR');
            		});
            	}
        		else{
        			deferred.resolve(clubList);
        		}
          		return deferred.promise;
          	},
          	getLanguagesList : function(){
        		var deferred = $q.defer();
          		if(!languageList){
            		var url = "ref-terms/ref-set/language";
            		var data = restService.restCall("", url, 'GETLIST');
            		data.$promise.then(function(response){
            			languageList = response;
            			deferred.resolve(response);
            		},
            		function (error) {
            			deferred.reject('ERROR');
            		});
            	}
        		else{
        			deferred.resolve(languageList);
        		}
          		return deferred.promise;
          	}
    	};
    }
})();
