(function() {
    'use strict';

    angular
        .module('lariAidApp')
        .config(stateConfig);
    stateConfig.$inject = ['$stateProvider','APP'];
    function stateConfig($stateProvider,APP) {
        $stateProvider.state('prospects', {
            parent: 'investigations',
            /*url: '/:id',*/
            data: {
                authorities: APP.GLOBAL.USER_GROUP
            },
            params: {
            	id:null,
            },
            views: {
                'content@': {
                    templateUrl: 'app/investigation/prospects/prospects.html',
                    controller: 'ProspectsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                	if(localStorage && localStorage.getItem('lari_currentVersion') != null){
                       	$translatePartialLoader.addPart('profile.json?version='+localStorage.getItem('lari_currentVersion'));
                       }else{
                       	$translatePartialLoader.addPart('profile.json?timestamp='+new Date().getTime());
                       }
                    return $translate.refresh();
                }]/*,
                mdTabIndex:['tabservice','$timeout', function (tabservice,$timeout) {
                	tabservice.setIndex("1");
                	$timeout(function() {
                	    angular.element('#profile').triggerHandler('click');
                	  },500);
                }]*/
            }
        })
         /*.state('back', {
	        	 parent: 'app',
	             url: '/investigation',
	             data: {
	                 authorities:  ['ROLE_USER']
	             },
	             views: {
	                 'dynamicView@': {
	                     templateUrl: 'app/investigation/investigation.html',
	                     controller: 'InvestigationController',
	                     controllerAs: 'vm'
	                 }
	             },
		        resolve: {
		            mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
		                $translatePartialLoader.addPart('home');
		                return $translate.refresh();
		            }]
		        }
		    });*/
    }
})();
