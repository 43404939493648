(function() {
    'use strict';

    angular
        .module('lariAidApp')
        .service('BirthCertificateService', BirthCertificateService);

    BirthCertificateService.$inject = ['$resource','restService','$q','$rootScope'];
    
    function BirthCertificateService ($resource,restService,$q,$rootScope) {
    	
    	
    }
})();