(function () {
  "use strict";

  var lariDevApp = angular.module("lariAidApp");
  lariDevApp.controller("UserProfileController", UserProfileController);

  UserProfileController.$inject = [
    "$scope",
    "$window",
    "$mdSidenav",
    "Principal",
    "$state",
    "$mdMedia",
    "APP",
    "UserProfileService",
    "$stateParams",
    "UserService",
    "$mdDialog",
    "$element",
    "BaseFactory",
    "CommonService",
    "communicationService"
  ];
  function UserProfileController(
    $scope,
    $window,
    $mdSidenav,
    Principal,
    $state,
    $mdMedia,
    APP,
    UserProfileService,
    $stateParams,
    UserService,
    $mdDialog,
    $element,
    BaseFactory,
    CommonService,
    communicationService
  ) {
    var vm = this;
    $scope.mediaCheck = $mdMedia;
    vm.APP = APP;
    /*vm.user =  $cookies.getObject('sessionDetails');*/
    vm.user = CommonService.getCookieUser().cookieUser;

    vm.userDetails = $stateParams;
    vm.userProfileLoader = true;
    vm.showCloseCases = false;
    vm.showOpenCases = true;
    vm.media = $mdMedia;

    var investigation = {};

    vm.userProfileDetails = function (userId) {
      if (userId) {
        var data = UserProfileService.getUserProfileDetails(userId);
        data.then(
          function (response) {
            vm.userProfileLoader = false;
            vm.profile = response;
            $scope.updateUserName(response.firstName, response.lastName);
            $scope.userActivated(response.activated);
            $scope.userRole(response.userRole);
            vm.getReopenStatus(response);
          },
          function (reason) {
            return reason;
          }
        );
      }
    };
    vm.openClosedCases = function () {
      if (vm.showCloseCases) {
        vm.showCloseCases = false;
      } else {
        vm.showCloseCases = true;
      }
    };
    vm.openOpenedCases = function () {
      if (vm.showOpenCases) {
        vm.showOpenCases = false;
      } else {
        vm.showOpenCases = true;
      }
    };
    if (vm.userDetails != null) {
      vm.userProfileDetails(vm.userDetails.id);
    }

    vm.toggleLeft = toggleLeft("right");

    function toggleLeft(navID) {
      return function () {
        $mdSidenav(navID).toggle();
      };
    }

    vm.prospectProfile = function (id) {
      investigation.id = id;
      $state.go("profile", investigation);
    };

    vm.getReopenStatus = function (profile) {
      var length = profile.investigationFormOpenList.length;
      for (var i = 0; i < length; i++) {
        $scope.getReopenFlag(profile.investigationFormOpenList[i].investigationId, i);
      }
    };

    $scope.getReopenFlag = function (investigationId, index) {
      vm["requestFlag_" + index] = false;
      vm["requestedShow_" + index] = false;
      if (vm.APP.INVESTIGATION.ENABLEREOPENFLAG == vm.APP.GLOBAL.TRUE) {
        vm["reopenLoader_" + index] = true;
        vm["reopenInvestigator_" + index] = false;
        vm["reopenAnalyst_" + index] = false;
        vm["reopenInvestigatorInprogress_" + index] = false;
        vm["reopenAnalystInprogress_" + index] = false;

        var data = UserProfileService.getReopenFlag(investigationId);
        data.then(
          function (response) {
            var reopenFlagDetails = response;
            vm["reopenLoader_" + index] = false;
            vm["reopenInvestigator_" + index] = reopenFlagDetails.investigatorFormReOpen;
            vm["reopenAnalyst_" + index] = reopenFlagDetails.analystFormReOpen;
            vm["reopenInvestigatorInprogress_" + index] = reopenFlagDetails.investigatorFormStatus;
            vm["reopenAnalystInprogress_" + index] = reopenFlagDetails.analystFormStatus;
          },
          function (reason) {
            vm["reopenLoader_" + index] = false;
          }
        );
      }
    };
  }
})();
