
angular.module('lariAidApp').directive('hidePop',['$window', function ($window) {
	
	var headerHeight = 76;
	
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
          element.on('scroll', function (e) {
        	  hidePopup();
          });
          
         element.on('mouseover', function (e) {
        	 hidePopup();
        });
    }
  };
  
  function hidePopup(){
	  if($(".popover").length > 0){
       	 if($(".popover").position().top <= headerHeight ){
       		 $(".popover").css("visibility","hidden");
       	 }
       	 else if($(".popover").position().top > headerHeight){
       		 $(".popover").css("visibility","visible");
       	 }
       }
  }
  
}]);