(function() {
    'use strict';
    
    var lariDevApp = angular
    .module('lariAidApp')
    lariDevApp.directive('textOnly', textOnly);
    
    function textOnly(){
    	return {
    		require: 'ngModel',
    	    link: function (scope, element, attr, ngModelCtrl) {
    	      function fromUser(text) {
    	        /*var transformedInput = text.replace(/[^A-Za-z \u00C0-\u017F]/g, '');
    	        if(transformedInput !== text) {
    	            ngModelCtrl.$setViewValue(transformedInput);
    	            ngModelCtrl.$render();
    	        }
    	        return transformedInput;*/
    	    	return text;
    	      }
    	      ngModelCtrl.$parsers.push(fromUser);
    	    }
    	}
    };
    
})();