(function () {
  "use strict";

  angular.module("lariAidApp").service("InvestigationFormService", InvestigationFormService);

  InvestigationFormService.$inject = ["$resource", "restService", "$q", "$rootScope", "$http", "BASE_URL"];

  function InvestigationFormService($resource, restService, $q, $rootScope, $http, BASE_URL) {
    this.saveInvestigationForm = function (dataObj) {
      var deferred = $q.defer();
      var url = "investigation-form-data/save";
      var data = restService.restCall(dataObj, url, "POST");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };

    this.copyInvestigationFormDetails = function (analystFormId, invFormId, sectionIdList) {
      var deferred = $q.defer();
      var url = "investigation-form-data/copy/" + analystFormId + "/" + invFormId + "?sectionIdList=" + sectionIdList;
      var data = restService.restCall("", url, "POST");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    this.getInvestigationFormBySectionId = function (sectionKey, formId) {
      var deferred = $q.defer();
      var url = "investigation-form-data/get/" + formId + "?sectionKey=" + sectionKey;
      var data = restService.restCall("", url, "GET");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    this.getFormProfileDetails = function (investigationFormId) {
      var deferred = $q.defer();
      var url = "investigation-forms/" + investigationFormId + "/prospect";
      var data = restService.restCall("", url, "GET");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    this.saveInvestigationFormComment = function (dataObj) {
      var deferred = $q.defer();
      var url = "form-comments/save";
      var data = restService.restCall(dataObj, url, "POST");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    this.updateInvestigationFormComment = function (dataObj) {
      var deferred = $q.defer();
      var url = "form-comments/update/";
      var data = restService.restCall(dataObj, url, "PUT");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    this.deleteInvestigationFormComment = function (commentId) {
      var deferred = $q.defer();
      var url = "form-comments/delete/" + commentId;
      var data = restService.restCall("", url, "DELETE");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    this.deleteFilesByIndex = function (parentEntityId, entityKey, sectionAccordionIndex) {
      var deferred = $q.defer();
      var url = "assets/delete/" + parentEntityId + "/" + entityKey + "/" + sectionAccordionIndex;
      var data = restService.restCall("", url, "DELETE");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };

    this.deleteFiles = function (documentId) {
      var deferred = $q.defer();
      var url = "assets/document/" + documentId;
      var data = restService.restCall("", url, "DELETE");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    //To delete multiple assets from the manage attachments popup
    this.deleteAllFiles = function (deleteAssetList) {
      var deferred = $q.defer();
      var url = "assets/document/list";
      var data = restService.restCall(deleteAssetList, url, "POST");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    this.getUploadFiles = function (parentEntityId, entityKey, sectionAccordionIndex, questionKey) {
      var deferred = $q.defer();
      var url = "assets/getListDocument/" + parentEntityId;

      if (entityKey && entityKey != "") {
        url += "?entityKey=" + entityKey;
      }

      if (sectionAccordionIndex != undefined && sectionAccordionIndex != null) {
        url += "&sectionAccordionIndex=" + sectionAccordionIndex;
      }

      if (questionKey != undefined && questionKey != null) {
        url += "&questionKey=" + questionKey;
      }
      var data = restService.restCall("", url, "GETLIST");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    this.saveInvestigatorResult = function (investigationFormId, ageResult, identityResult, formLanguage) {
      var deferred = $q.defer();
      var url =
        "investigation-form/save-investigator-result/" +
        investigationFormId +
        "?ageResult=" +
        ageResult +
        "&&identityResult=" +
        identityResult +
        "&&formLanguage=" +
        formLanguage;
      var data = restService.restCall("", url, "POST");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject(error);
        }
      );
      return deferred.promise;
    };

    this.addorSuggestEntityRequest = function (dataObj) {
      var deferred = $q.defer();
      var url =
        "approved-entity-profile-names?searchKey=" + dataObj.searchKey + "&entityTypeKey=" + dataObj.entityTypeKey;
      var data = restService.restCall("", url, "GETLIST");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    this.saveFamilyPhotoDescriptions = function (uploadFamilyPhotoObj) {
      var deferred = $q.defer();
      var url = "assets/save-asset-descriptions";
      var data = restService.restCall(uploadFamilyPhotoObj, url, "POST");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    this.getIndividualAnalystSectionList = function (sectionKey, formId) {
      var deferred = $q.defer();
      var url = "form-section-results/get/" + formId + "?sectionKey=" + sectionKey;
      var data = restService.restCall("", url, "GET");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    var saveSectionDeferred = $q.defer();
    this.saveIndividualAnalystSectionList = function (sectionKey, formId, isSaveFormAuditFlag, postCheckList) {
      if (saveSectionDeferred != undefined) {
        saveSectionDeferred.resolve();
        saveSectionDeferred = $q.defer();
      }
      var url =
        "form-section-results/" + formId + "?sectionKey=" + sectionKey + "&isSaveFormAuditFlag=" + isSaveFormAuditFlag;
      var timestamp = new Date().getTime();
      if (url.indexOf("?") != -1) {
        url = url + "&timestamp=" + timestamp;
      } else if (url.indexOf("?") == -1) {
        url = url + "?timestamp=" + timestamp;
      }
      url = BASE_URL + "api/" + url;
      var promise = $http.post(url, postCheckList, { timeout: saveSectionDeferred.promise }).then(function (response) {
        return response.data;
      });
      return promise;
    };

    //Get the location request by requestId
    this.getRequestProfileById = function (reqId) {
      var deferred = $q.defer();
      var url = "location-mappings/isApproved/" + reqId;
      var data = restService.restCall("", url, "GET");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    //Get the approved entity by entitysId
    this.getApprovedEntityProfile = function (requestedId) {
      var deferred = $q.defer();
      var url = "entity-profiles/isApproved/" + requestedId;
      var data = restService.restCall("", url, "GET");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };

    this.getFamilyInformationService = function (formId, lang) {
      var deferred = $q.defer();
      var url = "investigation-form-data/get-sibling-details/" + formId + "?langKey=" + lang;
      var data = restService.restCall("", url, "GETLIST");
      data.$promise.then(
        function (response) {
          deferred.resolve(response);
        },
        function (error) {
          deferred.reject("ERROR");
        }
      );
      return deferred.promise;
    };
  }
})();
