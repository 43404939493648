(function() {
	'use strict';

    angular
        .module('lariAidApp').directive('locationFilter',locationDropFilter);
    
    function locationDropFilter(){
			return {
			    templateUrl: 'app/library/locationdirective/library.locationdirective.html',
				controller:'LibraryLocationDirectiveController',
			    transclude:true,
				replace: true,
			    restrict: 'E',
				scope:{
					modelObject: '=modelObject',
					call: '&',
					horizon: '=horizonFlag',
					validation:'=validationRequired',
					formName:'=formName',
					isValidation:"=",
					savevalid: '&',
					inputsearchObject: '=inputsearchObject',
					disableFlag: '=disableFlag',
					ishideObject:'=ishideObject',
					relpaceinputfield: '&',
					relpaceinputfieldObject:'=relpaceinputfieldObject',
					media: '=media'
				},
			    link: function(scope, element, attrs) {
			    }
		    }
			
		}
    
})();