(function() {
    'use strict';
    
    var lariDevApp = angular
    .module('lariAidApp')
    lariDevApp.controller('InvestigationFormCommentController', InvestigationFormCommentController);
    
    InvestigationFormCommentController.$inject = ['$scope','$window', '$mdSidenav','Principal','$state','$mdMedia','$cookies','APP','$stateParams','$rootScope','InvestigationFormService','$timeout','$sce'];
    function InvestigationFormCommentController ($scope,$window, $mdSidenav,Principal,$state,$mdMedia,$cookies,APP,$stateParams,$rootScope,InvestigationFormService,$timeout,$sce) {
    	
    	/* Method name           : saveOrUpdateComment()
   	 	 * Method Description 	 : This method is used to save/update question comment
		 */
	   	$scope.saveOrUpdateComment = function(comment){
	   		if(!$scope.isContentEmpty(comment)) {
	   			var validatedComment=$scope.validation(comment);
	   			$scope.modelName = validatedComment;	
	   		} else {
	   			$scope.modelName = "";
	   		}
	   	};
	   	
	   	$scope.isContentEmpty = function(inputValue) {
	   		var plainText;
	   		var tempVal;

	   		if(inputValue != undefined){
	   			plainText = inputValue.replace(/<\/?[^>]+(>|$)/g, "");  
	   			plainText = plainText.replace(/&nbsp;/g, "");
	   			plainText = plainText.replace(/ /g, "");
	   			tempVal = plainText.replace(/[\n\r]/g,"");
	   			if(tempVal == '') plainText = tempVal
	   		}

	   		if (plainText == undefined || plainText == '') {
	   			return true;
	   		} else {
	   			return false;
	   		}
	   	};
	   	$scope.validation = function(inputComment){
	   		inputComment=inputComment.replace(/(:)[a-z]{1,4}(\([0-9a-z\(\-\\*\s\)\.]*\))/g,":16px");
	   		return inputComment;
	   	};
	   	$scope.removeComment = function(commentId){
	   		
	   		if(commentId) {
	   			var data = InvestigationFormService.deleteInvestigationFormComment(commentId);
	   		}
	   		if(data){
   				data.then(function(response){
	 		  		$scope.cmtId = response.id;
	 		    }, function(reason) {
	 			  return reason;
	 		   });
   			}
	   		$scope.modelName = "";
	   	};
	   	$rootScope.openAutocomplete=function(index){
 		   setTimeout(function(){
 		       angular.element('#'+index).find('input').focus();
 		   },0);
 };

	   		
		$scope.showOrHideComment = function(){
			$scope.showComment = !$scope.showComment;
		}
	   		
		   	$scope.trustAsHTML = function(htmlContent){
		   		return $sce.trustAsHtml(htmlContent);
		   		
		   	}
    }
    
})();