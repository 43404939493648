(function () {
  'use strict';

  angular
    .module('lariAidApp')
    .config(stateConfig);

  stateConfig.$inject = ['$stateProvider', 'APP', '$mdAriaProvider', '$mdDateLocaleProvider'];

  function stateConfig($stateProvider, APP, $mdAriaProvider, $mdDateLocaleProvider) {
    $stateProvider.state('legacyLari', {
      parent: 'app',
      url: '/legacyLari',
      data: {
        authorities: APP.GLOBAL.USER_GROUP
      },
      views: {
        'dynamicView': {
          templateUrl: 'app/legacyLari/legacyLari.management.html',
          controller: 'LegacyLariController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          if (localStorage && localStorage.getItem('lari_currentVersion') != null) {
            $translatePartialLoader.addPart('legacyLari.json?version=' + localStorage.getItem('lari_currentVersion'));
          } else {
            $translatePartialLoader.addPart('legacyLari.json?timestamp=' + new Date().getTime());
          }
          return $translate.refresh();
        }]
      }

    });
  }

})();
