(function () {
  "use strict";

  angular.module("lariAidApp").config(stateConfig);

  stateConfig.$inject = ["$stateProvider", "notificationsConfigProvider"];

  function stateConfig($stateProvider, notificationsConfigProvider) {
    // auto hide
    notificationsConfigProvider.setAutoHide(true);

    // delay before hide
    notificationsConfigProvider.setHideDelay(6000);

    // support HTML
    notificationsConfigProvider.setAcceptHTML(false);

    // Set an animation for hiding the notification
    //notificationsConfigProvider.setAutoHideAnimation('fadeOutNotifications');

    // delay between animation and removing the nofitication
    //notificationsConfigProvider.setAutoHideAnimationDelay(1200);

    $stateProvider.state("app", {
      abstract: true,
      views: {
        "navbar@": {
          templateUrl: "app/layouts/navbar/navbar.html",
          controller: "NavbarController",
          controllerAs: "vm"
        },
        "content@": {
          templateUrl: "app/main/main.html"
        }
      },
      resolve: {
        authorize: [
          "Auth",
          function (Auth) {
            return Auth.authorize().catch(e => {
              if (!!e && (!e.status || !Number.isInteger(e.status))) {
                console.error(e);
              }
            });
          }
        ],
        translatePartialLoader: [
          "$translatePartialLoader",
          function ($translatePartialLoader) {
            if (localStorage && localStorage.getItem("lari_currentVersion") != null) {
              $translatePartialLoader.addPart("global.json?version=" + localStorage.getItem("lari_currentVersion"));
            } else {
              $translatePartialLoader.addPart("global.json?timestamp=" + new Date().getTime());
            }
          }
        ]
      }
    });
  }
})();
