(function() {
    'use strict';

    angular
        .module('lariAidApp')
        .service('ReligiousInstitutionService', ReligiousInstitutionService);

    ReligiousInstitutionService.$inject = ['$resource','restService','$q','$rootScope'];
    
    function ReligiousInstitutionService ($resource,restService,$q,$rootScope) {
    	
    	
    		
    }
})();