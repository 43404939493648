(function(){
	'use strict'; 

angular
  .module('lariAidApp')
  .service('CommonService', ['$resource','restService','$q','$cookies','APP','Auth', function ($resource,restService,$q,$cookies,APP,Auth) {
	  
	  var vm = this;
	  vm.APP = APP;
	  
	  vm.getAgentLists= function(agentSearchKey){
  		var deferred = $q.defer();
  		var url = "trainers?searchKey="+agentSearchKey;
  		var data = restService.restCall("", url, 'GETLIST');
  		data.$promise.then(function(response){
  			deferred.resolve(response);
  		},
  		function (error) {
  			deferred.reject('ERROR');
  		});
  		return deferred.promise;
  	};
  	
  	vm.getUsersList= function(roleKey,searchkey){
		var deferred = $q.defer();
		var url = "users/role/"+roleKey+"?searchKey="+searchkey;
		var data = restService.restCall("", url, 'GETLIST');
		data.$promise.then(function(response){
			deferred.resolve(response);
		},
		function (error) {
			deferred.reject('ERROR');
		});
		return deferred.promise;
	};
	
	
	vm.getRefTermList = function(path,searchkey){
		var deferred = $q.defer();
		var url = "ref-terms/ref-set/"+path;
		if(searchkey){
			url+= "?searchKey="+searchkey;
		}
		var data = restService.restCall("", url, 'GETLIST');
		data.$promise.then(function(response){
			deferred.resolve(response);
		},
		function (error) {
			deferred.reject('ERROR');
		});
		return deferred.promise;
	};
	
	vm.getBirthCountry = function(){
		var deferred = $q.defer();
		var url = "prospects/birthCountry";
		var data = restService.restCall("", url, 'GETLIST');
		data.$promise.then(function(response){
			deferred.resolve(response);
		},
		function (error) {
			deferred.reject('ERROR');
		});
		return deferred.promise;
	};
	
	vm.getLocationById = function(locationMappingId){
		var deferred = $q.defer();
		var url = "location-mappings/"+locationMappingId;
		var data = restService.restCall('', url, 'GET');
		data.$promise.then(function(response){
			deferred.resolve(response);
		},
		function (error) {
			deferred.reject('ERROR');
		});
		return deferred.promise;
	};
	
	vm.getCookieLanguage = function() {
		var cookieLanguage = $cookies.getObject('NG_TRANSLATE_LANG_KEY');
		if(cookieLanguage) {
			return cookieLanguage;
		}
	};
	
	vm.getCookieUser = function(){
		var user = {};

		var cookieUser = $cookies.getObject('sessionDetails');
		if(cookieUser){
			var loggedUser = cookieUser.authorities[0];
			var loggedUserRole = '';
			if(loggedUser == vm.APP.GLOBAL.ROLE_MANAGER){
				loggedUserRole = vm.APP.GLOBAL.MANAGER;
			}
			else if(loggedUser == vm.APP.GLOBAL.ROLE_ADMIN){
				loggedUserRole = vm.APP.GLOBAL.ADMIN;
			}
			else if(loggedUser == vm.APP.GLOBAL.ROLE_INVESTIGATOR){
				loggedUserRole = vm.APP.GLOBAL.INVESTIGATOR; 
			}
			else if(loggedUser == vm.APP.GLOBAL.ROLE_ANALYST){
				loggedUserRole = vm.APP.GLOBAL.ANALYST;
			}
			else if(loggedUser == vm.APP.GLOBAL.ROLE_READ_ONLY){
				loggedUserRole = vm.APP.GLOBAL.READ_ONLY;
			}
			else if(loggedUser == vm.APP.GLOBAL.ROLE_COUNTRY_ADMIN){
				loggedUserRole = vm.APP.GLOBAL.ROLE_COUNTRY_ADMIN;
			}


			user.cookieUser = cookieUser;
			user.loggedUser = loggedUser;
			user.loggedUserRole = loggedUserRole;
	
			return  user;
		} else{
			Auth.logout(false);
		}

		};	
	}]
  );
})();